import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Table, Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import {
  BudgetHeader,
  BudgetFooter,
  BudgetType,
  BudgetContractItem,
  disableForecast,
} from '..';

import { useQueryBudgetsByType } from '../../../../../hooks';
import {
  updateArrayByKey,
  copyArrayReference,
  howManyApproved,
  costStats,
} from '../../../../../common';
import { BudgetConfigContext } from '../../../../../contexts';

const BudgetContractTable = ({
  view,
  requestForItem,
  details,
  onTotal = () => {},
  onValidate = () => {},
  createNewLineItem = () => {},
  costCode,
  claim,
}) => {
  const [contracts, setContracts] = useState([]);
  // This is to do with our currency decimals:
  const { config } = useContext(BudgetConfigContext);
  //

  const { loading, getBudgetsByType, emptyBudgetItem, refetchBudgets } =
    useQueryBudgetsByType({
      costCode: details.costCode.code,
      projectId: details.project._id,
      vendorId: details.vendor._id,
      type: BudgetType.Contract,
      onCompleted: (data) => {
        setContracts(data);
      },
    });

  const onDelete = (id) => {
    const clone = copyArrayReference(contracts);
    _.remove(clone, (i) => i._id === id);
    setContracts(clone);
  };

  const [totals, setTotals] = useState({
    forecast: {
      anticipated: 0,
      submitted: 0,
      recommended: 0,
    },
    committed: {
      contracted: 0,
    },
    fcc: 0,
    expenditure: {
      allowed: 0,
      thisClaim: 0,
      remaining: 0,
      current: 0,
      previous: 0,
    },
  });

  const createItem = () => {
    const contractsCopy = [...contracts];
    contractsCopy.push(
      emptyBudgetItem({
        type: BudgetType.Contract,
        vendor: details.vendor._id,
        project: details.project._id,
        costCode: details.costCode.code,
        accountsPayable: {
          key: costCode.accountsPayableCategory.name,
          value: costCode.accountsPayableCategory.value,
        },
        fundingSource: {
          key: costCode.fundingSourceCategory.name,
          value: costCode.fundingSourceCategory.value,
        },
      }),
    );
    setContracts(contractsCopy);
  };

  const onCalculationCompleted = (item) =>
    setContracts(updateArrayByKey(item, contracts));

  const calculateTotals = () => {
    const clone = { ...totals };
    clone.forecast.anticipated = _.sumBy(contracts, (item) =>
      disableForecast(item, 'anticipated') ? 0 : item.forecast.anticipated,
    );
    clone.forecast.submitted = _.sumBy(contracts, (item) =>
      disableForecast(item, 'submitted') ? 0 : item.forecast.submitted,
    );
    clone.forecast.recommended = _.sumBy(contracts, (item) =>
      disableForecast(item, 'recommended') ? 0 : item.forecast.recommended,
    );
    clone.committed.contracted = _.sumBy(
      contracts,
      (item) => item.committed.contracted,
    );
    clone.fcc = _.sumBy(contracts, (item) => item.fcc);

    clone.expenditure.allowed = _.sumBy(
      contracts,
      (item) => item.expenditure.allowed,
    );
    clone.expenditure.thisClaim = _.sumBy(
      contracts,
      (item) => item.expenditure.thisClaim,
    );
    clone.expenditure.remaining = _.sumBy(
      contracts,
      (item) => item.expenditure.remaining,
    );
    clone.expenditure.current = _.sumBy(contracts, (item) =>
      item.expenditure.current ? item.expenditure.current.amount : 0,
    );
    clone.expenditure.previous = _.sumBy(contracts, (item) =>
      item.expenditure.previous && item.expenditure.previous.length > 0
        ? _.sumBy(item.expenditure.previous, (p) => p.amount)
        : 0,
    );

    // approved count
    //

    setTotals(clone);
    onTotal({
      type: BudgetType.Contract,
      totals: {
        ffc: clone.fcc ? clone.fcc : 0,
        contracted: clone.committed.contracted ? clone.committed.contracted : 0,
        adjustments: 0,
        thisClaim: clone.expenditure.thisClaim,
        forecasts:
          clone.forecast.anticipated +
          clone.forecast.submitted +
          clone.forecast.recommended,
        expenditure: { ...clone.expenditure },
        count: contracts.length,
        approved: howManyApproved(contracts),
        costStats: costStats(contracts),
      },
    });
  };

  const validate = () => {
    const validation = {
      [BudgetType.Contract]: [],
    };
    contracts.forEach((contract) => {
      if (
        contract.committed.contracted == null ||
        contract.committed.contracted === undefined
      )
        validation[BudgetType.Contract].push({
          section: 'contract.committed.contracted',
          message: `You have a contract line item "${contract.ref}" without a contracted amount. Please provide an amout or remove the item prior to locking contract.`,
        });
    });
    onValidate(validation);
  };

  useEffect(() => getBudgetsByType(), []);

  useEffect(() => {
    if (requestForItem && requestForItem.value === BudgetType.Contract)
      createItem();
  }, [requestForItem]);

  useEffect(() => {
    calculateTotals();
    validate();
  }, [contracts]);

  useEffect(() => {
    if (claim) refetchBudgets();
  }, [claim]);

  return contracts.length > 0 ? (
    <Row>
      <Col md={12}>
        <h5 className="mt-2 contracts">
          <i className="fas fa-list" />{' '}
          {details.accountType === 'bills' ? 'Bills' : 'Contracts'}
        </h5>
        {!loading ? (
          <div className="belvedere-responsive">
            <Table size="sm" hover className="budget-entry">
              <thead>
                <BudgetHeader view={view} type={BudgetType.Contract} />
              </thead>
              <tbody>
                {contracts.map((item) => (
                  <BudgetContractItem
                    view={view}
                    onCalculationCompleted={onCalculationCompleted}
                    details={details}
                    item={item}
                    key={item._id}
                    onDelete={onDelete}
                  />
                ))}
              </tbody>
              <tfoot>
                <BudgetFooter
                  view={view}
                  type={BudgetType.Contract}
                  details={details}
                  value="contract"
                  totals={totals}
                  precision={config.decimal ? 2 : 0}
                  createNewLineItem={createNewLineItem}
                />
              </tfoot>
            </Table>
          </div>
        ) : (
          <Skeleton />
        )}
      </Col>
    </Row>
  ) : (
    <div className="d-flex mt-3 p-4 flex-column align-items-center justify-content-center">
      <i className="fas fa-pencil-alt display-4" />
      <h4 className="mt-2">
        Please add your first{' '}
        {details.accountType === 'bills' ? 'bills' : 'contract'} item for{' '}
        {details.vendor.name}
      </h4>
    </div>
  );
};

export default BudgetContractTable;
