import _ from "lodash";
import moment from "moment";

const defaultPeriodFields = {
    rowName: "",
    previous: "",
    adjusted: "",
    nonAdjusted: "",
    totalAdjusted: "",
    totalNonAdjusted: "",
    editable: false
}

export const defaultCashflowState = {
    cashflowId: undefined,
    columns: [],
    data: [],
    query: {
      startDate: moment(Date.now()).format("YYYY-MM-DD"),
      window: 12
    }
}

export const keyifyPeriods = (periods) => {
    return _.map(periods, period => {
        return {
            ...period,
            key: `${period.month}${period.year}`,
            name: `${moment.monthsShort(period.month - 1)} ${period.year}`
        }
    })
}

export const nullifyValues = (periods) => {
    return _.map(periods, period => {
        return {
            ...period,
            key: `${period.month}${period.year}`,
            name: `${moment.monthsShort(period.month - 1)} ${period.year}`,
            value: undefined
        }
    })
}

export const formatCashflow = (data) => {
    let estimatesRow = { ...defaultPeriodFields, rowName: "Period-to-Period", editable: true };
    let accumulativeRow = { ...defaultPeriodFields, rowName: "Accumulative" };
    let expenditureRow = { ...defaultPeriodFields, rowName: "Actual Expenditure" };
    let accumulativeExpenditureRow = { ...defaultPeriodFields, rowName: "Accumulative" }

    let columns = _.map(data.periods, (period) => {

        /**
         * Append additional properties like key and name
         */
        let column = {
            ...period,
            key: `${period.month}${period.year}`,
            name: `${moment.monthsShort(period.month - 1)} ${period.year}`
        }

        /**
         * While we're at it, let's create a data object from our periods.
         */
        estimatesRow[column.key] = column.value;
        estimatesRow["previous"] = data.calculations.previous;
        estimatesRow["adjusted"] = data.calculations.projected.adjusted;
        estimatesRow["nonAdjusted"] = data.calculations.projected.nonAdjusted;
        estimatesRow["totalAdjusted"] = data.calculations.projected.totalAdjusted;
        estimatesRow["totalNonAdjusted"] = data.calculations.projected.totalNonAdjusted;

        return column;

    });

    _.forEach(data.calculations.accumulativePeriods, (period) => {
        accumulativeRow[`${period.month}${period.year}`] = period.value;
    });

    _.forEach(data.calculations.expenditure, (period) => {
        expenditureRow[`${period.month}${period.year}`] = period.value;
    });

    _.forEach(data.calculations.accumulativeExpenditure, (period) => {
        accumulativeExpenditureRow[`${period.month}${period.year}`] = period.value;
    });

    return {
        cashflowId: data._id,
        columns,
        data: [estimatesRow, accumulativeRow, expenditureRow, accumulativeExpenditureRow],
        original: data
    }
}