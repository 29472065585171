import React from 'react';
import { withProject } from '../../common';
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap';
import _ from 'lodash';
import Cashflow from '../../components/cashflow/Cashflow';
import CashflowCard from '../../components/cashflow/CashflowCard';

const CashflowView = ({ project }) => {
  return (
    <>
      <Row>
        <Col md="9">
          <Breadcrumb tag="nav">
            <BreadcrumbItem tag="a" href="/portfolio">
              Portfolio
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" href={'/projects/' + project._id}>
              {project.name}
            </BreadcrumbItem>
            <BreadcrumbItem tag="a">Cashflows</BreadcrumbItem>
          </Breadcrumb>
        </Col>
        <Col md="3">
            {/* <CashflowCard project={project} /> */}
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Cashflow project={project} />
        </Col>
      </Row>
    </>
  );
};

export default withProject(CashflowView);
