import styled, { css } from 'styled-components';
import { BiStar } from 'react-icons/bi';

const ProjectCardIconStyles = css`
  width: 25px;
  height: 25px;
  color: #1B1B1B;
`;

export const StarIcon = styled(BiStar)`
  ${ProjectCardIconStyles}
`;
