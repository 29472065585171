import React from 'react';
import {
  ProjectOverviewWidgetStyles,
  Image,
  TextContainer,
  TextCard,
  TextHeading,
  Text,
} from './styles/ProjectOverviewWidget';
import stockProjectImage from '../../../assets/images/big/stock-project-image.jpg';

/**
 * React component which gives you an overview of a project
 * @param {Object} project The project object passed down
 */
const ProjectOverviewWidget = ({ project }) => {
  return (
    <ProjectOverviewWidgetStyles>
      <Image src={project.thumbnail ?? stockProjectImage} />
      <TextContainer>
        <TextCard>
          <TextHeading>Details</TextHeading>
          <Text>{project.description}</Text>
        </TextCard>
        <TextCard half>
          <TextHeading>Address</TextHeading>
          <Text>
            <a
              target="_blank"
              href={`https://www.google.com/maps/search/${project.address.replace(
                / /g,
                '+',
              )}`}
            >
              {project.address}
            </a>
          </Text>
        </TextCard>
        <TextCard half>
          <TextHeading>Client</TextHeading>
          <Text>{project.client.name}</Text>
        </TextCard>
        <TextCard half>
          <TextHeading>Name</TextHeading>
          <Text>{project.name}</Text>
        </TextCard>
        <TextCard half>
          <TextHeading>Code</TextHeading>
          <Text>{project.code}</Text>
        </TextCard>
      </TextContainer>
    </ProjectOverviewWidgetStyles>
  );
};

export default ProjectOverviewWidget;
