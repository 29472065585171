import React from 'react';
import { PhaseBadgeContainer } from './styles/PhaseBadge';

/**
 * This component is used to render the 'Phase' column of our milestone table
 * @param {React.JSX} children The children of our element
 * @param {string} backgroundColor The string of the background color for our phase badge 
 * @returns 
 */
const PhaseBadge = ({ children, backgroundColor }) => {
  return (
    <PhaseBadgeContainer backgroundColor={backgroundColor}>{children}</PhaseBadgeContainer>
  );
}

export default PhaseBadge;
