import React from 'react';

import { Main, Table, Period } from '../common';

const FfcBreakdown = ({ template }) => {
  return (
    <Main template={template} name={'FFC Breakdown'}>
      <Period period={template.period} />
      <Table table={template.table} />
    </Main>
  );
};

export default FfcBreakdown;
