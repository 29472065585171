import React, { useState } from 'react';
import _ from 'lodash';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
// Local Imports
import Card from './card';
import StatusHeader from './header';
import StatusTextArea from './textarea';
import { Spinner } from '../../components';
import { withProject } from '../../common';

// Graphql Queries
const GET_STATUS_COMMENTS = gql`
  query getStatusDocuments($projectId: ID!) {
    getStatusDocuments(projectId: $projectId) {
      _id
      text
      date
    } 
  }
`;

// GraphQL Mutations
const DELETE_STATUS_COMMENT = gql`
  mutation deleteStatusDocument($projectId: ID!, $documentId: ID!) {
    deleteStatusDocument(projectId: $projectId, documentId: $documentId) {
      code
      success
      message
      documents {
        _id
        text
        date
      }
    }
  }
`;

const Status = ({ project }) => {
  const [comments, setComments] = useState([]);

  // GraphQL Queries
  const { loading: loadingGetStatusComments, error: errorGetStatusComments, data: dataGetStatusComments } = useQuery(GET_STATUS_COMMENTS, {
    variables: {
      projectId: project._id,
    },
    onCompleted: (data) => {
      setComments(data.getStatusDocuments);
    }
  });

  // GraphQL Mutations
  const [deleteStatusComment, { loading: loadingDeleteStatusComment, errorDeleteStatusComment, dataDeleteStatusComment }] = useMutation(DELETE_STATUS_COMMENT, {
    onCompleted: (data) => {
      setComments(data.deleteStatusDocument.documents);
    }
  });

  if (loadingGetStatusComments) return <Spinner />;

  const handleCommentDelete = async (documentId) => {
    await deleteStatusComment({
      variables: {
        projectId: project._id,
        documentId,
      }
    });
  }

  return (
    <>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Home
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/projects/${project._id}`}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Status</BreadcrumbItem>
      </Breadcrumb>
      <StatusHeader />
      <StatusTextArea projectId={project._id} setComments={setComments} />
      <>
        {_.map(comments, (value, index) => {
          return (
            <Card
              key={value._id}
              id={value._id}
              projectId={project._id}
              date={value.date}
              text={value.text}
              isEditable={true}
              onDelete={() => handleCommentDelete(value._id)}
              setComments={setComments}
            />
          );
        })}

      </>
    </>
  );
};

export default withProject(Status);
