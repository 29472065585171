import gql from 'graphql-tag';

/**
 * Mutations
 */

const GET_BUDGET_MESSAGES_QUERY = gql`
  query ($budgetId: ID!) {
    getMessages(budgetId: $budgetId) {
      _id
      sender {
        _id
        name
        handle
        image
        title
      }
      message
      timestamp
      me
    }
  }
`;

const SEND_BUDGET_MESSAGES_MUTATION = gql`
  mutation ($budgetId: ID!, $message: String!) {
    sendMessage(budgetId: $budgetId, message: $message) {
      _id
      sender {
        _id
        name
        handle
        image
        title
      }
      message
      timestamp
      me
    }
  }
`;

export { GET_BUDGET_MESSAGES_QUERY, SEND_BUDGET_MESSAGES_MUTATION };
