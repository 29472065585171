import styled from 'styled-components';

export const FundingCardsContainerStyles = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px 16px;
  margin-bottom: 16px;
  font-family: 'Inter', sans-serif;
  color: #F9F9F9;

  @media screen and (min-width: 1250px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;