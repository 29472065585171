import styled, { css } from 'styled-components';
import { AiOutlinePieChart } from 'react-icons/ai';
import { SiFuturelearn } from 'react-icons/si';
import { BiTransfer } from 'react-icons/bi';
import { MdAddchart } from 'react-icons/md';
import { MdAttachMoney } from 'react-icons/md';
import { MdOutlineChangeCircle } from 'react-icons/md';

const statusCardIconStyles = css`
  width: 72px;
  height: 72px;
`;

export const PieGraphIcon = styled(AiOutlinePieChart)`
  ${statusCardIconStyles}
`;

export const FutureIcon = styled(SiFuturelearn)`
  ${statusCardIconStyles}
`;

export const TransferIcon = styled(BiTransfer)`
  ${statusCardIconStyles}
`;

export const AddIcon = styled(MdAddchart)`
  ${statusCardIconStyles}
`;

export const MoneyIcon = styled(MdAttachMoney)`
  ${statusCardIconStyles}
`;

export const ChangeIcon = styled(MdOutlineChangeCircle)`
  ${statusCardIconStyles}
`;
