import React from 'react';
import _ from 'lodash';
import { useQuery, gql } from '@apollo/client';
import { ProjectCardsContainer } from './styles/ProjectCards';
import ProjectCard from './ProjectCard';
import { LoadingErrorStateContainer, LoadingSpinner, LoadingErrorStateText, ErrorIcon } from './styles/LoadingErrorStates';

const GET_PROJECTS_BY_ORG = gql`
  query ($organisation: ID!) {
    getProjectsByOrg(organisation: $organisation) {
      _id
      name
      address
      code
      collaborators {
        user {
          _id
          name
          image
        }
      }
      locked
    }
  }
`;

const ProjectCards = ({ organisationId, filter }) => {
  if (!organisationId) return null;
  const { loading, error, data } = useQuery(GET_PROJECTS_BY_ORG, {
    variables: {
      organisation: organisationId,
    },
  });

  if (loading) return (
    <LoadingErrorStateContainer>
      <LoadingSpinner size={50} />
    </LoadingErrorStateContainer>
  );

  if (error) return (
    <LoadingErrorStateContainer>
      <ErrorIcon />
      <LoadingErrorStateText>
        Oops ... something went wrong, please refresh and try again. 
      </LoadingErrorStateText>
    </LoadingErrorStateContainer>
  );

  const numProjectsArchived = _.reduce(data.getProjectsByOrg, (accumulator, value) => {
    return accumulator + (value.locked === true ? 1 : 0);
  }, 0);

  const numProjectsActive = data.getProjectsByOrg.length - numProjectsArchived;

  if (filter === 'archived' && numProjectsArchived === 0) {
    return (
      <LoadingErrorStateContainer>
        <LoadingErrorStateText>
          You currently have no Archived Projects.
        </LoadingErrorStateText>
      </LoadingErrorStateContainer>
    );
  }

  if (filter === 'active' && numProjectsActive === 0) {
    return (
      <LoadingErrorStateContainer>
        <LoadingErrorStateText>
          You currently have no Active Projects.
        </LoadingErrorStateText>
      </LoadingErrorStateContainer>
    );
  }

  return (
    <ProjectCardsContainer>
      {_.map(data.getProjectsByOrg, (value, index) => {
        // If we show archived but project is active we return null
        if (filter === 'archived' && value.locked === false) return null;
        // If we show active but the project is archived we return null
        if (filter === 'active' && value.locked === true) return null;
        return (
          <ProjectCard
            index={index}
            projectId={value._id}
            code={value.code}
            name={value.name}
            address={value.address}
            collaborators={value.collaborators}
          />
        );
      })}
    </ProjectCardsContainer>
  );
};

export default ProjectCards;
