import styled from 'styled-components';
import { BsList } from 'react-icons/bs';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding-bottom: 16px;
`;

export const StatusFeedIcon = styled(BsList)`
  width: 25px;
  height: 25px;
  color: #1B1B1B;
`;

export const Heading = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-left: 8px;
`;
