import React, { useState } from 'react';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withProject } from '../../common';
import Financials from '../../components/budget/entry/Financials';
import Display from '../../components/budget/entry/Display';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Commitments = ({ project }) => {
  let query = useQuery();

  const [focotr, setFocotr] = useState(undefined);
  const [costCode, setCostCode] = useState(undefined);
  const [totalOfTotals, setTotalOfTotals] = useState(undefined);

  const onTotals = (totals) => setTotalOfTotals(totals);

  const onFinancialsChange = (event) => setCostCode(event);
  const addedFocotr = (item) => setFocotr(item);

  return (
    <>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={'/projects/' + project._id}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Costs {'&'} Claims</BreadcrumbItem>
      </Breadcrumb>

      <Row>
        <Col xs="12">
          <Financials
            project={project}
            totalOfTotals={totalOfTotals}
            onChange={onFinancialsChange}
            onFocotr={addedFocotr}
            cc={query.get('costCode')}
          />
        </Col>
      </Row>
      {costCode ? (
        <Row>
          <Col xs={12}>
            <Display
              costCode={costCode}
              project={project}
              focotrItem={focotr}
              onTotalsCompleted={onTotals}
            />
          </Col>
        </Row>
      ) : (
        ''
      )}
    </>
  );
};

export default withProject(Commitments);
