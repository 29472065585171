import { useMutation } from '@apollo/client';

import {
  CREATE_ROLE_MUTATION,
  UPDATE_ROLE_MUTATION,
  DELETE_ROLE_MUTATION,
  ADD_PERMISSIONS_MUTATION,
  REMOVE_PERMISSIONS_MUTATION,
} from '../../graphql/queries';

const useCreateRole = () => {
  const [_createRole, { data }] = useMutation(CREATE_ROLE_MUTATION);

  const createRole = (role) => {
    return _createRole({
      variables: {
        role,
      },
    });
  };

  return [createRole, { data }];
};

const useUpdateRole = () => {
  const [_updateRole, { data }] = useMutation(UPDATE_ROLE_MUTATION);

  const updateRole = (_id, role) => {
    return _updateRole({
      variables: {
        _id,
        role,
      },
    });
  };

  return [updateRole, { data }];
};

const useDeleteRole = () => {
  const [_deleteRole, { data }] = useMutation(DELETE_ROLE_MUTATION);

  const deleteRole = (_id) => {
    return _deleteRole({
      variables: {
        _id,
      },
    });
  };

  return [deleteRole, { data }];
};

const useAddPermissions = () => {
  const [_addPermissions, { data }] = useMutation(ADD_PERMISSIONS_MUTATION);

  const addPermissions = (_id, permissions) => {
    return _addPermissions({
      variables: {
        _id,
        permissions,
      },
    });
  };

  return [addPermissions, { data }];
};

const useRemovePermissions = () => {
  const [_removePermissions, { data }] = useMutation(
    REMOVE_PERMISSIONS_MUTATION,
  );

  const removePermissions = (_id, permissions) => {
    return _removePermissions({
      variables: {
        _id,
        permissions,
      },
    });
  };

  return [removePermissions, { data }];
};

export {
  useCreateRole,
  useUpdateRole,
  useDeleteRole,
  useAddPermissions,
  useRemovePermissions,
};
