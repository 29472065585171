import React from 'react';
import { Nav, Navbar, NavItem, NavLink } from 'reactstrap';

const SettingsHeaderContent = ({ settings }) => {
  return (
    <Navbar
      className={`top-navbar navbar-expand-md ${
        settings.activeNavbarBg === 'skin6' ? 'navbar-light' : 'navbar-dark'
      }`}
    >
      <Nav navbar>
        <NavItem>
          <NavLink href="/portfolio">
            <i className="mdi mdi-view-dashboard" /> Portfolio
          </NavLink>
        </NavItem>
      </Nav>
      <Nav className="ml-auto float-right" navbar>
        {/* <UserBar /> */}
      </Nav>
    </Navbar>
  );
};

export default SettingsHeaderContent;
