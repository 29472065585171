import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';
import 'react-multi-email/style.css';
import { useRolesByOrg, useDeleteRole } from '../../../hooks';
import OrganisationContext from '../../../contexts/organisation/organisation.context';
import RoleView from './RoleView';
import RoleModify from './RoleModify';

const RoleList = () => {
  const [modal, setModal] = useState({});
  const [baseRoles, setBaseRoles] = useState([]);
  const [customRoles, setCustomRoles] = useState([]);
  // const { user } = useContext(UserContext);
  const { currentOrganisation } = useContext(OrganisationContext);

  const { roles: data, loading } = useRolesByOrg(
    currentOrganisation ? currentOrganisation._id : undefined,
  );
  const [deleteOrgRole] = useDeleteRole();

  useEffect(() => {
    if (!loading) {
      const [baseData, customData] = data.reduce(
        (result, element) => {
          result[element.inbuilt ? 0 : 1].push(element);
          return result;
        },
        [[], []],
      );
      setBaseRoles(baseData);
      setCustomRoles(customData);
    }
  }, [loading]);

  const toggle = (id) => {
    setModal({ [id]: !modal[id] });
  };

  const deleteRole = (roleId) => {
    deleteOrgRole(roleId).catch((err) => {});

    toggle(roleId);

    setCustomRoles(customRoles.filter((role) => role._id !== roleId));
  };

  return (
    <Card>
      <CardHeader>
        <h3>Roles</h3>
      </CardHeader>
      <CardBody>
        <CardTitle>Base Roles</CardTitle>
        <Table no-wrap v-middle responsive className="no-wrap v-middle mb-5">
          <thead>
            <tr className="border-0">
              <th className="border-0">Name</th>
            </tr>
          </thead>
          <tbody>
            {baseRoles.map((role) => {
              const { _id, name } = role;

              return (
                <tr key={_id}>
                  <td>
                    <div className="d-flex no-block align-items-center">
                      {_upperCaseFirstLetter(name)}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex no-block align-items-center float-right">
                      <RoleView role={role} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="d-md-flex align-items-center">
          <div>
            <CardTitle>Custom Roles</CardTitle>
          </div>
          {/* <div className="ml-auto d-flex no-block align-items-center">
            <div className="dl">
              <RoleCreate
                user={user}
                organisation={currentOrganisation}
                setCustomRoles={setCustomRoles}
              />
            </div>
          </div> */}
        </div>
        {customRoles.length ? (
          <Table no-wrap v-middle responsive className="no-wrap v-middle">
            <thead>
              <tr className="border-0">
                <th className="border-0">Name</th>
              </tr>
            </thead>
            <tbody>
              {customRoles.map((role) => {
                const { _id, name } = role;

                return (
                  <tr key={_id}>
                    <td>
                      <div className="d-flex no-block align-items-center">
                        {_upperCaseFirstLetter(name)}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex no-block align-items-center float-right">
                        <div className="pr-2">
                          <RoleView role={role} />
                        </div>
                        <div className="pr-2">
                          <RoleModify
                            role={role}
                            setCustomRoles={setCustomRoles}
                          />
                        </div>
                        <Button onClick={() => toggle(_id)} color="danger">
                          <i className="fa fa-trash" />
                        </Button>
                        <Modal isOpen={modal[_id]} toggle={() => toggle(_id)}>
                          <ModalBody>
                            Are you sure you want to delete this role?
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="danger"
                              onClick={() => deleteRole(_id)}
                            >
                              Delete
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <CardSubtitle>
            There are currently no custom roles for this org
          </CardSubtitle>
        )}
      </CardBody>
    </Card>
  );
};

const _upperCaseFirstLetter = (item) =>
  item.charAt(0).toUpperCase() + item.slice(1);

export default RoleList;
