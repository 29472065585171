import React, { useEffect, useState, useContext } from 'react';
import {
  Row,
  Col,
  BreadcrumbItem,
  Breadcrumb,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';
import { DocumentList, DocumentUpload } from '../../components';
import { projectDocumentCategoryOptions } from '../../helpers';
import { useAddProjectDocument } from '../../hooks';

import ProjectContext from '../../contexts/projects/projects.context';

import { withProject } from '../../common';

const ProjectDocuments = ({ project }) => {
  const { setProject } = useContext(ProjectContext);
  const [isAddDocumentModelOpen, setDocumentModel] = useState(false);
  const [isDocumentUploadComplete, setDocumentUploadComplete] = useState(false);

  const toggleAddDocumentModel = () =>
    setDocumentModel(!isAddDocumentModelOpen);

  const [addProjectDocument, { loading }] = useAddProjectDocument({
    onCompleted: (document) => {
      project.documents
        ? project.documents.push(document)
        : (project.documents = [document]);
      setDocumentUploadComplete(true);
      setProject(project);
    },
  });

  const onDocumentUpload = (documentInput) => {
    setDocumentUploadComplete(false);
    addProjectDocument(project._id, documentInput);
  };

  useEffect(() => setProject(project), []);

  // Close model when upload is finishd
  useEffect(() => {
    if (isDocumentUploadComplete && isAddDocumentModelOpen)
      toggleAddDocumentModel();
  }, [isDocumentUploadComplete]);

  return (
    <>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/projects/${project._id}`}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Documents</BreadcrumbItem>
      </Breadcrumb>
      <Row>
        <Col sm="5">
          <Card>
            <CardHeader>
              <Row>
                <Col md="9">
                  <h5 className="mt-2">Project Documents</h5>
                </Col>
                <Col xs="12" md="3">
                  <Button
                    color="projx"
                    className="float-right mt-1"
                    onClick={toggleAddDocumentModel}
                  >
                    <i className="fas fa-plus" /> Upload
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DocumentList documents={project.documents} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DocumentUpload
        onDocumentUpload={onDocumentUpload}
        isOpen={isAddDocumentModelOpen}
        toggle={toggleAddDocumentModel}
        categoryOptions={projectDocumentCategoryOptions}
        loading={loading}
      />
    </>
  );
};

export default withProject(ProjectDocuments);
