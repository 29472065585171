import gql from 'graphql-tag';

/**
 * Queries
 */
const ROLES_BY_ORG_QUERY = gql`
  query ($organisationId: ID!) {
    getRolesByOrg(organisationId: $organisationId) {
      _id
      name
      permissions {
        name
      }
      inbuilt
      createdBy {
        _id
        name
      }
    }
  }
`;

const CREATE_ROLE_MUTATION = gql`
  mutation ($role: RoleInput!) {
    createRole(role: $role) {
      _id
      name
      permissions {
        name
        action
        subject
      }
      createdBy {
        _id
        name
      }
    }
  }
`;

const UPDATE_ROLE_MUTATION = gql`
  mutation ($_id: ID!, $role: UpdateRoleInput!) {
    updateRole(_id: $_id, role: $role) {
      _id
      name
      permissions {
        name
        action
        subject
      }
      createdBy {
        _id
        name
      }
    }
  }
`;

const DELETE_ROLE_MUTATION = gql`
  mutation ($_id: ID!) {
    deleteRole(_id: $_id) {
      _id
    }
  }
`;

const ADD_PERMISSIONS_MUTATION = gql`
  mutation ($_id: ID!, $permissions: [PermissionInput!]!) {
    addPermissions(_id: $_id, permissions: $permissions)
  }
`;

const REMOVE_PERMISSIONS_MUTATION = gql`
  mutation ($_id: ID!, $permissions: [PermissionInput!]!) {
    removePermissions(_id: $_id, permissions: $permissions)
  }
`;

export {
  ROLES_BY_ORG_QUERY,
  CREATE_ROLE_MUTATION,
  UPDATE_ROLE_MUTATION,
  DELETE_ROLE_MUTATION,
  ADD_PERMISSIONS_MUTATION,
  REMOVE_PERMISSIONS_MUTATION,
};
