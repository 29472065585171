import React, { useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Nav } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { usePanel } from '../../../hooks';

import {
  SidebarItemContainer,
  SidebarText,
  SidebarCreateProjectButton,
} from './styles/Sidebar';
import { OrganisationPortfolioIcon, PlusIcon } from './styles/SidebarIcons';

const Sidebar = (props) => {
  const { menu, resize } = usePanel();
  const [toggle, setToggle] = useState(true);
  const [toggleable, setToggleable] = useState(false);
  const createProject = () => {
    props.history.push('/project/new');
  };

  const expandLogo = () => {
    document.getElementById('logobg').classList.toggle('expand-logo');
    if (toggleable) setToggle(!toggle);
  };

  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? 'selected' : '';
  };

  // const collapsed = () => {
  //   const element = document.getElementById('main-wrapper');
  //   if (element.classList.contains('mini-sidebar')) {
  //     setToggleable(true);
  //   } else {
  //     setToggleable(false);
  //   }
  // };

  useEffect(() => {
    if (!resize) return;
    setToggle(resize === 'big');
    setToggleable(resize === 'small');
  }, [resize]);

  return (
    <div>
      <aside
        className="left-sidebar"
        id="sidebarbg"
        data-sidebarbg="skin5"
        onMouseEnter={expandLogo}
        onMouseLeave={expandLogo}
      >
        <div className="scroll-sidebar">
          <PerfectScrollbar className="sidebar-nav">
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar Menus will go here                                                */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav id="sidebarnav">
              <li className="selected sidebar-item" key="create_project">
                <span data-toggle="collapse" className="sidebar-link">
                  {/* <Button
                    className="btn"
                    color="projx"
                    size="lg"
                    block={toggle}
                    onClick={createProject}
                  >
                    {toggle ? 'Create Project' : '+'}
                  </Button> */}
                  <SidebarCreateProjectButton onClick={createProject} toggle={toggle}>
                    {toggle ? 'Create Project' : '+'}
                  </SidebarCreateProjectButton>
                </span>
              </li>

              <li
                className={`${activeRoute(`/portfolio`)} sidebar-item`}
                key="home"
              >
                <NavLink
                  to="/portfolio"
                  activeClassName="active"
                  className="sidebar-link"
                >
                  {/* <i className="mdi mdi-view-dashboard" /> */}
                  <SidebarItemContainer>
                    <OrganisationPortfolioIcon />
                    <SidebarText className="hide-menu">
                      Organisation Portfolio
                    </SidebarText>
                  </SidebarItemContainer>
                  {/* <span className="hide-menu">Organisation Portfolio</span> */}
                </NavLink>
              </li>

              {menu}

              {props.routes.map((prop, key) => {
                if (prop.redirect) {
                  return null;
                }
                if (prop.withParams) {
                  return null;
                }
                if (prop.navlabel) {
                  return (
                    <li key={key}>
                      <SidebarItemContainer break>
                        {prop.icon}
                        <SidebarText className="hide-menu">
                          {prop.name}
                        </SidebarText>
                      </SidebarItemContainer>
                      {/* <i className={prop.icon} />
                      <span className="hide-menu">{prop.name}</span> */}
                    </li>
                  );
                }

                if (prop.hide) return null;

                return (
                  <li
                    className={`${
                      activeRoute(prop.path) +
                      (prop.pro ? ' active active-pro' : '')
                    } sidebar-item`}
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="sidebar-link"
                      activeClassName="active"
                    >
                      <SidebarItemContainer>
                        {prop.icon}
                        <SidebarText className="hide-menu">
                          {prop.name}
                        </SidebarText>
                      </SidebarItemContainer>
                      {/* <i className={prop.icon} />
                      <span className="hide-menu">{prop.name}</span> */}
                    </NavLink>
                  </li>
                );
              })}
            </Nav>
          </PerfectScrollbar>
        </div>
      </aside>
    </div>
  );
};

export default withRouter(Sidebar);
