import React, { useState, useContext, forwardRef } from 'react';
import _ from 'lodash';
import { gql, useMutation } from '@apollo/client';
import { AddWidgetButtonStyles, AddWidgetButtonText } from './styles/AddWidgetButton';
import { AiOutlinePlus } from 'react-icons/ai';
import { Dropdown, Container, Row, Spinner } from 'react-bootstrap';
// Local Imports
import { widgetTitles } from '../../../constants';

// GraphQL Queries
const ADD_WIDGET_TO_DASHBOARD_TYPE = gql`
  mutation addWidget($projectId: ID!, $widgetType: WidgetType!, $dashboard: DashboardType!) {
    addWidget(projectId: $projectId, widgetType: $widgetType, dashboard: $dashboard) {
      code
      success
      message
      widgets {
        _id
        type
        data
        description
        dashboard
      }
    }
  }
`;

/**
 * Function which returns an array of widgets which we can add (i.e. widgets which do not exist yet)
 * @param {Array} widgets An array of our widgets pulled from 'WidgetContext' 
 * @returns An array of strings which represent which widgets to add
 */
  const filterWidgets = (widgets) => {
  return _.filter(Object.keys(widgetTitles), (widgetType) => {
    return (_.find(widgets, (w) => {
      return w.type === widgetType;
    })) ? false : true; 
  });
}

/**
 * 
 * @param {boolean} inEditMode Only render this button if inEditMode is true
 * @param {any[]} widgets Array of objects storing our widgets and the data associated with each one
 * @returns 
 */
const AddWidgetButton = ({ inEditMode, widgets, setWidgets, projectId, dashboard }) => {
  // TODO: Add loading state spinner on addWidget button
  const [isLoading, setIsLoading] = useState(false);

  const [
    addWidget,
    {
      data: dataAddWidget,
      loading: loadingAddWidget,
      error: errorAddWidget,
    },
  ] = useMutation(ADD_WIDGET_TO_DASHBOARD_TYPE, {
    onCompleted: (data) => {
      setWidgets(data.addWidget.widgets);
    }
  });

  // This is required in order for us to have a custom button to trigger a dropdown
  const CustomAddWidgetToggle = forwardRef(({ children, onClick }, ref) => (
    <AddWidgetButtonStyles
      inEditMode={inEditMode}
      ref={ref}
      href=""
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </AddWidgetButtonStyles>
  ));
  
  const widgetsToAdd = filterWidgets(widgets);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Dropdown>
          <Dropdown.Toggle as={CustomAddWidgetToggle} id="dropdown-custom-components">
            {isLoading
            ? <Spinner animation="border" size="sm" />
            : 
              <>
                <AiOutlinePlus />
                <AddWidgetButtonText>Add Widget</AddWidgetButtonText>
              </>
            }
          </Dropdown.Toggle>
          {!isLoading && 
            <Dropdown.Menu>
              {
                widgetsToAdd.length === 0 
                ?
                  <Dropdown.Item disabled>
                    All available widgets already added !
                  </Dropdown.Item>
                : 
                  _.map(widgetsToAdd, (widgetType, index) => (
                    <Dropdown.Item eventKey={index} key={index} onClick={() => addWidget({
                      variables: {
                        projectId,
                        widgetType,
                        dashboard, 
                      }
                    })}>
                      {widgetTitles[widgetType]}
                    </Dropdown.Item>
                  ))
                }
            </Dropdown.Menu>
          }
        </Dropdown>
      </Row>
    </Container>
    // <AddWidgetButtonStyles inEditMode={inEditMode}>
    //   <AiOutlinePlus />
    //   <AddWidgetButtonText>Add Widget</AddWidgetButtonText>
    // </AddWidgetButtonStyles>
  );
}

export default AddWidgetButton;