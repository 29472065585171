import { gql } from '@apollo/client';

// Get all our widgets associated with a project and render them on the dashboard
const GET_WIDGET_TYPES_BY_PROJECT = gql`
  query getWidgetsByProject($projectId: ID!) {
    getWidgets(projectId: $projectId) {
      _id
      type
    }
  }
`;

// Get data for a particular widget under a project
const GET_WIDGET_DATA_BY_PROJECT = gql`
  query getWidgetDataByProject($projectId: ID!, $widgetType: WidgetType!) {
    getWidget(projectId: $projectId, widgetType: $widgetType) {
      data
    }
  }
`

// Add a widget to the dashboard of a particular project
const ADD_WIDGET_TO_PROJECT = gql`
  mutation addWidgetToProject($projectId: ID!, $widgetType: WidgetType!) {
    addWidget(projectId: $projectId, widgetType: $widgetType) {
      code
      success
      message
      widgets {
        _id
        type
      }
    }
  }
`;

// Remove a widget from a dashboard for a particular project
const REMOVE_WIDGET_FROM_PROJECT = gql`
  mutation removeWidgetFromProject($projectId: ID!, $widgetType: WidgetType!) {
    removeWidget(projectId: $projectId, widgetType: $widgetType) {
      code
      success
      message
      widgets {
        _id
        type  
      }
    }
  }
`;

// Update the description text of a widget which already exists under a particular project
const UPDATE_WIDGET_DESCRIPTION = gql`
  mutation updateWidgetDescriptionForProject($projectId: ID!, $widgetType: WidgetType!, $widgetText: String!) {
    updateWidgetText(projectId: $projectId, widgetType: $widgetType, widgetText: $widgetText) {
      code
      success
      message
      widgets {
        _id
        type
        data
        description
      }
    }
  }
`;

const GET_STATUS_CARD_DATA_BY_PROJECT = gql`
  query getStatusCardDataForProject($projectId: ID!) {
    getStatusCardData(projectId: $projectId)
  }
`;

export {
  GET_WIDGET_TYPES_BY_PROJECT,
  GET_WIDGET_DATA_BY_PROJECT,
  ADD_WIDGET_TO_PROJECT,
  REMOVE_WIDGET_FROM_PROJECT,
  UPDATE_WIDGET_DESCRIPTION,
  GET_STATUS_CARD_DATA_BY_PROJECT
}
