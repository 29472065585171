import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import AuthContext from '../../contexts/auth/auth.context';
import { validateForm } from '../../helpers';

const EditPassword = () => {
  const { changePassword } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [shouldShowMismatchError, setShouldShowMismatchError] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState('');
  const [passwordFormatErrors, setPasswordFormatErrors] = useState([]);
  const [inputData, setInputData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleInputChange = (event) => {
    setInputData({
      ...inputData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    // only enable button when user has filled all inputs
    if (
      inputData.currentPassword.length > 0 &&
      inputData.newPassword.length > 0 &&
      inputData.confirmPassword.length > 0
    ) {
      setIsDisabledButton(false);
    } else {
      setIsDisabledButton(true);
    }

    if (inputData.newPassword.length > 0)
      setPasswordFormatErrors(validateForm('password', inputData.newPassword));
  }, [inputData]);

  const saveHandler = () => {
    setChangePasswordError('');

    if (inputData.newPassword === inputData.confirmPassword) {
      setShouldShowMismatchError(false);
      setLoading(true);

      changePassword(inputData.currentPassword, inputData.newPassword)
        .then(() => {
          setLoading(false);
          // disable button after updating password as a way of letting user know request has been complete
          setIsDisabledButton(true);
        })
        .catch((error) => {
          setLoading(false);

          if (error) setChangePasswordError(error);
          else
            setChangePasswordError(
              'Something went wrong. Please try again another time',
            );
        });
    } else {
      setShouldShowMismatchError(true);
    }
  };

  return (
    <Card>
      <CardBody>
        <Form>
          <FormGroup>
            <Label>Current Password</Label>
            <Input
              id="currentPassword"
              name="currentPassword"
              type="password"
              onChange={handleInputChange}
              value={inputData.currentPassword}
            />
          </FormGroup>
          <FormGroup>
            <Label>New Password</Label>
            <Input
              id="newPassword"
              name="newPassword"
              type="password"
              onChange={handleInputChange}
              value={inputData.newPassword}
            />
          </FormGroup>
          {passwordFormatErrors.length > 0 &&
            passwordFormatErrors.map((info, index) => {
              return (
                <span className="error mb-2" key={index}>
                  {`* ${info}`}
                  <br />
                </span>
              );
            })}
          <FormGroup>
            <Label>Confirm New Password</Label>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              onChange={handleInputChange}
              value={inputData.confirmPassword}
            />
          </FormGroup>
          {shouldShowMismatchError && (
            <div className="error mb-2">* New password does not match.</div>
          )}
          {changePasswordError.length > 0 && (
            <div className="error mb-2">{`* ${changePasswordError}`}</div>
          )}
          <Button
            color="success"
            onClick={saveHandler}
            disabled={isDisabledButton}
          >
            {loading ? 'Loading' : 'Save'}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};

export default EditPassword;
