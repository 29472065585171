import styled, { css } from 'styled-components';

export const FundingActivityCardsContainer = styled.div`
  height: 320px;
  overflow: auto;
`;

export const FundingActivityCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-bottom: 8px;
`;

export const FundingActivityCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FundingActivityCardTimestamp = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-color: #616161;
  font-weight: 300;
`;

export const FundingActivityCardAmount = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin: 4px 0;
  
  // When we have a deposit funding account event
  ${props => props.type === "DEPOSIT" && css`
    color: #00875A;
  `}

  // When we have a withdraw funding account event
  ${props => props.type === "WITHDRAW" && css`
    color: #E03131;
  `}
`;

export const FundingActivityCardDescription = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  font-weight: 400;
`;