import React from 'react';
import { Button } from 'reactstrap';

const VendorBranchContact = ({
  contact,
  contactIndex,
  removeContact = undefined,
}) => {
  const name = <h6 className="font-medium">{contact.name}</h6>;
  const details = (
    <span className="mb-3 d-block">
      <b>e:</b> {contact.email}, <b>p:</b> {contact.phone}
    </span>
  );
  const buttons = (
    <div className="float-right">
      <Button
        onClick={() => removeContact(contactIndex)}
        className="btn-sm"
        color="projx"
      >
        <i className="fa fa-trash" />
      </Button>
    </div>
  );
  return (
    <div className="d-flex flex-row comment-row mt-1 border-bottom">
      <div className="comment-text w-100">
        {name}
        {details}
        {!removeContact ? '' : <div className="comment-footer">{buttons}</div>}
      </div>
    </div>
  );
};

export default VendorBranchContact;
