import styled from 'styled-components';

export const SafetyPeriodPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  margin-left: 16px;
`;

export const SafetyPeriodPageHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0 0 0;
`;

export const SafetyPeriodPageHeading = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-left: 8px;
  margin: 0;
  letter-spacing: 1px;
`;

export const SafetyPeriodPageCardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0px 16px;
  margin: 16px 0;
  font-family: 'Inter', sans-serif;
  color: #F9F9F9;

  @media screen and (min-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const SafetyPeriodPageCardsColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SafetyPeriodPageCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  color: #1B1B1B;
  background-color: #F9F9F9;
  border: 1px solid #D9D9D9;
  margin: 8px 0;
`;

export const SafetyPeriodPageCardHeader = styled.p`
  display: flex;
  flex-direction: row
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin: 8px 0 16px 0;
`;

export const SafetyPeriodPageCardLineItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`;

export const SafetyPeriodPageCardLineItemText = styled.p`
  display: flex;
  flex-direction: row
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;


export const SafetyPeriodPageCardLineItemNumber = styled.p`
  display: flex;
  flex-direction: row
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

export const SafetyPeriodPageErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
`;

export const SafetyPeriodPageErrorContainerText = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
`;
