import React, { useState, useContext } from 'react';
import _ from 'lodash';
import { gql, useMutation } from '@apollo/client';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Switch from '@mui/material/Switch';
//
import { Button, IconButton, TagsInput } from '../../../components';
import { ProgressDiaryContext } from '../../../contexts';
import {
  HeaderContainer,
  HeaderFrame,
  ProgressDiaryTableIcon,
  Heading,
  FilterIcon,  
} from './styles/Header';
import {
  InputContainer,
  InputHeader,
  FooterContainer,
  DatePickerStyled,
  TextAreaStyled,
} from './styles/Modal';

// GraphQL Mutations
const CREATE_PROGRESS_DIARY_DOCUMENT = gql`
  mutation createProgressDiaryDocument($projectId: ID!, $input: ProgressDiaryDocumentInput!) {
    createProgressDiaryDocument(projectId: $projectId, input: $input) {
      code
      success
      message
      documents {
        _id
        event
        date
        tags
        reporting
      }
    }
  }
`;

/**
 * This is the Header for our progress diary component, containing the heading and the button which pops the modal for adding
 * a progress diary event
 * @param {string} projectId The ID of the project on which we are looking at the progress diary
 * @param {React.SetStateAction} setTableData setState function to update the data of our table after we create a new progress diary item
 * @returns Header component for the Progress Diary module
 */
const ProgressDiaryHeader = ({ projectId, setTableData }) => {
  // For showing/hiding our modal
  const [showAddProgressDiaryEventModal, setShowAddProgressDiaryEventModal] = useState(false);
  // For our date in the progress diary event creation modal
  const [eventDate, setEventDate] = useState(new Date());
  // State to keep track of our text describing the progress diary event creation modal
  const [eventText, setEventText] = useState('');
  // States related to our tags in our event creation modal
  const [eventTags, setEventTags] = useState([]);
  // State related to our switch indicating whether we report the new progress diary event.
  const [reportingSwitchChecked, setReportingSwitchChecked] = useState(true);

  const { filterAccordionOpen, setFilterAccordionOpen } = useContext(ProgressDiaryContext);

  const toggleAddProgressDiaryEventModal = () => {
    setShowAddProgressDiaryEventModal(() => !showAddProgressDiaryEventModal);
  };

  const handleReportingSwitchChange = (e) => {
    setReportingSwitchChecked(e.target.checked);
  }

  const [createProgressDiaryDocument, {
    loading: loadingCreateProgressDiaryDoc,
    error: errorCreateProgressDiaryDoc,
    data: dataCreateProgressDiaryDoc
  }] = useMutation(CREATE_PROGRESS_DIARY_DOCUMENT, {
    onCompleted: (data) => {
      setTableData(data.createProgressDiaryDocument.documents);
    }
  });

  const addEventButtonDisabled = eventText === '' || eventDate === null || eventTags.length === 0;

  // We click the button to create a new progress diary event
  const handleAddEventButtonClick = async (e) => {
    setShowAddProgressDiaryEventModal(false);
    await createProgressDiaryDocument({
      variables: {
        projectId,
        input: {
          event: eventText,
          date: eventDate.toISOString(),
          tags: eventTags,
          reporting: reportingSwitchChecked,
        }
      }
    });
    // Reset our state values
    setEventDate(new Date());
    setEventText('');
    setEventTags([]);
    setReportingSwitchChecked(true);
  }

  return (
    <HeaderContainer>
      <HeaderFrame>
        <ProgressDiaryTableIcon />
        <Heading>Progress Diary Table</Heading>
      </HeaderFrame>
      <HeaderFrame>
        <IconButton onClick={() => setFilterAccordionOpen(() => !filterAccordionOpen)} text="Filter">
          <FilterIcon />
        </IconButton>
        <Button onClick={toggleAddProgressDiaryEventModal}>Add Event</Button>
      </HeaderFrame>
      <Modal
        isOpen={showAddProgressDiaryEventModal}
        toggle={toggleAddProgressDiaryEventModal}
        backdrop
      >
        <ModalHeader toggle={toggleAddProgressDiaryEventModal}>
          <InputHeader>Add a Progress Diary Item</InputHeader>
        </ModalHeader>
        <ModalBody>
          <InputContainer>
            <InputHeader>Select a date (DD-MM-YYYY):</InputHeader>
            <DatePickerStyled
              dateFormat="dd/MM/yyyy"
              selected={eventDate}
              onChange={(date) => setEventDate(date)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>Event Details:</InputHeader>
            <TextAreaStyled value={eventText} onChange={(e) => setEventText(e.target.value)} placeholder="Describe your event" />
          </InputContainer>
          <InputContainer>
            <InputHeader>Tags (max. 5):</InputHeader>
            <TagsInput tags={eventTags} setTags={setEventTags} max={5} />
          </InputContainer>
          <InputContainer horizontal>
            <InputHeader>Reporting:</InputHeader>
            <Switch
              checked={reportingSwitchChecked}
              onChange={handleReportingSwitchChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </InputContainer>
          <FooterContainer>
            <Button onClick={handleAddEventButtonClick} disabled={addEventButtonDisabled}>
              Add
            </Button>
          </FooterContainer>
        </ModalBody>
      </Modal>
    </HeaderContainer>
  );
};

export default ProgressDiaryHeader;
