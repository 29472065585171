import React from 'react';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Input,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';

class EmailCampaign extends React.Component {
  render() {
    return (
      /*--------------------------------------------------------------------------------*/
      /* Used In Dashboard-1 [Classic]                                                  */
      /*--------------------------------------------------------------------------------*/
      <Card className="card-hover">
        <CardBody>
          <div className="d-md-flex align-items-center">
            <div>
              <CardTitle>Construction Budget</CardTitle>
              <CardSubtitle>Overview of Budget Breakdown</CardSubtitle>
            </div>
            <div className="ml-auto align-items-center">
              <div className="dl">
                <Input type="select" className="custom-select">
                  <option value="0">Monthly</option>
                  <option value="1">Daily</option>
                  <option value="2">Weekly</option>
                  <option value="3">Yearly</option>
                </Input>
              </div>
            </div>
          </div>
          <Row className="mt-4">
            <Col lg="4">
              <div>
                <Chart
                  style={{ height: '290px', width: '100%' }}
                  config={{
                    data: {
                      columns: [
                        ['used', 375000],
                        ['difference', 2125000],
                      ],

                      type: 'donut',
                      tooltip: {
                        show: true,
                      },
                    },
                    donut: {
                      label: {
                        show: false,
                      },
                      title: '$2,500,000 total',
                      width: 35,
                    },
                    legend: {
                      hide: true,
                    },
                    color: {
                      pattern: ['#40c4ff', '#2961ff'],
                    },
                  }}
                />
              </div>
            </Col>
            <Col lg="2">
              <ListGroup>
                <ListGroupItem className="border-0 mt-3 p-0">
                  <i className="fas fa-circle mr-1 text-cyan font-12"></i> Funds
                  Used <span className="float-right">15%</span>
                </ListGroupItem>
                <ListGroupItem className="border-0 mt-3 p-0">
                  <i className="fas fa-circle mr-1 text-info font-12"></i>{' '}
                  Difference Remaining <span className="float-right">85%</span>
                </ListGroupItem>
              </ListGroup>
            </Col>
            <Col lg="4">
              <div>
                <Chart
                  style={{ height: '290px', width: '100%' }}
                  config={{
                    data: {
                      columns: [
                        ['C000: Construction Cost', 375000],
                        ['B000: Design Consultant Costs', 125000],
                        ['D000: Other Project Costs', 37555],
                        ['A000: Development Costs', 265000],
                      ],

                      type: 'donut',
                      tooltip: {
                        show: true,
                      },
                    },
                    donut: {
                      label: {
                        show: false,
                      },
                      title: '$802,555 total',
                      width: 35,
                    },
                    legend: {
                      hide: true,
                    },
                    color: {
                      pattern: ['#40d2f7', '#77fc03', '#0373fc', '#f74040'],
                    },
                  }}
                />
              </div>
            </Col>
            <Col lg="2">
              <ListGroup>
                <ListGroupItem className="border-0 mt-3 p-0">
                  <i className="fas fa-circle mr-1 text-cyan font-12"></i> C000:
                  Construction Cost <span className="float-right">46%</span>
                </ListGroupItem>
                <ListGroupItem className="border-0 mt-3 p-0">
                  <i className="fas fa-circle mr-1 text-success font-12"></i>{' '}
                  B000: Design Consultant Costs{' '}
                  <span className="float-right">15%</span>
                </ListGroupItem>
                <ListGroupItem className="border-0 mt-3 p-0">
                  <i className="fas fa-circle mr-1 text-info font-12"></i> D000:
                  Other Project Costs <span className="float-right">5%</span>
                </ListGroupItem>
                <ListGroupItem className="border-0 mt-3 p-0">
                  <i className="fas fa-circle mr-1 text-warning font-12"></i>{' '}
                  A000: Development Costs{' '}
                  <span className="float-right">34%</span>
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default EmailCampaign;
