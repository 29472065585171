import React from 'react';

import { Button } from 'reactstrap';
import { useDocumentDownload } from '../../hooks';

const DocumentDownloadButton = ({ document }) => {
  const [downloadDocument, { loading }] = useDocumentDownload();

  return (
    <Button
      color="projx"
      onClick={() => downloadDocument(document)}
      disabled={loading}
    >
      <i className="fas fa-download" />
    </Button>
  );
};

export default DocumentDownloadButton;
