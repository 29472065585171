import { useQuery } from '@apollo/client';

import { ORGANISATION_MEMBERS_QUERY } from '../../graphql/queries';
import { cleanGqlTypename } from '../../common';

const useOrganisationMembers = (_id) => {
  const { loading, error, data } = useQuery(ORGANISATION_MEMBERS_QUERY, {
    variables: { _id },
  });

  const organisationData =
    !loading && data
      ? cleanGqlTypename(data.getOrganisation)
      : { owner: {}, members: [] };

  return {
    loading,
    error,
    data: organisationData,
  };
};

export { useOrganisationMembers };
