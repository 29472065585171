import React, { Component } from 'react';
import StepZilla from 'react-stepzilla';
import { v4 } from 'uuid';
import { Card, CardBody, CardTitle } from 'reactstrap';

import {
  WelcomeStep,
  DetailsStep,
  InviteStep,
  ConfirmStep,
  CostStep,
} from '../../components';

import GlobalReferenceContext from '../../contexts/global/globalReference.context';
import { slugify } from '../../common/utils';

class ProjectSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.projectStore = {
      key: v4(),
      slug: '',
      logo: 'https://projxstatic.s3-ap-southeast-2.amazonaws.com/default_company.png',
      project: {
        name: '',
        address: '',
        code: '',
        costCode: '',
        sectors: [],
      },
      client: {
        name: '',
        contract: '',
        abn: '',
      },
      invitees: [],
      totalBudget: 0,
      savedToCloud: false,
      prepare: () => {
        /**
         * slugify
         */
        this.projectStore.slug = slugify(this.projectStore.project.name);
      },
    };

    this.buildContext = this.buildContext.bind(this);
    this.onBudgetCostChange = this.onBudgetCostChange.bind(this);
  }

  onBudgetCostChange(update) {
    this.projectStore.budgetCosts = {
      // ...this.projectStore.budgetCosts,
      ...update,
    };
  }

  getStore() {
    return this.projectStore;
  }

  updateStore(update) {
    this.projectStore = {
      ...this.projectStore,
      ...update,
    };
  }

  buildContext(context) {
    const steps = [
      {
        name: 'Project',
        component: (
          <WelcomeStep
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: 'Principal',
        component: (
          <DetailsStep
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: 'Project Estimate',
        component: (
          <CostStep
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: 'Invite',
        component: (
          <InviteStep
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: 'Confirm',
        component: (
          <ConfirmStep
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
    ];

    return steps;
  }

  render() {
    return (
      <GlobalReferenceContext.Consumer>
        {(context) => (
          <Card>
            <CardBody className="border-bottom">
              <CardTitle className="mb-0">
                <i className="far fa-building mr-2" />
                New Project
              </CardTitle>
              <h6 className="card-subtitle mb-0 mt-1">
                Setup a new project by filling out required and optional steps
                ...
              </h6>
            </CardBody>
            <CardBody>
              <div className="project-steps">
                <div className="step-progress">
                  <StepZilla
                    steps={this.buildContext(context)}
                    nextTextOnFinalActionStep="Next"
                    preventEnterSubmission
                    // specify the next button class (if not given it defaults to "btn btn-prev btn-primary btn-lg" which depends on bootstrap)
                    nextButtonCls="btn btn-prev btn-projx btn-lg pull-right"
                    // specify the back button text (if not given it default to "btn btn-next btn-primary btn-lg")
                    backButtonCls="btn btn-next btn-projx btn-lg pull-left"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        )}
      </GlobalReferenceContext.Consumer>
    );
  }
}

export default ProjectSteps;
