import { useLazyQuery, useQuery } from '@apollo/client';
import _ from 'lodash';

import {
  PROJECTS_BY_ORG_QUERY,
  PROJECT_BY_ID_QUERY,
  PROJECT_BY_SLUG_QUERY,
} from '../../graphql/queries';

import { cleanGqlTypename } from '../../common';

const itemMapper = (item) => cleanGqlTypename(item);

const mapper = (data) => {
  return _.map(data, (item) => itemMapper(item));
};

const useProjectBySlug = (slug) => {
  const { loading, error, data, refetch } = useQuery(PROJECT_BY_SLUG_QUERY, {
    variables: { slug },
  });

  let project = !loading && data ? data.getProjectBySlug : {};
  project = itemMapper(project);

  return {
    projectBySlugLoading: loading,
    error,
    project,
    refetchProjectsBySlug: refetch,
  };
};

const useProjectById = (id) => {
  // const { setProjectContextId } = useContext(AuthContext);
  const { loading, error, data, refetch } = useQuery(PROJECT_BY_ID_QUERY, {
    variables: { _id: id },
  });

  let project = !loading && data ? data.getProject : {};
  project = itemMapper(project);

  // useEffect(() => {
  //   setProjectContextId(id);
  // },[id])

  return {
    projectByIdLoading: loading,
    error,
    project,
    refetchProjectsById: refetch,
  };
};

const useQueryProjectsByOrg = () => {
  const [_queryProjectByOrg, { loading, error, data }] = useLazyQuery(
    PROJECTS_BY_ORG_QUERY,
  );

  const queryProjectByOrg = (orgId) => {
    _queryProjectByOrg({ variables: { organisation: orgId } });
  };

  let projects = !loading && data ? data.getProjectsByOrg : [];
  projects = mapper(projects);

  return {
    queryProjectByOrg,
    projectsByOrgLoading: loading,
    error,
    projects,
  };
};

export { useProjectBySlug, useQueryProjectsByOrg, useProjectById };
