import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useAlert } from 'react-alert';
import {
  BudgetContractTable,
  BudgetProvisionalTable,
  BudgetVariationTable,
  BudgetType,
  ProcessClaims,
  Invoices,
} from './types';
import { BudgetToolbar, ToolbarType } from './Toolbar';
import { useUpdateFocotr } from '../../../hooks/graphql/useFocotr';
import { copyArrayReference, currencify } from '../../../common';
import { BudgetConfigContext } from '../../../contexts';

import Collapsable from '../../shared/Collapsable';

const Statistics = ({
  id,
  title,
  amount,
  color,
  icon,
  tooltip = undefined,
  precision = 0,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState({ tt: false });

  const toggle = (type) =>
    setTooltipOpen((val) => ({ ...val, [type]: !tooltipOpen[type] }));

  const iconElement = (
    <span className={`display-5 text-${color}`}>
      <i className={`mdi mdi-${icon}`} />
    </span>
  );

  const titleElement = <span>{title}</span>;
  const amountElement = (
    <h4 className={`font-medium mb-0 mt-0${amount < 0 ? ' in-minus' : ''}`}>
      {amount < 0
        ? `(${currencify(amount, true, precision)})`
        : amount === 0
        ? precision === 2
          ? '0.00'
          : '0'
        : currencify(amount, true, precision)}
    </h4>
  );
  return (
    <>
      <div className="d-flex align-items-center" id={`divStatistic_${id}`}>
        <div className="mr-2">{iconElement}</div>
        <div>
          {titleElement}
          {amountElement}
        </div>
        {!tooltip ? (
          ''
        ) : (
          <Tooltip
            placement="top"
            isOpen={tooltipOpen.tt}
            target={`divStatistic_${id}`}
            toggle={() => toggle('tt')}
          >
            {tooltip}
          </Tooltip>
        )}
      </div>
    </>
  );
};

const BudgetEntry = ({
  project,
  item,
  onFocotrDeleted = () => {},
  onUpdated = () => {},
  costCode,
  onTotals = () => {},
}) => {
  const [claimsOpen, setClaimsOpen] = useState(false);
  const [invoicesOpen, setInvoicesOpen] = useState(false);
  const [requestForItem, setRequestForItem] = useState();
  const [validation, setValidation] = useState({});
  const [view, setView] = useState('detailed');

  const { config, handleChangeConfig } = useContext(BudgetConfigContext);

  const [contTotals, setContTotals] = useState({
    adjustments: 0,
    contracted: 0,
    ffc: 0,
    thisClaim: 0,
    forecasts: 0,
    expenditure: {
      allowed: 0,
      previous: 0,
      current: 0,
      thisClaim: 0,
      remaining: 0,
    },
    count: 0,
    approved: 0,
    costStats: {
      contracted: 0,
      anticipated: 0,
      submitted: 0,
      assessed: 0,
      unknown: 0,
    },
  });
  const [psTotals, setPsTotals] = useState({
    adjustments: 0,
    contracted: 0,
    ffc: 0,
    thisClaim: 0,
    forecasts: 0,
    expenditure: {
      allowed: 0,
      previous: 0,
      current: 0,
      thisClaim: 0,
      remaining: 0,
    },
    count: 0,
    approved: 0,
    costStats: {
      contracted: 0,
      anticipated: 0,
      submitted: 0,
      assessed: 0,
      unknown: 0,
    },
  });
  const [varTotals, setVarTotals] = useState({
    adjustments: 0,
    contracted: 0,
    ffc: 0,
    thisClaim: 0,
    forecasts: 0,
    expenditure: {
      allowed: 0,
      previous: 0,
      current: 0,
      thisClaim: 0,
      remaining: 0,
    },
    count: 0,
    approved: 0,
    costStats: {
      contracted: 0,
      anticipated: 0,
      submitted: 0,
      assessed: 0,
      unknown: 0,
    },
  });
  const [totals, setTotals] = useState({
    adjustments: 0,
    contracted: 0,
    ffc: 0,
    thisClaim: 0,
    forecasts: 0,
    expenditure: {
      allowed: 0,
      previous: 0,
      current: 0,
      thisClaim: 0,
      remaining: 1,
    },
    costStats: {
      contracted: 0,
      anticipated: 0,
      submitted: 0,
      assessed: 0,
      unknown: 0,
    },
    stats: {
      count: 0,
      approved: 0,
      unapproved: 0,
      contracted: 0,
      provisional: 0,
      variation: 0,
    },
  });

  const [provisionals, setProvisionals] = useState([]);
  const [variations, setVariations] = useState([]);
  const [showLock, setShowLock] = useState(false);
  const [claim, setClaim] = useState(undefined);
  const alert = useAlert();

  const [updateFocotr] = useUpdateFocotr({
    onCompleted: (data) => {
      onUpdated(data);
      setShowLock(false);
    },
  });

  const toggleLock = () => setShowLock(!showLock);

  const lockContract = () => {
    updateFocotr(item._id, {
      costCode: item.costCode,
      project: item.project._id,
      vendor: item.vendor._id,
      locked: !item.locked,
      accountType: item.accountType,
    });
  };

  const validated = (type) => {
    if (validation[type] && validation[type].length > 0 && !item.locked) {
      validation[type].forEach((v) => alert.error(v.message));
      return false;
    }
    return true;
  };

  const onValidate = (v) => setValidation((val) => ({ ...val, ...v }));

  const onProvisionalsChange = (provisionals) => {
    setProvisionals(provisionals);
  };

  const onVariationsChange = (variations) => {
    setVariations(variations);
  };

  const onToolbarItemSelected = ({ type, value }) => {
    switch (type) {
      case ToolbarType.ProcessClaims:
        setView('claims');
        break;
      case ToolbarType.NewBudget:
        setRequestForItem({ type, value });
        break;
      case ToolbarType.ColumnView:
        setView(value);
        break;
      case ToolbarType.ClaimsView:
        setView(value);
        break;
      case ToolbarType.PreviousClaimsView:
        toggleInvoices();
        break;
      case ToolbarType.DeletedFocotr:
        onFocotrDeleted(value);
        break;
      case ToolbarType.Lock:
        if (item.accountType === 'bills') {
          alert.error('Can not lock an bills account');
          return;
        }
        if (!validated(BudgetType.Contract)) return;
        if (!validated(BudgetType.Provisional)) return;
        if (item.locked)
          return updateFocotr(item._id, {
            costCode: item.costCode,
            project: item.project._id,
            vendor: item.vendor._id,
            locked: !item.locked,
            accountType: item.accountType,
          });
        toggleLock();
        break;
      default:
        break;
    }
  };

  const toggleClaims = () => setClaimsOpen(!claimsOpen);

  const toggleInvoices = () => setInvoicesOpen(!invoicesOpen);

  const onClaimsProcessingCompleted = (processedClaim) => {
    setClaim(processedClaim.invoiceId);
  };

  useEffect(() => {
    const clone = copyArrayReference(totals);
    clone.ffc = contTotals.ffc + psTotals.ffc + varTotals.ffc;
    clone.contracted =
      contTotals.contracted + psTotals.contracted + varTotals.contracted;
    clone.adjustments =
      contTotals.adjustments + psTotals.adjustments + varTotals.adjustments;
    clone.thisClaim =
      contTotals.thisClaim + psTotals.thisClaim + varTotals.thisClaim;
    clone.forecasts =
      contTotals.forecasts + psTotals.forecasts + varTotals.forecasts;

    clone.expenditure.allowed =
      contTotals.expenditure.allowed +
      psTotals.expenditure.allowed +
      varTotals.expenditure.allowed;
    clone.expenditure.previous =
      contTotals.expenditure.previous +
      psTotals.expenditure.previous +
      varTotals.expenditure.previous;
    clone.expenditure.current =
      contTotals.expenditure.current +
      psTotals.expenditure.current +
      varTotals.expenditure.current;
    clone.expenditure.thisClaim =
      contTotals.expenditure.thisClaim +
      psTotals.expenditure.thisClaim +
      varTotals.expenditure.thisClaim;
    clone.expenditure.remaining =
      contTotals.expenditure.remaining +
      psTotals.expenditure.remaining +
      varTotals.expenditure.remaining;

    clone.stats.count = contTotals.count + psTotals.count + varTotals.count;
    clone.stats.approved =
      contTotals.approved + psTotals.approved + varTotals.approved;
    clone.stats.contracted = contTotals.count;
    clone.stats.provisional = psTotals.count;
    clone.stats.variation = varTotals.count;

    clone.stats.ffcPercentages = {
      contracts: contTotals.ffc,
      provisionals: psTotals.ffc,
      variations: varTotals.ffc,
    };

    clone.costStats.contracted =
      contTotals.costStats.contracted +
      psTotals.costStats.contracted +
      varTotals.costStats.contracted;
    clone.costStats.anticipated =
      contTotals.costStats.anticipated +
      psTotals.costStats.anticipated +
      varTotals.costStats.anticipated;
    clone.costStats.submitted =
      contTotals.costStats.submitted +
      psTotals.costStats.submitted +
      varTotals.costStats.submitted;
    clone.costStats.assessed =
      contTotals.costStats.assessed +
      psTotals.costStats.assessed +
      varTotals.costStats.assessed;
    clone.costStats.unknown =
      contTotals.costStats.unknown +
      psTotals.costStats.unknown +
      varTotals.costStats.unknown;

    setTotals(clone);

    onTotals({
      focotr: item._id,
      totals: clone,
    });
  }, [contTotals, psTotals, varTotals]);

  const BlueSwitch = withStyles({
    switchBase: {
      color: blue[500],
      '&$checked': {
        color: blue[500],
      },
      '&$checked + $track': {
        backgroundColor: blue[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <>
      <div className="step step1 mt-2">
        <div className="row justify-content-md-center">
          <div className="col">
            <Card>
              <CardBody className="border-top">
                <BudgetToolbar
                  project={project}
                  onItemSelected={onToolbarItemSelected}
                  details={item}
                  totals={totals}
                  variations={variations}
                  provisionals={provisionals}
                />
              </CardBody>
              <CardBody className="py-0">
                <Collapsable open={false} title="Configurations">
                  <Card>
                    <CardBody className="p-0">
                      <Row>
                        <Col xs="12">
                          <FormControlLabel
                            control={
                              <BlueSwitch
                                size="small"
                                checked={config.decimal}
                                onChange={(e) =>
                                  handleChangeConfig({
                                    name: e.target.name,
                                    state: e.target.checked,
                                  })
                                }
                                name="decimal"
                              />
                            }
                            label="Show Decimals"
                            labelPlacement="start"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapsable>
              </CardBody>
              <CardBody className="border-top mb-1">
                {item.accountType === 'bills' ? (
                  <BudgetContractTable
                    view={view}
                    details={item}
                    requestForItem={requestForItem}
                    onValidate={onValidate}
                    onTotal={(data) => setContTotals(data.totals)}
                    costCode={costCode}
                    claim={claim}
                    createNewLineItem={onToolbarItemSelected}
                  />
                ) : (
                  <>
                    <BudgetContractTable
                      view={view}
                      details={item}
                      requestForItem={requestForItem}
                      onValidate={onValidate}
                      onTotal={(data) => setContTotals(data.totals)}
                      costCode={costCode}
                      claim={claim}
                      createNewLineItem={onToolbarItemSelected}
                    />
                    <BudgetProvisionalTable
                      project={project}
                      view={view}
                      details={item}
                      requestForItem={requestForItem}
                      onValidate={onValidate}
                      onChange={onProvisionalsChange}
                      onTotal={(data) => setPsTotals(data.totals)}
                      costCode={costCode}
                      claim={claim}
                      createNewLineItem={onToolbarItemSelected}
                    />
                    <BudgetVariationTable
                      project={project}
                      view={view}
                      details={item}
                      requestForItem={requestForItem}
                      onValidate={onValidate}
                      onChange={onVariationsChange}
                      onTotal={(data) => setVarTotals(data.totals)}
                      costCode={costCode}
                      claim={claim}
                      createNewLineItem={onToolbarItemSelected}
                    />
                  </>
                )}
              </CardBody>
              {view === 'claims' ? (
                totals.adjustments === 0 &&
                totals.contracted === 0 &&
                totals.ffc === 0 ? (
                  <div />
                ) : (
                  <>
                    <CardBody>
                      <h3 className="float-left mt-3 ml-5">
                        Totals ({item.vendor.name}):
                      </h3>
                    </CardBody>
                    <CardBody className="border-top">
                      <Row className="mb-0">
                        <Col lg="2" md="6" />
                        <Col lg="2" md="6">
                          <Statistics
                            id="allowedToPay"
                            title="Allowed to Pay"
                            amount={totals.expenditure.allowed}
                            icon="currency-usd"
                            color="warning"
                            precision={config.decimal ? 2 : 0}
                          />
                        </Col>
                        <Col lg="2" md="6">
                          <Statistics
                            id="previouslyAssessed"
                            title="Previously Assessed"
                            amount={totals.expenditure.previous}
                            icon="currency-usd"
                            color="warning"
                            precision={config.decimal ? 2 : 0}
                          />
                        </Col>
                        <Col lg="2" md="6">
                          <Statistics
                            id="totalAssessed"
                            title="Total Assessed"
                            amount={totals.expenditure.current}
                            icon="currency-usd"
                            color="warning"
                            precision={config.decimal ? 2 : 0}
                          />
                        </Col>
                        <Col lg="2" md="6">
                          <Statistics
                            id="thisClaim"
                            title="This Claim"
                            amount={totals.expenditure.thisClaim}
                            icon="currency-usd"
                            color="warning"
                            precision={config.decimal ? 2 : 0}
                          />
                        </Col>
                        <Col lg="2" md="6">
                          <Statistics
                            id="remaining"
                            title="Remaining"
                            amount={totals.expenditure.remaining}
                            icon="currency-usd"
                            color="warning"
                            precision={config.decimal ? 2 : 0}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardBody className="border-top">
                      {item.accountType === 'bills' ? (
                        <Button
                          disabled={totals.thisClaim === 0}
                          onClick={() => toggleClaims()}
                          className="float-right ml-3 mt-2"
                          color="projx"
                        >
                          <i className="fas fa-cogs" /> Process Claims
                        </Button>
                      ) : (
                        <Button
                          disabled={!item.locked || totals.thisClaim === 0}
                          onClick={() => toggleClaims()}
                          className="float-right ml-3 mt-2"
                          color="projx"
                        >
                          <i className="fas fa-cogs" /> Process Claims
                        </Button>
                      )}
                    </CardBody>
                  </>
                )
              ) : totals.adjustments === 0 &&
                totals.contracted === 0 &&
                totals.ffc === 0 ? (
                <div />
              ) : (
                <>
                  <CardBody>
                    <h3 className="float-left mt-3 ml-5">
                      Totals ({item.vendor.name}):
                    </h3>
                  </CardBody>
                  <CardBody className="border-top">
                    <Row className="mb-0">
                      {item.accountType === 'bills' ? (
                        <>
                          <Col lg="2" md="6">
                            <Statistics
                              id="contractSum"
                              title="Original Bills Sum"
                              amount={totals.contracted}
                              icon="currency-usd"
                              color="primary"
                              precision={config.decimal ? 2 : 0}
                            />
                          </Col>
                          <Col lg="2" md="6">
                            <Statistics
                              id="ffc"
                              title="Final Forecast Cost"
                              amount={totals.ffc}
                              icon="currency-usd"
                              color="primary"
                              precision={config.decimal ? 2 : 0}
                              tooltip="Total Unapproved Cost + Total Approved Costs"
                            />
                          </Col>
                          <Col lg="2" md="6">
                            <Statistics
                              id="claims"
                              title="Assessed to Date"
                              amount={totals.expenditure.previous}
                              icon="currency-usd"
                              color="primary"
                              precision={config.decimal ? 2 : 0}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col lg="2" md="6">
                            <Statistics
                              id="contractSum"
                              title="Original Contract Sum"
                              amount={totals.contracted}
                              icon="currency-usd"
                              color="primary"
                              precision={config.decimal ? 2 : 0}
                            />
                          </Col>
                          <Col lg="2" md="6">
                            <Statistics
                              id="contractSumAdjustment"
                              title="Total Approved Adjustments"
                              amount={totals.adjustments}
                              icon="currency-usd"
                              color="primary"
                              precision={config.decimal ? 2 : 0}
                            />
                          </Col>
                          <Col lg="2" md="6">
                            <Statistics
                              id="adjustedContractSum"
                              title="Adjusted Contract Sum"
                              amount={totals.contracted + totals.adjustments}
                              icon="currency-usd"
                              color="primary"
                              precision={config.decimal ? 2 : 0}
                              tooltip="Original Contract Sum + Contract Sum Adjustments"
                            />
                          </Col>
                          <Col lg="2" md="6">
                            <Statistics
                              id="unapprovedAdjustments"
                              title="Unapproved Adjustments"
                              amount={psTotals.forecasts + varTotals.forecasts}
                              icon="currency-usd"
                              color="primary"
                              precision={config.decimal ? 2 : 0}
                              tooltip="Total Unapproved Cost + Total Approved Costs"
                            />
                          </Col>
                          <Col lg="2" md="6">
                            <Statistics
                              id="ffc"
                              title="Final Forecast Cost"
                              amount={totals.ffc}
                              icon="currency-usd"
                              color="primary"
                              precision={config.decimal ? 2 : 0}
                              tooltip="Total Unapproved Cost + Total Approved Costs"
                            />
                          </Col>
                          <Col lg="2" md="6">
                            <Statistics
                              id="claims"
                              title="Paid to Date"
                              amount={totals.expenditure.previous}
                              icon="currency-usd"
                              color="primary"
                              precision={config.decimal ? 2 : 0}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </CardBody>
                </>
              )}
            </Card>
            <ProcessClaims
              toggle={toggleClaims}
              open={claimsOpen}
              onCompleted={onClaimsProcessingCompleted}
              focotr={item}
              totalAmountToProcess={totals.thisClaim}
              allTotals={{ contTotals, psTotals, varTotals, totals }}
              project={project}
            />
            <Invoices
              toggle={toggleInvoices}
              open={invoicesOpen}
              item={item}
              project={project}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={showLock} toggle={toggleLock} backdrop>
        <ModalHeader toggle={toggleLock}>Lock Contracts</ModalHeader>
        <ModalBody>
          <span className="text-danger">
            <b>WARNING: </b>
          </span>
          You are about to lock the contract for{' '}
          <span className="text-info">
            <b>{item.vendor.name}</b>
          </span>{' '}
          vendor.
          <div className="mt-3">
            <span className="font-14">
              <b>
                Please check your contract amounts before proceeding. Only an
                authorized user will be able to unlock the contract.
              </b>
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="float-left"
            color="projx"
            outline
            onClick={toggleLock}
          >
            Cancel
          </Button>
          <Button color="projx" onClick={lockContract}>
            Lock
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BudgetEntry;
