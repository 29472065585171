import React, { useState, useEffect } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';
import classnames from 'classnames';
import _ from 'lodash';

import {
  useFocotrByCost,
  useFocotrOrder,
} from '../../../hooks/graphql/useFocotr';
import BudgetEntry from './BudgetEntry';
import { updateArrayByKey, copyArrayReference } from '../../../common';

const Display = ({
  costCode,
  project,
  focotrItem,
  onTotalsCompleted = () => {},
}) => {
  const [focotr, setFocotr] = useState();
  const [activeTab, setActiveTab] = useState();

  const [totals, setTotals] = useState({});

  const { getFocotrByCost } = useFocotrByCost({
    costCode: costCode.costCode.code,
    projectId: project._id,
    onCompleted: (data) => {
      setActiveTab(data && data.length >= 1 ? data[0]._id : undefined);
      setFocotr(data);
      setTotals({});
    },
  });

  const [updateFocotrOrder] = useFocotrOrder({
    onCompleted: (data) => {},
  });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const entryUpdated = (item) => setFocotr(updateArrayByKey(item, focotr));

  const calculateTotals = () => {
    let totalOfTotals = {
      adjustments: 0,
      contracted: 0,
      fcc: 0,
      thisClaims: 0,
      forecasts: 0,
      stats: {
        count: 0,
        approved: 0,
        contracted: 0,
        provisional: 0,
        variation: 0,
        ffcPercentages: {
          contracts: 0,
          provisionals: 0,
          variations: 0,
        },
      },
      costStats: {
        contracted: 0,
        anticipated: 0,
        submitted: 0,
        assessed: 0,
        // let: 0,
        // variation: 0,
        unknown: 0,
      },
    };

    _.forEach(totals, (v) => {
      totalOfTotals.adjustments += v.adjustments;
      totalOfTotals.contracted += v.contracted;
      totalOfTotals.fcc += v.ffc;
      totalOfTotals.thisClaims += v.thisClaims;
      totalOfTotals.forecasts += v.forecasts;
      totalOfTotals.stats.count += v.stats.count;
      totalOfTotals.stats.approved += v.stats.approved;
      totalOfTotals.stats.contracted += v.stats.contracted;
      totalOfTotals.stats.provisional += v.stats.provisional;
      totalOfTotals.stats.variation += v.stats.variation;
      totalOfTotals.costStats.contracted += v.costStats.contracted;
      totalOfTotals.costStats.anticipated += v.costStats.anticipated;
      totalOfTotals.costStats.submitted += v.costStats.submitted;
      totalOfTotals.costStats.assessed += v.costStats.assessed;
      totalOfTotals.costStats.unknown += v.costStats.unknown;

      totalOfTotals.stats.ffcPercentages.contracts +=
        v.stats.ffcPercentages.contracts;
      totalOfTotals.stats.ffcPercentages.provisionals +=
        v.stats.ffcPercentages.provisionals;
      totalOfTotals.stats.ffcPercentages.variations +=
        v.stats.ffcPercentages.variations;
    });

    onTotalsCompleted(totalOfTotals);
  };

  const onTotals = (total) => {
    let totalsClone = copyArrayReference(totals);
    totalsClone[total.focotr] = total.totals;
    setTotals(totalsClone);
  };

  const onFocotrDeleted = (f) => {
    let clone = copyArrayReference(focotr);
    _.remove(clone, (i) => i._id === f._id);
    setFocotr(clone);

    if (clone.length > 0) {
      setActiveTab(clone[0]._id);
    }
  };

  const [dragId, setDragId] = useState();

  const handleDrag = (ev) => setDragId(ev.currentTarget.id);

  const handleDrop = (ev) => {
    const dragItem = focotr.find((item) => item._id === dragId);
    const dropItem = focotr.find((item) => item._id === ev.currentTarget.id);

    const dragItemOrder = dragItem.order;
    const dropItemOrder = dropItem.order;

    const newItemState = focotr.map((item) => {
      if (item._id === dragId) {
        item.order = dropItemOrder;
      }
      if (item._id === ev.currentTarget.id) {
        item.order = dragItemOrder;
      }
      return item;
    });

    const orders = _.map(newItemState, (nis) => {
      return {
        _id: nis._id,
        order: nis.order,
      };
    });
    updateFocotrOrder(orders);
    setFocotr(newItemState);
  };

  useEffect(() => getFocotrByCost(), []);

  useEffect(() => {
    if (focotrItem) {
      setFocotr((item) => [...item, focotrItem]);
      setActiveTab(focotrItem._id);
    }
  }, [focotrItem]);

  useEffect(() => {
    calculateTotals();
  }, [totals]);

  if (!focotr) return <div />;

  return focotr && focotr.length > 0 ? (
    <div>
      <Nav pills>
        {focotr
          .sort((a, b) => a.order - b.order)
          .map((item) => (
            <NavItem
              id={item._id}
              key={item._id}
              draggable="true"
              onDragStart={handleDrag}
              onDrop={handleDrop}
              onDragOver={(ev) => ev.preventDefault()}
            >
              <NavLink
                className={classnames({ active: activeTab === item._id })}
                onClick={() => {
                  toggle(item._id);
                }}
              >
                <i className="mdi mdi-worker" /> {item.vendor.name}
              </NavLink>
            </NavItem>
          ))}
      </Nav>
      <TabContent className="p-4" activeTab={activeTab}>
        {focotr.map((item) => (
          <TabPane key={item._id} tabId={item._id}>
            <h3 className="text-info">
              {item.accountType === 'bills' ? 'Bills' : 'Contract'} Account
            </h3>
            <Row>
              <Col sm="12">
                <BudgetEntry
                  item={item}
                  onUpdated={entryUpdated}
                  costCode={costCode}
                  onTotals={onTotals}
                  onFocotrDeleted={onFocotrDeleted}
                  project={project}
                />
              </Col>
            </Row>
          </TabPane>
        ))}
      </TabContent>
    </div>
  ) : (
    <div className="d-flex mt-3 p-4 flex-column align-items-center justify-content-center">
      <i className="fas fa-building display-4"></i>
      <h4 className="mt-2">
        No vendors associated to the cost code. Please add a vendor.
      </h4>
    </div>
  );
};

export default Display;
