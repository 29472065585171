import React from 'react';
import { Input, Table } from 'reactstrap';
import { BaseActions, Permissions } from '@projx/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

const PermissionTable = ({ permissions, setPermissions, modify = false }) => {
  const renderAction = (
    availableActions,
    normalisedPermissionGroup,
    normalisedPermissionName,
    action,
  ) => {
    if (availableActions.includes(action)) {
      if (modify) {
        return (
          <Input
            className="ml-auto position-relative"
            id={`${normalisedPermissionGroup}${normalisedPermissionName}${action}`}
            type="checkbox"
            checked={permissions.some(
              (permission) =>
                permission.id ===
                `${normalisedPermissionGroup}${normalisedPermissionName}${action}`,
            )}
            onChange={(event) =>
              handlePermissionSelection(event, {
                group: normalisedPermissionGroup,
                name: normalisedPermissionName,
                action,
              })
            }
          />
        );
      }

      const assignedActions = _.get(
        permissions,
        `${normalisedPermissionGroup}.${normalisedPermissionName}`,
        [],
      );

      if (assignedActions.includes(action)) {
        return <FontAwesomeIcon icon="check" color="green" />;
      }

      return <FontAwesomeIcon icon="times" color="red" />;
    }

    return <FontAwesomeIcon icon="minus" color="grey" />;
  };

  const renderOtherActions = (
    availableActions,
    normalisedPermissionGroup,
    normalisedPermissionName,
  ) => {
    const otherAvailableActions = availableActions.filter(
      (action) => !Object.values(BaseActions).includes(action),
    );

    if (!otherAvailableActions.length) {
      return <FontAwesomeIcon icon="minus" color="grey" />;
    }

    if (modify) {
      return (
        <ul className="nav flex-column">
          {otherAvailableActions.map((action) => (
            <li>
              <Input
                className="ml-auto position-relative"
                id={`${normalisedPermissionGroup}${normalisedPermissionName}${action}`}
                type="checkbox"
                checked={() =>
                  permissions.some(
                    (permission) =>
                      permission.id ===
                      `${normalisedPermissionGroup}${normalisedPermissionName}${action}`,
                  )
                }
                onChange={(event) =>
                  handlePermissionSelection(
                    event,
                    normalisedPermissionGroup,
                    normalisedPermissionName,
                    action,
                  )
                }
              />
            </li>
          ))}
        </ul>
      );
    }

    const assignedActions = _.get(
      permissions,
      `${normalisedPermissionGroup}.${normalisedPermissionName}`,
      [],
    );

    return (
      <ul className="nav flex-column">
        {otherAvailableActions.map((action) => (
          <li>
            {assignedActions.includes(action) ? (
              <FontAwesomeIcon icon="check" color="green" className="mr-2" />
            ) : (
              <FontAwesomeIcon icon="times" color="red" className="mr-2" />
            )}
            {action}
          </li>
        ))}
      </ul>
    );
  };

  const handlePermissionSelection = (event, permission) => {
    const { id, checked } = event.target;
    if (checked) {
      setPermissions((items) => [...items, { id, permission }]);
    } else {
      setPermissions((items) => {
        return items.filter((item) => item.id !== id);
      });
    }
  };

  return (
    <div>
      {Object.entries(Permissions).map(
        ([permissionGroup, permissionValues]) => {
          const normalisedPermissionGroup = permissionGroup.toLowerCase();

          return (
            <Table
              no-wrap
              v-middle
              responsive
              hover
              className="no-wrap v-middle"
            >
              <thead>
                <tr className="border-0">
                  <th className="border-0">
                    {_upperCaseFirstLetter(normalisedPermissionGroup)}
                  </th>
                  <th className="border-0">read</th>
                  <th className="border-0">create</th>
                  <th className="border-0">update</th>
                  <th className="border-0">delete</th>
                  <th className="border-0">other</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(permissionValues).map(
                  ([permissionName, permissionActionValues]) => {
                    const normalisedPermissionName =
                      permissionName.toLowerCase();
                    const availableActions = Object.keys(
                      permissionActionValues,
                    ).map((permissionAction) => permissionAction.toLowerCase());

                    return (
                      <tr>
                        <td>
                          {_upperCaseFirstLetter(normalisedPermissionName)}
                        </td>
                        <td>
                          {renderAction(
                            availableActions,
                            normalisedPermissionGroup,
                            normalisedPermissionName,
                            'read',
                          )}
                        </td>
                        <td>
                          {renderAction(
                            availableActions,
                            normalisedPermissionGroup,
                            normalisedPermissionName,
                            'create',
                          )}
                        </td>
                        <td>
                          {renderAction(
                            availableActions,
                            normalisedPermissionGroup,
                            normalisedPermissionName,
                            'update',
                          )}
                        </td>
                        <td>
                          {renderAction(
                            availableActions,
                            normalisedPermissionGroup,
                            normalisedPermissionName,
                            'delete',
                          )}
                        </td>
                        <td>
                          {renderOtherActions(
                            availableActions,
                            normalisedPermissionGroup,
                            normalisedPermissionName,
                          )}
                        </td>
                      </tr>
                    );
                  },
                )}
              </tbody>
            </Table>
          );
        },
      )}
    </div>
  );
};

const _upperCaseFirstLetter = (item) =>
  item.charAt(0).toUpperCase() + item.slice(1);

export default PermissionTable;
