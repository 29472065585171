import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { Vendors } from '../../components';

const DirectoryView = () => {
  return (
    <>
      <Row className="mb-4">
        <Col xs="10">
          <h4 className="mb-3">
            <i className="mdi mdi-contacts mr-2" /> Directory
          </h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Vendors />
        </CardBody>
      </Card>
    </>
  );
};

export default DirectoryView;
