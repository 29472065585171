import { useContext } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import {
  GET_COSTCODE_SETUP_ORG_QUERY,
  GET_COSTCODE_SETUP_PROJECT_QUERY,
  CREATE_COSTCODE_SETUP_MUTATION,
  UPDATE_COSTCODE_SETUP_MUTATION,
  CREATE_PROJECT_COSTCODE_SETUP_MUTATION,
  UPDATE_PROJECT_COSTCODE_SETUP_MUTATION,
  DELETE_COSTCODE_SETUP_MUTATION,
  DELETE_PROJECT_COSTCODE_SETUP_MUTATION,
  RETRIEVE_COST_CODE_SETUP_QUERY,
} from '../../graphql/queries';
import { OrganisationContext } from '../../contexts';
import { cleanGqlTypename } from '../../common';

const itemMapper = (item) => cleanGqlTypename(item);

const mapper = (items) => _.map(items, (item) => itemMapper(item));

const generate = (item, orgId = undefined) => {
  let clone = _.cloneDeep(item);
  if (_.matchesProperty('_id', clone)) delete clone._id;
  if (_.matchesProperty('isNew', clone)) delete clone.isNew;
  if (_.matchesProperty('isDirty', clone)) delete clone.isDirty;
  if (_.matchesProperty('edit', clone)) delete clone.edit;
  if (_.matchesProperty('hasTransactionsAssociated', clone))
    delete clone.hasTransactionsAssociated;
  if (_.matchesProperty('hasVendorsAssociated', clone))
    delete clone.hasVendorsAssociated;
  if (_.matchesProperty('currentAmount', clone)) delete clone.currentAmount;

  clone['organisation'] = orgId;
  return clone;
};

const useCostCodeSetup = ({ onCompleted = () => {} }) => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const Actions = {
    GetCostCodeSetupOrg: 'GET_COSTCODE_SETUP_ORG',
    GetCostCodeSetupProject: 'GET_COSTCODE_SETUP_PROJECT',
    CreateCostCodeSetup: 'CREATE_COSTCODE_SETUP',
    UpdateCostCodeSetup: 'UPDATE_COSTCODE_SETUP',
    CreateCostCodeSetupProject: 'CREATE_COSTCODE_SETUP_PROJECT',
    UpdateCostCodeSetupProject: 'UPDATE_COSTCODE_SETUP_PROJECT',
    DeleteCostCodeSetupOrg: 'DELETE_COSTCODE_SETUP_ORG',
    DeleteCostCodeSetupProject: 'DELETE_COSTCODE_SETUP_PROJECT',
    RetrieveCostCodeSetup: 'RETRIEVE_COST_CODE_SETUP',
  };

  const [
    _getCostCodeSetupByOrg,
    {
      data: dataCostCodeSetupOrg,
      loading: loadingCostCodeSetupOrg,
      error: errorCostCodeSetupOrg,
    },
  ] = useLazyQuery(GET_COSTCODE_SETUP_ORG_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      onCompleted({
        action: 'GET_COSTCODE_SETUP_ORG',
        data: mapper(_.get(data, 'getCostCodeSetupByOrg')),
      }),
  });

  const [
    _retrieveCostCodeSetup,
    {
      data: costCodeSetupItem,
      loading: loadingCostCodeSetupItem,
      error: errorCostCodeSetupItem,
    },
  ] = useLazyQuery(RETRIEVE_COST_CODE_SETUP_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      onCompleted({
        action: Actions.RetrieveCostCodeSetup,
        data: _.get(itemMapper(data), 'retrieveCostCodeSetup'),
      }),
  });

  const [
    _getCostCodeSetupByProject,
    {
      data: dataCostCodeSetupProject,
      loading: loadingCostCodeSetupProject,
      error: errorCostCodeSetupProject,
    },
  ] = useLazyQuery(GET_COSTCODE_SETUP_PROJECT_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      onCompleted({
        action: 'GET_COSTCODE_SETUP_PROJECT',
        data: mapper(_.get(data, 'getCostCodeSetupByProject')),
      }),
  });

  const [
    _createCostCodeSetup,
    {
      data: dataCostCodeSetupCreate,
      loading: loadingCostCodeSetupCreate,
      error: errorCostCodeSetupCreate,
    },
  ] = useMutation(CREATE_COSTCODE_SETUP_MUTATION, {
    onCompleted: (data) =>
      onCompleted({
        action: 'CREATE_COSTCODE_SETUP',
        data: itemMapper(_.get(data, 'createCostCodeSetup')),
      }),
  });

  const [
    _updateCostCodeSetup,
    {
      data: dataCostCodeSetupUpdate,
      loading: loadingCostCodeSetupUpdate,
      error: errorCostCodeSetupUpdate,
    },
  ] = useMutation(UPDATE_COSTCODE_SETUP_MUTATION, {
    onCompleted: (data) =>
      onCompleted({
        action: 'UPDATE_COSTCODE_SETUP',
        data: itemMapper(_.get(data, 'updateCostCodeSetup')),
      }),
  });

  const [
    _createCostCodeSetupProject,
    {
      data: dataCostCodeSetupProjectCreate,
      loading: loadingCostCodeSetupProjectCreate,
      error: errorCostCodeSetupProjectCreate,
    },
  ] = useMutation(CREATE_PROJECT_COSTCODE_SETUP_MUTATION, {
    onCompleted: (data) =>
      onCompleted({
        action: 'CREATE_COSTCODE_SETUP_PROJECT',
        data: itemMapper(_.get(data, 'createProjectCostCodeSetup')),
      }),
  });

  const [
    _updateCostCodeSetupProject,
    {
      data: dataCostCodeSetupProjectUpdate,
      loading: loadingCostCodeSetupProjectUpdate,
      error: errorCostCodeSetupProjectUpdate,
    },
  ] = useMutation(UPDATE_PROJECT_COSTCODE_SETUP_MUTATION, {
    onCompleted: (data) =>
      onCompleted({
        action: 'UPDATE_COSTCODE_SETUP_PROJECT',
        data: itemMapper(_.get(data, 'updateProjecCostCodeSetup')),
      }),
  });

  const [
    _deleteCostCodeSetup,
    {
      data: dataCostCodeSetupDelete,
      loading: loadingCostCodeSetupDelete,
      error: errorCostCodeSetupDelete,
    },
  ] = useMutation(DELETE_COSTCODE_SETUP_MUTATION, {
    onCompleted: (data) =>
      onCompleted({
        action: Actions.DeleteCostCodeSetupOrg,
        data: _.get(data, 'deleteCostCodeSetup'),
      }),
  });

  const [
    _deleteCostCodeSetupProject,
    {
      data: dataCostCodeSetupProjectDelete,
      loading: loadingCostCodeSetupProjectDelete,
      error: errorCostCodeSetupProjectDelete,
    },
  ] = useMutation(DELETE_PROJECT_COSTCODE_SETUP_MUTATION, {
    onCompleted: (data) =>
      onCompleted({
        action: Actions.DeleteCostCodeSetupProject,
        data: _.get(data, 'deleteProjectCostCodeSetup'),
      }),
  });

  const deleteCostCodeSetup = (id) => {
    return _deleteCostCodeSetup({
      variables: {
        _id: id,
      },
    });
  };

  const deleteCostCodeSetupProject = (projectId, costCodeId) => {
    return _deleteCostCodeSetupProject({
      variables: {
        projectId,
        costCodeId,
      },
    });
  };

  const getCostCodeSetupByOrg = (orgId) => {
    return _getCostCodeSetupByOrg({
      variables: {
        orgId,
      },
    });
  };

  const retrieveCostCodeSetup = (projectId, costCodeId) => {
    return _retrieveCostCodeSetup({
      variables: {
        projectId,
        costCodeId,
      },
    });
  };

  const getCostCodeSetupByProject = (projectId) => {
    return _getCostCodeSetupByProject({
      variables: {
        projectId,
      },
    });
  };

  const createCostCodeSetup = (data) => {
    return _createCostCodeSetup({
      variables: {
        input: generate(
          data,
          currentOrganisation ? currentOrganisation._id : null,
        ),
      },
    });
  };

  const updateCostCodeSetup = (_id, data) => {
    return _updateCostCodeSetup({
      variables: {
        _id,
        input: generate(
          data,
          currentOrganisation ? currentOrganisation._id : null,
        ),
      },
    });
  };

  const createCostCodeSetupProject = (projectId, data) => {
    return _createCostCodeSetupProject({
      variables: {
        projectId,
        input: generate(data),
      },
    });
  };

  const updateCostCodeSetupProject = (projectId, costCodeSetupId, data) => {
    return _updateCostCodeSetupProject({
      variables: {
        projectId,
        costCodeSetupId,
        input: generate(data, undefined),
      },
    });
  };

  return {
    getCostCodeSetupByOrg,
    getCostCodeSetupByProject,
    createCostCodeSetup,
    updateCostCodeSetup,
    createCostCodeSetupProject,
    updateCostCodeSetupProject,
    deleteCostCodeSetup,
    deleteCostCodeSetupProject,
    dataCostCodeSetupOrg,
    loadingCostCodeSetupOrg,
    errorCostCodeSetupOrg,
    dataCostCodeSetupProject,
    loadingCostCodeSetupProject,
    errorCostCodeSetupProject,
    dataCostCodeSetupProjectCreate,
    loadingCostCodeSetupProjectCreate,
    errorCostCodeSetupProjectCreate,
    dataCostCodeSetupProjectUpdate,
    loadingCostCodeSetupUpdate,
    loadingCostCodeSetupProjectUpdate,
    errorCostCodeSetupUpdate,
    errorCostCodeSetupProjectUpdate,
    errorCostCodeSetupCreate,
    loadingCostCodeSetupCreate,
    dataCostCodeSetupCreate,
    dataCostCodeSetupUpdate,
    dataCostCodeSetupDelete,
    loadingCostCodeSetupDelete,
    errorCostCodeSetupDelete,
    dataCostCodeSetupProjectDelete,
    loadingCostCodeSetupProjectDelete,
    errorCostCodeSetupProjectDelete,
    retrieveCostCodeSetup,
    costCodeSetupItem,
    loadingCostCodeSetupItem,
    errorCostCodeSetupItem,
    Actions,
  };
};

export default useCostCodeSetup;
