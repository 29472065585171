import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

// This is our Spinner from the 'react-spinners' library, we apply custom styles here
export const ClipLoaderStyles = styled(ClipLoader)`
`;
