import React, { useContext, useEffect, useState } from 'react';
import ProjectsContext from './projects.context';
import { useQueryProjectsByOrg } from '../../hooks';

import OrganisationContext from '../organisation/organisation.context';
import _ from 'lodash';

const ProjectsProvider = ({ children }) => {
  const { currentOrganisation } = useContext(OrganisationContext);
  const [project, setProject] = useState(undefined);

  const { queryProjectByOrg, projectsByOrgLoading, projects } =
    useQueryProjectsByOrg();

  // when org is switched refetch projects
  useEffect(() => {
    if (_.has(currentOrganisation, '_id'))
      queryProjectByOrg(currentOrganisation._id);
  }, [currentOrganisation]);

  const refetchProjects = () => {
    if (_.has(currentOrganisation, '_id'))
      queryProjectByOrg(currentOrganisation._id);
  };

  return (
    <ProjectsContext.Provider
      value={{
        project,
        setProject,
        projects,
        loading: projectsByOrgLoading,
        refetchProjects,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsProvider;
