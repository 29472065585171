import styled, { css } from 'styled-components';
import { RiDeleteBinLine } from 'react-icons/ri';

export const SafetyPeriodCardStyles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center ;
  width: 100%;
  min-height: 48px;
  padding: 16px;
  border-radius: 8px;
  color: #1B1B1B;
  background-color: #F9F9F9;
  border: 1px solid #D9D9D9;
  cursor: pointer;

  &:hover {
    border: 1px solid #1B1B1B;
  }
`;

export const SafetyPeriodCardHeader = styled.p`
  font-weight: 500;
  font-size: 23px;
  margin: 0;
  line-height: 32px;
`;

export const SafetyPeriodCardButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-left: 16px;
  border: none;
  text-decoration: none;
  border-radius: 8px;
  background-color: #F9F9F9;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #D9D9D9;
  }

  ${(props) => props.visible === false && css`
    visibility: hidden;
  `}
`;

export const DeleteIcon = styled(RiDeleteBinLine)`
  width: 25px;
  height: 25px;
  color: #1B1B1B;
`;