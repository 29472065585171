import React, { useState, useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import Select from 'react-select';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from 'reactstrap';
import classnames from 'classnames';
// Local Imports
import { fundingEventTypeOptions } from '../constants';
import { Button } from '../../../components';
import { FundingContext } from '../../../contexts';
import FundingActivityCards from '../fundingActivityCards';
import {
  InputContainer,
  InputHeader,
  FooterContainer,
  Divider,
  TextAreaStyled,
  NumericInputStyles,
} from './styles/FundingAccountModal';

// GraphQL Queries
const ADD_FUNDING_ACCOUNT_EVENT = gql`
  mutation addFundingAccountEvent(
    $projectId: ID!
    $input: FundingAccountEventInput!
  ) {
    addFundingAccountEvent(projectId: $projectId, input: $input) {
      code
      success
      message
      fundingAccounts {
        _id
        name
        amount
        allocated
      }
    }
  }
`;

const DELETE_FUNDING_ACCOUNT = gql`
  mutation deleteFundingAccount(
    $projectId: ID!
    $fundingAccountName: String!
  ) {
    deleteFundingAccount(projectId: $projectId, fundingAccountName: $fundingAccountName) {
      code
      success
      message
      fundingAccounts {
        _id
        name
        amount
        allocated
      }
    }
  }
`;

/**
 * This component is in charge of rendering the modal that is displayed when the user clicks on a
 * funding account card.
 *
 * It provides the user with the 2 tabs.
 * 1) Add a funding event (i.e. either "DEPOSIT" to add funding or "WITHDRAW" to remove funding)
 * 2) View all the funding events performed on this particular funding account
 * @param {string} accountName The name of the account we have selected
 * @param {boolean} modalActive Whether the modal is open or not, controlled by parent component
 * @param {function} toggleFunction Function to toggle our modal, controlled by parent component
 * @returns
 */
const FundingAccountModal = ({ accountName, modalActive, toggleFunction }) => {
  // This state is in charge of the tabs in our modal
  const [activeTab, setActiveTab] = useState('1');
  // The states of our add funding event tab in our modal
  const [type, setType] = useState('DEPOSIT');
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState('');

  // For showing/hiding our modal for confirming deletion of a funding account
  const [showDeleteFundingAccountModal, setShowDeleteFundingAccountModal] =
    useState(false);

  const { projectId, setFundingAccounts } = useContext(FundingContext);

  // GraphQL
  const [
    addFundingAccountEvent,
    {
      loading: loadingAddFundingAccountEvent,
      error: errorAddFundingAccountEvent,
      data: dataAddFundingAccountEvent,
    },
  ] = useMutation(ADD_FUNDING_ACCOUNT_EVENT, {
    onCompleted: (data) => {
      setFundingAccounts(data.addFundingAccountEvent.fundingAccounts);
    },
  });

  const [
    deleteFundingAccount,
    {
      loading: loadingDeleteFundingAccount,
      error: errorDeleteFundingAccount,
      data: dataDeleteFundingAccount,
    },
  ] = useMutation(DELETE_FUNDING_ACCOUNT, {
    onCompleted: (data) => {
      setFundingAccounts(data.deleteFundingAccount.fundingAccounts);
    },
  });

  // Our handler function for changing between tabs in our modal
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // For adding a funding event
  const handleAddEventButtonClick = async (e) => {
    setType('DEPOSIT');
    setAmount(0);
    setDescription('');
    await addFundingAccountEvent({
      variables: {
        projectId,
        input: {
          accountName,
          type,
          amount,
          description,
        },
      },
    });
    toggleFunction();
  };

  const handleConfirmDeleteFundingAccountClick = async (e) => {
    setShowDeleteFundingAccountModal(false);
    // TODO Delete our funding account mutation goes here
    await deleteFundingAccount({
      variables: {
        projectId,
        fundingAccountName: accountName,
      }
    });
    toggleFunction();
  }

  const toggleConfirmDeleteModal = () => {
    setShowDeleteFundingAccountModal(() => !showDeleteFundingAccountModal);
  };

  const isAddEventButtonDisabled =
    amount === 0 || amount === undefined || description === '';

  return (
    <>
      <Modal isOpen={modalActive} toggle={toggleFunction} backdrop>
        <ModalHeader toggle={toggleFunction}>
          <InputHeader>{accountName}</InputHeader>
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => toggleTab('1')}
              >
                {`Add Event`}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => toggleTab('2')}
              >
                {`Activity`}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <InputContainer>
                <InputHeader>{`Type`}</InputHeader>
                <Select
                  options={fundingEventTypeOptions}
                  value={_.find(fundingEventTypeOptions, (item) => {
                    return item.value === type;
                  })}
                  defaultValue={{ value: 'DEPOSIT', label: 'Deposit' }}
                  onChange={(e) => setType(e.value)}
                />
              </InputContainer>
              <InputContainer>
                <InputHeader>{`Amount`}</InputHeader>
                <NumericInputStyles
                  displayType="input"
                  prefix={'$ '}
                  value={amount}
                  onValueChange={(values, sourceInfo) => {
                    setAmount(values.floatValue);
                  }}
                  placeholder="e.g. 123456"
                  thousandSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                />
              </InputContainer>
              <InputContainer>
                <InputHeader>{`Description`}</InputHeader>
                <TextAreaStyled
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="e.g. Additional funding received from sponsor."
                />
              </InputContainer>
              <FooterContainer>
                <Button
                  onClick={toggleConfirmDeleteModal}
                  outline
                >{`Delete`}</Button>
                <Divider />
                <Button
                  disabled={
                    isAddEventButtonDisabled || loadingAddFundingAccountEvent
                  }
                  onClick={handleAddEventButtonClick}
                >
                  {`Add Event`}
                </Button>
              </FooterContainer>
            </TabPane>
            <TabPane tabId="2">
              <FundingActivityCards fundingAccountName={accountName} />
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
      {/*
        This modal which pops up on top of the primary modal for editing a line item and is for for
        confirming our deletion of the module line item
      */}
      <Modal
        isOpen={showDeleteFundingAccountModal}
        toggle={toggleConfirmDeleteModal}
        backdrop
      >
        <ModalHeader toggle={toggleConfirmDeleteModal}>
          <InputHeader>Are you sure you want to delete this funding account?</InputHeader>
        </ModalHeader>
        <ModalBody>
          You will be unable to recover the data once it is deleted, are you
          sure you want to continue ?
          <FooterContainer>
            <Button onClick={toggleConfirmDeleteModal} outline>
              Cancel
            </Button>
            <Divider />
            <Button onClick={handleConfirmDeleteFundingAccountClick}>Confirm</Button>
          </FooterContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FundingAccountModal;
