import React, { Component } from 'react';
import Switch from 'react-bootstrap-switch';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Alert,
} from 'reactstrap';
import CurrencyInput from 'react-currency-input';

// const CostsStepV1 = ({ getStore, updateStore }) => {
//   const [budget, setBudget] = useState(
//     getStore().totalBudget && getStore().totalBudget > 0 ? true : false,
//   );

//   const onTextChange = (_, __, val) => {
//     updateStore({ totalBudget: val });
//   };

//   const onBudgetChange = (e) => {
//     if (!e.state.value) onTextChange(undefined, undefined, undefined);
//     setBudget(e.state.value);
//   };

//   return (
//     <div className="step step1 mt-2">
//       <div className="row justify-content-md-center">
//         <div className="col col-lg-5 mt-4">
//           <Row>
//             <Col sm="12" className="mb-2">
//               <h5>Do you have an estimated cost for the project?</h5>
//             </Col>
//             <Col sm="12">
//               <Switch
//                 onColor="danger"
//                 onText="Y"
//                 offText="N"
//                 defaultValue={budget}
//                 onChange={onBudgetChange}
//               />
//             </Col>
//           </Row>
//           {budget ? (
//             <>
//               <Row className="mt-4">
//                 <Col md={8} className="pr-5">
//                   <InputGroup>
//                     <InputGroupAddon addonType="prepend">
//                       <InputGroupText>$</InputGroupText>
//                     </InputGroupAddon>
//                     <CurrencyInput
//                       placeholder="$ amount"
//                       type="tel"
//                       precision={0}
//                       value={getStore().totalBudget}
//                       onChangeEvent={onTextChange}
//                       className="form-control currency-lg"
//                     />
//                   </InputGroup>
//                   <small className="badge badge-default badge-projx text-white float-left mb-4">
//                     Estimate
//                   </small>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={12}>
//                   <Label className="font-12">
//                     Note:{' '}
//                     <i>
//                       This is the TEC (Total End Cost or TEI (Total End
//                       Investment) amount. That is the amount inclusive of all
//                       costs for the project including consultants, construction
//                       and sundry. You will also be able to change this value
//                       later.
//                     </i>
//                   </Label>
//                 </Col>
//               </Row>
//             </>
//           ) : (
//             ''
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

class CostsStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      budget:
        this.props.getStore().totalBudget &&
        this.props.getStore().totalBudget > 0
          ? true
          : false,
      error: false,
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onBudgetChange = this.onBudgetChange.bind(this);
  }

  onTextChange = (_, __, val) => {
    this.setState({
      error: false,
    });
    this.props.updateStore({ totalBudget: val });
  };

  onBudgetChange = (e) => {
    if (!e.state.value) this.onTextChange(undefined, undefined, undefined);

    this.setState({
      budget: e.state.value,
    });
  };

  isValidated = () => {
    if (
      this.state.budget &&
      (!this.props.getStore().totalBudget ||
        this.props.getStore().totalBudget === 0)
    ) {
      this.setState({
        error: true,
      });
      return false;
    }
    return true;
  };

  render = () => {
    return (
      <div className="step step1 mt-2">
        <div className="row justify-content-md-center">
          <div className="col col-lg-5 mt-4">
            <Row>
              <Col sm="12" className="mb-2">
                <h5>Do you have an estimated cost for the project?</h5>
              </Col>
              <Col sm="12">
                <Switch
                  onColor="danger"
                  onText="Y"
                  offText="N"
                  defaultValue={this.state.budget}
                  onChange={this.onBudgetChange}
                />
              </Col>
            </Row>
            {this.state.budget ? (
              <>
                <Row className="mt-4">
                  <Col md={8} className="pr-5">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <CurrencyInput
                        placeholder="$ amount"
                        type="tel"
                        precision={0}
                        value={this.props.getStore().totalBudget}
                        onChangeEvent={this.onTextChange}
                        className="form-control currency-lg"
                      />
                    </InputGroup>
                    <small className="badge badge-default badge-projx text-white float-left mb-4">
                      Estimate
                    </small>
                  </Col>
                </Row>
                {this.state.error ? (
                  <Row>
                    <Col md={12} className="pr-5">
                      <Label className="font-12 text-danger">
                        Please provide an estimate before moving on.
                      </Label>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                <Row className="mt-3">
                  <Col md={12}>
                    <Alert color="projx">
                      Note: This is the TEC (Total End Cost) or TEI (Total End
                      Investment) amount. That's the amount inclusive of all
                      costs for the project including consultants, construction
                      and sundry. <br />
                      <br /> You will also be able to change this value later.
                    </Alert>
                  </Col>
                </Row>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  };
}

export default CostsStep;
