import { ReportContent } from '.';

import BudgetTransfers from './templates/budgetTransfers/BudgetTransfers';
import BudgetCurrentPrevious from './templates/budgetCurrentPrevious/BudgetCurrentPrevious';
import FfcBreakdown from './templates/ffcBreakdown/FfcBreakdown';
import Invoicing from './templates/invoicing/Invoicing';
import CostSummary from './templates/costSummary/CostSummary';


const ColumnType = {
  Text: 'text',
  Numerical: 'numerical',
  Date: 'date',
  Badge: 'badge',
};

const FilterType = {
  Text: 'text',
  Numerical: 'numerical',
  Date: 'date',
  Select: 'select',
};

const ReportType = {
  BudgetTransfers: 'budget-transfers',
  FfcBreakdown: 'ffc-breakdown',
  FccBreakdownPeriods: 'fcc-breakdown-periods',
  Invoicing: 'vendor-invoicing',
  ContractSumAdjustments: 'contract-sum-adjustments',
  CostSummary: 'cost-summary',
  BudgetCurrentPrevious: 'budget-current-previous',
  None: 'none',
};

const buildReports = (project) => {
  const { _id, name, address, client, organisation, code } = project;

  const header = {
    projectName: name,
    projectAddress: address,
    projectCode: code,
    clientName: client.name,
    clientContract: client.contract,
    clientAbn: client.abn,
    organisationName: organisation.name,
    organisationLogo: organisation.logo,
  };

  return [
    {
      group: 'Budget',
      reports: [
        {
          name: 'Budget Transfers',
          type: ReportType.BudgetTransfers,
          path: `/projects/:projectId/reports/${ReportType.BudgetTransfers}`,
          Component: ReportContent,
          pinned: true,
          template: {
            Component: BudgetTransfers,
            data: {
              header: { ...header },
            },
          },
        },
        {
          name: 'FFC Breakdown',
          type: ReportType.FfcBreakdown,
          path: `/projects/:projectId/reports/${ReportType.FfcBreakdown}`,
          Component: ReportContent,
          pinned: false,
          template: {
            Component: FfcBreakdown,
            data: {
              header: { ...header },
            },
          },
        },
        {
            name: "Current & Previous Month",
            type: ReportType.BudgetCurrentPrevious,
            path: `/projects/:projectId/reports/${ReportType.BudgetCurrentPrevious}`,
            Component: ReportContent,
            pinned: false,
            template: {
                Component: BudgetCurrentPrevious,
                data: {
                    header: { ...header }
                }
            }
        }
      ],
    },
    {
      group: 'Costs & Claims',
      reports: [
        {
          name: 'Cost Summary',
          type: ReportType.CostSummary,
          path: `/projects/:projectId/reports/${ReportType.CostSummary}`,
          Component: ReportContent,
          pinned: true,
          template: {
            Component: CostSummary,
            data: {
              header: { ...header },
            },
          },
        },
      ],
    },
    {
      group: 'Invoicing',
      reports: [
        {
          name: 'Vendor Invoices',
          type: ReportType.Invoicing,
          path: `/projects/:projectId/reports/${ReportType.Invoicing}`,
          Component: ReportContent,
          pinned: true,
          template: {
            Component: Invoicing,
            data: {
              header: { ...header },
            },
          },
        },
      ],
    },
  ];
};

export { buildReports, ColumnType, FilterType, ReportType };
