import { useContext } from 'react';

import { useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import {
  GET_TRANSACTION_QUERY,
  GET_TRANSACTIONS_QUERY,
  CREATE_TRANSACTION_MUTATION,
  APPROVE_TRANSACTION_MUTATION,
  WITHDRAW_TRANSACTION_MUTATION,
  REJECT_TRANSACTION_MUTATION,
} from '../../graphql/queries';

import { cleanGqlTypename } from '../../common';
import { TransactionContext } from '../../contexts/transaction/TransactionProvider';

const mapper = (items) => _.map(items, (item) => cleanGqlTypename(item));

const useTransaction = ({ onCompleted = () => {} }) => {
  const { transactions, setTransactions } = useContext(TransactionContext);

  const TransactionOperationType = {
    GetTransaction: 'GET_TRANSACTION',
    GetTransactions: 'GET_TRANSACTIONS',
    CreateTransaction: 'CREATE_TRANSACTION',
    ApproveTransaction: 'APPROVE_TRANSACTION',
    RejectTransaction: 'REJECT_TRANSACTION',
    WithdrawTransaction: 'WITHDRAW_TRANSACTION',
  };

  const [_approveTransaction] = useMutation(APPROVE_TRANSACTION_MUTATION, {
    onCompleted: (data) =>
      onCompleted({
        action: TransactionOperationType.ApproveTransaction,
        data: cleanGqlTypename(_.get(data, 'approveTransaction')),
      }),
  });

  const [_withdrawTransaction] = useMutation(WITHDRAW_TRANSACTION_MUTATION, {
    onCompleted: (data) =>
      onCompleted({
        action: TransactionOperationType.WithdrawTransaction,
        data: cleanGqlTypename(_.get(data, 'withdrawTransaction')),
      }),
  });

  const [_rejectTransaction] = useMutation(REJECT_TRANSACTION_MUTATION, {
    onCompleted: (data) =>
      onCompleted({
        action: TransactionOperationType.RejectTransaction,
        data: cleanGqlTypename(_.get(data, 'rejectTransaction')),
      }),
  });

  const [_getTransaction] = useLazyQuery(GET_TRANSACTION_QUERY, {
    onCompleted: (data) => {
      onCompleted({
        action: TransactionOperationType.GetTransaction,
        data: cleanGqlTypename(_.get(data, 'getTransaction')),
      });
    },
  });

  const [_getTransactions, { refetch }] = useLazyQuery(GET_TRANSACTIONS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      data = _.get(data, 'getTransactions');
      setTransactions(data);
      onCompleted({
        action: TransactionOperationType.GetTransactions,
        data: mapper(data),
      });
    },
  });

  const [_createTransaction] = useMutation(CREATE_TRANSACTION_MUTATION, {
    onCompleted: (data) =>
      onCompleted({
        action: TransactionOperationType.CreateTransaction,
        data: cleanGqlTypename(_.get(data, 'createTransaction')),
      }),
  });

  const getTransaction = (transactionId) => {
    return _getTransaction({
      variables: { transactionId },
    });
  };

  const getTransactions = (projectId) => {
    return _getTransactions({
      variables: { projectId },
    });
  };

  const createTransaction = (transaction) => {
    return _createTransaction({
      variables: { input: transaction },
    });
  };

  const approveTransaction = (transactionId) => {
    return _approveTransaction({
      variables: { transactionId },
    });
  };

  const withdrawTransaction = (transactionId) => {
    return _withdrawTransaction({
      variables: { transactionId },
    });
  };

  const rejectTransaction = (transactionId) => {
    return _rejectTransaction({
      variables: { transactionId },
    });
  };

  return {
    refetch,
    getTransaction,
    getTransactions,
    createTransaction,
    approveTransaction,
    withdrawTransaction,
    rejectTransaction,
    TransactionOperationType,
    transactions,
  };
};

export default useTransaction;
