import React from 'react';
import { NavLink } from 'react-router-dom';

const ReportItem = ({ report, project }) => {
  const pin = (e) => {
    e.stopPropagation();
  };
  return (
    <li className="list-group-item" style={{ cursor: 'pointer' }}>
      <span className={'list-group-item-action'}>
        <i
          className={'mr-3 ' + (report.pinned ? 'fas fa-star' : 'far fa-star')}
          onClick={pin}
        />
        <NavLink
          to={report.path.replace(':projectId', project._id)}
          className="sidebar-link"
          activeClassName="active"
          style={{ color: '#212529' }}
        >
          {report.name}
        </NavLink>
      </span>
    </li>
  );
};

export default ReportItem;
