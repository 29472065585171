import React, { useState } from 'react';
import moment from 'moment';
import { Tooltip } from 'reactstrap';
import { currencify } from '../../../../../common';
import { letDocumentCategoryOptions } from '../../../../../helpers';
import { DocumentListModal } from '../../../..';
import { useAddLetDocument } from '../../../../../hooks';

const Let = ({ letEntry, approve = () => {}, itemId }) => {
  const [tooltipOpen, setTooltipOpen] = useState({ approve: false });
  const [isDocumentListModelOpen, setDocumentListModel] = useState(false);
  const [isDocumentUploadComplete, setDocumentUploadComplete] = useState(false);

  const toggle = (type) =>
    setTooltipOpen((val) => ({ ...val, [type]: !tooltipOpen[type] }));
  const toggleDocumentListModal = () =>
    setDocumentListModel(!isDocumentListModelOpen);

  const [addLetDocument, { loading }] = useAddLetDocument({
    onCompleted: (document) => {
      letEntry.document
        ? letEntry.document.push(document)
        : (letEntry.document = [document]);
      setDocumentUploadComplete(true);
    },
  });

  const onDocumentUpload = (documentInput) => {
    setDocumentUploadComplete(false);
    addLetDocument(itemId, letEntry._id, documentInput);
  };

  const image = (
    <div className="p-2">
      <img
        src={letEntry.createdBy.image}
        alt="user"
        width="50"
        className="rounded-circle"
      />
    </div>
  );

  const badge = (
    <span
      className={
        letEntry.state === 'recommended'
          ? 'badge badge-warning'
          : 'badge badge-success'
      }
    >
      {letEntry.state}
    </span>
  );
  const value = (
    <h6 className={letEntry.value < 0 ? 'font-medium in-minus' : 'font-medium'}>
      {letEntry.value < 0
        ? `(${currencify(letEntry.value, true, 2)})`
        : currencify(letEntry.value, true, 2)}
    </h6>
  );
  const notes = <span className="mb-3 d-block">{letEntry.notes}</span>;
  const date = (
    <span className="text-muted float-right">
      <b>{letEntry.createdBy.name}</b>{' '}
      {moment(letEntry.timestamp).fromNow(true)} ago
    </span>
  );
  return (
    <>
      <div className="d-flex flex-row comment-row mt-0 border-bottom">
        {image}
        <div className="comment-text w-100">
          {value}
          {notes}
          <div className="comment-footer">
            {date}
            {badge}
            <span className="action-icons">
              <a
                id={`btnUpload${letEntry._id}`}
                href="#upload"
                onClick={() => toggleDocumentListModal()}
              >
                <i className="fas fa-file font-medium" />
              </a>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen.upload}
                target={`btnUpload${letEntry._id}`}
                toggle={() => toggle('upload')}
              >
                Upload Document
              </Tooltip>
              {letEntry.state === 'recommended' ? (
                <>
                  <a
                    id={`btnApprove${letEntry._id}`}
                    href="#approve"
                    onClick={() => approve(letEntry._id)}
                  >
                    <i className="ti-check font-medium" />
                  </a>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen.approve}
                    target={`btnApprove${letEntry._id}`}
                    toggle={() => toggle('approve')}
                  >
                    Approve Recommendation
                  </Tooltip>
                </>
              ) : (
                <div />
              )}
            </span>
          </div>
        </div>
      </div>
      <DocumentListModal
        documents={letEntry.document}
        onDocumentUpload={onDocumentUpload}
        documentUploadComplete={isDocumentUploadComplete}
        documentUploadLoading={loading}
        isOpen={isDocumentListModelOpen}
        toggle={toggleDocumentListModal}
        header="Let Documents"
        categoryOptions={letDocumentCategoryOptions}
      />
    </>
  );
};

export default Let;
