import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap';
import { gql, useQuery } from '@apollo/client';
// Local Imports
import { withProject } from '../../common';
import { FundingContext } from '../../contexts';
import { AccountCard } from '../../components';
import FundingCardsContainer from './fundingCardsContainer';
import FundingHeader from './header';
import FundingAccountModal from './fundingAccountModal';

// GraphQL Queries
const GET_FUNDING_ACCOUNTS_BY_PROJECT = gql`
  query getFundingAccountsByProject($projectId: ID!) {
    getFundingAccountsByProject(projectId: $projectId) {
      _id
      name
      amount
      allocated
    }
  }
`;

/**
 * Calculates percentage (i.e. (amount/total) * 100)
 * @param {number} amount The amount of the total
 * @param {*} total The total amount
 */
const calculatePercentage = (amount, total) => {
  if (!total) return 0;
  return (amount/total * 100);
}

const Funding = ({ project }) => {
  const [fundingAccounts, setFundingAccounts] = useState([]);
  const [currFundingAccount, setCurrFundingAccount] = useState("");
  // For showing/hiding our modal
  const [showAccountCardModal, setShowAccountCardModal] = useState(false);


  useEffect(() => {
    if (currFundingAccount !== "") {
      setShowAccountCardModal(true);
    }
  }, [currFundingAccount]);

  // GraphQL Stuff
  const {
    loading: loadingGetFundingAccountsByProject,
    error: errorGetFundingAccountsByProject,
    data: dataGetFundingAccountsByProject,
  } = useQuery(GET_FUNDING_ACCOUNTS_BY_PROJECT, {
    variables: {
      projectId: project._id,
    },
    onCompleted: (data) => {
      setFundingAccounts(data.getFundingAccountsByProject);
    },
    fetchPolicy: 'cache-and-network',
  });

  // Handler function for opening/closing our modal
  const toggleAccountCardModal = () => {
    /**
     * Only if account card modal is currently active, meaning current invocation of this  
    **/ 
    if (showAccountCardModal) {
      setCurrFundingAccount("");
    }
    setShowAccountCardModal(() => !showAccountCardModal);
  };

  return (
    <>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          {`Portfolio`}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/projects/${project._id}`}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">{`Funding`}</BreadcrumbItem>
      </Breadcrumb>
      <FundingContext.Provider value={{ setFundingAccounts, projectId: project._id }}>
        <FundingHeader projectId={project._id} />
        <FundingCardsContainer>
          {_.map(fundingAccounts, (value) => {
            return (
              <AccountCard
                key={value.name}
                heading={value.name}
                bodyPrimaryText={`$${value.amount - value.allocated}`}
                bodySecondaryText={`unallocated`}
                footerPrimaryText={`$${value.allocated} allocated`}
                footerSecondaryText={`($${value.amount} total)`}
                percentage={calculatePercentage(value.allocated, value.amount)}
                onClick={() => setCurrFundingAccount(value.name)}
              />
            );
          })}
        </FundingCardsContainer>
        <FundingAccountModal
          accountName={currFundingAccount}
          modalActive={showAccountCardModal}
          toggleFunction={toggleAccountCardModal}
        />
      </FundingContext.Provider>
    </>
  );
};

export default withProject(Funding);
