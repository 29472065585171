import React, { Component } from 'react';

export default class DetailsStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client: this.props.getStore().client,
    };

    this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

    this.isValidated = this.isValidated.bind(this);
    this.validationCheck = this.validationCheck.bind(this);
  }

  isValidated() {
    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator
    let isDataValid = false;
    // if full validation passes then save to store and pass as valid
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === true;
      })
    ) {
      if (
        this.props.getStore().client.name !== userInput.name ||
        this.props.getStore().client.abn !== userInput.abn
      ) {
        // only update store of something changed

        this.props.updateStore({
          client: {
            ...userInput,
          },
          savedToCloud: false, // use this to notify step4 that some changes took place and prompt the user to save again
        }); // Update store here (this is just an example, in reality you will do it via redux or flux)
      }

      isDataValid = true;
    } else {
      // if anything fails then update the UI validation state but NOT the UI Data State
      this.setState(
        Object.assign(
          userInput,
          validateNewInput,
          this._validationErrors(validateNewInput),
        ),
      );
    }

    return isDataValid;
  }

  validationCheck() {
    if (!this._validateOnDemand) return;

    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator

    this.setState(
      Object.assign(
        userInput,
        validateNewInput,
        this._validationErrors(validateNewInput),
      ),
    );
  }

  _validateData(data) {
    return {
      clientVal: data.name !== '',
      //abnVal: this._validateAbn(data.abn)
    };
  }

  _validationErrors(val) {
    const errMsgs = {
      clientValMsg: val.clientVal ? '' : 'A valid client name is required',
      abnValMsg: val.abnVal ? '' : 'A valid ABN or ACN is required',
    };
    return errMsgs;
  }

  _grabUserInput() {
    return {
      name: this.name.value,
      abn: this.abn.value,
    };
  }

  /**
   * Stackoverflow: https://stackoverflow.com/questions/14174738/regex-to-match-australian-business-number-abn
   */
  _validateAbn = function (abn) {
    var isValid = true;

    //remove all spaces
    abn = abn.replace(/\s/g, '');

    //0. ABN must be 11 digits long
    isValid &= abn && /^\d{11}$/.test(abn);

    if (isValid) {
      var weightedSum = 0;
      var weight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

      //Rules: 1,2,3
      for (var i = 0; i < weight.length; i++) {
        weightedSum += (parseInt(abn[i]) - (i === 0 ? 1 : 0)) * weight[i];
      }

      //Rules: 4,5
      isValid &= weightedSum % 89 === 0;
    }

    return isValid === 1 ? true : false;
  };

  render() {
    // explicit class assigning based on validation
    let notValidClasses = {};

    if (typeof this.state.clientVal === 'undefined' || this.state.clientVal) {
      notValidClasses.clientCls = 'form-control';
    } else {
      notValidClasses.clientCls = 'is-invalid form-control';
      notValidClasses.clientValGrpCls = 'text-danger';
    }

    if (typeof this.state.abnVal === 'undefined' || this.state.abnVal) {
      notValidClasses.abnCls = 'form-control';
    } else {
      notValidClasses.abnCls = 'is-invalid form-control';
      notValidClasses.abnValGrpCls = 'text-danger';
    }

    return (
      <div className="step step1 mt-5 ">
        <div className="row justify-content-md-center">
          <div className="col col-lg-6">
            <div className="">
              <h4>Enter Principal's Legal / Invoicing Entity Name and ABN</h4>
              <form id="Form" className="form-horizontal mt-2">
                <div className="form-group content form-block-holder">
                  <div>
                    <input
                      ref={(f) => {
                        this.name = f;
                      }}
                      autoComplete="off"
                      type="text"
                      placeholder="eg. Metricon Highrise"
                      className={notValidClasses.clientCls}
                      defaultValue={this.state.client.name}
                      required
                      onBlur={this.validationCheck}
                    />
                    <small className="badge badge-default badge-projx text-white float-left">
                      Client Name
                    </small>
                  </div>
                </div>
                <div className="form-group content form-block-holder mt-5">
                  <div>
                    <input
                      ref={(f) => {
                        this.abn = f;
                      }}
                      autoComplete="off"
                      type="text"
                      placeholder="eg. 33 051 775 556"
                      className={notValidClasses.abnCls}
                      //required
                      defaultValue={this.state.client.abn}
                      //onBlur={this.validationCheck}
                    />
                    <small className="badge badge-default badge-projx text-white float-left">
                      ABN / ACN
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
