import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import { Table, Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import {
  BudgetHeader,
  BudgetFooter,
  BudgetType,
  BudgetProvisionalItem,
  disableForecast,
} from '..';

import { useQueryBudgetsByType } from '../../../../../hooks';
import {
  updateArrayByKey,
  copyArrayReference,
  howManyApproved,
  costStats,
} from '../../../../../common';
import { BudgetConfigContext } from '../../../../../contexts';

const BudgetProvisionalTable = ({
  project,
  view,
  requestForItem,
  details,
  onTotal = () => {},
  onValidate = () => {},
  createNewLineItem = () => {},
  costCode,
  claim,
  onChange = () => {},
}) => {
  const [provisionals, setProvisionals] = useState([]);
  // This is to do with our currency decimals:
  const { config } = useContext(BudgetConfigContext);
  //
  const { loading, getBudgetsByType, emptyBudgetItem, refetchBudgets } =
    useQueryBudgetsByType({
      costCode: details.costCode.code,
      projectId: details.project._id,
      vendorId: details.vendor._id,
      type: BudgetType.Provisional,
      onCompleted: (data) => {
        setProvisionals(data);
      },
    });

  const onDelete = (id) => {
    const clone = copyArrayReference(provisionals);
    _.remove(clone, (i) => i._id === id);
    setProvisionals(clone);
  };

  const [totals, setTotals] = useState({
    forecast: {
      anticipated: 0,
      submitted: 0,
      recommended: 0,
    },
    committed: {
      contracted: 0,
      let: 0,
      adjustment: 0,
    },
    fcc: 0,
    expenditure: {
      allowed: 0,
      thisClaim: 0,
      remaining: 0,
      current: 0,
      previous: 0,
    },
  });

  useEffect(() => getBudgetsByType(), []);

  useEffect(() => {
    if (requestForItem && requestForItem.value === BudgetType.Provisional)
      createItem();
  }, [requestForItem]);

  const createItem = () => {
    const copy = [...provisionals];
    copy.push(
      emptyBudgetItem({
        type: BudgetType.Provisional,
        vendor: details.vendor._id,
        project: details.project._id,
        costCode: details.costCode.code,
        accountsPayable: {
          key: costCode.accountsPayableCategory.name,
          value: costCode.accountsPayableCategory.value,
        },
        fundingSource: {
          key: costCode.fundingSourceCategory.name,
          value: costCode.fundingSourceCategory.value,
        },
      }),
    );
    setProvisionals(copy);
  };

  const onCalculationCompleted = (item) =>
    setProvisionals(updateArrayByKey(item, provisionals));

  const calculateTotals = () => {
    const clone = { ...totals };
    clone.forecast.anticipated = _.sumBy(provisionals, (item) =>
      disableForecast(item, 'anticipated') ? 0 : item.forecast.anticipated,
    );
    clone.forecast.submitted = _.sumBy(provisionals, (item) =>
      disableForecast(item, 'submitted') ? 0 : item.forecast.submitted,
    );
    clone.forecast.recommended = _.sumBy(provisionals, (item) =>
      disableForecast(item, 'recommended') ? 0 : item.forecast.recommended,
    );
    clone.committed.contracted = _.sumBy(
      provisionals,
      (item) => item.committed.contracted,
    );
    clone.committed.let = _.sumBy(provisionals, (item) =>
      _.sumBy(item.committed.let, (item) => item.value),
    );
    clone.committed.adjustment = _.sumBy(
      provisionals,
      (item) => item.committed.adjustment,
    );
    clone.fcc = _.sumBy(provisionals, (item) => item.fcc);

    clone.expenditure.allowed = _.sumBy(
      provisionals,
      (item) => item.expenditure.allowed,
    );
    clone.expenditure.thisClaim = _.sumBy(
      provisionals,
      (item) => item.expenditure.thisClaim,
    );
    clone.expenditure.remaining = _.sumBy(
      provisionals,
      (item) => item.expenditure.remaining,
    );
    clone.expenditure.current = _.sumBy(provisionals, (item) =>
      item.expenditure.current ? item.expenditure.current.amount : 0,
    );
    clone.expenditure.previous = _.sumBy(provisionals, (item) =>
      item.expenditure.previous && item.expenditure.previous.length > 0
        ? _.sumBy(item.expenditure.previous, (p) => p.amount)
        : 0,
    );

    setTotals(clone);
    onTotal({
      type: BudgetType.Provisional,
      totals: {
        ffc: clone.fcc ? clone.fcc : 0,
        contracted: clone.committed.contracted ? clone.committed.contracted : 0,
        adjustments: clone.committed.adjustment
          ? clone.committed.adjustment
          : 0,
        thisClaim: clone.expenditure.thisClaim,
        forecasts:
          clone.forecast.anticipated +
          clone.forecast.submitted +
          clone.forecast.recommended,
        expenditure: { ...clone.expenditure },
        count: provisionals.length,
        approved: howManyApproved(provisionals),
        costStats: costStats(provisionals),
      },
    });
  };

  const validate = () => {
    const validation = {
      [BudgetType.Provisional]: [],
    };
    provisionals.forEach((provisional) => {
      if (
        provisional.committed.contracted == null ||
        provisional.committed.contracted === undefined
      )
        validation[BudgetType.Provisional].push({
          section: 'provisional.committed.contracted',
          message: `You have a provisional sum line item "${provisional.ref}" without a contracted amount. Please provide an amout or remove the item prior to locking contract.`,
        });
    });
    onValidate(validation);
  };

  useEffect(() => {
    calculateTotals();
    validate();
    onChange(provisionals);
  }, [provisionals]);

  useEffect(() => {
    if (claim) refetchBudgets();
  }, [claim]);

  return provisionals.length > 0 ? (
    <Row>
      <Col md={12}>
        <h5 className="mt-2 provisional">
          <i className="fas fa-list" /> Provisional Sums
        </h5>
        {!loading ? (
          <div className="belvedere-responsive">
            <Table size="sm" hover className="budget-entry">
              <thead>
                <BudgetHeader view={view} type={BudgetType.Provisional} />
              </thead>
              <tbody>
                {provisionals.map((item) => (
                  <BudgetProvisionalItem
                    project={project}
                    view={view}
                    onCalculationCompleted={onCalculationCompleted}
                    details={details}
                    item={item}
                    key={item._id}
                    onDelete={onDelete}
                  />
                ))}
              </tbody>
              <tfoot>
                <BudgetFooter
                  view={view}
                  type={BudgetType.Provisional}
                  details={details}
                  value="provisional"
                  totals={totals}
                  precision={config.decimal ? 2 : 0}
                  createNewLineItem={createNewLineItem}
                />
              </tfoot>
            </Table>
          </div>
        ) : (
          <Skeleton />
        )}
      </Col>
    </Row>
  ) : (
    <div />
  );
};

export default BudgetProvisionalTable;
