import styled from 'styled-components';

export const TextAreaStyled = styled.textarea`
  width: 100%;
  min-height: 24px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  padding: 8px;
  margin-bottom: 16px;
  
  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px #4299E1;
  }
`;