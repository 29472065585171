import React from 'react';

import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  section: {
    marginTop: 10,
    padding: 2,
    fontSize: 11,
    fontFamily: 'Lato Bold',
    backgroundColor: '#f0f0f0',
  },
});

const Section = ({ text }) => {
  return <Text style={styles.section}>{text}</Text>;
};

export default Section;
