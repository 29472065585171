import React from 'react';

import { Text, StyleSheet } from '@react-pdf/renderer';
import { currencify } from '../../../common';

const styles = StyleSheet.create({
  label: {
    fontSize: 9,
    fontFamily: 'Lato Bold',
  },
  negative: {
    color: 'red',
  },
});

const Amount = ({ value }) => {
  return (
    <Text style={value < 0 ? [styles.label, styles.negative] : styles.label}>
      {value
        ? value < 0
          ? '(' + currencify(value) + ')'
          : currencify(value)
        : '-'}
    </Text>
  );
};

export default Amount;
