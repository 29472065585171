import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
//import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import useLocalStorage from '@rehooks/local-storage';
import { toast } from 'react-toastify';
import AuthContext from '../../contexts/auth/auth.context';

import browserHistory from '../../browserHistory';

import { relayStylePagination } from '@apollo/client/utilities';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URL,
});

const ApolloWrapper = ({ children }) => {
  const history = useHistory();
  const { isValidToken, idToken, id } = useContext(AuthContext);

  const localStorageKeyOrganisationId = `${id}.currentOrganisationId`;
  const localStorageKeyProjectnId = `${id}.currentProject`;

  const [organisationId] = useLocalStorage(localStorageKeyOrganisationId);

  const [projectId] = useLocalStorage(localStorageKeyProjectnId);

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${
            typeof locations === 'object'
              ? JSON.stringify(locations)
              : locations
          }, Path: ${path}`,
        );

        if (extensions && extensions.code) {
          switch (extensions.code) {
            case 'PROJECT_NOT_FOUND':
              browserHistory.push('/notfound');
              break;
            default:
              break;
          }
        }
      });

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      if (networkError.message && networkError.message.length > 0)
        toast.error(networkError.message);
    }
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 0,
    },
    attempts: {
      max: 2,
      retryIf: async (err) => {
        // when unauthorize response from server, logout
        if ([401, 403].includes(err.statusCode)) {
          history.push('/logout');
          return true;
        }
        return true;
      },
    },
  });

  const authLink = setContext((_, { headers }) => {
    if (isValidToken()) {
      // return the headers to the context so httpLink can read them

      return {
        headers: {
          ...headers,
          Authorization: idToken ? `${idToken}` : '',
          'X-Projx-ProjectId': projectId || '',
          'X-Projx-OrganisationId': organisationId || '',
        },
      };
    }
    // invalid token, logout user
    return history.push('/logout');
  });

  const client = new ApolloClient({
    link: errorLink.concat(retryLink).concat(authLink).concat(uploadLink),
    //cache: new InMemoryCache(),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getActivities: relayStylePagination(),
          },
        },
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
