import styled, { css } from 'styled-components';

export const ButtonStyles = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  background-color: #E7224A;
  color: #F9F9F9;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  outline: none;
  
  &:focus {
    outline: none;
  }

  ${(props) => props.outline && css`
    background-color: #F9F9F9;
    color: #E7224A;
    border: 1px solid #E7224A;

    &:hover {
      background-color: #E7224A;
      color: #F9F9F9;
    }
  `}

  ${(props) => props.disabled && css`
    background-color: #FBBAC7;
    cursor: not-allowed;
  `}

  ${(props) => !props.disabled && !props.outline && css`
    &:hover {
      background-color: #F2607E;
    }
  `}
`;
