import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';
import Select from 'react-select';
import 'react-multi-email/style.css';
import {
  useOrganisationMembers,
  useRolesByOrg,
  useUpdateProjectCollaborator,
  useDeleteProjectCollaborator,
} from '../../../../hooks';
import UserContext from '../../../../contexts/user/user.context';
import OrganisationContext from '../../../../contexts/organisation/organisation.context';
import UserAdd from './UserAdd';

const UserList = ({ project }) => {
  const [modal, setModal] = useState(false);
  const { user } = useContext(UserContext);
  const [collaborators, setCollaborators] = useState(project.collaborators);
  const { currentOrganisation } = useContext(OrganisationContext);
  const [updateProjectCollaborator] = useUpdateProjectCollaborator();
  const [deleteProjectCollaborator] = useDeleteProjectCollaborator();

  const {
    data: { members },
  } = useOrganisationMembers(
    currentOrganisation ? currentOrganisation._id : undefined,
  );
  const { roles } = useRolesByOrg(
    currentOrganisation ? currentOrganisation._id : undefined,
  );

  const toggle = () => {
    setModal(!modal);
  };

  const updateCollaboratorRole = (userId, roleId) => {
    updateProjectCollaborator(project._id, {
      user: userId,
      role: roleId,
    }).catch((err) => console.error(err));
  };

  const deleteCollaborator = (userId) => {
    deleteProjectCollaborator(project._id, userId).catch((err) =>
      console.error(err),
    );

    toggle();

    setCollaborators(
      collaborators.filter((collaborator) => collaborator.user._id !== userId),
    );
  };

  return (
    <Card>
      <CardBody>
        <div className="d-md-flex align-items-center">
          <div>
            <CardTitle>Collaborators</CardTitle>
          </div>
          <div className="ml-auto d-flex no-block align-items-center">
            <div className="dl">
              <UserAdd
                organisation={currentOrganisation}
                project={project}
                collaborators={collaborators}
                members={members}
                roles={roles}
              />
            </div>
          </div>
        </div>
        <Table no-wrap v-middle responsive className="no-wrap v-middle">
          <thead>
            <tr className="border-0">
              <th className="border-0">Name</th>
              <th className="border-0">Email</th>
              <th className="border-0">Role</th>
            </tr>
          </thead>
          <tbody>
            {collaborators.map((collaborator) => {
              const {
                role: { _id: roleId, name: roleName },
                user: { _id: userId, name: userName, email, image },
              } = collaborator;

              return (
                <tr key={userId}>
                  <td>
                    <div className="d-flex no-block align-items-center">
                      <div className="mr-2">
                        <img
                          src={image}
                          className="rounded-circle"
                          width="45"
                          alt="user"
                        />
                      </div>
                      {userName}
                    </div>
                  </td>
                  <td>{email}</td>
                  <td>
                    <Select
                      closeMenuOnSelect
                      isClearable={false}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      onChange={({ value }) => {
                        updateCollaboratorRole(userId, value);
                      }}
                      menuPortalTarget={document.body}
                      isDisabled={
                        project.owner._id === userId ||
                        (user && user._id === userId)
                      }
                      defaultValue={{ value: roleId, label: roleName }}
                      options={roles.map((orgRole) => ({
                        value: orgRole._id,
                        label: orgRole.name,
                      }))}
                    />
                  </td>
                  <td>
                    <div className="float-right">
                      <Button
                        onClick={toggle}
                        disabled={
                          project.owner._id === userId ||
                          (user && user._id === userId)
                        }
                        color="danger"
                      >
                        <i className="fa fa-trash" />
                      </Button>
                      <Modal isOpen={modal} toggle={toggle}>
                        <ModalBody>
                          Are you sure you want to remove this collaborator from
                          the project?
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            color="danger"
                            onClick={() => {
                              deleteCollaborator(userId);
                            }}
                          >
                            Remove
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default UserList;
