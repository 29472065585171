import gql from 'graphql-tag';

const CREATE_FOCOTR_MUTATION = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    organisation {
      _id
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  mutation ($input: FocotrInput!) {
    createFocotr(input: $input) {
      _id
      costCode {
        code
        name
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      locked
      accountType
      invoices {
        _id
        invoiceId
        date
        gstType
        gstValue
        claimedAmount
        claimedAmountComments
        qsRecommendation
        qsAmount
        comments
        timestamp
        paymentSchedule {
          projectName
          principal
          contractor
          issueDate
          originalContractSum
          variationsApprovedToDate
          provisionalSumAdjustment
          adjustedContractSum
          contractorPaymentReference
          contractorPaymentClaimDate
          totalAmountOfPaymentClaim
          contractWorks
          variationWorks
          provisionalSums
          workExecutedSubTotal
          previouslyAssessed
          thisClaim
          gst
          includingGst
          reasonIfAmountsDiffer
          comments
        }
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      order
    }
  }
`;

const UPDATE_FOCOTR_MUTATION = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    organisation {
      _id
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  mutation ($_id: ID!, $input: FocotrInput!) {
    updateFocotr(_id: $_id, input: $input) {
      _id
      costCode {
        code
        name
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      locked
      accountType
      invoices {
        _id
        invoiceId
        date
        gstType
        gstValue
        claimedAmount
        claimedAmountComments
        qsRecommendation
        qsAmount
        comments
        timestamp
        paymentSchedule {
          projectName
          principal
          contractor
          issueDate
          originalContractSum
          variationsApprovedToDate
          provisionalSumAdjustment
          adjustedContractSum
          contractorPaymentReference
          contractorPaymentClaimDate
          totalAmountOfPaymentClaim
          contractWorks
          variationWorks
          provisionalSums
          workExecutedSubTotal
          previouslyAssessed
          thisClaim
          gst
          includingGst
          reasonIfAmountsDiffer
          comments
        }
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      order
    }
  }
`;

const GET_FOCOTR_BY_PROJECT_QUERY = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    organisation {
      _id
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  query ($projectId: ID!) {
    getFocotrsByProject(projectId: $projectId) {
      _id
      costCode {
        code
        name
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      locked
      accountType
      invoices {
        _id
        invoiceId
        date
        gstType
        gstValue
        claimedAmount
        claimedAmountComments
        qsRecommendation
        qsAmount
        comments
        timestamp
        paymentSchedule {
          projectName
          principal
          contractor
          issueDate
          originalContractSum
          variationsApprovedToDate
          provisionalSumAdjustment
          adjustedContractSum
          contractorPaymentReference
          contractorPaymentClaimDate
          totalAmountOfPaymentClaim
          contractWorks
          variationWorks
          provisionalSums
          workExecutedSubTotal
          previouslyAssessed
          thisClaim
          gst
          includingGst
          reasonIfAmountsDiffer
          comments
        }
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      order
    }
  }
`;

const GET_FOCOTR_BY_PROJECT_AND_COST_QUERY = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    organisation {
      _id
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  query ($projectId: ID!, $costCode: String!) {
    getFocotrsByProjectAndCost(projectId: $projectId, costCode: $costCode) {
      _id
      costCode {
        code
        name
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      locked
      accountType
      invoices {
        _id
        invoiceId
        date
        gstType
        gstValue
        claimedAmount
        claimedAmountComments
        qsRecommendation
        qsAmount
        comments
        timestamp
        paymentSchedule {
          projectName
          principal
          contractor
          issueDate
          originalContractSum
          variationsApprovedToDate
          provisionalSumAdjustment
          adjustedContractSum
          contractorPaymentReference
          contractorPaymentClaimDate
          totalAmountOfPaymentClaim
          contractWorks
          variationWorks
          provisionalSums
          workExecutedSubTotal
          previouslyAssessed
          thisClaim
          gst
          includingGst
          reasonIfAmountsDiffer
          comments
        }
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      order
    }
  }
`;

const GET_FOCOTR_QUERY = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    organisation {
      _id
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  query ($_id: ID!) {
    getFocotr(_id: $_id) {
      _id
      costCode {
        code
        name
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      locked
      accountType
      invoices {
        _id
        invoiceId
        date
        gstType
        gstValue
        claimedAmount
        claimedAmountComments
        qsRecommendation
        qsAmount
        comments
        timestamp
        paymentSchedule {
          projectName
          principal
          contractor
          issueDate
          originalContractSum
          variationsApprovedToDate
          provisionalSumAdjustment
          adjustedContractSum
          contractorPaymentReference
          contractorPaymentClaimDate
          totalAmountOfPaymentClaim
          contractWorks
          variationWorks
          provisionalSums
          workExecutedSubTotal
          previouslyAssessed
          thisClaim
          gst
          includingGst
          reasonIfAmountsDiffer
          comments
        }
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      order
    }
  }
`;

//codeHasAssociatedCommitments

const CODE_HAS_ASSOCIATED_COMMITMENTS_QUERY = gql`
  query ($projectId: ID!, $code: String!) {
    codeHasAssociatedCommitments(projectId: $projectId, code: $code)
  }
`;

const FOCOTR_INVOICES_QUERY = gql`
  query ($_id: ID!) {
    getInvoices(_id: $_id) {
      _id
      invoiceId
      date
      gstType
      gstValue
      claimedAmount
      claimedAmountComments
      qsRecommendation
      qsAmount
      comments
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      timestamp
      paymentSchedule {
        projectName
        principal
        contractor
        issueDate
        originalContractSum
        variationsApprovedToDate
        provisionalSumAdjustment
        adjustedContractSum
        contractorPaymentReference
        contractorPaymentClaimDate
        totalAmountOfPaymentClaim
        contractWorks
        variationWorks
        provisionalSums
        workExecutedSubTotal
        previouslyAssessed
        thisClaim
        gst
        includingGst
        reasonIfAmountsDiffer
        comments
      }
    }
  }
`;

const ADD_FOCOTR_DOCUMENT_MUTATION = gql`
  mutation ($_id: ID!, $documentInput: DocumentInput!) {
    addFocotrDocument(_id: $_id, documentInput: $documentInput) {
      _id
      title
      category
      notes
      uploadedBy
      location
      createdAt
    }
  }
`;

const ADD_FOCOTR_INVOICE_DOCUMENT_MUTATION = gql`
  mutation ($_id: ID!, $invoiceId: ID!, $documentInput: DocumentInput!) {
    addFocotrInvoiceDocument(
      _id: $_id
      invoiceId: $invoiceId
      documentInput: $documentInput
    ) {
      _id
      title
      category
      notes
      uploadedBy
      location
      createdAt
    }
  }
`;

const UPDATE_FOCOTR_ORDER_MUTATION = gql`
  mutation ($orders: [FocotrOrder!]!) {
    updateOrder(orders: $orders)
  }
`;

const CAN_DELETE_FOCOTR_QUERY = gql`
  query ($_id: ID!) {
    canDeleteFocotr(_id: $_id)
  }
`;

const DELETE_FOCOTR_MUTATION = gql`
  mutation ($_id: ID!) {
    deleteFocotr(_id: $_id)
  }
`;

export {
  CREATE_FOCOTR_MUTATION,
  UPDATE_FOCOTR_MUTATION,
  GET_FOCOTR_BY_PROJECT_QUERY,
  GET_FOCOTR_BY_PROJECT_AND_COST_QUERY,
  GET_FOCOTR_QUERY,
  CODE_HAS_ASSOCIATED_COMMITMENTS_QUERY,
  ADD_FOCOTR_DOCUMENT_MUTATION,
  FOCOTR_INVOICES_QUERY,
  CAN_DELETE_FOCOTR_QUERY,
  DELETE_FOCOTR_MUTATION,
  ADD_FOCOTR_INVOICE_DOCUMENT_MUTATION,
  UPDATE_FOCOTR_ORDER_MUTATION,
};
