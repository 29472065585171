import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardSubtitle,
  Table,
  CardBody,
  Input,
  Label,
  CardLink,
} from 'reactstrap';
import _ from 'lodash';
import OrganisationContext from '../../../contexts/organisation/organisation.context';
import AuthContext from '../../../contexts/auth/auth.context';

import { useOrganisationMembers, useRolesByOrg } from '../../../hooks';
import { updateArrayByKey } from '../../../common';

const InviteStep = ({ getStore, updateStore }) => {
  const [contacts, setContacts] = useState([]);
  const [modifiedMembers, setModifiedMembers] = useState([]);

  const { currentOrganisation } = useContext(OrganisationContext);
  const { id } = useContext(AuthContext);

  const { data, loading } = useOrganisationMembers(
    currentOrganisation ? currentOrganisation._id : undefined,
  );
  const { loading: rolesLoading, getRoleId } = useRolesByOrg(
    currentOrganisation ? currentOrganisation._id : undefined,
  );

  const emailSelectChanged = (e, contact) => {
    contact.isChecked = e.target.checked;
    const mod = updateArrayByKey(contact, modifiedMembers);

    autoAddMembers(mod);
  };

  const memberExists = (member) =>
    _.find(
      getStore().invitees,
      (invitee) => invitee.user === member.user._id,
    ) !== undefined;

  const autoAddMembers = (members) => {
    const checked = [];

    _.forEach(members, (member) => {
      if (member.isChecked === undefined)
        member.isChecked = memberExists(member);

      if (member.isAdmin || member.user._id === id || member.isChecked) {
        const invitee = {
          user: member.user._id,
          role: member.isAdmin ? getRoleId('admin') : getRoleId('viewer'),
          member,
        };
        checked.push(invitee);
      }
    });

    setModifiedMembers(members);
    setContacts(checked);
  };

  useEffect(() => {
    if (!loading && !rolesLoading) autoAddMembers(data.members);
  }, [loading, rolesLoading]);

  useEffect(() => {
    if (contacts) updateStore({ invitees: contacts });
  }, [contacts]);

  return (
    <>
      <div className="step step1 mt-5">
        <div className="row justify-content-md-center">
          <div className="col col-lg-5">
            <div className="">
              <h4>
                <code>Almost finished!</code> Anybody else you wish to invite to
                the project space?
              </h4>
              <Row>
                <Col md={12}>
                  <Card>
                    <div className="p-3">
                      <CardTitle>
                        <i className="far fa-user mr-2" />
                        Name or Email
                      </CardTitle>
                      <CardSubtitle className="mb-0">
                        You have <code>{contacts.length}</code> invitees.
                      </CardSubtitle>
                    </div>
                    <CardBody>
                      <div className="table-responsive contact-list mb-4">
                        <Table className="table contact-table no-wrap table-hover v-middle mb-0">
                          <tbody>
                            {modifiedMembers.map((contact) => (
                              <tr className="bg-light" key={contact.user._id}>
                                <td style={{ width: `${25}px` }}>
                                  <img
                                    src={contact.user.image}
                                    className="rounded-circle"
                                    width="25"
                                    alt={contact.user.image}
                                  />
                                </td>
                                <td>
                                  <p className="mb-0 text-truncate font-12">
                                    {contact.user.name} ({contact.user.email})
                                  </p>
                                  <p className="mb-0 text-muted font-10">
                                    {contact.user.title}{' '}
                                    <b className="text-danger">
                                      {contact.isAdmin
                                        ? '(Owner)'
                                        : contact.user._id === id
                                        ? '(Owner)'
                                        : '(Viewer)'}
                                    </b>
                                  </p>
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: `${25}px` }}
                                >
                                  <Input
                                    disabled={
                                      contact.isAdmin || id === contact.user._id
                                    }
                                    checked={
                                      contact.isAdmin ||
                                      id === contact.user._id ||
                                      contact.isChecked
                                    }
                                    type="checkbox"
                                    onChange={(e) =>
                                      emailSelectChanged(e, contact)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                    <CardBody className="border-top">
                      <Label className="font-12">
                        Not on the list? Head over to{' '}
                        <CardLink href="/settings-v2/users">Settings</CardLink>{' '}
                        and invite them to your org!
                      </Label>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteStep;
