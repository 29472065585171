import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
// Material UI
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

// Custom styles
import {
  ProjectCardContainer,
  ProjectCardHeaderContainer,
  ProjectCardHeading,
  ProjectCardInfoContainer,
  ProjectCardCodeText,
  ProjectCardTimeStamp,
  ProjectCardFooterContainer,
  ProjectCardFooterButtonGroup,
  ProjectCardFooterButton,
} from './styles/ProjectCards';
import { StarIcon } from './styles/ProjectCardIcons';

const ProjectCard = ({ projectId, code, name, address, collaborators, index }) => {
  const history = useHistory();
  const [showFooterButtons, setShowFooterButtons] = useState(false);

  const handleProjectStar = (e) => {
    // Stop event bubbling to ensure parent onClick handler `handleProjectCardClick` is not triggered
    e.stopPropagation();
    console.log('TODO: Star this Project !!');
  }

  const handleProjectCardClick = (e) => {
    history.push(`/projects/${projectId}/summary`);
  }

  return (
    <ProjectCardContainer
      onMouseOver={() => setShowFooterButtons(true)}
      onMouseOut={() => setShowFooterButtons(false)}
      onClick={(e) => handleProjectCardClick(e)}
    >
      <ProjectCardHeaderContainer>
        <ProjectCardHeading>{name}</ProjectCardHeading>
        <ProjectCardInfoContainer>
          <ProjectCardCodeText index={index}>
            {code}
          </ProjectCardCodeText>
          <ProjectCardTimeStamp>{address}</ProjectCardTimeStamp>
        </ProjectCardInfoContainer>
      </ProjectCardHeaderContainer>
      <ProjectCardFooterContainer>
        <AvatarGroup max={3}>
          {_.map(collaborators, (value, index) => (
            <Avatar alt={value.user.name} src={value.user.image} />
          ))}
        </AvatarGroup>
        <ProjectCardFooterButtonGroup visible={showFooterButtons}>
          <ProjectCardFooterButton onClick={(e) => handleProjectStar(e)}>
            <StarIcon />
          </ProjectCardFooterButton>
        </ProjectCardFooterButtonGroup>
      </ProjectCardFooterContainer>
    </ProjectCardContainer>
  );
};

export default ProjectCard;
