import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { positions, Provider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import AuthContext from '../contexts/auth/auth.context';
import InvitationContext from '../contexts/invitation/invitation.context';
import { ProjectProv } from '../contexts';
import UserProvider from '../contexts/user/user.provider';
import PanelProvider from '../contexts/panel/panelProvider';
import Fulllayout from '../layouts/fulllayout';
import Settings from '../views/settings/settings';
import OrganisationProvider from '../contexts/organisation/organisation.provider';
import GlobalReferenceProvider from '../contexts/global/globalReference.provider';
import ProjectsProvider from '../contexts/projects/projects.provider';
import Invitation from '../components/invitation/invitation';
import NotFound from '../views/errors/NotFound';
import Logout from '../views/authentication/logout';

import BudgetProvider from '../contexts/budgetConfig/budgetConfig.provider';

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

const AuthenticatedRoutes = () => {
  const { authenticated } = useContext(AuthContext);
  const { invitationToken } = useContext(InvitationContext);

  if (!authenticated) return null;

  // available routes only when authenticated
  if (invitationToken) {
    return (
      <Route
        path="/"
        render={() => <Invitation invitationToken={invitationToken} />}
      />
    );
  }

  return (
    <Provider template={AlertTemplate} {...options}>
      <ProjectProv>
        <BudgetProvider>
          <PanelProvider>
            <UserProvider>
              <OrganisationProvider>
                <ProjectsProvider>
                  <GlobalReferenceProvider>
                    <Switch>
                      <Route path="/notfound" component={NotFound} />
                      <Route path="/settings-v2" component={Settings} />
                      <Route path="/logout" component={Logout} />
                      <Route path="/" component={Fulllayout} />
                      <Route render={() => <Redirect to="/" />} />
                    </Switch>
                  </GlobalReferenceProvider>
                </ProjectsProvider>
              </OrganisationProvider>
            </UserProvider>
          </PanelProvider>
        </BudgetProvider>
      </ProjectProv>
    </Provider>
  );
};
export default AuthenticatedRoutes;
