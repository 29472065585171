import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";
import { MdErrorOutline } from 'react-icons/md';

export const LoadingErrorStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
`;

export const ErrorIcon = styled(MdErrorOutline)`
  width: 70px;
  height: 70px;
  margin-bottom: 24px;
`;

export const LoadingErrorStateText = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
`;
// This is our Spinner from the 'react-spinners' library, we apply custom styles here
export const LoadingSpinner = styled(ClipLoader)`
`;
