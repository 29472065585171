import _ from 'lodash';

const randomColour = () => {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const evaulateError = (errorMessage) => {
  if (errorMessage.includes('E11000')) throw Error('Entry already exists.');
  return errorMessage;
};
const extractGraphqlError = (error) => {
  if (!error.graphQLErrors) return 'Unable to determine error.';

  if (Array.isArray(error.graphQLErrors)) {
    if (error.graphQLErrors.count <= 0) return 'Unable to determine error.';
    let errors = [];
    try {
      errors = _.map(error.graphQLErrors, (err) =>
        evaulateError(err.message),
      ).join(', ');
    } catch (e) {
      return e.message;
    }

    return errors;
  }
};


export { randomColour, toBase64, extractGraphqlError };
