import styled, { css } from 'styled-components';
import { MdOutlineUnfoldMore, MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';

const TableColumnHeaderIconStyles = css`
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;

const ReportingColumnIconStyles = css`
  width: 24px;
  height: 24px;
`;

export const UpArrow = styled(MdOutlineExpandLess)`
  ${TableColumnHeaderIconStyles}
`;

export const DownArrow = styled(MdOutlineExpandMore)`
  ${TableColumnHeaderIconStyles}
`;

export const VerticalArrows = styled(MdOutlineUnfoldMore)`
  ${TableColumnHeaderIconStyles}
`;

export const CheckIcon = styled(BsCheckCircleFill)`
  ${ReportingColumnIconStyles}
  color: #00D215;
`;

export const CrossIcon = styled(BsXCircleFill)`
  ${ReportingColumnIconStyles}
  color: #F83F23;
`;