import styled, { css } from 'styled-components';

export const PhaseBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #F9F9F9;
  font-size: 16px;
  padding: 8px;
  ${props => props.backgroundColor && css`
    background-color: ${props.backgroundColor}; 
  `}
`;
