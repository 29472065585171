// Library Imports
import React, { useState, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
// Local Imports
import { Button, Filter } from '../../../components';
import { MilestoneContext } from '../../../contexts';
import { filterPhaseSelectOptions, filterStatusSelectOptions } from '../constants';
import {
  AccordionContainer,
  FilterDatePicker,
  DateSeparationText,
  Separator,
} from './styles/FilterAccordion';

const statusSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    height: 100,
    overflow: 'auto',
  })
}

const FilterAccordion = ({ projectId }) => {
  // State for controlling our filter component
  const [milestonePhase, setMilestonePhase] = useState('ALL');
  const [startScheduleDate, setStartScheduleDate] = useState(null);
  const [endScheduleDate, setEndScheduleDate] = useState(null);
  const [startRevisedScheduleDate, setStartRevisedScheduleDate] = useState(null);
  const [endRevisedScheduleDate, setEndRevisedScheduleDate] = useState(null);
  const [startTargetDate, setStartTargetDate] = useState(null);
  const [endTargetDate, setEndTargetDate] = useState(null);
  const [startActualDate, setStartActualDate] = useState(null);
  const [endActualDate, setEndActualDate] = useState(null);
  const [milestoneStatus, setMilestoneStatus] = useState('ALL');

  const { 
    filterAccordionOpen,
    getMilestoneTableDataWithFilters,
  } = useContext(MilestoneContext);

  // Handler for our start date inputs
  const handleEndDateSelect = (date, startDate, updateStartDate, updateEndDate) => {
    if (moment(date).isBefore(moment(startDate))) {
      updateStartDate(endDate);
      updateEndDate(null);
    } else {
      updateEndDate(date);
    }
  };

  // Handler for our end date inputs
  const handleStartDateSelect = (date, endDate, updateStartDate, updateEndDate) => {
    if (moment(date).isAfter(moment(endDate))) {
      updateStartDate(date);
      updateEndDate(null);
    } else {
      updateStartDate(date);
    }
  };

  // Handler for resetting all filters to their defaults, so by default ALL results get rendered
  const handleResetFilters = () => {
    setMilestonePhase('ALL');
    setStartScheduleDate(null);
    setEndScheduleDate(null);
    setStartRevisedScheduleDate(null);
    setEndRevisedScheduleDate(null);
    setStartTargetDate(null);
    setEndTargetDate(null);
    setStartActualDate(null);
    setEndActualDate(null);
    setMilestoneStatus('ALL');
  };

  const handleApplyFilters = async () => {
    const filter = {
      phase: milestonePhase,
      startScheduleDate: startScheduleDate ? startScheduleDate.toISOString() : null,
      endScheduleDate: endScheduleDate ? endScheduleDate.toISOString() : null,
      startRevisedScheduleDate: startRevisedScheduleDate ? startRevisedScheduleDate.toISOString() : null,
      endRevisedScheduleDate: endRevisedScheduleDate ? endRevisedScheduleDate.toISOString() : null,
      startTargetDate: startTargetDate ? startTargetDate.toISOString() : null,
      endTargetDate: endTargetDate ? endTargetDate.toISOString() : null,
      startActualDate: startActualDate ? startActualDate.toISOString() : null,
      endActualDate: endActualDate ? endActualDate.toISOString() : null,
      status: milestoneStatus,
    };
    await getMilestoneTableDataWithFilters({
      variables: {
        projectId,
        filter, 
      }
    });
  };

  return (
    <AccordionContainer open={filterAccordionOpen}>
      <Filter>
        <Filter.Item>
          <Filter.Label>{'Phase:'}</Filter.Label>
          <Filter.Input>
            <div style={{ width: '180px'}}>
              <Select
                options={filterPhaseSelectOptions}
                value={_.find(filterPhaseSelectOptions, (item) => {
                  return item.value === milestonePhase;
                })}
                defaultValue={{ value: 'ALL', label: 'All' }}
                onChange={(e) => setMilestonePhase(e.value)}
              />
            </div>
          </Filter.Input>
        </Filter.Item>
        <Filter.Item>
          <Filter.Label>{'Schedule Date Range:'}</Filter.Label>
          <Filter.Input>
            <FilterDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              selected={startScheduleDate}
              onChange={(date) => handleStartDateSelect(date, endScheduleDate, setStartScheduleDate, setEndScheduleDate)}
            />
            <DateSeparationText>{'to'}</DateSeparationText>
            <FilterDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              selected={endScheduleDate}
              onChange={(date) => handleEndDateSelect(date, startScheduleDate, setStartScheduleDate, setEndScheduleDate)}
            />
          </Filter.Input>
        </Filter.Item>
        <Filter.Item>
          <Filter.Label>{'Revised Schedule Date Range:'}</Filter.Label>
          <Filter.Input>
            <FilterDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              selected={startRevisedScheduleDate}
              onChange={(date) => handleStartDateSelect(date, endRevisedScheduleDate, setStartRevisedScheduleDate, setEndRevisedScheduleDate)}
            />
            <DateSeparationText>{'to'}</DateSeparationText>
            <FilterDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              selected={endRevisedScheduleDate}
              onChange={(date) => handleEndDateSelect(date, startRevisedScheduleDate, setStartRevisedScheduleDate, setEndRevisedScheduleDate)}
            />
          </Filter.Input>
        </Filter.Item>
        <Filter.Item>
          <Filter.Label>{'Target Date Range:'}</Filter.Label>
          <Filter.Input>
            <FilterDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              selected={startTargetDate}
              onChange={(date) => handleStartDateSelect(date, endTargetDate, setStartTargetDate, setEndTargetDate)}
            />
            <DateSeparationText>{'to'}</DateSeparationText>
            <FilterDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              selected={endTargetDate}
              onChange={(date) => handleEndDateSelect(date, startTargetDate, setStartTargetDate, setEndTargetDate)}
            />
          </Filter.Input>
        </Filter.Item>
        <Filter.Item>
          <Filter.Label>{'Actual Date Range:'}</Filter.Label>
          <Filter.Input>
            <FilterDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              selected={startActualDate}
              onChange={(date) => handleStartDateSelect(date, endActualDate, setStartActualDate, setEndActualDate)}
            />
            <DateSeparationText>{'to'}</DateSeparationText>
            <FilterDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              selected={endActualDate}
              onChange={(date) => handleEndDateSelect(date, startActualDate, setStartActualDate, setEndActualDate)}
            />
          </Filter.Input>
        </Filter.Item>
        <Filter.Item>
          <Filter.Label>{'Status:'}</Filter.Label>
          <Filter.Input>
            <div style={{ width: '140px' }}>
              <Select
                styles={statusSelectStyles}
                options={filterStatusSelectOptions}
                value={_.find(filterStatusSelectOptions, (item) => {
                  return item.value === milestoneStatus;
                })}
                defaultValue={{ value: 'ALL', label: 'All' }}
                onChange={(e) => setMilestoneStatus(e.value)}
              />
            </div>
          </Filter.Input>
        </Filter.Item>
        <Filter.Footer>
          <Button outline onClick={handleResetFilters}>
            {`Reset`}
          </Button>
          <Separator />
          <Button onClick={handleApplyFilters}>{`Apply`}</Button>
        </Filter.Footer>
      </Filter>
    </AccordionContainer>
  );
};

export default FilterAccordion;
