import { useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';

import { GET_DOCUMENT_SIGNED_URL } from '../../graphql/queries';

const useDocumentDownload = () => {
  const [loading, setLoading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);

  const [_getDocumentSignedUrl, { data, error, refetch }] = useLazyQuery(
    GET_DOCUMENT_SIGNED_URL,
  );

  const downloadDocument = (document) => {
    setLoading(true);
    const _args = { variables: { location: document.location } };

    // refetch if already downloaded once else download for first time, after data will be defined
    if (data) refetch(_args);
    else _getDocumentSignedUrl(_args);
  };

  // handle once getting signed url is successfull
  useEffect(() => {
    if (data) {
      setLoading(false);
      setDownloaded(true);
      window.open(data.getDocumentSignedUrl.url);
    }
  }, [data]);

  return [
    downloadDocument,
    {
      loading,
      error,
      downloaded,
    },
  ];
};

export { useDocumentDownload };
