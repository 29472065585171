import React from 'react';
import { Link } from 'react-router-dom';
// New register component stuff
import logoDarkSVG from '../../assets/images/logo_dark.svg';
import { LogoIMG } from '../../components';
import {
  LoginPageContainer,
  LoginFormContainer,
  LogoContainer,
  LoginFormBody,
  LoginFormHeading,
  LoginFormLink,
  LoginFormText,
} from './styles/LoginStyles';

const Register = () => {
  return (
    <LoginPageContainer>
      <LoginFormContainer center>
        <Link to="/">
          <LogoContainer>
            <LogoIMG src={logoDarkSVG} alt="projx-logo" />
          </LogoContainer>
        </Link>
        <LoginFormBody>
          <LoginFormHeading>Register</LoginFormHeading>
          <LoginFormText>
            Please contact your administrator to assist you through the onboarding process.
          </LoginFormText>
          <LoginFormText>
            Already have an account?{' '}
            <LoginFormLink to="/authentication/login">
              Login
            </LoginFormLink>
          </LoginFormText>
        </LoginFormBody>
      </LoginFormContainer>
    </LoginPageContainer>
  );
}

export default Register;
