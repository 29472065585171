import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import { PROJECT_SUMMARY_QUERY, REPORT_QUERY } from '../../graphql/queries';

import { cleanGqlTypename } from '../../common';

const itemMapper = (item) => cleanGqlTypename(item);
const mapper = (items) => _.map(items, (item) => itemMapper(item));

const useSummary = ({ onCompleted = () => {} }) => {
  let [_getSummary, { loading, error, refetch }] = useLazyQuery(
    PROJECT_SUMMARY_QUERY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => onCompleted(mapper(_.get(data, 'summaryView'))),
    },
  );

  const getSummary = (projectId) => {
    return _getSummary({
      variables: {
        projectId,
      },
    });
  };

  return { getSummary, loading, error, refetch };
};

const useReport = ({ onCompleted = () => {} }) => {
  let jsonify = ({ data, reportType, query, totals }) => {
    return {
      query,
      reportType,
      data: data ? JSON.parse(data) : undefined,
      totals: totals ? JSON.parse(totals) : undefined,
    };
  };

  let [_runReport, { loading, error, refetch }] = useLazyQuery(REPORT_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) =>
      onCompleted(jsonify(cleanGqlTypename(_.get(data, 'reportQuery')))),
  });

  const runReport = ({ projectId, type, query }) => {
    return _runReport({
      variables: {
        projectId,
        type,
        query,
      },
    });
  };

  return [runReport, { loading, error, refetch }];
};

export { useSummary, useReport };
