import React, { useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  WidgetCardStyles,
  WidgetHeader,
  WidgetBody,
  DeleteWidgetButton,
  WidgetTitleContainer,
  WidgetOptions,
} from './styles/WidgetCard';
import { AiOutlineMinus } from 'react-icons/ai';
import { InfoButton, InfoIcon } from './styles/WidgetButtons';
import { widgetInfo } from '../../../constants';

// GraphQL Mutations
const REMOVE_WIDGET_FROM_DASHBOARD_TYPE = gql`
  mutation removeWidget(
    $projectId: ID!
    $widgetType: WidgetType!
    $dashboard: DashboardType!
  ) {
    removeWidget(
      projectId: $projectId
      widgetType: $widgetType
      dashboard: $dashboard
    ) {
      code
      success
      message
      widgets {
        _id
        type
        data
        description
        dashboard
      }
    }
  }
`;

/**
 * A component which returns a WidgetCard, which represents one Widget
 * @param {string} header The header of our component
 * @param {React.JSX} children The children of our WidgetCard
 * @param {string} type Represents the type of widget stored in this card
 * @param {string} projectId ID of the project to which this widgetCard belongs to
 * @param {string} height A character representing the size of the widget (One of 'M' or 'L')
 * @param {boolean} inEditMode A boolean representing whether we are editing our widgets or not
 * @param {Function} setWidgets A setState update function to update our widgets on the current dashboard
 * @param {string} dashboard A string representing what dashboard we are currently performing operations on
 * @returns {React.JSX} A widget card which we wrap every single widget in
*/
const WidgetCard = ({ header, children, type, projectId, height, inEditMode, setWidgets, dashboard }) => {
  const [deleteWidgetModal, setDeleteWidgetModal] = useState(false);
  const [widgetInfoModal, setWidgetInfoModal] = useState(false);

  const [
    removeWidget,
    {
      data: dataRemoveWidget,
      loading: loadingRemoveWidget,
      error: errorRemoveWidget,
    },
  ] = useMutation(REMOVE_WIDGET_FROM_DASHBOARD_TYPE, {
    onCompleted: (data) => {
      setWidgets(data.removeWidget.widgets);
    }
  });

  const toggleDeleteWidgetModal = () =>
    setDeleteWidgetModal(() => !deleteWidgetModal);
  const toggleWidgetInfoModal = () =>
    setWidgetInfoModal(() => !widgetInfoModal);

  const deleteWidget = async () => {
    await removeWidget({
      variables: {
        projectId,
        widgetType: type,
        dashboard,
      }
    });
    toggleDeleteWidgetModal();
  };

  return (
    <>
      <WidgetCardStyles inEditMode={inEditMode} height={height}>
        {inEditMode && (
          <DeleteWidgetButton onClick={toggleDeleteWidgetModal}>
            <AiOutlineMinus />
          </DeleteWidgetButton>
        )}
        <WidgetTitleContainer>
          <WidgetHeader>{header}</WidgetHeader>
          <WidgetOptions>
            <InfoButton onClick={toggleWidgetInfoModal}>
              <InfoIcon />
            </InfoButton>
          </WidgetOptions>
        </WidgetTitleContainer>
        <WidgetBody>{children}</WidgetBody>
      </WidgetCardStyles>
      <Modal
        isOpen={deleteWidgetModal}
        toggle={toggleDeleteWidgetModal}
        backdrop
      >
        <ModalHeader toggle={toggleDeleteWidgetModal}>
          Delete Widget
        </ModalHeader>
        <ModalBody>
          <span className="text-danger">
            <b>WARNING: </b>
          </span>
          You are about to delete the{' '}
          <span className="text-info">
            <b>{header}</b>
          </span>{' '}
          widget. After deleting, you will be unable to recover any data
          associated with this widget.
          <div className="mt-3">
            <span className="font-14">
              <b>Are you sure you want to continue ?</b>
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="float-left"
            color="projx"
            outline
            onClick={toggleDeleteWidgetModal}
          >
            Cancel
          </Button>
          <Button color="projx" onClick={deleteWidget}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={widgetInfoModal} toggle={toggleWidgetInfoModal} backdrop>
        <ModalHeader toggle={toggleWidgetInfoModal}>
          {header} Widget
        </ModalHeader>
        <ModalBody>{widgetInfo[type]}</ModalBody>
        <ModalFooter>
          <Button
            className="float-left"
            color="projx"
            outline
            onClick={toggleWidgetInfoModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default WidgetCard;
