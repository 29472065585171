import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Reports from './Reports';

const ReportCard = ({ report, project }) => {
  return (
    <Card>
      <CardHeader>{report.group}</CardHeader>
      <CardBody>
        <Reports reports={report.reports} project={project} />
      </CardBody>
    </Card>
  );
};

export default ReportCard;
