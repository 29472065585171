import styled from 'styled-components';
import Chart from 'react-c3-component';

export const StyledSafetyIncidentLineGraph = styled(Chart)`
  width: 100%;
  font-family: 'Inter', sans-serif;
  .c3-axis-y text {
    font-size: 14px; 
  }

  .c3-axis-x text {
    font-size: 14px; //change the size of the fonts
  }

  .c3-legend-item {
    font-size: 14px;
  }

  .c3-line-FAIs {
    stroke-width: 2px;
  }

  .c3-line-LTIs {
    stroke-width: 2px;
  }

  .c3-line-MTIs {
    stroke-width: 2px;
  }
`;
