import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ACCEPT_INVITATION } from '../../graphql/queries';
import InvitationContext from '../../contexts/invitation/invitation.context';

const Invitation = ({ invitationToken }) => {
  const { setInvitationToken } = useContext(InvitationContext);
  const history = useHistory();

  const [acceptInvitation] = useMutation(ACCEPT_INVITATION, {
    onCompleted() {
      setInvitationToken(undefined);
      history.replace('/');
    },
    onError(error) {
      setInvitationToken(undefined);
      history.replace('/');
    },
  });

  useEffect(() => {
    acceptInvitation({
      variables: { invitationToken },
    });
  }, []);

  return null;
};

export default Invitation;
