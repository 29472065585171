const validator = {
  email: {
    rules: [
      {
        test: /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
        message: 'Please enter a valid email',
      },
    ],
    errors: [],
    valid: false,
    state: '',
  },
  password: {
    // password rules are found in ui/static/src/helpers/form-validator/libs/rules.js
    errors: [],
    valid: false,
    state: '',
  },
  confirmPassword: {
    // confirmPassword rules are found in ui/static/src/helpers/form-validator/libs/rules.js
    errors: [],
    valid: false,
    state: '',
  },
  name: {
    rules: [
      {
        test: /^[a-z ,.'-]+$/i,
        message: 'Invalid Name',
      },
    ],
    errors: [],
    valid: false,
    state: '',
  },
};

export default validator;
