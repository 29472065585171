import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { gql, useMutation } from '@apollo/client';
// Local imports
import {
  CardContainer,
  InfoContainer,
  HeaderContainer,
  Heading,
  TimeStamp,
  Text,
  OptionsContainer,
  Button,
  Dot,
  TextArea,
} from './styles/Card';

// GraphQL Mutations
const UPDATE_STATUS_COMMENT = gql`
  mutation updateStatusDocument($projectId: ID!, $documentId: ID!, $input: StatusDocumentInput!) {
    updateStatusDocument(projectId: $projectId, documentId: $documentId, input: $input) {
      code
      success
      message
      documents {
        _id
        text
        date
      }
    }
  }
`;

/**
 * This component serves as a generic card component
 * @param {string} id The ID of our status comment
 * @param {string} projectId The ID of the project we are in
 * @param {string} heading The heading of our card
 * @param {string} date The date ISO8601 string of when our card was created
 * @param {string} text The text content of our Card
 * @param {boolean} isEditable Determines whether our card is editable or not
 * @param {function} onDelete The function to call when the 'Delete' button is clicked
 * @param {function} setComments The useState function passed from parent component to update status comments
 */
const Card = ({ id, projectId, heading, date, text, isEditable, onDelete, setComments }) => {
  const [inEditMode, setInEditMode] = useState(false);
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    setEditValue(text);
  }, [text]);

  const [updateStatusComment, { loading: loadingUpdateStatusComment, errorUpdateStatusComment, dataUpdateStatusComment }] = useMutation(UPDATE_STATUS_COMMENT, {
    onCompleted: (data) => {
      setComments(data.updateStatusDocument.documents);
    }
  });

  const handleUpdateComment = async () => {
    await updateStatusComment({
      variables: {
        documentId: id,
        projectId,
        input: {
          text: editValue,
          date,
        }
      }
    });
    setInEditMode(false);
  }

  return (
    <CardContainer>
      <InfoContainer>
        <HeaderContainer>
          {heading && <Heading>{heading}</Heading>}
          {date && <TimeStamp>{moment(date).fromNow()}</TimeStamp>}
        </HeaderContainer>
        {inEditMode ? <TextArea value={editValue} onChange={(e) => setEditValue(e.target.value)} /> : <Text>{text}</Text>}
        <OptionsContainer>
          {isEditable && 
            <>
              <Button onClick={() => setInEditMode(true)}>{'Edit'}</Button>
              <Dot />
            </>
          }
          <Button onClick={onDelete}>{'Delete'}</Button>
          {inEditMode &&
            <>
              <Dot />
              <Button onClick={handleUpdateComment}>{`Update`}</Button>
              <Dot />
              <Button onClick={() => setInEditMode(false)}>{`Cancel`}</Button>
            </>
          }
        </OptionsContainer>
      </InfoContainer>
    </CardContainer>
  );
};

export default Card;
