import React, { useEffect, useContext } from 'react';
import { Row, Col, BreadcrumbItem, Breadcrumb } from 'reactstrap';
import { ProjectOverview, ProjectSummary } from '../../components';

import ProjectContext from '../../contexts/projects/projects.context';

import { withProject } from '../../common';

const Project = ({ project }) => {
  const { setProject } = useContext(ProjectContext);

  useEffect(() => setProject(project), []);

  return (
    <div>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem active tag="a" href="">
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href="">
          Summary
        </BreadcrumbItem>
      </Breadcrumb>
      <Row>
        <Col sm={12}>
          <ProjectOverview project={project} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <ProjectSummary project={project} />
        </Col>
      </Row>
    </div>
  );
};

export default withProject(Project);
