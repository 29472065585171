import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { v4 } from 'uuid';
import {
  Row,
  Col,
  Card,
  CardSubtitle,
  Table,
  Button,
  CardBody,
} from 'reactstrap';
import { useAlert } from 'react-alert';
import Paginator from 'react-hooks-paginator';
import BudgetRow from './budgetRow';
import { GlobalReferenceContext } from '../../../contexts';
import GlobalSearch from '../../global/Search';
import { updateArrayByKey } from '../../../common';

const BudgetSettings = () => {
  const alert = useAlert();

  const [budgetStore, setBudgetStore] = useState([]);
  const {
    budgets,
    updateBudget,
    addBudgetItem,
    saveBudgetItem,
    deleteBudgetLine,
  } = useContext(GlobalReferenceContext);

  const pageLimit = 20;

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [changing, setChanging] = useState(false);
  const [newCode, setNewCode] = useState(undefined);

  const onChange = (budgetData, isNew) => {
    if (isNew) {
      setNewCode((item) => ({ ...item, ...budgetData }));
      return;
    }

    let tempBudgetStore = Object.assign([], budgetStore);

    if (_.some(budgetStore, (item) => item._id === budgetData._id)) {
      tempBudgetStore = _.map(budgetStore, (budgetItem) => {
        return budgetItem._id === budgetData._id ? budgetData : budgetItem;
      });
    } else {
      tempBudgetStore.push(budgetData);
    }

    setBudgetStore(tempBudgetStore);

    return tempBudgetStore;
  };

  const onSave = () => {
    if (newCode)
      addBudgetItem(newCode, (err, collection) => {
        if (err) {
          alert.error(err.message);
          return;
        }
        setCurrentPage(Math.ceil(collection.length / pageLimit));
        saveAll(collection);
      });
    else saveAll(budgetStore);
  };

  const saveAll = (collection) => {
    _.forEach(collection, async (item) => {
      if (item.isNew) {
        try {
          await saveBudgetItem(item);
          setNewCode(undefined);
        } catch (e) {
          // eslint-disable-next-line no-undef
          console.error(e);
        }
      } else if (item.isDirty) updateBudget(item);
      else return;
    });
  };

  const onDelete = (item) => {
    setChanging(false);
    deleteBudgetLine(item);
  };

  const onEdit = (item) => {
    if (
      !_.some(
        budgetStore,
        (storeItem) =>
          !storeItem.locked || storeItem.isDirty || storeItem.isNew,
      ) &&
      item.locked
    ) {
      item.locked = false;
    } else {
      const thisItem = _.find(budgetStore, (checkItem) => !checkItem.locked);

      if (thisItem && thisItem._id !== item._id) {
        alert.info(`Close or save an existing unlocked cost code.`);
        return;
      }

      if (!item.isDirty) item.locked = true;
      else
        alert.info(
          `Budget item "${item.costCode.name}" has been modified. Please save it.`,
        );
    }

    setBudgetStore(updateArrayByKey(item, budgetStore));
  };

  const newBudgetItem = () => {
    // if (_.some(budgetStore, (storeItem) => storeItem.isDirty || storeItem.isNew)) {
    //     alert.info(`Please save or lock existing code before creating a new one.`);
    //     return;
    // }

    if (newCode) {
      alert.info(
        `Please save or lock existing code before creating a new one.`,
      );
      return;
    }

    const budgetItem = {
      _id: v4(),
      name: '',
      description: '',
      costCode: { code: '', name: '' },
      budgetCategory: { code: '', name: '' },
      accountsPayableCategory: { code: '', name: '' },
      fundingSourceCategory: { code: '', name: '' },
      type: 'cost',
      locked: false,
      amount: undefined,
      organisation: '',
      isNew: true,
      edit: true,
    };

    setNewCode(budgetItem);

    // addBudgetItem(budgetItem, (collection) => {
    //     setCurrentPage(Math.ceil(collection.length / pageLimit));
    // });
  };

  const onSearchResults = (results) => {
    if (!results) setBudgetStore(budgets);
    else setBudgetStore(results);
  };

  useEffect(() => setBudgetStore(budgets), [budgets]);

  useEffect(() => {
    if (budgetStore)
      setCurrentData(budgetStore.slice(offset, offset + pageLimit));
  }, [offset, budgetStore]);

  if (!budgetStore) return <div />;

  return (
    <div>
      <Row className="mb-4">
        <Col xs="10">
          <h4 className="mb-3">
            <i className="fas fa-dollar-sign mr-2" /> Budget Codes Setup
          </h4>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={12}>
          <Card>
            <div className="p-3">
              <CardSubtitle className="mb-0">
                <Row>
                  <Col xs={9}>
                    You have{' '}
                    <code>
                      {
                        _.filter(
                          budgetStore,
                          (i) => i.isNew === true || i.isDirty === true,
                        ).length
                      }
                    </code>{' '}
                    modified budget items.
                  </Col>
                  <Col xs={3}>
                    <Button
                      color="projx"
                      onClick={onSave}
                      className="btn float-right ml-2"
                    >
                      <i className="fas fa-save" /> Save
                    </Button>
                    <Button
                      disabled={newCode !== undefined}
                      color="projx"
                      onClick={newBudgetItem}
                      className="btn float-right"
                    >
                      <i className="fas fa-plus" /> New Code
                    </Button>
                  </Col>
                </Row>
              </CardSubtitle>
            </div>
            <CardBody>
              <Row>
                <Col sm="4">
                  <GlobalSearch
                    data={_.clone(budgetStore)}
                    keys={[
                      'butgetCategory.name',
                      'budgetCategory.code',
                      'costCode.code',
                      'costCode.name',
                    ]}
                    onResults={onSearchResults}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardBody>
              <Table hover>
                <thead>
                  <tr className="align-middle">
                    <th scope="col">Cost Code</th>
                    <th scope="col">Cost Name</th>
                    <th scope="col">Budget Cat.</th>
                    <th scope="col">Budget Type</th>
                    <th scope="col">Acct. Payable</th>
                    <th scope="col">Funding Src.</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {newCode ? (
                    <BudgetRow
                      onEdit={onEdit}
                      onChange={onChange}
                      onDelete={onDelete}
                      budget={newCode}
                      key="newCode"
                      isNew
                    />
                  ) : (
                    ''
                  )}
                  {_.map(
                    _.orderBy(
                      currentData,
                      [(cd) => cd.costCode.code.toLowerCase()],
                      ['asc'],
                    ),
                    (budget, budgetIndex) => (
                      <BudgetRow
                        onEdit={onEdit}
                        onChange={onChange}
                        onDelete={onDelete}
                        budget={budget}
                        key={budgetIndex}
                        isNew={false}
                      />
                    ),
                  )}
                </tbody>
              </Table>
            </CardBody>
            <CardBody className="border-top">
              <Paginator
                totalRecords={budgetStore ? budgetStore.length : 0}
                pageLimit={pageLimit}
                pageNeighbours={1}
                setOffset={setOffset}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BudgetSettings;
