import gql from 'graphql-tag';

const CREATE_VENDOR_MUTATION = gql`
  mutation ($item: VendorInput!) {
    createVendor(item: $item) {
      _id
      name
      representative
      abn
      type
      tags
      branches {
        branchId
        branchName
        contacts {
          name
          email
          phone
        }
      }
      address {
        street
        suburb
        postcode
        state
      }
      archived
    }
  }
`;

const UPDATE_VENDOR_MUTATION = gql`
  mutation ($_id: ID!, $item: VendorInput!) {
    updateVendor(_id: $_id, item: $item) {
      _id
      name
      representative
      abn
      type
      tags
      branches {
        branchId
        branchName
        contacts {
          name
          email
          phone
        }
      }
      address {
        street
        suburb
        postcode
        state
      }
      archived
    }
  }
`;

const VENDOR_QUERY = gql`
  query ($_id: ID!) {
    getVendor(_id: $_id) {
      _id
      name
      representative
      abn
      type
      tags
      branches {
        branchId
        branchName
        contacts {
          name
          email
          phone
        }
      }
      address {
        street
        suburb
        postcode
        state
      }
      archived
    }
  }
`;

const VENDOR_BY_ORG_QUERY = gql`
  query ($orgId: ID!, $includeArchived: Boolean!) {
    getVendorsByOrg(orgId: $orgId, includeArchived: $includeArchived) {
      _id
      name
      representative
      abn
      type
      tags
      branches {
        branchId
        branchName
        contacts {
          name
          email
          phone
        }
      }
      address {
        street
        suburb
        postcode
        state
      }
      archived
    }
  }
`;

export {
  VENDOR_BY_ORG_QUERY,
  VENDOR_QUERY,
  CREATE_VENDOR_MUTATION,
  UPDATE_VENDOR_MUTATION,
};
