import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import _ from 'lodash';
import { useAlert } from 'react-alert';
import { useTransaction } from '../../../hooks';
import { currencify, extractGraphqlError } from '../../../common';

const TransactionItem = ({
  transactionsState,
  setTransactionsState,
  item,
  costCode,
  onTransferAction = () => {},
}) => {
  const [transaction, setTransaction] = useState(undefined);

  const alert = useAlert();

  const {
    approveTransaction,
    withdrawTransaction,
    rejectTransaction,
    TransactionOperationType,
  } = useTransaction({
    onCompleted: ({ action, data }) => {
      switch (action) {
        case TransactionOperationType.ApproveTransaction:
          setTransaction(data);
          break;
        case TransactionOperationType.RejectTransaction:
          setTransaction(data);
          break;
        case TransactionOperationType.WithdrawTransaction:
          setTransaction(data);
          break;
        default:
          break;
      }
      onTransferAction({
        action,
        data,
      });
    },
  });

  const approve = async (transactionId) => {
    try {
      await approveTransaction(transactionId);
      setTransactionsState('approved');
    } catch (e) {
      alert.error(extractGraphqlError(e));
    }
  };

  const withdraw = async (transactionId) => {
    try {
      await withdrawTransaction(transactionId);
      setTransactionsState('withdraw');
    } catch (e) {
      alert.error(extractGraphqlError(e));
    }
  };

  const reject = async (transactionId) => {
    try {
      await rejectTransaction(transactionId);
      setTransactionsState('reject');
    } catch (e) {
      alert.error(extractGraphqlError(e));
    }
  };

  useEffect(() => {
    setTransaction(item);
  }, []);

  if (!transaction) return <div />;

  const badge = (
    <span
      className={`mb-2 badge ${
        costCode.costCode.code === item.source.costCode.code
          ? 'badge-warning'
          : 'badge-success'
      }`}
    >
      {costCode.costCode.code === item.source.costCode.code
        ? `-${currencify(transaction.amount, false, 2)}`
        : currencify(transaction.amount, false, 2)}
    </span>
  );
  const value = (
    <h6 className="font-medium">
      {transaction.source.costCode.name}{' '}
      <i className="fas fa-long-arrow-alt-right" color="warning" />{' '}
      {transaction.destination.costCode.name}{' '}
    </h6>
  );
  const notes = <span className="mb-3 d-block">{transaction.notes}</span>;
  const date = (
    <div className="d-flex float-right">
      <img
        src={transaction.createdBy.image}
        alt="user"
        width="15"
        height="15"
        className="rounded-circle mr-2 mt-1"
      />
      <span className="text-muted float-right">
        <b>{transaction.createdBy.name}</b>{' '}
        {moment(transaction.timestamp).fromNow(true)} ago
      </span>
    </div>
  );

  return (
    <div className="d-flex flex-row comment-row mt-2 border-bottom">
      <div className="comment-text w-100">
        {value}
        {notes}
        <div className="comment-footer">
          {date}
          {badge}
          {/* <span className="action-icons"> */}
          <span>
            {!transaction.state || transaction.state === 'pending' ? (
              <>
                <Button
                  outline
                  color="projx"
                  className="btn-sm ml-2"
                  onClick={() => approve(transaction._id)}
                >
                  Approve
                </Button>
                <Button
                  outline
                  color="projx"
                  className="btn-sm ml-2"
                  onClick={() => reject(transaction._id)}
                >
                  Reject
                </Button>
                <Button
                  outline
                  color="projx"
                  className="btn-sm ml-2"
                  onClick={() => withdraw(transaction._id)}
                >
                  Withdraw
                </Button>
              </>
            ) : (
              ''
            )}
          </span>
        </div>
        <h5>History</h5>
        <ListGroup className="pb-2">
          {_.map(transaction.history, (history, idx) => (
            <ListGroupItem key={idx} className="mb-1">
              {history.state === 'pending' ? (
                <span className="float-left badge badge-secondary">
                  {history.state}
                </span>
              ) : (
                ''
              )}
              {history.state === 'approved' ? (
                <span className="float-left badge badge-success">
                  {history.state}
                </span>
              ) : (
                ''
              )}
              {history.state === 'withdrawn' ? (
                <span className="float-left badge badge-info">
                  {history.state}
                </span>
              ) : (
                ''
              )}
              {history.state === 'rejected' ? (
                <span className="float-left badge badge-warning">
                  {history.state}
                </span>
              ) : (
                ''
              )}
              <span className="text-muted float-right">
                <b>{history.user.name}</b>{' '}
                {moment(history.timestamp).fromNow(true)} ago
              </span>
              <img
                src={history.user.image}
                alt="user"
                width="15"
                height="15"
                className="rounded-circle mr-2 mt-1 float-right"
              />
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
    </div>
  );
};

export default TransactionItem;
