import gql from 'graphql-tag';

const PROJECT_SUMMARY_QUERY = gql`
  query ($projectId: ID!) {
    summaryView(projectId: $projectId) {
      group {
        costCode {
          code
          name
        }
        budgetCategory {
          code
          name
        }
      }
      summary {
        originalBudget
        budgetTransfer
        currentBudget

        forecasts {
          contract
          provisionalSum
          variations
          total
        }
        committments {
          contract
          provisionalSum
          variations
          let
          adjustments
          total
        }
        ffc
        position {
          approvedPosition
          fccPosition
        }
        expenditure {
          thisMonth
          cumulative
          committedCostToComplete
          budgetCostToComplete
          forecastToComplete
        }
      }
      isTest
    }
  }
`;

const REPORT_QUERY = gql`
  query ($projectId: ID!, $type: String!, $query: ReportQueryInput!) {
    reportQuery(projectId: $projectId, type: $type, query: $query) {
      data
      totals
      reportType
      query {
        columns {
          field
          type
        }
        dateRange {
          field
          from
          to
        }
        comparison {
          withPeriods
          period
          values
        }
        layout {
          accessor
          type
        }
        queryBy {
          accessor
          values
        }
        queryByVendor
        filterBy {
          field
          value
        }
        showBalances
        showDecimals
      }
    }
  }
`;

export { PROJECT_SUMMARY_QUERY, REPORT_QUERY };
