import rules from './rules';

const validateForm = (fieldName, value) => {
  const fieldRules = rules[fieldName];

  const errors = [];

  fieldRules.forEach((rule) => {
    if (rule.test instanceof RegExp) {
      if (!rule.test.test(value)) errors.push(rule.message);
    } else if (typeof rule.test === 'function') {
      if (!rule.test(value)) errors.push(rule.message);
    }
  });

  return errors;
};

export { validateForm };
