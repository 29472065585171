import styled, { css } from 'styled-components';
import { RiCheckboxMultipleBlankLine } from 'react-icons/ri';
import { FiArchive } from 'react-icons/fi'

export const DashboardIconStyles = css`
  width: 25px;
  height: 25px;
  color: #1B1B1B;
`;

export const PortfolioIcon = styled(RiCheckboxMultipleBlankLine)`
  ${DashboardIconStyles}
`;

export const ArchiveIcon = styled(FiArchive)`
  ${DashboardIconStyles}
`;  
