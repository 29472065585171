import styled, { css } from 'styled-components';

export const IconButtonStyles = styled.button`
  background-color: #F9F9F9;
  border: none;
  height: 40px;
  margin: 0 8px;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #D9D9D9;
  }

  ${(props) => props.disabled && css`
    &:hover {
      background-color: transparent;
      cursor: not-allowed;
    }
  `}
`;

export const IconButtonText = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;
`;
