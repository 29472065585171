import styled from 'styled-components';

export const SafetyPeriodCardsContainerStyles = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px 16px;
  margin: 16px 0;
  font-family: 'Inter', sans-serif;
  color: #F9F9F9;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
