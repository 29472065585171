import React from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap';
import { withProject } from '../../common';

const Risks = ({ project }) => {
  return (
    <>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/projects/${project._id}`}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Risks</BreadcrumbItem>
      </Breadcrumb>

      <Row>
        <Col sm="12">
          <div className="d-flex mt-3 p-4 flex-column align-items-center justify-content-center">
            <i className="fas fa-exclamation-triangle display-4" />
            <h2 className="mt-2">
              We are working hard to bring you Risks module as our next
              feature...
            </h2>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withProject(Risks);
