import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { Projects } from '../../components/dashboard';
import { OrganisationContext } from '../../contexts';
import { usePanel } from '../../hooks';
import { ProjectCards } from '../../components';
import {
  HeadingContainer,
  Heading,
  HeadingText,
  HeaderGroup,
} from './styles/Dashboard';
import { PortfolioIcon, ArchiveIcon } from './styles/DashboardIcons';

const options = [
  { value: 'active', label: 'Active' },
  { value: 'archived', label: 'Archived' },
];

/**
 * This component renders the organisation portfolio of projects
 * @returns {React.JSX}
 */
const Portfolio = () => {
  const { setMenu } = usePanel();
  const [selectValue, setSelectValue] = useState('active');

  const { currentOrganisation } = useContext(OrganisationContext);

  useEffect(() => setMenu(null), []);

  // console.log(currentOrganisation);
  return (
    <div>
      <HeadingContainer>
        <Heading>
          {selectValue === 'active' && <PortfolioIcon />}
          {selectValue === 'archived' && <ArchiveIcon />}
          <HeadingText>
            {selectValue === 'active' ? 'Active Projects' : 'Archived Projects'}
          </HeadingText>
        </Heading>
        <Select
          options={options}
          defaultValue={{ value: 'active', label: 'Active' }}
          onChange={(e) => setSelectValue(e.value)}
        />
      </HeadingContainer>
      <ProjectCards
        organisationId={currentOrganisation?._id ?? null}
        filter={selectValue}
      />
      {/* <Row>
        <Col xs={12}>
          <Projects />
        </Col>
      </Row> */}
    </div>
  );
};

export default Portfolio;
