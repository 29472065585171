import React from 'react';

import { Main, Table, Period } from '../common';

const BudgetCurrentPrevious = ({ template }) => {
  return (
    <Main template={template} name={'Budget Current & Previous'}>
      <Period period={template.period} />
      <Table table={template.table} />
    </Main>
  );
};

export default BudgetCurrentPrevious;
