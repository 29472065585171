import React, { useState } from 'react';
import _ from 'lodash';
import { CardLink } from 'reactstrap';
import { currencify } from '../../../common';

const CostCodeItem = ({ project, name, group }) => {
  const [rowsOpen, setRowsOpen] = useState(true);
  const toggle = () => setRowsOpen(!rowsOpen);

  return (
    <>
      <tr onClick={toggle}>
        <td>
          <div className="d-flex flex-row comment-row mt-2 border-bottom">
            <div className="comment-text w-100">
              <div className="comment-footer">
                <span className="float-right">
                  <b>
                    {currencify(
                      _.sumBy(group, (i) => i.currentAmount),
                      true,
                      2,
                    )}
                  </b>
                </span>
                <h5>
                  <i
                    className={
                      rowsOpen
                        ? 'fas fa-chevron-circle-down'
                        : 'fas fa-chevron-circle-right'
                    }
                  ></i>{' '}
                  {name}
                </h5>
              </div>
            </div>
          </div>
        </td>
      </tr>
      {_.map(
        _.orderBy(group, [(cc) => cc.costCode.code.toLowerCase()], ['asc']),
        (groupItem) => (
          <tr
            key={groupItem._id}
            className={!rowsOpen ? 'budget-cost' : 'pl-2 budget-cost open'}
          >
            <td>
              <div className="d-flex flex-row comment-row mt-2 ml-4">
                <div className="comment-text w-100">
                  <div className="comment-footer">
                    <span className="text-muted float-right">
                      <b>{currencify(groupItem.currentAmount, true, 2)}</b>
                    </span>
                    <span className="text-muted">
                      <CardLink
                        href={`/projects/${project._id}/commitments?costCode=${groupItem.costCode.code}`}
                      >
                        <b>({groupItem.costCode.code})</b>{' '}
                        {groupItem.costCode.name}
                      </CardLink>
                    </span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ),
      )}
    </>
  );
};

export default CostCodeItem;
