import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  FormGroup,
  Row,
  Label,
  Col,
  Button,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap';
import Select from 'react-select';
import { useAlert } from 'react-alert';
import { InputWrapper, DisplayType } from '../entry/types';
import { GlobalReferenceContext } from '../../../contexts';
import { CostTransfer } from '../..';
import { useTransaction, useCostCodeSetup } from '../../../hooks';
import { COST_TYPE, currencify } from '../../../common';

const highlightStyle = {
  control: (base) => ({
    ...base,
    border: '1px solid red',
    // You can also use state.isFocused to conditionally style based on the focus state
  }),
};

const BudgetManagementContent = ({
  item,
  onUpdate,
  project,
  costCodes,
  refresh,
  onDelete = () => {},
}) => {
  const alert = useAlert();

  const { normalize, transformBudgets } = useContext(GlobalReferenceContext);
  const [openCostTransfer, setOpenCostTransfer] = useState(false);
  const { getTransactions } = useTransaction({ onCompleted: () => {} });
  const [filtered, setFiltered] = useState([]);
  const [show, setShow] = useState(false);
  const [itemUpdated, setItemUpdated] = useState('');
  const [loading, setLoading] = useState(false);

  const { Actions, createCostCodeSetupProject, updateCostCodeSetupProject } =
    useCostCodeSetup({
      onCompleted: ({ action, data }) => {
        switch (action) {
          case Actions.CreateCostCodeSetupProject:
            setItemUpdated('Successfully created Cost Code');
            onUpdate(data);
            setLoading(false);
            break;
          case Actions.UpdateCostCodeSetupProject:
            setItemUpdated('Successfully updated Cost Code');
            onUpdate(data);
            setLoading(false);
            break;
          default:
            break;
        }
      },
    });

  const updateState = (data) => {
    setItemUpdated('');
    onUpdate(Object.assign(item, data));
  };
  const onInputChange = (e) => {
    setItemUpdated('');
    _.set(item, e.cellKey, e.value);
    onUpdate(item);
  };

  const toggleCostTransfer = () => {
    setOpenCostTransfer(!openCostTransfer);
  };

  const transactionCompleted = () => {
    getTransactions(project._id);
    refresh();
  };

  const onCostCodeSelected = (e) => {
    setItemUpdated('');
    updateState({ costCode: { name: e.label, code: e.value.costCode.code } });
    updateState({
      _id: '',
      name: e.value.name || '',
      description: e.value.description || '',
      costCode: e.value.costCode,
      budgetCategory: e.value.budgetCategory,
      accountsPayableCategory: e.value.accountsPayableCategory,
      fundingSourceCategory: e.value.fundingSourceCategory,
      type: e.value.type,
    });
  };

  const saveCode = async () => {
    setLoading(true);

    try {
      if (item._id === '') await createCostCodeSetupProject(project._id, item);
      else await updateCostCodeSetupProject(project._id, item._id, item);
    } catch (e) {
      alert.info(e.message);
      setLoading(false);
    }
  };

  const deleteCode = async () => onDelete(item);

  const tryDelete = () => {
    toggleDelete();
  };

  const toggleDelete = () => setShow(!show);

  useEffect(() => {
    const transformed = _.filter(transformBudgets(), (tb) => {
      for (let i = 0; i < costCodes.length; i++) {
        if (costCodes[i].costCode.code === tb.value.costCode.code) return false;
      }
      return true;
    });
    setFiltered(
      _.orderBy(transformed, [(cc) => cc.label.toLowerCase()], ['asc']),
    );
  }, [costCodes]);

  return (
    <>
      <CardBody>
        <div className="p-3">
          {!item._id || item._id === '' ? (
            <FormGroup>
              <Row>
                <Col sm="6">
                  <Label className="font-12">Cost Code</Label>
                  <Select
                    className="mt-2"
                    styles={
                      item.costCode.code === 'Unassigned'
                        ? highlightStyle
                        : undefined
                    }
                    closeMenuOnSelect
                    default={{
                      label: `(${item.costCode.code}) ${item.costCode.name}`,
                    }}
                    value={{
                      label: `(${item.costCode.code}) ${item.costCode.name}`,
                    }}
                    options={filtered}
                    onChange={onCostCodeSelected}
                  />
                </Col>
                <Col sm="6">
                  {item.costCode.code === 'Unassigned' ? (
                    <>
                      <Label className="text-danger mt-5">
                        <i className="fas fa-arrow-circle-left mr-2" />
                        Select one of the existing budget codes.
                      </Label>
                    </>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
            </FormGroup>
          ) : (
            <div />
          )}
          <FormGroup>
            {item.currentAmount ? (
              <div>
                <Row>
                  <Col sm="5">
                    <Label className="font-12">Current Budget Amount</Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    <Label className="font-14 text-info">
                      <b>{currencify(item.currentAmount, false, 2)}</b>
                    </Label>
                  </Col>
                </Row>
              </div>
            ) : (
              <div />
            )}

            <Row>
              <Col sm="5">
                <Label className="font-12">Original Budget</Label>
                <InputWrapper
                  disabled={
                    item.hasTransactionsAssociated || item.hasVendorsAssociated
                  }
                  onInputChange={onInputChange}
                  width="150px"
                  cellKey="amount"
                  value={item.amount}
                  type={DisplayType.Currency}
                  precision={0}
                  allowEmpty={false}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm="6">
                <Label className="font-12">Name</Label>
                <InputWrapper
                  onInputChange={onInputChange}
                  width="150px"
                  cellKey="name"
                  value={item.name || ''}
                  type={DisplayType.Text}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm="6">
                <Label className="font-12">Description</Label>
                <InputWrapper
                  onInputChange={onInputChange}
                  width="150px"
                  cellKey="description"
                  value={item.description || ''}
                  type={DisplayType.TextArea}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm="6">
                <Label className="font-12">Type</Label>

                <Select
                  closeMenuOnSelect={true}
                  defaultValue={COST_TYPE[0]}
                  value={_.find(COST_TYPE, (i) => i.value === item.type)}
                  options={COST_TYPE}
                  onChange={(e) => updateState({ type: e.value })}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm="6">
                <Label className="font-12">Default Category</Label>
                <Select
                  closeMenuOnSelect
                  default={{ label: item.budgetCategory.name }}
                  value={{ label: item.budgetCategory.name }}
                  options={normalize('budget_category')}
                  onChange={(e) =>
                    updateState({
                      budgetCategory: { name: e.label, code: e.value },
                    })
                  }
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm="6">
                <Label className="font-12">Default Accounts Payable</Label>
                <Select
                  closeMenuOnSelect
                  default={{ label: item.accountsPayableCategory.name }}
                  value={{ label: item.accountsPayableCategory.name }}
                  options={normalize('accounts_payable')}
                  onChange={(e) =>
                    updateState({
                      accountsPayableCategory: { name: e.label, code: e.value },
                    })
                  }
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm="6">
                <Label className="font-12">Default Funding Source</Label>
                <Select
                  closeMenuOnSelect
                  default={{ label: item.fundingSourceCategory.name }}
                  value={{ label: item.fundingSourceCategory.name }}
                  options={normalize('funding_source_category')}
                  onChange={(e) =>
                    updateState({
                      fundingSourceCategory: { name: e.label, code: e.value },
                    })
                  }
                />
              </Col>
            </Row>
          </FormGroup>
          {openCostTransfer ? (
            <CostTransfer
              item={item}
              project={project}
              toggle={toggleCostTransfer}
              open={openCostTransfer}
              transactionCompleted={transactionCompleted}
            />
          ) : (
            ''
          )}
        </div>
      </CardBody>
      {item.hasTransactionsAssociated || item.hasVendorsAssociated ? (
        <CardBody className="border-top">
          {item.hasTransactionsAssociated ? (
            <Alert color="projx">
              {item.costCode.name} has associated budget transfers.
            </Alert>
          ) : (
            ''
          )}
          {item.hasVendorsAssociated ? (
            <Alert color="projx">
              {item.costCode.name} has associated vendors.
            </Alert>
          ) : (
            ''
          )}
        </CardBody>
      ) : (
        ''
      )}
      <CardFooter>
        <Button
          disabled={item.currentAmount <= 0 || !item._id || item._id === ''}
          onClick={toggleCostTransfer}
          className="btn btn-info ml-auto float-left ml-2"
          color="projx"
        >
          <i className="fas fa-random" /> Transfer
        </Button>
        <Button
          disabled={item.costCode.name === 'Unsaved' || loading}
          className="btn btn-success ml-auto float-right"
          color="projx"
          onClick={saveCode}
        >
          <i className="fas fa-save" /> {loading ? 'Saving...' : 'Save'}
        </Button>
        <Button
          disabled={!item._id || item._id === ''}
          color="projx"
          outline
          className="btn btn-success ml-auto float-right mr-2"
          onClick={tryDelete}
        >
          <i className="fas fa-trash" /> Delete
        </Button>
      </CardFooter>
      {itemUpdated === '' ? (
        ''
      ) : (
        <CardFooter>
          <div className="float-left">
            <i className="fas fa-info-circle text-info" /> {itemUpdated}
          </div>
          <i
            className="fas fa-window-close float-right text-secondary"
            onClick={() => setItemUpdated('')}
          />
        </CardFooter>
      )}
      <Modal isOpen={show} toggle={toggleDelete}>
        <ModalHeader toggle={toggleDelete}>
          Delete {item.costCode.name} cost code
        </ModalHeader>
        <ModalBody>
          <span className="text-danger">
            <b>WARNING: </b>
          </span>
          You are about to delete{' '}
          <span className="text-danger">
            <b>{item.costCode.name}</b>
          </span>{' '}
          cost code, with an associated amount of{' '}
          <code>{currencify(item.currentAmount, true)}</code>.
          <div className="mt-3">
            <span className="text-danger font-12">
              <b>
                Note: If you need to transfer this amount to another cost code,
                click Transfer, otherwise do you want to delete this cost code?
              </b>
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="float-left" color="projx" onClick={toggleDelete}>
            Cancel
          </Button>
          <Button
            className="btn"
            color="projx"
            onClick={() => {
              toggleDelete();
              toggleCostTransfer();
            }}
          >
            Transfer
          </Button>
          <Button color="projx" outline onClick={deleteCode}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BudgetManagementContent;
