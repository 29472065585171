import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DocumentCard } from '..';

const DocumentList = ({ documents }) => {
  const _latestDocumentFirst = (olderDocument, newerDocument) => {
    return moment(newerDocument.createdAt) - moment(olderDocument.createdAt);
  };

  if (_.isNil(documents)) return null;
  if (documents && documents.length === 0) return null;

  return (
    <div className="comment-widgets scrollable">
      <PerfectScrollbar>
        {documents.sort(_latestDocumentFirst).map((document) => {
          return <DocumentCard key={document._id} document={document} />;
        })}
      </PerfectScrollbar>
    </div>
  );
};

export default DocumentList;
