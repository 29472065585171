import styled, { css } from 'styled-components';
import CountUp from 'react-countup';

export const StatusCardsStyle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px 16px;
  margin-bottom: 16px;
  font-family: 'Inter', sans-serif;
  color: #F9F9F9;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StatusCardStyle = styled.div`
  width: 100%;
  border-radius: 8px;
  min-height: 152px;
  padding: 16px;
  display: grid;
  grid-template-columns: 70% 1fr;
  transition: transform 0.05s;

  &:hover {
    transform: scale(1.03);
  }

  ${props => props.gradient === 'lunada' && css`
    background-image: linear-gradient(to top right, #20BDFF, #5433FF);
  `};

  ${props => props.gradient === 'dimigo' && css`
    background-image: linear-gradient(to top right, #ec008c, #fc6767);
  `};

  ${props => props.gradient === 'quepal' && css`
    background-image: linear-gradient(to top right, #38ef7d, #11998e);
  `};

  ${props => props.gradient === 'citrus' && css`
    background-image: linear-gradient(to top right, #FDC830, #F37335);
  `};

  ${props => props.gradient === 'ash' && css`
    background-image: linear-gradient(to top right, #606c88, #3f4c6b);
  `};

  ${props => props.gradient === 'purpink' && css`
    background-image: linear-gradient(to top right, #E100FF, #7F00FF);
  `};
  
`;

export const StatusCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 0 0 35px;
`;

export const StatusCardNumber = styled(CountUp)`
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
`;

export const StatusCardText = styled.p`
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  text-transform: uppercase;
`;

export const StatusCardIconContainer = styled.div`
  display: flex;
  flex-direction column;
  justify-content: center;
  align-items: center;
`;