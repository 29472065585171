import styled, { css } from 'styled-components';

export const ProjectOverviewWidgetStyles = styled.div`
  width: 100%;
  border-radius: 8px;
  min-height: 448px;
  border: 1px solid #D9D9D9;
  padding: 32px;
  color: #1B1B1B;
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Inter', sans-serif;
  margin-bottom: 15px;

  @media screen and (min-width: 1390px) {
    flex-direction: row;
  }
`;

export const Image = styled.img`
  border-radius: 8px;
  height: 384px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 30px;
  
  @media screen and (min-width: 1390px) {
    width: 50%;
    max-width: 600px;
    margin-bottom: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 15px;
  gap: 20px;
  width: 100%;
  
  @media screen and (min-width: 1390px) {
    width: 50%;
    max-width: 600px;
  }
`;

export const TextCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 5px 0;

  ${props => props.half && css`
    width: 48%;
  `};
`;

export const TextHeading = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
