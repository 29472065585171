import React from 'react';
import { withProject } from '../../common';
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap';
import _ from 'lodash';

import { ReportCard, buildReports } from '../../components/reports';
import { Route, Switch } from 'react-router-dom';

const Reports = ({ project }) => {
  return (
    <>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={'/projects/' + project._id}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Reports</BreadcrumbItem>
      </Breadcrumb>

      <Row>
        <Col md="2">
          {_.map(buildReports(project), (report) => (
            <ReportCard key={report.group} report={report} project={project} />
          ))}
        </Col>
        <Col md="10">
          <Switch>
            {_.map(buildReports(project), (report) =>
              _.map(report.reports, (item) => (
                <Route
                  path={item.path}
                  render={(props) => {
                    let Component = item.Component;
                    return (
                      <Component
                        {...props}
                        reportConfig={{
                          name: item.name,
                          type: item.type,
                          template: item.template,
                        }}
                      />
                    );
                  }}
                />
              )),
            )}
          </Switch>
        </Col>
      </Row>
    </>
  );
};

export default withProject(Reports);
