import gql from 'graphql-tag';

const GET_BUDGET_CHILDREN = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    abn
    representative
    abn
    address {
      street
      suburb
      postcode
      state
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  fragment BudgetFieldItemDetails on BudgetFieldItem {
    _id
    value
    state
    notes
    document {
      _id
      category
      title
      notes
      location
      uploadedBy
      createdAt
    }
    createdBy {
      ...UserDetails
    }
    timestamp
  }

  query ($budgetId: ID!) {
    getChildren(budgetId: $budgetId) {
      _id
      costCode
      type
      ref
      phase
      activity
      forecast {
        anticipated
        submitted
        recommended
        locked
      }
      committed {
        contracted
        let {
          ...BudgetFieldItemDetails
        }
        adjustment
        locked
        open
        approved
      }
      expenditure {
        previous {
          _id
          claimId
          timestamp
          amount
          date
          comments
        }
        current {
          amount
          comments
        }
        thisClaim
        allowed
        remaining
      }
      fcc
      description
      commitmentStatus
      noticeReferences
      comments
      action
      actionBy
      sipmReview {
        key
        value
      }
      contingencyCostCode
      accountsPayable {
        key
        value
      }
      fundingSource {
        key
        value
      }
      costCause {
        key
        value
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      timestamp
      stage
      history {
        _id
        field
        fieldValue
        modifiedOn
        stage
        modifiedBy {
          _id
          name
          handle
          image
        }
      }
      parentId
      isOpen
      childCount
    }
  }
`;

const SAVE_BUDGET_LINE_MUTATION = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    abn
    representative
    abn
    address {
      street
      suburb
      postcode
      state
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  fragment BudgetFieldItemDetails on BudgetFieldItem {
    _id
    value
    state
    notes
    document {
      _id
      category
      title
      notes
      location
      uploadedBy
      createdAt
    }
    createdBy {
      ...UserDetails
    }
    timestamp
  }

  mutation ($input: BudgetInput!) {
    saveBudgetLine(input: $input) {
      _id
      costCode
      type
      ref
      phase
      activity
      forecast {
        anticipated
        submitted
        recommended
        locked
      }
      committed {
        contracted
        let {
          ...BudgetFieldItemDetails
        }
        adjustment
        locked
        open
        approved
      }
      expenditure {
        previous {
          _id
          claimId
          timestamp
          amount
          date
          comments
        }
        current {
          amount
          comments
        }
        thisClaim
        allowed
        remaining
      }
      fcc
      description
      commitmentStatus
      noticeReferences
      comments
      action
      actionBy
      sipmReview {
        key
        value
      }
      contingencyCostCode
      accountsPayable {
        key
        value
      }
      fundingSource {
        key
        value
      }
      costCause {
        key
        value
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      timestamp
      stage
      history {
        _id
        field
        fieldValue
        modifiedOn
        stage
        modifiedBy {
          _id
          name
          handle
          image
        }
      }
      parentId
      isOpen
      childCount
    }
  }
`;

const GET_BUDGETS_BY_PROJECT_VENDOR_TYPE_QUERY = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    abn
    representative
    abn
    address {
      street
      suburb
      postcode
      state
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }

  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  fragment BudgetFieldItemDetails on BudgetFieldItem {
    _id
    value
    state
    notes
    document {
      _id
      category
      title
      notes
      location
      uploadedBy
      createdAt
    }
    createdBy {
      ...UserDetails
    }
    timestamp
  }

  query ($projectId: ID!, $vendorId: ID!, $type: String!, $costCode: String!) {
    getBudgetsByProjectAndVendorAndType(
      projectId: $projectId
      vendorId: $vendorId
      type: $type
      costCode: $costCode
    ) {
      _id
      costCode
      type
      ref
      phase
      activity
      forecast {
        anticipated
        submitted
        recommended
        locked
      }
      committed {
        contracted
        let {
          ...BudgetFieldItemDetails
        }
        adjustment
        locked
        open
        approved
      }
      expenditure {
        previous {
          _id
          claimId
          timestamp
          amount
          date
          comments
        }
        current {
          amount
          comments
        }
        thisClaim
        allowed
        remaining
      }
      fcc
      description
      commitmentStatus
      noticeReferences
      comments
      action
      actionBy
      sipmReview {
        key
        value
      }
      contingencyCostCode
      accountsPayable {
        key
        value
      }
      fundingSource {
        key
        value
      }
      costCause {
        key
        value
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      timestamp
      stage
      history {
        _id
        field
        fieldValue
        modifiedOn
        stage
        modifiedBy {
          _id
          name
          handle
          image
        }
      }
      parentId
      isOpen
      childCount
    }
  }
`;

const GET_BUDGETS_BY_PROJECT_QUERY = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    abn
    representative
    abn
    address {
      street
      suburb
      postcode
      state
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  fragment BudgetFieldItemDetails on BudgetFieldItem {
    _id
    value
    state
    notes
    document {
      _id
      category
      title
      notes
      location
      uploadedBy
      createdAt
    }
    createdBy {
      ...UserDetails
    }
    timestamp
  }

  query ($projectId: ID!, $vendorId: ID!) {
    getBudgetsByProjectAndVendor(projectId: $projectId, vendorId: $vendorId) {
      _id
      costCode
      type
      ref
      phase
      activity
      forecast {
        anticipated
        submitted
        recommended
        locked
      }
      committed {
        contracted
        let {
          ...BudgetFieldItemDetails
        }
        adjustment
        locked
        open
        approved
      }
      expenditure {
        previous {
          _id
          claimId
          timestamp
          amount
          date
          comments
        }
        current {
          amount
          comments
        }
        thisClaim
        allowed
        remaining
      }
      fcc
      description
      commitmentStatus
      noticeReferences
      comments
      action
      actionBy
      sipmReview {
        key
        value
      }
      contingencyCostCode
      accountsPayable {
        key
        value
      }
      fundingSource {
        key
        value
      }
      costCause {
        key
        value
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      timestamp
      stage
      history {
        _id
        field
        fieldValue
        modifiedOn
        stage
        modifiedBy {
          _id
          name
          handle
          image
        }
      }
      parentId
      isOpen
      childCount
    }
  }
`;

const GET_BUDGET_FIELD_ITEM_QUERY = gql`
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  query ($itemId: ID!) {
    getBudgetFieldItems(itemId: $itemId) {
      _id
      value
      state
      notes
      document {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      createdBy {
        ...UserDetails
      }
      timestamp
    }
  }
`;

const CREATE_BUDGET_FIELD_ITEM_MUTATION = gql`
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  mutation ($itemId: ID!, $input: BudgetFieldItemInput!) {
    createBudgetFieldItem(itemId: $itemId, input: $input) {
      _id
      value
      state
      notes
      document {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      createdBy {
        ...UserDetails
      }
      timestamp
    }
  }
`;

const DELETE_BUDGET_FIELD_ITEM_MUTATION = gql`
  mutation ($itemId: ID!, $fieldItemId: ID!) {
    deleteBudgetFieldItem(itemId: $itemId, fieldItemId: $fieldItemId)
  }
`;

const DELETE_BUDGET_MUTATION = gql`
  mutation ($_id: ID!) {
    deleteBudget(_id: $_id) {
      _id
    }
  }
`;

const APPROVE_LET_MUTATION = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    abn
    representative
    abn
    address {
      street
      suburb
      postcode
      state
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  fragment BudgetFieldItemDetails on BudgetFieldItem {
    _id
    value
    state
    notes
    document {
      _id
      category
      title
      notes
      location
      uploadedBy
      createdAt
    }
    createdBy {
      ...UserDetails
    }
    timestamp
  }
  mutation ($itemId: ID!, $fieldItemId: ID!) {
    approveLet(itemId: $itemId, fieldItemId: $fieldItemId) {
      _id
      costCode
      type
      ref
      phase
      activity
      forecast {
        anticipated
        submitted
        recommended
        locked
      }
      committed {
        contracted
        let {
          ...BudgetFieldItemDetails
        }
        adjustment
        locked
        open
        approved
      }
      expenditure {
        previous {
          _id
          claimId
          timestamp
          amount
          date
          comments
        }
        current {
          amount
          comments
        }
        thisClaim
        allowed
        remaining
      }
      fcc
      description
      commitmentStatus
      noticeReferences
      comments
      action
      actionBy
      sipmReview {
        key
        value
      }
      contingencyCostCode
      accountsPayable {
        key
        value
      }
      fundingSource {
        key
        value
      }
      costCause {
        key
        value
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      timestamp
      stage
      history {
        _id
        field
        fieldValue
        modifiedOn
        stage
        modifiedBy {
          _id
          name
          handle
          image
        }
      }
      parentId
      isOpen
      childCount
    }
  }
`;

const ADD_BUDGET_DOCUMENT_MUTATION = gql`
  mutation (
    $_id: ID!
    $document: String!
    $category: String!
    $title: String!
    $notes: String
  ) {
    addBudgetDocument(
      _id: $_id
      document: $document
      category: $category
      title: $title
      notes: $notes
    ) {
      _id
      title
      category
      notes
      uploadedBy
      location
      createdAt
    }
  }
`;

const SAVE_FIELD_MUTATION = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    abn
    representative
    abn
    address {
      street
      suburb
      postcode
      state
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  fragment BudgetFieldItemDetails on BudgetFieldItem {
    _id
    value
    state
    notes
    document {
      _id
      category
      title
      notes
      location
      uploadedBy
      createdAt
    }
    createdBy {
      ...UserDetails
    }
    timestamp
  }

  mutation ($budgetId: ID!, $parentId: ID, $key: String!, $value: String) {
    saveField(
      budgetId: $budgetId
      parentId: $parentId
      key: $key
      value: $value
    ) {
      budgetId
      parentId
      key
      value
      saved
      message
      history {
        _id
        field
        fieldValue
        modifiedOn
        stage
        modifiedBy {
          _id
          name
          handle
          image
        }
      }
      budget {
        _id
        costCode
        type
        ref
        phase
        activity
        forecast {
          anticipated
          submitted
          recommended
          locked
        }
        committed {
          contracted
          let {
            ...BudgetFieldItemDetails
          }
          adjustment
          locked
          open
          approved
        }
        expenditure {
          previous {
            _id
            claimId
            timestamp
            amount
            date
            comments
          }
          current {
            amount
            comments
          }
          thisClaim
          allowed
          remaining
        }
        fcc
        description
        commitmentStatus
        noticeReferences
        comments
        action
        actionBy
        sipmReview {
          key
          value
        }
        contingencyCostCode
        accountsPayable {
          key
          value
        }
        fundingSource {
          key
          value
        }
        costCause {
          key
          value
        }
        documents {
          _id
          category
          title
          notes
          location
          uploadedBy
          createdAt
        }
        project {
          ...ProjectDetails
        }
        vendor {
          ...VendorDetails
        }
        createdBy {
          ...UserDetails
        }
        modifiedBy {
          ...UserDetails
        }
        timestamp
        stage
        history {
          _id
          field
          fieldValue
          modifiedOn
          stage
          modifiedBy {
            _id
            name
            handle
            image
          }
        }
        parentId
        isOpen
        childCount
      }
    }
  }
`;

const ADD_LET_DOCUMENT_MUTATION = gql`
  mutation ($itemId: ID!, $fieldItemId: ID!, $documentInput: DocumentInput!) {
    addLetDocument(
      itemId: $itemId
      fieldItemId: $fieldItemId
      documentInput: $documentInput
    ) {
      _id
      title
      category
      notes
      uploadedBy
      location
      createdAt
    }
  }
`;

const SPLIT_VARIATION_MUTATION = gql`
  fragment VendorDetails on Vendor {
    _id
    name
    abn
    representative
    abn
    address {
      street
      suburb
      postcode
      state
    }
  }

  fragment ProjectDetails on Project {
    _id
    name
    slug
    logo
    locked
  }
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
  }

  fragment BudgetFieldItemDetails on BudgetFieldItem {
    _id
    value
    state
    notes
    document {
      _id
      category
      title
      notes
      location
      uploadedBy
      createdAt
    }
    createdBy {
      ...UserDetails
    }
    timestamp
  }

  mutation ($itemId: ID!) {
    splitVariation(itemId: $itemId) {
      _id
      costCode
      type
      ref
      phase
      activity
      forecast {
        anticipated
        submitted
        recommended
        locked
      }
      committed {
        contracted
        let {
          ...BudgetFieldItemDetails
        }
        adjustment
        locked
        open
        approved
      }
      expenditure {
        previous {
          _id
          claimId
          timestamp
          amount
          date
          comments
        }
        current {
          amount
          comments
        }
        thisClaim
        allowed
        remaining
      }
      fcc
      description
      commitmentStatus
      noticeReferences
      comments
      action
      actionBy
      sipmReview {
        key
        value
      }
      contingencyCostCode
      accountsPayable {
        key
        value
      }
      fundingSource {
        key
        value
      }
      costCause {
        key
        value
      }
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      project {
        ...ProjectDetails
      }
      vendor {
        ...VendorDetails
      }
      createdBy {
        ...UserDetails
      }
      modifiedBy {
        ...UserDetails
      }
      timestamp
      stage
      history {
        _id
        field
        fieldValue
        modifiedOn
        stage
        modifiedBy {
          _id
          name
          handle
          image
        }
      }
      parentId
      isOpen
      childCount
    }
  }
`;

export {
  GET_BUDGETS_BY_PROJECT_QUERY,
  SAVE_BUDGET_LINE_MUTATION,
  GET_BUDGETS_BY_PROJECT_VENDOR_TYPE_QUERY,
  GET_BUDGET_FIELD_ITEM_QUERY,
  CREATE_BUDGET_FIELD_ITEM_MUTATION,
  DELETE_BUDGET_FIELD_ITEM_MUTATION,
  APPROVE_LET_MUTATION,
  DELETE_BUDGET_MUTATION,
  ADD_BUDGET_DOCUMENT_MUTATION,
  SAVE_FIELD_MUTATION,
  ADD_LET_DOCUMENT_MUTATION,
  GET_BUDGET_CHILDREN,
  SPLIT_VARIATION_MUTATION,
};
