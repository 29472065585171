import React, { useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useUpload } from '../../hooks';
import { toBase64 } from '../../common/utils';

const PhotoUpload = ({
  imageType,
  identifier,
  onUploadCompleted = () => {},
}) => {
  const [uploadImage, { loading: uploadLoading, data }] = useUpload(imageType);
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (!validity.valid) return;

    try {
      await uploadImage(await toBase64(file), identifier);
    } catch (e) {}
  };

  useEffect(() => {
    if (!uploadLoading && data) onUploadCompleted(data);
  }, [uploadLoading]);

  return (
    <>
      <Button color="projx" onClick={handleClick}>
        <i className="fas fa-upload" />{' '}
        {uploadLoading ? 'Uploading...' : 'Upload'}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

PhotoUpload.propTypes = {
  onUploadCompleted: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
  imageType: PropTypes.string.isRequired,
};

export default PhotoUpload;
