import React from 'react';

// Styles
import {
  SpinnerContainer,
  ClipLoaderStyles,
} from './styles/Spinner';

const Spinner = () => {
  return (
    <SpinnerContainer>
      <ClipLoaderStyles />
    </SpinnerContainer>
  );
}

export default Spinner;