import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;

  ${(props) => props.horizontal && css`
    flex-direction: row;
    align-items: center;
  `}
`;

export const InputHeader = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 8px 0;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Divider = styled.div`
  margin: 0 4px;
`;

export const TextAreaStyled = styled.textarea`
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  padding: 8px;
`;

export const NumericInputStyles = styled(NumberFormat)`
  font-family: 'Inter', sans-serif;
  color: #1B1B1B;
  background-color: #F9F9F9;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #D9D9D9;
`;
