import React from 'react';

import { Card, CardBody, CardFooter } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';

const ProjectOverview = ({ project }) => {
  return (
    <Card>
      {project.code ? (
        <CardHeader>
          Project Code: <b>{project.code}</b>
        </CardHeader>
      ) : (
        ''
      )}
      <CardBody>
        {!project ? (
          <div className="d-flex mt-3 p-4 flex-column align-items-center justify-content-center">
            <i className="ti-agenda display-5" />
            <h4 className="mt-2">Unable to load project. Please refresh.</h4>
          </div>
        ) : (
          <>
            <div className="d-flex align-items-center mb-3">
              <div>
                <h1 className="font-bold mb-0">{project.name}</h1>
                <span>{project.client.name}</span>
              </div>
              <div className="ml-auto float-right">
                <img
                  src={project.logo}
                  className="rounded-circle"
                  width="50"
                  alt={project.logo}
                />
              </div>
            </div>
            <p>{project.client.contract}</p>
          </>
        )}
      </CardBody>
      <CardFooter>
        {/* <ProjectToolbar projectKey={project._id} /> */}
        <i className="mdi mdi-map-marker mr-2 text-danger" />
        <a
          target="_blank"
          href={`https://www.google.com/maps/search/${project.address.replace(/ /g, '+')}`}
        >
          {project.address}
        </a>
      </CardFooter>
    </Card>
  );
};

export default ProjectOverview;
