import styled, { css } from 'styled-components';
import { MdOutlineUnfoldMore, MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';

const TableColumnHeaderIconStyles = css`
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;

export const UpArrow = styled(MdOutlineExpandLess)`
  ${TableColumnHeaderIconStyles}
`;

export const DownArrow = styled(MdOutlineExpandMore)`
  ${TableColumnHeaderIconStyles}
`;

export const VerticalArrows = styled(MdOutlineUnfoldMore)`
  ${TableColumnHeaderIconStyles}
`;