import React, { useState, useContext, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { parse } from 'query-string';
import validators from './validators';
import AuthContext from '../../contexts/auth/auth.context';
import rules from '../../helpers/form-validator/libs/rules';
import { RequiresNewPassword } from '../../common/errors';
// New home page stuff
import ClaimsSVG from '../../assets/images/login-slider/supporting_documents.svg';
import DashboardSVG from '../../assets/images/login-slider/dashboard.svg';
import VariationSVG from '../../assets/images/login-slider/variations.svg';
import logoDarkSVG from '../../assets/images/logo_dark.svg';
import { LogoIMG } from '../../components';
import {
  LoginPageContainer,
  CarouselContainer,
  LoginFormContainer,
  LogoContainer,
  LoginFormBody,
  LoginFormHeading,
  LoginFormInputLabel,
  LoginFormInput,
  LoginFormErrorContainer,
  LoginFormErrorText,
  LoginFormInfo,
  LoginFormLink,
  LoginFormText,
  LoginFormButton,
  LoginPageImage,
  LoginPageCarouselHeading,
  LoginPageCarouselText,
  LoginPageCarouselButtonContainer,
  LoginPageCarouselButton,
  FadeAnimationContainer,
} from './styles/LoginStyles';

const carouselCards = {
  0: {
    image: ClaimsSVG,
    heading: 'Uniform reports at your fingertips',
    text: 'Get a detailed overview of your projects - anytime, anywhere.',
  },
  1: {
    image: DashboardSVG,
    heading: 'Utilise the power of customizable dashboards',
    text: 'Understand your project status at a glance with powerful visualisations',
  },
  2: {
    image: VariationSVG,
    heading: 'Be on the same page',
    text: 'Work seamlessly with the project team by being on one platform',
  },
};

const Login = ({ location }) => {
  const history = useHistory();

  const [carouselIndex, setCarouselIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [forceNewPassword, setForceNewPassword] = useState(false);

  const {
    login: authContextLogin,
    forceNewPassword: authContextForceNewPassword,
  } = useContext(AuthContext);

  // Construct errors for email field
  let emailErrors = [];
  if (!validators.email.rules[0].test.test(email) && email.length !== 0) {
    emailErrors.push('* Please enter a valid email');
  }

  // Construct errors for new password field
  let newPasswordErrors = [];
  if (newPassword.length !== 0) {
    if (newPassword.length < 8) {
      newPasswordErrors.push('* Password must be at least 8 characters');
    }
    if (!rules.password[1].test.test(newPassword)) {
      newPasswordErrors.push('* Password must contain a lowercase character');
    }
    if (!rules.password[2].test.test(newPassword)) {
      newPasswordErrors.push('* Password must contain an uppercase character');
    }
    if (!rules.password[3].test.test(newPassword)) {
      newPasswordErrors.push('* Password must contain a number');
    }
    if (!rules.password[4].test.test(newPassword)) {
      newPasswordErrors.push('* Password must contain a special character');
    }
  }

  // Construct errors for confirm new password field
  let confirmNewPasswordErrors = [];
  if (newPassword.length !== 0 && newPassword !== confirmNewPassword && confirmNewPassword.length !== 0) {
    confirmNewPasswordErrors.push('* Passwords do not match');
  }

  const isNewPasswordButtonDisabled =
    newPasswordErrors.length !== 0 ||
    confirmNewPasswordErrors.length !== 0 ||
    newPassword.length === 0 ||
    confirmNewPassword.length === 0 ||
    isProcessing;

  const isLoginButtonDisabled =
    emailErrors.length !== 0 ||
    email.length === 0 ||
    password.length === 0 ||
    isProcessing;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleLoginFormSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    authContextLogin(email, password)
      .then((user) => {
        history.push({
          pathname: '/',
        });
      })
      .catch((error) => {
        if (error instanceof RequiresNewPassword) {
          setForceNewPassword(true);
          setPassword('');
          setIsProcessing(false);
          return;
        }

        setIsProcessing(false);
        console.error(error);
        alert(error.message);
      });
  };

  const handleCompleteNewPassword = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    authContextForceNewPassword(email, password)
      .then((user) => {
        history.push({
          pathname: '/',
        });
      })
      .catch((error) => {
        setIsProcessing(false);
        console.error(error);
        alert(error.message);
      });
  };

  useEffect(() => {
    const params = parse(location.search);
    if (params.email) {
      setEmail(params.email);
      // this.formValidators('email', params.email);
    }
  }, [location]);

  useEffect(() => {
    const carouselPaginate = setInterval(() => {
      setCarouselIndex(() =>
        carouselIndex === Object.keys(carouselCards).length - 1
          ? 0
          : carouselIndex + 1,
      );
    }, 10000);

    return () => {
      clearInterval(carouselPaginate);
    };
  }, [carouselIndex]);

  const showFormValidationErrors = (field) => {
    switch (field) {
      case 'email':
        const mappedEmailErrors = _.map(emailErrors, (value) => (
          <LoginFormErrorText>{value}</LoginFormErrorText>
        ));

        return (
          <LoginFormErrorContainer>
            {mappedEmailErrors}
          </LoginFormErrorContainer>
        );

        break;
      case 'newPassword':
        const mappedNewPasswordErrors = _.map(newPasswordErrors, (value) => (
          <LoginFormErrorText>{value}</LoginFormErrorText>
        ));

        return (
          <LoginFormErrorContainer>
            {mappedNewPasswordErrors}
          </LoginFormErrorContainer>
        );

        break;
      case 'confirmNewPassword':
        const mappedConfirmNewPasswordErrors = _.map(
          confirmNewPasswordErrors,
          (value) => <LoginFormErrorText>{value}</LoginFormErrorText>,
        );

        return (
          <LoginFormErrorContainer>
            {mappedConfirmNewPasswordErrors}
          </LoginFormErrorContainer>
        );

        break;
    }
  };

  if (forceNewPassword) {
    return (
      <LoginPageContainer>
        <LoginFormContainer center>
          <Link to="/">
            <LogoContainer>
              <LogoIMG src={logoDarkSVG} alt="projx-logo" />
            </LogoContainer>
          </Link>
          <LoginFormBody onSubmit={handleCompleteNewPassword}>
            <LoginFormHeading>Reset your password</LoginFormHeading>
            <LoginFormInputLabel for="newPassword">
              New password
            </LoginFormInputLabel>
            <LoginFormInput
              placeholder=""
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            {showFormValidationErrors('newPassword')}
            <LoginFormInputLabel for="confirmNewPassword">
              Confirm new password
            </LoginFormInputLabel>
            <LoginFormInput
              placeholder=""
              type="password"
              id="confirmNewPassword"
              name="confirmNewPassword"
              value={confirmNewPassword}
              onChange={handleConfirmNewPasswordChange}
            />
            {showFormValidationErrors('confirmNewPassword')}
            <LoginFormButton
              type="submit"
              disabled={isNewPasswordButtonDisabled}
            >
              {isProcessing ? 'Loading...' : 'Continue'}
            </LoginFormButton>
          </LoginFormBody>
        </LoginFormContainer>
      </LoginPageContainer>
    );
  }

  return (
    <LoginPageContainer>
      <LoginFormContainer>
        <Link to="/">
          <LogoContainer>
            <LogoIMG src={logoDarkSVG} alt="projx-logo" />
          </LogoContainer>
        </Link>
        <LoginFormBody onSubmit={handleLoginFormSubmit}>
          <LoginFormHeading>Login to your workspace</LoginFormHeading>
          <LoginFormInputLabel for="email">Email address</LoginFormInputLabel>
          <LoginFormInput
            placeholder="besure@projx.com"
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
          />
          {showFormValidationErrors('email')}
          <LoginFormInputLabel for="password">Password</LoginFormInputLabel>
          <LoginFormInput
            placeholder="at least 8 characters"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <LoginFormInfo>
            <LoginFormLink to="/authentication/reset-password">
              Forgot password
            </LoginFormLink>
          </LoginFormInfo>
          <LoginFormButton type="submit" disabled={isLoginButtonDisabled}>
            {isProcessing ? 'Loading...' : 'Login'}
          </LoginFormButton>
          <LoginFormText>
            Don't have an account?{' '}
            <LoginFormLink to="/authentication/register">
              Create one
            </LoginFormLink>
          </LoginFormText>
        </LoginFormBody>
      </LoginFormContainer>
      <CarouselContainer>
        {/* Use 'key' prop in FadeAnimationContainer component to force a re-render for fade animation */}
        <FadeAnimationContainer key={carouselIndex}>
          <LoginPageImage src={carouselCards[carouselIndex].image} />
          <LoginPageCarouselHeading>
            {carouselCards[carouselIndex].heading}
          </LoginPageCarouselHeading>
          <LoginPageCarouselText>
            {carouselCards[carouselIndex].text}
          </LoginPageCarouselText>
        </FadeAnimationContainer>
        <LoginPageCarouselButtonContainer>
          <LoginPageCarouselButton
            onClick={() => setCarouselIndex(0)}
            active={carouselIndex === 0}
          />
          <LoginPageCarouselButton
            onClick={() => setCarouselIndex(1)}
            active={carouselIndex === 1}
          />
          <LoginPageCarouselButton
            onClick={() => setCarouselIndex(2)}
            active={carouselIndex === 2}
          />
        </LoginPageCarouselButtonContainer>
      </CarouselContainer>
    </LoginPageContainer>
  );
};

export default Login;
