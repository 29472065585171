import styled, { css } from 'styled-components';
import {
  HiChevronLeft,
  HiChevronRight,
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
} from 'react-icons/hi';

export const TableStyles = styled.div`
  display: block;
  max-width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      font-size: 22px;
      font-weight: 300;
      margin-left: 8px;
    }

    th,
    td {
      margin: 0;
      padding: 12px 12px;
      border-bottom: 1px solid #D9D9D9;
      border-right: 1px solid #D9D9D9;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        background-color: #D9D9D9;
        width: 4px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action: none;

        &.isResizing {
          background: #1B1B1B;
        }
      }
    }
  }
`;


export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 24px;
`;

export const PaginationArrows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const IconStyles = css`
  color: #1B1B1B;
  width: 25px;
  height: 25px;
`;

export const DoubleLeftArrowIcon = styled(HiChevronDoubleLeft)`
  ${IconStyles}
`;

export const LeftArrowIcon = styled(HiChevronLeft)`
  ${IconStyles}
`;

export const RightArrowIcon = styled(HiChevronRight)`
  ${IconStyles}
`;

export const DoubleRightArrowIcon = styled(HiChevronDoubleRight)`
  ${IconStyles}
`;

export const PaginationText = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;

export const PaginationNumbers = styled.p`
  margin-left: 4px;
  font-weight: 700;
`

