import React, { useState } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Contact } from './Shared';


const AdditionalDetailsForm = ({ onSave = () => { }, toggle = () => { }, toggleLet = () => { }, open, item, details, loading, children }) => {

  return (
    <Modal isOpen={open} toggle={toggle} backdrop>
      <ModalHeader toggle={toggle}>
        <code>{item.ref} Details</code>
      </ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter className="mb-2">
        <Button color="projx" onClick={toggle}>
          Close
        </Button>
        <Button disabled={loading} color="projx" onClick={onSave}>
          <i className="fas fas-save" /> Save
        </Button>
        {details && item.type === "provisional" && !item.isNew ? (
          <>
            <Button
              disabled={loading || !details.locked || !item.committed.open}
              className="ml-2"
              color="projx"
              onClick={toggleLet}
            >
              <i className="fas fas-dollar-sign" /> Create Let Amount
            </Button>
          </>
        ) : (
          <div />
        )}
        <div className="budget-contact-card float-right">
          <Contact
            user={item.modifiedBy ? item.modifiedBy : {}}
            timestamp={item.timestamp}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AdditionalDetailsForm;
