import React, { useState } from 'react';
import _ from 'lodash';
//
import { TagsInputContainer, UnorderedListStyles, ListItemStyles, TagTitle, RemoveTagIcon, TagsInputStyles } from './styles/TagsInput';

/**
 * 
 * @param {string[]} tags The array of strings representing our tags, which is state passed from parent component
 * @param {React.SetStateAction} setTags Function updating the state of our 'tags' parameter to update tags
 * @param {number} max Set the maximum number of tags allowed inside of our input
 * @returns Our TagsInput component
 */
const TagsInput = ({ tags, setTags, max }) => {
  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      // If we have a max value defined and our size is already at this value we return:
      if (max !== undefined && _.size(tags) === max) return;
      // Trim our string of any leading or trailing whitespaces
      const trimmedString = _.trim(event.target.value);
      // Check if current tag already exists:
      const findTag = _.find(tags, (value, index) => {
        return value === trimmedString;
      });

      if (findTag !== undefined) {
        return;
      }

      // Set our tags
      setTags([...tags, trimmedString]);
      event.target.value = "";
    }
  };

  const removeTags = (tagIndexToRemove) => {
    setTags([..._.filter(tags, (value, index) => {
      return index !== tagIndexToRemove;
    })]);
  };

  return (
    <TagsInputContainer>
      <UnorderedListStyles>
        {_.map(tags, (value, index) => (
          <ListItemStyles key={index}>
            <TagTitle>{value}</TagTitle>
            <RemoveTagIcon onClick={() => removeTags(index)} />
          </ListItemStyles>
        ))}
      </UnorderedListStyles>
      <TagsInputStyles
        type="text"
        onKeyUp={event => addTags(event)}
        placeholder="Press enter to add tags"
    />
    </TagsInputContainer>
  );
};

export default TagsInput;
