import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  body {
    color: #1B1B1B;
    background-color: #F9F9F9;
    font-family: 'Inter', sans-serif; 
    font-size: 14px;
  }
`;

export default GlobalStyles;