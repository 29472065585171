import React, { useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap';
import { withProject } from '../../common';
import { BudgetManagement, CostCodeOverview } from '../../components';

import { TransactionProvider } from '../../contexts';

const Budgets = ({ project }) => {
  const [codes, setCodes] = useState(undefined);
  return (
    <>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/projects/${project._id}`}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Budgets</BreadcrumbItem>
      </Breadcrumb>
      <TransactionProvider>
        <Row>
          <Col xs="12" md="6">
            <BudgetManagement
              project={project}
              onLoaded={(data) => setCodes(data)}
            />
          </Col>
          <Col xs="12" md="6">
            <CostCodeOverview costCodes={codes} project={project} />
          </Col>
        </Row>
      </TransactionProvider>
    </>
  );
};

export default withProject(Budgets);
