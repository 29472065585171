import { useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import {
  DELETE_CLAIM_MUTATION,
  PROCESS_CLAIM_MUTATION,
  GET_CLAIMS_QUERY,
} from '../../graphql/queries';

import { cleanGqlTypename, toBase64 } from '../../common';

const itemMapper = (item) => cleanGqlTypename(item);

const mapper = (items) => {
  return _.map(items, (item) => itemMapper(item));
};

const useClaim = ({ onCompleted = () => {} }) => {
  const Actions = {
    DeleteClaim: 'DELETE_CLAIM',
    ProcessClaim: 'PROCESS_CLAIM',
    GetClaims: 'GET_CLAIMS',
  };

  const [_getClaims, { loading: getLoading }] = useLazyQuery(GET_CLAIMS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      onCompleted({
        action: Actions.GetClaims,
        data: mapper(_.get(data, 'getClaims')),
      }),
  });

  const [_deleteClaim, { loading: deleteLoading }] = useMutation(
    DELETE_CLAIM_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted({
          action: Actions.DeleteClaim,
          data: itemMapper(_.get(data, 'deleteClaim')),
        }),
    },
  );

  const [_processClaim, { loading: processLoading }] = useMutation(
    PROCESS_CLAIM_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted({
          action: Actions.ProcessClaim,
          data: itemMapper(_.get(data, 'processClaim')),
        }),
    },
  );

  const getClaims = (budgetId) => {
    return _getClaims({
      variables: {
        budgetId,
      },
    });
  };

  const deleteClaim = (budgetId) => {
    return _deleteClaim({
      variables: {
        budgetId,
      },
    });
  };

  const processClaim = (focotrId, data) => {
    if (_.has(data, 'documentInput') && data.documentInput) {
      return toBase64(data.documentInput.document).then((base64Document) => {
        const clonedData = _.cloneDeep(data);
        clonedData.documentInput.document = base64Document;

        return _processClaim({
          variables: {
            focotrId,
            input: clonedData,
          },
        });
      });
    }
    return _processClaim({
      variables: {
        focotrId,
        input: data,
      },
    });
  };

  return {
    getClaims,
    deleteClaim,
    processClaim,
    getLoading,
    deleteLoading,
    processLoading,
    Actions,
  };
};

export default useClaim;
