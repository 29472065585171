import _ from 'lodash';
import { toBase64 } from '../common';
/**
 * Given a file name string, strip the file extension
 * eg. filename.txt => filename
 */
const removeFileExtension = (str) => {
  return str.substr(0, str.lastIndexOf('.')) || str;
};

const _toDropDownLabels = (e) => {
  return { label: e, value: e };
};

/**
 * Map documentInput.document which is a file to base64 encoded strings
 *
 * @param { Array[DocumentInput] } documentInputs array of document inputs where documentInput.document is File
 *
 * @returns { Promise[Array]}
 */
const documentInputsToBase64 = (documentInputs) => {
  return Promise.all(
    _.map(documentInputs, (documentInput) => {
      return toBase64(documentInput.document).then((documentBase64) => {
        return _.assign({}, documentInput, { document: documentBase64 });
      });
    }),
  );
};

const contractDocumentCategoryOptions = [
  'Anticipated Amount - Correspondence, Notice',
  'Submitted Amount - Quote, Fee, Proposal',
  'Recommended Amount - QS Assessment',
  'Approved Amount - Client Endorsement',
  'General Correspondence',
  'Miscellaneous / Other',
].map(_toDropDownLabels);

const provisionalSumVariationDocumentCategoryOptions = [
  'Anticipated Amount - Correspondence, Notice',
  'Submitted Amount - Quote, Fee, Proposal',
  'Recommended Amount - QS Assessment',
  'Approved Amount - Client Endorsement',
  'General Correspondence',
  'Client Change Request',
  'Request for Information',
  'Consultant Advice Notice',
  'Document Transmittal',
  "Superintendent's Direction",
  'Latent Condition Notice',
  'Disruptive Activity Notice',
  'Notice of Potential Delay',
  'Extension of Time',
  'Head Contractor Varaition',
  'Provisional Sum Adjustment',
  'Miscellaneous / Other',
].map(_toDropDownLabels);

const letDocumentCategoryOptions = [
  'Recommended Amount - QS Assessment',
  'Approved Amount - Client Endorsement',
  'General Correspondence',
  'Client Change Request',
  'Request for Information',
  'Consultant Advice Notice',
  'Document Transmittal',
  "Superintendent's Direction",
  'Latent Condition Notice',
  'Disruptive Activity Notice',
  'Notice of Potential Delay',
  'Extension of Time',
  'Head Contractor Varaition',
  'Provisional Sum Adjustment',
  'Miscellaneous / Other',
].map(_toDropDownLabels);

const projectDocumentCategoryOptions = [
  'Reports & Minutes',
  'Master Programme',
  'Forecast Cashflow',
  'Batch Payment Report',
  'Financial Reports',
  'Contact List',
  'Risk Register',
  'Authority Approvals',
  'Planning & Building Permits',
  'Key Correspondence',
  'Miscellaneous / Other',
].map(_toDropDownLabels);

// Cost & Claims
const focotrDocumentCategoryOptions = [
  'Bank Guarantee',
  'Contract Sum Adjustment',
  'Insurances',
  'Contract, Agreement, Purchase Order',
  'Deed of Novation',
  'Services / Project Brief',
  'Miscellaneous / Other',
].map(_toDropDownLabels);

const processClaimDocumentCategoryOptions = [
  'Progress Claim',
  'QS Claim Recommendation',
  'Payment Schedule',
  'Invoice',
  'Statutory Declaration',
  'Bank Guarantees',
  'Insurances',
  'Photos',
  'General Correspondence',
  'Miscellaneous / Other',
].map(_toDropDownLabels);

export {
  removeFileExtension,
  documentInputsToBase64,
  letDocumentCategoryOptions,
  contractDocumentCategoryOptions,
  provisionalSumVariationDocumentCategoryOptions,
  projectDocumentCategoryOptions,
  focotrDocumentCategoryOptions,
  processClaimDocumentCategoryOptions,
};
