import gql from 'graphql-tag';

const DELETE_CLAIM_MUTATION = gql`
  mutation ($budgetId: ID!) {
    deleteClaim(budgetId: $budgetId)
  }
`;

const PROCESS_CLAIM_MUTATION = gql`
  mutation ($focotrId: ID!, $input: ClaimArgsInput!) {
    processClaim(focotrId: $focotrId, input: $input) {
      _id
      invoiceId
      date
      gstType
      gstValue
      claimedAmount
      claimedAmountComments
      qsRecommendation
      qsAmount
      comments
      timestamp
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      paymentSchedule {
        projectName
        principal
        contractor
        issueDate
        originalContractSum
        variationsApprovedToDate
        provisionalSumAdjustment
        adjustedContractSum
        contractorPaymentReference
        contractorPaymentClaimDate
        totalAmountOfPaymentClaim
        contractWorks
        variationWorks
        provisionalSums
        workExecutedSubTotal
        previouslyAssessed
        thisClaim
        gst
        includingGst
        reasonIfAmountsDiffer
        comments
      }
    }
  }
`;

const GET_CLAIMS_QUERY = gql`
  query ($budgetId: ID!) {
    getClaims(budgetId: $budgetId) {
      _id
      claimId
      amount
      timestamp
      date
      comments
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
      paymentSchedule {
        projectName
        principal
        contractor
        issueDate
        originalContractSum
        variationsApprovedToDate
        provisionalSumAdjustment
        adjustedContractSum
        contractorPaymentReference
        contractorPaymentClaimDate
        totalAmountOfPaymentClaim
        contractWorks
        variationWorks
        provisionalSums
        workExecutedSubTotal
        previouslyAssessed
        thisClaim
        gst
        includingGst
        reasonIfAmountsDiffer
        comments
      }
    }
  }
`;

export { DELETE_CLAIM_MUTATION, PROCESS_CLAIM_MUTATION, GET_CLAIMS_QUERY };
