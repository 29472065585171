import { useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { useContext } from 'react';
import { translateGqlData } from '../../common';

import {
  GET_REFERENCES_BY_ORG,
  CREATE_REFERENCE_MUTATION,
  UPDATE_REFERENCE_MUTATION,
  DELETE_REFERENCE_MUTATION,
  UPDATE_REFERENCE_ITEM_MUTATION,
  DELETE_REFERENCE_ITEM_MUTATION,
  CREATE_REFERENCE_ITEM_MUTATION,
} from '../../graphql/queries';
import { OrganisationContext } from '../../contexts';

const itemMapper = (data) => {
  return {
    _id: data._id,
    limit: data.limit,
    name: data.name,
    organisation: data.organisation._id,
    items: _.map(data.items, (item) => ({
      key: item.key,
      value: item.value,
      locked: item.locked,
    })),
  };
};

const referenceItemMapper = (data) => {
  return {
    key: data.key,
    value: data.value,
    locked: data.locked,
  };
};

const mapper = (data) => _.map(data, (item) => itemMapper(item));

const useQueryReference = ({ onCompleted = () => {} }) => {
  const [_getReferences, { loading, error }] = useLazyQuery(
    GET_REFERENCES_BY_ORG,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) =>
        onCompleted(mapper(translateGqlData(data, 'getAllReferences'))),
    },
  );

  const getReferences = (orgId) => {
    _getReferences({ variables: { organisationId: orgId } });
  };

  return { loading, error, getReferences };
};

const useCreateReference = ({ onCompleted = () => {} }) => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const [_createReference, { loading, error }] = useMutation(
    CREATE_REFERENCE_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(itemMapper(translateGqlData(data, 'createReference'))),
    },
  );

  const createReference = (reference) => {
    reference.organisation = currentOrganisation
      ? currentOrganisation._id
      : undefined;
    return _createReference({
      variables: {
        item: reference,
      },
    });
  };
  return [createReference, { loading, error }];
};

const useUpdateReference = ({ onCompleted = () => {} }) => {
  const [_updateReference, { loading, error }] = useMutation(
    UPDATE_REFERENCE_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(itemMapper(translateGqlData(data, 'updateReference'))),
    },
  );

  const updateReference = (id, reference) => {
    return _updateReference({
      variables: {
        _id: id,
        item: reference,
      },
    });
  };
  return [updateReference, { loading, error }];
};

const useDeleteReference = ({ onCompleted = () => {} }) => {
  const [_deleteReference, { loading, error }] = useMutation(
    DELETE_REFERENCE_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(mapper(translateGqlData(data, 'deleteReference'))),
    },
  );

  const deleteReference = (id) => {
    return _deleteReference({
      variables: {
        _id: id,
      },
    });
  };
  return [deleteReference, { loading, error }];
};

const useUpdateReferenceItem = ({ onCompleted = () => {} }) => {
  const [_updateReferenceItem, { loading, error }] = useMutation(
    UPDATE_REFERENCE_ITEM_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(
          referenceItemMapper(translateGqlData(data, 'updateReferenceItem')),
        ),
    },
  );

  const updateReferenceItem = (id, referenceItem) => {
    return _updateReferenceItem({
      variables: {
        _id: id,
        item: referenceItem,
      },
    });
  };
  return [updateReferenceItem, { loading, error }];
};

const useCreateReferenceItem = ({ onCompleted = () => {} }) => {
  const [_createReferenceItem, { loading, error }] = useMutation(
    CREATE_REFERENCE_ITEM_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(
          referenceItemMapper(translateGqlData(data, 'createReferenceItem')),
        ),
    },
  );

  const createReferenceItem = (id, referenceItem) => {
    return _createReferenceItem({
      variables: {
        _id: id,
        item: referenceItem,
      },
    });
  };
  return [createReferenceItem, { loading, error }];
};

const useDeleteReferenceItem = ({ onCompleted = () => {} }) => {
  const [_deleteReferenceItem, { loading, error }] = useMutation(
    DELETE_REFERENCE_ITEM_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(translateGqlData(data, 'deleteReferenceItem')),
    },
  );

  const deleteReferenceItem = (id, key) => {
    return _deleteReferenceItem({
      variables: {
        _id: id,
        key,
      },
    });
  };
  return [deleteReferenceItem, { loading, error }];
};

export {
  useCreateReference,
  useUpdateReference,
  useDeleteReference,
  useUpdateReferenceItem,
  useCreateReferenceItem,
  useDeleteReferenceItem,
  useQueryReference,
};
