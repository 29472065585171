import gql from 'graphql-tag';

const CASHFLOW_FRAGMENTS = gql`

  fragment CostCodeDetails on CostCodeSetup {
    _id
    costCode {
      code
      name
    }
    type
    amount
    currentAmount
  }

  fragment UserDetails on User {
    _id
    name
    email
    handle
    title
    image
  }

  fragment CashflowPeriodDetails on CashflowPeriod {
    month
    year
    value
  }

  fragment TypedCashflowPeriodDetails on TypedCashflowPeriod {
    month
    year
    value
    type
    timestamp
  }

  fragment CostTypeCalculationFactorsDetails on CostTypeCalculationFactors {
    total
    revision
    toComplete
  }

  fragment CashflowProjectedCalculationsDetails on CashflowProjectedCalculations {
    adjusted
    nonAdjusted
    totalAdjusted
    totalNonAdjusted
  }

  fragment CashflowByCalculationsDetails on CashflowByCalculations {
    byBudget {
      ...CostTypeCalculationFactorsDetails
    }
    byCommitted {
      ...CostTypeCalculationFactorsDetails
    }
    byApproved {
      ...CostTypeCalculationFactorsDetails
    }
    byForecast {
      ...CostTypeCalculationFactorsDetails
    }
    spentToDate
    difference
  }

  fragment CashflowCalculationsDetails on CashflowCalculations {
    projected {
      ...CashflowProjectedCalculationsDetails
    }
    byCostType {
      ...CashflowByCalculationsDetails
    }
    accumulativePeriods {
      ...CashflowPeriodDetails
    }
    expenditure {
      ...CashflowPeriodDetails
    }
    accumulativeExpenditure {
      ...CashflowPeriodDetails
    }
    baselineProjections {
      ...CashflowPeriodDetails
    }
    accumulativeBaselineProjection {
      ...CashflowPeriodDetails
    }
    costProjections {
      ...CashflowPeriodDetails
    }
    accumulativeCostProjection {
      ...CashflowPeriodDetails
    }
    expenditureDifferenceProjections {
      ...CashflowPeriodDetails
    }
    accumulativeExpenditureDifferenceProjections {
      ...CashflowPeriodDetails
    }
    adjustedProjections {
      ...CashflowPeriodDetails
    }
    accumulativeAdjustedProjection {
      ...CashflowPeriodDetails
    }

    previous
  }

  fragment CashflowDetails on Cashflow {
    _id
    projectId
    costCode {
      ...CostCodeDetails
    }
    periods {
      ...CashflowPeriodDetails
    }
    updates {
      ...TypedCashflowPeriodDetails
    }
    calculations {
      ...CashflowCalculationsDetails
    }
    createdOn
    createdBy {
      ...UserDetails
    }
    modifiedOn
    modifiedBy {
      ...UserDetails
    }
  }


`;

const CASHFLOW_SUMMARY_ITEM_FRAGMENTS = gql`
  fragment SummaryCostTypeCalculationFactorsDetails on SummaryCostTypeCalculationFactors {
    total
    revision
    toComplete
    difference
  }

  fragment CashflowPeriodDetails on CashflowPeriod {
    month
    year
    value
  }

  fragment CashflowSummaryItemDetails on CashflowSummaryItem {
    cashflowId
    costCode {
      code
      name 
    }
    budgetCategory {
      code
      name
    }
    projectionBy
    projectionFactors {
      ...SummaryCostTypeCalculationFactorsDetails
    }
    periods {
      ...CashflowPeriodDetails
    }
    total
  }
`;

const CASHFLOW_SUMMARY_FRAGMENTS = gql`

  ${CASHFLOW_SUMMARY_ITEM_FRAGMENTS}

  fragment CashflowHeaderDetails on CashflowHeader {
    month
    year
    caption
  }
  
  fragment CashflowSumaryDetails on CashflowSummary {
    projectId
    items {
      ...CashflowSummaryItemDetails
    }
    columns {
      ...CashflowHeaderDetails
    }
  }
`;

const GET_CASHFLOW_QUERY = gql`
  query ($cashflowId: ID!, $window: Int!, $startDate: String!) {
    getCashflow(cashflowId: $cashflowId, window: $window, startDate: $startDate) {
      ...CashflowDetails
    }
  }
  ${CASHFLOW_FRAGMENTS}
`;

const GET_SUMMARY_ITEM_QUERY = gql`
  query ($cashflowId: ID!, $window: Int!, $startDate: String!) {
    getSummaryItem(cashflowId: $cashflowId, window: $window, startDate: $startDate) {
      ...CashflowSummaryItemDetails
    }
  }
  ${CASHFLOW_SUMMARY_ITEM_FRAGMENTS}
`;

const GET_CASHFLOW_PROJECT_COSTCODE_QUERY = gql`
  query ($projectId: ID!, $costCodeId: ID!, $window: Int!, $startDate: String!) {
    getCashflowByProjectAndCostCode(projectId: $projectId, costCodeId: $costCodeId, window: $window, startDate: $startDate) {
      ...CashflowDetails
    }
  }
  ${CASHFLOW_FRAGMENTS}
`;

const GET_CASHFLOWS_QUERY = gql`
  query ($projectId: ID!, $params: PeriodBuildParamsInput!) {
    getCashflows(projectId: $projectId, params: $params) {
      ...CashflowSumaryDetails
    }
  }
  ${CASHFLOW_SUMMARY_FRAGMENTS}
`;

const UPDATE_ESTIMATE_MUTATION = gql`
  mutation ($cashflow: CashflowQueryParamsInput!, $estimate: CashflowPeriodParamsInput!) {
    updateEstimate(cashflow: $cashflow, estimate: $estimate) {
      ...CashflowDetails
    }
  }
  ${CASHFLOW_FRAGMENTS}
`;

const CHANGE_PROJECTION_BY_MUTATION = gql`
  mutation ($cashflow: CashflowQueryParamsInput!, $byType: CashflowTypeParams!) {
    changeProjectionBy(cashflow: $cashflow, byType: $byType) {
      ...CashflowSummaryItemDetails
    }
  }
  ${CASHFLOW_SUMMARY_ITEM_FRAGMENTS}
`;

const UPDATE_AUTO_ESTIMATE_MUTATION = gql`
  mutation ($cashflow: CashflowQueryParamsInput!, $estimate: [CashflowPeriodParamsInput!]!) {
    autoEstimates(cashflow: $cashflow, estimate: $estimate) {
      ...CashflowDetails
    }
  }
  ${CASHFLOW_FRAGMENTS}
`;

const UPDATE_TYPED_ESTIMATE_MUTATION = gql`
  mutation ($cashflow: CashflowQueryParamsInput!, $estimate: TypedCashflowPeriodParamsInput!) {
    updateTypedEstimate(cashflow: $cashflow, estimate: $estimate) {
      ...CashflowDetails
    }
  }
  ${CASHFLOW_FRAGMENTS}
`;

const CREATE_CASHFLOW_MUTATION = gql`
  mutation ($projectId: ID!, $costCodeId: ID!) {
    createCashflow(projectId: $projectId, costCodeId: $costCodeId) {
      ...CashflowDetails
    }
  }
  ${CASHFLOW_FRAGMENTS}
`;

export { GET_CASHFLOW_QUERY, GET_CASHFLOWS_QUERY, UPDATE_ESTIMATE_MUTATION, UPDATE_TYPED_ESTIMATE_MUTATION, CREATE_CASHFLOW_MUTATION, GET_CASHFLOW_PROJECT_COSTCODE_QUERY, UPDATE_AUTO_ESTIMATE_MUTATION, CHANGE_PROJECTION_BY_MUTATION, GET_SUMMARY_ITEM_QUERY};
