import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import Switch from 'react-bootstrap-switch';
import { useInviteMembersToOrg } from '../../../hooks';

const UserActions = ({ organisation, members }) => {
  const [modal, setModal] = useState(false);
  const [roleButtonClicked, setRoleButtonClicked] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [inviteMembersToOrg] = useInviteMembersToOrg();

  const toggle = () => {
    setModal(!modal);
    setRoleButtonClicked(false);
    setInvitations([]);
  };

  const notAlreadyMember = (email) => {
    return !members.some((member) => member.user.email === email);
  };

  const handleInvitations = (emails) => {
    setInvitations(emails);
  };

  const sendInvites = () => {
    const userInvites = invitations.map((inviteEmail) => ({
      email: inviteEmail,
      isAdmin: roleButtonClicked,
    }));

    inviteMembersToOrg(organisation._id, userInvites).catch((err) =>
      console.error(err),
    );
    setModal(!modal);
    setInvitations([]);
  };

  return (
    <div>
      <Button color="projx" onClick={toggle} className="btn btn-lg float-right">
        <i className="fas fa-plus" /> Invite Members
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Invite people to {organisation.name}</ModalHeader>
        <ModalBody>
          <div className="mb-2 d-md-flex align-items-center">
            To:
            <div className="ml-auto">
              Admin?:{' '}
              <Switch
                onText={<i className="ti-check" />}
                offText={<i className="ti-close" />}
                defaultValue={roleButtonClicked}
                onChange={(elm, state) => setRoleButtonClicked(state)}
              />
            </div>
          </div>
          <ReactMultiEmail
            placeholder="Enter emails"
            style={{ minHeight: '100px' }}
            emails={invitations}
            onChange={(emails) => {
              handleInvitations(emails);
            }}
            validateEmail={(email) => {
              return isEmail(email) && notAlreadyMember(email);
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index} style={{ fontSize: '.9rem' }}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="projx" onClick={sendInvites}>
            Invite
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserActions;
