// Library Imports
import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { gql, useLazyQuery } from '@apollo/client';
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
  usePagination,
} from 'react-table';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Chrono } from 'react-chrono';
// Local Imports
import { IconButton } from '../../components';
import { withProject } from '../../common';
import { MilestoneContext } from '../../contexts';
import MilestoneHeader from './header';
import {
  TableStyles,
  PaginationContainer,
  PaginationArrows,
  DoubleLeftArrowIcon,
  LeftArrowIcon,
  RightArrowIcon,
  DoubleRightArrowIcon,
  PaginationText,
  PaginationNumbers
} from './table/styles/Table';
import {
  UpArrow,
  DownArrow,
  VerticalArrows,
} from './icons/styles/Icons';
import EditLineItemModal from './editLineItemModal';
import EditTableLineItemButton from './editLineItemButton';
import FilterAccordion from './filterAccordion';
import StatusBadge from './statusBadge';
import PhaseBadge from './phaseBadge';
import { statusColorOptionMapping, phaseColorOptionMapping } from './constants';

// GraphQL Queries
const GET_MILESTONE_DOCUMENTS_WITH_FILTER = gql`
  query getMilestoneDocumentsWithFilter(
    $projectId: ID!
    $filter: MilestoneFilterInput!
  ) {
    getMilestoneDocumentsWithFilter(projectId: $projectId, filter: $filter) {
      _id
      phase
      description
      scheduleDate
      revisedScheduleDate
      targetDate
      actualDate
      status
    }
  }
`;

/**
 *
 * @param {Object} project This is the project object for the default export for our milestone
 * @returns Our view which is used to render the Milestone Page
 */
const MilestoneView = ({ project }) => {
  // Data for our table
  const [tableData, setTableData] = useState([]);
  // Data for our timeline widget
  const [timelineData, setTimelineData] = useState([]);
  // When in our editing milestone modal, use this state to keep track of which document we are editing
  const [currDocumentId, setCurrDocumentId] = useState('');
  // For showing/hiding our modal for editing a milestone
  const [showEditMilestoneModal, setShowEditMilestoneModal] = useState(false);

  // For controlling the state of our accordion
  const [filterAccordionOpen, setFilterAccordionOpen] = useState(false);

  /**
   * This function toggles our editLineItem Modal but is wrapped to setState of the
   * documentId of the line item that is clicked
   * @param {string} documentId The ID of our progress diary document
   */
  const toggleEditLineItemModal = ({ documentId }) => {
    // If parameter is defined in our function call then only do we assign it to our state
    if (documentId) setCurrDocumentId(documentId);

    // If modal is currently open (we are closing it), then reset currDocumentId
    if (showEditMilestoneModal) setCurrDocumentId('');
    setShowEditMilestoneModal(() => !showEditMilestoneModal);
  };

  const [
    getMilestoneTableDataWithFilters,
    {
      loading: loadingGetMilestoneDocsWithFilter,
      error: errorGetMilestoneDocsWithFilter,
      data: dataGetMilestoneDocsWithFilter,
    },
  ] = useLazyQuery(GET_MILESTONE_DOCUMENTS_WITH_FILTER, {
    onCompleted: (data) => {
      setTableData(data.getMilestoneDocumentsWithFilter);
    },
  });

  useEffect(() => {
    let items = [];
    // Map to the data format our react-chrono timeline requires
    // NOTE: Our tableData is already sorted by scheduleDate on the backend :)
    items = _.map(tableData, (value) => {
      return {
        title: moment(value.scheduleDate).format('DD MMM, YYYY'),
        cardTitle: value.phase,
        cardDetailedText: value.description,
      };
    });
    setTimelineData(items);
  }, [tableData]);

  useEffect(() => {
    getMilestoneTableDataWithFilters({
      variables: {
        projectId: project._id,
        filter: {
          phase: 'ALL',
          startScheduleDate: null,
          endScheduleDate: null,
          startRevisedScheduleDate: null,
          endRevisedScheduleDate: null,
          startTargetDate: null,
          endTargetDate: null,
          startActualDate: null,
          endActualDate: null,
          status: 'ALL',
        },
      },
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        accessor: '_id', // accessor is the "key" in the data
        minWidth: 40,
        width: 40,
        maxWidth: 40,
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ value }) => {
          return (
            <EditTableLineItemButton
              onClick={() => toggleEditLineItemModal({ documentId: value })}
            />
          );
        },
      },
      {
        Header: 'Phase',
        accessor: 'phase', // accessor is the "key" in the data
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        disableResizing: true,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <PhaseBadge
                backgroundColor={phaseColorOptionMapping[value].backgroundColor}
              >
                {phaseColorOptionMapping[value].text}
              </PhaseBadge>
            </div>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        minWidth: 350,
        width: 450,
        disableSortBy: true,
      },
      {
        Header: 'Schedule Date',
        accessor: 'scheduleDate',
        minWidth: 210,
        width: 210,
        maxWidth: 210,
        disableResizing: true,
        sortType: (rowA, rowB) =>
          moment(rowA.values.scheduleDate).isBefore(rowB.values.scheduleDate)
            ? -1
            : 1,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                textAlign: 'end',
              }}
            >
              {value === null ? '-' : moment(value).format('DD MMM, YYYY')}
            </div>
          );
        },
      },
      {
        Header: 'Revised Schedule Date',
        accessor: 'revisedScheduleDate',
        minWidth: 300,
        width: 300,
        maxWidth: 300,
        disableResizing: true,
        sortType: (rowA, rowB) =>
          moment(rowA.values.revisedScheduleDate).isBefore(
            rowB.values.revisedScheduleDate,
          )
            ? -1
            : 1,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                textAlign: 'end',
              }}
            >
              {value === null ? '-' : moment(value).format('DD MMM, YYYY')}
            </div>
          );
        },
      },
      {
        Header: 'Target Date',
        accessor: 'targetDate',
        minWidth: 180,
        width: 180,
        maxWidth: 180,
        disableResizing: true,
        sortType: (rowA, rowB) =>
          moment(rowA.values.targetDate).isBefore(rowB.values.targetDate)
            ? -1
            : 1,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                textAlign: 'end',
              }}
            >
              {value === null ? '-' : moment(value).format('DD MMM, YYYY')}
            </div>
          );
        },
      },
      {
        Header: 'Actual Date',
        accessor: 'actualDate',
        minWidth: 180,
        width: 180,
        maxWidth: 180,
        disableResizing: true,
        sortType: (rowA, rowB) =>
          moment(rowA.values.actualDate).isBefore(rowB.values.actualDate)
            ? -1
            : 1,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                textAlign: 'end',
              }}
            >
              {value === null ? '-' : moment(value).format('DD MMM, YYYY')}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 130,
        width: 130,
        maxWidth: 130,
        disableResizing: true,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StatusBadge
                backgroundColor={
                  statusColorOptionMapping[value].backgroundColor
                }
              >
                {statusColorOptionMapping[value].text}
              </StatusBadge>
            </div>
          );
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows // (replace rows with 'page' so we can use pagination)
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } =
    useTable(
      {
        columns,
        data: tableData,
        initialState: {
          pageIndex: 0,
          pageSize: 10,
        },
      },
      useSortBy,
      useFlexLayout,
      useResizeColumns,
      usePagination,
    );

  return (
    <>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/projects/${project._id}`}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Milestones</BreadcrumbItem>
      </Breadcrumb>
      <div style={{ height: '400px' }}>
        <Chrono
          items={timelineData}
          theme={{
            primary: '#1B1B1B',
            secondary: '#EF6555',
            // cardBgColor: '#F9F9F9',
            // cardForeColor: '#1B1B1B',
            titleColor: '#1B1B1B',
            // textColor: 'red',
          }}
          fontSizes={{
            cardSubtitle: '16px',
            cardText: '24px',
            cardTitle: '24px',
          }}
          // This is super important, it ensures our timeline re-renders with state updates
          allowDynamicUpdate={true}
          enableOutline
          cardWidth={400}
          cardHeight={100}
        />
      </div>
      {/* This context provider is used for our milestone filter component */}
      <MilestoneContext.Provider
        value={{
          filterAccordionOpen,
          setFilterAccordionOpen,
          getMilestoneTableDataWithFilters,
        }}
      >
        <MilestoneHeader projectId={project._id} setTableData={setTableData} />
        <FilterAccordion projectId={project._id} />
      </MilestoneContext.Provider>
      <TableStyles>
        <div className="tableWrap">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      {...column.getHeaderProps({
                        // Handle our sorting props
                        ...column.getSortByToggleProps(),
                      })}
                    >
                      {column.render('Header')}
                      {column.canResize && (
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          }`}
                        />
                      )}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <DownArrow />
                          ) : (
                            <UpArrow />
                          )
                        ) : column.canSort ? (
                          <VerticalArrows />
                        ) : null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.collapse ? 'collapse' : '',
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <PaginationContainer>
            <PaginationArrows>
              <IconButton
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <DoubleLeftArrowIcon />
              </IconButton>
              <IconButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <LeftArrowIcon />
              </IconButton>
              <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
                <RightArrowIcon />
              </IconButton>
              <IconButton
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <DoubleRightArrowIcon />
              </IconButton>
            </PaginationArrows>
            <PaginationText>
              {`Page`}
              <PaginationNumbers>{`${pageIndex + 1} of ${
                pageOptions.length
              }`}</PaginationNumbers>
            </PaginationText>
          </PaginationContainer>
        </div>
      </TableStyles>
      {/* Only if our currDocumentId is defined, then do we render our edit line item modal */}
      {currDocumentId !== '' && (
        <EditLineItemModal
          showEditMilestoneModal={showEditMilestoneModal}
          toggleEditLineItemModal={toggleEditLineItemModal}
          documentId={currDocumentId}
          projectId={project._id}
          setTableData={setTableData}
        />
      )}
    </>
  );
};

export default withProject(MilestoneView);
