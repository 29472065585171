import React from 'react';

import { Main, Table, Period } from '../common';

const BudgetTransfers = ({ template }) => {
  return (
    <Main template={template} name={'Budget Transfer'}>
      <Period period={template.period} />
      <Table table={template.table} />
    </Main>
  );
};

export default BudgetTransfers;
