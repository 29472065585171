import styled from 'styled-components';
import { MdErrorOutline } from 'react-icons/md';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export const ErrorIcon = styled(MdErrorOutline)`
  font-size: 100px;
`;

export const ErrorText = styled.p`
  font-size: 17px;
  font-weight: 700;
  margin-top: 15px;
  padding: 0 15px;
  text-align: center;
`;