import React from 'react';
import {
  Text,
  Document,
  Page,
  StyleSheet,
  Image,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';

import { GlobalStyles } from '../common';
import { Amount, Section } from '../../../pdf/common';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    textTransform: 'uppercase',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    paddingLeft: '30%',
  },
  name: {
    fontSize: 18,
    fontFamily: 'Lato Bold',
  },
  logo: {
    width: 60,
    height: 60,
  },
  formDetails: {
    flexDirection: 'row',
  },
  detailsContainer: {
    flexDirection: 'column',
    padding: 2,
    marginTop: 5,
    // border: 1,
    // borderStyle: "dotted",
    // borderColor: "black"
  },
  detailsLabel: {
    flex: 1,
    fontSize: 9,
    fontFamily: 'Lato Bold',
    width: '40%',
  },
  detailsText: {
    flex: 1,
    fontSize: 9,
    fontFamily: 'Lato',
  },
  detailsFooter: {
    fontSize: 9,
    fontFamily: 'Lato Bold',
    textAlign: 'center',
    left: 0,
    right: 0,
    marginTop: 30,
    marginBottom: 10,
  },
  marginize: {
    marginTop: 3,
  },
  sectionText: {
    marginTop: 10,
    padding: 2,
    fontSize: 11,
    fontFamily: 'Lato Bold',
    backgroundColor: '#f0f0f0',
  },
  signatures: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 100,
  },
  signatureBox: {
    border: 1,
    borderStyle: 'dotted',
    borderColor: 'black',
    margin: 5,
    flexGrow: 1,
    height: '110px',
  },
  signatureLine: {
    fontSize: 8,
    fontFamily: 'Lato',
    color: 'grey',
    marginTop: 30,
    marginBottom: 8,
    padding: 3,
    borderBottom: 1,
    borderStyle: 'dotted',
    borderColor: 'gray',
  },
  signatureInfo: {
    fontSize: 8,
    fontFamily: 'Lato',
    padding: 3,
  },
  footer: {
    fontSize: 8,
    fontFamily: 'Lato Bold',
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    color: 'grey',
    padding: 3,
    borderWidth: 1,
    borderColor: 'gray',
    borderStyle: 'dashed',
    '@media orientation: landscape': {
      marginTop: 10,
    },
  },
  gap: {
    height: '300px',
  },
  negative: {
    color: 'red',
  },
});

const Header = ({ name, details }) => {
  return (
    <View style={{ margin: 2 }}>
      <View style={styles.container}>
        <View style={styles.detailColumn}>
          <Text style={styles.name}>{name}</Text>
        </View>
        <View style={styles.linkColumn}>
          <Image
            style={styles.logo}
            src={`${
              details.organisationLogo
            }?noCache=${Math.random().toString()}`}
            source={{
              header: {
                'Access-Control-Allow-Origin': '*',
              },
            }}
          />
        </View>
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.formDetails}>
          <Text style={styles.detailsLabel}>Project Code:</Text>
          <Text style={styles.detailsText}>
            {details.projectCode ? details.projectCode : '-'}
          </Text>
        </View>
        <View style={styles.formDetails}>
          <Text style={styles.detailsLabel}>Project Name:</Text>
          <Text style={styles.detailsText}>
            {details.projectName ? details.projectName : '-'}
          </Text>
        </View>
        <View style={styles.formDetails}>
          <Text style={styles.detailsLabel}>Project Address:</Text>
          <Text style={styles.detailsText}>
            {details.projectAddress ? details.projectAddress : '-'}
          </Text>
        </View>
        <View style={styles.formDetails}>
          <Text style={styles.detailsLabel}>Client:</Text>
          <Text style={styles.detailsText}>
            {details.clientName ? details.clientName : '-'}
          </Text>
        </View>
        <View style={styles.formDetails}>
          <Text style={[styles.detailsLabel, styles.marginize]}>
            Budget Code:
          </Text>
          <Text style={[styles.detailsText, styles.marginize]}>
            {details.costCode ? details.costCode : '-'}
          </Text>
        </View>
        <View style={styles.formDetails}>
          <Text style={styles.detailsLabel}>Vendor:</Text>
          <Text style={styles.detailsText}>
            {details.vendorName ? details.vendorName : '-'}
          </Text>
        </View>
        <View style={styles.formDetails}>
          <Text style={styles.detailsLabel}>Issue Date:</Text>
          <Text style={styles.detailsText}>
            {details.issueDate ? details.issueDate : '-'}
          </Text>
        </View>
      </View>
    </View>
  );
};

const Signature = () => {
  return (
    <View style={styles.signatureBox}>
      <Text style={styles.signatureLine}>sign</Text>
      <Text style={styles.signatureInfo}>Name:</Text>
      <Text style={styles.signatureInfo}>Position:</Text>
      <Text style={styles.signatureInfo}>Date:</Text>
    </View>
  );
};

const CostAdjustmentApproval = ({ template, project }) => {
  const {
    costCode,
    vendorName,
    issueDate,
    title,
    type,
    cause,
    commitmentStatus,
    noticeReferences,
    description,
    submittedCost,
    recommendedCost,
    action,
    sipmReview,
    contingencyDrawdownLabel,
    contingencyDrawdownAmount,
    comments,
  } = template;
  return (
    <Document>
      <Page orientation="portrait" size="A4" style={GlobalStyles.page}>
        <Header
          name="Cost Adjustment Approval"
          details={{
            organisationLogo: project.organisation.logo,
            projectCode: project.code,
            projectName: project.name,
            projectAddress: project.address,
            clientName: project.client.name,
            costCode,
            vendorName,
            issueDate,
          }}
        />

        <Section text="Adjustment" />
        <View style={styles.detailsContainer}>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>Title:</Text>
            <Text style={styles.detailsText}>{title}</Text>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>Type:</Text>
            <Text style={styles.detailsText}>{type}</Text>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>Cause:</Text>
            <Text style={styles.detailsText}>{cause}</Text>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>Commitment Status:</Text>
            <Text style={styles.detailsText}>{commitmentStatus}</Text>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>Notice XRef:</Text>
            <Text style={styles.detailsText}>{noticeReferences}</Text>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>Description:</Text>
            <Text style={styles.detailsText}>{description}</Text>
          </View>
        </View>
        <Section text="Endorsement" />
        <View style={styles.detailsContainer}>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>Vendor Submitted Cost:</Text>
            <View style={styles.detailsText}>
              <Amount value={submittedCost} />
            </View>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>
              Quantity Surveyor Assessed Cost:
            </Text>
            <View style={styles.detailsText}>
              <Amount value={recommendedCost} />
            </View>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>QS Recommendation:</Text>
            <Text style={styles.detailsText}>{action}</Text>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>
              PM/Superintendent Cost Review:
            </Text>
            <Text style={styles.detailsText}>{sipmReview}</Text>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>Contingency Drawdown:</Text>
            <Text style={styles.detailsText}>{contingencyDrawdownLabel}</Text>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>
              Current Budget for Contingency:
            </Text>
            <View style={styles.detailsText}>
              <Amount value={contingencyDrawdownAmount} />
            </View>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsLabel}>Further Comments:</Text>
            <Text style={styles.detailsText}>{comments}</Text>
          </View>
          <View style={styles.formDetails}>
            <Text style={styles.detailsFooter}>
              This adjustment is endorsed for approval for ${recommendedCost}
            </Text>
          </View>
        </View>
        <Section text="Approval" />
        <View style={styles.signatures}>
          <Signature />
          <Signature />
        </View>
        <Text style={styles.footer}>
          Cost Adjustment Approval Form generated by Projx on{' '}
          {moment(Date.now()).format('Do MMM YYYY')} at{' '}
          {moment(Date.now()).format('hh:mm')}
        </Text>
      </Page>
    </Document>
  );
};

export default CostAdjustmentApproval;
