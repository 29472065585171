import React, { useState } from 'react';
import { Row, Col, Collapse, CardTitle } from 'reactstrap';

const Collapsable = (props) => {
  const { children, title = '', open = false } = props;
  const [isOpen, setIsOpen] = useState(open);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Row>
      <Col xs="12">
        <CardTitle
          onClick={toggle}
          className="ml-2 mt-3"
          style={{ cursor: 'pointer' }}
        >
          <i
            className={
              isOpen
                ? 'fas fa-chevron-circle-down'
                : 'fas fa-chevron-circle-right'
            }
          />{' '}
          {title}
        </CardTitle>
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </Col>
    </Row>
  );
};

export default Collapsable;
export { Collapsable };
