import React, { useState } from 'react';
import {
  EditProgressDiaryLineItemButtonContainer,
  EditProgressDiaryLineItemButtonStyles,
  EditIcon,
} from './styles/EditProgressDiaryLineItemButton';

const EditTableLineItemButton = ({ onClick }) => {
  const [showButton, setShowButton] = useState(false);
  return (
    <EditProgressDiaryLineItemButtonContainer
      onClick={onClick}
      onMouseOver={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
    >
      <EditProgressDiaryLineItemButtonStyles show={showButton}>
        <EditIcon />
      </EditProgressDiaryLineItemButtonStyles>
    </EditProgressDiaryLineItemButtonContainer>
  );
};

export default EditTableLineItemButton;
