import gql from 'graphql-tag';

const GET_TRANSACTIONS_QUERY = gql`
  query ($projectId: ID!) {
    getTransactions(projectId: $projectId) {
      _id
      source {
        _id
        costCode {
          name
          code
        }
      }
      destination {
        _id
        costCode {
          name
          code
        }
      }
      createdBy {
        _id
        name
        handle
        image
      }
      modifiedBy {
        _id
        name
        handle
        image
      }
      modifiedDate
      project {
        _id
        name
      }
      notes
      timestamp
      amount
      state
      history {
        state
        timestamp
        user {
          _id
          name
          handle
          image
        }
      }
    }
  }
`;

const GET_TRANSACTION_QUERY = gql`
  query ($transactionId: ID!) {
    getTransaction(transactionId: $transactionId) {
      _id
      source {
        _id
        costCode {
          name
          code
        }
      }
      destination {
        _id
        costCode {
          name
          code
        }
      }
      createdBy {
        _id
        name
        handle
        image
      }
      modifiedBy {
        _id
        name
        handle
        image
      }
      modifiedDate
      project {
        _id
        name
      }
      notes
      timestamp
      amount
      state
      history {
        state
        timestamp
        user {
          _id
          name
          handle
          image
        }
      }
    }
  }
`;

const CREATE_TRANSACTION_MUTATION = gql`
  mutation ($input: TransactionInput!) {
    createTransaction(input: $input) {
      _id
      source {
        _id
        costCode {
          name
          code
        }
      }
      destination {
        _id
        costCode {
          name
          code
        }
      }
      createdBy {
        _id
        name
        handle
        image
      }
      modifiedBy {
        _id
        name
        handle
        image
      }
      modifiedDate
      project {
        _id
        name
      }
      notes
      timestamp
      amount
      state
      history {
        state
        timestamp
        user {
          _id
          name
          handle
          image
        }
      }
    }
  }
`;

const APPROVE_TRANSACTION_MUTATION = gql`
  mutation ($transactionId: ID!) {
    approveTransaction(transactionId: $transactionId) {
      _id
      source {
        _id
        costCode {
          name
          code
        }
      }
      destination {
        _id
        costCode {
          name
          code
        }
      }
      createdBy {
        _id
        name
        handle
        image
      }
      modifiedBy {
        _id
        name
        handle
        image
      }
      modifiedDate
      project {
        _id
        name
      }
      notes
      timestamp
      amount
      state
      history {
        state
        timestamp
        user {
          _id
          name
          handle
          image
        }
      }
    }
  }
`;

const WITHDRAW_TRANSACTION_MUTATION = gql`
  mutation ($transactionId: ID!) {
    withdrawTransaction(transactionId: $transactionId) {
      _id
      source {
        _id
        costCode {
          name
          code
        }
      }
      destination {
        _id
        costCode {
          name
          code
        }
      }
      createdBy {
        _id
        name
        handle
        image
      }
      modifiedBy {
        _id
        name
        handle
        image
      }
      modifiedDate
      project {
        _id
        name
      }
      notes
      timestamp
      amount
      state
      history {
        state
        timestamp
        user {
          _id
          name
          handle
          image
        }
      }
    }
  }
`;

const REJECT_TRANSACTION_MUTATION = gql`
  mutation ($transactionId: ID!) {
    rejectTransaction(transactionId: $transactionId) {
      _id
      source {
        _id
        costCode {
          name
          code
        }
      }
      destination {
        _id
        costCode {
          name
          code
        }
      }
      createdBy {
        _id
        name
        handle
        image
      }
      modifiedBy {
        _id
        name
        handle
        image
      }
      modifiedDate
      project {
        _id
        name
      }
      notes
      timestamp
      amount
      state
      history {
        state
        timestamp
        user {
          _id
          name
          handle
          image
        }
      }
    }
  }
`;

export {
  GET_TRANSACTIONS_QUERY,
  GET_TRANSACTION_QUERY,
  CREATE_TRANSACTION_MUTATION,
  APPROVE_TRANSACTION_MUTATION,
  WITHDRAW_TRANSACTION_MUTATION,
  REJECT_TRANSACTION_MUTATION,
};
