import React from 'react';

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@david.kucsai/react-pdf-table';
import { StyleSheet, View } from '@react-pdf/renderer';

import _, { get } from 'lodash';

const styles = StyleSheet.create({
  cell: {
    fontSize: 6,
    fontFamily: 'Lato',
    paddingLeft: 2,
  },
  cellTotals: {
    fontSize: 6,
    fontFamily: 'Lato Bold',
    paddingLeft: 2,
  },
  headerCell: {
    fontSize: 8,
    fontFamily: 'Lato Bold',
    paddingLeft: 2,
    backgroundColor: '#edfdff',
  },
  tableTotals: {
    marginTop: 2,
  },
  table: {
    marginTop: 10,
  },
});

const TableTotals = ({ table }) => {
  return (
    <View style={styles.tableTotals}>
      <Table data={table.totals.data}>
        <TableHeader>
          {_.map(table.fields, (field) => (
            <TableCell key={field.key} style={styles.headerCell} />
          ))}
        </TableHeader>
        <TableBody>
          {_.map(table.totals.columns, (column) => (
            <DataTableCell
              key={column.accessor}
              style={styles.cellTotals}
              getContent={(r) => get(r, column.accessor)}
            />
          ))}
        </TableBody>
      </Table>
    </View>
  );
};

export default (props) => {
  const { table } = props;

  return (
    <View style={styles.table}>
      <Table data={table.data}>
        <TableHeader>
          {_.map(table.fields, (field) => (
            <TableCell key={field.key} style={styles.headerCell}>
              {field.label}
            </TableCell>
          ))}
        </TableHeader>
        <TableBody>
          {_.map(table.fields, (field) => (
            <DataTableCell
              key={field.key}
              style={styles.cell}
              getContent={(r) => get(r, field.key)}
            />
          ))}
        </TableBody>
      </Table>
      <TableTotals table={table} />
    </View>
  );
};
