import React from 'react';
import NumericFormat from 'react-number-format';
// Local Imports
import {
  CardContainer,
  Header,
  HeaderGroup,
  HeaderText,
  BodyContainer,
  BodyPrimaryText,
  BodySecondaryText,
  BarPercentageContainer,
  BarPercentage,
  FooterContainer,
  FooterGroup,
  FooterPrimaryText,
  FooterSecondaryText,
} from './styles/AccountCard';
import {
  colors,
  calculateColorRange,
} from './constants';

/**
 * This component renders a generic card for an 'account' (i.e. funding account, cost account etc.)
 * @param {string} heading The heading of our card
 * @param {string} bodyPrimaryText The main text of our body in our component
 * @param {string} bodySecondaryText The secondary text of our body in our component
 * @param {string} footerPrimaryText The primary text of our footer in the card
 * @param {string} footerSecondaryText The secondary text of our footer in the card
 * @param {number} percentage The percentage of our card, used for our <BarPercentage /> container component
 * @param {function} onClick The onClick function handler for our component
 * (i.e. 80 - means 80% since we pass in a number)
 * @returns An AccountCard React component
 */
const AccountCard = ({ heading, bodyPrimaryText, bodySecondaryText, footerPrimaryText, footerSecondaryText, percentage, onClick }) => {
  return (
    <CardContainer onClick={onClick}>
      <Header>
        <HeaderGroup>
          <HeaderText>{heading}</HeaderText>
        </HeaderGroup>
      </Header>
      <BodyContainer>
        <BodyPrimaryText>{bodyPrimaryText}</BodyPrimaryText>
        <BodySecondaryText>{bodySecondaryText}</BodySecondaryText>
      </BodyContainer>
      <BarPercentageContainer>
        <BarPercentage widthPercentage={percentage} color={colors[calculateColorRange(percentage)]}/>
      </BarPercentageContainer>
      <FooterContainer>
        <FooterGroup>
          <FooterPrimaryText>{footerPrimaryText}</FooterPrimaryText>
          <FooterSecondaryText>{footerSecondaryText}</FooterSecondaryText>
        </FooterGroup>
        <FooterGroup>
          <FooterPrimaryText>
            <NumericFormat displayType="text" value={percentage} decimalScale={2} suffix="%"/>
          </FooterPrimaryText>
        </FooterGroup>
      </FooterContainer>
    </CardContainer>
  );
};

export default AccountCard;
