import React from 'react';
import moment from 'moment';
import { FileIcon } from 'react-file-icon';

import DocumentDownloadButton from './DocumentDownloadButton';

const DocumentCard = ({ document }) => {
  const _getDocumentExtension = (doc) => {
    // example of document location value: https://belvedere-documents-dev.s3.ap-southeast-2.amazonaws.com/5f75be157c28a28e1d535248.txt

    const documentLocationSplit = doc.location.split('/');
    const documentName =
      documentLocationSplit[documentLocationSplit.length - 1];

    const documentNameSpit = documentName.split('.');

    // document could have no extension
    return documentNameSpit.length > 1 ? documentNameSpit[1] : '';
  };

  const iconExtension = _getDocumentExtension(document);

  const icon = (
    <div
      style={{
        marginLeft: 'auto',
        // marginTop: 'auto',
        // marginBottom: 'auto',
        width: '3em',
        height: '3em',
      }}
    >
      <FileIcon extension={iconExtension} radius={4} type="document" />
    </div>
  );

  const title = <h6 className="font-bold">{document.title}</h6>;
  const category = <h6 className="font-normal">{document.category}</h6>;
  const notes = <span className="text-muted">{document.notes}</span>;
  // for when uploaded by resolves to name
  // const date = <span className="text-muted float-right"><b>{letEntry.createdBy.name}</b> {moment(letEntry.timestamp).fromNow(true)} ago</span>
  const date = (
    <span className="text-muted float-right">
      {moment(document.createdAt).fromNow(true)} ago
    </span>
  );

  const downloadButton = (
    <span className="float-right">
      <DocumentDownloadButton document={document} />
    </span>
  );

  return (
    <div className="d-flex flex-row comment-row mt-0 border-bottom">
      {icon}
      <div className="comment-text w-100">
        {downloadButton}
        {title}
        {category}
        {notes}
        <div className="comment-footer">{date}</div>
      </div>
    </div>
  );
};

export default DocumentCard;
