import React, { useEffect, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import useLocalStorage from '@rehooks/local-storage';
import { useProjectById, usePanel } from '../../hooks';
import { Menu } from '../../components';
import AuthContext from '../../contexts/auth/auth.context';

const withProject = (Component) => {
  const C = (props) => {
    const { setMenu } = usePanel();
    const { id } = useContext(AuthContext);

    const [, setProjectId] = useLocalStorage(
      `${id}.currentProject`,
      props.match.params.handle
        ? props.match.params.handle
        : props.match.params.projectId,
    );

    const { projectByIdLoading, project } = useProjectById(
      props.match.params.handle
        ? props.match.params.handle
        : props.match.params.projectId,
    );

    useEffect(() => {
      setProjectId(
        props.match.params.handle
          ? props.match.params.handle
          : props.match.params.projectId,
      );
    }, [props.match.params.projectId, props.match.params.handle]);

    useEffect(() => {
      setMenu(<Menu project={project} />);
    }, [projectByIdLoading]);

    if (projectByIdLoading) return <Skeleton />;

    return <Component {...props} project={project} />;
  };
  return C;
};

export default withProject;
