import styled, {css } from 'styled-components';
import { GrUserSettings } from 'react-icons/gr';
import { BiLogOut } from 'react-icons/bi';

const UserBarIconStyles = css`
  width: 20px;
  height: 20px;
  color: #1B1B1B;
`;

export const UserSettingIcon = styled(GrUserSettings)`
  ${UserBarIconStyles}
`;

export const LogoutIcon = styled(BiLogOut)`
  ${UserBarIconStyles}
`;