import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { cleanGqlTypename } from '../../../common';
import { Spinner } from '../../../components';
import SafetyNumericInput from '../safetyPageNumericInput';

// Styles
import {
  SafetyPeriodPageContainer,
  SafetyPeriodPageHeaderContainer,
  SafetyPeriodPageHeading,
  SafetyPeriodPageCardsContainer,
  SafetyPeriodPageCardsColumn,
  SafetyPeriodPageCard,
  SafetyPeriodPageCardHeader,
  SafetyPeriodPageCardLineItem,
  SafetyPeriodPageCardLineItemText,
  SafetyPeriodPageCardLineItemNumber,
  SafetyPeriodPageErrorContainer,
  SafetyPeriodPageErrorContainerText,
} from './styles/SafetyPeriodPageStyles';

// GraphQL tagged template literals
const GET_SAFETY_DOCUMENT_FOR_PROJECT = gql`
  query getSafetyDocument($projectId: ID!, $month: Int!, $year: Int!) {
    getSafetyDocument(projectId: $projectId, month: $month, year: $year) {
      project
      period {
        month
        year
      }
      referenceMetrics {
        hoursWorked
        monthlyBuilderClaim
      }
      healthAndSafetyIndicators {
        siteInductionsCompleted
        drugTestsCompleted
      }
      environmentalIndicators {
        fuelConsumptionInLitres
        LPGConsumptionInLitres
        electricityConsumptionInKWH
        waterConsumptionInKL
        wasteRecycledInTonnes
        wasteLandfillInTonnes
      }
      inspectionsAndAudits {
        client
        builder
        regulator
        union
        independent
        otherOrArbitrary
      }
      incidents {
        firstAidInjuries
        MTIs
        LTIs
        environmentalIncidents
      }
      events {
        nearMisses
        complaints
        reprimands
        notifiableEvents
      }
    }
  }
`;

const UPDATE_SAFETY_DOCUMENT = gql`
  mutation Mutation($updateSafetyDocumentInput: UpdateSafetyDocumentInput!) {
    updateSafetyDocument(input: $updateSafetyDocumentInput) {
      code
      success
      message
      safetyDocument {
        project
        period {
          month
          year
        }
        referenceMetrics {
          hoursWorked
          monthlyBuilderClaim
        }
        healthAndSafetyIndicators {
          siteInductionsCompleted
          drugTestsCompleted
        }
        environmentalIndicators {
          fuelConsumptionInLitres
          LPGConsumptionInLitres
          electricityConsumptionInKWH
          waterConsumptionInKL
          wasteRecycledInTonnes
          wasteLandfillInTonnes
        }
        inspectionsAndAudits {
          client
          builder
          regulator
          union
          independent
          otherOrArbitrary
        }
        incidents {
          firstAidInjuries
          MTIs
          LTIs
          environmentalIncidents
        }
        events {
          nearMisses
          complaints
          reprimands
          notifiableEvents
        }
      }
    }
  }
`;

/**
 * This function takes in an integer string comma separated at the thousands, with 2 decimal places
 * and converts it to a floating point number
 * @param {string} str The string which we convert to a float
 * @returns A floating point number of the string
 */
const parseFloatString = (str) => {
  // If no input is provided, our string will be the empty string
  if (str === '') return 0;
  return parseFloat(str.replace(/,/g, ''));
}

/**
 * This component renders all the data belonging to a particular safety period for a project
 * 
 * @param {string} projectId The ID of the project for which we want to display the safety period page 
 * @param {number} month The month of the safety period
 * @param {number} year The year of the safety period
 * @returns 
 */
const SafetyPeriodPage = ({ projectId, month, year }) => {
  const [safetyData, setSafetyData] = useState({
    referenceMetrics: {
      hoursWorked: 0,
      monthlyBuilderClaim: 0,
    },
    healthAndSafetyIndicators: {
      siteInductionsCompleted: 0,
      drugTestsCompleted: 0,
    },
    environmentalIndicators: {
      fuelConsumptionInLitres: 0,
      LPGConsumptionInLitres: 0,
      electricityConsumptionInKWH: 0,
      waterConsumptionInKL: 0,
      wasteRecycledInTonnes: 0,
      wasteLandfillInTonnes: 0,
    },
    inspectionsAndAudits: {
      client: 0,
      builder: 0,
      regulator: 0,
      union: 0,
      independent: 0,
      otherOrArbitrary: 0,
    },
    incidents: {
      firstAidInjuries: 0,
      MTIs: 0,
      LTIs: 0,
      environmentalIncidents: 0,
    },
    events: {
      nearMisses: 0,
      complaints: 0,
      reprimands: 0,
      notifiableEvents: 0,
    }
  });

  const { loading: loadingGetSafetyDocument, error: errorGetSafetyDocument, data: dataGetSafetyDocument } = useQuery(GET_SAFETY_DOCUMENT_FOR_PROJECT, {
    variables: {
      projectId,
      month,
      year
    },
    onCompleted: (data) => {
      setSafetyData(cleanGqlTypename(data.getSafetyDocument));
    },
    fetchPolicy: "cache-and-network",
  });

  const [updateSafetyDocument, { loading: loadingUpdateSafetyDocument, error: errorUpdateSafetyDocument, data }] = useMutation(UPDATE_SAFETY_DOCUMENT, {
    onCompleted: (data) => {
      setSafetyData(cleanGqlTypename(data.updateSafetyDocument.safetyDocument));
    },
  });

  if (loadingGetSafetyDocument) {
    return <Spinner />;
  }

  if (errorGetSafetyDocument) {
    return (
      <SafetyPeriodPageErrorContainer>
        <SafetyPeriodPageErrorContainerText>
          Oops... something went wrong, please refresh the page and try again.
        </SafetyPeriodPageErrorContainerText>
      </SafetyPeriodPageErrorContainer>
    );
  }
  
  return (
    <SafetyPeriodPageContainer>
      <SafetyPeriodPageHeaderContainer>
        <SafetyPeriodPageHeading>
          {`${moment({month: month - 1}).format('MMMM')} ${year} HSE Reporting`}
        </SafetyPeriodPageHeading>
      </SafetyPeriodPageHeaderContainer>
      <SafetyPeriodPageCardsContainer>
        <SafetyPeriodPageCardsColumn>
          <SafetyPeriodPageCard>
            <SafetyPeriodPageCardHeader>{`Reference Metrics`}</SafetyPeriodPageCardHeader>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Hours Worked`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.referenceMetrics.hoursWorked}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          referenceMetrics: {
                            ...safetyData.referenceMetrics,
                            hoursWorked: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Monthly Builder Claim ($)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.referenceMetrics.monthlyBuilderClaim}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          referenceMetrics: {
                            ...safetyData.referenceMetrics,
                            monthlyBuilderClaim: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
          </SafetyPeriodPageCard>
          <SafetyPeriodPageCard>
            <SafetyPeriodPageCardHeader>{`Environmental Indicators`}</SafetyPeriodPageCardHeader>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Fuel Consumption (L)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.environmentalIndicators.fuelConsumptionInLitres}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          environmentalIndicators: {
                            ...safetyData.environmentalIndicators,
                            fuelConsumptionInLitres: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`LPG Consumption (L)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.environmentalIndicators.LPGConsumptionInLitres}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          environmentalIndicators: {
                            ...safetyData.environmentalIndicators,
                            LPGConsumptionInLitres: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Electricity Consumption (kW/h)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.environmentalIndicators.electricityConsumptionInKWH}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          environmentalIndicators: {
                            ...safetyData.environmentalIndicators,
                            electricityConsumptionInKWH: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Water Consumption (kL)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.environmentalIndicators.waterConsumptionInKL}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          environmentalIndicators: {
                            ...safetyData.environmentalIndicators,
                            waterConsumptionInKL: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Waste Recycled (t)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.environmentalIndicators.wasteRecycledInTonnes}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          environmentalIndicators: {
                            ...safetyData.environmentalIndicators,
                            wasteRecycledInTonnes: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Waste Landfill (t)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.environmentalIndicators.wasteLandfillInTonnes}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          environmentalIndicators: {
                            ...safetyData.environmentalIndicators,
                            wasteLandfillInTonnes: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
          </SafetyPeriodPageCard>
        </SafetyPeriodPageCardsColumn>
        <SafetyPeriodPageCardsColumn>
          <SafetyPeriodPageCard>
            <SafetyPeriodPageCardHeader>{`Inspections & Audits`}</SafetyPeriodPageCardHeader>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Client`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.inspectionsAndAudits.client}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          inspectionsAndAudits: {
                            ...safetyData.inspectionsAndAudits,
                            client: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Builder`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.inspectionsAndAudits.builder}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          inspectionsAndAudits: {
                            ...safetyData.inspectionsAndAudits,
                            builder: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Regulator`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.inspectionsAndAudits.regulator}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          inspectionsAndAudits: {
                            ...safetyData.inspectionsAndAudits,
                            regulator: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Union`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.inspectionsAndAudits.union}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          inspectionsAndAudits: {
                            ...safetyData.inspectionsAndAudits,
                            union: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Independent`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.inspectionsAndAudits.independent}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          inspectionsAndAudits: {
                            ...safetyData.inspectionsAndAudits,
                            independent: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Other/Arbitrary`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.inspectionsAndAudits.otherOrArbitrary}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          inspectionsAndAudits: {
                            ...safetyData.inspectionsAndAudits,
                            otherOrArbitrary: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
          </SafetyPeriodPageCard>
          <SafetyPeriodPageCard>
            <SafetyPeriodPageCardHeader>{`Health & Safety Indicators`}</SafetyPeriodPageCardHeader>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Site Inductions`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.healthAndSafetyIndicators.siteInductionsCompleted}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          healthAndSafetyIndicators: {
                            ...safetyData.healthAndSafetyIndicators,
                            siteInductionsCompleted: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Drug Tests`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.healthAndSafetyIndicators.drugTestsCompleted}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          healthAndSafetyIndicators: {
                            ...safetyData.healthAndSafetyIndicators,
                            drugTestsCompleted: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
          </SafetyPeriodPageCard>
        </SafetyPeriodPageCardsColumn>
        <SafetyPeriodPageCardsColumn>
          <SafetyPeriodPageCard>
            <SafetyPeriodPageCardHeader>{'Incidents'}</SafetyPeriodPageCardHeader>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`First Aid Injuries (FAI)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.incidents.firstAidInjuries}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          incidents: {
                            ...safetyData.incidents,
                            firstAidInjuries: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Medically Treated Injuries (MTI)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.incidents.MTIs}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          incidents: {
                            ...safetyData.incidents,
                            MTIs: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Lost Time Injuries (LTI)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.incidents.LTIs}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          incidents: {
                            ...safetyData.incidents,
                            LTIs: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Environmental Incidents`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.incidents.environmentalIncidents}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          incidents: {
                            ...safetyData.incidents,
                            environmentalIncidents: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
          </SafetyPeriodPageCard>
          <SafetyPeriodPageCard>
            <SafetyPeriodPageCardHeader>{`Events`}</SafetyPeriodPageCardHeader>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Near Miss Events (NME)`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.events.nearMisses}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          events: {
                            ...safetyData.events,
                            nearMisses: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>   
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Complaints Received`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.events.complaints}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          events: {
                            ...safetyData.events,
                            complaints: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />        
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Reprimands Issued`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.events.reprimands}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          events: {
                            ...safetyData.events,
                            reprimands: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
            <SafetyPeriodPageCardLineItem>
              <SafetyPeriodPageCardLineItemText>{`Notifiable Events`}</SafetyPeriodPageCardLineItemText>
              <SafetyPeriodPageCardLineItemNumber>
                <SafetyNumericInput
                  value={safetyData.events.notifiableEvents}
                  onBlur={(e) => {
                    updateSafetyDocument({
                      variables: {
                        updateSafetyDocumentInput: {
                          ...safetyData,
                          events: {
                            ...safetyData.events,
                            notifiableEvents: parseFloatString(e.target.value),
                          }
                        }
                      }
                    });
                  }}
                />
              </SafetyPeriodPageCardLineItemNumber>
            </SafetyPeriodPageCardLineItem>
          </SafetyPeriodPageCard>
        </SafetyPeriodPageCardsColumn>
      </SafetyPeriodPageCardsContainer>
    </SafetyPeriodPageContainer>
  );
}

export default SafetyPeriodPage;
