import React, { useState, useContext } from 'react';
import { useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

// Contexts
import { SafetyContext } from '../../../contexts';

// Styles
import {
  SafetyPeriodCardStyles,
  SafetyPeriodCardHeader,
  SafetyPeriodCardButton,
  DeleteIcon,
} from './styles/safetyPeriodCardStyles';

import {
  DeleteSafetyPeriodModalHeader,
  DeleteSafetyPeriodModalText,
  DeleteSafetyPeriodModalButton,
  DeleteSafetyPeriodModalFooterContainer,
} from './styles/deleteSafetyPeriodModal';

// GraphQL tagged template literals
const REMOVE_PROJECT_SAFETY_PERIOD = gql`
  mutation Mutation($removeSafetyDocumentInput: RemoveSafetyDocumentInput!) {
    removeSafetyDocument(input: $removeSafetyDocumentInput) {
      code
      success
      message
      safetyPeriods {
        month
        year
      }
    }
  }
`;

const SafetyPeriodCard = ({ month, year }) => {
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const { setCurrSafetyPeriod, setSafetyPeriods, projectId } = useContext(SafetyContext);
  const [showDeleteSafetyPeriodModal, setShowDeleteSafetyPeriodModal] = useState(false);

  // GraphQL Mutations
  const [
    removeSafetyPeriod,
    {
      loading: removeSafetyPeriodLoading,
      error: removeSafetyPeriodError,
      data: removeSafetyPeriodData,
    },
  ] = useMutation(REMOVE_PROJECT_SAFETY_PERIOD, {
    onCompleted: (data) => {
      // If our mutation was not successful, just return
      if (data.removeSafetyDocument.success === false) {
        toast.error(data.removeSafetyDocument.message);
        return;
      }
      setSafetyPeriods(data.removeSafetyDocument.safetyPeriods);
      toast.success(data.removeSafetyDocument.message);
    }
  });

  const handleDeleteSafetyPeriod = async () => {
    setShowDeleteSafetyPeriodModal(() => !showDeleteSafetyPeriodModal);
    await removeSafetyPeriod({
      variables: {
        removeSafetyDocumentInput: {
          projectId,
          existingSafetyPeriod: {
            month,
            year,
          },
        },
      },
    });
  }

  /**
   * When a safety period card is clicked, set the safety period so parent component can render 
   * stuff relevant to this safety period (i.e month and year)
   */
  const handleSafetyPeriodCardClick = (e) => {
    setCurrSafetyPeriod({ month, year });
  };

  /**
   * When the delete button is clicked on a safety period card, stop event bubbling to parent onClick
   * event handler 
   */
  const handleDeleteSafetyPeriodButtonClicked = (e) => {
    e.stopPropagation();
    setShowDeleteSafetyPeriodModal(() => !showDeleteSafetyPeriodModal);
  }

  /**
   * Toggle our modal which is a confirmation for whether we want to delete the safety period or not
   */
  const toggleDeleteSafetyPeriodModal = () => {
    setShowDeleteSafetyPeriodModal(() => !showDeleteSafetyPeriodModal);
  }

  return (
    <>
      <SafetyPeriodCardStyles
        onMouseOver={() => setShowDeleteButton(true)}
        onMouseOut={() => setShowDeleteButton(false)}
        onClick={handleSafetyPeriodCardClick}
      >
        {/* NOTE: We subtract 1 from the month because in moment.js they are 0 indexed */}
        <SafetyPeriodCardHeader>{`${moment({ month: month - 1 }).format(
          'MMMM',
        )} ${year}`}</SafetyPeriodCardHeader>
        <SafetyPeriodCardButton
          onClick={handleDeleteSafetyPeriodButtonClicked}
          visible={showDeleteButton}
        >
          <DeleteIcon />
        </SafetyPeriodCardButton>
      </SafetyPeriodCardStyles>
      <Modal
        isOpen={showDeleteSafetyPeriodModal}
        toggle={toggleDeleteSafetyPeriodModal}
        backdrop
      >
        <ModalHeader toggle={toggleDeleteSafetyPeriodModal}>
          <DeleteSafetyPeriodModalHeader>Delete this Safety Period</DeleteSafetyPeriodModalHeader>
        </ModalHeader>
        <ModalBody>
          <DeleteSafetyPeriodModalText>
            {`Are you sure you want to delete the Safety Period for ${moment({
              month: month - 1,
            }).format('MMMM')} ${year} ? You will be unable to recover any data associated with this Safety Period.`}
          </DeleteSafetyPeriodModalText>
          <DeleteSafetyPeriodModalFooterContainer>
            <DeleteSafetyPeriodModalButton onClick={handleDeleteSafetyPeriod}>
              Delete
            </DeleteSafetyPeriodModalButton>
          </DeleteSafetyPeriodModalFooterContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SafetyPeriodCard;
