import styled, { css } from 'styled-components';

export const ProjectCardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px 16px;
  margin-bottom: 16px;
  font-family: 'Inter', sans-serif;
  color: #F9F9F9;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ProjectCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 216px;
  padding: 16px;
  border-radius: 8px;
  color: #1B1B1B;
  background-color: #F9F9F9;
  border: 1px solid #D9D9D9;
  cursor: pointer;

  &:hover {
    border: 1px solid #1B1B1B;
  }
`;

export const ProjectCardHeaderContainer = styled.div` 
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ProjectCardHeading = styled.p`
  font-weight: 500;
  font-size: 23px;
  margin: 8px 0;
  line-height: 32px;
`;

export const ProjectCardInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProjectCardCodeText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 8px;
  padding: 4px 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  
  ${(props) => props.index % 7 === 0 && css`
    color: #1B1B1B;
    background-color: #F2607E;
  `}

  ${(props) => props.index % 7 === 1 && css`
    color: #1B1B1B;
    background-color: #007BF2;
  `}

  ${(props) => props.index % 7 === 2 && css`
    color: #1B1B1B;
    background-color: #FFD145;
  `}

  ${(props) => props.index % 7 === 3 && css`
    color: #F9F9F9;
    background-color: #C21646;
  `}

  ${(props) => props.index % 7 === 4 && css`
    color: #F9F9F9;
    background-color: #0D49C1;
  `}

  ${(props) => props.index % 7 === 5 && css`
    color: #1B1B1B;
    background-color: #FD992C;
  `}

  ${(props) => props.index % 7 === 6 && css`
    color: #1B1B1B;
    background-color: #969696;
  `}
`;

export const ProjectCardTimeStamp = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 8px;
`;

export const ProjectCardFooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ProjectCardFooterButtonGroup = styled.div`
  ${(props) => props.visible === false && css`
    visibility: hidden;
  `}
`;

export const ProjectCardFooterButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-left: 16px;
  border: none;
  text-decoration: none;
  border-radius: 8px;
  background-color: #F9F9F9;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #D9D9D9;
  }
`;