import React from 'react';
import _ from 'lodash';

import SafetyPeriodCard from '../safetyPeriodCard';

// Styles
import { SafetyPeriodCardsContainerStyles } from './styles/SafetyPeriodCardsStyles';

const SafetyPeriodCards = ({ safetyPeriods }) => {
  return (
    <SafetyPeriodCardsContainerStyles>
      {_.map(safetyPeriods, (value) => (
        <SafetyPeriodCard key={`${value.month}-${value.year}`} month={value.month} year={value.year} />
      ))}
    </SafetyPeriodCardsContainerStyles>
  );
}

export default SafetyPeriodCards;