import gql from 'graphql-tag';

/**
 * Mutations
 */

const INVITE_MEMBERS_TO_ORG_MUTATION = gql`
  mutation ($organisationId: ID!, $invitations: [MemberInvitationInput!]!) {
    inviteMembersToOrg(
      organisationId: $organisationId
      invitations: $invitations
    )
  }
`;

const INVITE_COLLABORATORS_TO_PROJECT_MUTATION = gql`
  mutation (
    $organisationId: ID!
    $projectId: ID!
    $invitations: [CollaboratorInvitationInput!]!
  ) {
    inviteCollaboratorsToProject(
      organisationId: $organisationId
      projectId: $projectId
      invitations: $invitations
    )
  }
`;

const ACCEPT_INVITATION = gql`
  mutation ($invitationToken: String!) {
    acceptInvitation(invitationToken: $invitationToken)
  }
`;

export {
  INVITE_MEMBERS_TO_ORG_MUTATION,
  INVITE_COLLABORATORS_TO_PROJECT_MUTATION,
  ACCEPT_INVITATION,
};
