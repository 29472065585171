import gql from 'graphql-tag';

const GET_COSTCODE_SETUP_ORG_QUERY = gql`
  query ($orgId: ID!) {
    getCostCodeSetupByOrg(orgId: $orgId) {
      _id
      name
      description
      costCode {
        code
        name
      }
      budgetCategory {
        code
        name
      }
      accountsPayableCategory {
        code
        name
      }
      fundingSourceCategory {
        code
        name
      }
      type
      organisation {
        _id
      }
      locked
    }
  }
`;

const GET_COSTCODE_SETUP_PROJECT_QUERY = gql`
  query ($projectId: ID!) {
    getCostCodeSetupByProject(projectId: $projectId) {
      _id
      name
      description
      costCode {
        code
        name
      }
      budgetCategory {
        code
        name
      }
      accountsPayableCategory {
        code
        name
      }
      fundingSourceCategory {
        code
        name
      }
      type
      organisation {
        _id
      }
      locked
      amount
      currentAmount
      hasVendorsAssociated
      hasTransactionsAssociated
    }
  }
`;

const CREATE_COSTCODE_SETUP_MUTATION = gql`
  mutation ($input: CostCodeSetupInput!) {
    createCostCodeSetup(input: $input) {
      _id
      name
      description
      costCode {
        code
        name
      }
      budgetCategory {
        code
        name
      }
      accountsPayableCategory {
        code
        name
      }
      fundingSourceCategory {
        code
        name
      }
      type
      organisation {
        _id
      }
      locked
    }
  }
`;

const UPDATE_COSTCODE_SETUP_MUTATION = gql`
  mutation ($_id: ID!, $input: CostCodeSetupInput!) {
    updateCostCodeSetup(_id: $_id, input: $input) {
      _id
      name
      description
      costCode {
        code
        name
      }
      budgetCategory {
        code
        name
      }
      accountsPayableCategory {
        code
        name
      }
      fundingSourceCategory {
        code
        name
      }
      type
      organisation {
        _id
      }
      locked
    }
  }
`;

const CREATE_PROJECT_COSTCODE_SETUP_MUTATION = gql`
  mutation ($projectId: ID!, $input: CostCodeSetupInput!) {
    createProjectCostCodeSetup(projectId: $projectId, input: $input) {
      _id
      name
      description
      costCode {
        code
        name
      }
      budgetCategory {
        code
        name
      }
      accountsPayableCategory {
        code
        name
      }
      fundingSourceCategory {
        code
        name
      }
      type
      organisation {
        _id
      }
      locked
      amount
      currentAmount
      hasVendorsAssociated
      hasTransactionsAssociated
    }
  }
`;

const UPDATE_PROJECT_COSTCODE_SETUP_MUTATION = gql`
  mutation (
    $projectId: ID!
    $costCodeSetupId: ID!
    $input: CostCodeSetupInput!
  ) {
    updateProjecCostCodeSetup(
      projectId: $projectId
      costCodeSetupId: $costCodeSetupId
      input: $input
    ) {
      _id
      name
      description
      costCode {
        code
        name
      }
      budgetCategory {
        code
        name
      }
      accountsPayableCategory {
        code
        name
      }
      fundingSourceCategory {
        code
        name
      }
      type
      organisation {
        _id
      }
      locked
      amount
      currentAmount
      hasVendorsAssociated
      hasTransactionsAssociated
    }
  }
`;

const RETRIEVE_COST_CODE_SETUP_QUERY = gql`
  query ($projectId: ID!, $costCodeId: ID!) {
    retrieveCostCodeSetup(projectId: $projectId, costCodeId: $costCodeId) {
      _id
      name
      description
      costCode {
        code
        name
      }
      budgetCategory {
        code
        name
      }
      accountsPayableCategory {
        code
        name
      }
      fundingSourceCategory {
        code
        name
      }
      type
      organisation {
        _id
      }
      locked
      amount
      currentAmount
      hasVendorsAssociated
      hasTransactionsAssociated
    }
  }
`;

const DELETE_COSTCODE_SETUP_MUTATION = gql`
  mutation ($_id: ID!) {
    deleteCostCodeSetup(_id: $_id)
  }
`;

const DELETE_PROJECT_COSTCODE_SETUP_MUTATION = gql`
  mutation ($projectId: ID!, $costCodeId: ID!) {
    deleteProjectCostCodeSetup(projectId: $projectId, costCodeId: $costCodeId)
  }
`;

export {
  GET_COSTCODE_SETUP_ORG_QUERY,
  GET_COSTCODE_SETUP_PROJECT_QUERY,
  CREATE_COSTCODE_SETUP_MUTATION,
  UPDATE_COSTCODE_SETUP_MUTATION,
  CREATE_PROJECT_COSTCODE_SETUP_MUTATION,
  UPDATE_PROJECT_COSTCODE_SETUP_MUTATION,
  DELETE_COSTCODE_SETUP_MUTATION,
  DELETE_PROJECT_COSTCODE_SETUP_MUTATION,
  RETRIEVE_COST_CODE_SETUP_QUERY,
};
