import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import 'react-multi-email/style.css';
import Switch from 'react-bootstrap-switch';
import {
  useOrganisationMembers,
  useUpdateOrgMember,
  useDeleteOrgMember,
} from '../../../hooks';
import OrganisationContext from '../../../contexts/organisation/organisation.context';
import UserActions from './UserActions';
import UserContext from '../../../contexts/user/user.context';
import classnames from 'classnames';

const UserList = () => {
  const [modal, setModal] = useState({});
  const [members, setMembers] = useState([]);
  const { currentOrganisation } = useContext(OrganisationContext);
  const { user } = useContext(UserContext);
  const [updateOrgMember] = useUpdateOrgMember();
  const [deleteOrgMember] = useDeleteOrgMember();
  const { data, loading } = useOrganisationMembers(
    currentOrganisation ? currentOrganisation._id : undefined,
  );

  const [activeTab, setActiveTab] = useState('members');

  const toggle = (id) => {
    setModal({ [id]: !modal[id] });
  };

  const updateMemberPermissions = (userId, isAdmin) => {
    updateOrgMember(currentOrganisation._id, { user: userId, isAdmin });
  };

  const deleteMember = (userId) => {
    deleteOrgMember(currentOrganisation._id, userId).catch((err) =>
      console.error(err),
    );

    toggle(userId);

    setMembers(members.filter((member) => member.user._id !== userId));
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (!loading) setMembers(data.members);
  }, [loading]);

  return (
    <Card>
      <CardHeader>
        {/* <h3>Members</h3> */}
        <Nav pills>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'members' })}
              onClick={() => {
                toggleTab('members');
              }}
            >
              Members
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'ownership' })}
              onClick={() => {
                toggleTab('ownership');
              }}
            >
              Ownership
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>
      <CardBody>
        <TabContent className="p-4" activeTab={activeTab}>
          <TabPane key="members" tabId="members">
            <div className="d-md-flex align-items-center">
              <div className="ml-auto d-flex no-block align-items-center">
                <div className="dl">
                  <UserActions
                    organisation={currentOrganisation}
                    members={members}
                  />
                </div>
              </div>
            </div>
            <Table no-wrap v-middle responsive className="no-wrap v-middle">
              <thead>
                <tr className="border-0">
                  <th className="border-0">Name</th>
                  <th className="border-0">Email</th>
                  <th className="border-0">Admin?</th>
                </tr>
              </thead>
              <tbody>
                {members.map((member) => {
                  const {
                    isAdmin,
                    user: { _id, name, email, image },
                  } = member;

                  return (
                    <tr key={_id}>
                      <td>
                        <div className="d-flex no-block align-items-center">
                          <div className="mr-2">
                            <img
                              src={image}
                              className="rounded-circle"
                              width="45"
                              alt="user"
                            />
                          </div>
                          {name}
                        </div>
                      </td>
                      <td>{email}</td>
                      <td>
                        <Switch
                          onText={<i className="ti-check" />}
                          offText={<i className="ti-close" />}
                          defaultValue={isAdmin}
                          disabled={
                            data.owner._id === _id || (user && _id === user._id)
                          }
                          onChange={(elm, state) =>
                            updateMemberPermissions(_id, state)
                          }
                        />
                      </td>
                      <td>
                        <div className="float-right">
                          <Button
                            onClick={() => toggle(_id)}
                            disabled={
                              data.owner._id === _id ||
                              (user && _id === user._id)
                            }
                            color="danger"
                          >
                            <i className="fa fa-trash" />
                          </Button>
                          <Modal isOpen={modal[_id]} toggle={() => toggle(_id)}>
                            <ModalBody>
                              Are you sure you want to remove this member from
                              the organisation?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="danger"
                                onClick={() => deleteMember(_id)}
                              >
                                Remove
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TabPane>
          <TabPane key="ownership" tabId="ownership">
            <Row>
              <Col sm="12">
                <div className="d-flex mt-3 p-4 flex-column align-items-center justify-content-center">
                  <i className="fas fa-unlock-alt display-4"></i>
                  <h4 className="mt-2">This is a subscription-only feature.</h4>
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default UserList;
