import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

import moment from 'moment';

const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    paddingBottom: 3,
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  text: {
    fontSize: 10,
    fontFamily: 'Lato Bold',
  },
});

export default (props) => {
  const { period } = props;

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        For the reporting period {moment(period.from).format('Do MMM YYYY')} to{' '}
        {moment(period.to).format('Do MMM YYYY')}
      </Text>
    </View>
  );
};
