import { useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { v4 } from 'uuid';

import {
  GET_BUDGETS_BY_PROJECT_VENDOR_TYPE_QUERY,
  SAVE_BUDGET_LINE_MUTATION,
  GET_BUDGET_FIELD_ITEM_QUERY,
  CREATE_BUDGET_FIELD_ITEM_MUTATION,
  APPROVE_LET_MUTATION,
  DELETE_BUDGET_MUTATION,
  ADD_BUDGET_DOCUMENT_MUTATION,
  SAVE_FIELD_MUTATION,
  ADD_LET_DOCUMENT_MUTATION,
  GET_BUDGET_CHILDREN,
  SPLIT_VARIATION_MUTATION,
} from '../../graphql/queries';
import { cleanGqlTypename } from '../../common';
import { toBase64 } from '../../common/utils';

const FIELD_ITEM_TEMPLATE = {
  _id: '',
  value: '',
  state: '',
  notes: '',
  createdBy: {
    _id: '',
    name: '',
    email: '',
    handle: '',
    phone: '',
    title: '',
    image: '',
  },
  timestamp: '',
};

const ITEM_TEMPLATE = {
  _id: '',
  costCode: '',
  type: '',
  ref: '',
  phase: '',
  activity: '',
  forecast: {
    anticipated: undefined,
    submitted: undefined,
    recommended: undefined,
    locked: false,
  },
  committed: {
    contracted: undefined,
    let: [],
    adjustment: undefined,
    approved: undefined,
    locked: false,
    open: true,
  },
  expenditure: {
    allowed: 0,
    thisClaim: 0,
    remaining: 0,
    current: undefined,
    previous: [],
  },
  fcc: undefined,
  description: '',
  commitmentStatus: '',
  noticeReferences: '',
  comments: '',
  action: '',
  actionBy: '',
  sipmReview: {
    key: '',
    value: '',
  },
  contingencyCostCode: '',
  accountsPayable: {
    key: '',
    value: '',
  },
  fundingSource: {
    key: '',
    value: '',
  },
  costCause: {
    key: '',
    value: '',
  },
  documents: [],
  project: '',
  vendor: '',
  modifiedBy: undefined,
  timestamp: undefined,
  stage: '',
  history: [],
  parentId: undefined,
  isOpen: false,
  childCount: 0,
};

const fieldItemMapper = (original) => {
  let item = _.cloneDeep(FIELD_ITEM_TEMPLATE);
  original = cleanGqlTypename(original);
  item._id = original._id;
  item.value = original.value;
  item.state = original.state;
  item.notes = original.notes;
  item.createdBy = original.createdBy;
  item.timestamp = original.timestamp;
  item.document = original.document;

  return item;
};

const itemMapper = (original) => {
  let budgetCopy = _.cloneDeep(ITEM_TEMPLATE);

  original = cleanGqlTypename(original);

  budgetCopy._id = original._id;
  budgetCopy.costCode = original.costCode;
  budgetCopy.type = original.type;
  budgetCopy.ref = original.ref;
  budgetCopy.phase = original.phase;
  budgetCopy.activity = original.activity;
  budgetCopy.forecast.anticipated = original.forecast.anticipated;
  budgetCopy.forecast.submitted = original.forecast.submitted;
  budgetCopy.forecast.recommended = original.forecast.recommended;
  budgetCopy.forecast.locked = original.forecast.locked;
  budgetCopy.committed.contracted = original.committed.contracted;
  budgetCopy.committed.let = original.committed.let;
  budgetCopy.committed.adjustment = original.committed.adjustment;
  budgetCopy.committed.locked = original.committed.locked;
  budgetCopy.committed.approved = original.committed.approved;
  budgetCopy.committed.open = original.committed.open;
  budgetCopy.expenditure.current = original.expenditure
    ? original.expenditure.current
      ? original.expenditure.current
      : undefined
    : undefined;
  budgetCopy.expenditure.previous = original.expenditure
    ? original.expenditure.previous
      ? original.expenditure.previous
      : []
    : [];
  budgetCopy.expenditure.remaining = original.expenditure.remaining;
  budgetCopy.expenditure.allowed = original.expenditure.allowed;
  budgetCopy.expenditure.thisClaim = original.expenditure.thisClaim;
  budgetCopy.fcc = original.fcc;
  budgetCopy.description = original.description;
  budgetCopy.commitmentStatus = original.commitmentStatus;
  budgetCopy.noticeReferences = original.noticeReferences;
  budgetCopy.comments = original.comments;
  budgetCopy.action = original.action;
  budgetCopy.actionBy = original.actionBy;
  budgetCopy.sipmReview = original.sipmReview;
  budgetCopy.contingencyCostCode = original.contingencyCostCode;
  budgetCopy.accountsPayable = original.accountsPayable;
  budgetCopy.fundingSource = original.fundingSource;
  budgetCopy.costCause = original.costCause;
  budgetCopy.documents = original.documents;
  budgetCopy.project = original.project._id;
  budgetCopy.vendor = original.vendor._id;
  budgetCopy.modifiedBy = original.modifiedBy;
  budgetCopy.timestamp = original.timestamp;
  budgetCopy.stage = original.stage;
  budgetCopy.history = original.history ? original.history : [];
  budgetCopy.isOpen = original.isOpen;
  budgetCopy.parentId = original.parentId;
  budgetCopy.childCount = original.childCount;

  return budgetCopy;
};

const augmentReturn = (saveFieldData) => {
  if (saveFieldData.budget) {
    saveFieldData.budget = itemMapper(saveFieldData.budget);
  }
  return saveFieldData;
};

const saveItemMapper = (item) => {
  const clone = _.cloneDeep(item);

  let current = clone.expenditure.current;

  if (clone.isNew) {
    delete clone.isNew;
    clone._id = '';
  }
  delete clone.isOpen;
  delete clone.expenditure;
  delete clone.documents;
  delete clone.committed.let;
  delete clone.timestamp;
  delete clone.modifiedBy;
  delete clone.stage;
  delete clone.history;
  delete clone.childCount;

  clone['expenditure'] = {
    current: current,
  };

  return clone;
};

const mapper = (items) => items.map((original) => itemMapper(original));

const fieldMapper = (items) =>
  items.map((original) => fieldItemMapper(original));

const emptyBudgetItem = (itemData) => {
  const clone = _.cloneDeep(ITEM_TEMPLATE);
  clone._id = v4();
  return {
    ...clone,
    ...itemData,
    isNew: true,
  };
};

const emptyFieldItem = ({ value, state, notes, documentInput }) => {
  return {
    value,
    state,
    notes,
    documentInput,
  };
};

const useSaveField = ({ onCompleted = () => {} }) => {
  const [_saveField, { data, loading, error }] = useMutation(
    SAVE_FIELD_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(augmentReturn(cleanGqlTypename(_.get(data, 'saveField')))),
    },
  );

  const saveField = ({ budgetId, parentId, key, value }) => {
    return _saveField({
      variables: {
        budgetId,
        parentId,
        key,
        value,
      },
    });
  };

  const item =
    !loading && data ? cleanGqlTypename(_.get(data, 'saveField')) : undefined;

  return [saveField, { item, loading, error }];
};

const useApproveLet = ({ onCompleted = () => {} }) => {
  const [_approveLet, { data, loading, error }] = useMutation(
    APPROVE_LET_MUTATION,
    {
      onCompleted: (data) => onCompleted(itemMapper(_.get(data, 'approveLet'))),
    },
  );

  const approveLet = (itemId, fieldItemId) => {
    return _approveLet({
      variables: {
        itemId,
        fieldItemId,
      },
    });
  };

  const letItem =
    !loading && data ? itemMapper(_.get(data, 'approveLet')) : undefined;

  return [approveLet, { letItem, loading, error }];
};

const useDeleteBudget = ({ onCompleted = () => {} }) => {
  const [_deleteBudget, { data, loading, error }] = useMutation(
    DELETE_BUDGET_MUTATION,
    {
      onCompleted: (data) => onCompleted(_.get(data, 'deleteBudget')),
    },
  );

  const deleteBudget = (id) => {
    return _deleteBudget({
      variables: {
        _id: id,
      },
    });
  };

  let budgetItem = !loading && data ? _.get(data, 'deleteBudget') : undefined;

  return [deleteBudget, { budgetItem, loading, error }];
};

const useQueryFieldItems = ({ itemId, onCompleted = () => {} }) => {
  const [getBudgetFieldItems, { loading, error, refetch }] = useLazyQuery(
    GET_BUDGET_FIELD_ITEM_QUERY,
    {
      variables: {
        itemId,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) =>
        onCompleted(fieldMapper(_.get(data, 'getBudgetFieldItems'))),
    },
  );

  return { loading, error, getBudgetFieldItems, refetchFieldItems: refetch };
};

const useBudgetChildren = ({ onCompleted = () => {} }) => {
  let [_getBudgetChildren, { loading, error, refetch }] = useLazyQuery(
    GET_BUDGET_CHILDREN,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => onCompleted(mapper(_.get(data, 'getChildren'))),
    },
  );

  const getBudgetChildren = (budgetId) => {
    return _getBudgetChildren({
      variables: {
        budgetId,
      },
    });
  };

  return [getBudgetChildren, { loading, error, refetch }];
};

const useCreateBudgetFieldItem = ({ onCompleted = () => {} }) => {
  const [_createBudgetFieldItem, { data, loading, error }] = useMutation(
    CREATE_BUDGET_FIELD_ITEM_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(fieldItemMapper(_.get(data, 'createBudgetFieldItem'))),
    },
  );

  const createBudgetFieldItem = (itemId, budgetFieldItem) => {
    return _createBudgetFieldItem({
      variables: {
        itemId,
        input: emptyFieldItem(budgetFieldItem),
      },
    });
  };

  const fieldItem =
    !loading && data
      ? fieldItemMapper(_.get(data, 'createBudgetFieldItem'))
      : undefined;

  return [createBudgetFieldItem, { fieldItem, loading, error }];
};

const useQueryBudgetsByType = ({
  costCode,
  projectId,
  vendorId,
  type,
  onCompleted = () => {},
}) => {
  const [getBudgetsByType, { loading, error, refetch }] = useLazyQuery(
    GET_BUDGETS_BY_PROJECT_VENDOR_TYPE_QUERY,
    {
      variables: {
        costCode,
        projectId,
        vendorId,
        type,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) =>
        onCompleted(mapper(_.get(data, 'getBudgetsByProjectAndVendorAndType'))),
    },
  );

  const _refetchBudgets = async () => {
    try {
      let { data } = await refetch({
        costCode,
        projectId,
        vendorId,
        type,
      });
      onCompleted(mapper(_.get(data, 'getBudgetsByProjectAndVendorAndType')));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  return {
    loading,
    error,
    getBudgetsByType,
    refetchBudgets: _refetchBudgets,
    emptyBudgetItem,
  };
};

const useSaveBudgetLine = ({ onCompleted = () => {} }) => {
  const [_saveBudgetLine, { data, loading, error }] = useMutation(
    SAVE_BUDGET_LINE_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(itemMapper(_.get(data, 'saveBudgetLine'))),
    },
  );

  const saveBudgetLine = (budgetItem) => {
    return _saveBudgetLine({
      variables: {
        input: saveItemMapper(budgetItem),
      },
    });
  };

  const budgetItem =
    !loading && data ? itemMapper(_.get(data, 'saveBudgetLine')) : undefined;

  return [saveBudgetLine, { budgetItem, loading, error }];
};

const useAddBudgetDocument = () => {
  const [_addDocument, { data, loading, error }] = useMutation(
    ADD_BUDGET_DOCUMENT_MUTATION,
  );

  const addDocument = (budgetId, document, title, category, notes) => {
    return toBase64(document).then((documentBase64) => {
      return _addDocument({
        variables: {
          _id: budgetId,
          document: documentBase64,
          title,
          category,
          notes,
        },
      });
    });
  };

  return [
    addDocument,
    { loading, error, document: _.get(data, 'addBudgetDocument', data) },
  ];
};

const useAddLetDocument = ({ onCompleted = () => {} }) => {
  const [_addLetDocument, { data, loading, error }] = useMutation(
    ADD_LET_DOCUMENT_MUTATION,
    {
      onCompleted: (data) => onCompleted(_.get(data, 'addLetDocument')),
    },
  );

  const addLetDocument = (itemId, fieldItemId, documentInput) => {
    return toBase64(documentInput.document).then((documentBase64) => {
      return _addLetDocument({
        variables: {
          itemId,
          fieldItemId,
          documentInput: _.assign({}, documentInput, {
            document: documentBase64,
          }),
        },
      });
    });
  };

  const document = !loading && data ? _.get(data, 'addLetDocument') : undefined;

  return [addLetDocument, { document, loading, error }];
};

const useSplitVariation = ({ onCompleted = () => {} }) => {
  const [_splitVariation, { data, loading, error }] = useMutation(
    SPLIT_VARIATION_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(itemMapper(_.get(data, 'splitVariation'))),
    },
  );

  const splitVariation = (itemId) => {
    return _splitVariation({
      variables: {
        itemId,
      },
    });
  };

  const item =
    !loading && data ? itemMapper(_.get(data, 'splitVariation')) : undefined;

  return [splitVariation, { item, loading, error }];
};

export {
  useSaveBudgetLine,
  useQueryBudgetsByType,
  useQueryFieldItems,
  useCreateBudgetFieldItem,
  useApproveLet,
  useDeleteBudget,
  useAddBudgetDocument,
  useSaveField,
  useAddLetDocument,
  useBudgetChildren,
  useSplitVariation,
};
