const COST_TYPE = [
  {
    value: 'cost',
    label: 'Cost Account',
  },
  {
    value: 'contingency',
    label: 'Contingency Account',
  },
  {
    value: 'contribution',
    label: 'Contribution Account',
  },
];

export { COST_TYPE };
