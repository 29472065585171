import gql from 'graphql-tag';

const SINGLE_DOCUMENT_UPLOAD = gql`
  mutation (
    $file: Upload!
    $category: String!
    $title: String!
    $notes: String
  ) {
    singleDocumentUpload(
      file: $file
      category: $category
      title: $title
      notes: $notes
    ) {
      _id
      category
      title
      notes
      location
      uploadedBy
      createdAt
    }
  }
`;

const GET_DOCUMENT_SIGNED_URL = gql`
  query ($location: String!) {
    getDocumentSignedUrl(location: $location) {
      url
    }
  }
`;
export { SINGLE_DOCUMENT_UPLOAD, GET_DOCUMENT_SIGNED_URL };
