/**
 * The 3 colours we use in our <AccountCard /> component
 */
const colors = {
  normal: '#2074D4',
  warning: '#FF9800',
  danger: '#E03131',
};

/**
 * Calculates what color to display when you are given a particular percentage
 * @param {number} percentage
 * @returns
 */
const calculateColorRange = (percentage) => {
  if (percentage < 80) return 'normal';
  if (percentage < 100) return 'warning';
  return 'danger';
};

export { colors, calculateColorRange };
