import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Let } from '..';
import _ from 'lodash';

const Lets = ({ lets, approve = () => {}, itemId }) => {
  return (
    <div className="comment-widgets scrollable" style={{ height: '400px' }}>
      <PerfectScrollbar>
        {_.map(
          _.orderBy(lets, [(ll) => ll.timestamp.toLowerCase()], ['desc']),
          (l) => (
            <Let key={l._id} letEntry={l} approve={approve} itemId={itemId} />
          ),
        )}
      </PerfectScrollbar>
    </div>
  );
};

export default Lets;
