import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { withProject } from '../../common';
import { Spinner, Button } from '../../components';

// Our custom safety components for styling/layout purposes
import SafetyPeriodCards from './safetyPeriodCards';
import SafetyPeriodPage from './safetyPeriodPage';

// Our safety widget graphs
// import SafetyIncidentLineGraph from './safetyGraphs/incidentLineGraph';
// import SafetyEventBarGraph from './safetyGraphs/eventBarGraph';

// Context
import { SafetyContext } from '../../contexts';

// Styles
import {
  SafetyPage,
  SafetyHeaderContainer,
  SafetyHeaderGroup,
  SafetyHeaderBackButton,
  SafetyHeaderBackButtonText,
  SafetyHeading,
  SafetyPeriodNoItemContainer,
  SafetyPeriodNoItemText,
  SafetyHeaderBackIcon,
  SafetyPeriodsIcon,
} from './safetyPage/styles/SafetyPage';
import {
  MonthYearPicker,
  InputHeader,
  FooterContainer,
} from './safetyPage/styles/addSafetyPeriodModal';


// GraphQL Queries
const GET_PROJECT_SAFETY_PERIODS = gql`
  query getSafetyPeriods($projectId: ID!) {
    getSafetyPeriods(projectId: $projectId) {
      month
      year
    }
  }
`;

// GraphQL Mutations
const CREATE_PROJECT_SAFETY_PERIOD = gql`
  mutation Mutation($createSafetyDocumentInput: CreateSafetyDocumentInput!) {
    createSafetyDocument(input: $createSafetyDocumentInput) {
      code
      success
      message
      safetyPeriods {
        month
        year
      }
    }
  }
`;

const Safety = ({ project }) => {
  const [showAddSafetyPeriodModal, setShowAddSafetyPeriodModal] =
    useState(false);
  const [monthYearDate, setMonthYearDate] = useState(new Date());
  const [safetyPeriods, setSafetyPeriods] = useState([{}]);
  const [currSafetyPeriod, setCurrSafetyPeriod] = useState({
    month: null,
    year: null,
  });

  // GraphQL Mutations
  const [
    createSafetyPeriod,
    {
      loading: loadingCreateSafetyPeriod,
      error: errorCreateSafetyPeriod,
      data: dataCreateSafetyPeriod,
    },
  ] = useMutation(CREATE_PROJECT_SAFETY_PERIOD, {
    onCompleted: (data) => {
      // If our mutation was not successful, just return
      if (data.createSafetyDocument.success === false) {
        toast.error(data.createSafetyDocument.message);
        return;
      }
      setSafetyPeriods(data.createSafetyDocument.safetyPeriods);
      toast.success(data.createSafetyDocument.message);
    },
  });

  // GraphQL Queries
  const {
    loading: loadingSafetyPeriods,
    error: errorSafetyPeriods,
    data: dataSafetyPeriods,
  } = useQuery(GET_PROJECT_SAFETY_PERIODS, {
    variables: {
      projectId: project._id,
    },
    onCompleted: (data) => {
      setSafetyPeriods(data.getSafetyPeriods);
    },
  });

  const toggleAddSafetyPeriodModal = () => {
    setShowAddSafetyPeriodModal(() => !showAddSafetyPeriodModal);
  };

  const handleAddSafetyPeriod = async () => {
    setShowAddSafetyPeriodModal(() => !showAddSafetyPeriodModal);
    await createSafetyPeriod({
      variables: {
        createSafetyDocumentInput: {
          projectId: `${project._id}`,
          newSafetyPeriod: {
            // Add 1 to 'month' field since in moment.js they are 0 indexed - which we do not want
            month: moment(monthYearDate).month() + 1,
            year: moment(monthYearDate).year(),
          },
        },
      },
    });
  };

  if (loadingSafetyPeriods) return <Spinner />;
  if (errorSafetyPeriods) {
    return (
      <SafetyPeriodNoItemContainer>
        <SafetyPeriodNoItemText>
          Oops... Something went wrong, please refresh and try again.
        </SafetyPeriodNoItemText>
      </SafetyPeriodNoItemContainer>
    );
  }

  // If this boolean expression is true, then we have selected a particular safety period,
  // hence we render a <SafetyPeriodPage> component
  if (currSafetyPeriod.month !== null && currSafetyPeriod.year !== null) {
    return (
      <SafetyPage>
        <Breadcrumb tag="nav">
          <BreadcrumbItem tag="a" href="/portfolio">
            Home
          </BreadcrumbItem>
          <BreadcrumbItem tag="a" href={`/projects/${project._id}`}>
            {project.name}
          </BreadcrumbItem>
          <BreadcrumbItem tag="a" href={`/projects/${project._id}/safety`}>
            Safety
          </BreadcrumbItem>
          <BreadcrumbItem tag="a">{`${moment({
            month: currSafetyPeriod.month - 1,
          }).format('MMM')} ${currSafetyPeriod.year}`}</BreadcrumbItem>
        </Breadcrumb>
        <SafetyHeaderContainer>
          <SafetyHeaderGroup>
            <SafetyHeaderBackButton
              onClick={() => setCurrSafetyPeriod({ month: null, year: null })}
            >
              <SafetyHeaderBackIcon />
              <SafetyHeaderBackButtonText>Back</SafetyHeaderBackButtonText>
            </SafetyHeaderBackButton>
          </SafetyHeaderGroup>
        </SafetyHeaderContainer>
        <SafetyPeriodPage
          projectId={project._id}
          month={currSafetyPeriod.month}
          year={currSafetyPeriod.year}
        />
      </SafetyPage>
    );
  }

  return (
    <SafetyPage>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Home
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/projects/${project._id}`}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Safety</BreadcrumbItem>
      </Breadcrumb>
      <SafetyHeaderContainer>
        <SafetyHeaderGroup>
          <SafetyPeriodsIcon />
          <SafetyHeading>Safety Periods</SafetyHeading>
        </SafetyHeaderGroup>
        <SafetyHeaderGroup>
          <Button onClick={toggleAddSafetyPeriodModal}>
            Add Safety Period
          </Button>
        </SafetyHeaderGroup>
      </SafetyHeaderContainer>
      <SafetyContext.Provider
        value={{
          setSafetyPeriods,
          projectId: project._id,
          setCurrSafetyPeriod,
        }}
      >
        <SafetyPeriodCards safetyPeriods={safetyPeriods} />
      </SafetyContext.Provider>
      {safetyPeriods.length === 0 && (
        <SafetyPeriodNoItemContainer>
          <SafetyPeriodNoItemText>
            You currently have no Safety Periods.
          </SafetyPeriodNoItemText>
        </SafetyPeriodNoItemContainer>
      )}
      <Modal
        isOpen={showAddSafetyPeriodModal}
        toggle={toggleAddSafetyPeriodModal}
        backdrop
      >
        <ModalHeader toggle={toggleAddSafetyPeriodModal}>
          <InputHeader>Add a Safety Period</InputHeader>
        </ModalHeader>
        <ModalBody>
          <InputHeader>Select a period:</InputHeader>
          <MonthYearPicker
            selected={monthYearDate}
            onChange={(date) => setMonthYearDate(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
          <FooterContainer>
            <Button onClick={handleAddSafetyPeriod}>
              Add
            </Button>
          </FooterContainer>
        </ModalBody>
      </Modal>
    </SafetyPage>
  );
};

export default withProject(Safety);
