import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  FormGroup,
  ModalFooter,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import Switch from 'react-bootstrap-switch';
import { useAlert } from 'react-alert';
import TagsInput from 'react-tagsinput';
import { InputWrapper } from '../budget/entry/types';
import { useCreateVendor, useUpdateVendor } from '../../hooks';
import { copyArrayReference } from '../../common';

const VendorForm = ({
  vendor,
  toggle = () => {},
  onVendor = () => {},
  open,
}) => {
  const alert = useAlert();
  const [createVendor, { vendor: createVendorData, loading: createLoading }] =
    useCreateVendor();
  const [updateVendor, { vendor: updateVendorData, loading: updateLoading }] =
    useUpdateVendor();
  // const [activeTab, setActiveTab] = useState();

  const [v, setV] = useState();

  // const toggleTab = (tab) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  const onInputChangeEvent = (e) => {
    const { cellKey, value } = e;
    const clone = copyArrayReference(v);
    _.set(clone, cellKey, value);
    setV(clone);
  };

  const save = async () => {
    try {
      if (v._id === '') await createVendor(v);
      else await updateVendor(v._id, v);
    } catch (e) {
      alert.error(e.message);
    }
  };

  const vendorTypes = () => {
    return [
      {
        value: 'consultant',
        label: 'Consultant',
      },
      {
        value: 'supplier',
        label: 'Supplier',
      },
      {
        value: 'contractor',
        label: 'Contractor',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ];
  };

  //   const onBranchUpdate = (branch) => {
  //     const clone = copyArrayReference(v);
  //     clone.branches = updateArrayByKey(branch, clone.branches, 'branchId');
  //     setV(clone);
  //     setActiveTab(branch.branchId);
  //   };

  //   const addBranch = () => {
  //     const clone = copyArrayReference(v);
  //     const branch = {
  //       branchId: v4(),
  //       branchName: `Branch Name ${clone.branches.length + 1}`,
  //       contacts: [],
  //     };
  //     clone.branches.push(branch);
  //     setV(clone);
  //     setActiveTab(branch.branchId);
  //   };

  //   const deleteBranch = (branchId) => {
  //     const clone = copyArrayReference(v);
  //     clone.branches = _.filter(
  //       clone.branches,
  //       (branch) => branch.branchId != branchId,
  //     );
  //     setV(clone);
  //   };

  const handleTags = (tags) => {
    const clone = copyArrayReference(v);
    _.set(clone, 'tags', tags);
    setV(clone);
  };

  useEffect(() => {
    if (!createLoading && !_.isEmpty(createVendorData))
      onVendor(createVendorData);
  }, [createLoading]);

  useEffect(() => {
    if (!updateLoading && !_.isEmpty(updateVendorData))
      onVendor(updateVendorData);
  }, [updateLoading]);

  useEffect(() => {
    setV(vendor);
  }, [open]);

  if (!v) return <div />;

  return (
    <Modal isOpen={open} toggle={toggle} backdrop>
      <ModalHeader toggle={toggle}>New Vendor</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">
                <b className="text-danger">*</b> ABN:
              </Label>
              <InputWrapper
                value={v.abn}
                cellKey="abn"
                type="text"
                onInputChange={onInputChangeEvent}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">
                <b className="text-danger">*</b> Vendor Name:
              </Label>
              <InputWrapper
                value={v.name}
                cellKey="name"
                type="text"
                onInputChange={onInputChangeEvent}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">
                <b className="text-danger">*</b> Representative Name:
              </Label>
              <InputWrapper
                value={v.representative}
                cellKey="representative"
                type="text"
                onInputChange={onInputChangeEvent}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">
                <b className="text-danger">*</b> Vendor Type:
              </Label>
              <Select
                closeMenuOnSelect
                defaultValue={{ label: v.type }}
                options={vendorTypes()}
                onChange={(e) =>
                  onInputChangeEvent({ cellKey: 'type', value: e.value })
                }
              />
            </Col>
          </Row>
        </FormGroup>
      </ModalBody>
      <ModalBody className="border-top">
        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">Primary Office Address:</Label>
              <InputWrapper
                value={v.address.suburb}
                cellKey="address.suburb"
                type="text"
                onInputChange={onInputChangeEvent}
              />
            </Col>
          </Row>
        </FormGroup>
      </ModalBody>
      {/* <ModalBody className="border-top">
                <Row className="mb-2">
                    <Col sm="12">
                        <Button className="float-right" color="projx" onClick={addBranch}>
                            Add Branch
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Nav tabs>
                            {v.branches.map((branch) => (
                                <NavItem key={branch.branchId}>
                                    <div className="d-flex">
                                        <NavLink
                                            className={'d-flex ' + classnames({ active: activeTab === branch.branchId })}>
                                            <div onClick={() => { toggleTab(branch.branchId); }}>
                                                <i className="mdi mdi-worker" />{" "}
                                                {branch.branchName}
                                            </div>
                                            <i className="fas fa-trash text-warning ml-3 mt-1" onClick={() => deleteBranch(branch.branchId)} />
                                        </NavLink>

                                    </div>
                                </NavItem>
                            ))}
                        </Nav>
                        <TabContent className="p-4" activeTab={activeTab}>
                            {v.branches.map((branch) => (
                                <TabPane key={branch.branchId} tabId={branch.branchId}>
                                    <Row>
                                        <Col sm="12">
                                            <VendorBranch branch={branch} onUpdate={onBranchUpdate} />
                                        </Col>
                                    </Row>
                                </TabPane>
                            ))}
                        </TabContent>
                    </Col>
                </Row>
            </ModalBody> */}
      <ModalBody className="border-top">
        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">Tags:</Label>
              <TagsInput
                value={v.tags}
                onChange={(tags) => handleTags(tags)}
                tagProps={{
                  className: 'react-tagsinput-tag bg-info text-white rounded',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="12">
              <Label className="font-12">Archive:</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Switch
                onText="Y"
                offText="N"
                defaultValue={v.archived ? v.archived : false}
                onChange={(e) =>
                  onInputChangeEvent({
                    cellKey: 'archived',
                    value: e.state.value,
                  })
                }
              />
            </Col>
          </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button
          disabled={
            createLoading ||
            v.abn === '' ||
            v.name === '' ||
            v.type ===
              '' /* | v.address.suburb == "" || v.branches.length == 0 */
          }
          color="projx"
          className="float-right"
          onClick={save}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default VendorForm;
