import React from 'react';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import UserList from '../../../components/project/settings/users/UserList';
import { withProject } from '../../../common';

const Users = ({ project }) => {
  return (
    <div>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={'/projects/' + project._id}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Collaborators</BreadcrumbItem>
      </Breadcrumb>
      <Row>
        <Col>
          <UserList project={project} />
        </Col>
      </Row>
    </div>
  );
};

export default withProject(Users);
