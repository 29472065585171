import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useContext } from 'react';
import AuthContext from '../../contexts/auth/auth.context';

import { UPDATE_ACCOUNT_MUTATION } from '../../graphql/queries';

const useUpdateUser = () => {
  const { id } = useContext(AuthContext);
  const [_updateAccount, { data, loading, error }] = useMutation(
    UPDATE_ACCOUNT_MUTATION,
  );

  const updateUser = (user) => {
    return _updateAccount({ variables: _.assign({}, { _id: id }, user) });
  };

  return [updateUser, { data: _.get(data, 'updateAccount'), loading, error }];
};

export { useUpdateUser };
