import React, { useState, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { TextAreaStyled } from './styles/StatusTextArea';

// GraphQL Mutations
const CREATE_STATUS_COMMENT = gql`
  mutation createStatusDocument($projectId: ID!, $input: StatusDocumentInput!) {
    createStatusDocument(projectId: $projectId, input: $input) {
      code
      success
      message
      documents {
        _id
        text
        date
      }
    } 
  }
`;

const StatusTextArea = ({ projectId, setComments }) => {
  const [value, setValue] = useState('');
  const textareaRef = useRef();

  const [createStatusComment, { loading, error, data }] = useMutation(CREATE_STATUS_COMMENT, {
    onCompleted: (data) => {
      setComments(data.createStatusDocument.documents);
    }
  });

  const handleKeyPress = async (e) => {
    // If enter key pressed and we have a defined value, then we create our status comment
    if (e.keyCode == 13 && value !== "") {
      await createStatusComment({
        variables: {
          projectId,
          input: {
            text: value,
            date: new Date().toISOString(),
          }
        }
      });
      textareaRef.current.blur();
      setValue('');
    }
  }

  return (
    <TextAreaStyled 
      ref={textareaRef}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleKeyPress}
      placeholder="Enter your status update"
    />
  );
}

export default StatusTextArea;
