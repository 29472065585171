import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Collapse,
  CardTitle,
  Label,
  Button,
} from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import _ from 'lodash';
import { InputWrapper, DisplayType } from '../Shared';

import { Chat, DocumentList, DocumentUpload } from '../../../..';
import { contractDocumentCategoryOptions } from '../../../../../helpers';

import { GlobalReferenceContext } from '../../../../../contexts';
import { useAddBudgetDocument } from '../../../../../hooks';
import { COMMITMENT_STATUS } from '../../../../../common/dropdowns';

const BudetContractItemSidePanel = ({ item }) => {
  const [isDocumentSectionOpen, setDocumentSection] = useState(true);
  const [isCommentSectionOpen, setCommentSection] = useState(true);
  const [addBudgetDocument, { document, loading }] = useAddBudgetDocument();
  const [isAddDocumentModelOpen, setDocumentModel] = useState(false);

  const toggleDocumentSection = () =>
    setDocumentSection(!isDocumentSectionOpen);
  const toggleCommentSection = () => setCommentSection(!isCommentSectionOpen);
  const toggleAddDocumentModel = () =>
    setDocumentModel(!isAddDocumentModelOpen);

  const onDocumentUpload = ({ document, title, category, notes }) => {
    addBudgetDocument(item._id, document, title, category, notes);
  };

  useEffect(() => {
    // when document upload has finished
    if (!loading && document) {
      item.documents.push(document);
      toggleAddDocumentModel();
    }
  }, [document, loading]);

  return (
    <Row>
      <Col md="12">
        {/* *
         * Document Drop Zone
         */}
        <CardTitle onClick={toggleDocumentSection}>
          <i
            className={
              isDocumentSectionOpen
                ? 'fas fa-chevron-circle-down'
                : 'fas fa-chevron-circle-right'
            }
          />{' '}
          Documents
        </CardTitle>
        <Collapse isOpen={isDocumentSectionOpen}>
          {item.documents.length > 0 && (
            <DocumentList documents={item.documents} />
          )}
          <Button color="projx" onClick={toggleAddDocumentModel}>
            <i className="fas fa-upload" /> Upload
          </Button>
          <DocumentUpload
            loading={loading}
            onDocumentUpload={onDocumentUpload}
            isOpen={isAddDocumentModelOpen}
            toggle={toggleAddDocumentModel}
            categoryOptions={contractDocumentCategoryOptions}
          />
        </Collapse>

        {/**
         * Comments zone
         */}
        <CardTitle onClick={toggleCommentSection}>
          <i
            className={
              isCommentSectionOpen
                ? 'fas fa-chevron-circle-down mt-2'
                : 'fas fa-chevron-circle-right mt-2'
            }
          />{' '}
          Comments
        </CardTitle>
        <Collapse isOpen={isCommentSectionOpen} className="border">
          <Chat id={item._id} />
        </Collapse>
      </Col>
    </Row>
  );
};

const BudgetContractItemPanel = ({
  item,
  locked,
  onInputChange = () => {},
}) => {
  const { normalize } = useContext(GlobalReferenceContext);

  const dropdownSelected = (key, value, label) => {
    onInputChange({
      cellKey: key,
      value: {
        key: label,
        value,
      },
    });
  };

  return (
    <Row>
      <Col md="12">
        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">Description</Label>
              <InputWrapper
                onInputChange={onInputChange}
                width="150px"
                cellKey="description"
                value={item.description}
                type={DisplayType.TextArea}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">Action</Label>
              <InputWrapper
                onInputChange={onInputChange}
                width="150px"
                cellKey="action"
                value={item.action}
                type={DisplayType.TextArea}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm="8">
              <Label className="font-12">Action By</Label>
              <InputWrapper
                onInputChange={onInputChange}
                width="150px"
                cellKey="actionBy"
                value={moment(item.actionBy).format('YYYY-MM-DD')}
                type={DisplayType.DateTime}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">Accounts Payable</Label>
              <Select
                isDisabled={locked}
                width="150px"
                closeMenuOnSelect
                defaultValue={{ label: item.accountsPayable.key }}
                options={normalize('accounts_payable')}
                onChange={(e) =>
                  dropdownSelected('accountsPayable', e.value, e.label)
                }
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">Funding Source</Label>
              <Select
                isDisabled={locked}
                width="150px"
                closeMenuOnSelect
                defaultValue={{ label: item.fundingSource.key }}
                options={normalize('funding_source_category')}
                onChange={(e) =>
                  dropdownSelected('fundingSource', e.value, e.label)
                }
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">Phase</Label>
              <InputWrapper
                onInputChange={onInputChange}
                disabled={locked}
                width="150px"
                cellKey="activity"
                value={item.activity}
                type={DisplayType.Select}
                optionsKey="activities"
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col sm="12">
              <Label className="font-12">Commitment Status</Label>
              <Select
                closeMenuOnSelect
                defaultValue={_.find(
                  COMMITMENT_STATUS,
                  (i) => i.value === item.commitmentStatus,
                )}
                options={COMMITMENT_STATUS}
                onChange={(e) =>
                  onInputChange({
                    cellKey: 'commitmentStatus',
                    value: e.value,
                  })
                }
              />
            </Col>
          </Row>
        </FormGroup>
      </Col>
    </Row>
  );
};

export { BudgetContractItemPanel, BudetContractItemSidePanel };
