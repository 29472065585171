import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  CardFooter,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Badge,
  Form,
  Label,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useForm } from 'react-hook-form';

import { useCreateReferenceItem, useDeleteReferenceItem } from '../../../hooks';
import { updateArrayByKey } from '../../../common';
import { GlobalReferenceContext } from '../../../contexts';

const EMPTY_ITEM = { key: '', value: '', locked: false };

const ReferenceItem = ({ referenceItem }) => {
  const [reference, setReference] = useState(undefined);
  const [refItem, setRefItem] = useState(EMPTY_ITEM);

  const { updateReferenceItem } = useContext(GlobalReferenceContext);

  const [createReferenceItem, { loading }] = useCreateReferenceItem({
    onCompleted: (data) => {
      const copy = { ...reference };
      copy.items = updateArrayByKey(data, copy.items, 'key', true);
      copy.items = order(copy.items);
      setReference(copy);
      updateReferenceItem(copy);
      setRefItem(EMPTY_ITEM);
      reset();
    },
  });

  const [deleteReferenceItem] = useDeleteReferenceItem({
    onCompleted: (data) => removeItem(data),
  });

  const editReferenceItem = (referenceId, item) => {
    setRefItem((prev) => ({ ...prev, key: item.key, value: item.value }));
    setValue('key', item.key);
    setValue('value', item.value);
  };

  const { handleSubmit, register, errors, reset, setValue } = useForm();

  const addItem = () => {
    if (_.some(reference.items, (item) => item.value === refItem.value)) return;
    createReferenceItem(reference._id, refItem);
  };

  const removeItem = (key) => {
    const copy = { ...reference };
    copy.items = _.filter(copy.items, (item) => item.key !== key);
    setReference(copy);
  };

  const onChange = (e, item) => {
    const { value } = e.target;
    setRefItem((prev) => ({ ...prev, [item]: value }));
  };

  const order = (items) =>
    _.orderBy(items, [(ri) => ri.value.toLowerCase()], ['asc']);

  useEffect(() => {
    if (referenceItem) {
      referenceItem.items = order(referenceItem.items);
      setReference(referenceItem);
    }
  }, [referenceItem]);

  if (!reference) return <div />;

  // 5f82ec3ac1e6c01cbcdf4ca4
  // 5f82ec3f4fe1dd00081e6490

  return (
    <Col xs="12" md="4">
      <Card>
        <CardHeader tag="h5">{reference.name}</CardHeader>
        <CardBody>
          <CardSubtitle>{reference.description}</CardSubtitle>
          <div className="chat-box" style={{ height: '300px' }}>
            <PerfectScrollbar>
              <ListGroup>
                {reference.items.map((item, itemIndex) => (
                  <ListGroupItem
                    tag="a"
                    action
                    key={`${item.key}_${itemIndex}`}
                  >
                    <Row>
                      <Col xs="9">
                        <Label className="font-12">
                          <b>({item.key})</b> {item.value}
                        </Label>
                      </Col>
                      <Col xs="3">
                        <i
                          onClick={() => editReferenceItem(reference._id, item)}
                          className="fas fa-edit float-right ml-3 text-info"
                        />
                        {!item.locked ? (
                          <i
                            onClick={() =>
                              deleteReferenceItem(reference._id, item.key)
                            }
                            className="fas fa-trash-alt float-right text-warning"
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </PerfectScrollbar>
          </div>
        </CardBody>
        <CardBody className="border-top">
          <Form onSubmit={handleSubmit(addItem)}>
            <Row className="mb-2">
              <Col sm="6">
                <Label className="font-12">Item Code:</Label>
                <input
                  name="key"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9-_]+$/,
                      message: 'Invalid. No spaces allowed.',
                    },
                  })}
                  placeholder="Enter item code"
                  onChange={(e) => onChange(e, 'key')}
                  className="form-control"
                  type="text"
                />
                <small className="badge badge-danger badge-info text-white float-left">
                  {errors.key && errors.key.message}
                </small>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm="12">
                <Label className="font-12">Item Name:</Label>
                <input
                  name="value"
                  ref={register({ required: true })}
                  placeholder="Enter item value"
                  onChange={(e) => onChange(e, 'value')}
                  className="form-control"
                  type="textarea"
                  rows="2"
                />
                <small className="badge badge-danger badge-info text-white float-left">
                  {errors.value && errors.value.message}
                </small>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Button
                  disabled={loading}
                  type="submit"
                  className="float-right"
                  value="submit"
                  color="projx"
                >
                  <i className="fas fa-save" /> {loading ? 'Saving...' : 'Save'}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Badge color="warning" className="ml-3" pill>
            {reference.items.length}
          </Badge>{' '}
          <b>{reference.name}</b> items
        </CardFooter>
      </Card>
    </Col>
  );
};

export default ReferenceItem;
