import logoLightSVG from '../../assets/images/logo_light.svg';
import logoDarkSVG from '../../assets/images/logo_dark.svg';
import logoSmallSVG from '../../assets/images/monogram_color.svg';
import styled, { css } from 'styled-components';

// NOTE: In order for this styled-component to work properly, the imported SVG files
// must have exactly the same names as above
export const LogoIMG = styled.img`
  ${props => props.src === logoSmallSVG && css`
    width: 32px;
    height: 32px; 
  `}    

  ${props => (props.src === logoLightSVG || props.src === logoDarkSVG) && css`
    width: auto;
    height: 40px; 
  `}
`;

