import React from 'react';
import moment from 'moment';

import { Text, Document, Font, Page, StyleSheet } from '@react-pdf/renderer';
import Header from './Header';

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
  },
  image: {
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: 'column',
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  footer: {
    fontSize: 8,
    fontFamily: 'Lato Bold',
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    color: 'grey',
    padding: 3,
    borderWidth: 1,
    borderColor: 'gray',
    borderStyle: 'dashed',
    '@media orientation: landscape': {
      marginTop: 10,
    },
  },
});

const openSans = require('../../../assets/fonts/Open_Sans/OpenSans-Regular.ttf');
const lato = require('../../../assets/fonts/Lato/Lato-Regular.ttf');
const latoBold = require('../../../assets/fonts/Lato/Lato-Bold.ttf');
const latoItalic = require('../../../assets/fonts/Lato/Lato-Italic.ttf');

Font.register({
  family: 'Open Sans',
  src: openSans,
});
Font.register({
  family: 'Lato',
  src: lato,
});
Font.register({
  family: 'Lato Italic',
  src: latoItalic,
});
Font.register({
  family: 'Lato Bold',
  src: latoBold,
});

const Report = (props) => {
  const reportGenerationDateTime = Date.now();
  const { orientation, size, template, children, name } = props;

  return (
    <Page orientation={orientation} size={size} style={styles.page}>
      <Header header={template.header} name={name} />
      {children}
      <Text style={styles.footer}>
        {name} report generated by Projx on{' '}
        {moment(reportGenerationDateTime).format('Do MMM YYYY')} at{' '}
        {moment(reportGenerationDateTime).format('hh:mm')}
      </Text>
    </Page>
  );
};

const Main = (props) => {
  const { children } = props;

  return (
    <Document>
      <Report orientation="landscape" size="A4" {...props}>
        {children}
      </Report>
    </Document>
  );
};

export default Main;
