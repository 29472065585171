import React, { useState } from 'react';
import BudgetConfigContext from './budgetConfig.context';

const BudgetConfigProvider = ({ children }) => {
  const [toggleStates, setToggleStates] = useState({
    decimal: true,
  });
  const handleChangeConfig = ({ name, state }) => {
    setToggleStates({ ...toggleStates, [name]: state });
  };

  return (
    <BudgetConfigContext.Provider
      value={{
        config: toggleStates,
        handleChangeConfig,
      }}
    >
      {children}
    </BudgetConfigContext.Provider>
  );
};

export default BudgetConfigProvider;
