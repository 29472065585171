import React from 'react';

import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    paddingBottom: 5,
    flexDirection: 'row',

    alignItems: 'stretch',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    textTransform: 'uppercase',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    paddingLeft: '50%',
  },
  name: {
    fontSize: 18,
    fontFamily: 'Lato Bold',
  },
  subtitle: {
    fontSize: 10,
    justifySelf: 'flex-end',
    fontFamily: 'Lato',
  },
  link: {
    fontFamily: 'Lato',
    fontSize: 10,
    color: 'black',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  logo: {
    width: 45,
    height: 45,
  },
  formDetailsLeft: {
    flexDirection: 'column',
    width: '20%',
    padding: 2,
    marginTop: 10,
  },
  formDetailsRight: {
    flex: 1,
    padding: 2,
    marginTop: 10,
  },
  detailsContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  detailsLabel: {
    fontSize: 10,
    fontFamily: 'Lato Bold',
  },
  detailsText: {
    fontSize: 10,
    fontFamily: 'Lato',
  },
  marginize: {
    marginTop: 3,
  },
});

export default (props) => {
  const { header, name } = props;

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.detailColumn}>
          <Text style={styles.name}>{name}</Text>
        </View>
        <View style={styles.linkColumn}>
          <Image
            style={styles.logo}
            src={`${
              header.organisationLogo
            }?noCache=${Math.random().toString()}`}
            source={{
              header: {
                'Access-Control-Allow-Origin': '*',
              },
            }}
          />
        </View>
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.formDetailsLeft}>
          <Text style={styles.detailsLabel}>Project Code:</Text>
          <Text style={styles.detailsLabel}>Project Name:</Text>
          <Text style={styles.detailsLabel}>Project Address:</Text>
          <Text style={styles.detailsLabel}>Client:</Text>
          <Text style={[styles.detailsLabel, styles.marginize]}>
            Cost Code:
          </Text>
          <Text style={styles.detailsLabel}>Vendor:</Text>
        </View>
        <View style={styles.formDetailsRight}>
          <Text style={styles.detailsText}>{header.projectCode}</Text>
          <Text style={styles.detailsText}>{header.projectName}</Text>
          <Text style={styles.detailsText}>{header.projectAddress}</Text>
          <Text style={styles.detailsText}>{header.clientName}</Text>
          <Text style={[styles.detailsText, styles.marginize]}>
            {header.costCode}
          </Text>
          <Text style={styles.detailsText}>{header.vendorName}</Text>
        </View>
      </View>
    </View>
  );
};
