import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PermissionTable from './PermissionTable';

const RoleView = ({ role }) => {
  const [modal, setModal] = useState(false);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const permissionsHash = {};
    role.permissions.forEach((permission) => {
      const [group, name, action] = permission.name.split('.');
      if (permissionsHash[group]) {
        if (permissionsHash[group][name]) {
          permissionsHash[group][name].push(action);
        } else {
          permissionsHash[group][name] = [action];
        }
      } else {
        permissionsHash[group] = {
          [name]: [action],
        };
      }
    });

    setPermissions(permissionsHash);
  }, [role.permissions]);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div>
      <Button onClick={toggle} color="projx">
        <i className="fa fa-search" />
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {_upperCaseFirstLetter(role.name)}
        </ModalHeader>
        <ModalBody>
          <PermissionTable permissions={permissions} />
        </ModalBody>
      </Modal>
    </div>
  );
};

const _upperCaseFirstLetter = (item) =>
  item.charAt(0).toUpperCase() + item.slice(1);

export default RoleView;
