import React, { useEffect, useState, useContext } from 'react';
import {
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import classnames from 'classnames';
import _ from 'lodash';

import { v4 } from 'uuid';
import Paginator from 'react-hooks-paginator';
import Skeleton from 'react-loading-skeleton';
import { useQueryVendors } from '../../hooks';

import VendorForm from './VendorForm';
import { updateArrayByKey, insertIntoArray } from '../../common';
import VendorCard from './VendorCard';
import GlobalSearch from '../global/Search';
import { OrganisationContext } from '../../contexts';

const Vendors = () => {
  const { currentOrganisation } = useContext(OrganisationContext);
  const [getVendors, { loading }] = useQueryVendors({
    onCompleted: (data) => {
      setVendors(data);
    },
  });

  const [vendors, setVendors] = useState([]);
  const [t, setT] = useState();
  const [ve, setVendor] = useState();
  const [open, setOpen] = useState(false);

  const pageLimit = 9;

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [searching, setSearching] = useState(false);

  const toggleForm = () => {
    setOpen(!open);
  };

  const selectVendor = (vendor) => {
    setVendor(vendor);
    toggleForm();
  };

  const onVendor = (vendor) => {
    if (_.some(vendors, (i) => i._id === vendor._id)) {
      setVendors(updateArrayByKey(vendor, vendors));
    } else {
      setVendors(insertIntoArray(vendor, vendors));
    }
    toggleForm();
    setVendor(undefined);
  };

  const newVendor = () => {
    setVendor({
      _id: '',
      address: {
        street: '',
        suburb: '',
        postcode: '',
        state: '',
      },
      representative: '',
      branches: [],
      abn: '',
      name: '',
      type: '',
      tags: [],
    });
    setOpen(true);
  };

  const onSearchResults = (results) => {
    if (!results) {
      setVendors(_.clone(vendors));
      setSearching(false);
    } else {
      setSearching(true);
      setCurrentData(results.slice(offset, offset + pageLimit));
    }
  };

  useEffect(() => setT(v4()), []);

  useEffect(() => {
    if (_.has(currentOrganisation, '_id')) {
      getVendors();
    }
  }, [currentOrganisation]);

  useEffect(() => {
    if (vendors) {
      setCurrentData(vendors.slice(offset, offset + pageLimit));
    }
  }, [offset, vendors]);

  return (
    <div>
      <Nav pills>
        <NavItem key={t}>
          <NavLink className={classnames({ active: true })}>
            <i className="mdi mdi-worker" /> <b>{vendors.length}</b> Vendors
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className="p-4" activeTab={t}>
        <Row className="mb-2">
          <Col sm="4">
            {vendors.length > 0 ? (
              <GlobalSearch
                data={_.clone(vendors)}
                keys={['representative', 'type', 'abn', 'name', 'tags']}
                onResults={onSearchResults}
              />
            ) : (
              ''
            )}
          </Col>
          <Col sm="8">
            <Button
              disabled={loading}
              className="float-right mb-3"
              color="projx"
              onClick={newVendor}
            >
              <i className="fas fa-plus mr-1" /> Create Vendor
            </Button>
          </Col>
        </Row>
        <Row className="border-top pt-3">
          <Col sm="12">
            <TabPane key={t} tabId={t}>
              {loading ? (
                <Row>
                  <Col xs="4">
                    <Card>
                      <CardHeader>
                        <Skeleton />
                      </CardHeader>
                      <CardBody>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </CardBody>
                      <CardFooter>
                        <Skeleton />
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col xs="4">
                    <Card>
                      <CardHeader>
                        <Skeleton />
                      </CardHeader>
                      <CardBody>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </CardBody>
                      <CardFooter>
                        <Skeleton />
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col xs="4">
                    <Card>
                      <CardHeader>
                        <Skeleton />
                      </CardHeader>
                      <CardBody>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </CardBody>
                      <CardFooter>
                        <Skeleton />
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((vendor) => (
                        <VendorCard
                          key={vendor._id}
                          vendor={vendor}
                          onSelect={selectVendor}
                        />
                      ))
                    ) : (
                      <Col sm="12">
                        <div className="d-flex mt-3 p-4 flex-column align-items-center justify-content-center">
                          <i className="ti-agenda display-5" />
                          <h4 className="mt-2">No vendors to display ...</h4>
                        </div>
                      </Col>
                    )}
                  </Row>
                  {!searching ? (
                    <Paginator
                      totalRecords={vendors ? vendors.length : 0}
                      pageLimit={pageLimit}
                      pageNeighbours={1}
                      setOffset={setOffset}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : (
                    ''
                  )}
                </>
              )}
            </TabPane>
          </Col>
        </Row>
      </TabContent>
      {ve ? (
        <VendorForm
          open={open}
          toggle={toggleForm}
          onVendor={onVendor}
          vendor={ve}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default Vendors;
