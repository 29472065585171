import React from 'react';
import {
  Text,
  Document,
  Page,
  StyleSheet,
  Image,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';

import { Section, Table, Amount } from '../../../pdf/common';
import { GlobalStyles } from '../common';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    textTransform: 'uppercase',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    paddingLeft: '30%',
  },
  name: {
    fontSize: 18,
    fontFamily: 'Lato Bold',
  },
  subtitle: {
    fontSize: 10,
    justifySelf: 'flex-end',
    fontFamily: 'Lato',
  },
  link: {
    fontFamily: 'Lato',
    fontSize: 10,
    color: 'black',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  logo: {
    width: 60,
    height: 60,
  },
  formDetailsLeft: {
    flexDirection: 'column',
    flex: 1,
    padding: 2,
    marginTop: 5,
    // border: 1,
    // borderStyle: "dotted",
    // borderColor: "black"
  },
  formDetailsRight: {
    flex: 1,
    flexDirection: 'column',
    width: '60%',
    padding: 2,
    marginTop: 5,
    // border: 1,
    // borderStyle: "dotted",
    // borderColor: "black"
  },
  detailsContainer: {
    // flex: 1,
    flexDirection: 'row',

    // border: 1,
    // borderStyle: "dotted",
    // borderColor: "black"
  },
  theRestDetailsContainer: {
    flexDirection: 'row',
    height: 100,
  },
  detailsLabel: {
    fontSize: 9,
    fontFamily: 'Lato Bold',
  },
  detailsText: {
    fontSize: 9,
    fontFamily: 'Lato',
  },
  marginize: {
    marginTop: 3,
  },
  main: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  sectionText: {
    marginTop: 10,
    padding: 2,
    fontSize: 11,
    fontFamily: 'Lato Bold',
    backgroundColor: '#f0f0f0',
  },
  sectionFooter: {
    fontSize: 8,
    fontFamily: 'Lato Bold',
    color: 'grey',
    paddingTop: 3,
    marginTop: 3,
  },
  signature: {
    border: 1,
    borderStyle: 'dotted',
    borderColor: 'black',
    margin: 5,
    width: '200px',
    height: '50px',
  },
  longLabel: {
    fontSize: 9,
    fontFamily: 'Lato Bold',
  },
  footer: {
    fontSize: 8,
    fontFamily: 'Lato Bold',
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    color: 'grey',
    padding: 3,
    borderWidth: 1,
    borderColor: 'gray',
    borderStyle: 'dashed',
    '@media orientation: landscape': {
      marginTop: 10,
    },
  },
  gap: {
    height: '300px',
  },
});

const Header = ({ name, details }) => {
  return (
    <View style={{ margin: 2 }}>
      <View style={styles.container}>
        <View style={styles.detailColumn}>
          <Text style={styles.name}>{name}</Text>
        </View>
        <View style={styles.linkColumn}>
          <Image
            style={styles.logo}
            src={`${
              details.organisationLogo
            }?noCache=${Math.random().toString()}`}
            source={{
              header: {
                'Access-Control-Allow-Origin': '*',
              },
            }}
          />
        </View>
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.formDetailsLeft}>
          <Text style={styles.detailsLabel}>Project Code:</Text>
          <Text style={styles.detailsLabel}>Project Name:</Text>
          <Text style={styles.detailsLabel}>Project Address:</Text>
          <Text style={styles.detailsLabel}>Client:</Text>
          <Text style={[styles.detailsLabel, styles.marginize]}>
            Budget Code:
          </Text>
          <Text style={styles.detailsLabel}>Vendor:</Text>
          <Text style={styles.detailsLabel}>Issue Date:</Text>
        </View>
        <View style={styles.formDetailsRight}>
          <Text style={styles.detailsText}>
            {details.projectCode ? details.projectCode : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.projectName ? details.projectName : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.projectAddress ? details.projectAddress : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.clientName ? details.clientName : '-'}
          </Text>
          <Text style={[styles.detailsText, styles.marginize]}>
            {details.costCode ? details.costCode : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.vendorName ? details.vendorName : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.issueDate ? details.issueDate : '-'}
          </Text>
        </View>
      </View>
    </View>
  );
};

const ContractSumAdjustment = ({ template, project }) => {
  const {
    vendorName,
    costCode,
    issueDate,
    adjustments,
    contracted,
    adjustmentsTotal,
    userName,
    userTitle,
    userCompany,
  } = template;

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={GlobalStyles.page}>
        <Header
          name="Contract Sum Adjustment"
          details={{
            organisationLogo: project.organisation.logo,
            projectCode: project.code,
            projectName: project.name,
            projectAddress: project.address,
            clientName: project.client.name,
            costCode,
            vendorName,
            issueDate,
          }}
        />

        <Section text="Approved Cost Adjustments" />
        <Table table={adjustments} />

        <Section text="Revised Contract Sum Adjustments" />
        <View style={styles.detailsContainer}>
          <View style={styles.formDetailsLeft}>
            <Text style={styles.detailsLabel}>
              Original Vendor Contract Sum:
            </Text>
            <Text style={styles.detailsLabel}>Adjustments Total:</Text>
            <Text style={styles.detailsLabel}>Adjusted Contract Sum:</Text>
          </View>
          <View style={styles.formDetailsRight}>
            <Amount value={contracted} />
            <Amount value={adjustmentsTotal} />
            <Amount value={contracted + adjustmentsTotal} />
          </View>
        </View>

        <Section text="Prepared By" />
        <View style={styles.theRestDetailsContainer}>
          <View style={styles.formDetailsRight}>
            <Text style={styles.longLabel}>{userName}</Text>
            <Text style={styles.longLabel}>
              {userCompany} ({userTitle})
            </Text>
            <Text style={styles.sectionFooter}>
              This Contract Sum Adjustment does not constitute a valuation of
              the works executed but rather the price submitted for the cost
              adjustment is approved and will form part of the Adjusted Contract
              Sum. The Contractor is required to claim payment for cost
              adjustments under a Payment Claim under the Contract.
            </Text>
          </View>
        </View>

        <Text style={styles.footer}>
          Contract Sum Adjustment Form generated by Projx on{' '}
          {moment(Date.now()).format('Do MMM YYYY')} at{' '}
          {moment(Date.now()).format('hh:mm')}
        </Text>
      </Page>
    </Document>
  );
};

export default ContractSumAdjustment;
