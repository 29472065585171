import React, { } from 'react';
import _ from 'lodash';
import { Route, Switch } from 'react-router-dom';
import CashflowTableCustom from './CashflowTableV2';

const Cashflow = ({ project }) => {
  return (
    // <Switch>
    //   {_.map(project.costCodes, (code) => (
    //     <Route
    //       path={`/projects/${project._id}/cashflows/${code._id}`}
    //       render={(props) => <CashflowTableCustom {...props} project={project} code={code} />}
    //     />
    //   ))}
    // </Switch>
    <CashflowTableCustom project={project} />
  );
};

export default Cashflow;
