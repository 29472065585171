import React from 'react';
import { ButtonStyles } from './styles/Button';

/**
 * This Button component is the generalized Button component across our entire
 * projx web application
 * 
 * @param {JSX} children The children of our button
 * @param {Function} onClick The onClick event handler passed down
 * @param {boolean} disabled Whether our button is disabled or not 
 * @param {boolean} outline Invert the colors between text and background color to create an 'outline' styled button 
 * @returns {JSX} Our custom Button element
 */
const Button = ({ children, onClick, disabled, outline }) => {
  return (
    <ButtonStyles onClick={onClick} disabled={disabled} outline={outline}>
      {children}
    </ButtonStyles>
  );
}

export default Button;
