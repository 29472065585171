import styled, { css } from 'styled-components';

export const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 8px;
  padding: 4px 8px;
  margin: 2px;
  letter-spacing: 1px;
  font-size: ${(props) => props.fontSize || '12px'};

  ${(props) => props.index % 7 === 0 && css`
    color: #1B1B1B;
    background-color: #F2607E;
  `}

  ${(props) => props.index % 7 === 1 && css`
    color: #1B1B1B;
    background-color: #007BF2;
  `}

  ${(props) => props.index % 7 === 2 && css`
    color: #1B1B1B;
    background-color: #FFD145;
  `}

  ${(props) => props.index % 7 === 3 && css`
    color: #F9F9F9;
    background-color: #C21646;
  `}

  ${(props) => props.index % 7 === 4 && css`
    color: #F9F9F9;
    background-color: #0D49C1;
  `}

  ${(props) => props.index % 7 === 5 && css`
    color: #1B1B1B;
    background-color: #FD992C;
  `}

  ${(props) => props.index % 7 === 6 && css`
    color: #1B1B1B;
    background-color: #969696;
  `}
`;