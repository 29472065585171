// Library Imports
import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { gql, useQuery, useMutation } from '@apollo/client';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
// Local Imports
import { Button, Spinner } from '../../../components';
import { phaseSelectOptions, statusSelectOptions } from '../constants';
import {
  InputContainer,
  InputHeader,
  FooterContainer,
  DatePickerStyled,
  TextAreaStyled,
  Divider,
} from './styles/Modal';

// GraphQL Queries
const GET_MILESTONE_DOCUMENT = gql`
  query getMilestoneDocument($projectId: ID!, $documentId: ID!) {
    getMilestoneDocument(projectId: $projectId, documentId: $documentId) {
      _id
      phase
      description
      scheduleDate
      revisedScheduleDate
      targetDate
      actualDate
      status
    } 
  }
`;

// GraphQL Mutations
const UPDATE_MILESTONE_DOCUMENT = gql`
  mutation updateMilestoneDocument($projectId: ID!, $documentId: ID!, $input: MilestoneDocumentInput!) {
    updateMilestoneDocument(projectId: $projectId, documentId: $documentId, input: $input) {
      code
      success
      message
      documents {
        _id
        phase
        description
        scheduleDate
        revisedScheduleDate
        targetDate
        actualDate
        status
      }
    } 
  }
`;

const DELETE_MILESTONE_DOCUMENT = gql`
  mutation deleteMilestoneDocument($projectId: ID!, $documentId: ID!) {
    deleteMilestoneDocument(projectId: $projectId, documentId: $documentId) {
      code
      success
      message
      documents {
        _id
        phase
        description
        scheduleDate
        revisedScheduleDate
        targetDate
        actualDate
        status
      }
    } 
  }
`;

/**
 * This component is the modal for when we want to edit a milestone line item
 * @returns Our Modal for editing a line item
 */
const EditLineItemModal = ({
  showEditMilestoneModal,
  toggleEditLineItemModal,
  documentId,
  projectId,
  setTableData,
}) => {
  // The states for our milestone line item input
  const [phase, setPhase] = useState('');
  const [description, setDescription] = useState('');
  const [scheduleDate, setScheduleDate] = useState(null);
  const [revisedScheduleDate, setRevisedScheduleDate] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  const [actualDate, setActualDate] = useState(null);
  const [status, setStatus] = useState('');

  // For showing/hiding our modal for confirming deletion of a milestone
  const [showDeleteMilestoneEventModal, setShowDeleteMilestoneEventModal] = useState(false);

  // GraphQL Queries
  const {
    loading: loadingGetMilestoneDocument,
    error: errorGetMilestoneDocument,
    data: dataGetMilestoneDocument,
  } = useQuery(GET_MILESTONE_DOCUMENT, {
    variables: {
      projectId,
      documentId,
    },
    onCompleted: (data) => {
      setPhase(data.getMilestoneDocument.phase)
      setDescription(data.getMilestoneDocument.description)
      setScheduleDate(data.getMilestoneDocument.scheduleDate ? new Date(data.getMilestoneDocument.scheduleDate): null);
      setRevisedScheduleDate(data.getMilestoneDocument.revisedScheduleDate ? new Date(data.getMilestoneDocument.revisedScheduleDate): null);
      setTargetDate(data.getMilestoneDocument.targetDate ? new Date(data.getMilestoneDocument.targetDate): null);
      setActualDate(data.getMilestoneDocument.actualDate ? new Date(data.getMilestoneDocument.actualDate): null);
      setStatus(data.getMilestoneDocument.status);
    },  
  });

  // GraphQL Mutations
  const [updateMilestoneDocument, {
    loading: loadingUpdateMilestoneDoc,
    error: errorUpdateMilestoneDoc,
    data: dataUpdateMilestoneDoc
  }] = useMutation(UPDATE_MILESTONE_DOCUMENT, {
    onCompleted: (data) => {
      setTableData(data.updateMilestoneDocument.documents);
    }
  });
  
  const [deleteMilestoneDocument, {
    loading: loadingDeleteMilestoneDoc,
    error: errorDeleteMilestoneDoc,
    data: dataDeleteMilestoneDoc
  }] = useMutation(DELETE_MILESTONE_DOCUMENT, {
    onCompleted: (data) => {
      setTableData(data.deleteMilestoneDocument.documents);
    }
  });

  const toggleConfirmDeleteModal = (e) => {
    setShowDeleteMilestoneEventModal(() => !showDeleteMilestoneEventModal);
  }

  if (loadingGetMilestoneDocument) {
    return (
      <Modal isOpen={showEditMilestoneModal} toggle={toggleEditLineItemModal} backdrop>
        <ModalHeader toggle={toggleEditLineItemModal}>
          <InputHeader>Edit Milestone Item</InputHeader>
        </ModalHeader>
        <ModalBody>
          <Spinner />
        </ModalBody>
      </Modal>
    );
  }

  const handleUpdateButtonClick = async (e) => {
    await updateMilestoneDocument({
      variables: {
        projectId, 
        documentId,
        input: {
          phase,
          description,
          scheduleDate,
          revisedScheduleDate,
          targetDate,
          actualDate,
          status,
        }
      }
    });
    toggleEditLineItemModal({});
  }

  const handleConfirmDeleteButtonClick = async (e) => {
    setShowDeleteMilestoneEventModal(false);
    await deleteMilestoneDocument({
      variables: {
        projectId,
        documentId,
      }
    });
    toggleEditLineItemModal({});
  }

  const updateButtonDisabled = description === '' || scheduleDate === null;

  return (
    <>
      {/* This is our primary modal for editing a milestone line item */}
      <Modal isOpen={showEditMilestoneModal} toggle={toggleEditLineItemModal} backdrop>
        <ModalHeader toggle={toggleEditLineItemModal}>
          <InputHeader>Edit Milestone</InputHeader>
        </ModalHeader>
        <ModalBody>
          <InputContainer>
            <InputHeader>{`Phase:`}</InputHeader>
            <Select
              options={phaseSelectOptions}
              value={_.find(phaseSelectOptions, (item) => {
                return item.value === phase;
              })}
              defaultValue={{ value: 'CONSTRUCTION', label: 'Construction' }}
              onChange={(e) => setPhase(e.value)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`* Description:`}</InputHeader>
            <TextAreaStyled
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe your milestone"
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`* Select a Schedule Date (DD-MM-YYYY):`}</InputHeader>
            <DatePickerStyled
              dateFormat="dd/MM/yyyy"
              selected={scheduleDate}
              onChange={(date) => setScheduleDate(date)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`Select a Revised Schedule Date (DD-MM-YYYY):`}</InputHeader>
            <DatePickerStyled
              dateFormat="dd/MM/yyyy"
              selected={revisedScheduleDate}
              onChange={(date) => setRevisedScheduleDate(date)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`Select a Target Date (DD-MM-YYYY):`}</InputHeader>
            <DatePickerStyled
              dateFormat="dd/MM/yyyy"
              selected={targetDate}
              onChange={(date) => setTargetDate(date)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`Select an Actual Date (DD-MM-YYYY):`}</InputHeader>
            <DatePickerStyled
              dateFormat="dd/MM/yyyy"
              selected={actualDate}
              onChange={(date) => setActualDate(date)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`Status:`}</InputHeader>
            <Select
              options={statusSelectOptions}
              value={_.find(statusSelectOptions, (item) => {
                return item.value === status;
              })}
              defaultValue={{ value: 'ON_TRACK', label: 'On Track' }}
              onChange={(e) => setStatus(e.value)}
            />
          </InputContainer>
          <FooterContainer>
            <Button onClick={toggleConfirmDeleteModal} outline>{`Delete`}</Button>
            <Divider />
            <Button
              onClick={handleUpdateButtonClick}
              disabled={updateButtonDisabled}
            >
              {`Update`}
            </Button>
          </FooterContainer>
        </ModalBody>
      </Modal>
      {/*
        This modal which pops up on top of the primary modal for editing a line item and is for for
        confirming our deletion of the module line item
      */}
      <Modal isOpen={showDeleteMilestoneEventModal} toggle={toggleConfirmDeleteModal} backdrop>
        <ModalHeader toggle={toggleConfirmDeleteModal}>
          <InputHeader>Are you sure you want to delete this item?</InputHeader>
        </ModalHeader>
        <ModalBody>
          You will be unable to recover the data once it is deleted, are you sure you want to continue ?
          <FooterContainer>
            <Button onClick={toggleConfirmDeleteModal} outline>Cancel</Button>
            <Divider />
            <Button
              onClick={handleConfirmDeleteButtonClick}
            >
              Confirm
            </Button>
          </FooterContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditLineItemModal;
