import React, { useState, useContext } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Collapse,
  CardTitle,
  Tooltip,
} from 'reactstrap';
import moment from 'moment';
import Switch from 'react-bootstrap-switch';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress'; // Not using react strap as buggy, it renders nothing...
import { useAlert } from 'react-alert';
import { InputWrapper, DisplayType } from '../Shared';
import { useClaim } from '../../../../../hooks';
import { currencify, extractGraphqlError } from '../../../../../common';
import { processClaimDocumentCategoryOptions } from '../../../../../helpers';
import { DocumentForm } from '../../../..';
import { PaymentSchedule } from '../../../../forms';
import FormViewer from '../../../../forms/FormViewer';
import UserContext from '../../../../../contexts/user/user.context';

const INITIAL_DOCUMENT_FORM_STATE = {
  document: null,
  title: '',
  notes: '',
  category: '',
};

// Determine of user has modified any of the document form
const _hasFilledDocumentForm = (documentFormState) => {
  return (
    JSON.stringify(documentFormState) !==
    JSON.stringify(INITIAL_DOCUMENT_FORM_STATE)
  );
};

const ProcessClaims = ({
  onCompleted = () => {},
  toggle = () => {},
  open,
  focotr,
  totalAmountToProcess,
  allTotals,
  project,
}) => {
  const { user } = useContext(UserContext);

  const [claimId, setClaim] = useState('');
  const [date, setDate] = useState('');
  const [gstType, setGstType] = useState('percentage');
  const [gstValue, setGstValue] = useState(10);
  const [claimedAmount, setClaimedAmount] = useState('');
  const [claimedAmountComments, setClaimedAmountComments] = useState('');
  const [qsAmount, setQsAmount] = useState(undefined);
  const [comments, setComments] = useState('');

  const [generated, setGenerated] = useState(undefined);
  const [forms, setForms] = useState({ paymentSchedule: false });

  // Document upload
  const [documentFormState, setDocumentFormState] = useState(
    INITIAL_DOCUMENT_FORM_STATE,
  );
  const [isDocumentSectionOpen, setDocumentSection] = useState(false);

  const toggleDocumentSection = () =>
    setDocumentSection(!isDocumentSectionOpen);
  const clearDocumentFormState = () =>
    setDocumentFormState(INITIAL_DOCUMENT_FORM_STATE);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleToolTip = () => setTooltipOpen(!tooltipOpen);

  const [defaultTax, setDefaultTax] = useState(true);
  const [qsRecommendation, setQsRecommendation] = useState(false);

  const alert = useAlert();

  const { processClaim, processLoading, Actions } = useClaim({
    onCompleted: ({ action, data }) => {
      if (action === Actions.ProcessClaim) {
        onCompleted(data);
        clear();
        toggle();
      }
    },
  });

  const clear = () => {
    setClaim('');
    setDate('');
    setClaimedAmount('');
    setQsAmount(undefined);
    setClaimedAmountComments('');
    setGstType('percentage');
    setGstValue(10);
    setComments('');
  };

  const buildPaymentSchedule = (withDateFormat = false) => {
    return {
      projectName: focotr.project.name,
      contractor: '',
      issueDate: withDateFormat ? new Date(date) : date,
      originalContractSum: allTotals.totals.contracted,
      variationsApprovedToDate: allTotals.varTotals.adjustments,
      provisionalSumAdjustment: allTotals.psTotals.adjustments,
      adjustedContractSum:
        allTotals.totals.contracted + allTotals.totals.adjustments,
      contractorPaymentReference: claimId,
      contractorPaymentClaimDate: withDateFormat ? new Date(date) : date,
      totalAmountOfPaymentClaim: claimedAmount,
      contractWorks: allTotals.contTotals.expenditure.current,
      variationWorks: allTotals.varTotals.expenditure.current,
      provisionalSums: allTotals.psTotals.expenditure.current,
      workExecutedSubTotal: allTotals.totals.expenditure.current,
      previouslyAssessed: allTotals.totals.expenditure.previous,
      thisClaim: allTotals.totals.expenditure.thisClaim,
      gst:
        gstType === 'percentage' ? claimedAmount * (gstValue / 100) : gstValue,
      includingGst: calculateGstValue(),
      reasonIfAmountsDiffer: claimedAmountComments,
      comments: comments,
    };
  };

  const process = async () => {
    const paymentSchedule = buildPaymentSchedule(true);

    try {
      await processClaim(focotr._id, {
        claimId,
        date: new Date(date),
        gstType,
        gstValue,
        claimedAmount,
        claimedAmountComments,
        qsRecommendation,
        qsAmount,
        comments,
        documentInput: _hasFilledDocumentForm(documentFormState)
          ? documentFormState
          : undefined,
        paymentSchedule,
      });
      // If we get no error, then claim has been successfully processed !
      toast.success('Claim successfully processed.');
    } catch (e) {
      alert.error(extractGraphqlError(e));
    }

    // Clear document form after processing
    clearDocumentFormState();
  };

  const changeDefaultTax = (val) => {
    if (val) {
      setGstValue(10);
      setGstType('percentage');
    } else {
      setGstValue('');
      setGstType('amount');
    }
    setDefaultTax(val);
  };

  const viewPaymentSchedule = () => {
    setGenerated(
      <PaymentSchedule
        template={{
          ...buildPaymentSchedule(),
          costCode: `${focotr.costCode.code} - ${focotr.costCode.name}`,
          vendorName: focotr.vendor.name,
          userName: user.name,
          userTitle: user.title,
          userCompany: user.company,
        }}
        project={project}
      />,
    );
    toggleForm('paymentSchedule');
  };

  const toggleForm = (type) => {
    setForms((prev) => {
      const isVisible = !prev[type];
      if (!isVisible) setGenerated(undefined);
      return {
        ...prev,
        [type]: isVisible,
      };
    });
  };

  const changeQsRecommendation = (val) => {
    setQsAmount(undefined);
    setQsRecommendation(val);
  };

  const changeClaimedAmount = (val) => {
    setClaimedAmount(val);
  };

  const calculateGstValue = () => {
    if (gstType === 'percentage' && gstValue !== '' && claimedAmount !== '')
      return claimedAmount + claimedAmount * (gstValue / 100);
    if (gstType === 'amount' && gstValue !== '' && claimedAmount !== '')
      return claimedAmount + gstValue;
    return claimedAmount;
  };

  const includingGstCalculation = () => {
    if (gstType === 'percentage' && gstValue !== '' && claimedAmount !== '')
      return currencify(
        claimedAmount + claimedAmount * (gstValue / 100),
        true,
        2,
      );
    if (gstType === 'amount' && gstValue !== '' && claimedAmount !== '')
      return currencify(claimedAmount + gstValue, true, 2);

    return '$-';
  };

  const disable = () =>
    claimId === '' ||
    date === '' ||
    gstType === '' ||
    gstValue === '' ||
    claimedAmount === '' ||
    ((!defaultTax || defaultTax) && gstValue === '') ||
    (claimedAmount !== totalAmountToProcess && claimedAmountComments === '') ||
    (qsRecommendation && !qsAmount) ||
    processLoading;

  const invalid = () =>
    claimId === '' ||
    date === '' ||
    gstType === '' ||
    gstValue === '' ||
    claimedAmount === '' ||
    ((!defaultTax || defaultTax) && gstValue === '') ||
    (claimedAmount !== totalAmountToProcess && claimedAmountComments === '') ||
    (qsRecommendation && !qsAmount);

  // useEffect(() => setClaimedAmount(totalAmountToProcess), [totalAmountToProcess]);

  return (
    <>
      <Modal isOpen={open} toggle={toggle} backdrop>
        <ModalHeader toggle={toggle}>Process Claims</ModalHeader>
        <ModalBody className="border-bottom mb-2">
          <span>
            Total of <code>{currencify(totalAmountToProcess, true, 2)}</code> to
            claim
          </span>
        </ModalBody>
        <ModalBody>
          <Row>
            <Col sm="5">
              <FormGroup>
                <Label className="font-12">Invoice No#:</Label>
                <InputWrapper
                  onInputChange={(data) => setClaim(data.value)}
                  width="50px"
                  cellKey="claimId"
                  value={claimId}
                  type={DisplayType.Text}
                />
              </FormGroup>
            </Col>
            <Col sm="5">
              <FormGroup>
                <Label className="font-12">Invoice Date:</Label>
                <InputWrapper
                  onInputChange={(data) => setDate(data.value)}
                  width="50px"
                  cellKey="date"
                  value={moment(date).format('YYYY-MM-DD')}
                  type={DisplayType.DateTime}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm="5">
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="font-12">Default Tax:</Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Switch
                      onColor="danger"
                      onText="Y"
                      offText="N"
                      defaultValue={defaultTax}
                      onChange={(e) => changeDefaultTax(e.state.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col sm="7">
              <FormGroup>
                {defaultTax ? (
                  <>
                    <Row>
                      <Col sm="12">
                        <Label className="font-12">GST (%):</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        <InputWrapper
                          disabled
                          onInputChange={(data) => setGstValue(data.value)}
                          width="50px"
                          cellKey="gstValue"
                          value={gstValue}
                          type={DisplayType.Number}
                        />
                      </Col>
                      <Col sm="8" className="mt-2">
                        <h4>
                          <code>{includingGstCalculation()}</code>
                        </h4>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col sm="12">
                        <Label className="font-12">
                          Enter an amount in dollars & cents:
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <InputWrapper
                          onInputChange={(data) => setGstValue(data.value)}
                          width="50px"
                          cellKey="gstValue"
                          value={gstValue}
                          type={DisplayType.Currency}
                          precision={2}
                          allowNegative
                        />
                      </Col>
                      <Col sm="6" className="mt-2">
                        <h4>
                          <code>{includingGstCalculation()}</code>
                        </h4>
                      </Col>
                    </Row>
                  </>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="font-12r">Verify Claimed Amount:</Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Label className="font-10 text-danger">
                      Enter the Total Amount of the Claim / Invoice (ex. GST)
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    <InputWrapper
                      onInputChange={(data) => changeClaimedAmount(data.value)}
                      width="50px"
                      cellKey="claimedAmount"
                      value={claimedAmount}
                      type={DisplayType.Currency}
                      precision={2}
                      allowNegative
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>

          {claimedAmount !== '' && claimedAmount !== totalAmountToProcess ? (
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="font-12">
                    Explain why claimed amount differs from scheduled amount?:
                  </Label>
                  <InputWrapper
                    onInputChange={(data) =>
                      setClaimedAmountComments(data.value)
                    }
                    width="150px"
                    cellKey="claimedAmountComments"
                    value={claimedAmountComments}
                    type={DisplayType.TextArea}
                  />
                </Col>
              </Row>
            </FormGroup>
          ) : (
            <div />
          )}
          <Row>
            <Col sm="5">
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="font-12">
                      Quantity Surveyor Recommendation?:
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Switch
                      onColor="danger"
                      onText="Y"
                      offText="N"
                      defaultValue={qsRecommendation}
                      onChange={(e) => changeQsRecommendation(e.state.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col sm="7">
              {qsRecommendation ? (
                <FormGroup>
                  <Row>
                    <Col sm="12">
                      <Label className="font-12">Recommended Amount:</Label>
                      <InputWrapper
                        onInputChange={(data) => setQsAmount(data.value)}
                        width="50px"
                        cellKey="qsAmount"
                        value={qsAmount}
                        type={DisplayType.Currency}
                        precision={2}
                        allowNegative
                      />
                    </Col>
                  </Row>
                </FormGroup>
              ) : (
                <div />
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalBody className="border-top mb-2">
          <FormGroup>
            <Row>
              <Col sm="12">
                <Label className="font-12">Comments</Label>
                <InputWrapper
                  onInputChange={(data) => setComments(data.value)}
                  width="150px"
                  cellKey="comments"
                  value={comments}
                  type={DisplayType.TextArea}
                />
              </Col>
            </Row>
          </FormGroup>
          <CardTitle onClick={toggleDocumentSection}>
            <i
              className={
                isDocumentSectionOpen
                  ? 'fas fa-chevron-circle-down'
                  : 'fas fa-chevron-circle-right'
              }
            />
            {'  '}Attach Document (optional){' '}
          </CardTitle>
          <Collapse isOpen={isDocumentSectionOpen}>
            <DocumentForm
              documentFormState={documentFormState}
              setDocumentFormState={setDocumentFormState}
              categoryOptions={processClaimDocumentCategoryOptions}
            />
            <div>
              Need to upload multiple documents?{' '}
              {
                <i
                  id="InfoTooltip"
                  className={isDocumentSectionOpen ? 'fa fa-info-circle' : ''}
                />
              }
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="InfoTooltip"
                toggle={toggleToolTip}
              >
                Attach one document and &quot;Process&quot;. Navigate to
                &quot;Previous Claims&quot; to attach additional documents.
              </Tooltip>
            </div>
          </Collapse>
        </ModalBody>
        {invalid() ? (
          <ModalBody className="border-top mb-2">
            <span className="text-danger font-12">
              Please fill out all required fields (Invoice Number, Invoice Date,
              GST Amount and any conditional fields).
            </span>
          </ModalBody>
        ) : (
          ''
        )}
        <ModalFooter>
          <Button
            disabled={disable()}
            className="float-right"
            outline
            color="projx"
            onClick={viewPaymentSchedule}
          >
            View Payment Schedule
          </Button>
          <Button
            disabled={disable()}
            className="float-right"
            color="projx"
            onClick={process}
          >
            {processLoading ? (
              <CircularProgress color="white" size={18} />
            ) : (
              'Process'
            )}
          </Button>
        </ModalFooter>
      </Modal>
      <FormViewer
        key="frmPaymentSchedule"
        toggle={() => toggleForm('paymentSchedule')}
        open={forms.paymentSchedule}
        generated={generated}
      />
    </>
  );
};

export default ProcessClaims;
