import React, { useState, useEffect } from 'react';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

const FormViewer = (props) => {
  const [isPdfReady, setIsPdfReady] = useState(false);
  const [pdfLoaded, setPdfLoaded] = useState(false);

  const { toggle = () => {}, open, generated } = props;

  const toggleButtonClicked = () => {
    setPdfLoaded(false);
    setIsPdfReady(false);
    toggle();
  };

  useEffect(() => {
    if (!generated) return;

    // Hacky but necessary for the react-pdf, as it crashes when re-rendering more than one PDF
    setTimeout(() => {
      setIsPdfReady(true);
    }, 2000);
  }, [generated]);

  return (
    <Modal
      isOpen={open}
      toggle={toggleButtonClicked}
      backdrop
      size="lg"
      style={{ width: '1000px' }}
    >
      <ModalHeader toggle={toggleButtonClicked} />

      <ModalBody>
        {pdfLoaded && (
          <PDFViewer width="100%" height="1000">
            {generated}
          </PDFViewer>
        )}
      </ModalBody>
      <ModalFooter>
        {isPdfReady && generated && (
          <PDFDownloadLink document={generated} fileName="download">
            {({ loading, error }) => {
              if (error)
                return (
                  <Label className="font-warning">
                    Error generating document...
                  </Label>
                );

              if (!loading) {
                setPdfLoaded(true);
              }

              return loading ? (
                <Button disabled>Loading...</Button>
              ) : (
                <Button color="success">Download</Button>
              );
            }}
          </PDFDownloadLink>
        )}

        <Button onClick={toggleButtonClicked}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default FormViewer;
