import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;

  ${(props) => props.horizontal && css`
    flex-direction: row;
    align-items: center;
  `}
`;

export const InputHeader = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 8px 0;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const DatePickerStyled = styled(DatePicker)`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  height: 36px;
  padding-left: 8px;
  margin: 0;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
`;

export const TextAreaStyled = styled.textarea`
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  padding: 8px;
`;
