import React, { useEffect, useState, useContext } from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown,
} from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { useAlert } from 'react-alert';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { BudgetType } from './types';
import { focotrDocumentCategoryOptions } from '../../../helpers';
import {
  useAddFocotrDocument,
  useDeleteFocotr,
  useFocotrCanDelete,
} from '../../../hooks';
import { DocumentListModal } from '../..';
import { ContractSumAdjustment } from '../../forms';
import FormViewer from '../../forms/FormViewer';
import { extractGraphqlError } from '../../../common';
import UserContext from '../../../contexts/user/user.context';
import OrganisationContext from '../../../contexts/organisation/organisation.context';

const ToolbarType = {
  NewBudget: 'new-budget',
  ColumnView: 'column-view',
  ClaimsView: 'claims-view',
  PreviousClaimsView: 'previous-claims-view',
  Lock: 'lock',
  ProcessClaims: 'process-claims',
  DeletedFocotr: 'deleted-focotr',
};

const BudgetToolbar = ({
  project,
  onItemSelected = () => {},
  details,
  totals,
  variations = [],
  provisionals = [],
}) => {
  const { user } = useContext(UserContext);
  const { currentOrganisation } = useContext(OrganisationContext);
  const { canDelete } = useFocotrCanDelete({
    onCompleted: (canDelete) => {
      setCanDelete(canDelete);
    },
  });

  const [deleteFocotr] = useDeleteFocotr({
    onCompleted: (data) => {
      if (data)
        onItemSelected({ type: ToolbarType.DeletedFocotr, value: details });
    },
  });

  const alert = useAlert();

  const [canDeleteFocotr, setCanDelete] = useState(false);

  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

  const [isOpen, setOpen] = useState(false);
  const toggleNewItem = () => setOpen(!isOpen);

  const [forms, setForms] = useState({ sumAdjustment: false });
  const [generated, setGenerated] = useState(undefined);

  const [costs, setCosts] = useState(true);

  const onSelectedView = (data) => {
    onItemSelected(data);
    setCosts(!costs);
  };

  const disableButton =
    totals.adjustments === 0 && totals.contracted === 0 && totals.ffc === 0;

  // Document stuff
  const [isDocumentListModelOpen, setDocumentListModel] = useState(false);
  const [isDocumentUploadComplete, setDocumentUploadComplete] = useState(false);

  const toggleDocumentListModal = () =>
    setDocumentListModel(!isDocumentListModelOpen);

  const [addFocotrDocument, { loading }] = useAddFocotrDocument({
    onCompleted: (document) => {
      details.documents
        ? details.documents.push(document)
        : (details.documents = [document]);
      setDocumentUploadComplete(true);
    },
  });

  const onDocumentUpload = (documentInput) => {
    setDocumentUploadComplete(false);
    addFocotrDocument(details._id, documentInput);
  };

  const onFormSelected = () => {
    setGenerated(
      <ContractSumAdjustment
        project={project}
        template={{
          costCode: details.costCode.name,
          vendorName: details.vendor.name,
          issueDate: moment().format('MMMM Do YYYY, h:mm a'),
          adjustments: { ...buildAdjustments(), withTotals: true },
          contracted: totals.contracted,
          adjustmentsTotal: totals.adjustments,
          userName: user.name,
          userTitle: user.title,
          userCompany: user.company,
        }}
      />,
    );

    toggleForm('sumAdjustment');
  };

  const toggleForm = (type) => {
    setForms((prev) => {
      const isVisible = !prev[type];
      if (!isVisible) setGenerated(undefined);
      return {
        ...prev,
        [type]: isVisible,
      };
    });
  };

  const buildAdjustments = () => {
    const fields = [
      {
        label: 'Reference',
        key: 'reference',
      },
      {
        label: 'Description',
        key: 'description',
      },
      {
        label: 'Amount (ex GST)',
        key: 'amount',
      },
    ];

    const data = [
      ..._.map(
        _.filter(
          variations,
          (variation) => !!_.isNumber(variation.committed.approved),
        ),
        (variation) => {
          return {
            reference: variation.ref,
            description: variation.phase,
            amount: variation.committed.approved,
          };
        },
      ),
      ..._.map(
        _.filter(
          provisionals,
          (provisional) => !!_.isNumber(provisional.committed.adjustment),
        ),
        (provisional) => {
          return {
            reference: provisional.ref,
            description: provisional.phase,
            amount: provisional.committed.adjustment,
          };
        },
      ),
    ];

    const totalsData = [
      {
        reference: '',
        description: '',
        amount: _.sumBy(data, 'amount'),
      },
    ];

    return {
      fields,
      data,
      totals: {
        data: totalsData,
        columns: [
          { accessor: 'reference' },
          { accessor: 'description' },
          { accessor: 'amount' },
        ],
      },
    };
  };

  const onDeleteFocotr = async () => {
    try {
      await deleteFocotr(details._id);
    } catch (e) {
      alert.error(extractGraphqlError(e));
    }
  };

  const onDelete = () => {
    toggle();
  };

  const disableUnlocking = () => {
    if (details.locked === false) return false;

    return !isUserAdmin(); //|| variations.length > 0;
  };

  const isUserAdmin = () => {
    return (
      project.collaborators.some(
        (collaborator) =>
          collaborator.user._id === user._id &&
          collaborator.role.name === 'admin',
      ) ||
      currentOrganisation.members.some(
        (member) => member.user._id === user._id && member.isAdmin === true,
      )
    );
  };

  useEffect(() => {
    canDelete(details._id);
  }, [details]);

  // useEffect(() => {
  //     if(!focotrId)
  //         canDelete(focotrId);
  // }, [focotrId])

  return (
    <>
      <div className="float-left mt-2">
        <h2>
          <code>{details.locked ? 'LOCKED' : ''}</code>
        </h2>
      </div>
      {canDeleteFocotr ? (
        <Button
          onClick={onDelete}
          color="projx"
          className="float-right mt-2 ml-3"
        >
          DELETE VENDOR
        </Button>
      ) : (
        ''
      )}

      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-circle float-right ml-3 btn-mywhite"
          color="white"
        >
          <i className="fas fa-ellipsis-h" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>View</DropdownItem>
          <DropdownItem
            onClick={() =>
              onItemSelected({ type: ToolbarType.ColumnView, value: 'simple' })
            }
          >
            <i className="fas fa-th-list" /> Simple View
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              onItemSelected({
                type: ToolbarType.ColumnView,
                value: 'detailed',
              })
            }
          >
            <i className="fas fa-th" /> Detailed View
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem header>Claims</DropdownItem>
          <DropdownItem
            onClick={() =>
              onItemSelected({ type: ToolbarType.ClaimsView, value: 'claims' })
            }
          >
            <i className="fas fa-newspaper" /> Claims View
          </DropdownItem>
          <DropdownItem
            className="text-info"
            onClick={() =>
              onItemSelected({
                type: ToolbarType.PreviousClaimsView,
                value: 'previous-claims',
              })
            }
          >
            <i className="fas fa-dollar-sign" /> Previous Claims
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {disableButton ? (
        <div />
      ) : (
        <>
          {details.accountType === 'bills' ? (
            ''
          ) : (
            <Button
              disabled={disableUnlocking()}
              onClick={() =>
                onItemSelected({
                  type: ToolbarType.Lock,
                  value: BudgetType.Contract,
                })
              }
              className="float-right ml-3 mt-2"
              color="projx"
            >
              <i
                className={details.locked ? 'fas fa-lock' : 'fas fa-lock-open'}
              />
              {details.locked ? ' Unlock Contract' : ' Lock Contract'}
            </Button>
          )}

          <Button
            onClick={() =>
              onSelectedView({
                type: costs
                  ? ToolbarType.ProcessClaims
                  : ToolbarType.ColumnView,
                value: 'detailed',
              })
            }
            className="float-right ml-3 mt-2"
            outline
            color="projx"
          >
            <i className="fas fa-cogs" />
            {costs ? ' Claims' : ' Costs'}
          </Button>
          <Button
            className="float-right ml-3 mt-2"
            color="projx"
            outline
            onClick={toggleDocumentListModal}
          >
            <i className="fas fa-folder" />
            {' Documents'}
          </Button>
        </>
      )}
      <UncontrolledDropdown className="ml-3 float-right mt-2">
        <DropdownToggle outline caret color="projx">
          <i className="fas fa-file" />
          {' Forms'}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => onFormSelected()}>
            <i className="fas fa-sliders-h" /> Contract Sum Adjustment
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {generated && (
        <FormViewer
          key="frmContractSumAdjustment"
          toggle={() => toggleForm('sumAdjustment')}
          open={forms.sumAdjustment}
          generated={generated}
        />
      )}
      <ButtonDropdown
        className="ml-2 float-right mt-2"
        isOpen={isOpen}
        toggle={toggleNewItem.bind(null)}
      >
        <DropdownToggle caret color="projx">
          <i className="fa fa-plus" /> Create
        </DropdownToggle>
        <DropdownMenu>
          {details.accountType === 'bills' ? (
            <DropdownItem
              onClick={() =>
                onItemSelected({
                  type: ToolbarType.NewBudget,
                  value: BudgetType.Contract,
                })
              }
            >
              Bills
            </DropdownItem>
          ) : (
            <>
              <DropdownItem
                disabled={details.locked}
                onClick={() =>
                  onItemSelected({
                    type: ToolbarType.NewBudget,
                    value: BudgetType.Contract,
                  })
                }
              >
                Contract
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                disabled={details.locked}
                onClick={() =>
                  onItemSelected({
                    type: ToolbarType.NewBudget,
                    value: BudgetType.Provisional,
                  })
                }
              >
                Provisional Sum
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                disabled={!details.locked}
                onClick={() =>
                  onItemSelected({
                    type: ToolbarType.NewBudget,
                    value: BudgetType.Variation,
                  })
                }
              >
                Variation
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </ButtonDropdown>
      <Modal isOpen={show} toggle={toggle}>
        <ModalHeader>
          Delete &quot;{details.vendor.name}&quot; vendor
        </ModalHeader>
        <ModalBody>
          <span className="text-danger">
            <b>WARNING: </b>
          </span>
          You are about to delete{' '}
          <span className="text-danger">
            <b>{details.vendor.name}</b>
          </span>{' '}
          vendor record. This will delete any costs associated with this vendor.
          <div className="mt-3">
            <span className="text-danger">
              <b>Are you sure you want to delete this vendor?</b>
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="projx" outline>
            Cancel
          </Button>
          <Button color="projx" onClick={onDeleteFocotr}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <DocumentListModal
        documents={details.documents}
        onDocumentUpload={onDocumentUpload}
        documentUploadComplete={isDocumentUploadComplete}
        documentUploadLoading={loading}
        isOpen={isDocumentListModelOpen}
        toggle={toggleDocumentListModal}
        header="Documents"
        categoryOptions={focotrDocumentCategoryOptions}
      />
    </>
  );
};

export { ToolbarType, BudgetToolbar };
