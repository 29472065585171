import styled, { css } from 'styled-components';
import { CgOrganisation } from 'react-icons/cg';
import { MdOutlineDashboardCustomize, MdOutlineAccountBalance, MdOutlineSettings, MdOutlineStickyNote2 } from 'react-icons/md';
import { VscTable } from 'react-icons/vsc';
import { BiDollar, BiDotsHorizontalRounded, BiTimer, BiTagAlt } from 'react-icons/bi';
import { IoPricetagsOutline, IoWarningOutline } from 'react-icons/io5';
import { HiOutlineDocumentReport, HiOutlineDocumentDuplicate, HiOutlineCash } from 'react-icons/hi';
import { VscGraphLine, VscMilestone } from 'react-icons/vsc';
import { AiOutlineSafety, AiOutlineFundProjectionScreen, AiOutlineCalendar } from 'react-icons/ai';
import { FiUsers, FiDatabase } from 'react-icons/fi';
import { RiExchangeDollarLine } from 'react-icons/ri';
import { BsJournals } from 'react-icons/bs';

const iconStyles = css`
  width: 24px;
  height: 24px;

  ${props => props.subitem && css`
    margin-left: 15px;
  `}
`;

export const OrganisationPortfolioIcon = styled(CgOrganisation)`
  ${iconStyles}
`;

export const HorizontalDotsIcon = styled(BiDotsHorizontalRounded)`
  ${iconStyles}
  margin-left: 14px;
`;

export const DashboardIcon = styled(MdOutlineDashboardCustomize)`
  ${iconStyles}
`;

export const FinancialsIcon = styled(HiOutlineCash)`
  ${iconStyles}
`;

export const CostSummaryIcon = styled(VscTable)`
  ${iconStyles}
`;

export const FundingIcon = styled(BiDollar)`
  ${iconStyles}
`;

export const BudgetIcon = styled(MdOutlineAccountBalance)`
  ${iconStyles}
`

export const CostAndClaimsIcon = styled(IoPricetagsOutline)`
  ${iconStyles}
`;

export const CostReportIcon = styled(HiOutlineDocumentReport)`
  ${iconStyles}
`;

export const CashflowIcon = styled(VscGraphLine)`
  ${iconStyles}
`;

export const ProgramIcon = styled(AiOutlineCalendar)`
  ${iconStyles}
`;

export const MilestoneIcon = styled(VscMilestone)`
  ${iconStyles}
`;

export const ProgressDiaryIcon = styled(MdOutlineStickyNote2)`
  ${iconStyles}
`;

export const LookaheadIcon = styled(BiTimer)`
  ${iconStyles}
`;

export const StatusIcon = styled(BiTagAlt)`
  ${iconStyles}
`;

export const SafetyIcon = styled(AiOutlineSafety)`
  ${iconStyles}
`;

export const RiskIcon = styled(IoWarningOutline)`
  ${iconStyles}
`;

export const DocumentIcon = styled(HiOutlineDocumentDuplicate)`
  ${iconStyles}
`;

export const SettingIcon = styled(MdOutlineSettings)`
  ${iconStyles}
`;

export const ProjectProfileIcon = styled(AiOutlineFundProjectionScreen)`
  ${iconStyles}
`

export const CollaboratorIcon = styled(FiUsers)`
  ${iconStyles}
`

export const ReferenceDataIcon = styled(FiDatabase)`
  ${iconStyles}
`;

export const BudgetCodeIcon = styled(RiExchangeDollarLine)`
  ${iconStyles}
`;

export const DirectoryIcon = styled(BsJournals)`
  ${iconStyles}
`;
