import React, { useState } from 'react';

const ProjectCtx = React.createContext();

const ProjectProv = ({ children }) => {
  const [project, setProject] = useState();
  const [budgetCost, setBudgetCost] = useState();
  const [budgetSettings, setBudgetSettings] = useState();
  const [projects, setProjects] = useState([]);

  return (
    <ProjectCtx.Provider
      value={{
        project,
        setProject,
        budgetCost,
        setBudgetCost,
        budgetSettings,
        setBudgetSettings,
        projects,
        setProjects,
      }}
    >
      {children}
    </ProjectCtx.Provider>
  );
};

export { ProjectProv, ProjectCtx };
