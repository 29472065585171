import React from 'react';
import { components } from 'react-select';

const initiationOptions = [
  { value: 'General Development', label: 'General Development' },
  {
    value: 'Business Case (Feasibility)',
    label: 'Business Case (Feasibility)',
  },
  { value: 'Acquisition', label: 'Acquisition' },
  { value: 'Sales & Marketing', label: 'Sales & Marketing' },
];

const planningAndDesignOptions = [
  { value: 'General Design', label: 'General Design' },
  { value: 'Town Planning (TP)', label: 'Town Planning (TP)' },
  { value: 'Concept Design (CD)', label: 'Concept Design (CD)' },
  { value: 'Schematic Design (SD)', label: 'Schematic Design (SD)' },
  { value: 'Design Development (DD)', label: 'Design Development (DD)' },
  { value: 'Issued For Tender (IFT)', label: 'Issued For Tender (IFT)' },
  {
    value: 'Approved For Construction (AFC)',
    label: 'Approved For Construction (AFC)',
  },
];

const tenderOptions = [
  { value: 'General Tender', label: 'General Tender' },
  { value: 'Head Contractor Tender', label: 'Head Contractor Tender' },
  { value: 'Other Procurement', label: 'Other Procurement' },
];

const constructionOptions = [
  { value: 'General Construction', label: 'General Construction' },
  { value: 'Enabling Works', label: 'Enabling Works' },
  { value: 'Early Works', label: 'Early Works' },
  { value: 'Main Works', label: 'Main Works' },
  { value: 'Other Works', label: 'Other Works' },
];

const operationalOptions = [
  { value: 'General Operational', label: 'General Operational' },
  { value: 'Commissioning & Handover', label: 'Commissioning & Handover' },
  {
    value: 'Defects Liability Period (DLP)',
    label: 'Defects Liability Period (DLP)',
  },
  { value: 'Maintenance Period (MP)', label: 'Maintenance Period (MP)' },
];

const BUDGET_SELECTS = {
  activities: [
    {
      label: 'Initiation (Pre-Design)',
      options: initiationOptions,
    },
    {
      label: 'Planning & Design (Pre-Tender)',
      options: planningAndDesignOptions,
    },
    {
      label: 'Tender (Pre-Construction)',
      options: tenderOptions,
    },
    {
      label: 'Construction',
      options: constructionOptions,
    },
    {
      label: 'Operational (Post-Construction)',
      options: operationalOptions,
    },
  ],
};

const IndicatorsContainer = (props) => {
  return (
    <div>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const resolveOptions = (key) => {
  return BUDGET_SELECTS[key] ?? [];
};

export { IndicatorsContainer, resolveOptions };
