/* eslint-disable import/prefer-default-export */
import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { cleanGqlTypename } from '../../common';
import { OrganisationContext } from '../../contexts';
import {
  ADD_ORG_MEMBER_MUTATION,
  UPDATE_ORG_MEMBER_MUTATION,
  DELETE_ORG_MEMBER_MUTATION,
  SAVE_ORG_MUTATION,
  DELETE_ORG_MUTATION,
  LEAVE_ORG_MUTATION,
} from '../../graphql/queries';
import _ from 'lodash';

export const useAddOrgMember = () => {
  const [_addOrgMember, { data, loading, error }] = useMutation(
    ADD_ORG_MEMBER_MUTATION,
  );

  const addOrgMember = (organisationId, member) => {
    return _addOrgMember({
      variables: { _id: organisationId, member },
    });
  };

  return [addOrgMember, { data, loading, error }];
};

export const useUpdateOrgMember = () => {
  const [_updateOrgMember, { data, loading, error }] = useMutation(
    UPDATE_ORG_MEMBER_MUTATION,
  );

  const updateOrgMember = (organisationId, member) => {
    return _updateOrgMember({
      variables: { _id: organisationId, member },
    });
  };

  return [updateOrgMember, { data, loading, error }];
};

export const useDeleteOrgMember = () => {
  const [_deleteOrgMember, { data, loading, error }] = useMutation(
    DELETE_ORG_MEMBER_MUTATION,
  );

  const deleteOrgMember = (organisationId, userId) => {
    return _deleteOrgMember({
      variables: { _id: organisationId, userId },
    });
  };

  return [
    deleteOrgMember,
    { data: cleanGqlTypename(_.get(data, 'deleteOrg')), loading, error },
  ];
};

export const useLeaveOrg = () => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const [_leaveOrg, { data, loading, error }] = useMutation(LEAVE_ORG_MUTATION);

  const leaveOrg = () => {
    return _leaveOrg({
      variables: {
        _id: currentOrganisation._id,
      },
    });
  };

  return [
    leaveOrg,
    { data: cleanGqlTypename(_.get(data, 'leaveOrg')), loading, error },
  ];
};

export const useDeleteOrg = () => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const [_deleteOrg, { data, loading, error }] =
    useMutation(DELETE_ORG_MUTATION);

  const deleteOrg = () => {
    return _deleteOrg({
      variables: {
        _id: currentOrganisation._id,
      },
    });
  };

  return [deleteOrg, { data, loading, error }];
};

export const useSaveOrg = () => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const [_saveOrg, { data, loading, error }] = useMutation(SAVE_ORG_MUTATION);

  const saveOrg = (kvPair) => {
    return _saveOrg({
      variables: {
        _id: currentOrganisation._id,
        input: kvPair,
      },
    });
  };

  let organisation =
    !loading && data ? cleanGqlTypename(_.get(data, 'saveOrg')) : undefined;

  return [saveOrg, { organisation, loading, error }];
};
