import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { parse } from 'query-string';
import validators from './validators';
import AuthContext from '../../contexts/auth/auth.context';
import rules from '../../helpers/form-validator/libs/rules';

// New ResetPassword component stuff
import logoDarkSVG from '../../assets/images/logo_dark.svg';
import { LogoIMG } from '../../components';
import {
  LoginPageContainer,
  LoginFormContainer,
  LogoContainer,
  LoginFormBody,
  LoginFormHeading,
  LoginFormInput,
  LoginFormErrorContainer,
  LoginFormErrorText,
  LoginFormText,
  LoginFormEmailText,
  LoginFormButton,
} from './styles/LoginStyles';

const ResetPassword = ({ location }) => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasStartedRecovery, setHasStartedRecovery] = useState(false);

  const {
    forgotPassword: authContextForgotPassword,
    confirmPasswordChange: authContextConfirmPasswordChange,
  } = useContext(AuthContext);

  // Construct errors for email field
  let emailErrors = [];
  if (!validators.email.rules[0].test.test(email) && email.length !== 0) {
    emailErrors.push('* Please enter a valid email');
  }

  // Construct errors for new password field
  let passwordErrors = [];
  if (password.length !== 0) {
    if (password.length < 8) {
      passwordErrors.push('* Password must be at least 8 characters');
    }
    if (!rules.password[1].test.test(password)) {
      passwordErrors.push('* Password must contain a lowercase character');
    }
    if (!rules.password[2].test.test(password)) {
      passwordErrors.push('* Password must contain an uppercase character');
    }
    if (!rules.password[3].test.test(password)) {
      passwordErrors.push('* Password must contain a number');
    }
    if (!rules.password[4].test.test(password)) {
      passwordErrors.push('* Password must contain a special character');
    }
  }

  // Construct errors for confirm new password field
  let confirmPasswordErrors = [];
  if (
    password.length !== 0 &&
    password !== confirmPassword &&
    confirmPassword.length !== 0
  ) {
    confirmPasswordErrors.push('* Passwords do not match');
  }

  const isStartRecoveryButtonDisabled =
    email.length === 0 || emailErrors.length !== 0 || isProcessing;

  const isConfirmButtonDisabled =
    password.length === 0 ||
    password !== confirmPassword ||
    passwordErrors.length !== 0 || isProcessing;

  useEffect(() => {
    const params = parse(location.search);
    if (params.code) {
      setEmail(params.email);
      setVerificationCode(params.code);
    }
  }, [location]);

  const showFormValidationErrors = (field) => {
    switch (field) {
      case 'email':
        const mappedEmailErrors = _.map(emailErrors, (value) => (
          <LoginFormErrorText>{value}</LoginFormErrorText>
        ));

        return (
          <LoginFormErrorContainer>{mappedEmailErrors}</LoginFormErrorContainer>
        );

        break;
      case 'newPassword':
        const mappedNewPasswordErrors = _.map(passwordErrors, (value) => (
          <LoginFormErrorText>{value}</LoginFormErrorText>
        ));

        return (
          <LoginFormErrorContainer>
            {mappedNewPasswordErrors}
          </LoginFormErrorContainer>
        );

        break;
      case 'confirmNewPassword':
        const mappedConfirmNewPasswordErrors = _.map(
          confirmPasswordErrors,
          (value) => <LoginFormErrorText>{value}</LoginFormErrorText>,
        );

        return (
          <LoginFormErrorContainer>
            {mappedConfirmNewPasswordErrors}
          </LoginFormErrorContainer>
        );

        break;
    }
  };

  const doStartPasswordReset = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    authContextForgotPassword(email)
      .then(() => {
        setHasStartedRecovery(true);
        setIsProcessing(false);
      })
      .catch((error) => {
        setIsProcessing(false);
        console.error(error);
        alert(error.message);
      });
  };

  const doCompletePasswordReset = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    authContextConfirmPasswordChange(verificationCode, email, password)
      .then(() => {
        history.push('/authentication/login');
      })
      .catch((error) => {
        setIsProcessing(false);
        console.error(error);
        alert(error.message);
      });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <LoginPageContainer>
      <LoginFormContainer center>
        <Link to="/">
          <LogoContainer>
            <LogoIMG src={logoDarkSVG} alt="projx-logo" />
          </LogoContainer>
        </Link>
        {verificationCode ? (
          <LoginFormBody onSubmit={doCompletePasswordReset}>
            <LoginFormHeading>Reset Password</LoginFormHeading>
            <LoginFormText>
              To complete your password change please enter a new password
            </LoginFormText>
            <LoginFormInput
              placeholder="New password"
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
            />
            {showFormValidationErrors('newPassword')}
            <LoginFormInput
              placeholder="Confirm new password"
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            {showFormValidationErrors('confirmNewPassword')}
            <LoginFormButton type="submit" disabled={isConfirmButtonDisabled}>
              {isProcessing ? 'Loading...' : 'Confirm'}
            </LoginFormButton>
          </LoginFormBody>
        ) : hasStartedRecovery ? (
          <LoginFormBody>
            <LoginFormText>
              If we found a user with email address: <LoginFormEmailText>{email}</LoginFormEmailText> you will receive an
              email from us shortly. If you do not receive the email within a
              few minutes, please check your junk/spam email folder.
            </LoginFormText>
          </LoginFormBody>
        ) : (
          <LoginFormBody onSubmit={doStartPasswordReset}>
            <LoginFormHeading>Reset Password</LoginFormHeading>
            <LoginFormText>
              Enter your email to start the recovery process
            </LoginFormText>
            <LoginFormInput
              placeholder="Email"
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
            />
            {showFormValidationErrors('email')}
            <LoginFormButton
              type="submit"
              disabled={isStartRecoveryButtonDisabled}
            >
              {isProcessing ? 'Loading...' : 'Start Recovery'}
            </LoginFormButton>
          </LoginFormBody>
        )}
      </LoginFormContainer>
    </LoginPageContainer>
  );
};

export default ResetPassword;
