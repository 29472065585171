import React from 'react';
import { gql, useQuery } from '@apollo/client';
// Nivo Charts
import { ResponsiveLine } from '@nivo/line';
// C3.js Charts
import Chart from 'react-c3-component';
import 'c3/c3.css';
// Our timeline component
import { Chrono } from 'react-chrono';
// Widget specific stuff
import WidgetCard from './WidgetCard';
import EditWidgetButton from './EditWidgetButton';
import AddWidgetButton from './AddWidgetButton';
import StatusCards from './StatusCards';
import LoadingWidgetSpinner from './LoadingWidgetSpinner';
import ErrorWidget from './ErrorWidget';
import ProjectOverviewWidget from './ProjectOverviewWidget';
import {
  StyledSafetyEventBarGraph,
  StyledSafetyIncidentLineGraph,
} from './safetyGraphs';
// Generic Imports
import { projxColorPalette } from '../../constants';
import { ActivityStream } from '../../components';

// GraphQL Queries
const GET_WIDGET_BY_DASHBOARD = gql`
  query getWidget($projectId: ID!, $widgetType: WidgetType!, $dashboard: DashboardType!) {
    getWidget(projectId: $projectId, widgetType: $widgetType, dashboard: $dashboard) {
      _id
      type
      data
      description
      dashboard
    }
  }
`;

/**
 * Widget component is a 'polymorphic' component for widgets on our dashboard
 * dynamically rendering when a different 'type' props is specified
 *
 * NOTE: Make sure all components are wrapped in a 'WidgetCard'
 * for styling purposes:
 *
 * @param {string} title The title of the Widget
 * @param {string} type The type of the widget
 * @param {boolean} inEditMode Indicates whether the Widget is in edit mode
 * @param {string} projectId The ID of our current project
 * @param {string} height A character representing the height of our widget
 * @param {string} dashboard The dashboard on which we render the widget
 * @param {Function} setWidgets A setState function to update the widgets on our dashboard
 * @return {JSX.Element} Returns the Widget based on type
 */
const Widget = ({ title, type, inEditMode, projectId, height, dashboard, setWidgets }) => {
  const {
    loading,
    error,
    data: widgetData,
  } = useQuery(GET_WIDGET_BY_DASHBOARD, {
    variables: {
      projectId,
      widgetType: type,
      dashboard,
    },
  });

  // Handle loading state of widget for GraphQL query
  if (loading) {
    return (
      <WidgetCard
        header={title}
        inEditMode={inEditMode}
        type={type}
        projectId={projectId}
      >
        <LoadingWidgetSpinner color="#000000" size={60} />
      </WidgetCard>
    );
  }

  // Handle error state of widget for GraphQL query
  if (error) {
    return (
      <WidgetCard
        header={title}
        inEditMode={inEditMode}
        type={type}
        projectId={projectId}
      >
        <ErrorWidget />
      </WidgetCard>
    );
  }

  if (type === 'ACTIVITY_STREAM') {
    return (
      <WidgetCard
        header={title}
        type={type}
        projectId={projectId}
        height={height}
        inEditMode={inEditMode}
        dashboard={dashboard}
        setWidgets={setWidgets}
      >
        <ActivityStream projectId={projectId} />
      </WidgetCard>
    );
  }

  if (type === 'BUDGET_BREAKDOWN') {
    return (
      <WidgetCard
        header={title}
        type={type}
        projectId={projectId}
        height={height}
        inEditMode={inEditMode}
        dashboard={dashboard}
        setWidgets={setWidgets}
      >
        <Chart
          style={{ width: '100%' }}
          config={{
            data: JSON.parse(widgetData.getWidget.data),
            // size: {
            //   height: 330,
            // },
            color: {
              pattern: projxColorPalette,
            },
            donut: {
              width: 65,
            },
            interaction: {
              enabled: inEditMode ? false : true,
            },
            legend: {
              hide: inEditMode ? true : false,
            },
          }}
        />
      </WidgetCard>
    );
  }

  if (type === 'CASHFLOW') {
    return (
      <WidgetCard
        header={title}
        type={type}
        projectId={projectId}
        height={height}
        inEditMode={inEditMode}
        dashboard={dashboard}
        setWidgets={setWidgets}
      >
        <ResponsiveLine
          data={JSON.parse(widgetData.getWidget.data)}
          theme={{
            fontSize: '12px',
          }}
          isInteractive={inEditMode ? false : true}
          margin={{ top: 30, right: 110, bottom: 50, left: 80 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="catmullRom"
          colors={projxColorPalette}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'months',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: -50,
            legendPosition: 'middle',
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </WidgetCard>
    );
  }

  if (type === 'CONTINGENCY_METER') {
    return (
      <WidgetCard
        header={title}
        type={type}
        projectId={projectId}
        height={height}
        inEditMode={inEditMode}
        dashboard={dashboard}
        setWidgets={setWidgets}
      >
        <Chart
          style={{ width: '100%' }}
          config={{
            data: JSON.parse(widgetData.getWidget.data),
            size: {
              height: 330,
            },
            color: {
              pattern: ['#e7224a', '#ff7f0e', '#ffbb33', '#00c853'],
              threshold: {
                values: [25, 50, 75, 100],
              },
            },
            interaction: {
              enabled: inEditMode ? false : true,
            },
            legend: {
              hide: inEditMode ? true : false,
            },
            gauge: {
              width: 120,
            },
          }}
        />
      </WidgetCard>
    );
  }

  if (type === 'VARIATION_BY_CAUSE') {
    return (
      <WidgetCard
        header={title}
        type={type}
        projectId={projectId}
        height={height}
        inEditMode={inEditMode}
        dashboard={dashboard}
        setWidgets={setWidgets}
      >
        <Chart
          style={{ width: '100%' }}
          config={{
            data: JSON.parse(widgetData.getWidget.data).data,
            axis: JSON.parse(widgetData.getWidget.data).axis,
            bar: {
              width: {
                ratio: 0.8,
              },
            },
            color: {
              pattern: projxColorPalette,
            },
            zoom: {
              enabled: true,
            },
            size: {
              height: 370,
            },
            interaction: {
              enabled: inEditMode ? false : true,
            },
            legend: {
              hide: inEditMode ? true : false,
            },
          }}
        />
      </WidgetCard>
    );
  }

  if (type === 'SAFETY_INCIDENT') {
    return (
      <WidgetCard
        header={title}
        type={type}
        projectId={projectId}
        height={height}
        inEditMode={inEditMode}
        dashboard={dashboard}
        setWidgets={setWidgets}
      >
        <StyledSafetyIncidentLineGraph
          config={{
            data: JSON.parse(widgetData.getWidget.data),
            axis: {
              x: {
                type: 'category',
              },
              y: {
                label: {
                  text: 'Number of Occurences',
                  position: 'outer-middle',
                },
              },
            },
            size: {
              height: 320,
            },
            padding: {
              bottom: 15,
              top: 5,
            },
            color: {
              pattern: projxColorPalette,
            },
            zoom: {
              enabled: true,
            },
          }}
        />
      </WidgetCard>
    );
  }

  if (type === 'SAFETY_EVENT') {
    return (
      <WidgetCard
        header={title}
        type={type}
        projectId={projectId}
        height={height}
        inEditMode={inEditMode}
        dashboard={dashboard}
        setWidgets={setWidgets}
      >
        <StyledSafetyEventBarGraph
          config={{
            data: JSON.parse(widgetData.getWidget.data).actualData,
            axis: {
              x: {
                type: 'category',
                categories: JSON.parse(widgetData.getWidget.data)
                  .xAxisCategories,
              },
            },
            size: {
              height: 320,
            },
            bar: {
              width: {
                ratio: 0.5,
              },
            },
            padding: {
              bottom: 15,
              top: 5,
            },
            color: {
              pattern: projxColorPalette,
            },
            zoom: {
              enabled: true,
            },
          }}
        />
      </WidgetCard>
    );
  }

  if (type === 'MILESTONE_TIMELINE') {
    return (
      <WidgetCard
        header={title}
        type={type}
        projectId={projectId}
        height={height}
        inEditMode={inEditMode}
        dashboard={dashboard}
        setWidgets={setWidgets}
      >
        <Chrono
          items={JSON.parse(widgetData.getWidget.data)}
          theme={{
            primary: '#1B1B1B',
            secondary: '#EF6555',
            // cardBgColor: '#F9F9F9',
            // cardForeColor: '#1B1B1B',
            titleColor: '#1B1B1B',
            // textColor: 'red',
          }}
          fontSizes={{
            cardSubtitle: '16px',
            cardText: '24px',
            cardTitle: '24px',
          }}
          // This is super important, it ensures our timeline re-renders with state updates
          allowDynamicUpdate={true}
          enableOutline
          cardWidth={400}
          cardHeight={100}
        />
      </WidgetCard>
    );
  }

  if (type === 'FFC_EXPENDITURE_CHANGE') {
    return (
      <WidgetCard
        header={title}
        type={type}
        projectId={projectId}
        height={height}
        inEditMode={inEditMode}
        dashboard={dashboard}
        setWidgets={setWidgets}
      ></WidgetCard>
    );
  }
};

/**
 * A sub-component to our Widget component which renders the button to edit widgets.
 * @param {boolean} inEditMode Boolean to indicate whether our widgets are in an editing state
 * @param {Function} onClick onClick event handler which puts widgets in edit mode and vice versa
 * @returns {React.JSX} Button used to edit widgets
 */
Widget.EditWidgetButton = ({ inEditMode, onClick }) => {
  return <EditWidgetButton inEditMode={inEditMode} onClick={onClick} />;
};

/**
 * A sub-component to our Widget component which renders the button to add widgets.
 * @param {boolean} inEditMode Boolean to indicate whether our widgets are in an editing state
 * @param {any[]} widgets The array containing data about our widgets
 * @param {Function} setWidgets The setState update function for our widgets
 * @param {string} projectId The ID of the project for which we want to add a widget
 * @param {string} dashboard The dashboard on which we want to add the widgets
 * @returns {React.JSX} Button used to add widgets
 */
Widget.AddWidgetButton = ({
  inEditMode,
  widgets,
  setWidgets,
  projectId,
  dashboard,
}) => {
  return (
    <AddWidgetButton
      inEditMode={inEditMode}
      widgets={widgets}
      setWidgets={setWidgets}
      projectId={projectId}
      dashboard={dashboard}
    />
  );
};

/**
 * A sub-component which contains our status cards on the dashboard
 * @param {string} projectId The ID of the project for which we want to render the status cards
 */
Widget.StatusCards = ({ projectId }) => {
  return <StatusCards projectId={projectId} />;
};

/**
 * A sub-component which displays our project overview as a widget
 * @param {string} project The project object of a particular project
 * @returns {React.JSX} ProjectOverviewWidget component
 */
Widget.ProjectOverview = ({ project }) => {
  return <ProjectOverviewWidget project={project} />;
};

export default Widget;
