import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import InvitationContext from './invitation.context';

const InvitationProvider = ({ children, location: { search } }) => {
  const [invitationToken, setInvitationToken] = useState();

  useEffect(() => {
    const params = search ? parse(search, { parseBooleans: true }) : undefined;

    if (params && params.token) {
      setInvitationToken(params.token);
    }
  }, [search]);

  return (
    <InvitationContext.Provider
      value={{
        invitationToken,
        setInvitationToken,
      }}
    >
      {children}
    </InvitationContext.Provider>
  );
};

export default withRouter(InvitationProvider);
