import styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export const TagsInputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
`;

export const TagsInputStyles= styled.input`
  flex: 1;
  border: none;
  height: 46px;
  font-size: 16px;
  padding: 8px 0 0 0;
  &:focus {
    outline: transparent;
  }
`;

export const UnorderedListStyles = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
`;

export const ListItemStyles = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  color: #1B1B1B;
  padding: 0 8px;
  list-style: none;
  border-radius: 16px;
  margin: 0 8px 8px 0;
  background-color: #D9D9D9;
  border: 1px solid #1B1B1B;
`;

export const TagTitle = styled.p`
  margin: 0;
`;

export const RemoveTagIcon = styled(AiOutlineCloseCircle)`
  display: block;
  width: 20px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #1B1B1B;
  border-radius: 50%;
  background-color: #D9D9D9;
  cursor: pointer;
`;
