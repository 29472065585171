import gql from 'graphql-tag';

const GET_REFERENCES_BY_ORG = gql`
  query ($organisationId: ID!) {
    getAllReferences(organisationId: $organisationId) {
      _id
      name
      description
      limit
      items {
        key
        value
        locked
      }
      organisation {
        _id
      }
    }
  }
`;

const CREATE_REFERENCE_MUTATION = gql`
  mutation ($item: ReferenceInput!) {
    createReference(item: $item) {
      _id
      name
      description
      limit
      items {
        key
        value
        locked
      }
      organisation {
        _id
      }
    }
  }
`;

const UPDATE_REFERENCE_MUTATION = gql`
  mutation ($_id: ID!, $item: ReferenceInput!) {
    updateReference(_id: $_id, item: $item) {
      _id
      name
      description
      limit
      items {
        key
        value
        locked
      }
      organisation {
        _id
      }
    }
  }
`;

const DELETE_REFERENCE_MUTATION = gql`
  mutation ($_id: ID!) {
    deleteReference(_id: $_id) {
      _id
      name
      description
      limit
      items {
        key
        value
        locked
      }
      organisation {
        _id
      }
    }
  }
`;
const CREATE_REFERENCE_ITEM_MUTATION = gql`
  mutation ($_id: ID!, $item: ReferenceItemInput!) {
    createReferenceItem(_id: $_id, item: $item) {
      key
      value
      locked
    }
  }
`;

const UPDATE_REFERENCE_ITEM_MUTATION = gql`
  mutation ($_id: ID!, $item: ReferenceItemInput!) {
    updateReferenceItem(_id: $_id, item: $item) {
      key
      value
      locked
    }
  }
`;

const DELETE_REFERENCE_ITEM_MUTATION = gql`
  mutation ($_id: ID!, $key: String!) {
    deleteReferenceItem(_id: $_id, key: $key)
  }
`;

export {
  GET_REFERENCES_BY_ORG,
  CREATE_REFERENCE_MUTATION,
  UPDATE_REFERENCE_MUTATION,
  DELETE_REFERENCE_MUTATION,
  UPDATE_REFERENCE_ITEM_MUTATION,
  DELETE_REFERENCE_ITEM_MUTATION,
  CREATE_REFERENCE_ITEM_MUTATION,
};
