import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { usePanel } from '../../../hooks';
import Backdrop from '../../../components/panel/backdrop';

export default () => {
  const [isShowing, setShowing] = useState(false);

  const { content, setContent } = usePanel();

  const backdropClicked = () => setContent(undefined);

  const toggleContent = () => {
    if (content) {
      document
        .getElementById('contextualizer')
        .classList.add('show-service-panel');
      setShowing(true);
    } else {
      document
        .getElementById('contextualizer')
        .classList.remove('show-service-panel');
      setShowing(false);
    }
  };

  useEffect(() => toggleContent(), [content]);

  return (
    <>
      <Backdrop show={isShowing} backdropClicked={backdropClicked} />
      <aside className="customizer" id="contextualizer">
        <PerfectScrollbar>
          <div className="customizer-body">{content}</div>
        </PerfectScrollbar>
      </aside>
    </>
  );
};
