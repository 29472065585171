import React, { useContext } from 'react';
import Select from 'react-select';
import _ from 'lodash';

import Skeleton from 'react-loading-skeleton';

import 'react-tagsinput/react-tagsinput.css';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';

import { GlobalReferenceContext } from '../../../contexts';
import { InputWrapper, DisplayType } from '../../budget/entry/types';
import { copyArrayReference, COST_TYPE } from '../../../common';
import { Button } from 'reactstrap';

const BudgetRow = ({
  budget,
  isNew,
  onChange = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  const { references, normalize } = useContext(GlobalReferenceContext);

  let updateState = (data) => {
    let budgetCopy = Object.assign(budget, data);
    budgetCopy['isDirty'] = true;
    onChange(budgetCopy, isNew);
  };

  const onInputChangeEvent = (e, validate) => {
    let val = e.value;

    /**
     * No spaces allowed
     */
    if (validate && val.includes(' ')) val = val.replace(/\s/g, '');

    let budgetCopy = copyArrayReference(budget);
    _.set(budgetCopy, e.cellKey, val);
    updateState(budgetCopy);
  };

  if (!references || !budget)
    return (
      <tr>
        <td colSpan="7">
          <Skeleton />
        </td>
      </tr>
    );

  return (
    <tr key={budget._id}>
      <td width="120px">
        <InputWrapper
          disabled={budget.locked}
          onInputChange={(e) => onInputChangeEvent(e, true)}
          cellKey="costCode.code"
          type={DisplayType.Text}
          value={budget.costCode.code}
        />
      </td>
      <td width="220px">
        <InputWrapper
          disabled={budget.locked}
          onInputChange={(e) => onInputChangeEvent(e, false)}
          cellKey="costCode.name"
          type={DisplayType.Text}
          value={budget.costCode.name}
        />
      </td>
      <td>
        <Select
          isDisabled={budget.locked}
          closeMenuOnSelect={true}
          defaultValue={{ label: budget.budgetCategory.name }}
          options={normalize('budget_category')}
          onChange={(e) =>
            updateState({ budgetCategory: { name: e.label, code: e.value } })
          }
        />
      </td>
      <td>
        <Select
          isDisabled={budget.locked}
          closeMenuOnSelect={true}
          defaultValue={COST_TYPE[0]}
          value={_.find(COST_TYPE, (i) => i.value === budget.type)}
          options={COST_TYPE}
          onChange={(e) => updateState({ type: e.value })}
        />
      </td>
      <td>
        <Select
          isDisabled={budget.locked}
          closeMenuOnSelect={true}
          defaultValue={{ label: budget.accountsPayableCategory.name }}
          options={normalize('accounts_payable')}
          onChange={(e) =>
            updateState({
              accountsPayableCategory: { name: e.label, code: e.value },
            })
          }
        />
      </td>
      <td>
        <Select
          isDisabled={budget.locked}
          closeMenuOnSelect={true}
          defaultValue={{ label: budget.fundingSourceCategory.name }}
          options={normalize('funding_source_category')}
          onChange={(e) =>
            updateState({
              fundingSourceCategory: { name: e.label, code: e.value },
            })
          }
        />
      </td>
      <td width="100px">
        {!budget.locked ? (
          <div className="float-right">
            {/* <Button className="btn-sm mr-2 mt-2" onClick={() => onEdit(budget)} color="info">
                            <i className={budget.unoperateable ? "fas fa-unlock" : "fas fa-lock"} />
                        </Button> */}
            <Button
              className="btn-sm mt-2"
              onClick={() => onDelete(budget)}
              color="warning"
            >
              <i className="fas fa-trash" />
            </Button>
          </div>
        ) : (
          <div />
        )}
      </td>
    </tr>
  );
};

export default BudgetRow;
