const currencify = (val, withSign = false, precision = undefined) => {
  // if(val == undefined || val == null || isNaN(val))
  //    return "";

  if (!val && isNaN) return '';

  // overrride forever
  withSign = false;

  if (precision === undefined || precision == null || isNaN(precision))
    precision = 0;

  const convertedValue = Number(Math.abs(val))
    .toFixed(precision)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const currencyValue = withSign ? `$${convertedValue}` : convertedValue;
  return currencyValue;
};

export default currencify;
