import React from 'react';
import { SafetyNumericInputStyles } from './styles/NumericInput';

const SafetyNumericInput = (props) => {
  return (
    <SafetyNumericInputStyles
      decimalSeparator="."
      decimalScale={2}
      thousandSeparator={true}
      {...props}
    />
  );
}

export default SafetyNumericInput;
