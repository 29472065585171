import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Table } from 'reactstrap';
import moment from 'moment';
import { useAlert } from 'react-alert';
import {
  calculateAggregatedCosts,
  stageEquals,
} from '@projx/models/dist/lib/utils/index';
import { FccFactory } from '@projx/models/dist/lib/domains/helpers/index';
import {
  DisplayWrapper,
  DisplayType,
  BudgetVariationItemSidePanel,
  BudgetVariationItemPanel,
  BudgetType,
  BudgetMenus,
  SwitchWrapper,
  disableForecast,
  Stage,
} from '..';
import {
  copyArrayReference,
  currencify,
  evaluateLineStatus,
  extractGraphqlError,
  disableOnSplit,
  updateArrayByKey,
} from '../../../../../common';

import { Panel } from '../../../..';

import {
  useSaveBudgetLine,
  usePanel,
  useDeleteBudget,
  useSplitVariation,
  useBudgetChildren,
} from '../../../../../hooks';

import { UserContext, BudgetConfigContext } from '../../../../../contexts';
import FormViewer from '../../../../forms/FormViewer';

import { CostAdjustmentApproval } from '../../../../forms';
import BudgetVariationSubitem from './BudgetVariationSubitem';

import AdditionalDetailsForm from '../AdditionalDetailsForm';

const BudgetVariationItem = ({
  project,
  view,
  details,
  item,
  onCalculationCompleted = () => {},
  onDelete = () => {},
}) => {
  const alert = useAlert();

  const { setContent } = usePanel();

  const { user } = useContext(UserContext);
  // This is to do with our currency decimals:
  const { config } = useContext(BudgetConfigContext);
  //

  const [entry, setEntry] = useState();
  const [childrenItems, setChildrenItems] = useState([]);
  const [contingencyDrawdown, setContingencyDrawdown] = useState(undefined);
  const [rowOpen, setRowOpen] = useState(false);
  const [splitOpen, setSplitOpen] = useState(false);
  const [forms, setForms] = useState({ approval: false });

  const [generated, setGenerated] = useState(undefined);

  const [lineStatus, setLineStatus] = useState('loaded');

  const [splitVariation] = useSplitVariation({
    onCompleted: (data) => {
      if (data) {
        //childrenItems.push(data);
        setChildrenItems(updateArrayByKey(data, childrenItems, '_id', true));
      }
    },
  });

  const [getBudgetChildren] = useBudgetChildren({
    onCompleted: (data) => {
      setChildrenItems(data);
    },
  });

  const [saveBudgetLine, { loading }] = useSaveBudgetLine({
    onCompleted: (data) => {
      setLineStatus('saved');
      const calculatedItem = calculate(data);
      _setEntry(calculatedItem);
      onCalculationCompleted(calculatedItem);
    },
  });

  const [deleteBudget] = useDeleteBudget({
    onCompleted: (data) => {
      if (data) onDelete(entry._id);
    },
  });

  const _setEntry = (item) => {
    setEntry(item);

    if (item.contingencyCostCode) {
      setContingencyDrawdown(
        project.costCodes.find(
          ({ costCode }) => costCode.code === item.contingencyCostCode,
        ),
      );
    }
  };

  const toggleRow = () => {
    setRowOpen(!rowOpen);
  };

  const toggleSplits = () => {
    if (!splitOpen) {
      getBudgetChildren(entry._id);
    }
    setSplitOpen(() => !splitOpen);
  };

  const onClick = async (type) => {
    switch (type) {
      case 'chat':
        updatePanel(entry);
        break;
      case 'upload':
        updatePanel(entry);
        break;
      case 'expand':
        toggleRow();
        break;
      case 'split-show':
        toggleSplits();
        break;
      case 'split':
        splitVariation(entry._id);
        break;
      case 'approval-form':
        setGenerated(
          <CostAdjustmentApproval
            project={project}
            template={{
              costCode: `${details.costCode.code} - ${details.costCode.name}`,
              vendorName: details.vendor.name,
              issueDate: moment().format('MMMM Do YYYY, h:mm a'),
              title: `${entry.ref} - ${entry.activity}`,
              type: entry.type.charAt(0).toUpperCase() + entry.type.slice(1),
              cause: entry.costCause.key,
              commitmentStatus: entry.commitmentStatus
                ? entry.commitmentStatus.charAt(0).toUpperCase() +
                  entry.commitmentStatus.slice(1)
                : '',
              noticeReferences: entry.noticeReferences,
              description: entry.description,
              submittedCost: entry.forecast.submitted,
              recommendedCost: entry.forecast.recommended,
              action: entry.action,
              sipmReview: entry.sipmReview.key,
              contingencyDrawdownLabel: contingencyDrawdown
                ? `${contingencyDrawdown.costCode.code} - ${contingencyDrawdown.costCode.name}`
                : undefined,
              contingencyDrawdownAmount: contingencyDrawdown
                ? contingencyDrawdown.currentAmount
                : undefined,
              comments: entry.comments,
            }}
          />,
        );

        toggleForm('approval');

        break;
      case 'delete':
        try {
          await deleteBudget(entry._id);
        } catch (e) {
          alert.error(extractGraphqlError(e));
        }
        break;
      default:
        break;
    }
  };

  const updatePanel = (updatedEntry) => {
    setContent(
      <Panel title="Budget Variations" subtitle={updatedEntry.phase}>
        <BudgetVariationItemSidePanel item={updatedEntry} />
      </Panel>,
    );
  };

  const calculate = (entry) => {
    if (childrenItems.length > 0) {
      entry.stage = stageEquals(childrenItems, ['approved', 'contracted'])
        ? 'approved'
        : 'split';
      entry.fcc = _.sumBy(childrenItems, (child) =>
        FccFactory.getFcc(child).calculate(),
      );
      entry = calculateAggregatedCosts(entry, childrenItems);
    }

    return entry;
  };

  const validateExpenditureAssessed = (val) => {
    if (!val) return { valid: true };

    if (val > entry.expenditure.allowed)
      return {
        valid: false,
        message: `Claim amount exceeds the allowed to pay amount of ${currencify(
          entry.expenditure.allowed,
          true,
        )}`,
      };

    return {
      valid: true,
    };
  };

  const onInputChangeEvent = (e) => {
    let copyEntry = e.budget
      ? copyArrayReference(e.budget)
      : copyArrayReference(entry);

    if (!e.budget) _.set(copyEntry, e.cellKey, e.value);

    if (e.type === DisplayType.Number || e.type === DisplayType.Currency) {
      copyEntry = calculate(copyEntry);
      onCalculationCompleted(copyEntry);
    }
    copyEntry.history = e.history ? e.history : copyEntry.history;

    _setEntry(copyEntry);
    setLineStatus(e.error ? 'error' : e.autoSave ? 'saved' : 'dirty');
  };

  const saveVariation = async (item) => {
    try {
      await saveBudgetLine(item);
      setRowOpen(false);
    } catch (e) {
      setLineStatus('error');
    }
  };

  const save = () => saveVariation(entry);

  const toggleForm = (type) => {
    setForms((prev) => {
      const isVisible = !prev[type];
      if (!isVisible) setGenerated(undefined);
      return {
        ...prev,
        [type]: isVisible,
      };
    });
  };

  const onSubitemCalculationCompleted = (subItem) => {
    setChildrenItems(updateArrayByKey(subItem, childrenItems));
  };

  useEffect(() => {
    if (!entry || !childrenItems) return;

    let calculated = calculate(entry, true);

    _setEntry(calculated);
    onCalculationCompleted(calculated);
  }, [childrenItems]);

  useEffect(() => {
    if (item.isNew) saveVariation(item);
    else {
      const calculatedItem = calculate(item);
      _setEntry(calculatedItem);
      onCalculationCompleted(calculatedItem);
      setLineStatus('loaded');
    }
  }, [item]);

  return entry ? (
    <>
      {generated && (
        <FormViewer
          key="frmCostAdjustmentApproval"
          toggle={() => toggleForm('approval')}
          open={forms.approval}
          generated={generated}
        />
      )}
      <tr disabled={loading} className="popup-menu-trigger">
        <th className={evaluateLineStatus(lineStatus)} />
        <td className="buttons-menu">
          <div className="popup-menu mt-1 ml-2">
            <BudgetMenus
              onClick={onClick}
              item={entry}
              type={BudgetType.Variation}
              splitOpen={splitOpen}
            />
          </div>
        </td>
        <td className="created-by">
          <div className="mt-1">
            <img
              src={entry.modifiedBy ? entry.modifiedBy.image : user.image}
              alt="user"
              className="rounded-circle"
              width="20"
            />
          </div>
        </td>
        <td className="reference">
          <SwitchWrapper
            onInputChange={onInputChangeEvent}
            disabled={false}
            width="150px"
            cellKey="ref"
            value={entry.ref}
            type={DisplayType.Text}
            budget={entry}
            autoSave
            maxLength={10}
            placeholder="[REF]"
          />
        </td>
        <td className="text">
          <SwitchWrapper
            // NOTE: Activity field should not be disabled when contract is locked for variation items:
            // disabled={details.locked}
            onInputChange={onInputChangeEvent}
            width="150px"
            cellKey="phase"
            value={entry.phase}
            type={DisplayType.Text}
            budget={entry}
            autoSave
            maxLength={100}
            placeholder="[ACTIVITY]"
          />
        </td>
        <td className="stage align-middle">
          <Stage cost={entry} />
          {/* {!currentStage ? (
            <div />
          ) : (
            <span className={`ml-3 badge badge-${currentStage.color}`}>
              {currentStage.name}
            </span>
          )} */}
        </td>
        {view === 'simple' && (
          <td className="currency ffc-display">
            <DisplayWrapper
              onInputChange={onInputChangeEvent}
              cellKey="fcc"
              value={entry.fcc}
              type={DisplayType.Currency}
              preicison={config.decimal ? 2 : 0}
            />
          </td>
        )}
        {view === 'detailed' && (
          <>
            <td className="currency forecast-light">
              {disableForecast(entry, 'anticipated') ||
              disableOnSplit(entry) ? (
                <DisplayWrapper
                  onInputChange={onInputChangeEvent}
                  cellKey="forecast.anticipated"
                  value={entry.forecast.anticipated}
                  type={DisplayType.Currency}
                  precision={config.decimal ? 2 : 0}
                  disabled
                />
              ) : (
                <SwitchWrapper
                  onInputChange={onInputChangeEvent}
                  cellKey="forecast.anticipated"
                  value={entry.forecast.anticipated}
                  type={DisplayType.Currency}
                  allowNegative
                  budget={entry}
                  autoSave
                  precision={config.decimal ? 2 : 0}
                />
              )}
            </td>
            <td className="currency forecast-light">
              {disableForecast(entry, 'submitted') || disableOnSplit(entry) ? (
                <DisplayWrapper
                  onInputChange={onInputChangeEvent}
                  cellKey="forecast.submitted"
                  value={entry.forecast.submitted}
                  type={DisplayType.Currency}
                  precision={config.decimal ? 2 : 0}
                  disabled
                />
              ) : (
                <SwitchWrapper
                  onInputChange={onInputChangeEvent}
                  cellKey="forecast.submitted"
                  value={entry.forecast.submitted}
                  type={DisplayType.Currency}
                  allowNegative
                  budget={entry}
                  autoSave
                  precision={config.decimal ? 2 : 0}
                />
              )}
            </td>
            <td className="currency forecast-light">
              {disableForecast(entry, 'recommended') ||
              disableOnSplit(entry) ? (
                <DisplayWrapper
                  onInputChange={onInputChangeEvent}
                  cellKey="forecast.recommended"
                  value={entry.forecast.recommended}
                  type={DisplayType.Currency}
                  precision={config.decimal ? 2 : 0}
                  disabled
                />
              ) : (
                <SwitchWrapper
                  onInputChange={onInputChangeEvent}
                  cellKey="forecast.recommended"
                  value={entry.forecast.recommended}
                  type={DisplayType.Currency}
                  allowNegative
                  budget={entry}
                  autoSave
                  precision={config.decimal ? 2 : 0}
                />
              )}
            </td>
            <td
              className={details.locked ? 'currency locked' : 'currency'}
              style={{ cursor: 'not-allowed' }}
            >
              <DisplayWrapper
                cellKey="committed.empty2"
                value=""
                type={DisplayType.Empty}
              />
            </td>
            <td
              className={details.locked ? 'currency locked' : 'currency'}
              style={{ cursor: 'not-allowed' }}
            >
              <DisplayWrapper
                cellKey="committed.empty1"
                value=""
                type={DisplayType.Empty}
              />
            </td>
            <td className="currency">
              <SwitchWrapper
                disabled={disableOnSplit(entry)}
                onInputChange={onInputChangeEvent}
                cellKey="committed.approved"
                value={entry.committed.approved}
                type={DisplayType.Currency}
                allowNegative
                budget={entry}
                autoSave
                precision={config.decimal ? 2 : 0}
              />
            </td>
            <td className="currency ffc-display">
              <DisplayWrapper
                onInputChange={onInputChangeEvent}
                cellKey="fcc"
                value={entry.fcc}
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
            <td className="currency expenditure">
              <DisplayWrapper
                cellKey="expenditure.current.amount"
                value={
                  entry.expenditure.current
                    ? entry.expenditure.current.amount
                    : 0
                }
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
          </>
        )}
        {view === 'claims' && (
          <>
            <td className="currency expenditure">
              <DisplayWrapper
                cellKey="expenditure.allowed"
                value={entry.expenditure.allowed}
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
            <td className="currency expenditure">
              <DisplayWrapper
                cellKey="expenditure.previous"
                value={
                  entry.expenditure.previous.length > 0
                    ? _.sumBy(entry.expenditure.previous, (e) => e.amount)
                    : 0
                }
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
            <td className="currency">
              {entry.childCount > 0 ? (
                <DisplayWrapper
                  cellKey="expenditure.current.amount"
                  value={
                    entry.expenditure.current
                      ? entry.expenditure.current.amount
                      : ''
                  }
                  type={DisplayType.Currency}
                  precision={config.decimal ? 2 : 0}
                />
              ) : (
                <SwitchWrapper
                  disabled={
                    !details.locked ||
                    entry.expenditure.allowed == null ||
                    entry.expenditure.allowed === undefined ||
                    entry.expenditure.allowed === 0 ||
                    entry.childCount > 0
                  }
                  onInputChange={onInputChangeEvent}
                  cellKey="expenditure.current.amount"
                  value={
                    entry.expenditure.current
                      ? entry.expenditure.current.amount
                      : ''
                  }
                  type={DisplayType.Currency}
                  budget={entry}
                  autoSave
                  precision={config.decimal ? 2 : 0}
                  validation={validateExpenditureAssessed}
                  allowNegative
                />
              )}
            </td>
            <td className="currency expenditure">
              <DisplayWrapper
                cellKey="expenditure.thisClaim"
                value={entry.expenditure.thisClaim}
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
            <td className="currency expenditure-header">
              <DisplayWrapper
                cellKey="expenditure.remaining"
                value={entry.expenditure.remaining}
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
          </>
        )}
      </tr>
      {!entry.parentId && childrenItems.length > 0 && (
        <tr
          disabled={loading}
          className={!splitOpen ? 'budget-panel' : 'budget-panel open'}
        >
          <th className={evaluateLineStatus(lineStatus)}></th>
          <td colSpan={17}>
            <Table key="bleh123" size="sm" hover className="budget-entry">
              <tbody>
                {_.map(childrenItems, (child) => (
                  <BudgetVariationSubitem
                    project={project}
                    view={view}
                    details={details}
                    item={child}
                    onCalculationCompleted={onSubitemCalculationCompleted}
                    onDelete={onDelete}
                    key={item._id + '_' + child._id}
                  />
                ))}
              </tbody>
            </Table>
          </td>
        </tr>
      )}
      <AdditionalDetailsForm
        onSave={save}
        toggle={toggleRow}
        open={rowOpen}
        item={entry}
        loading={loading}
        details={details}
      >
        <BudgetVariationItemPanel
          onInputChange={onInputChangeEvent}
          project={project}
          item={entry}
        />
      </AdditionalDetailsForm>
    </>
  ) : (
    <tr>
      <th colSpan="11" />
    </tr>
  );
};

export default BudgetVariationItem;
