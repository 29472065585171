import React, { useState } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardTitle,
  Collapse,
} from 'reactstrap';
import { InputWrapper, DisplayType } from '../Shared';
import Select from 'react-select';

import { useCreateBudgetFieldItem } from '../../../../../hooks';
import { DocumentForm } from '../../../../';
import { letDocumentCategoryOptions } from '../../../../../helpers/';

const options = [
  { value: 'recommended', label: 'Recommended' },
  { value: 'approved', label: 'Approved' },
];

const INITIAL_DOCUMENT_FORM_STATE = {
  document: null,
  title: '',
  notes: '',
  category: '',
};

// Determine of user has modified any of the document form
const _hasFilledDocumentForm = (documentFormState) => {
  return (
    JSON.stringify(documentFormState) !==
    JSON.stringify(INITIAL_DOCUMENT_FORM_STATE)
  );
};

const LetEntry = ({ onLet = () => {}, toggle = () => {}, open, item }) => {
  const [value, setValue] = useState(undefined);
  const [state, setState] = useState('recommended');
  const [notes, setNotes] = useState('');

  // Document upload
  const [documentFormState, setDocumentFormState] = useState(
    INITIAL_DOCUMENT_FORM_STATE,
  );
  const [isDocumentSectionOpen, setDocumentSection] = useState(false);

  const toggleDocumentSection = () =>
    setDocumentSection(!isDocumentSectionOpen);

  const [createBudgetFieldItem, { loading }] = useCreateBudgetFieldItem({
    onCompleted: (data) => {
      onLet(data);
      clear();
      toggle();
    },
  });

  const clear = () => {
    setValue(undefined);
    setState('recommended');
    setNotes('');
    setDocumentFormState(INITIAL_DOCUMENT_FORM_STATE);
  };

  const createLetItem = () => {
    createBudgetFieldItem(item._id, {
      value,
      state,
      notes,
      documentInput: _hasFilledDocumentForm(documentFormState)
        ? documentFormState
        : undefined,
    });
  };

  return (
    <Modal isOpen={open} toggle={toggle} backdrop={true}>
      <ModalHeader toggle={toggle}>
        <i className="fas fas-dollar-sign" /> New Let Amount for{' '}
        <code>{item.ref}</code>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Row>
            <Label sm="3">Amount ($)</Label>
            <Col sm="3">
              <InputWrapper
                onInputChange={(data) => setValue(data.value)}
                width="50px"
                cellKey={'value'}
                value={value}
                type={DisplayType.Currency}
                precision={2}
                allowNegative={true}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Label sm="3">State</Label>
            <Col sm="5">
              <Select
                closeMenuOnSelect={true}
                defaultValue={{ label: 'Recommended' }}
                options={options}
                onChange={(e) => setState(e.value)}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Label sm="3">Notes</Label>
            <Col sm="9">
              <InputWrapper
                onInputChange={(data) => setNotes(data.value)}
                width="150px"
                cellKey={'notes'}
                value={notes}
                type={DisplayType.TextArea}
              />
            </Col>
          </Row>
        </FormGroup>
        <CardTitle onClick={toggleDocumentSection}>
          <i
            className={
              isDocumentSectionOpen
                ? 'fas fa-chevron-circle-down'
                : 'fas fa-chevron-circle-right'
            }
          ></i>
          {'  '}Attach Document (optional)
        </CardTitle>
        <Collapse isOpen={isDocumentSectionOpen}>
          <DocumentForm
            documentFormState={documentFormState}
            setDocumentFormState={setDocumentFormState}
            categoryOptions={letDocumentCategoryOptions}
          />
        </Collapse>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!value || loading}
          className="float-right"
          color="primary"
          onClick={createLetItem}
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LetEntry;
