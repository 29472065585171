import gql from 'graphql-tag';
import _ from 'lodash';

const BUDGET_BY_PROJRCT_KEY_QUERY = gql`
  query BudgetSettings($id: String!) {
    budgetSettingsByKey(id: $id) {
      id
      projectName
    }
  }
`;

const mapper = (data) => {
  return data;
};

const useMutation = (query, { variables }) => {
  const data = _.find([], (budget) => budget.key === variables.key);

  return {
    loaded: true,
    error: data ?? Error(`${variables.key} doesn't exist`),
    data: {
      budgetSettingsByKey: data ?? undefined,
    },
  };
};

const UseBudgetSettingsQuery = (key) => {
  const { loaded, error, data } = useMutation(BUDGET_BY_PROJRCT_KEY_QUERY, {
    variables: { key },
  });

  let budgetSettings = loaded && data ? data.budgetSettingsByKey : {};
  budgetSettings = mapper(budgetSettings);

  return { loaded, error, budgetSettings };
};

export default UseBudgetSettingsQuery;
