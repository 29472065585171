import React from 'react';
import {
  EditWidgetButtonStyles,
  EditWidgetButtonText,
  EditWidgetButtonPencilIcon,
  EditWidgetButtonXIcon
} from './styles/EditWidgetButton';

/**
 * A component which renders the button to edit a widget on our dashboard
 * @param {boolean} inEditMode Indicates whether the widgets are in editing mode 
 * @param {function} onClick The onClick handler for our button which changes state from editing to not editing
 * @returns {React.JSX} A Button which we can click to edit widgets
 */
const EditWidgetButton = ({ inEditMode, onClick }) => {
  return (
    <EditWidgetButtonStyles onClick={onClick}>
      {inEditMode ? <EditWidgetButtonXIcon />: <EditWidgetButtonPencilIcon/>}
      <EditWidgetButtonText>
        {inEditMode ? 'Stop Editing' : 'Edit Widgets'}
      </EditWidgetButtonText>
    </EditWidgetButtonStyles>
  );
}

export default EditWidgetButton;