import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from '../views/authentication/login';
import Register from '../views/authentication/register';
import Invitation from '../views/authentication/invitation';
import AuthContext from '../contexts/auth/auth.context';
import ResetPassword from '../views/authentication/reset-password';

const UnauthenticatedRoutes = ({ location, history }) => {
  const { authenticated } = useContext(AuthContext);

  if (authenticated) return null;

  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/authentication/login" component={Login} />
      <Route path="/authentication/reset-password" component={ResetPassword} />
      <Route path="/authentication/register" component={Register} />
      <Route path="/authentication/invitation">
        {location.search.includes('token') ? (
          <Invitation location={location} history={history} />
        ) : (
          <Redirect to="/authentication/register" />
        )}
      </Route>
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default UnauthenticatedRoutes;
