import gql from 'graphql-tag';

const UPLOAD_IMAGE_MUTATION = gql`
  mutation ($type: String!, $image: String!, $identifier: ID) {
    uploadImage(type: $type, image: $image, identifier: $identifier) {
      url
      type
    }
  }
`;

export { UPLOAD_IMAGE_MUTATION };
