import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ModalFooter,
} from 'reactstrap';
import PermissionTable from './PermissionTable';
import { useUpdateRole } from '../../../hooks';

const RoleModify = ({ role, setCustomRoles }) => {
  const [modal, setModal] = useState(false);
  const [roleName, setRoleName] = useState(role.name);
  const [permissions, setPermissions] = useState(
    role.permissions.map((permission) => ({
      id: `${permission.group}${permission.name}${permission.action}`,
      permission,
    })),
  );
  const [updateRole] = useUpdateRole();

  const toggle = () => {
    setModal(!modal);
  };

  const updateCustomRole = () => {
    const updatedRole = {
      name: roleName,
      permissions: permissions.map(({ permission }) => permission),
    };

    updateRole(role._id, updatedRole)
      .then(() =>
        setCustomRoles((existingRoles) =>
          existingRoles.map((existingRole) =>
            existingRole._id === role._id
              ? { _id: role._id, ...updatedRole }
              : role,
          ),
        ),
      )
      .catch((err) => console.error(err));
    setModal(!modal);
  };

  return (
    <div>
      <Button onClick={toggle}>
        <i className="fa fa-edit" />
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Edit role</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Enter a role name"
              onChange={(event) => setRoleName(event.target.value)}
              value={roleName}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Permissions</Label>
          </FormGroup>
          <PermissionTable
            modify
            permissions={permissions}
            setPermissions={setPermissions}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={updateCustomRole}>
            Create
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RoleModify;
