import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress'; // Not using react strap as buggy, it renders nothing...
import DocumentForm from './DocumentForm';

const INITIAL_DOCUMENT_FORM_STATE = {
  document: null,
  title: '',
  notes: '',
  category: '',
};

const DocumentUpload = ({
  onDocumentUpload,
  loading,
  isOpen,
  toggle,
  categoryOptions,
}) => {
  const [documentFormState, setDocumentFormState] = useState(
    INITIAL_DOCUMENT_FORM_STATE,
  );

  const isButtonDisabled =
    loading ||
    !documentFormState.document ||
    !documentFormState.title ||
    !documentFormState.category;

  const _buttonOnClick = () => onDocumentUpload(documentFormState);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>Upload Document</ModalHeader>
      <ModalBody>
        <DocumentForm
          documentFormState={documentFormState}
          setDocumentFormState={setDocumentFormState}
          categoryOptions={categoryOptions}
        />
      </ModalBody>
      <ModalFooter>
        <div style={{ display: 'flex' }}>
          <Button
            style={{ marginLeft: 'auto' }}
            color="projx"
            onClick={_buttonOnClick}
            disabled={isButtonDisabled}
          >
            {loading ? <CircularProgress color="white" size={18} /> : 'Upload'}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DocumentUpload;
