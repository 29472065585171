import { useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import {
  CREATE_FOCOTR_MUTATION,
  GET_FOCOTR_BY_PROJECT_AND_COST_QUERY,
  UPDATE_FOCOTR_MUTATION,
  CODE_HAS_ASSOCIATED_COMMITMENTS_QUERY,
  ADD_FOCOTR_DOCUMENT_MUTATION,
  FOCOTR_INVOICES_QUERY,
  CAN_DELETE_FOCOTR_QUERY,
  DELETE_FOCOTR_MUTATION,
  ADD_FOCOTR_INVOICE_DOCUMENT_MUTATION,
  UPDATE_FOCOTR_ORDER_MUTATION,
} from '../../graphql/queries';
import { toBase64 } from '../../common/utils';

import { cleanGqlTypename } from '../../common';

const ITEM_TEMPLATE = {
  _id: '',
  costCode: {
    code: '',
    name: '',
  },
  locked: false,
  project: undefined,
  vendor: undefined,
  createdBy: undefined,
  modifiedBy: undefined,
  accountType: '',
  invoices: [],
  order: undefined,
};

const itemMapper = (original) => {
  let focotrCopy = _.cloneDeep(ITEM_TEMPLATE);

  focotrCopy._id = original._id;
  focotrCopy.costCode = original.costCode;
  focotrCopy.locked = original.locked;
  focotrCopy.project = original.project;
  focotrCopy.vendor = original.vendor;
  focotrCopy.createdBy = original.createdBy;
  focotrCopy.modifiedBy = original.modifiedBy;
  focotrCopy.invoices = original.invoices ? original.invoices : [];
  focotrCopy.documents = original.documents;
  focotrCopy.accountType = original.accountType;
  focotrCopy.order = original.order ? original.order : 1;

  return focotrCopy;
};

const saveItemMapper = (item) => cleanGqlTypename(_.cloneDeep(item));

const mapper = (items) => items.map((original) => itemMapper(original));

const invoiceItemMapper = (item) => cleanGqlTypename(item);
const invoiceMapper = (items) =>
  _.map(items, (item) => invoiceItemMapper(item));

const emptyFocotrItem = ({ locked, project, vendor, costCode }) => {
  let clone = _.cloneDeep(ITEM_TEMPLATE);
  clone.costCode = costCode;
  clone.locked = locked;
  clone.project = project;
  clone.vendor = vendor;
  delete clone.createdBy;
  delete clone.modifiedBy;
  delete clone.order;
  return clone;
};

const useFocotrOrder = ({ onCompleted = () => {} }) => {
  const [_updateFocotrOrder, { data, loading, error }] = useMutation(
    UPDATE_FOCOTR_ORDER_MUTATION,
    {
      onCompleted: (data) => onCompleted(_.get(data, 'updateOrder')),
    },
  );

  const updateFocotrOrder = (orders) => {
    return _updateFocotrOrder({
      variables: {
        orders,
      },
    });
  };

  let updateOrder = !loading && data ? _.get(data, 'updateOrder') : false;

  return [updateFocotrOrder, { updateOrder, loading, error }];
};

const useFocotrByCost = ({ costCode, projectId, onCompleted = () => {} }) => {
  let [getFocotrByCost, { loading, error, refetch }] = useLazyQuery(
    GET_FOCOTR_BY_PROJECT_AND_COST_QUERY,
    {
      variables: {
        costCode,
        projectId,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) =>
        onCompleted(
          mapper(cleanGqlTypename(_.get(data, 'getFocotrsByProjectAndCost'))),
        ),
    },
  );

  return {
    loading,
    error,
    getFocotrByCost,
    refetchFocotr: refetch,
    emptyFocotrItem,
  };
};

const useFocotrInvoices = ({ onCompleted = () => {} }) => {
  let [_getFocotrInvoices, { loading, error, refetch }] = useLazyQuery(
    FOCOTR_INVOICES_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) =>
        onCompleted(invoiceMapper(_.get(data, 'getInvoices'))),
    },
  );

  const getFocotrInvoices = (focotrId) => {
    return _getFocotrInvoices({
      variables: {
        _id: focotrId,
      },
    });
  };

  return { loading, error, getFocotrInvoices, refetchAssociation: refetch };
};

const useFocotrHasAssociatedCommitments = ({ onCompleted = () => {} }) => {
  let [_getAssociation, { loading, error, refetch }] = useLazyQuery(
    CODE_HAS_ASSOCIATED_COMMITMENTS_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) =>
        onCompleted(_.get(data, 'codeHasAssociatedCommitments')),
    },
  );

  const getAssociation = (projectId, code) => {
    return _getAssociation({
      variables: {
        projectId,
        code,
      },
    });
  };

  return { loading, error, getAssociation, refetchAssociation: refetch };
};

const useFocotrCanDelete = ({ onCompleted = () => {} }) => {
  let [_canDelete, { loading, error }] = useLazyQuery(CAN_DELETE_FOCOTR_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => onCompleted(_.get(data, 'canDeleteFocotr')),
  });

  const canDelete = (focotrId) => {
    return _canDelete({
      variables: {
        _id: focotrId,
      },
    });
  };

  return { loading, error, canDelete };
};

const useCreateFocotr = ({ onCompleted = () => {} }) => {
  const [_createFocotr, { data, loading, error }] = useMutation(
    CREATE_FOCOTR_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(itemMapper(_.get(data, 'createFocotr'))),
    },
  );

  const createFocotr = (focotrItem) => {
    return _createFocotr({
      variables: {
        input: saveItemMapper(focotrItem),
      },
    });
  };

  let focotrItem =
    !loading && data ? itemMapper(_.get(data, 'createFocotr')) : undefined;

  return [createFocotr, { focotrItem, loading, error }];
};

const useDeleteFocotr = ({ onCompleted = () => {} }) => {
  const [_deleteFocotr, { data, loading, error }] = useMutation(
    DELETE_FOCOTR_MUTATION,
    {
      onCompleted: (data) => onCompleted(_.get(data, 'deleteFocotr')),
    },
  );

  const deleteFocotr = (focotrId) => {
    return _deleteFocotr({
      variables: {
        _id: focotrId,
      },
    });
  };

  let canDelete = !loading && data ? _.get(data, 'deleteFocotr') : false;

  return [deleteFocotr, { canDelete, loading, error }];
};

const useUpdateFocotr = ({ onCompleted = () => {} }) => {
  const [_updateFocotr, { data, loading, error }] = useMutation(
    UPDATE_FOCOTR_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(itemMapper(_.get(data, 'updateFocotr'))),
    },
  );

  const updateFocotr = (id, focotrItem) => {
    return _updateFocotr({
      variables: {
        _id: id,
        input: saveItemMapper(focotrItem),
      },
    });
  };

  let focotrItem =
    !loading && data ? itemMapper(_.get(data, 'updateFocotr')) : undefined;

  return [updateFocotr, { focotrItem, loading, error }];
};

const useAddFocotrDocument = ({ onCompleted = () => {} }) => {
  const [_addFocotrDocument, { data, loading, error }] = useMutation(
    ADD_FOCOTR_DOCUMENT_MUTATION,
    {
      onCompleted: (data) => onCompleted(_.get(data, 'addFocotrDocument')),
    },
  );

  const addFocotrDocument = (_id, documentInput) => {
    return toBase64(documentInput.document).then((documentBase64) => {
      return _addFocotrDocument({
        variables: {
          _id,
        },
      });
    });
  };

  const document =
    !loading && data ? _.get(data, 'addFocotrDocument') : undefined;

  return [addFocotrDocument, { document, loading, error }];
};

const useAddFocotrInvoiceDocument = ({ onCompleted = () => {} }) => {
  const [_addFocotrInvoiceDocument, { data, loading, error }] = useMutation(
    ADD_FOCOTR_INVOICE_DOCUMENT_MUTATION,
    {
      onCompleted: (data) => {
        onCompleted(_.get(data, 'addFocotrInvoiceDocument'));
      },
    },
  );

  const addFocotrInvoiceDocument = (_id, invoiceId, documentInput) => {
    return toBase64(documentInput.document).then((documentBase64) => {
      return _addFocotrInvoiceDocument({
        variables: {
          _id,
          invoiceId,
          documentInput: _.assign({}, documentInput, {
            document: documentBase64,
          }),
        },
      });
    });
  };

  const document =
    !loading && data ? _.get(data, 'addFocotrInvoiceDocument') : undefined;

  return [addFocotrInvoiceDocument, { document, loading, error }];
};

export {
  useFocotrByCost,
  useCreateFocotr,
  useUpdateFocotr,
  useFocotrHasAssociatedCommitments,
  useAddFocotrDocument,
  useFocotrInvoices,
  useFocotrCanDelete,
  useDeleteFocotr,
  useAddFocotrInvoiceDocument,
  useFocotrOrder,
};
