import styled from 'styled-components';
import { FiEdit2, FiX } from 'react-icons/fi';

export const EditWidgetButtonStyles = styled.button`
  display: flex;
  align-items: center;
  background: #1B1B1B;
  border-radius: 35px;
  font-size: 16px;
  margin: 50px auto 0 auto;
  padding: 14px 16px;
  border: 0;
  color: white;
  cursor: pointer;
  opacity: 0.8;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const EditWidgetButtonText = styled.p`
  margin: 0 0 0 5px;
`;

export const EditWidgetButtonPencilIcon = styled(FiEdit2)`
  width: 18px;
  height: 18px;
  padding-bottom: 2px;
`;

export const EditWidgetButtonXIcon = styled(FiX)`
  width: 20px;
  height: 20px;
`;
