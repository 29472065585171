import styled, { css } from 'styled-components';
import { GrEdit } from 'react-icons/gr';

export const EditProgressDiaryLineItemButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const EditProgressDiaryLineItemButtonStyles = styled.button`
  background-color: #F9F9F9;
  border: none;
  width: 35px;
  height: 35px;
  margin: 0px 2px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${props => props.show === false && css`
    visibility: hidden;
  `}
`;

export const EditIcon = styled(GrEdit)`
  width: 24px;
  height: 24px;
  color: #1B1B1B;
`;