import styled, { css } from "styled-components";
import DatePicker from 'react-datepicker';

export const AccordionContainer = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  margin-bottom: 8px;

  ${(props) => props.open && css`
    max-height: 380px;
    height: 380px;
  `}
`;

export const FilterDatePicker = styled(DatePicker)`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  height: 36px;
  width: 100%;
  padding-left: 8px;
  margin: 0;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
`;

export const DateSeparationText = styled.p`
  font-size: 16px;
  margin: 0 8px;
`;

export const Separator = styled.div`
  margin: 0 4px;
`;
