import React, { useContext, useState, useEffect } from 'react';
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Label,
  Input,
  Row,
  Col,
  Badge,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';

import { useCashflow } from '../../hooks';
import { formatCashflow, defaultCashflowState, keyifyPeriods, nullifyValues } from '.';
import {
  DisplayType,
  DisplayWrapper,
  InputWrapper,
  SwitchWrapper,
} from '../budget/entry/types';
import TypedEstimateEntry from './TypedEstimateEntry';
import SCurve from './curves/SCurve';

import { BudgetConfigContext } from '../../contexts';
import Skeleton from 'react-loading-skeleton';

import CashflowMatrix from "./CashflowMatrix";

const CashflowTableCustom = ({ project, code }) => {
  const [cashflow, setCashflow] = useState({ ...defaultCashflowState });

  const [queryPeriod, setQueryPeriod] = useState({
    startDate: moment(Date.now()).format("YYYY-MM-DD"),
    window: 12
  });

  const [cashflowSummary, setCashflowSummary] = useState(undefined)
  const [isHidden, setIsHidden] = useState(true);

  const {
    Actions,
    getCashflows,
    getCashflowsLoading
  } = useCashflow({
    onCompleted: ({ action, data }) => {
      if ([Actions.GetCashflows].includes(action))
        setCashflowSummary(data);
    },
  });

  const onQueryChangeHandler = async (query) => {
    try {
      getCashflows(project._id, {
        ...query
      });
      setQueryPeriod({
        startDate: query.startDate,
        window: query.window
      });
    } catch { }
  };

  useEffect(() => {
    try {
      getCashflows(project._id, {
        ...queryPeriod
      });
    } catch (e) {
      setCashflowSummary(undefined);
    }
  }, []);

  if (!cashflowSummary) return <Skeleton />

  return (
    <>
      <Card>
        <CardHeader>Cashflow</CardHeader>

        <CardBody>
          <Row>
            <Col md="12">
              <PeriodFilter
                cashflow={cashflow}
                onQueryChange={onQueryChangeHandler}
              />
            </Col>
          </Row>
        </CardBody>

        <CardBody className="border-top mb-2">

          <Table size="sm" hover className="budget-entry">
            <ProjectionPeriodHeadings
              onClick={() => setIsHidden(!isHidden)}
              columns={cashflowSummary.columns}
              isHidden={isHidden}
            />
            <GroupByBudget queryPeriod={queryPeriod} summary={cashflowSummary} isHidden={isHidden} />
          </Table>

        </CardBody>
      </Card>
    </>
  );
};


const FormatCurrency = ({ val }) => {
  return (
    <td className={`currency ${val !== 0 ? "cashflow-indicator-notok" : "cashflow-indicator-ok"}`}>
      {val !== 0 ? (
        <DisplayWrapper
          value={val}
          type={DisplayType.Currency}
          precision={0} />
      ) : (
        <div className="pt-1" style={{ textAlign: "center" }}>
          <i className={`fas fa-check-circle`}></i>
        </div>

      )}
    </td>
  );
};


{/* <DisplayWrapper
                  cellKey={`${key}-previous-expanded`}
                  value={item.projectionFactors.total}
                  type={DisplayType.Currency}
                  precision={0}
                /> */}

const ToggleMore = ({ isHidden, onClick = () => { } }) => {
  return (
    <Label className="font-14 text" style={{ cursor: "pointer" }} onClick={onClick}>
      {isHidden ? "More " : "Less "}<i
        className={
          isHidden
            ? 'fas fa-chevron-circle-right'
            : 'fas fa-chevron-circle-left'
        }
      />
    </Label>
  );
};

const ProjectionTypeToggle = ({ title, isHidden }) => {
  return (
    <Label className="font-12 text ml-5">
      <i
        className={
          !isHidden
            ? 'fas fa-chevron-circle-down'
            : 'fas fa-chevron-circle-right'
        }
      />{' '}
      {title}
    </Label>
  );
};

const ProjectionBy = ({ by }) => {
  const evaluateBy = () => {
    switch (by) {
      case "budget": return "projx";
      case "forecast": return "info";
      case "approved": return "success";
      case "committed": return "success";
      default: return "default"
    }
  }
  return (
    <td className="currency">
      <Badge className="mt-2" color={evaluateBy()}>{by}</Badge>
    </td>
  )
}

const GroupByBudget = ({ queryPeriod, summary, isHidden }) => {

  const { items, columns } = summary;

  const groupIt = (items) => {
    let groups = _.groupBy(items, (item) => item.budgetCategory.name);
    return groups;
  }
  return (
    <tbody>
      {_.map(groupIt(items), (group, key) => (
        <>
          <tr>
            <th className="p-2 cashflow-category-header" colSpan={isHidden ? columns.length + 2 : columns.length + 5}>{key}</th>
          </tr>
          <ProjectionPeriods queryPeriod={queryPeriod} items={group} isHidden={isHidden} />
        </>
      ))}
    </tbody>
  )
}

const ProjectionPeriods = ({ queryPeriod, items, isHidden }) => {
  return (
    <>
      {_.map(items, (item, key) => (
        <ProjectionItem queryPeriod={queryPeriod} item={item} key={key} isHidden={isHidden} />
      ))}
    </>
  )
}

const ProjectionItem = ({ queryPeriod, item, key, isHidden }) => {

  const [summaryItem, setSummaryItem] = useState(item);
  const [isEditable, setIsEditable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setSummaryItem(item);
  }, [item])

  return (
    <>
      <tr onClick={() => setIsExpanded(!isExpanded)} style={{ cursor: "pointer" }}>
        {isHidden ? (
          <td className="text pl-3 font-12 cashflow-code-stretch">
            <Label className="font-12 text ml-4 mt-1">
              {`(${summaryItem.costCode.code}) ${summaryItem.costCode.name}`}
            </Label>
          </td>
        ) : (
          <>
            <td className="text pl-3 font-12 cashflow-code-stretch">
              <Label className="font-12 text ml-4 mt-1">
                {`(${summaryItem.costCode.code}) ${summaryItem.costCode.name}`}
              </Label>
            </td>
            <ProjectionBy by={summaryItem.projectionBy} />
            <FormatCurrency val={summaryItem.projectionFactors.revision} />
            <FormatCurrency val={summaryItem.projectionFactors.difference} />
          </>
        )}
        {_.map(keyifyPeriods(summaryItem.periods), (period, idx) => (
          <>
            {isEditable ? (
              <td
                className={'currency period-subheader-editable'}
              >
                <div style={{ cursor: 'pointer !important' }}>
                  <DisplayWrapper
                    cellKey={`${idx}-${period.key}-monthly`}
                    value={period.value}
                    type={DisplayType.Currency}
                    precision={0}
                  />
                </div>
              </td>
            ) : (
              <td className={`currency`}>
                <DisplayWrapper
                  cellKey={`${idx}-${period.key}-monthly`}
                  value={period.value}
                  type={DisplayType.Currency}
                  precision={0}
                />
              </td>
            )}
          </>
        ))}
        <td className="currency">
          <DisplayWrapper
            cellKey={`${key}-total`}
            value={summaryItem.total}
            type={DisplayType.Currency}
            precision={0}
          />
        </td>
      </tr>
      {isExpanded ? (
        <CashflowHoodPanel queryPeriod={queryPeriod} isExpanded={isExpanded} isEditable={isEditable} isHidden={isHidden} setSummaryItem={setSummaryItem} item={summaryItem} expand={setIsExpanded} />
      ) : ""}
    </>
  )
}

const CashflowEdit = ({ queryPeriod, isHidden, summaryItem, adjustBy, setAdjustBy = () => { }, updateEstimate = () => { }, updateTypedEstimate = () => { } }) => {

  const [periods, setPeriods] = useState([]);

  const onInputChangeHandler = async (e) => {

    const { month, year } = e.cellKey;
    const { value } = e;

    if (!value)
      return;

    if (adjustBy.value == "original")
      await updateEstimate({ ...queryPeriod, cashflowId: summaryItem.cashflowId }, { month: month, year: year, value: value });
    else
      await updateTypedEstimate({ ...queryPeriod, cashflowId: summaryItem.cashflowId }, { month, year, value, type: adjustBy.value });
  };

  useEffect(() => {
    setPeriods(summaryItem.periods);
  }, summaryItem)

  return (
    <tr hidden={!adjustBy} className="period-subheader-editable">
      <td className="text pl-3 font-12" colSpan={isHidden ? 1 : 4}>
        <Label className="font-12 text ml-4 mt-1 ml-5">
          <div className="ml-5">
            Adjust By {_.capitalize(adjustBy.label)}
          </div>
        </Label>
      </td>
      {_.map(nullifyValues(periods), (item) => (
        <td className={`currency`}>
          <SwitchWrapper autoSave={true} onInputChange={onInputChangeHandler} cellKey={item} value={item.value} type={DisplayType.Currency} precision={0} allowNegative={true} />
        </td>
      ))}
      <td>
        <Label className="ml-3 mt-1 btn-sm" style={{ cursor: "pointer" }}>
          <i className="fas fa-window-close" onClick={() => setAdjustBy(undefined)} />
        </Label>
      </td>
    </tr>
  )
}

const CashflowHoodPanel = ({ queryPeriod, isExpanded, isHidden, isEditable, item, setSummaryItem = () => { }, expand = () => { } }) => {

  const adjustByOptions = [
    { value: 'original', label: 'Original' },
    { value: 'baseline', label: 'Baseline' },
    { value: 'cost', label: 'Cost' },
    { value: 'ctc', label: "Cost to Complete" }
  ];
  const [showOffset, setShowOffset] = useState(true);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [adjustBy, setAdjustBy] = useState(undefined);

  const [cashflow, setCashflow] = useState({ ...defaultCashflowState });

  const {
    Actions,
    getCashflow,
    changeProjectionBy,
    changeProjectionByLoading,
    getCashflowLoading,
    getSummaryItem,
    updateEstimate,
    updateTypedEstimate
  } = useCashflow({
    onCompleted: ({ action, data }) => {
      if ([Actions.GetCashflow].includes(action)) {
        setCashflow(prev => ({ ...prev, ...formatCashflow(data) }))
      }
      if ([Actions.ChangeProjectionBy, Actions.GetSummaryItem].includes(action)) {
        setSummaryItem(data);
      }
      if ([Actions.UpdateEstimate, Actions.UpdateTypedEstimate].includes(action)) {
        if (showBreakdown) {
          getCashflow({
            cashflowId: item.cashflowId,
            ...queryPeriod
          });
        }
        getSummaryItem({
          cashflowId: item.cashflowId,
          ...queryPeriod
        })
      }
    }
  });

  const projectByHandler = (projectBy) => {
    if (projectBy === item.projectionBy)
      return;

    changeProjectionBy({
      cashflowId: item.cashflowId,
      ...queryPeriod
    }, {
      type: projectBy,
      value: undefined
    })
  }

  const refreshCashflow = (override = false) => {

    if (override) {
      getCashflow({
        cashflowId: item.cashflowId,
        ...queryPeriod
      });
      return;
    }

    if (!showBreakdown)
      return;

    getCashflow({
      cashflowId: item.cashflowId,
      ...queryPeriod
    });
  }

  useEffect(() => {
    refreshCashflow()
  }, [showBreakdown]);

  useEffect(() => {
    refreshCashflow();
  }, [queryPeriod]);

  useEffect(() => {
    refreshCashflow(true)
  }, []);

  return (
    <>
      {getCashflowLoading ? (
        <tr>
          <td colSpan={item.periods.length + (isHidden ? 2 : 5)}>
            <Skeleton />
            <Skeleton />
          </td>
        </tr>
      ) : ""}
      {cashflow.cashflowId && showBreakdown ? (
        <AllProjectionTypes cashflow={cashflow} isHidden={isHidden} />
      ) : ""}
      {adjustBy ? (
        <CashflowEdit queryPeriod={queryPeriod} editable={true} isHidden={isHidden} summaryItem={item} adjustBy={adjustBy} setAdjustBy={setAdjustBy} updateEstimate={updateEstimate} updateTypedEstimate={updateTypedEstimate} />
      ) : ""}
      <tr className={!isExpanded ? 'budget-panel' : 'budget-panel open'}>
        <td colSpan={item.periods.length + (isHidden ? 2 : 5)}>
          <Card>
            <CardBody>
              {cashflow.cashflowId && showOffset ? (
                <CashflowMatrix cashflow={cashflow} />
              ) : ""}
            </CardBody>
            <CardFooter className="mb-2">
              <Button outline className="float-left ml-2" color="projx" onClick={() => setShowBreakdown(!showBreakdown)}>
                <i className="fas fas-list" /> {showBreakdown ? "Hide Breakdown" : "Show Breakdown"}
              </Button>
              <Button outline className="float-left ml-2" color="projx" onClick={() => setShowOffset(!showOffset)}>
                <i className="fas fa-retweet" /> {showOffset ? "Hide Offset" : "Show Offset"}
              </Button>
              <Button className="float-right ml-2" color="projx" onClick={() => expand(!isExpanded)}>
                <i className="fas fas-save" /> Close
              </Button>
              <UncontrolledDropdown className="ml-2 float-right">
                <DropdownToggle caret color="projx" outline>
                  Adjust By
                </DropdownToggle>
                <DropdownMenu>
                  {_.map(adjustByOptions, (ab) => (
                    <DropdownItem onClick={() => setAdjustBy(ab)}>
                      {ab.label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown className="ml-2 float-right">
                <DropdownToggle caret color="projx" outline>
                  Project By
                </DropdownToggle>
                <DropdownMenu>
                  {_.map(["budget", "approved", "committed", "forecast"], (projectBy) => (
                    <DropdownItem onClick={() => projectByHandler(projectBy)}>
                      {_.capitalize(projectBy)}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </CardFooter>
          </Card>
        </td>
      </tr>
    </>
  )
}

const ProjectionPeriodHeadings = ({
  onClick = () => { },
  columns,
  isHidden,
}) => {
  return (
    <thead>
      <tr>
        <th colSpan={isHidden ? 1 : 4}>
          <ToggleMore onClick={onClick} isHidden={isHidden} />
        </th>
        <th
          colSpan={columns.length}
          className="period-header period-header-radius-left period-header-radius-right font-14"
        >
          {'Construction Period'}
        </th>
        <th colSpan={1} />
      </tr>
      <tr>
        {isHidden ? <th /> :
          (
            <>
              <th />
              <th>Projected By</th>
              <th>Total Cost</th>
              <th>CTC</th>
            </>
          )}
        {_.map(columns, (column) => (
          <th className="period-month">{column.caption}</th>
        ))}
        <th><div className="font-bold">Totals</div></th>
      </tr>
    </thead>
  );
};


const PeriodFilter = ({ cashflow, isHidden, onQueryChange = () => { } }) => {
  const stringifyMonth = (val) => moment(val).format('YYYY-MM-DD');

  const monthDifference = (from, to) =>
    Math.floor(moment(to).diff(moment(from), 'months', true));

  const [months, setMonths] = useState({
    from: cashflow.query.startDate,
    to: moment(cashflow.query.startDate)
      .add(cashflow.query.window, 'month')
      .format('YYYY-MM-DD'),
  });

  const previousHandler = () => {
    let from = stringifyMonth(moment(months.from).subtract(1, 'month'));
    let to = stringifyMonth(moment(months.to).subtract(1, 'month'));
    setMonths({ from, to });
  };

  const nextHandler = () => {
    let from = stringifyMonth(moment(months.from).add(1, 'month'));
    let to = stringifyMonth(moment(months.to).add(1, 'month'));
    setMonths({ from, to });
  };

  const onMonthChange = (direction, e) => {
    const month = stringifyMonth(e.target.value);

    if (months[direction] == month) return;

    setMonths((prev) => ({ ...prev, [direction]: month }));
  };

  useEffect(() => {
    let diff = monthDifference(months.from, months.to);

    if (!moment(months.from).isValid() || isNaN(diff) || diff <= 0) return;

    let query = {
      cashflowId: cashflow.cashflowId,
      startDate: months.from,
      window: diff,
    };
    console.log(query);

    onQueryChange(query);
  }, [months]);

  return (
    <div className="d-flex float-right">
      <Button color="projx" className="mr-1" onClick={previousHandler}>
        <i className="fas fa-angle-left" />
      </Button>
      {/* <div className="d-flex"> */}
      <Input
        type="date"
        value={months.from}
        onChange={(e) => onMonthChange('from', e)}
      />
      <Label className="font-14 ml-2 mr-2 mt-1">to</Label>
      <Input
        type="date"
        value={months.to}
        onChange={(e) => onMonthChange('to', e)}
      />
      {/* </div> */}
      <Button color="projx" className="ml-1" onClick={nextHandler}>
        <i className="fas fa-angle-right" />
      </Button>
    </div>
  );
};

const AllProjectionTypes = ({ cashflow, isHidden }) => {
  return (
    <>
      <ProjectionTypeTableSection
        title={'Original Projection'}
        key="original"
        isHidden={isHidden}
        section={{
          monthly: cashflow.columns,
          cummulative:
            cashflow.original.calculations.accumulativePeriods,
        }}
      />
      <ProjectionTypeTableSection
        title={'Amended Projection'}
        key="amended"
        isHidden={isHidden}
        section={{
          monthly:
            cashflow.original.calculations.baselineProjections,
          cummulative:
            cashflow.original.calculations
              .accumulativeBaselineProjection,
        }}
      />
      <ProjectionTypeTableSection
        title={'Cost Projection'}
        key="cost"
        isHidden={isHidden}
        section={{
          monthly: cashflow.original.calculations.costProjections,
          cummulative:
            cashflow.original.calculations
              .accumulativeCostProjection,
        }}
      />
      <ProjectionTypeTableSection
        title={'Actual Expenditure'}
        key="expenditure"
        isHidden={isHidden}
        section={{
          monthly: cashflow.original.calculations.expenditure,
          cummulative:
            cashflow.original.calculations.accumulativeExpenditure,
        }}
      />
      <ProjectionTypeTableSection
        title={'Expenditure Difference'}
        key="expenditure-diff"
        isHidden={isHidden}
        section={{
          monthly:
            cashflow.original.calculations
              .expenditureDifferenceProjections,
          cummulative:
            cashflow.original.calculations
              .accumulativeExpenditureDifferenceProjections,
        }}
      />
      <ProjectionTypeTableSection
        title={'Adjusted Cashflow'}
        key="adjusted"
        isHidden={isHidden}
        section={{
          monthly:
            cashflow.original.calculations.adjustedProjections,
          cummulative:
            cashflow.original.calculations
              .accumulativeAdjustedProjection,
        }}
      />
    </>
  )
}

const ProjectionTypeTableSection = ({
  title,
  key,
  section,
  isHidden,
  isEditable = false,
  onInputChange = () => { },
  onPeriodSelected = () => { },
  isLast = false,
}) => {
  const { config } = useContext(BudgetConfigContext);
  const [isHiddenInternal, setExpanded] = useState(true);

  const toggle = () => setExpanded(!isHiddenInternal);

  const periodSum = (sec) =>
    sec.length > 0 ? _.sumBy(sec, (m) => (m.value ? m.value : 0)) : 0;

  const lastValue = (sec) => {
    let item = sec.slice(-1)[0];
    return item ? (item.value ? item.value : 0) : 0;
  };

  const onInputChangeHandler = (e) => {
    onInputChange(e);
  };

  const CummulativeChecksOut = ({ first, second }) => {
    return (
      <td
        className={first == second ? 'period-cell-true' : 'period-cell-false'}
      >
        <i
          className={`${first == second ? 'fas fa-check-circle' : 'fas fa-times-circle'
            } mt-2`}
        />
      </td>
    );
  };

  return (
    <>
      <tr onClick={toggle} style={{ cursor: 'pointer' }}>
        {!isHiddenInternal ? (
          <td className="text pl-4" colSpan={(isHidden ? 2 : 5) + section.monthly.length}>
            <ProjectionTypeToggle title={title} isHidden={isHiddenInternal} />
          </td>
        ) : (
          <>
            <td className="text pl-4" colSpan={isHidden ? 1 : 4}>
              <ProjectionTypeToggle title={title} isHidden={isHiddenInternal} />
            </td>
            {_.map(keyifyPeriods(section.monthly), (item, idx) => (
              <td
                className={`currency ${isLast && idx == 0
                  ? 'period-header period-header-radius-bottom-left'
                  : isLast && idx == section.monthly.length - 1
                    ? 'period-header period-header-radius-botton-right'
                    : isLast
                      ? 'period-header'
                      : ''
                  }`}
              >
                <DisplayWrapper
                  cellKey={`${key}-${item.key}-monthly`}
                  value={item.value}
                  type={DisplayType.Currency}
                  precision={0}
                />
              </td>
            ))}
            <td className="currency">
              <DisplayWrapper
                cellKey={`${key}-total`}
                value={_.sumBy(section.monthly, (m) => (m.value ? m.value : 0))}
                type={DisplayType.Currency}
                precision={0}
              />
            </td>
          </>
        )}
      </tr>
      <tr hidden={isHiddenInternal}>
        <td className="text pl-3 font-12" colSpan={isHidden ? 1 : 4}>
          <Label className="font-12 text ml-4 mt-1 ml-5">
            <i className="mdi mdi-subdirectory-arrow-right" /> Monthly
          </Label>
        </td>
        {_.map(keyifyPeriods(section.monthly), (item) => (
          <td className={`currency ${isLast ? 'period-header' : ''}`}>
            <DisplayWrapper
              cellKey={`${key}-${item.key}-monthly`}
              value={item.value}
              type={DisplayType.Currency}
              precision={0}
            />
          </td>
        ))}
        <td className="currency">
          <DisplayWrapper
            cellKey={`${key}-total`}
            value={_.sumBy(section.monthly, (m) => (m.value ? m.value : 0))}
            type={DisplayType.Currency}
            precision={0}
          />
        </td>
      </tr>
      <tr hidden={isHiddenInternal}>
        <td colSpan={isHidden ? 1 : 4}>
          <Label className="font-12 text ml-4 mt-1 ml-5" >
            <i className="mdi mdi-subdirectory-arrow-right" /> Cumulative
          </Label>
        </td>
        {_.map(keyifyPeriods(section.cummulative), (item) => (
          <td className="currency period-cummulative">
            <DisplayWrapper
              cellKey={`${key}-${item.key}-cummulative`}
              value={item.value}
              type={DisplayType.Currency}
              precision={0}
            />
          </td>
        ))}
        <CummulativeChecksOut
          first={periodSum(section.monthly)}
          second={lastValue(section.cummulative)}
        />
      </tr>
    </>
  );
};

export default CashflowTableCustom;
