import React, { useState, useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
// Local Imports
import {
  FundingActivityCardsContainer,
  FundingActivityCardContainer,
  FundingActivityCardHeader,
  FundingActivityCardTimestamp,
  FundingActivityCardAmount,
  FundingActivityCardDescription,
} from './styles/FundingActivityCard';
import { Spinner } from '../../../components';
import { FundingContext } from '../../../contexts';

// GraphQL
const GET_FUNDING_ACCOUNT_EVENTS = gql`
  query getFundingAccountEvents($projectId: ID!, $fundingAccountName: String!) {
    getFundingAccountEvents(
      projectId: $projectId
      fundingAccountName: $fundingAccountName
    ) {
      _id
      type
      timestamp
      amount
      description
    }
  }
`;

/**
 * This component renders all the activity cards associated with our funding event
 * @param {string} fundingAccountName The name of our funding account for which we want funding events
 * @param {number} timestamp The unix timestamp of the funding event
 * @param {string} type The type of our funding event - either "DEPOSIT" or "WITHDRAW"
 * @param {number} amount The amount of money associated with our funding event
 * @param {string} description The description associated with our funding event
 * @returns
 */
const FundingActivityCards = ({
  fundingAccountName="",
}) => {
  if (fundingAccountName === "") {
    return <></>;
  }
  const [fundingAccountEvents, setFundingAccountEvents] = useState([]);
  const { projectId } = useContext(FundingContext);
  const {
    loading: loadingGetFundingAccountEvents,
    error: errorGetFundingAccountEvents,
    data: dataGetFundingAccountEvents,
  } = useQuery(GET_FUNDING_ACCOUNT_EVENTS, {
    variables: {
      projectId,
      fundingAccountName,
    },
    onCompleted: (data) => {
      setFundingAccountEvents(data.getFundingAccountEvents)
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loadingGetFundingAccountEvents) {
    return <Spinner />;
  }

  return (
    <FundingActivityCardsContainer>
      {_.map(fundingAccountEvents, (value, index) => (
        <FundingActivityCardContainer key={value._id}>
          <FundingActivityCardHeader>
            <FundingActivityCardTimestamp>{moment.unix(value.timestamp).fromNow()}</FundingActivityCardTimestamp>
          </FundingActivityCardHeader>
          <FundingActivityCardAmount type={value.type}>
            {value.type === 'DEPOSIT' ? `+$${value.amount}` : `-$${value.amount}`}
          </FundingActivityCardAmount>
          <FundingActivityCardDescription>
            {value.description}
          </FundingActivityCardDescription>
        </FundingActivityCardContainer>
      ))}
    </FundingActivityCardsContainer>
  );
};

export default FundingActivityCards;
