import styled, { css } from 'styled-components';

export const AddWidgetButtonStyles = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  height: 40px;
  margin: 25px auto 0 auto;
  background-color: #D9D9D9;
  color: #1B1B1B;
  border-radius: 35px;
  outline: none;
  font-size: 16px;
  transition: transform 0.1s;
  padding: 14px 16px;
  border: 0;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    color: black;
  }

  /* Only enable if not in editing mode for our widgets */
  ${(props) =>
    !props.inEditMode &&
    css`
      display: none;
    `}
`;

export const AddWidgetButtonText = styled.p`
  margin: 0 0 0 5px;
`;