// Library Imports
import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { gql, useLazyQuery } from '@apollo/client';
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
  usePagination,
} from 'react-table';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
// Local Imports
import { withProject } from '../../common';
import { Badges, IconButton } from '../../components';
import { ProgressDiaryContext } from '../../contexts';
import ProgressDiaryHeader from './header';
import {
  TableStyles,
  PaginationContainer,
  PaginationArrows,
  DoubleLeftArrowIcon,
  LeftArrowIcon,
  RightArrowIcon,
  DoubleRightArrowIcon,
  PaginationText,
  PaginationNumbers
} from './table/styles/Table';
import {
  UpArrow,
  DownArrow,
  VerticalArrows,
  CheckIcon,
  CrossIcon,
} from './icons/styles/Icons';
import EditLineItemModal from './editLineItemModal';
import EditTableLineItemButton from './editLineItemButton';
import FilterAccordion from './filterAccordion';

// GraphQL Queries
const GET_PROGRESS_DIARY_DOCUMENTS_WITH_FILTER = gql`
  query getProgressDiaryDocuments(
    $projectId: ID!
    $filter: ProgressDiaryFilterInput!
  ) {
    getProgressDiaryDocumentsWithFilter(
      projectId: $projectId
      filter: $filter
    ) {
      _id
      event
      date
      tags
      reporting
    }
  }
`;

/**
 *
 * @param {Object} project This is the project object for the default export for our progress diary
 * @returns Our view which is used to render the ProgressDiary Page
 */
const ProgressDiary = ({ project }) => {
  const [tableData, setTableData] = useState([]);
  // When in our editing progress diary event modal, use this state to keep track of which document we are editing
  const [currDocumentId, setCurrDocumentId] = useState('');
  // For showing/hiding our modal for editing an event
  const [showEditProgressDiaryEventModal, setShowEditProgressDiaryEventModal] =
    useState(false);

  // For controlling the state of our accordion
  const [filterAccordionOpen, setFilterAccordionOpen] = useState(false);

  // State for controlling our filter component
  // The date we start from in our filter
  const [fromDate, setFromDate] = useState(new Date(0));
  // The date we end on in our filter
  const [toDate, setToDate] = useState(null);
  // The tags we filter on
  const [filterTags, setFilterTags] = useState([]);
  // State related to our select component for selecting reporting filter
  const [reportingSelectValue, setReportingSelectValue] = useState('BOTH');

  /**
   * This function toggles our editLineItem Modal but is wrapped to setState of the
   * documentId of the line item that is clicked
   * @param {string} documentId The ID of our progress diary document
   */
  const toggleEditLineItemModal = ({ documentId }) => {
    // If parameter is defined in our function call then only do we assign it to our state
    if (documentId) setCurrDocumentId(documentId);

    // If modal is currently open (we are closing it), then reset currDocumentId
    if (showEditProgressDiaryEventModal) setCurrDocumentId('');
    setShowEditProgressDiaryEventModal(() => !showEditProgressDiaryEventModal);
  };

  const [
    getTableDataWithFilters,
    {
      loading: loadingGetProgressDiaryDocs,
      error: errorGetProgressDiaryDocs,
      data: dataGetProgressDiaryDocs,
    },
  ] = useLazyQuery(GET_PROGRESS_DIARY_DOCUMENTS_WITH_FILTER, {
    onCompleted: (data) => {
      setTableData(data.getProgressDiaryDocumentsWithFilter);
    },
  });

  useEffect(() => {
    getTableDataWithFilters({
      variables: {
        projectId: project._id,
        filter: {
          startDate: fromDate ? fromDate.toISOString() : null,
          endDate: toDate ? toDate.toISOString() : null,
          tags: filterTags,
          reporting: reportingSelectValue,
        },
      },
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        accessor: '_id', // accessor is the "key" in the data
        minWidth: 40,
        width: 40,
        maxWidth: 40,
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ value }) => {
          return (
            <EditTableLineItemButton
              onClick={() => toggleEditLineItemModal({ documentId: value })}
            />
          );
        },
      },
      {
        Header: 'Event',
        accessor: 'event', // accessor is the "key" in the data
        minWidth: 450,
        width: 600,
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'date',
        minWidth: 160,
        width: 160,
        maxWidth: 160,
        disableResizing: true,
        sortType: (rowA, rowB) =>
          moment(rowA.values.date).isBefore(rowB.values.date) ? -1 : 1,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                textAlign: 'end',
              }}
            >
              {moment(value).format('DD MMM, YYYY')}
            </div>
          );
        },
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        minWidth: 250,
        width: 350,
        maxWidth: 500,
        sortType: (rowA, rowB) => {
          return _.size(rowA.values.tags) > _.size(rowB.values.tags) ? 1 : -1;
        },
        Cell: ({ value }) => {
          return <Badges tags={value} fontSize={'14px'} />;
        },
      },
      {
        Header: 'Reporting',
        accessor: 'reporting',
        minWidth: 160,
        width: 160,
        maxWidth: 160,
        sortType: 'basic',
        disableResizing: true,
        Cell: ({ value }) => {
          return (
            <div style={{ textAlign: 'center' }}>
              {value === true ? <CheckIcon /> : <CrossIcon />}
            </div>
          );
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows, // (replace rows with 'page' so we can use pagination)
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    useSortBy,
    useFlexLayout,
    useResizeColumns,
    usePagination,
  );

  return (
    <>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Home
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/projects/${project._id}`}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Progress Diary</BreadcrumbItem>
      </Breadcrumb>
      {/* This context provider is used for our progress diary filter component */}
      <ProgressDiaryContext.Provider
        value={{
          filterAccordionOpen,
          setFilterAccordionOpen,
          fromDate,
          setFromDate,
          toDate,
          setToDate,
          filterTags,
          setFilterTags,
          reportingSelectValue,
          setReportingSelectValue,
          getTableDataWithFilters,
        }}
      >
        <ProgressDiaryHeader
          projectId={project._id}
          setTableData={setTableData}
        />
        <FilterAccordion projectId={project._id} />
      </ProgressDiaryContext.Provider>
      <TableStyles>
        <div className="tableWrap">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      {...column.getHeaderProps({
                        // Handle our sorting props
                        ...column.getSortByToggleProps(),
                      })}
                    >
                      {column.render('Header')}
                      {column.canResize && (
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          }`}
                        />
                      )}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <DownArrow />
                          ) : (
                            <UpArrow />
                          )
                        ) : column.canSort ? (
                          <VerticalArrows />
                        ) : null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.collapse ? 'collapse' : '',
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <PaginationContainer>
            <PaginationArrows>
              <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <DoubleLeftArrowIcon />
              </IconButton>
              <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                <LeftArrowIcon />
              </IconButton>
              <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
                <RightArrowIcon />
              </IconButton>
              <IconButton
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <DoubleRightArrowIcon />
              </IconButton>
            </PaginationArrows>
            <PaginationText>
              {`Page`}
              <PaginationNumbers>{`${pageIndex + 1} of ${pageOptions.length}`}</PaginationNumbers>
            </PaginationText>
          </PaginationContainer>
        </div>
      </TableStyles>
      {/* Only if our currDocumentId is defined, then do we render our edit line item modal */}
      {currDocumentId !== '' && (
        <EditLineItemModal
          showEditProgressDiaryEventModal={showEditProgressDiaryEventModal}
          toggleEditLineItemModal={toggleEditLineItemModal}
          documentId={currDocumentId}
          projectId={project._id}
          setTableData={setTableData}
        />
      )}
    </>
  );
};

export default withProject(ProgressDiary);
