import { Font, StyleSheet } from '@react-pdf/renderer';

const openSans = require('../../../../assets/fonts/Open_Sans/OpenSans-Regular.ttf');
const lato = require('../../../../assets/fonts/Lato/Lato-Regular.ttf');
const latoBold = require('../../../../assets/fonts/Lato/Lato-Bold.ttf');
const latoItalic = require('../../../../assets/fonts/Lato/Lato-Italic.ttf');

const GlobalStyles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
  },
  leftColumn: {
    flexDirection: 'column',
    width: '70%',
    padding: 2,
  },
  formName: {
    marginBottom: 5,
  },
  formDetails: {
    marginBottom: 5,
  },
  footer: {
    fontSize: 12,
    fontFamily: 'Lato Bold',
    textAlign: 'center',
    marginTop: 25,
    paddingTop: 10,
    borderWidth: 3,
    borderColor: 'gray',
    borderStyle: 'dashed',
    '@media orientation: landscape': {
      marginTop: 10,
    },
  },
});

Font.register({
  family: 'Open Sans',
  src: openSans,
});
Font.register({
  family: 'Lato',
  src: lato,
});
Font.register({
  family: 'Lato Italic',
  src: latoItalic,
});
Font.register({
  family: 'Lato Bold',
  src: latoBold,
});

export { GlobalStyles };
