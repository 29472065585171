import React, { useContext, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Table,
  Navbar,
} from 'reactstrap';

import _ from 'lodash';
import { UserContext } from '../../../../contexts';
import * as data from '../data';
import { usePanel } from '../../../../hooks';
import OrgSelector from '../../../../components/organisation/orgSelector';
import { GlobalSearchV2 } from '../../../../components/global/Search';
/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logoLightSVG from '../../../../assets/images/logo_light.svg';
import logoSmallSVG from '../../../../assets/images/monogram_color.svg';
import { LogoIMG } from '../../../../components';

// NavBar imports
import {
  HeaderContainer,
  HeaderGroup,
  HeaderButton,
  SearchBarContainer,
  SearchBarInput,
} from './styles/HeaderStyles';
import {
  LeftArrow,
  RightArrow,
  SearchIcon,
  SettingIcon,
  NotificationIcon,
  ProfileIcon,
} from './styles/HeaderIcons';

// Dropdown Menu
import { Dropdown } from '../../../../components';
import {
  UserSettingIcon,
  LogoutIcon,
} from '../../../../components/user/styles/UserBarIcons';

const DefaultHeaderContent = ({ settings }) => {
  const { setResize } = usePanel();
  const history = useHistory();
  const [bigLogo, setBigLogo] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // This useState hook takes two values, 'large' and 'small';
  const [sideBarSize, setSideBarSize] = useState('large');
  const { user, loading } = useContext(UserContext);
  // For our profile menu dropdown
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [mouseOnProfileMenu, setMouseOnProfileMenu] = useState(false);

  const onSearchResults = (results) => {
    setSearchResults(results);
  };

  const onSearchItemSelected = (href) => {
    setSearchResults([]);
    history.push(href);
  };

  /*--------------------------------------------------------------------------------*/
  /* To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  const toggle = () => setIsOpen(!isOpen);

  /*--------------------------------------------------------------------------------*/
  /* To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  const showMobilemenu = () => {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar');
  };

  const sidebarHandler = () => {
    const element = document.getElementById('main-wrapper');

    switch (settings.activeSidebarType) {
      case 'full':
      case 'iconbar':
        element.classList.toggle('mini-sidebar');
        if (element.classList.contains('mini-sidebar')) {
          setSideBarSize('small');
          element.setAttribute('data-sidebartype', 'mini-sidebar');
          setBigLogo(false);
          setResize('small');
        } else {
          setSideBarSize('large');
          element.setAttribute('data-sidebartype', settings.activeSidebarType);
          setBigLogo(true);
          setResize('big');
        }
        break;
      case 'overlay':
      case 'mini-sidebar':
        element.classList.toggle('full');
        if (element.classList.contains('full')) {
          element.setAttribute('data-sidebartype', 'full');
        } else {
          element.setAttribute(
            'data-sidebartype',
            this.settings.activeSidebarType,
          );
        }
        break;
      default:
    }
  };

  return (
    <Navbar
      className={`top-navbar ${
        settings.activeNavbarBg === 'skin6' ? 'navbar-light' : 'navbar-dark'
      }`}
      expand="md"
    >
      <div
        className="navbar-header"
        id="logobg"
        data-logobg={settings.activeLogoBg}
      >
        {/*--------------------------------------------------------------------------------*/}
        {/* Mobile View Toggler  [visible only after 768px screen]                         */}
        {/*--------------------------------------------------------------------------------*/}
        <span
          className="nav-toggler d-block d-md-none"
          onClick={showMobilemenu}
        >
          <i className="ti-menu ti-close" />
        </span>
        {/*--------------------------------------------------------------------------------*/}
        {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
        {/*--------------------------------------------------------------------------------*/}
        <NavbarBrand href="/">
          <b className="logo-icon">
            <LogoIMG
              src={bigLogo ? logoLightSVG : logoSmallSVG}
              alt="homepage"
              className="dark-logo"
            />
            <LogoIMG
              src={bigLogo ? logoLightSVG : logoSmallSVG}
              alt="homepage"
              className="light-logo"
            />
          </b>
        </NavbarBrand>
        {/*--------------------------------------------------------------------------------*/}
        {/* Mobile View Toggler  [visible only after 768px screen]                         */}
        {/*--------------------------------------------------------------------------------*/}
        <span className="topbartoggler d-block d-md-none" onClick={toggle}>
          <i className="ti-more" />
        </span>
      </div>
      <Collapse
        className="navbarbg"
        isOpen={isOpen}
        navbar
        data-navbarbg={settings.activeNavbarBg}
      >
        <HeaderContainer>
          <HeaderGroup>
            <HeaderButton onClick={sidebarHandler}>
              {sideBarSize === 'large' ? <LeftArrow /> : <RightArrow />}
            </HeaderButton>
            <SearchBarContainer>
              <SearchIcon />
              <SearchBarInput placeholder="Search" />
            </SearchBarContainer>
          </HeaderGroup>
          <HeaderGroup>
            {loading ? <div /> : <OrgSelector user={user} />}
            <Link to="/settings-v2">
              <HeaderButton>
                <SettingIcon />
              </HeaderButton>
            </Link>
            <HeaderButton>
              <NotificationIcon />
            </HeaderButton>
            <HeaderButton
              onFocus={() => setShowProfileMenu(true)}
              onBlur={() => !mouseOnProfileMenu && setShowProfileMenu(false)}
            >
              <ProfileIcon />
            </HeaderButton>
            {/* The dropdown below is for our profile menu */}
            <Dropdown
              show={showProfileMenu}
              top={65}
              right={30}
              onMouseOver={() => setMouseOnProfileMenu(true)}
              onMouseLeave={() => setMouseOnProfileMenu(false)}
            >
              <Dropdown.Item to="/account" onClick={() => setShowProfileMenu(false)}>
                <UserSettingIcon />
                <Dropdown.ItemText>Account Settings</Dropdown.ItemText>
              </Dropdown.Item>
              <Dropdown.Item to="/logout" onClick={() => setShowProfileMenu(false)}>
                <LogoutIcon />
                <Dropdown.ItemText>Logout</Dropdown.ItemText>
              </Dropdown.Item>
            </Dropdown>
          </HeaderGroup>
        </HeaderContainer>
        {/* <i className="ti-menu" /> */}
        {/* {data.dropdownMenus.map((menu, idx) => (
          <UncontrolledDropdown
            nav
            inNavbar
            className="mega-dropdown"
            key={idx}
          >
            <DropdownToggle nav>
              {' '}
              <i className={menu.icon} />
              {menu.menu}
            </DropdownToggle>
            <DropdownMenu>
              <Row>
                {menu.sections.map((section, idx2) => {
                  return (
                    <Col xs="8" sm="8" md="8" lg="2" key={idx2}>
                      <h5 className="mb-3 text-uppercase">
                        {section.section}
                      </h5>
                      <ListGroup flush>
                        {section.items.map((item, idx3) => (
                          <ListGroupItem
                            href={item.route}
                            tag="a"
                            className="border-0 pl-0 text-dark pt-0"
                            key={idx3}
                          >
                            <i className={`${item.icon} text-info mr-2`} />
                            {item.text}
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Col>
                  );
                })}
              </Row>
            </DropdownMenu>
          </UncontrolledDropdown>
        ))} */}
      </Collapse>
    </Navbar>
  );

  return (
    <Navbar
      className={`top-navbar ${
        settings.activeNavbarBg === 'skin6' ? 'navbar-light' : 'navbar-dark'
      }`}
      expand="md"
    >
      <div
        className="navbar-header"
        id="logobg"
        data-logobg={settings.activeLogoBg}
      >
        {/*--------------------------------------------------------------------------------*/}
        {/* Mobile View Toggler  [visible only after 768px screen]                         */}
        {/*--------------------------------------------------------------------------------*/}
        <span
          className="nav-toggler d-block d-md-none"
          onClick={showMobilemenu}
        >
          <i className="ti-menu ti-close" />
        </span>
        {/*--------------------------------------------------------------------------------*/}
        {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
        {/*--------------------------------------------------------------------------------*/}
        <NavbarBrand href="/">
          <b className="logo-icon">
            <LogoIMG
              src={bigLogo ? logoLightSVG : logoSmallSVG}
              alt="homepage"
              className="dark-logo"
            />
            <LogoIMG
              src={bigLogo ? logoLightSVG : logoSmallSVG}
              alt="homepage"
              className="light-logo"
            />
          </b>
        </NavbarBrand>
        {/*--------------------------------------------------------------------------------*/}
        {/* Mobile View Toggler  [visible only after 768px screen]                         */}
        {/*--------------------------------------------------------------------------------*/}
        <span className="topbartoggler d-block d-md-none" onClick={toggle}>
          <i className="ti-more" />
        </span>
      </div>
      <Collapse
        className="navbarbg"
        isOpen={isOpen}
        navbar
        data-navbarbg={settings.activeNavbarBg}
      >
        <Nav className="float-left" navbar>
          <NavItem>
            <NavLink
              href="#"
              className="d-none d-md-block"
              onClick={sidebarHandler}
            >
              <i className="ti-menu" />
            </NavLink>
          </NavItem>
          {/* {data.dropdownMenus.map((menu, idx) => (
            <UncontrolledDropdown
              nav
              inNavbar
              className="mega-dropdown"
              key={idx}
            >
              <DropdownToggle nav>
                {' '}
                <i className={menu.icon} />
                {menu.menu}
              </DropdownToggle>
              <DropdownMenu>
                <Row>
                  {menu.sections.map((section, idx2) => {
                    return (
                      <Col xs="8" sm="8" md="8" lg="2" key={idx2}>
                        <h5 className="mb-3 text-uppercase">
                          {section.section}
                        </h5>
                        <ListGroup flush>
                          {section.items.map((item, idx3) => (
                            <ListGroupItem
                              href={item.route}
                              tag="a"
                              className="border-0 pl-0 text-dark pt-0"
                              key={idx3}
                            >
                              <i className={`${item.icon} text-info mr-2`} />
                              {item.text}
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </Col>
                    );
                  })}
                </Row>
              </DropdownMenu>
            </UncontrolledDropdown>
          ))} */}
        </Nav>
        <Nav className="pl-5">
          <NavItem style={{ width: '500px' }}>
            <UncontrolledDropdown
              className="mega-dropdown"
              isOpen={searchResults.length > 0}
            >
              <DropdownToggle nav>
                <GlobalSearchV2 onResults={onSearchResults} />
              </DropdownToggle>
              <DropdownMenu>
                <div className="d-flex justify-content-center pb-2 border-bottom">
                  <h5>Search Results</h5>
                </div>
                <div>
                  <Table className="v-middle" responsive hover>
                    <tbody>
                      {_.map(searchResults, (result) => (
                        <tr onClick={() => onSearchItemSelected(result.href)}>
                          <td>
                            <i
                              className={`${result.icon} text-${result.colour}`}
                            />
                          </td>
                          <td>{result.type}</td>
                          <td>{result.title}</td>
                          <td>{result.description}</td>
                          <td>{result.tags.join(', ')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavItem>
        </Nav>
        <Nav className="ml-auto float-right" navbar>
          {/*--------------------------------------------------------------------------------*/}
          {/* Start Select Organisation Dropdown                                             */}
          {/*--------------------------------------------------------------------------------*/}
          {loading ? <div /> : <OrgSelector user={user} />}
          {/*--------------------------------------------------------------------------------*/}
          {/* Start Settings Button                                                          */}
          {/*--------------------------------------------------------------------------------*/}
          <NavItem>
            <NavLink href="/settings-v2">
              {/* <i className="mdi mdi-settings font-24" /> */}
              <HeaderButton>
                <SettingIcon />
              </HeaderButton>
            </NavLink>
          </NavItem>
          {/*--------------------------------------------------------------------------------*/}
          {/* End Settings Dropdown                                                          */}
          {/*--------------------------------------------------------------------------------*/}
          {/*--------------------------------------------------------------------------------*/}
          {/* Start Notifications Dropdown                                                   */}
          {/*--------------------------------------------------------------------------------*/}
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {/* <i className="mdi mdi-bell font-24" /> */}
              <HeaderButton>
                <NotificationIcon />
              </HeaderButton>
            </DropdownToggle>
            <DropdownMenu right className="mailbox">
              <span className="with-arrow">
                <span className="bg-primary" />
              </span>
              <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
                <div className="">
                  <h4 className="mb-0">4 New</h4>
                  <p className="mb-0">Notifications</p>
                </div>
              </div>
              <div className="message-center notifications">
                {/* <!-- Message --> */}
                {data.notifications.map((notification, index) => {
                  return (
                    <span href="" className="message-item" key={index}>
                      <span
                        className={`btn btn-circle btn-${notification.iconbg}`}
                      >
                        <i className={notification.iconclass} />
                      </span>
                      <div className="mail-contnet">
                        <h5 className="message-title">{notification.title}</h5>
                        <span className="mail-desc">{notification.desc}</span>
                        <span className="time">{notification.time}</span>
                      </div>
                    </span>
                  );
                })}
              </div>
              <a className="nav-link text-center mb-1 text-dark" href=";">
                <strong>Check all notifications</strong>
                <i className="fa fa-angle-right" />
              </a>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default DefaultHeaderContent;
