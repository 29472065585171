import React, { useEffect, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { CSVLink } from 'react-csv';

const ExportCSV = ({ artefact, pdf, isPdfReady, build = () => {} }) => {
  let [data, setData] = useState([]);

  const modifyTotals = (totals) => {
    if (totals && totals.data[0] && totals.data[0].budgetCategory) {
      let modifiedTotals = totals.data[0];
      modifiedTotals.group.budgetCategory.name = 'Totals:';
      return [modifiedTotals];
    }
    return [];
  };

  useEffect(() => {
    if (artefact.data) {
      setData(artefact.data.concat(modifyTotals(artefact.totals)));
    }
  }, [artefact]);

  return (
    <CSVLink
      style={{ marginRight: '20px'}}
      data={data}
      headers={build()}
      filename={`${artefact.fileName}.csv`}
    >
      <i className="fas fa-file-excel text-success" /> XLSX
    </CSVLink>
  );

  return (
    <>
      {artefact.data ? (
        <UncontrolledDropdown className="float-right">
          <DropdownToggle caret outline color="projx">
            Export
          </DropdownToggle>
          <DropdownMenu>
            {pdf && isPdfReady ? (
              <DropdownItem>
                <i className="fas fa-file-pdf text-danger" /> {pdf}
              </DropdownItem>
            ) : (
              ''
            )}
            <DropdownItem>
              <CSVLink
                data={data}
                headers={build()}
                filename={`${artefact.fileName}.csv`}
              >
                <i className="fas fa-file-excel text-success" /> XLSX
              </CSVLink>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        ''
      )}
    </>
  );
};

export default ExportCSV;
