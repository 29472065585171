import React, { useState, useContext } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { gql, useMutation } from '@apollo/client';
// Local Imports
import { Button } from '../../../components';
import { FundingContext } from '../../../contexts';
import {
  HeaderContainer,
  HeaderFrame,
  FundingAccountIcon,
  Heading,
} from './styles/Header';
import {
  InputContainer,
  InputHeader,
  FooterContainer,
  TextAreaStyled,
  NumericInputStyles,
} from './styles/Modal';

// GraphQL Mutations
const CREATE_FUNDING_ACCOUNT_DOCUMENT = gql`
  mutation createFundingAccount($projectId: ID!, $input: FundingAccountInput!) {
    createFundingAccount(projectId: $projectId, input: $input) {
      code
      success
      message
      fundingAccounts {
        _id
        name
        amount
        allocated
      }
    }
  }
`;

/**
 * This is the Header for our funding account page, containing the heading and the button which pops the modal for creating
 * a funding account
 * @param {string} projectId The ID of the project for which we are looking at funding for
 * @returns Header component for the Progress Diary module
 */
const FundingHeader = ({ projectId }) => {
  // The name of our new funding account
  const [fundingAccountName, setFundingAccountName] = useState('');
  // The amount of money we initially deposit into our funding account
  const [fundingAmount, setFundingAmount] = useState(0);
  // Description associated with our initial deposit 
  const [fundingDescription, setFundingDescription] = useState('');
  // Our add funding account modal
  const [showAddFundingAccountModal, setShowAddFundingAccountModal] = useState(false);

  const { setFundingAccounts } = useContext(FundingContext);

  const [
    createFundingAccountDocument,
    {
      loading: loadingCreateFundingAccountDoc,
      error: errorCreateFundingAccountDoc,
      data: dataCreateFundingAccountDoc,
    },
  ] = useMutation(CREATE_FUNDING_ACCOUNT_DOCUMENT, {
    onCompleted: (data) => {
      // If our mutation was not successful, just return
      if (data.createFundingAccount.success === false) {
        toast.error(data.createFundingAccount.message);
        return;
      }
      setFundingAccounts(data.createFundingAccount.fundingAccounts);
      toast.success(data.createFundingAccount.message); 
    },
  });

  const toggleCreateFundingAccountModal = (e) => {
    setShowAddFundingAccountModal(() => !showAddFundingAccountModal);
  }

  const addFundingAccountButtonDisabled =
    fundingAccountName === '' || fundingAmount < 0 || fundingAmount === undefined || fundingDescription === '';

  // We click the button to create a new progress diary event
  const handleAddEventButtonClick = async (e) => {
    await createFundingAccountDocument({
      variables: {
        projectId,
        input: {
          amount: fundingAmount,
          description: fundingDescription,
          accountName: fundingAccountName,
        },
      },
    });
    setShowAddFundingAccountModal(false);
  };

  return (
    <>
      <HeaderContainer>
        <HeaderFrame>
          <FundingAccountIcon />
          <Heading>{`Funding Accounts`}</Heading>
        </HeaderFrame>
        <HeaderFrame>
          <Button onClick={toggleCreateFundingAccountModal}>{`New`}</Button>
        </HeaderFrame>
      </HeaderContainer>
      <Modal isOpen={showAddFundingAccountModal} toggle={toggleCreateFundingAccountModal} backdrop>
        <ModalHeader toggle={toggleCreateFundingAccountModal}>
          <InputHeader>{`Create a Funding Account`}</InputHeader>
        </ModalHeader>
        <ModalBody>
          <InputContainer>
            <InputHeader>{`Account Name:`}</InputHeader>
            <TextAreaStyled
              value={fundingAccountName}
              onChange={(e) => setFundingAccountName(e.target.value)}
              placeholder="e.g. CAPEX"
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`Initial Funding Amount:`}</InputHeader>
            {/* See https://s-yadav.github.io/react-number-format/ */}
            <NumericInputStyles
              displayType="input"
              prefix={'$ '}
              value={fundingAmount}
              onValueChange={(values, sourceInfo) => {
                setFundingAmount(values.floatValue);
              }}
              placeholder="e.g. 123456"
              thousandSeparator=","
              decimalScale={2}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`Funding Description:`}</InputHeader>
            <TextAreaStyled
              value={fundingDescription}
              onChange={(e) => setFundingDescription(e.target.value)}
              placeholder="e.g. Contributed by Sponsor A"
            />
          </InputContainer>
          <FooterContainer>
            <Button
              onClick={handleAddEventButtonClick}
              disabled={addFundingAccountButtonDisabled}
            >
              {`Create`}
            </Button>
          </FooterContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FundingHeader;
