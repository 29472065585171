import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardSubtitle,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Form,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useQueryReference, useCreateReference } from '../../../hooks';
import ReferenceItem from './referenceItem';
import { OrganisationContext } from '../../../contexts';

const EMPTY_ITEM = {
  name: '',
  description: '',
  limit: 20,
  items: [],
};
const Reference = () => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const [refModal, setRefModal] = useState(false);
  const [references, setReferences] = useState([]);
  const [referenceItem, setReferenceItem] = useState(EMPTY_ITEM);
  const { handleSubmit, register, errors, reset } = useForm();

  const { getReferences, loading } = useQueryReference({
    onCompleted: (data) => setReferences(data),
  });

  const [createReference] = useCreateReference({
    onCompleted: (data) => {
      const copy = [...references];
      copy.push(data);
      setReferences(copy);
      setReferenceItem(EMPTY_ITEM);
      reset();
      toggleRef();
    },
  });

  const addReference = () => {
    if (
      _.some(references, (reference) => reference.name === referenceItem.name)
    )
      return;
    createReference(referenceItem);
  };

  const onChange = (e, item) => {
    const { value } = e.target;
    setReferenceItem((prev) => ({ ...prev, [item]: value }));
  };

  const toggleRef = () => setRefModal(!refModal);

  useEffect(() => {
    if (_.has(currentOrganisation, '_id'))
      getReferences(currentOrganisation._id);
  }, [currentOrganisation]);

  return (
    <div>
      <Row className="mb-4">
        <Col xs="10">
          <h4 className="mb-3">
            <i className="fas fa-database mr-2" />
            Global Reference Data
          </h4>
        </Col>
        <Col xs="2">
          <Button
            disabled={loading}
            color="projx"
            onClick={toggleRef}
            className="btn float-right"
          >
            <i className="fas fa-plus mr-1" /> Create Reference
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <Col xs="4">
            <Card>
              <CardHeader>
                <Skeleton />
              </CardHeader>
              <CardBody>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </CardBody>
              <CardFooter>
                <Skeleton />
              </CardFooter>
            </Card>
          </Col>
          <Col xs="4">
            <Card>
              <CardHeader>
                <Skeleton />
              </CardHeader>
              <CardBody>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </CardBody>
              <CardFooter>
                <Skeleton />
              </CardFooter>
            </Card>
          </Col>
          <Col xs="4">
            <Card>
              <CardHeader>
                <Skeleton />
              </CardHeader>
              <CardBody>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </CardBody>
              <CardFooter>
                <Skeleton />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          {references.map((ref) => (
            <ReferenceItem key={ref._id} referenceItem={ref} />
          ))}
        </Row>
      )}

      <Modal isOpen={refModal} toggle={toggleRef} backdrop>
        <Form onSubmit={handleSubmit(addReference)}>
          <ModalHeader toggle={toggleRef}>
            Create Global Reference Model
          </ModalHeader>
          <ModalBody>
            <CardSubtitle>
              Limit: <code>{referenceItem.limit}</code>
            </CardSubtitle>
            <div className="mb-4 mt-2">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-edit" />
                  </InputGroupText>
                </InputGroupAddon>
                <input
                  name="name"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9-_]+$/,
                      message: 'Invalid. No spaces allowed.',
                    },
                  })}
                  placeholder="Reference name (no spaces)"
                  onChange={(e) => onChange(e, 'name')}
                  className="form-control"
                  type="text"
                />
              </InputGroup>
              <small className="badge badge-danger badge-info text-white float-left">
                {errors.name && errors.name.message}
              </small>
            </div>

            <div className="mb-2">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-edit" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="description"
                  placeholder="Reference description"
                  onChange={(e) => onChange(e, 'description')}
                  value={referenceItem.description}
                  className="form-control"
                  type="textarea"
                  rows="2"
                />
              </InputGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={referenceItem.name === ''}
              color="projx"
              type="submit"
            >
              Create
            </Button>
            <Button outline color="projx" onClick={toggleRef}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Reference;
