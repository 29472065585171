import { useContext } from 'react';
import PanelContext from '../contexts/panel/panelContext';

const usePanel = () => {
  const { content, setContent, menu, setMenu, resize, setResize } =
    useContext(PanelContext);
  return { content, menu, setContent, setMenu, resize, setResize };
};

export default usePanel;
