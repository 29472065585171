import React, { useState } from 'react';

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

export const TransactionContext = React.createContext({});

const TransactionProvider = ({ children }) => {
  const [transactions, setTransactions] = useState(undefined);

  return (
    <TransactionContext.Provider
      value={{
        transactions,
        setTransactions,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export default TransactionProvider;
