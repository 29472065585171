import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Table, Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import {
  BudgetHeader,
  BudgetFooter,
  BudgetType,
  disableForecast,
  BudgetVariationItem,
} from '..';

import { useQueryBudgetsByType } from '../../../../../hooks';
import {
  updateArrayByKey,
  copyArrayReference,
  howManyApproved,
  costStats,
} from '../../../../../common';
import { BudgetConfigContext } from '../../../../../contexts';

const BudgetVariationTable = ({
  project,
  view,
  requestForItem,
  details,
  onTotal = () => {},
  onValidate = () => {},
  createNewLineItem = () => {},
  costCode,
  claim,
  onChange = () => {},
}) => {
  const [variations, setVariations] = useState([]);

  // This is to do with our currency decimals:
  const { config } = useContext(BudgetConfigContext);
  //

  const { loading, getBudgetsByType, emptyBudgetItem, refetchBudgets } =
    useQueryBudgetsByType({
      costCode: details.costCode.code,
      projectId: details.project._id,
      vendorId: details.vendor._id,
      type: BudgetType.Variation,
      onCompleted: (data) => {
        setVariations(data);
      },
    });

  const onDelete = (id) => {
    const clone = copyArrayReference(variations);
    _.remove(clone, (i) => i._id === id);
    setVariations(clone);
  };

  const [totals, setTotals] = useState({
    forecast: {
      anticipated: 0,
      submitted: 0,
      recommended: 0,
    },
    committed: {
      approved: 0,
    },
    fcc: 0,
    expenditure: {
      allowed: 0,
      thisClaim: 0,
      remaining: 0,
      current: 0,
      previous: 0,
    },
  });

  const createItem = () => {
    const copy = [...variations];
    copy.push(
      emptyBudgetItem({
        type: BudgetType.Variation,
        vendor: details.vendor._id,
        project: details.project._id,
        costCode: details.costCode.code,
        accountsPayable: {
          key: costCode.accountsPayableCategory.name,
          value: costCode.accountsPayableCategory.value,
        },
        fundingSource: {
          key: costCode.fundingSourceCategory.name,
          value: costCode.fundingSourceCategory.value,
        },
      }),
    );
    setVariations(copy);
  };

  const onCalculationCompleted = (item) =>
    setVariations(updateArrayByKey(item, variations));

  const calculateTotals = () => {
    const clone = { ...totals };
    clone.forecast.anticipated = _.sumBy(variations, (item) =>
      disableForecast(item, 'anticipated') ? 0 : item.forecast.anticipated,
    );
    clone.forecast.submitted = _.sumBy(variations, (item) =>
      disableForecast(item, 'submitted') ? 0 : item.forecast.submitted,
    );
    clone.forecast.recommended = _.sumBy(variations, (item) =>
      disableForecast(item, 'recommended') ? 0 : item.forecast.recommended,
    );
    clone.committed.approved = _.sumBy(
      variations,
      (item) => item.committed.approved,
    );
    clone.fcc = _.sumBy(variations, (item) => item.fcc);

    clone.expenditure.allowed = _.sumBy(
      variations,
      (item) => item.expenditure.allowed,
    );
    clone.expenditure.thisClaim = _.sumBy(
      variations,
      (item) => item.expenditure.thisClaim,
    );
    clone.expenditure.remaining = _.sumBy(
      variations,
      (item) => item.expenditure.remaining,
    );
    clone.expenditure.current = _.sumBy(variations, (item) =>
      item.expenditure.current ? item.expenditure.current.amount : 0,
    );
    clone.expenditure.previous = _.sumBy(variations, (item) =>
      item.expenditure.previous && item.expenditure.previous.length > 0
        ? _.sumBy(item.expenditure.previous, (p) => p.amount)
        : 0,
    );

    setTotals(clone);
    onTotal({
      type: BudgetType.Variation,
      totals: {
        ffc: clone.fcc ? clone.fcc : 0,
        contracted: 0,
        adjustments: clone.committed.approved ? clone.committed.approved : 0,
        thisClaim: clone.expenditure.thisClaim,
        forecasts:
          clone.forecast.anticipated +
          clone.forecast.submitted +
          clone.forecast.recommended,
        expenditure: { ...clone.expenditure },
        count: variations.length,
        approved: howManyApproved(variations),
        costStats: costStats(variations),
      },
    });
  };

  const validate = () => {
    const validation = {
      [BudgetType.Variation]: [],
    };
    variations.forEach((variation) => {
      if (!variation.committed.approved || variation.committed.approved <= 0)
        validation[BudgetType.Variation].push({
          section: 'contract.committed.approved',
          message: `You have a variation line item "${variation.ref}" without an approved amount. Please provide an amout or remove the item prior to locking contract.`,
        });
    });
    onValidate(validation);
  };

  useEffect(() => getBudgetsByType(), []);

  useEffect(() => {
    if (requestForItem && requestForItem.value === BudgetType.Variation)
      createItem();
  }, [requestForItem]);

  useEffect(() => {
    calculateTotals();
    validate();
    onChange(variations);
  }, [variations]);

  useEffect(() => {
    if (claim) refetchBudgets();
  }, [claim]);

  return variations.length > 0 ? (
    <Row>
      <Col md={12}>
        <h5 className="mt-2 variations">
          <i className="fas fa-list" /> Variations
        </h5>
      </Col>
      <Col md={12}>
        {!loading ? (
          <div className="belvedere-responsive">
            <Table size="sm" hover className="budget-entry">
              <thead>
                <BudgetHeader view={view} type={BudgetType.Variation} />
              </thead>
              <tbody>
                {variations.map((item) => (
                  <BudgetVariationItem
                    project={project}
                    view={view}
                    onCalculationCompleted={onCalculationCompleted}
                    details={details}
                    item={item}
                    key={item._id}
                    onDelete={onDelete}
                  />
                ))}
              </tbody>
              <tfoot>
                <BudgetFooter
                  view={view}
                  type={BudgetType.Variation}
                  details={details}
                  value="variation"
                  totals={totals}
                  precision={config.decimal ? 2 : 0}
                  createNewLineItem={createNewLineItem}
                />
              </tfoot>
            </Table>
          </div>
        ) : (
          <Skeleton />
        )}
      </Col>
    </Row>
  ) : (
    <div />
  );
};

export default BudgetVariationTable;
