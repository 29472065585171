import React from 'react';
import { HeaderContainer, StatusFeedIcon, Heading } from './styles/Header';

const StatusHeader = () => {
  return (
    <HeaderContainer>
      <StatusFeedIcon />
      <Heading>{'Status Feed'}</Heading>
    </HeaderContainer>
  );
}

export default StatusHeader;
