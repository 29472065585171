import React from 'react';
import { ListGroup } from 'reactstrap';
import _ from 'lodash';

import ReportItem from './ReportItem';

const Reports = ({ reports, project }) => {
  return (
    <ListGroup className="contact-filter">
      {_.map(reports, (report) => (
        <ReportItem key={report.name} project={project} report={report} />
      ))}
    </ListGroup>
  );
};

export default Reports;
