import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 16px 32px 16px 8px;
  background-color: #F9F9F9;
  border-bottom: 1px solid #D9D9D9;
  color: #1B1B1B;
  font-family: 'Inter', sans-serif;
`;

export const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const HeaderButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-left: 16px;
  border: none;
  text-decoration: none;
  border-radius: 8px;
  background-color: #F9F9F9;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: #D9D9D9;
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 360px;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  margin-left: 16px;
  border-radius: 8px;
  border: 1px solid #1B1B1B;
`;

export const SearchBarInput = styled.input`
  padding: 4px;
  border: none;
  width: 100%;
  background-color: #F9F9F9;

  &::placeholder {
    color: #969696;
  }
`;
