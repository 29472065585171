import React from 'react';
import Header from '../../layouts/layout-components/header/header';
import Footer from '../../layouts/layout-components/footer/footer';

const NotFound = () => {
  return (
    <div
      id="main-wrapper"
      data-layout="vertical"
      className="page-wrapper d-block"
    >
      <Header content="settings" />
      <div className="error-box">
        <div className="error-body text-center">
          <div className="mt-4">
            <h1>
              Whoops! The resource you're looking for doesn't exist{' '}
              <span role="img" aria-label="Pensive Face">
                😔
              </span>
            </h1>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
