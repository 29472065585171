import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useUpdateUser } from '../../hooks';
import { UserContext } from '../../contexts';

const EditPersonalInfo = (props) => {
  const { user } = props;

  const { fetchUser } = useContext(UserContext);

  const initialData = {
    name: user.name || '',
    title: user.title || '',
    company: user.company || '',
    phone: user.phone || '',
  };

  const [data, setData] = useState(initialData);

  const [updateAccount, { data: updateUserData, loading }] = useUpdateUser();

  useEffect(() => {
    // when update completes refetch user in context
    if (!loading && updateUserData) fetchUser();
  }, [loading]);

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const updateAccountHandler = () => {
    updateAccount(data);
  };

  return (
    <Card>
      <CardBody>
        <Form>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder={user.name}
              value={data.name}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Company</Label>
            <Input
              type="text"
              id="company"
              name="company"
              placeholder="Enter the company that you belong to."
              onChange={handleInputChange}
              value={data.company}
            />
          </FormGroup>
          <FormGroup>
            <Label>Job Title</Label>
            <Input
              type="text"
              id="title"
              name="title"
              placeholder="Enter your Job Title. eg. Project Manager"
              onChange={handleInputChange}
              value={data.title}
            />
          </FormGroup>
          <FormGroup>
            <Label>Phone Number</Label>
            <Input
              type="text"
              id="phone"
              name="phone"
              placeholder="Enter your contact number."
              onChange={handleInputChange}
              value={data.phone}
            />
          </FormGroup>
          <Button
            color="success"
            onClick={updateAccountHandler}
            disabled={loading}
          >
            {loading ? 'Loading' : 'Update'}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};

export default EditPersonalInfo;
