import React from 'react';
import {
  Text,
  Document,
  Page,
  StyleSheet,
  Image,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';

import { GlobalStyles } from '../common';
import { Amount, Section } from '../../../pdf/common';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    textTransform: 'uppercase',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    paddingLeft: '30%',
  },
  name: {
    fontSize: 18,
    fontFamily: 'Lato Bold',
  },
  subtitle: {
    fontSize: 10,
    justifySelf: 'flex-end',
    fontFamily: 'Lato',
  },
  link: {
    fontFamily: 'Lato',
    fontSize: 10,
    color: 'black',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  logo: {
    width: 60,
    height: 60,
  },
  formDetailsLeft: {
    flexDirection: 'column',
    width: '60%',
    padding: 2,
    marginTop: 5,
    // border: 1,
    // borderStyle: "dotted",
    // borderColor: "black"
  },
  formDetailsLeft: {
    flex: 1,
    flexDirection: 'column',
    padding: 2,
    marginTop: 5,
    // border: 1,
    // borderStyle: "dotted",
    // borderColor: "black"
  },
  formDetailsRight: {
    flex: 1,
    flexDirection: 'column',
    padding: 2,
    marginTop: 5,
    // border: 1,
    // borderStyle: "dotted",
    // borderColor: "black"
  },
  detailsContainer: {
    flexDirection: 'row',

    // border: 1,
    // borderStyle: "dotted",
    // borderColor: "black"
  },
  theRestDetailsContainer: {
    flexDirection: 'row',
    height: 100,
  },
  detailsLabel: {
    fontSize: 9,
    fontFamily: 'Lato Bold',
  },
  detailsText: {
    fontSize: 9,
    fontFamily: 'Lato',
  },
  marginize: {
    marginTop: 3,
  },
  main: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  sectionText: {
    marginTop: 10,
    padding: 2,
    fontSize: 11,
    fontFamily: 'Lato Bold',
    backgroundColor: '#f0f0f0',
  },
  sectionFooter: {
    fontSize: 8,
    fontFamily: 'Lato Bold',
    color: 'grey',
    paddingTop: 3,
    marginTop: 3,
  },
  signature: {
    border: 1,
    borderStyle: 'dotted',
    borderColor: 'black',
    margin: 5,
    width: '200px',
    height: '50px',
  },
  longLabel: {
    fontSize: 9,
    fontFamily: 'Lato Bold',
  },
  footer: {
    fontSize: 8,
    fontFamily: 'Lato Bold',
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    color: 'grey',
    padding: 3,
    borderWidth: 1,
    borderColor: 'gray',
    borderStyle: 'dashed',
    '@media orientation: landscape': {
      marginTop: 10,
    },
  },
  gap: {
    height: '300px',
  },
  negative: {
    color: 'red',
  },
});

const Header = ({ name, details }) => {
  return (
    <View style={{ margin: 2 }}>
      <View style={styles.container}>
        <View style={styles.detailColumn}>
          <Text style={styles.name}>{name}</Text>
        </View>
        <View style={styles.linkColumn}>
          <Image
            style={styles.logo}
            src={`${
              details.organisationLogo
            }?noCache=${Math.random().toString()}`}
            source={{
              header: {
                'Access-Control-Allow-Origin': '*',
              },
            }}
          />
        </View>
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.formDetailsLeft}>
          <Text style={styles.detailsLabel}>Project Code:</Text>
          <Text style={styles.detailsLabel}>Project Name:</Text>
          <Text style={styles.detailsLabel}>Project Address:</Text>
          <Text style={styles.detailsLabel}>Client:</Text>
          <Text style={[styles.detailsLabel, styles.marginize]}>
            Budget Code:
          </Text>
          <Text style={styles.detailsLabel}>Vendor:</Text>
          <Text style={styles.detailsLabel}>Issue Date:</Text>
        </View>
        <View style={styles.formDetailsRight}>
          <Text style={styles.detailsText}>
            {details.projectCode ? details.projectCode : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.projectName ? details.projectName : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.projectAddress ? details.projectAddress : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.clientName ? details.clientName : '-'}
          </Text>
          <Text style={[styles.detailsText, styles.marginize]}>
            {details.costCode ? details.costCode : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.vendorName ? details.vendorName : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.issueDate ? details.issueDate : '-'}
          </Text>
        </View>
      </View>
    </View>
  );
};

const PaymentSchedule = ({ template, project }) => {
  const {
    costCode,
    vendorName,
    issueDate,
    originalContractSum,
    variationsApprovedToDate,
    provisionalSumAdjustment,
    adjustedContractSum,
    contractorPaymentReference,
    contractorPaymentClaimDate,
    totalAmountOfPaymentClaim,
    contractWorks,
    variationWorks,
    provisionalSums,
    workExecutedSubTotal,
    previouslyAssessed,
    thisClaim,
    gst,
    includingGst,
    reasonIfAmountsDiffer,
    comments,
    userName,
    userTitle,
    userCompany,
  } = template;

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={GlobalStyles.page}>
        <Header
          name="Payment Schedule"
          details={{
            organisationLogo: project.organisation.logo,
            projectCode: project.code,
            projectName: project.name,
            projectAddress: project.address,
            clientName: project.client.name,
            costCode,
            vendorName,
            issueDate,
          }}
        />

        <Section text="Contract Sum" />
        <View style={styles.detailsContainer}>
          <View style={styles.formDetailsLeft}>
            <Text style={styles.detailsLabel}>Original Contract Sum:</Text>
            <Text style={styles.detailsLabel}>
              Add/Deduct Variations Approved to Date:
            </Text>
            <Text style={styles.detailsLabel}>
              Add/Deduct Provisional Sum Adjustments:
            </Text>
            <Text style={styles.detailsLabel}>Adjusted Contract Sum:</Text>
          </View>
          <View style={styles.formDetailsRight}>
            <Amount value={originalContractSum} />
            <Amount value={variationsApprovedToDate} />
            <Amount value={provisionalSumAdjustment} />
            <Amount value={adjustedContractSum} />
          </View>
        </View>

        <Section text="Claimed Amount" />
        <View style={styles.detailsContainer}>
          <View style={styles.formDetailsLeft}>
            <Text style={styles.detailsLabel}>
              Contractor Payment Claimed Reference:
            </Text>
            <Text style={styles.detailsLabel}>
              Date of Contractor&apos;s Payment Claim:
            </Text>
            <Text style={styles.detailsLabel}>
              Total Amount of Payment Claim (ex. GST):
            </Text>
          </View>
          <View style={styles.formDetailsRight}>
            <Text style={styles.detailsText}>{contractorPaymentReference}</Text>
            <Text style={styles.detailsText}>
              {moment(contractorPaymentClaimDate).format('Do MMM YYYY')}
            </Text>
            <Amount value={totalAmountOfPaymentClaim} />
          </View>
        </View>

        <Section text="Value of Work Executed" />
        <View style={styles.detailsContainer}>
          <View style={styles.formDetailsLeft}>
            <Text style={styles.detailsLabel}>
              Contractor Works (ex. Provisional Sums):
            </Text>
            <Text style={styles.detailsLabel}>Variation Works:</Text>
            <Text style={styles.detailsLabel}>Provisional Sums:</Text>
            <Text style={styles.detailsLabel}>Sub-Total:</Text>
          </View>
          <View style={styles.formDetailsRight}>
            <Amount value={contractWorks} />
            <Amount value={variationWorks} />
            <Amount value={provisionalSums} />
            <Amount value={workExecutedSubTotal} />
          </View>
        </View>

        <Section text="Certified Amount" />
        <View style={styles.detailsContainer}>
          <View style={styles.formDetailsLeft}>
            <Text style={styles.detailsLabel}>
              Less Amount Certified in Previous payment Certificates:
            </Text>
            <Text style={styles.detailsLabel}>
              Payment Amount Certified This Claim (ex. GST):
            </Text>
            <Text style={styles.detailsLabel}>
              Goods and Services Tax (10%)
            </Text>
            <Text style={styles.detailsLabel}>
              Payment Amount Certified This Claim (inc. GST):
            </Text>
          </View>
          <View style={styles.formDetailsRight}>
            <Amount value={previouslyAssessed} />
            <Amount value={thisClaim} />
            <Amount value={gst} />
            <Amount value={includingGst} />
          </View>
        </View>

        <View style={styles.detailsContainer}>
          <Text style={styles.longLabel}>{reasonIfAmountsDiffer}</Text>
        </View>

        <Section text="Comments" />
        <View style={styles.detailsContainer}>
          <View style={styles.formDetailsRight}>
            <Text style={styles.longLabel}>{comments}</Text>
          </View>
        </View>

        <Section text="Prepared By" />
        <View style={styles.theRestDetailsContainer}>
          <View style={styles.formDetailsRight}>
            <Text style={styles.longLabel}>{userName}</Text>
            <Text style={styles.longLabel}>
              {userCompany} ({userTitle})
            </Text>
            <Text style={styles.sectionFooter}>
              This payment certificate is a &apos;Payment Schedule&apos; for the
              purpose of, and made under, the Building and Construction Industry
              Security of Payment Act (VIC) 2002. This payment certificate is
              not evidence or to the value of worker an admission of liability
              or evidence that work has been executed satisfactorily. All
              amounts noted in this Payment Schedule are exclusive of GST unless
              noted.
            </Text>
          </View>
        </View>

        <Text style={styles.footer}>
          Payment Schedule Form generated by Projx on{' '}
          {moment(Date.now()).format('Do MMM YYYY')} at{' '}
          {moment(Date.now()).format('hh:mm')}
        </Text>
      </Page>
    </Document>
  );
};

export default PaymentSchedule;
