import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _ from 'lodash';

import Paginator from 'react-hooks-paginator';
import TransactionItem from './TransactionItem';
import { useTransaction } from '../../../hooks';

const Transactions = ({ project, costCode, onTransferAction = () => {} }) => {
  const pageLimit = 5;

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [transactionsState, setTransactionsState] = useState(undefined);

  const { getTransactions, transactions } = useTransaction({
    onCompleted: () => {},
  });

  useEffect(() => getTransactions(project._id), [transactionsState]);

  useEffect(() => {
    if (transactions) {
      const filtered = _.filter(transactions, (cc) => {
        if (
          costCode.costCode.code === cc.destination.costCode.code ||
          costCode.costCode.code === cc.source.costCode.code
        )
          return true;
        return false;
      });
      setCurrentData(filtered.slice(offset, offset + pageLimit));
    }
  }, [offset, transactions, costCode]);

  return (
    <>
      <PerfectScrollbar>
        {currentData && currentData.length > 0 ? (
          currentData.map((item) => (
            <TransactionItem
              transactionsState={transactionsState}
              setTransactionsState={setTransactionsState}
              key={item._id}
              item={item}
              costCode={costCode}
              onTransferAction={onTransferAction}
            />
          ))
        ) : (
          <div className="d-flex mt-3 p-4 flex-column align-items-center justify-content-center">
            <i className="ti-agenda display-5" />
            <h4 className="mt-2">No transactions to display ...</h4>
          </div>
        )}
      </PerfectScrollbar>

      <Paginator
        totalRecords={transactions ? transactions.length : 0}
        pageLimit={5}
        pageNeighbours={1}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default Transactions;
