import React from 'react';
import {
  StatusCardStyle,
  StatusCardInfo,
  StatusCardNumber,
  StatusCardText,
  StatusCardIconContainer,
} from './styles/StatusCards';

/**
 * Renders one status card
 * @param {number} number The amount we want to display
 * @param {string} header The heading for our status card
 * @returns 
 */
const StatusCard = ({ number, header, gradient, icon }) => {
  return (
    <StatusCardStyle gradient={gradient}>
      <StatusCardInfo>
        <StatusCardNumber
          start={0}
          end={number}
          duration={4}
          separator={','}>
        </StatusCardNumber>
        <StatusCardText>
          {header}
        </StatusCardText>
      </StatusCardInfo>
      <StatusCardIconContainer>
        {icon}
      </StatusCardIconContainer>
    </StatusCardStyle>
  );
}

export default StatusCard;
