import React, { useContext } from 'react';
import { Row, Col, Card, Nav, CardHeader } from 'reactstrap';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import Footer from '../../layouts/layout-components/footer/footer';
import UserList from '../../components/settings/users/UserList';
import Customization from '../../components/settings/profile/Customization';
import OrgProfile from '../../components/settings/users/OrgProfile';
import RoleList from '../../components/settings/roles/RoleList';
import { OrganisationContext } from '../../contexts';
import Header from '../../layouts/layout-components/header/header';

import Skeleton from 'react-loading-skeleton';

const settingsRoutes = [
  {
    default: true,
    name: 'General',
    path: '/settings-v2/organisation',
    icon: 'fas fa-address-card',
    component: OrgProfile,
  },
  {
    name: 'Customization',
    path: '/settings-v2/customization',
    icon: 'fab fa-nintendo-switch',
    component: Customization,
  },
  {
    name: 'Members',
    path: '/settings-v2/users',
    icon: 'fa fa-users',
    component: UserList,
  },
  {
    path: '/settings-v2/roles',
    name: 'Roles',
    icon: 'fa fa-key',
    component: RoleList,
  },
];

const Settings = () => {
  const location = useLocation();
  const { currentOrganisation } = useContext(OrganisationContext);

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? 'selected' : '';
  };

  if (!currentOrganisation) return <Skeleton />;

  return (
    <div
      id="main-wrapper"
      data-layout="vertical"
      className="page-wrapper d-block"
    >
      <Header content="settings" />

      <Row className="page-content container-fluid justify-content-center">
        <Col md="2">
          <Card>
            <CardHeader>
              <h4>{currentOrganisation.name} Settings</h4>
            </CardHeader>
            <div className="sidebar-nav">
              <Nav vertical>
                {settingsRoutes.map((route) => (
                  <li
                    className={`${activeRoute(route.path)} sidebar-item`}
                    style={{ width: 'auto' }}
                  >
                    <NavLink
                      to={route.path}
                      className="sidebar-link"
                      activeClassName="active"
                      style={{ color: '#212529' }}
                    >
                      <h3>
                        <i
                          style={{ color: '#212529' }}
                          className={route.icon}
                        />
                      </h3>{' '}
                      {route.name}
                    </NavLink>
                  </li>
                ))}
              </Nav>
            </div>
          </Card>
        </Col>
        <Col md="6">
          <Switch>
            {settingsRoutes.map((route) => (
              <Route path={route.path} component={route.component} />
            ))}
            <Redirect to={settingsRoutes.find((route) => route.default).path} />
          </Switch>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default Settings;
