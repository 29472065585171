import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactTable from 'react-table-v6';
import treeTableHOC from 'react-table-v6/lib/hoc/treeTable';
import { CSVLink } from 'react-csv';
import {
  Card,
  CardBody,
  CardHeader,
  CardLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Label,
} from 'reactstrap';
// import "react-table/react-table.css";
import _ from 'lodash';
import { useSummary } from '../../../hooks';
import { currencify } from '../../../common';
import TableLoader from '../../loaders/TableLoader';

const TreeTable = treeTableHOC(ReactTable);

const headers = [
  {
    label: 'Budget Category',
    key: 'group.budgetCategory.name',
  },
  {
    label: 'Budget Codes',
    key: 'group.costCode.code',
  },
  {
    label: 'Budget Description',
    key: 'group.costCode.name',
  },
  {
    label: 'Original',
    key: 'summary.originalBudget',
  },
  {
    label: 'Transfer',
    key: 'summary.budgetTransfer',
  },
  {
    label: 'Current',
    key: 'summary.currentBudget',
  },
  {
    label: 'Unapproved Contract',
    key: 'summary.forecasts.contract',
  },
  {
    label: 'Unapproved Prov. Sum',
    key: 'summary.forecasts.provisionalSum',
  },
  {
    label: 'Unapproved Variations',
    key: 'summary.forecasts.variations',
  },
  {
    label: 'Unapproved Total',
    key: 'summary.forecasts.total',
  },
  {
    label: 'Approved Contract',
    key: 'summary.committments.contract',
  },
  {
    label: 'Approved Prov. Sum',
    key: 'summary.committments.provisionalSum',
  },
  {
    label: 'Approved Let',
    key: 'summary.committments.let',
  },
  {
    label: 'Approved Adjustments',
    key: 'summary.committments.adjustments',
  },
  {
    label: 'Approved Variations',
    key: 'summary.committments.variations',
  },
  {
    label: 'Approved Total',
    key: 'summary.committments.total',
  },
  {
    label: 'Position',
    key: 'summary.position.approvedPosition',
  },
  {
    label: 'Total',
    key: 'summary.ffc',
  },
  {
    label: 'Position',
    key: 'summary.position.fccPosition',
  },
  // {
  //     label: "This Month",
  //     key: "summary.expenditure.thisMonth",
  // },
  {
    label: 'Cumulative',
    key: 'summary.expenditure.cumulative', // key: "summary.expenditure.cumulative",
  },
  {
    label: 'Budget To Complete',
    key: 'summary.expenditure.budgetCostToComplete',
  },
  {
    label: 'Approved To Complete',
    key: 'summary.expenditure.committedCostToComplete',
  },
  {
    label: 'Forecast To Complete',
    key: 'summary.expenditure.forecastToComplete',
  },
];

const ProjectSummary = ({ project }) => {
  const [summary, setSummary] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [exp, setExpanded] = useState(undefined);

  const [columnsVisibility, setColumnsVisibility] = useState({
    unapproved: false,
    approved: false,
    expenditure: false,
    budget: false,
    ffc: false,
  });

  const reactTableRef = useRef(null);

  const { getSummary, loading } = useSummary({
    onCompleted: (data) => {
      _.forEach(data, (item) => {
        item.group.costCodeString = 'Cost Code';
      });
      setSummary(data);
    },
  });

  const filteredSummary = _.filter(
    summary,
    (item) =>
      item.group.costCode.code !== 'Total' &&
      item.group.costCode.name !== 'Total',
  );

  const currencifyCell = (props) => {
    const { value, row } = props;
    const { isTest } = row._original;

    if (value && value > 0)
      return (
        <div
          style={{
            textAlign: 'right',
            fontWeight: isTest ? 'bolder' : 'normal',
          }}
        >
          {currencify(value, false, 0)}
        </div>
      );
    if (value && value < 0)
      return (
        <div
          style={{
            textAlign: 'right',
            fontWeight: isTest ? 'bolder' : 'normal',
          }}
          className="text-danger"
        >
          ({currencify(value, false, 0)})
        </div>
      );
    return <div style={{ textAlign: 'right' }}>-</div>;
  };

  const handleExpandedChange = (newExpanded) => {
    setExpanded(newExpanded);
  };
  const groupSize = () =>
    _.map(_.groupBy(summary, 'group.budgetCategory.name')).length;

  const expanded = (expandState) => {
    setExpanded(
      _.map(_.groupBy(summary, 'group.budgetCategory.name'), () => expandState),
    );
  };

  const toggleSummary = () => setToggle(!toggle);

  const headerClicked = (headerText, type) => (
    <Label
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setColumnsVisibility((prev) => ({ ...prev, [type]: !prev[type] }));
      }}
    >
      {headerText}{' '}
      {columnsVisibility[type] ? (
        <i className="fas fa-minus-circle" />
      ) : (
        <i className="fas fa-plus-circle" />
      )}
    </Label>
  );

  useEffect(() => {
    getSummary(project._id);
  }, []);

  useEffect(() => {
    expanded(toggle);
  }, [toggle]);

  useEffect(() => {
    expanded(true);
  }, [summary]);

  return (
    <Card>
      <CardHeader><h3>Project Summary</h3></CardHeader>
      <CardBody className="border-bottom">
        <UncontrolledDropdown className="float-right">
          <DropdownToggle
            className="float-right ml-3 btn-mywhite"
            color="white"
          >
            <i className="mdi mdi-export" /> Export
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Export</DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <i className="fas fa-file-pdf text-danger" /> PDF
            </DropdownItem>
            <DropdownItem>
              <CSVLink
                data={filteredSummary}
                headers={headers}
                filename={`${project.name}.csv`}
              >
                <i className="fas fa-file-excel text-success" /> XLSX
              </CSVLink>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <Button
          className="float-right"
          outline
          color="projx"
          onClick={toggleSummary}
        >
          {toggle ? 'Collapse' : 'Expand'}
        </Button>
      </CardBody>
      <CardBody>
        {!loading ? (
          <TreeTable
            ref={reactTableRef}
            data={summary}
            showPagination={false}
            pageSize={groupSize()}
            defaultPageSize={5}
            onExpandedChange={handleExpandedChange}
            // expanded={toggle ? expanded() : collapse()}
            expanded={exp}
            //expanded={exp ? exp : toggle ? expanded(true) : expanded(false)}
            sortable={false}
            pivotBy={['group.budgetCategory.name']}
            columns={[
              {
                Header: ' ',
                columns: [
                  {
                    accessor: 'group.budgetCategory.name',
                    width: 25,
                  },
                ],
              },
              // any other columns we want to display
              {
                Header: '',
                columns: [
                  {
                    Header: 'Cost Code',
                    accessor: 'group',
                    width: 100,
                    Cell: ({ value }) => {
                      if (!value.isTest)
                        return (
                          <CardLink
                            href={`/projects/${project._id}/commitments?costCode=${value.costCode.code}`}
                          >
                            {value.costCode.code}
                          </CardLink>
                        );
                      return '';
                    },
                  },
                  {
                    Header: 'Cost Name',
                    accessor: '',
                    width: 180,
                    Cell: ({ value }) => {
                      if (!value.isTest)
                        return (
                          <CardLink
                            href={`/projects/${project._id}/commitments?costCode=${value.group.costCode.code}`}
                          >
                            {value.group.costCode.name}
                          </CardLink>
                        );
                      return <div>{value.group.costCode.name}</div>;
                    },
                  },
                ],
              },
              {
                Header: () => headerClicked('Budget', 'budget'),
                headerClassName: 'budget-header font-12',
                columns: [
                  {
                    Header: 'Original',
                    Cell: currencifyCell,
                    accessor: 'summary.originalBudget',
                    headerClassName: 'budget font-12',
                    show: columnsVisibility.budget,
                  },
                  {
                    Header: 'Transfer',
                    Cell: currencifyCell,
                    accessor: 'summary.budgetTransfer',
                    headerClassName: 'budget font-12',
                    show: columnsVisibility.budget,
                  },
                  {
                    Header: 'Current',
                    Cell: currencifyCell,
                    accessor: 'summary.currentBudget',
                    headerClassName: 'budget font-12',
                  },
                ],
              },
              {
                Header: () => headerClicked('Unapproved', 'unapproved'),
                headerClassName: 'forecast-header font-12',
                columns: [
                  {
                    Header: 'Contract',
                    Cell: currencifyCell,
                    accessor: 'summary.forecasts.contract',
                    headerClassName: 'forecast font-12',
                    show: columnsVisibility.unapproved,
                  },
                  {
                    Header: 'Prov. Sum',
                    Cell: currencifyCell,
                    accessor: 'summary.forecasts.provisionalSum',
                    headerClassName: 'forecast font-12',
                    show: columnsVisibility.unapproved,
                  },
                  {
                    Header: 'Variations',
                    Cell: currencifyCell,
                    accessor: 'summary.forecasts.variations',
                    headerClassName: 'forecast font-12',
                    show: columnsVisibility.unapproved,
                  },
                  {
                    Header: 'Total',
                    Cell: currencifyCell,
                    accessor: 'summary.forecasts.total',
                    headerClassName: 'forecast font-12',
                  },
                ],
              },
              {
                Header: () => headerClicked('Approved', 'approved'),
                headerClassName: 'committed-header font-12',
                columns: [
                  {
                    Header: 'Contract',
                    Cell: currencifyCell,
                    accessor: 'summary.committments.contract',
                    headerClassName: 'committed font-12',
                    show: columnsVisibility.approved,
                  },
                  {
                    Header: 'Prov. Sum',
                    Cell: currencifyCell,
                    accessor: 'summary.committments.provisionalSum',
                    headerClassName: 'committed font-12',
                    show: columnsVisibility.approved,
                  },
                  {
                    Header: 'Let',
                    Cell: currencifyCell,
                    accessor: 'summary.committments.let',
                    headerClassName: 'committed font-12',
                    show: columnsVisibility.approved,
                  },
                  {
                    Header: 'Adjustments',
                    Cell: currencifyCell,
                    accessor: 'summary.committments.adjustments',
                    headerClassName: 'committed font-12',
                    show: columnsVisibility.approved,
                  },
                  {
                    Header: 'Variations',
                    Cell: currencifyCell,
                    accessor: 'summary.committments.variations',
                    headerClassName: 'committed font-12',
                    show: columnsVisibility.approved,
                  },
                  {
                    Header: 'Total',
                    Cell: currencifyCell,
                    accessor: 'summary.committments.total',
                    headerClassName: 'committed font-12',
                  },
                  {
                    Header: 'Position',
                    Cell: currencifyCell,
                    accessor: 'summary.position.approvedPosition',
                    headerClassName: 'committed font-12',
                    show: columnsVisibility.approved,
                  },
                ],
              },
              {
                // Header: () => (<div>FFC{" "}<i className="fas fa-info-circle" title="Forecast Final Cost" /></div>),
                Header: () => headerClicked('Forecast Final Cost', 'ffc'),
                headerClassName: 'ffc-header font-12',
                columns: [
                  {
                    Header: 'Amount',
                    Cell: currencifyCell,
                    accessor: 'summary.ffc',
                    headerClassName: 'ffc font-12',
                  },
                  {
                    Header: 'Position',
                    Cell: currencifyCell,
                    accessor: 'summary.position.fccPosition',
                    headerClassName: 'ffc font-12',
                    show: columnsVisibility.ffc,
                  },
                ],
              },
              {
                Header: () => headerClicked('Expenditure', 'expenditure'),
                headerClassName: 'expenditure-header-header font-12',
                columns: [
                  {
                    Header: 'Cumulative', // Header: "This Month",
                    Cell: currencifyCell,
                    accessor: 'summary.expenditure.cumulative',
                    headerClassName: 'expenditure-header font-12',
                  },
                  // {
                  //     Header: "Cumulative",
                  //     Cell: currencifyCell,
                  //     accessor: "summary.expenditure.cumulative",
                  //     headerClassName: "expenditure-header font-12",
                  //     show: columnsVisibility.expenditure
                  // },
                  {
                    Header: 'Budget To Complete',
                    Cell: currencifyCell,
                    accessor: 'summary.expenditure.budgetCostToComplete',
                    headerClassName: 'expenditure-header font-12',
                    show: columnsVisibility.expenditure,
                  },
                  {
                    Header: 'Approved To Complete',
                    Cell: currencifyCell,
                    accessor: 'summary.expenditure.committedCostToComplete',
                    headerClassName: 'expenditure-header font-12',
                    show: columnsVisibility.expenditure,
                  },
                  {
                    Header: 'Forecast To Complete',
                    Cell: currencifyCell,
                    accessor: 'summary.expenditure.forecastToComplete',
                    headerClassName: 'expenditure-header font-12',
                    show: columnsVisibility.expenditure,
                  },
                ],
              },
            ]}
          />
        ) : (
            <TableLoader />
        )}
      </CardBody>
    </Card>
  );
};

export default ProjectSummary;
