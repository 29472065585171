import React, { useContext } from 'react';
import { Card, CardBody, CardHeader, Row, Col, Label } from 'reactstrap';
import { OrganisationContext } from '../../../contexts';
import { ImageType } from '../../../hooks';

import UploadPhoto from '../../global/UploadPhoto';

const Customization = () => {
  const { currentOrganisation, setCurrentOrganisation } =
    useContext(OrganisationContext);

  const onUploadCompleted = (data) => {
    currentOrganisation.logo = data.url;
    setCurrentOrganisation(currentOrganisation);
  };

  return (
    <Card>
      <CardHeader>
        <h3>Customization</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="3">
            <Label className="font-14 mb-3">Org Logo</Label>
            <img
              src={currentOrganisation.logo}
              alt="user"
              className="d-block pb-5"
              width="150"
            />
            <UploadPhoto
              onUploadCompleted={onUploadCompleted}
              imageType={ImageType.Organisation}
              identifier={currentOrganisation._id}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Customization;
