import moment from 'moment';

const date = new Date();

const financialYearRange = () => {
  const startMonthName = 'July';
  const endMonthName = 'June';
  if (moment().quarter() === 4) {
    return {
      current: {
        from: moment()
          .month(startMonthName)
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment()
          .add(1, 'year')
          .month(endMonthName)
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      last: {
        from: moment()
          .subtract(1, 'year')
          .month(startMonthName)
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment().month(endMonthName).endOf('month').format('YYYY-MM-DD'),
      },
    };
  }
  return {
    current: {
      from: moment()
        .subtract(1, 'year')
        .month(startMonthName)
        .startOf('month')
        .format('YYYY-MM-DD'),
      to: moment().month(endMonthName).endOf('month').format('YYYY-MM-DD'),
    },
    last: {
      from: moment()
        .subtract(2, 'year')
        .month(startMonthName)
        .startOf('month')
        .format('YYYY-MM-DD'),
      to: moment()
        .subtract(1, 'year')
        .month(endMonthName)
        .endOf('month')
        .format('YYYY-MM-DD'),
    },
  };
};

const dateRanges = () => {
  return [
    {
      value: {
        from: '1970-01-01',
        to: moment(date).endOf('month').format('YYYY-MM-DD'),
      },
      label: 'Beginning of Time',
    },
    {
      value: {
        from: moment(date).startOf('month').format('YYYY-MM-DD'),
        to: moment(date).endOf('month').format('YYYY-MM-DD'),
      },
      label: 'This Month',
    },
    {
      value: {
        from: moment(date).startOf('quarter').format('YYYY-MM-DD'),
        to: moment(date).endOf('quarter').format('YYYY-MM-DD'),
      },
      label: 'This Quarter',
    },
    {
      value: {
        from: financialYearRange().current.from,
        to: financialYearRange().current.to,
      },
      label: 'This Financial Year',
    },
    {
      value: {
        from: moment(date)
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment(date)
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      label: 'Last Month',
    },
    {
      value: {
        from: moment(date)
          .subtract(1, 'quarter')
          .startOf('quarter')
          .format('YYYY-MM-DD'),
        to: moment(date)
          .subtract(1, 'quarter')
          .endOf('quarter')
          .format('YYYY-MM-DD'),
      },
      label: 'Last Quarter',
    },
    {
      value: {
        from: moment(date).startOf('month').format('YYYY-MM-DD'),
        to: moment(date).format('YYYY-MM-DD'),
      },
      label: 'Month To Date',
    },
    {
      value: {
        from: moment(date).startOf('quarter').format('YYYY-MM-DD'),
        to: moment(date).format('YYYY-MM-DD'),
      },
      label: 'Quarter To Date',
    },
    {
      value: {
        from: moment(date).startOf('year').format('YYYY-MM-DD'),
        to: moment(date).format('YYYY-MM-DD'),
      },
      label: 'Year To Date',
    },
    {
      value: {
        from: moment(date).format('YYYY-MM-DD'),
        to: moment(date).format('YYYY-MM-DD'),
      },
      label: 'Custom',
    },
  ];
};

const defaultComparisonPeriod = (date) => {
  return moment(date).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
};
const comparisonPeriods = () => {
  return [
    {
      value: undefined,
      label: 'None',
    },
    {
      value: 1,
      label: '1 Period',
    },
    {
      value: 2,
      label: '2 Periods',
    },
    {
      value: 3,
      label: '3 Perods',
    },
    {
      value: 4,
      label: '4 Periods',
    },
  ];
};

export { dateRanges, comparisonPeriods, defaultComparisonPeriod };
