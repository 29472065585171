import React from 'react';
import { FilterContainer, FilterItem, FilterLabel, FilterInput, FilterFooter } from './styles/Filter';

/**
 * This is our primary container for our filter component, which is used to create filter option selections
 * @param {React.JSX} children The children of our component 
 * @returns Our primary filter component
 */
const Filter = ({ children }) => {
  return (
    <FilterContainer>
      {children}
    </FilterContainer>
  );
}

/**
* This component represents a 'row' in our filter component, normally containing as children our <Filter.Label> and the second child
* being our arbitrary input which the label corresponds to wrapped in a <Filter.Input>
* @param {React.JSX} children The children of our component  
* @returns 
 */
Filter.Item = ({ children }) => {
  return (
    <FilterItem>
      {children}
    </FilterItem>
  );
}

/**
 * The label component for our filter input, note however that our input may not necessarily be an <input> tag.
 * But for accessability reasons we render a label nevertheless.
 * @param {React.JSX} children The children of our component 
 * @param {string} id The id attribute is used to linked a label component to an input, helps with accessability
 * @returns A label for the arbitrary input for our filter component
 */
Filter.Label = ({ children, id }) => {
  return (
    <FilterLabel id={id}>
      {children}
    </FilterLabel>
  )
}

/**
 * This component contains the arbitrary input(s) to display for our label in our filter component
 * @param {React.JSX} children The children of our component 
 * @param {number} minWidth The minimum width of our component in pixels
 * @returns Our input container for our inputs for the particular label in our filter component
 */
Filter.Input = ({ children, minWidth }) => {
  return (
    <FilterInput minWidth={minWidth}>
      { children}
    </FilterInput>
  )
}

/**
 * The footer for our filter component, typically containing a 'Reset' button and a 'Apply' button, for
 * resetting and applying filters respectively.
 * @param {React.JSX} children The children of our component 
 * @returns Our footer container for our filter component
 */
Filter.Footer = ({ children }) => {
  return (
    <FilterFooter>
      {children}
    </FilterFooter>
  )
}

export default Filter;
