import gql from 'graphql-tag';

/**
 * Queries
 */
const ORGANISATION_MEMBERS_QUERY = gql`
  query ($_id: ID!) {
    getOrganisation(_id: $_id) {
      owner {
        _id
      }
      members {
        isAdmin
        user {
          _id
          name
          email
          image
          title
          handle
        }
      }
    }
  }
`;

/**
 * Mutations
 */
const ADD_ORG_MEMBER_MUTATION = gql`
  mutation ($_id: ID!, $member: MemberInput!) {
    addOrgMember(_id: $_id, member: $member)
  }
`;

const UPDATE_ORG_MEMBER_MUTATION = gql`
  mutation ($_id: ID!, $member: MemberInput!) {
    updateOrgMember(_id: $_id, member: $member)
  }
`;

const DELETE_ORG_MEMBER_MUTATION = gql`
  mutation ($_id: ID!, $userId: ID!) {
    deleteOrgMember(_id: $_id, userId: $userId)
  }
`;

const LEAVE_ORG_MUTATION = gql`
  mutation ($_id: ID!) {
    leaveOrg(_id: $_id)
  }
`;

const DELETE_ORG_MUTATION = gql`
  mutation ($_id: ID!) {
    deleteOrg(_id: $_id)
  }
`;

const SAVE_ORG_MUTATION = gql`
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
    company
  }

  mutation ($_id: ID!, $input: OrgKeyValue!) {
    saveOrg(_id: $_id, input: $input) {
      _id
      name
      logo
      owner {
        ...UserDetails
      }
      config {
        projectLimit
        trialPeriodEnding
        paid
      }
    }
  }
`;

export {
  ORGANISATION_MEMBERS_QUERY,
  ADD_ORG_MEMBER_MUTATION,
  UPDATE_ORG_MEMBER_MUTATION,
  DELETE_ORG_MEMBER_MUTATION,
  SAVE_ORG_MUTATION,
  DELETE_ORG_MUTATION,
  LEAVE_ORG_MUTATION,
};
