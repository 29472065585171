import React, { useEffect, useState } from 'react';
import {
  Button,
  CardFooter,
  Col,
  Label,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Badge,
  Collapse,
} from 'reactstrap';
import _ from 'lodash';
import VendorBranchContact from './VendorBranchContact';

const VendorCard = ({ vendor, onSelect = () => {} }) => {
  const [open, setOpen] = useState(undefined);

  const toggle = (id) => {
    setOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  useEffect(() => {
    const branches = {};
    _.forEach(vendor.branches, (branch) => {
      branches[branch.branchId] = false;
    });
    setOpen(branches);
  }, []);

  return (
    <Col xs="12" md="4">
      <Card>
        <CardBody>
          <CardTitle>
            {vendor.name}, <i className="text-muted">{vendor.representative}</i>{' '}
            <Label className="font-12">
              <b className="text-danger">
                {vendor.archived ? '(Archived)' : ''}
              </b>
            </Label>
          </CardTitle>
          <CardSubtitle>{vendor.address.suburb}</CardSubtitle>
        </CardBody>
        {!_.isEmpty(open) ? (
          <CardBody className="border-top">
            {_.map(vendor.branches, (branch) => (
              <div className="mb-1" key={branch.branchId}>
                <CardTitle
                  className="text-info"
                  onClick={() => toggle(branch.branchId)}
                >
                  <i
                    className={
                      open[branch.branchId]
                        ? 'fas fa-chevron-circle-down'
                        : 'fas fa-chevron-circle-right'
                    }
                  />{' '}
                  {branch.branchName}
                </CardTitle>
                <Collapse isOpen={open[branch.branchId]}>
                  {_.map(branch.contacts, (contact, idx) => (
                    <VendorBranchContact
                      key={idx}
                      contact={contact}
                      contactIndex={idx}
                    />
                  ))}
                </Collapse>
              </div>
            ))}
          </CardBody>
        ) : (
          ''
        )}
        {vendor.tags && vendor.tags.length > 0 ? (
          <CardBody className="border-top">
            <div>
              {_.map(vendor.tags, (tag, idx) => (
                <Badge key={idx} className="float-left mr-2" color="warning">
                  {tag}
                </Badge>
              ))}
            </div>
          </CardBody>
        ) : (
          ''
        )}
        <CardFooter>
          <Label className="font-12 float-left">ABN: {vendor.abn}</Label>
          <Button
            className="btn-sm float-right"
            color="projx"
            onClick={(e) => onSelect(vendor)}
          >
            <i className="fas fa-edit" />
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default VendorCard;
