import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const SafetyNumericInputStyles = styled(NumberFormat)`
  font-family: 'Inter', sans-serif;
  color: #1B1B1B;
  background-color: #F9F9F9;
  width: 110px;
  border: none;
  text-align: right;
`;
