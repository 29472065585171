import { useContext, useState } from 'react';
import _ from 'lodash';
import { UseBudgetSettingsQuery } from './index';

import { ProjectCtx, GlobalReferenceContext } from '../contexts';

const useBudgetSetting = () => {
  const [loaded, setIsLoaded] = useState(false);
  const { budgetSettings, setBudgetSettings } = useContext(ProjectCtx);
  const globalContext = useContext(GlobalReferenceContext);

  const getBudgetSetting = (key) => {
    const { budgetSettings, loaded } = UseBudgetSettingsQuery(key);
    setBudgetSettings(budgetSettings);
    setIsLoaded(loaded);
    return budgetSettings;
  };

  const getBudgetReferences = (overrideWithGlobal = false) => {
    if (overrideWithGlobal) return globalContext.getBudgetReferences();
    return budgetSettings.items.map((item) => {
      return { value: item.key, label: item.costCode };
    });
  };

  const getCategoryText = (key) =>
    _.get(
      _.find(getBudgetReferences(), (bc) => bc.value === key),
      'label',
    );

  return {
    loaded,
    budgetSettings,
    getCategoryText,
    getBudgetReferences,
    getBudgetSetting,
  };
};

export default useBudgetSetting;
