import styled from 'styled-components';
import { MdInfoOutline } from 'react-icons/md';

export const InfoIcon = styled(MdInfoOutline)`
  width: 25px;
  height: 25px;
`;

export const InfoButton = styled.span`
  background-color: #F9F9F9;
  border: none;
  width: 35px;
  height: 35px;
  margin: 0px 2px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #D9D9D9;
  }
`;