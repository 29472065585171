import React, { useEffect, useState, useContext } from 'react';
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';

import ReactTable from 'react-table-v6';
import treeTableHOC from 'react-table-v6/lib/hoc/treeTable';
import { currencify } from '../../../../../common';
import {
  useFocotrInvoices,
  useAddFocotrInvoiceDocument,
} from '../../../../../hooks';
import { DocumentListModal } from '../../../..';
import { processClaimDocumentCategoryOptions } from '../../../../../helpers';
import UserContext from '../../../../../contexts/user/user.context';
import { PaymentSchedule } from '../../../../forms';
import FormViewer from '../../../../forms/FormViewer';

const TreeTable = treeTableHOC(ReactTable);

const Invoices = ({ toggle = () => {}, open, item, project }) => {
  const { user } = useContext(UserContext);

  const [alteredInvoices, setInvoices] = useState([]);

  const [generated, setGenerated] = useState(undefined);
  const [forms, setForms] = useState({ paymentSchedule: false });

  // Document stuff
  const [selectedInvoiceDocuments, setSelectedInvoiceDocuments] = useState([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState();
  const [isDocumentListModelOpen, setDocumentListModel] = useState(false);
  const [isDocumentUploadComplete, setDocumentUploadComplete] = useState(false);

  const toggleDocumentListModal = () =>
    setDocumentListModel(!isDocumentListModelOpen);

  const [addFocotrInvoiceDocument, { loading }] = useAddFocotrInvoiceDocument({
    onCompleted: (document) => {
      setSelectedInvoiceDocuments((d) => [...d, document]);
      setDocumentUploadComplete(true);
    },
  });

  const onDocumentButtonClicked = (invoiceId) => {
    setSelectedInvoiceDocuments(
      _.get(
        _.find(alteredInvoices, (invoice) => invoice._id === invoiceId),
        'documents',
      ),
    );
    setSelectedInvoiceId(invoiceId);
    toggleDocumentListModal();
  };

  const onDocumentUpload = (documentInput) => {
    setDocumentUploadComplete(false);
    addFocotrInvoiceDocument(item._id, selectedInvoiceId, documentInput);
  };

  const { getFocotrInvoices } = useFocotrInvoices({
    onCompleted: (invoices) => {
      const altered = _.map(invoices, (invoice) => ({
        ...invoice,
        monthAndYear: moment(invoice.date).format('MMM YYYY'),
      }));

      setInvoices(altered);
    },
  });

  const groupSize = () =>
    _.map(_.groupBy(alteredInvoices, 'monthAndYear')).length;
  const expanded = () =>
    _.map(_.groupBy(alteredInvoices, 'monthAndYear'), () => true);

  const currencifyCell = ({ value }) => {
    if (value && value > 0)
      return (
        <div style={{ textAlign: 'right' }}>{currencify(value, false, 2)}</div>
      );
    if (value && value < 0)
      return (
        <div style={{ textAlign: 'right' }} className="text-danger">
          ({currencify(value, false, 2)})
        </div>
      );
    return <div style={{ textAlign: 'right' }}>-</div>;
  };

  const viewPaymentSchedule = ({ comments, paymentSchedule }) => {
    setGenerated(
      <PaymentSchedule
        template={{
          ...paymentSchedule,
          costCode: `${item.costCode.code} - ${item.costCode.name}`,
          vendorName: item.vendor.name,
          userName: user.name,
          userTitle: user.title,
          userCompany: user.company,
          comments: comments,
        }}
        project={project}
      />,
    );
    toggleForm('paymentSchedule');
  };

  const toggleForm = (type) => {
    setForms((prev) => {
      const isVisible = !prev[type];
      if (!isVisible) setGenerated(undefined);
      return {
        ...prev,
        [type]: isVisible,
      };
    });
  };

  useEffect(() => {
    if (!open) return;
    getFocotrInvoices(item._id);
  }, [open]);

  return (
    <>
      <Modal
        isOpen={open}
        toggle={toggle}
        backdrop
        size="lg"
        style={{ maxWidth: '1000px', width: '100%' }}
      >
        <ModalHeader toggle={toggle}>{item.vendor.name} Invoices</ModalHeader>
        <ModalBody>
          <TreeTable
            data={alteredInvoices}
            showPagination={false}
            defaultPageSize={5}
            pageSize={groupSize()}
            expanded={expanded()}
            className="-striped -highlight"
            pivotBy={['monthAndYear']}
            columns={[
              {
                accessor: 'monthAndYear',
              },
              {
                Header: 'Invoice Id',
                accessor: 'invoiceId',
                Cell: ({ value }) => {
                  return <div style={{ textAlign: 'center' }}>{value}</div>;
                },
              },
              {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ value }) => {
                  return (
                    <div style={{ textAlign: 'center' }}>
                      {moment(value).format('Do MMM YYYY')}
                    </div>
                  );
                },
              },
              {
                Header: 'Claimed Amount',
                accessor: 'claimedAmount',
                Cell: currencifyCell,
              },
              {
                Header: 'Total Claimed',
                accessor: 'totalAmount',
                Cell: currencifyCell,
              },
              {
                Header: 'GST',
                accessor: '',
                Cell: ({ value }) => {
                  return (
                    <div style={{ textAlign: 'center' }}>
                      {value.gstType === 'percentage' ? (
                        <div>
                          {value.gstValue} <Badge color="info">%</Badge>
                        </div>
                      ) : (
                        <div>
                          <Badge color="success">$</Badge> {value.gstValue}
                        </div>
                      )}
                    </div>
                  );
                },
              },
              {
                Header: 'Quantity Surveyor',
                accessor: '',
                Cell: ({ value }) => {
                  return value.qsRecommendation ? (
                    <div style={{ textAlign: 'right' }}>
                      <Badge color="warning">
                        {currencify(value.qsAmount, false, 2)}
                      </Badge>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>N/A</div>
                  );
                },
              },
              {
                Header: 'Documents',
                accessor: '',
                Cell: ({ value }) => {
                  return (
                    <div
                      style={{ alignContent: 'center', textAlign: 'center' }}
                    >
                      <Button
                        className="justify-content-center"
                        color="projx"
                        size="sm"
                        onClick={() => onDocumentButtonClicked(value._id)}
                      >
                        <i className="fas fa-file" />
                      </Button>
                    </div>
                  );
                },
              },
              {
                Header: 'Payment Schedule',
                accessor: '',
                Cell: ({ value }) => {
                  return (
                    value.paymentSchedule && (
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          className="btn btn-sm"
                          color="projx"
                          onClick={() => viewPaymentSchedule(value)}
                        >
                          Schedule
                        </Button>
                      </div>
                    )
                  );
                },
              },
              {
                Header: 'Created',
                accessor: '',
                Cell: ({ value }) => {
                  return (
                    <div style={{ textAlign: 'center' }}>
                      {moment(value.timestamp).fromNow(true)} ago
                    </div>
                  );
                },
              },
            ]}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="projx">
            Close
          </Button>
        </ModalFooter>
        <DocumentListModal
          documents={selectedInvoiceDocuments}
          onDocumentUpload={onDocumentUpload}
          documentUploadComplete={isDocumentUploadComplete}
          documentUploadLoading={loading}
          isOpen={isDocumentListModelOpen}
          toggle={toggleDocumentListModal}
          header="Documents"
          categoryOptions={processClaimDocumentCategoryOptions}
        />
      </Modal>
      <FormViewer
        key="frmInvoicesPaymentSchedule"
        toggle={() => toggleForm('paymentSchedule')}
        open={forms.paymentSchedule}
        generated={generated}
      />
    </>
  );
};

export default Invoices;
