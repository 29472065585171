import React, { Component } from 'react';
import {} from 'reactstrap';

import 'react-tagsinput/react-tagsinput.css';
import Select from 'react-select';

import {
  groupedOptions,
  IndicatorsContainer,
  constructionTypes,
} from '../common';

export default class WelcomeStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: props.getStore().project,
      sectors: [],
      constructionType: undefined,
    };

    this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

    this.onConstructionTypeSelected =
      this.onConstructionTypeSelected.bind(this);
    this.validationCheck = this.validationCheck.bind(this);
    this.isValidated = this.isValidated.bind(this);
    this.onSelectSector = this.onSelectSector.bind(this);
  }
  isValidated() {
    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator
    let isDataValid = false;

    // if full validation passes then save to store and pass as valid
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === true;
      })
    ) {
      this.props.updateStore({
        project: {
          ...userInput,
        },
        savedToCloud: false, // use this to notify step4 that some changes took place and prompt the user to save again
      }); // Update store here (this is just an example, in reality you will do it via redux or flux)

      isDataValid = true;
    } else {
      // if anything fails then update the UI validation state but NOT the UI Data State
      this.setState(
        Object.assign(
          userInput,
          validateNewInput,
          this._validationErrors(validateNewInput),
        ),
      );
    }

    return isDataValid;
  }

  validationCheck() {
    if (!this._validateOnDemand) return;

    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator

    this.setState(
      Object.assign(
        userInput,
        validateNewInput,
        this._validationErrors(validateNewInput),
      ),
    );
  }

  onSelectSector(tags) {
    this.setState({
      sectors: tags,
    });
  }

  onConstructionTypeSelected(selected) {
    this.setState({
      constructionType: selected.value,
    });
  }

  _validateData(data) {
    return {
      projectNameVal: data.name !== '',
    };
  }

  _validationErrors(val) {
    const errMsgs = {
      projectNameValMsg: val.projectNameVal
        ? ''
        : 'A valid project name is required',
    };
    return errMsgs;
  }

  _grabUserInput() {
    return {
      name: this.projectName.value,
      address: this.projectAddress.value,
      code: this.projectCode.value,
      sectors: this.state.sectors,
    };
  }

  render() {
    // explicit class assigning based on validation
    let notValidClasses = {};

    if (
      typeof this.state.projectNameVal === 'undefined' ||
      this.state.projectNameVal
    ) {
      notValidClasses.projectNameCls = 'form-control';
    } else {
      notValidClasses.projectNameCls = 'is-invalid form-control';
      notValidClasses.projectNameValGrpCls = 'text-danger';
    }
    return (
      <div className="step step1 mt-5 ">
        <div className="row justify-content-md-center">
          <div className="col col-lg-6">
            <div className="">
              <h4>
                <code>Hey!</code> Let's get started with some basics ...
              </h4>
              <form id="Form" className="form-horizontal mt-2">
                <div className="form-group content form-block-holder">
                  <div>
                    <input
                      maxLength={200}
                      minLength={5}
                      ref={(f) => {
                        this.projectName = f;
                      }}
                      autoComplete="off"
                      type="text"
                      placeholder="eg. Metricon Highrise CBD Project..."
                      className={notValidClasses.projectNameCls}
                      required
                      defaultValue={this.state.project.name}
                      onBlur={this.validationCheck}
                    />
                    <small className="badge badge-default badge-projx text-white float-left">
                      Project Name
                    </small>
                  </div>
                </div>

                <div className="form-group content form-block-holder mt-5">
                  <div>
                    <input
                      maxLength={500}
                      minLength={0}
                      ref={(f) => {
                        this.projectAddress = f;
                      }}
                      autoComplete="off"
                      type="text"
                      placeholder="eg. 1771 Princess Highway, Melbourne"
                      className="form-control"
                      defaultValue={this.state.project.address}
                    />
                    <small className="badge badge-default badge-projx text-white float-left">
                      Address
                    </small>
                  </div>
                </div>
                <div className="form-group content form-block-holder mt-5 border-top pt-5">
                  <div>
                    <input
                      maxLength={500}
                      minLength={0}
                      ref={(f) => {
                        this.projectCode = f;
                      }}
                      autoComplete="off"
                      type="text"
                      placeholder="eg. PROJ001"
                      style={{ width: '200px' }}
                      className="form-control"
                      defaultValue={this.state.project.code}
                    />
                    <small className="badge badge-default badge-projx text-white float-left">
                      Project Code
                    </small>
                  </div>
                </div>
                <div className="form-group content form-block-holder mt-5">
                  <div>
                    <Select
                      defaultValue={
                        this.props.getStore().project.constructionType
                      }
                      options={constructionTypes}
                      onChange={this.onConstructionTypeSelected}
                      style={{ width: '100px' }}
                      closeMenuOnSelect={true}
                    />

                    <small className="badge badge-default badge-projx text-white float-left">
                      Construction Type
                    </small>
                  </div>
                </div>
                <div className="form-group content form-block-holder mt-5">
                  <div>
                    <Select
                      defaultValue={this.props.getStore().project.sectors}
                      options={groupedOptions}
                      components={{ IndicatorsContainer }}
                      onChange={(tags) => this.onSelectSector(tags)}
                      isMulti
                      style={{ width: '300px' }}
                      closeMenuOnSelect={true}
                    />

                    <small className="badge badge-default badge-projx text-white float-left">
                      Project-relevant Industries
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
