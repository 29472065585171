import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { Main, Table, Period } from '../common';
import { ReportType } from '../../data';
import { useReport } from '../../../../hooks';

const Invoicing = ({ template }) => {
  return (
    <Main template={template} name={'Invoicing'}>
      <Period period={template.period} />
      <Table table={template.table} />
    </Main>
  );
};

export default Invoicing;
