const password = [
  {
    test: (value) => {
      return value.length >= 8;
    },
    message: 'Password must be at least 8 characters',
  },
  {
    test: /^(.*[a-z].*)+$/,
    message: 'Password must contain a lowercase character',
  },
  {
    test: /^(.*[A-Z].*)+$/,
    message: 'Password must contain an uppercase character',
  },
  {
    test: /^(.*[0-9].*)+$/,
    message: 'Password must contain a number',
  },
  {
    test: /^(.*[!@#$%^&*()].*)+$/,
    message: 'Password must contain a special character',
  },
];

const confirmPassword = [
  {
    test: ({ passwordValue, confirmPasswordValue }) => {
      return passwordValue === confirmPasswordValue;
    },
    message: "Passwords don't match",
  },
];

export default { password, confirmPassword };
