const COMMITMENT_STATUS = [
  {
    value: 'uncommitted',
    label: 'Uncommitted',
  },
  {
    value: 'committed',
    label: 'Committed',
  },
];

export { COMMITMENT_STATUS };
