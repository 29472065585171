import React, { useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import AuthContext from '../auth/auth.context';
import UserContext from './user.context';
import { cleanGqlTypename } from '../../common';
import { GET_USER_PROFILE } from '../../graphql/queries';

const UserProvider = (props) => {
  const { children } = props;
  const { id } = useContext(AuthContext);

  const [getUserProfile, { loading, data, error, refetch }] =
    useLazyQuery(GET_USER_PROFILE);

  useEffect(() => {
    getUserProfile();
  }, [id]);

  return (
    <UserContext.Provider
      value={{
        user: _.get(cleanGqlTypename(data), 'profileView') || null,
        loading: !data || loading,
        error,
        fetchUser: refetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
