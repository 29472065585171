import React from 'react';
import { StatusBadgeContainer } from './styles/StatusBadge';

/**
 * This component is used to render the 'Status' column of our milestone table
 * @param {React.JSX} children The children of our element
 * @param {string} backgroundColor The string of the background color for our status badge 
 * @returns 
 */
const StatusBadge = ({ children, backgroundColor }) => {
  return (
    <StatusBadgeContainer backgroundColor={backgroundColor}>{children}</StatusBadgeContainer>
  );
}

export default StatusBadge;
