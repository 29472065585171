import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

export const LoginPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #1B1B1B;
  height: 100vh;
  font-family: 'Inter', sans-serif;
`;

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 64px;
  background-color: #F9F9F9;

  ${(props) => props.center && css`
    width: 100%;
  `}

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%:
`;

export const LoginFormBody = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  max-width: 592px;
`;

export const LoginFormHeading = styled.p`
  width: 100%;
  font-weight: 600;
  font-size: 57px;
  line-height: 68px;
  margin: 32px 0;
`;

export const LoginFormInputLabel = styled.label`
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0;
  width: 100%;
`;

export const LoginFormInput = styled.input`
  width: 100%;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #969696;

  &:focus {
    border: 1px solid #1B1B1B;
  }
`;

export const LoginFormErrorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LoginFormErrorText = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 2px 0;
  width: 100%;
  color: #E7224A;
`;

export const LoginFormInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 16px 0;
`;

export const LoginFormLink = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #E7224A;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #E7224A;
  }
`;

export const LoginFormText = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0;

  ${(props) => props.center && css`
    text-align: center;
  `}
`;

export const LoginFormBoldText = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0;
  display: inline;
  font-weight: 500;
`;

export const LoginFormEmailText = styled.div`
  font-weight: 600;
  margin: 16px 0;
`;

export const LoginFormButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  color: #F9F9F9;
  background-color: #E7224A;
  font-size: 16px;
  line-height: 24px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 8px 0;

  ${(props) => props.disabled && css`
    background-color: #FBBAC7;
    cursor: not-allowed;
  `}
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 64px;
  background-color: #E7224A;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const LoginPageImage = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 16px 0;
  align-self: center;
`;

export const LoginPageCarouselButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px auto 0 auto;
  width: 64px;
`;

export const LoginPageCarouselHeading = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #F9F9F9;
  width: 100%;
  text-align: center;
  margin: 8px 0;
`;

export const LoginPageCarouselText = styled.p`
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  text-align: center;
  color: #F9F9F9;
  width: 100%;
  margin: 8px 0;
`;

// Fade animation
const fadeIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

export const FadeAnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${fadeIn} 2s;
  height: 70vh;
`;

export const LoginPageCarouselButton = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #FBBAC7;
  cursor: pointer;

  ${(props) => props.active && css`
    background-color: #F9F9F9;
  `}
`;
