import React from 'react';
import { Button, Card, CardBody, CardFooter, CardTitle } from 'reactstrap';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useStream } from '../../../hooks';

const ActivityStream = ({ projectId }) => {
  // Remove eslint-disable comment for when error variable is used:
  const [getActivities, { data, loading, error }] = useStream(projectId); // eslint-disable-line no-unused-vars
  const fetch = async () => {
    await getActivities(data.pageInfo.endCursor);
  };

  return (
    <Card>
      <CardBody className="py-0 px-0">
        {/* <div className="d-md-flex align-items-center">
          <div>
            <CardTitle>Projects Activity Stream</CardTitle>
          </div>
        </div> */}
        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <div
            className="steamline mt-0 scrollable"
            style={{ height: '320px', width: '100%' }}
          >
            <PerfectScrollbar
              onYReachEnd={!loading && data.pageInfo.hasNextPage && fetch}
            >
              {_.map(data.activities, (activity, idx) => (
                <div
                  key={idx}
                  className="sl-item pl-4 mr-3"
                  style={{ border: 0, borderBottom: '1px solid #d6deeb' }}
                >
                  <div className="sl-left">
                    {' '}
                    <img
                      src={activity.actor.avatarUrl}
                      alt="user"
                      className="rounded-circle"
                    />{' '}
                  </div>
                  <div className="sl-right mb-3">
                    <div>
                      <a href="/" className="link">
                        {activity.actor.displayName}
                      </a>
                      <span className="sl-date ml-1">
                        {moment(activity.created).fromNow(true)} ago
                      </span>
                      <p>{activity.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </PerfectScrollbar>
          </div>
        )}
      </CardBody>
      {/* <CardFooter>
        <Button
          color="projx"
          onClick={fetch}
          disabled={loading || !data.pageInfo.hasNextPage}
        >
          More
        </Button>
      </CardFooter> */}
    </Card>
  );
};
export default ActivityStream;
