import React from 'react';
import _ from 'lodash';
// Status Card
import { StatusCardsStyle } from './styles/StatusCards';
import StatusCard from './statusCard';
// GraphQL
import { useQuery } from '@apollo/client';
import { GET_STATUS_CARD_DATA_BY_PROJECT } from '../../../graphql/queries';
// Icons
import { PieGraphIcon, FutureIcon, TransferIcon, AddIcon, MoneyIcon, ChangeIcon } from './styles/StatusCardIcons';

/**
 * The keys of this object map to the data for our status cards to a particular title
 * based on the 'getStatusCardData(projectId: ID!)' GraphQL Query
 *
 * This allows us to dynamically map backend data structures to react props, based on
 * the 'StatusCardData' TypeScript interface in our models
 */
const headerMap = {
  totalCurrentBudget: 'Current Budget',
  totalFFC: 'Final Forecast Cost',
  totalForecastCostToComplete: 'Forecast Cost To Complete',
  totalApprovedVariations: 'Approved Variations',
  totalCumulativeExpenditure: 'Cumulative Expenditure',
  totalFFCPosition: 'FFC Position',
};

const iconMap = {
  totalCurrentBudget: <PieGraphIcon />,
  totalFFC: <FutureIcon />,
  totalForecastCostToComplete: <AddIcon />,
  totalApprovedVariations: <ChangeIcon />,
  totalCumulativeExpenditure: <TransferIcon />,
  totalFFCPosition: <MoneyIcon />,
}

/**
 * Renders all the status cards for a particular project
 * @param {string} projectId The ID of the project for which we want status cards for
 * @returns
 */
const StatusCards = ({ projectId }) => {
  const { loading, error, data } = useQuery(GET_STATUS_CARD_DATA_BY_PROJECT, {
    variables: { projectId },
  });

  if (loading) return <></>;

  return (
    <StatusCardsStyle>
      <StatusCard
        gradient="lunada"
        header={headerMap.totalCurrentBudget}
        number={JSON.parse(data.getStatusCardData).totalCurrentBudget}
        icon={iconMap.totalCurrentBudget}
      />
      <StatusCard
        gradient="dimigo"
        header={headerMap.totalFFC}
        number={JSON.parse(data.getStatusCardData).totalFFC}
        icon={iconMap.totalFFC}
      />
      <StatusCard
        gradient="quepal"
        header={headerMap.totalForecastCostToComplete}
        number={JSON.parse(data.getStatusCardData).totalForecastCostToComplete}
        icon={iconMap.totalForecastCostToComplete}
      />
      <StatusCard
        gradient="citrus"
        header={headerMap.totalApprovedVariations}
        number={JSON.parse(data.getStatusCardData).totalApprovedVariations}
        icon={iconMap.totalApprovedVariations}
      />
      <StatusCard
        gradient="ash"
        header={headerMap.totalCumulativeExpenditure}
        number={JSON.parse(data.getStatusCardData).totalCumulativeExpenditure}
        icon={iconMap.totalCumulativeExpenditure}
      />
      <StatusCard
        gradient="purpink"
        header={headerMap.totalFFCPosition}
        number={JSON.parse(data.getStatusCardData).totalFFCPosition}
        icon={iconMap.totalFFCPosition}
      />
    </StatusCardsStyle>
  );
};

export default StatusCards;
