import { useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import {
  GET_CASHFLOW_QUERY,
  GET_CASHFLOWS_QUERY,
  GET_CASHFLOW_PROJECT_COSTCODE_QUERY,
  UPDATE_ESTIMATE_MUTATION,
  CREATE_CASHFLOW_MUTATION,
  UPDATE_TYPED_ESTIMATE_MUTATION,
  UPDATE_AUTO_ESTIMATE_MUTATION,
  CHANGE_PROJECTION_BY_MUTATION, 
  GET_SUMMARY_ITEM_QUERY
} from '../../graphql/queries';

import { cleanGqlTypename, toBase64 } from '../../common';

const itemMapper = (item) => cleanGqlTypename(item);

const mapper = (items) => {
  return _.map(items, (item) => itemMapper(item));
};

export const useCashflow = ({ onCompleted = () => {} }) => {
  const Actions = {
    CreateCashflow: 'CREATE_CASHFLOW',
    UpdateEstimate: 'UPDATE_CASHFLOW_ESTIMATE',
    UpdateAutoEstimate: 'UPDATE_CASHFLOW_AUTO_ESTIMATE',
    UpdateTypedEstimate: 'UPDATE_TYPED_CASHFLOW_ESTIMATE',
    GetCashflow: 'GET_CASHFLOW',
    GetCashflows: 'GET_CASHFLOWS',
    GetCashflowByProjectCostCode: "GET_CASHFLOW_PROJECT_COSTCODE",
    GetSummaryItem: "GET_SUMMARY_ITEM",
    ChangeProjectionBy: "CHANGE_PROJECTION_BY"
  };

  const [_getCashflows, { loading: getCashflowsLoading }] = useLazyQuery(GET_CASHFLOWS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      onCompleted({
        action: Actions.GetCashflows,
        data: itemMapper(_.get(data, 'getCashflows')),
      }),
  });

  const [_getCashflowByProjectCostCode, { loading: getCashflowProjectCostCodeLoading }] = useLazyQuery(GET_CASHFLOW_PROJECT_COSTCODE_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      onCompleted({
        action: Actions.GetCashflowByProjectCostCode,
        data: itemMapper(_.get(data, 'getCashflowByProjectAndCostCode')),
      }),
  });

  const [_getCashflow, { loading: getCashflowLoading }] = useLazyQuery(GET_CASHFLOW_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      onCompleted({
        action: Actions.GetCashflow,
        data: itemMapper(_.get(data, 'getCashflow')),
      }),
  });

  const [_getSummaryItem, { loading: getSummaryItemLoading }] = useLazyQuery(GET_SUMMARY_ITEM_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      onCompleted({
        action: Actions.GetSummaryItem,
        data: itemMapper(_.get(data, 'getSummaryItem')),
      }),
  });

  const [_updateEstimate, { loading: updateEstimateLoading }] = useMutation(
    UPDATE_ESTIMATE_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted({
          action: Actions.UpdateEstimate,
          data: itemMapper(_.get(data, 'updateEstimate')),
        }),
    },
  );

  const [_updateAutoEstimate, { loading: updateAutoEstimateLoading }] = useMutation(
    UPDATE_AUTO_ESTIMATE_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted({
          action: Actions.UpdateAutoEstimate,
          data: itemMapper(_.get(data, 'autoEstimates')),
        }),
    },
  );

  const [_changeProjectionBy, { loading: changeProjectionByLoading }] = useMutation(
    CHANGE_PROJECTION_BY_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted({
          action: Actions.ChangeProjectionBy,
          data: itemMapper(_.get(data, 'changeProjectionBy')),
        }),
    },
  );

  const [_updateTypedEstimate, { loading: updateTypedEstimateLoading }] = useMutation(
    UPDATE_TYPED_ESTIMATE_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted({
          action: Actions.UpdateTypedEstimate,
          data: itemMapper(_.get(data, 'updateTypedEstimate')),
        }),
    },
  );

  const [_createCashflow, { loading: createCashflowLoading }] = useMutation(
    CREATE_CASHFLOW_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted({
          action: Actions.CreateCashflow,
          data: itemMapper(_.get(data, 'createCashflow')),
        }),
    },
  );

  const getCashflows = (projectId, { window = 5, startDate = Date.now()}) => {
    return _getCashflows({
      variables: {
        projectId,
        params: {
          window,
          startDate
        }
      },
    });
  };

  const getCashflow = ({ cashflowId, window = 5, startDate = Date.now()}) => {
    return _getCashflow({
      variables: {
        cashflowId,
        window,
        startDate
      }
    });
  };

  const getSummaryItem = ({ cashflowId, window = 5, startDate = Date.now()}) => {
    return _getSummaryItem({
      variables: {
        cashflowId,
        window,
        startDate
      }
    });
  };


  const getCashflowByProjectCostCode = ({ projectId, costCodeId, window = 5, startDate = Date.now()}) => {
    return _getCashflowByProjectCostCode({
      variables: {
        projectId,
        costCodeId,
        window,
        startDate
      }
    });
  };

  const updateEstimate = ({ cashflowId, window = 5, startDate = Date.now()}, { month, year, value }) => {
    return _updateEstimate({
      variables: {
        cashflow: {
          cashflowId,
          window,
          startDate
        },
        estimate: {
          month,
          year,
          value
        }
      },
    });
  };

  const updateAutoEstimate = ({ cashflowId, window = 5, startDate = Date.now()}, estimates) => {
    return _updateAutoEstimate({
      variables: {
        cashflow: {
          cashflowId,
          window,
          startDate
        },
        estimate: estimates
      },
    });
  };

  const changeProjectionBy = ({ cashflowId, window = 5, startDate = Date.now()}, { type, value }) => {
    return _changeProjectionBy({
      variables: {
        cashflow: {
          cashflowId,
          window,
          startDate
        },
        byType: {
          type,
          value
        }
      },
    });
  };

  const updateTypedEstimate = ({ cashflowId, window = 5, startDate = Date.now()}, { month, year, value, type }) => {
    return _updateTypedEstimate({
      variables: {
        cashflow: {
          cashflowId,
          window,
          startDate
        },
        estimate: {
          month,
          year,
          value,
          type
        }
      },
    });
  };

  const createCashflow = ({ projectId, costCodeId }) => {
    return _createCashflow({
      variables: {
        projectId,
        costCodeId
      },
    });
  };

  return {
    getCashflows,
    getCashflow,
    updateEstimate,
    updateTypedEstimate,
    updateAutoEstimate,
    createCashflow,
    getCashflowByProjectCostCode,
    getSummaryItem,
    changeProjectionBy,
    changeProjectionByLoading,
    getSummaryItemLoading,
    getCashflowsLoading,
    getCashflowLoading,
    updateEstimateLoading,
    updateTypedEstimateLoading,
    updateAutoEstimateLoading,
    createCashflowLoading,
    getCashflowProjectCostCodeLoading,
    Actions,
  };
};

export default useCashflow;
