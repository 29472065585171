import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import {
  HorizontalDotsIcon,
  DashboardIcon,
  FinancialsIcon,
  CostSummaryIcon,
  FundingIcon,
  BudgetIcon,
  CostAndClaimsIcon,
  CostReportIcon,
  CashflowIcon,
  ProgramIcon,
  MilestoneIcon,
  ProgressDiaryIcon,
  LookaheadIcon,
  StatusIcon,
  SafetyIcon,
  RiskIcon,
  DocumentIcon,
  SettingIcon,
  ProjectProfileIcon,
  CollaboratorIcon,
} from '../../../layouts/layout-components/sidebar/styles/SidebarIcons';
import {
  SidebarItemContainer,
  SidebarText,
} from '../../../layouts/layout-components/sidebar/styles/Sidebar';

// eslint-disable-next-line no-undef
const { REACT_APP_ENABLE_PROJECT_DASHBOARD } = process.env;

const buildProjectMenu = ({ _id, logo }) => {
  return [
    {
      key: 'dashboard',
      icon: <DashboardIcon />,
      display: 'Dashboards',
      path: `/projects/${_id}/portfolio`,
      state: 'dashboardAggregate',
      collapse: true,
      child: [
        {
          key: 'financial-dashboard',
          // icon: 'fas fa-list',
          icon: <DashboardIcon subitem={true} />,
          logo,
          display: 'Financial Dashboard',
          path: `/projects/${_id}/financial-dashboard`,
        },
        {
          key: 'program-dashboard',
          // icon: 'fas fa-list',
          icon: <DashboardIcon subitem={true} />,
          logo,
          display: 'Program Dashboard',
          path: `/projects/${_id}/program-dashboard`,
        },
      ],
    },
    {
      key: 'financials',
      // icon: 'fas fa-cogs',
      icon: <FinancialsIcon />,
      display: 'Financials',
      path: `/projects/${_id}/financials`,
      state: 'financialsAggregate',
      collapse: true,
      child: [
        {
          key: 'summary',
          // icon: 'fas fa-list',
          icon: <CostSummaryIcon subitem={true} />,
          logo,
          display: 'Cost Summary',
          path: `/projects/${_id}/summary`,
        },
        {
          key: 'funding',
          // icon: 'fas fa-dollar-sign',
          icon: <FundingIcon subitem={true} />,
          display: 'Funding',
          path: `/projects/${_id}/funding`,
        },
        {
          key: 'budgets',
          // icon: 'fas fa-balance-scale',
          icon: <BudgetIcon subitem={true} />,
          display: 'Budgets',
          path: `/projects/${_id}/budgets`,
        },
        {
          key: 'contracts',
          // icon: 'fas fa-chart-line',
          icon: <CostAndClaimsIcon subitem={true} />,
          display: 'Costs & Claims',
          path: `/projects/${_id}/commitments`,
        },
        {
          key: 'reports',
          // icon: 'fas fa-chart-pie',
          icon: <CostReportIcon subitem={true} />,
          display: 'Cost Reports',
          path: `/projects/${_id}/reports`,
        },
        {
          key: 'cashflows',
          // icon: 'fas fa-code-branch',
          icon: <CashflowIcon subitem={true} />,
          display: 'Cashflows',
          path: `/projects/${_id}/cashflows`,
        },
      ],
    },
    {
      key: 'program',
      icon: <ProgramIcon />,
      display: 'Program',
      path: `/projects/${_id}/program`,
      state: 'programAggregate',
      collapse: true,
      child: [
        {
          key: 'milestones',
          icon: <MilestoneIcon subitem={true} />,
          display: 'Milestones',
          path: `/projects/${_id}/milestones`,
        },
        {
          key: 'progressDiary',
          icon: <ProgressDiaryIcon subitem={true} />,
          display: 'Progress Diary',
          path: `/projects/${_id}/progress-diary`,
        },
        {
          key: 'lookahead',
          icon: <LookaheadIcon subitem={true} />,
          display: 'Lookahead',
          path: `/projects/${_id}/lookahead`,
        },
        {
          key: 'status',
          icon: <StatusIcon subitem={true} />,
          display: 'Status',
          path: `/projects/${_id}/status`,
        },
      ]
    },
    {
      key: 'safety',
      icon: <SafetyIcon />,
      display: 'Safety',
      path: `/projects/${_id}/safety`,
    },
    {
      key: 'risks',
      // icon: 'fas fa-exclamation-triangle',
      icon: <RiskIcon />,
      display: 'Risks',
      path: `/projects/${_id}/risks`,
    },
    {
      key: 'documents',
      // icon: 'fas fa-upload',
      icon: <DocumentIcon />,
      display: 'Documents',
      path: `/projects/${_id}/documents`,
    },
    {
      key: 'settings',
      // icon: 'fas fa-cogs',
      icon: <SettingIcon />,
      display: 'Settings',
      path: `/projects/${_id}/settings`,
      state: 'projectSettings',
      collapse: true,
      child: [
        {
          key: 'projectProfile',
          // icon: 'fas fa-building',
          icon: <ProjectProfileIcon subitem />,
          display: 'Project Profile',
          path: `/projects/${_id}/settings/profile`,
        },
        {
          key: 'projectUsers',
          // icon: 'fas fa-users',
          icon: <CollaboratorIcon subitem />,
          display: 'Collaborators',
          path: `/projects/${_id}/settings/users`,
        },
      ],
    },
  ];
};

const Menu = ({ project }) => {
  const location = useLocation();
  const [sidePanelState, setSidePanelState] = useState({});

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? 'selected' : '';
  };

  if (!project || _.isEmpty(project))
    return (
      <li>
        <Skeleton />
      </li>
    );

  return (
    <>
      <li key="project">
        <SidebarItemContainer break>
          <HorizontalDotsIcon />
          <SidebarText className="hide-menu">
            {_.truncate(project.name, { length: 24 })}
          </SidebarText>
        </SidebarItemContainer>
        {/* <i className="mdi mdi-dots-horizontal" />
        <span className="hide-menu">
          {_.truncate(project.name, { length: 15 })} FINANCIALS
        </span> */}
      </li>
      {buildProjectMenu(project)
        .filter(({ enabled = true }) => enabled === true)
        .map((item) => {
          if (item.break) {
            return (
              // <li className="nav-small-cap" key={item.key}>
              //   <i className={item.icon} />
              //   <span className="hide-menu">{item.display}</span>
              // </li>
              <SidebarItemContainer break={true}>
                {item.icon}
                <SidebarText className="hide-menu">{item.display}</SidebarText>
              </SidebarItemContainer>
            );
          }
          if (item.collapse) {
            const firstdd = {};
            firstdd[item.state] = !sidePanelState[item.state];
            return (
              /*--------------------------------------------------------------------------------*/
              /* Menus wiil be goes here                                                        */
              /*--------------------------------------------------------------------------------*/
              <li
                className={`${activeRoute(item.path)} sidebar-item`}
                key={item.key}
              >
                <span
                  data-toggle="collapse"
                  className="sidebar-link has-arrow"
                  aria-expanded={sidePanelState[item.state]}
                  onClick={() => setSidePanelState(firstdd)}
                >
                  <SidebarItemContainer>
                    {item.icon}
                    <SidebarText className="hide-menu">
                      {item.display}
                    </SidebarText>
                  </SidebarItemContainer>
                  {/* <i className={item.icon} />
                  <span className="hide-menu">{item.display}</span> */}
                </span>
                {/*--------------------------------------------------------------------------------*/}
                {/* Sub-Menus wiil be goes here                                                    */}
                {/*--------------------------------------------------------------------------------*/}
                <Collapse isOpen={sidePanelState[item.state]}>
                  <ul className="first-level">
                    {item.child.map((item) => {
                      return (
                        /*--------------------------------------------------------------------------------*/
                        /* Adding Sidebar Item                                                            */
                        /*--------------------------------------------------------------------------------*/
                        <li
                          className={`${activeRoute(item.path)} sidebar-item`}
                          key={item.key}
                        >
                          <NavLink
                            to={item.path}
                            className="sidebar-link"
                            activeClassName="active"
                          >
                            <SidebarItemContainer>
                              {item.icon}
                              <SidebarText className="hide-menu">
                                {item.display}
                              </SidebarText>
                            </SidebarItemContainer>
                            {/* <i className={item.icon} />
                            <span className="hide-menu">{item.display}</span> */}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </Collapse>
              </li>
            );
          }

          return (
            <li
              className={`${activeRoute(item.path)} sidebar-item`}
              key={item.key}
            >
              <NavLink
                to={item.path}
                activeClassName="active"
                className="sidebar-link"
              >
                <SidebarItemContainer>
                  {item.icon}
                  <SidebarText className="hide-menu">
                    {item.display}
                  </SidebarText>
                </SidebarItemContainer>
                {/* <i className={item.icon} />
                <span className="hide-menu">{item.display}</span> */}
              </NavLink>
            </li>
          );
        })}
    </>
  );
};

export default Menu;
