import React from 'react';
import ReactDOM from 'react-dom';
import { Card, CardBody, Form, Input, Row, Col, Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Message from './message';

import user1 from '../../../assets/images/users/1.jpg';
import user2 from '../../../assets/images/users/2.jpg';

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chats: [
        {
          username: 'Sasa Savic',
          content: 'Finally completed this feature lol',
          img: user1,
          id: 1,
        },
        {
          username: 'Arun Yuvarajah',
          content: 'Love it! :heart:',
          img: user1,
          id: 2,
        },
        {
          username: 'Sasa Savic',
          content:
            'Our ProjX logo is now also up and running ... check out top left ;)',
          img: user1,
          id: 3,
        },
        {
          username: 'Evan Karopoulous',
          content: 'Lol... that looks pretty cool Sash!!!',
          img: user2,
          id: 4,
        },
        {
          username: 'Sasa Savic',
          content: 'I know hey... not bad',
          img: user1,
          id: 5,
        },
      ],
    };
    this.submitMessage = this.submitMessage.bind(this);
  }

  componentDidMount() {
    this.scrollToBot();
  }

  componentDidUpdate() {
    this.scrollToBot();
  }

  scrollToBot() {
    ReactDOM.findDOMNode(this.chats).scrollTop = ReactDOM.findDOMNode(
      this.chats,
    ).scrollHeight;
  }

  submitMessage(e) {
    e.preventDefault();
    this.setState(
      {
        chats: this.state.chats.concat([
          {
            username: 'Kevin Hsu',
            content: <span>{ReactDOM.findDOMNode(this.msg).value}</span>,
            img: user2,
          },
        ]),
      },
      () => {
        ReactDOM.findDOMNode(this.msg).value = '';
      },
    );
  }

  render() {
    const username = 'Kevin Hsu';
    const { chats } = this.state;
    return (
      /*--------------------------------------------------------------------------------*/
      /* Used In Dashboard-1 [Classic] && Dashboard-4 [General]                         */
      /*--------------------------------------------------------------------------------*/
      <Card>
        <CardBody>
          <h5 className="mb-4">Recent Chats</h5>
          <div className="chat-box" style={{ height: '475px' }}>
            <PerfectScrollbar>
              <ul
                className="chat-list p-4"
                ref={(e) => {
                  this.chats = e;
                }}
              >
                {chats.map((chat, index) => (
                  <Message key={index} chat={chat} user={username} />
                ))}
              </ul>
            </PerfectScrollbar>
          </div>
          <div className="border-top pt-4">
            <Form onSubmit={(e) => this.submitMessage(e)}>
              <Row>
                <Col xs="9">
                  <div className="input-field mt-0 mb-0">
                    <Input
                      placeholder="Type and enter"
                      ref={(f) => {
                        this.msg = f;
                      }}
                      className="form-control border-0"
                      type="text"
                    />
                  </div>
                </Col>
                <Col xs="3">
                  <Button
                    className="btn btn-circle btn-lg btn-cyan float-right text-white"
                    type="submit"
                    value="submit"
                  >
                    <i className="fas fa-paper-plane"></i>
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default Chat;
