// Library imports
import React, { useState, useContext } from 'react';
import _ from 'lodash';
import { gql, useMutation } from '@apollo/client';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Select  from 'react-select';
// Local imports
import { Button, IconButton } from '../../../components';
import { MilestoneContext } from '../../../contexts';
import { phaseSelectOptions, statusSelectOptions } from '../constants';
import {
  HeaderContainer,
  HeaderFrame,
  MilestoneTableIcon,
  Heading,
  FilterIcon,  
} from './styles/Header';
import {
  InputContainer,
  InputHeader,
  FooterContainer,
  DatePickerStyled,
  TextAreaStyled,
} from './styles/Modal';

// GraphQL Mutations
const CREATE_MILESTONE_DOCUMENT = gql`
  mutation createMilestoneDocument($projectId: ID!, $input: MilestoneDocumentInput!) {
    createMilestoneDocument(projectId: $projectId, input: $input) {
      code
      success
      message
      documents {
        _id
        phase
        description
        scheduleDate
        revisedScheduleDate
        targetDate
        actualDate
        status
      }
    } 
  }
`;

/**
 * This is the Header for our progress diary component, containing the heading and the button which pops the modal for adding
 * a progress diary event
 * @param {string} projectId The ID of the project on which we are looking at the progress diary
 * @param {React.SetStateAction} setTableData setState function to update the data of our table after we create a new progress diary item
 * @returns Header component for the Progress Diary module
 */
const MilestoneHeader = ({ projectId, setTableData }) => {
  // For showing/hiding our modal
  const [showAddMilestoneModal, setShowAddMilestoneEventModal] = useState(false);
  // For our milestone input states for our modal
  const [phase, setPhase] = useState('CONSTRUCTION');
  const [description, setDescription] = useState('');
  const [scheduleDate, setScheduleDate] = useState(null);
  const [revisedScheduleDate, setRevisedScheduleDate] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  const [actualDate, setActualDate] = useState(null);
  const [status, setStatus] = useState('ON_TRACK');

  const { filterAccordionOpen, setFilterAccordionOpen } = useContext(MilestoneContext);

  const toggleAddMilestoneModal = () => {
    setShowAddMilestoneEventModal(() => !showAddMilestoneModal);
  };

  const [createMilestoneDocument, {
    loading: loadingCreateMilestoneDoc,
    error: errorCreateMilestoneDoc,
    data: dataCreateMilestoneDoc
  }] = useMutation(CREATE_MILESTONE_DOCUMENT, {
    onCompleted: (data) => {
      setTableData(data.createMilestoneDocument.documents);
    }
  });

  const addMilestoneButtonDisabled = description === '' || scheduleDate === null;

  // We click the button to create a new progress diary event
  const handleAddMilestoneButtonClick = async (e) => {
    setShowAddMilestoneEventModal(false);
    await createMilestoneDocument({
      variables: {
        projectId,
        input: {
          phase,
          description,
          scheduleDate,
          revisedScheduleDate,
          targetDate,
          actualDate,
          status,
        }
      }
    });
    // Reset our state values
    setPhase('CONSTRUCTION');
    setDescription('');
    setScheduleDate(null);
    setRevisedScheduleDate(null);
    setTargetDate(null);
    setActualDate(null);
    setStatus('ON_TRACK');
  }

  return (
    <HeaderContainer>
      <HeaderFrame>
        <MilestoneTableIcon />
        <Heading>Milestone Table</Heading>
      </HeaderFrame>
      <HeaderFrame>
        <IconButton onClick={() => setFilterAccordionOpen(() => !filterAccordionOpen)} text="Filter">
          <FilterIcon />
        </IconButton>
        <Button onClick={toggleAddMilestoneModal}>Add Event</Button>
      </HeaderFrame>
      <Modal
        isOpen={showAddMilestoneModal}
        toggle={toggleAddMilestoneModal}
        backdrop
      >
        <ModalHeader toggle={toggleAddMilestoneModal}>
          <InputHeader>Add a Milestone</InputHeader>
        </ModalHeader>
        <ModalBody>
          <InputContainer>
            <InputHeader>{`Phase:`}</InputHeader>
            <Select
              options={phaseSelectOptions}
              value={_.find(phaseSelectOptions, (item) => {
                return item.value === phase;
              })}
              defaultValue={{ value: 'CONSTRUCTION', label: 'Construction' }}
              onChange={(e) => setPhase(e.value)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`* Description:`}</InputHeader>
            <TextAreaStyled
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe your milestone"
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`* Select a Schedule Date (DD-MM-YYYY):`}</InputHeader>
            <DatePickerStyled
              dateFormat="dd/MM/yyyy"
              selected={scheduleDate}
              onChange={(date) => setScheduleDate(date)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`Select a Revised Schedule Date (DD-MM-YYYY):`}</InputHeader>
            <DatePickerStyled
              dateFormat="dd/MM/yyyy"
              selected={revisedScheduleDate}
              onChange={(date) => setRevisedScheduleDate(date)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`Select a Target Date (DD-MM-YYYY):`}</InputHeader>
            <DatePickerStyled
              dateFormat="dd/MM/yyyy"
              selected={targetDate}
              onChange={(date) => setTargetDate(date)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`Select an Actual Date (DD-MM-YYYY):`}</InputHeader>
            <DatePickerStyled
              dateFormat="dd/MM/yyyy"
              selected={actualDate}
              onChange={(date) => setActualDate(date)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>{`Status:`}</InputHeader>
            <Select
              options={statusSelectOptions}
              value={_.find(statusSelectOptions, (item) => {
                return item.value === status;
              })}
              defaultValue={{ value: 'ON_TRACK', label: 'On Track' }}
              onChange={(e) => setStatus(e.value)}
            />
          </InputContainer>
          <FooterContainer>
            <Button onClick={handleAddMilestoneButtonClick} disabled={addMilestoneButtonDisabled}>
              Add
            </Button>
          </FooterContainer>
        </ModalBody>
      </Modal>
    </HeaderContainer>
  );
};

export default MilestoneHeader;
