import { useEffect, useState, useContext } from 'react';
import _ from 'lodash';

import { GlobalReferenceContext } from '../../contexts';
import { useCostCodeSetup, useQueryVendors } from '../../hooks';
import { ColumnType, ReportType, FilterType } from './data';

const useReportSettings = ({ projectId }) => {
  const { /*references, */ normalize } = useContext(GlobalReferenceContext);

  const [allReferences, setAllReferences] = useState({
    codes: [],
    vendors: [],
    accountsPayable: normalize('accounts_payable'),
    fundingSource: normalize('funding_source_category'),
  });

  const { getCostCodeSetupByProject } = useCostCodeSetup({
    onCompleted: ({ data }) => {
      setAllReferences((prev) => ({
        ...prev,
        codes: _.orderBy(
          data,
          [(cc) => cc.costCode.name.toLowerCase()],
          ['asc'],
        ),
      }));
    },
  });

  const [getVendors] = useQueryVendors({
    onCompleted: (data) => {
      setAllReferences((prev) => ({
        ...prev,
        vendors: data
          ? data.map((vendor) => ({
            label: vendor.name,
            value: vendor.name,
          }))
          : [],
      }));
    },
  });

  useEffect(() => {
    getCostCodeSetupByProject(projectId);
    getVendors();
  }, []);

  const transformBudgetCostCodeOptions = (key) => {
    try {
      return allReferences.codes.map((i) => ({
        value: i.costCode[key],
        label: i.costCode[key],
      }));
    } catch {
      return [];
    }
  };

  const getReportSettings = (type) => {
    switch (type) {
      case ReportType.BudgetTransfers:
        return {
          queryByDefaultAccessor: 'source.costCode.code',
          defaultDateRangeField: 'modifiedDate',
          withPeriod: false,
          hasVendor: false,
          columns: [
            {
              label: 'Reference',
              value: '_id',
              isGroupable: false,
              default: false,
              type: ColumnType.Text,
            },
            {
              label: 'Created By',
              value: 'createdBy.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
            },
            {
              label: 'Modified By',
              value: 'modifiedBy.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
            },
            {
              label: 'Source Budget Code',
              value: 'source.costCode.code',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: transformBudgetCostCodeOptions('code'),
              },
            },
            {
              label: 'Source Budget',
              value: 'source.costCode.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: transformBudgetCostCodeOptions('name'),
              },
            },
            {
              label: 'Destination Budget Code',
              value: 'destination.costCode.code',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: transformBudgetCostCodeOptions('code'),
              },
            },
            {
              label: 'Destination Budget',
              value: 'destination.costCode.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: transformBudgetCostCodeOptions('name'),
              },
            },
            {
              label: 'Amount',
              value: 'amount',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
              filter: {
                type: FilterType.Numerical,
                format: ({ value }) => {
                  return Number(value);
                },
              },
            },
            {
              label: 'Modified Date',
              value: 'modifiedDate',
              isGroupable: true,
              default: true,
              type: ColumnType.Date,
            },
            {
              label: 'Created Date',
              value: 'timestamp',
              isGroupable: true,
              default: true,
              type: ColumnType.Date,
            },
            {
              label: 'State',
              value: 'state',
              isGroupable: true,
              default: true,
              type: ColumnType.Badge,
              filter: {
                type: FilterType.Select,
                options: [
                  {
                    label: 'Pending',
                    value: 'pending',
                  },
                  {
                    label: 'Withdrawn',
                    value: 'withdrawn',
                  },
                  {
                    label: 'Rejected',
                    value: 'rejected',
                  },
                  {
                    label: 'Approved',
                    value: 'approved',
                  },
                ],
              },
            },
            {
              label: 'Notes',
              value: 'notes',
              isGroupable: false,
              default: false,
              type: ColumnType.Text,
              filter: {
                type: ColumnType.Text,
              },
            },
          ],
        };
      case ReportType.FccBreakdownPeriods:
        return {
          queryByDefaultAccessor: 'costCode',
          defaultDateRangeField: 'timestamp',
          hasVendor: true,
          withPeriod: true,
          columns: [
            {
              label: 'Budget Id',
              value: '_id',
              isGroupable: false,
              default: false,
              type: ColumnType.Text,
            },
            {
              label: 'Reference',
              value: 'ref',
              isGroupable: false,
              default: true,
              type: ColumnType.Text,
            },
            {
              label: 'Modified On',
              value: 'firstHistory.modifiedOn',
              isGroupable: true,
              default: true,
              type: ColumnType.Date,
            },
            {
              label: 'Budget Code',
              value: 'costCode',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: transformBudgetCostCodeOptions('code'),
              },
            },
            {
              label: 'Cost Type',
              value: 'type',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: [
                  {
                    label: 'Contracts',
                    value: 'contract',
                  },
                  {
                    label: 'Provisional Sums',
                    value: 'provisional',
                  },
                  {
                    label: 'Variations',
                    value: 'variation',
                  },
                ],
              },
            },
            {
              label: 'Vendor',
              value: 'vendor.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: allReferences.vendors,
              },
            },
            {
              label: 'Phase',
              value: 'phase',
              isGroupable: false,
              default: true,
              type: ColumnType.Text,
            },
            {
              label: 'Description',
              value: 'description',
              isGroupable: false,
              default: false,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Text,
              },
            },
            {
              label: 'Accounts Payable',
              value: 'accountsPayable.key',
              isGroupable: true,
              default: false,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: allReferences.accountsPayable,
              },
            },
            {
              label: 'Funding Source',
              value: 'fundingSource.key',
              isGroupable: true,
              default: false,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: allReferences.fundingSource,
              },
            },
            {
              label: 'Stage',
              value: 'firstHistory.stage',
              isGroupable: true,
              disabled: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: [
                  {
                    label: 'Created',
                    value: 'created',
                  },
                  {
                    label: 'Anticipated',
                    value: 'anticipated',
                  },
                  {
                    label: 'Submitted',
                    value: 'submitted',
                  },
                  {
                    label: 'Recommended',
                    value: 'recommended',
                  },
                  {
                    label: 'Contracted',
                    value: 'contracted',
                  },
                  {
                    label: 'Approved',
                    value: 'approved',
                  },
                  {
                    label: 'Unknown',
                    value: 'unknown',
                  },
                ],
              },
            },
            {
              label: 'FFC',
              value: 'firstHistory.ffc',
              isGroupable: false,
              disabled: true,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Comparison Stage',
              value: 'secondHistory.stage',
              isGroupable: false,
              disabled: true,
              default: true,
              type: ColumnType.Text,
            },
            {
              label: 'Comparison FFC',
              value: 'secondHistory.ffc',
              isGroupable: false,
              disabled: true,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Movement',
              value: 'movement',
              isGroupable: false,
              disabled: true,
              default: true,
              type: ColumnType.Numerical,
            },
          ],
        };
      case ReportType.FfcBreakdown:
        return {
          queryByDefaultAccessor: 'costCode',
          defaultDateRangeField: 'timestamp',
          withPeriod: false,
          hasVendor: true,
          columns: [
            {
              label: 'Budget Id',
              value: '_id',
              isGroupable: false,
              default: false,
              type: ColumnType.Text,
            },
            {
              label: 'Reference',
              value: 'ref',
              isGroupable: false,
              default: true,
              type: ColumnType.Text,
            },
            {
              label: 'Modified By',
              value: 'history.modifiedBy.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
            },
            {
              label: 'Modified On',
              value: 'history.modifiedOn',
              isGroupable: true,
              default: true,
              type: ColumnType.Date,
            },
            {
              label: 'Budget Code',
              value: 'costCode',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: transformBudgetCostCodeOptions('code'),
              },
            },
            {
              label: 'Cost Type',
              value: 'type',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: [
                  {
                    label: 'Contracts',
                    value: 'contract',
                  },
                  {
                    label: 'Provisional Sums',
                    value: 'provisional',
                  },
                  {
                    label: 'Variations',
                    value: 'variation',
                  },
                ],
              },
            },
            {
              label: 'Vendor',
              value: 'vendor.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: allReferences.vendors,
              },
            },
            {
              label: 'Phase',
              value: 'phase',
              isGroupable: false,
              default: true,
              type: ColumnType.Text,
            },
            {
              label: 'Description',
              value: 'description',
              isGroupable: false,
              default: false,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Text,
              },
            },
            {
              label: 'Accounts Payable',
              value: 'accountsPayable.key',
              isGroupable: true,
              default: false,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: allReferences.accountsPayable,
              },
            },
            {
              label: 'Funding Source',
              value: 'fundingSource.key',
              isGroupable: true,
              default: false,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: allReferences.fundingSource,
              },
            },
            {
              label: 'Stage',
              value: 'history.stage',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: [
                  {
                    label: 'Created',
                    value: 'created',
                  },
                  {
                    label: 'Anticipated',
                    value: 'anticipated',
                  },
                  {
                    label: 'Submitted',
                    value: 'submitted',
                  },
                  {
                    label: 'Recommended',
                    value: 'recommended',
                  },
                  {
                    label: 'Contracted',
                    value: 'contracted',
                  },
                  {
                    label: 'Approved',
                    value: 'approved',
                  },
                  {
                    label: 'Unknown',
                    value: 'unknown',
                  },
                ],
              },
            },
            {
              label: 'Amount / FFC',
              value: 'history.ffc',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
          ],
        };
      case ReportType.Invoicing:
        return {
          queryByDefaultAccessor: 'costCode.code', // vendor.name
          defaultDateRangeField: 'invoices.date',
          withPeriod: false,
          hasVendor: true,
          columns: [
            {
              label: 'Invoice Date',
              value: 'invoices.date',
              isGroupable: true,
              default: true,
              type: ColumnType.Date,
            },
            {
              label: 'Invoice No#',
              value: 'invoices.invoiceId',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Text,
              },
            },
            {
              label: 'Vendor',
              value: 'vendor.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: allReferences.vendors,
              },
            },
            {
              label: 'Cost Code',
              value: 'costCode.code',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: transformBudgetCostCodeOptions('code'),
              },
            },
            {
              label: 'Cost Code Name',
              value: 'costCode.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Select,
                options: transformBudgetCostCodeOptions('name'),
              },
            },
            {
              label: 'Claimed Amount',
              value: 'invoices.claimedAmount',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Comments',
              value: 'invoices.comments',
              isGroupable: false,
              default: true,
              type: ColumnType.Text,
              filter: {
                type: FilterType.Text,
              },
            },
          ],
        };
      case ReportType.BudgetCurrentPrevious:
        return {
          queryByDefaultAccessor: '', // vendor.name
          defaultDateRangeField: '',
          hasVendor: true,
          withPeriod: true,
          columns: [
            {
              label: 'Reference',
              value: 'ref',
              isGroupable: false,
              default: true,
              type: ColumnType.Text,
            },
          ],
        };
      case ReportType.ContractSumAdjustments:
        return {
          queryByDefaultAccessor: '', // vendor.name
          defaultDateRangeField: '',
          columns: [
            {
              label: 'Reference',
              value: 'ref',
              isGroupable: false,
              default: true,
              type: ColumnType.Text,
            },
          ],
        };
      case ReportType.CostSummary:
        return {
          queryByDefaultAccessor: '', // vendor.name
          defaultDateRangeField: '*',
          withPeriod: false,
          hasVendor: false,
          columns: [
            {
              label: 'Cost Code',
              value: 'group.costCode.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
            },
            {
              label: 'Cost Category',
              value: 'group.budgetCategory.name',
              isGroupable: true,
              default: true,
              type: ColumnType.Text,
            },
            {
              label: 'Original Budget',
              value: 'summary.originalBudget',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Budget Transfer',
              value: 'summary.budgetTransfer',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Current Budget',
              value: 'summary.currentBudget',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Unapproved Contract',
              value: 'summary.forecasts.contract',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Unapproved Provosional Sum',
              value: 'summary.forecasts.provisionalSum',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Unapproved Variations',
              value: 'summary.forecasts.variations',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Unapproved Total',
              value: 'summary.forecasts.total',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Approved Contract',
              value: 'summary.committments.contract',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Approved Provisional Sum',
              value: 'summary.committments.provisionalSum',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Approved Let',
              value: 'summary.committments.let',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Approved Adjustments',
              value: 'summary.committments.adjustments',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Approved Variations',
              value: 'summary.committments.variations',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Approved Total',
              value: 'summary.committments.total',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Approved Position',
              value: 'summary.position.approvedPosition',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'FFC Amount',
              value: 'summary.ffc',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'FFC Position',
              value: 'summary.position.fccPosition',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Expenditure Cumulative',
              value: 'summary.expenditure.cumulative',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Expenditure Budget To Complete',
              value: 'summary.expenditure.budgetCostToComplete',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Expenditure Approved To Complete',
              value: 'summary.expenditure.committedCostToComplete',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
            {
              label: 'Expenditure Forecast To Complete',
              value: 'summary.expenditure.forecastToComplete',
              isGroupable: false,
              default: true,
              type: ColumnType.Numerical,
            },
          ],
        };
      default:
        return {
          queryByDefaultAccessor: '',
          defaultDateRangeField: '',
          columns: [],
        };
    }
  };

  return {
    getReportSettings,
    allReferences,
  };
};

export default useReportSettings;

export { useReportSettings };
