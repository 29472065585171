import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardLink,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';

import { CSVLink } from 'react-csv';

import _, { defaults } from 'lodash';

import Switch from 'react-bootstrap-switch';
import Select, { components } from 'react-select';

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { dateRanges, defaultComparisonPeriod } from '../../common/utils';
import { useCostCodeSetup, useQueryVendors, useReport } from '../../hooks';
import {
  defaultReportQuery,
  defaultReportStyleSheets,
  defaultPdfDocument,
} from './PdfDefaults';

import { updateArrayByKey, removeFromArray, currencify } from '../../common';

import { FilterType } from './data';

import ExportCSV from './ExportCSV';
import useReportSettings from './useReportSettings';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '30px',
    height: '30px',
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '30px',
    padding: '0 6px',
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '30px',
  }),
};

const IndicatorsContainer = (props) => {
  return (
    <div>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const ReportFilter = ({
  projectId,
  onQuery = () => {},
  artefact = {},
  type,
}) => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [dates] = useState(dateRanges());
  const [currentOptions, setCurrentOptions] = useState([]);
  const [filters, setFilters] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pdf, setPdf] = useState(undefined);
  const [pdfDownloadLink, setPdfDownloadLink] = useState();
  const [isPdfReady, setIsPdfReady] = useState(false);
  const [selectedAll, setSelectedAll] = useState('');

  /**
   * Vendors
   */
  const [getVendors] = useQueryVendors({
    onCompleted: (data) => {
      setVendorz(data);
    },
  });

  const [vendorz, setVendorz] = useState(undefined);
  const [vendorsNormalized, setVendors] = useState([]);
  const [hasVendors, setHasVendors] = useState(false);
  const vendorSelected = (selectedVendor) => {
    setConfig((prev) => ({ ...prev, queryByVendor: selectedVendor._id }));
  };

  /** ****** */

  const [config, setConfig] = useState({
    dateRange: {
      field: undefined,
      from: dates[0].value.from,
      to: dates[0].value.to,
    },
    comparison: {
      withPeriods: false,
      period: 1,
      values: [],
    },
    queryBy: {
      accessor: '',
      values: [],
    },
    queryByVendor: '',
    filterBy: [],
    layout: {
      type: 'view',
      accessor: undefined,
    },
    columns: [],
    showDecimals: true,
    showBalances: true,
  });

  // PDF Generation Query
  const [defaultQuery, setDefaultQuery] = useState(
    defaultReportQuery({
      type: type,
      dateFrom: config.dateRange.from,
      dateTo: config.dateRange.to,
      costCodeValues: config.queryBy.values,
    }),
  );

  const [defaultQueryData, setDefaultQueryData] = useState({});
  const [pdfStyles, setPdfStyles] = useState({});

  const [runReport, { loading, error }] = useReport({
    onCompleted: (data) => {
      setDefaultQueryData(data);
    },
  });

  useEffect(() => {
    const stylesBasedOnReportType = defaultReportStyleSheets(type);
    setPdfStyles(stylesBasedOnReportType);
  }, [type]);

  useEffect(() => {
    setDefaultQuery(
      defaultReportQuery({
        type: type,
        dateFrom: config.dateRange.from,
        dateTo: config.dateRange.to,
        costCodeValues: config.queryBy.values,
        vendor: config.queryByVendor,
      }),
    );
  }, [config]);

  useEffect(() => {
    try {
      const RunReport = async () => {
        await runReport({
          projectId,
          type,
          query: defaultQuery,
        });
      };

      RunReport();
    } catch (e) {
      console.error(e);
    }
  }, [defaultQuery]);

  useEffect(() => {
    setPdf(
      defaultPdfDocument(
        type,
        defaultReportStyleSheets(type),
        defaultQueryData,
      ),
    );
  }, [defaultQueryData]);

  useEffect(() => {
    setPdfDownloadLink(
      <PDFDownloadLink document={pdf} fileName={`${artefact.fileName}-${type}`}>
        {({ blob, url, loading, error }) =>
          loading ? (
            'Loading...'
          ) : (
            <>
              <i className="fas fa-file-pdf text-danger" /> PDF
            </>
          )
        }
      </PDFDownloadLink>,
    );
  }, [pdf]);

  // ******************
  const { getReportSettings, allReferences } = useReportSettings({ projectId });

  const { getCostCodeSetupByProject, Actions } = useCostCodeSetup({
    onCompleted: ({ action, data }) => {
      switch (action) {
        case Actions.GetCostCodeSetupProject:
          setCurrentOptions(
            _.orderBy(data, [(cc) => cc.costCode.name.toLowerCase()], ['asc']),
          );
          break;
        default:
          break;
      }
    },
  });

  const update = () => {
    // setPdf(undefined);
    setIsPdfReady(false);

    onQuery({
      query: config,
      columns,
    });
  };

  const disableUpdate = () => {
    if (!config.queryBy.accessor) return false;
    return config.queryBy.values.length === 0;
  };

  const buildExportHeaders = () => {
    const removed = _.remove(Object.assign([], columns), (column) => {
      if (_.some(config.columns, (c) => c.field === column.value)) return true;
      return false;
    });

    const prepared = _.map(removed, (column) => {
      return {
        label: column.label,
        key: column.value,
      };
    });

    return prepared;
  };

  const toggleOptions = () => setOptionsOpen(!optionsOpen);

  const transformedOptions = () =>
    currentOptions.map((i) => ({
      value: i.costCode.code,
      label: ` ${i.costCode.code} - ${i.costCode.name}`,
    }));

  const onDateSelected = (e) =>
    setConfig((prev) => ({
      ...prev,
      dateRange: {
        from: e.value.from,
        to: e.value.to,
        field: prev.dateRange.field,
      },
    }));

  const onDateChanged = (field, e) => {
    const { value } = e.target;
    setConfig((prev) => ({
      ...prev,
      dateRange: { ...prev.dateRange, [field]: value },
    }));
  };

  const onComparisonDateChanged = (e) => {
    const { value } = e.target;
    setConfig((prev) => ({
      ...prev,
      comparison: { ...prev.comparison, values: [value] },
    }));
  };

  // setConfig(prev => ({ ...prev, dateRange: { from: e.value.from, to: e.value.to, field: prev.dateRange.field } }));

  const logChange = (val) => {
    setConfig((prev) => ({
      ...prev,
      queryBy: {
        accessor: prev.queryBy.accessor,
        values: _.map(val, (v) => v.value),
      },
    }));
    setSelectedAll('');
  };

  const layoutChanged = ({ value }) =>
    setConfig((prev) => ({
      ...prev,
      layout: {
        type: value,
        accessor: value === 'view' ? '' : prev.layout.accessor,
      },
    }));

  const groupValueChanged = ({ value }) =>
    setConfig((prev) => ({
      ...prev,
      layout: { accessor: value, type: prev.layout.type },
    }));

  const selectValueChanged = (selected) => {
    setConfig((prev) => ({
      ...prev,
      filterBy: updateArrayByKey(selected, prev.filterBy, 'field'),
    }));
  };

  const getFilterValue = (accessor) => {
    const filter = _.find(
      config.filterBy,
      (filterBy) => filterBy.field === accessor,
    );
    return filter ? filter.value : '';
  };

  const fieldSelected = ({ checked, accessor }) => {
    if (checked)
      setConfig((prev) => ({
        ...prev,
        columns: _.union(config.columns, [accessor]),
      }));
    else
      setConfig((prev) => ({
        ...prev,
        columns: _.filter(
          config.columns,
          (column) => column.field !== accessor.field,
        ),
      }));
  };

  const filterSelected = (filter) => {
    setConfig((prev) => ({
      ...prev,
      filterBy: updateArrayByKey(
        {
          field: filter.value,
          value: '',
        },
        prev.filterBy,
        'field',
        true,
      ),
    }));
    setFilters(updateArrayByKey(filter, filters, 'value', true));
  };

  const removeFilter = (filterValue) => {
    setConfig((prev) => ({
      ...prev,
      filterBy: removeFromArray(
        {
          field: filterValue.value,
        },
        prev.filterBy,
        'field',
      ),
    }));
    setFilters(removeFromArray(filterValue, filters, 'value'));
  };

  const buildFilterOptions = () => {
    return _.map(
      _.filter(
        columns,
        (column) =>
          column.filter !== undefined &&
          _.find(config.filterBy, (filter) => filter.field === column.value) ===
            undefined,
      ),
      (col) => {
        return {
          label: col.label,
          value: col,
        };
      },
    );
  };

  const reset = () => {
    setOptionsOpen(false);
    setCurrentOptions([]);
    setFilters([]);
  };

  const evaluatePdfTemplate = (art) => {
    if (art.document.template && art.document.template.Component) {
      const { Component } = art.document.template;
      art.document.template.data.header.costCode =
        config.queryBy.values.join(', ');
      art.document.template.data.header.vendor =
        config.queryBy.values.join(', ');

      const template = {
        ...art.document.template.data,
        table: {
          fields: buildExportHeaders(),
          data: art.data,
          totals: art.totals,
        },
        period: {
          from: config.dateRange.from,
          to: config.dateRange.to,
        },
      };
      setPdf(
        <PDFDownloadLink
          document={<Component template={template} />}
          fileName={artefact.document.fileName}
        >
          {({ blob, url, loading, error }) => {
            if (error) return;
            return loading ? <Label>Loading...</Label> : <Label>PDF</Label>;
          }}
        </PDFDownloadLink>,
      );
    }
  };

  const selectAllBudgets = () => {
    const options = transformedOptions();

    setConfig((prev) => ({
      ...prev,
      queryBy: {
        accessor: prev.queryBy.accessor,
        values: _.map(options, (v) => v.value),
      },
    }));

    setSelectedAll(`Selected all (${options.length}) options`);
  };

  useEffect(() => {
    const reportSettings = getReportSettings(type);

    setColumns(reportSettings.columns);

    reset();

    getCostCodeSetupByProject(projectId);

    setConfig((prev) => ({
      ...prev,
      layout: {
        type: prev.layout.type,
        accessor: '',
      },
      comparison: {
        ...prev.comparison,
        withPeriods: reportSettings.withPeriod,
        values: [defaultComparisonPeriod(config.dateRange.to)],
      },
      queryBy: {
        accessor: reportSettings.queryByDefaultAccessor,
        values: prev.queryBy.values,
      },
      dateRange: {
        field: reportSettings.defaultDateRangeField,
        to: prev.dateRange.to,
        from: prev.dateRange.from,
      },
      columns: _.map(
        _.filter(reportSettings.columns, (column) => column.default),
        (column) => ({ field: column.value, type: column.type }),
      ),
    }));

    // temporary:
    setHasVendors(reportSettings.hasVendor);
  }, [type, allReferences]);

  // useEffect(() => {
  //   if (!artefact.data) return;
  //   evaluatePdfTemplate(artefact);
  // }, [columns, artefact, defaultQueryData]);

  useEffect(() => {
    if (!pdf) return;
    setIsPdfReady(true);
  }, [pdf]);

  useEffect(() => {
    if (vendorz) {
      const allVendorz = vendorz
        ? vendorz.map((vendor) => {
            return {
              label: `${vendor.name}`,
              value: vendor,
            };
          })
        : [];

      allVendorz.unshift({
        label: 'All Vendors',
        value: {
          _id: '',
        },
      });

      setVendors(allVendorz);
    }
  }, [vendorz]);

  useEffect(() => {
    if (hasVendors);
    getVendors();
  }, [hasVendors]);

  if (columns.length === 0) return <div />;

  return (
    <Card>
      <CardBody>
        <FormGroup>
          {config.queryBy.accessor ? (
            <Row>
              <Label sm="2">Budget</Label>
              <Col sm="6">
                <Select
                  placeholder="Select budget..."
                  options={transformedOptions()}
                  components={{ IndicatorsContainer }}
                  onChange={logChange}
                  isMulti
                  closeMenuOnSelect
                />
              </Col>
              <Col sm="1">
                <Button
                  onClick={selectAllBudgets}
                  className="float-left mt-1"
                  color="projx"
                >
                  <i className="fas fa-check-square" />
                </Button>
              </Col>
              <Col sm="3">
                <Label className="mt-2 text-info">{selectedAll}</Label>
              </Col>
            </Row>
          ) : (
            ''
          )}
          {hasVendors ? (
            <Row className="mt-2">
              <Label sm="2">Vendor</Label>
              <Col sm="4">
                <Select
                  placeholder="Select vendor..."
                  closeMenuOnSelect
                  defaultValue={undefined}
                  options={vendorsNormalized}
                  onChange={(e) => vendorSelected(e.value)}
                />
              </Col>
              {/* <Col sm="2">
                                <Button
                                    onClick={selectAllVendors}
                                    className="float-left mt-1"
                                    color="info"
                                >
                                    <i className="fas fa-check-square" />
                                </Button>
                            </Col> */}
            </Row>
          ) : (
            ''
          )}

          <Row className="mt-2">
            <Label sm="2">Date Range</Label>
            <Col sm="6">
              <Row>
                <Col md="4">
                  <Select
                    closeMenuOnSelect
                    options={dates}
                    onChange={onDateSelected}
                    styles={customStyles}
                  />
                </Col>
                <Col md="6">
                  <div className="d-flex">
                    <Input
                      type="date"
                      value={config.dateRange.from}
                      onChange={(e) => onDateChanged('from', e)}
                      className="mr-2"
                    />
                    <Input
                      type="date"
                      value={config.dateRange.to}
                      onChange={(e) => onDateChanged('to', e)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="4">
              <b>
                <CardLink
                  style={{ cursor: 'pointer' }}
                  onClick={toggleOptions}
                  className="float-right text-info mt-2"
                >
                  {optionsOpen ? 'Close' : 'Report Settings'}
                </CardLink>
              </b>
            </Col>
          </Row>
          {config.comparison.withPeriods ? (
            <Row className="mt-2">
              <Label sm="2">Comparison Period</Label>
              <Col sm="6">
                <Row>
                  <Col md="4" />
                  <Col md="6">
                    <div className="d-flex">
                      <Input
                        disabled
                        type="date"
                        value={config.dateRange.from}
                        onChange={(e) => {}}
                        className="mr-2"
                      />
                      <Input
                        type="date"
                        value={config.comparison.values[0]}
                        onChange={onComparisonDateChanged}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </FormGroup>
      </CardBody>
      {!optionsOpen ? (
        <CardFooter>
          <Button
            disabled={disableUpdate()}
            className="float-right ml-3"
            color="projx"
            onClick={update}
          >
            Update
          </Button>
          {/* <Export
            artefact={artefact}
            pdf={pdfDownloadLink}
            isPdfReady={true}
            build={buildExportHeaders}
          /> */}
          {artefact.data && (
            <>
              <ExportCSV artefact={artefact} build={buildExportHeaders} />
              {pdfDownloadLink}
            </>
          )}
        </CardFooter>
      ) : (
        ''
      )}
      {optionsOpen ? (
        <>
          <CardBody className="border-top">
            <Row className="">
              <Label sm="2">Show Decimals</Label>
              <Col sm="5">
                <Switch
                  onColor="danger"
                  onText="Y"
                  offText="N"
                  value={config.showDecimals}
                  onChange={(e) =>
                    setConfig((prev) => ({
                      ...prev,
                      showDecimals: e.state.value,
                    }))
                  }
                />
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border-top">
            <Row className="">
              <Label sm="2">Layout</Label>
              <Col sm="3">
                <Select
                  closeMenuOnSelect
                  defaultValue={{ value: 'view', label: 'View' }}
                  options={[
                    { value: 'groupBy', label: 'Group By...' },
                    { value: 'view', label: 'View' },
                  ]}
                  onChange={layoutChanged}
                  styles={customStyles}
                />
              </Col>
              {config.layout.type === 'groupBy' ? (
                <Col sm="3">
                  <Select
                    closeMenuOnSelect
                    defaultValue={columns[3]}
                    options={_.filter(columns, (column) => column.isGroupable)}
                    onChange={groupValueChanged}
                    styles={customStyles}
                  />
                </Col>
              ) : (
                ''
              )}
            </Row>
          </CardBody>
          <CardBody className="border-top">
            <Row className="">
              <Label sm="2">Show Balances</Label>
              <Col sm="5">
                <Switch
                  onColor="danger"
                  onText="Y"
                  offText="N"
                  value={config.showBalances}
                  onChange={(e) =>
                    setConfig((prev) => ({
                      ...prev,
                      showBalances: e.state.value,
                    }))
                  }
                />
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border-top">
            <Row className="">
              <Label sm="2">Columns</Label>
              <Col sm="10" className="d-flex flex-row flex-wrap">
                {_.map(columns, (column, idx) => (
                  <div className="p-2" style={{ width: '200px' }}>
                    <CustomInput
                      id={`column${idx}`}
                      type="checkbox"
                      disabled={column.disabled ? column.disabled : false}
                      onChange={(e) =>
                        fieldSelected({
                          checked: e.target.checked,
                          accessor: { type: column.type, field: column.value },
                        })
                      }
                      defaultChecked={column.default}
                      label={column.label}
                    />
                  </div>
                ))}
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border-top">
            {_.map(filters, (filter) => (
              <Row>
                <Label sm="2">{filter.label}</Label>
                <Col sm="4">
                  {filter.filter.type === FilterType.Select ? (
                    <Select
                      closeMenuOnSelect
                      defaultValue={undefined}
                      options={filter.filter.options}
                      onChange={(e) =>
                        selectValueChanged({
                          value: e.value,
                          field: filter.value,
                        })
                      }
                      styles={customStyles}
                    />
                  ) : (
                    ''
                  )}
                  {filter.filter.type === FilterType.Text ||
                  filter.filter.type === FilterType.Numerical ? (
                    <Input
                      value={getFilterValue(filter.value)}
                      onChange={(e) =>
                        selectValueChanged({
                          value: e.target.value,
                          field: filter.value,
                        })
                      }
                    />
                  ) : (
                    ''
                  )}
                </Col>
                <Col sm="1">
                  <Button
                    className="btn-sm"
                    color="projx"
                    onClick={(e) => removeFilter(filter)}
                  >
                    <i className="fas fa-trash" />
                  </Button>
                </Col>
              </Row>
            ))}
          </CardBody>
          <CardBody>
            <Row>
              <Col sm="5">
                <UncontrolledDropdown className="float-left">
                  <DropdownToggle caret outline color="projx">
                    Add a Filter
                  </DropdownToggle>
                  <DropdownMenu>
                    {_.map(buildFilterOptions(), (filter) => (
                      <DropdownItem
                        onClick={(e) => filterSelected(filter.value)}
                      >
                        {filter.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              disabled={disableUpdate()}
              className="float-right ml-3"
              color="projx"
              onClick={update}
            >
              Update
            </Button>
            <ExportCSV artefact={artefact} build={buildExportHeaders} />
            {pdfDownloadLink}
          </CardFooter>
        </>
      ) : (
        ''
      )}
    </Card>
  );
};

export default ReportFilter;
export { ReportFilter };
