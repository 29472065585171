import React, { useContext, useState } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { OrganisationContext } from '../../contexts';
import AuthContext from '../../contexts/auth/auth.context';

const OrgSelector = ({ user }) => {
  const { currentOrganisation, setCurrentOrganisation } =
    useContext(OrganisationContext);
  const { id } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const onOrganisationSelectedEvent = (organisation) => {
    setCurrentOrganisation(organisation);
    history.push('/portfolio');
    setOpen(false);
  };

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <UncontrolledDropdown nav inNavbar className="mr-3" isOpen={open}>
        <DropdownToggle nav caret onClick={toggle}>
          {!currentOrganisation ? (
            ''
          ) : (
            <div>
              <img
                src={currentOrganisation.logo}
                alt="organisation"
                width="31"
                className="mr-3"
              />
              {currentOrganisation.name}
            </div>
          )}
        </DropdownToggle>
        <DropdownMenu right className="mailbox">
          <span className="with-arrow">
            <span className="bg-primary" />
          </span>
          <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
            <div className="">
              <h4 className="mb-0">
                You're part of {user.organisations.length} organisation
                {user.organisations.length === 1 ? '' : 's'}
              </h4>
            </div>
          </div>
          <div className="message-center notifications">
            {user.organisations.map((organisation, index) => {
              return (
                <span
                  href=""
                  className="message-item"
                  key={index}
                  onClick={(e) => onOrganisationSelectedEvent(organisation)}
                >
                  <img src={organisation.logo} alt="organisation" width="31" />
                  <div className="mail-contnet">
                    <h5 className="message-title">{organisation.name}</h5>
                    <span className="mail-desc">
                      {organisation.owner ? (
                        organisation.owner._id === id ? (
                          <Badge color="success">My Organisation</Badge>
                        ) : (
                          <Badge color="warning">External Organisation</Badge>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </span>
              );
            })}
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default OrgSelector;
