import React, { useMemo } from 'react';

import { useDropzone } from 'react-dropzone'; // https://react-dropzone.js.org/

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const FileUploadDropzone = ({
  acceptedFileTypes, // https://react-dropzone.js.org/#section-accepting-specific-file-types
  onFilesAccepted,
  onDropRejected,
  multiple = false,
  maxSizeBytes = Infinity,
  children,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptedFileTypes,
    multiple,
    onDropAccepted: onFilesAccepted,
    onDropRejected: onDropRejected,
    maxSize: maxSizeBytes,
    // onDropAccepted: useCallback(onFilesAccepted),
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default FileUploadDropzone;
