import React from 'react';
import _ from 'lodash';
import { BadgesContainer, Badge } from './styles/Tags';

/**
 * Returns a container with a series of tags
 * @param {string[]} tags An array of strings representing our tags
 * @param {number} fontSize The font size in pixels of our tags
 */
const Badges = ({ tags, fontSize }) => {
  return (
    <BadgesContainer>
      {_.map(tags, (value, index) => {
        return (
          <Badge fontSize={fontSize} index={index}>{value}</Badge>
        );
      })}
    </BadgesContainer>
  );
};

export default Badges;