// * This file contains constants which are used throughout the frontend application

/**
 * An array of strings where each string is a hex code for a color,
 * ensuring consistent color styling across our graphs (but can be used elsewhere).
 */
export const projxColorPalette = [
  '#E7224A',
  '#0069E0',
  '#FFBB33',
  '#E040FB',
  '#00C853',
  '#FF7F0E',
  '#1B1B1B',
];

/**
* The keys of this object are equivalent to the GraphQL Schema 'enum WidgetType'
*/
export const widgetTitles = {
  ACTIVITY_STREAM: 'Activity Stream',
  BUDGET_BREAKDOWN: 'Budget Breakdown',
  CASHFLOW: 'Cashflow',
  CONTINGENCY_METER: 'Contingency Meter',
  VARIATION_BY_CAUSE: 'Variations By Cause',
  // FFC_EXPENDITURE_CHANGE: 'FFC/Expenditure Change',
  SAFETY_INCIDENT: 'Safety Incidents',
  SAFETY_EVENT: 'Safety Events',
  MILESTONE_TIMELINE: 'Milestones'
};

/**
 * Widths of our widget, out of a max of 12
 */
export const widgetWidths = {
  ACTIVITY_STREAM: 6,
  BUDGET_BREAKDOWN: 6,
  CASHFLOW: 12,
  CONTINGENCY_METER: 6,
  VARIATION_BY_CAUSE: 12,
  // FFC_EXPENDITURE_CHANGE: 6,
  SAFETY_INCIDENT: 12,
  SAFETY_EVENT: 12,
  MILESTONE_TIMELINE: 12,
}

/**
* Heights of our widget, one of 'M' or 'L', default is 'S'.
*/
export const widgetHeights = {
  ACTIVITY_STREAM: 'S',
  BUDGET_BREAKDOWN: 'S',
  CASHFLOW: 'M',
  CONTINGENCY_METER: 'S',
  VARIATION_BY_CAUSE: 'M',
  // FFC_EXPENDITURE_CHANGE: 'S',
  SAFETY_INCIDENT: 'M',
  SAFETY_EVENT: 'M',
  MILESTONE_TIMELINE: 'M',
}

/**
 * If a widget is used in a dashboard, here we store what dashboard the widget should belong in.
 */
export const widgetDashboard = {
  ACTIVITY_STREAM: 6,
  BUDGET_BREAKDOWN: 6,
  CASHFLOW: 12,
  CONTINGENCY_METER: 6,
  VARIATION_BY_CAUSE: 12,
  // FFC_EXPENDITURE_CHANGE: 6,
  SAFETY_INCIDENT: 12,
  SAFETY_EVENT: 12,
  MILESTONE_TIMELINE: 12,
}

/**
 * The info text associated with a particular widget, essentially describing what the widget shows and were it pulls the data from.
 */
export const widgetInfo = {
  ACTIVITY_STREAM: 
    `
      The Activity Stream Widget keeps track of key changes to your project and displays it as 
      a feed. Examples of changes include (but not limited to), updating Cost & Claims line items, creating
      a new Cost Code etc.
    `,
  BUDGET_BREAKDOWN: 
    `
      The Budget Breakdown Widget fetches the Total Current Budget from each of the budget categories in your
      Cost Summary and displays it as a percentage of your Total Current Budget for your entire project. NOTE: 
      You can click on the legend items to add and remove items from the chart.
    `,
  CASHFLOW: 
    `
      The Cashflow Widget fetches data from the Cashflow module and graphs the Cashflow for the next 12 months 
      based on the different types of projections.
    `,
  CONTINGENCY_METER: 
    `
      The Contingency Meter Widget takes the total contingency for a particular budget category and expresses it as a 
      percentage of the Total Cost to Complete for the same budget category. NOTE: You can click on the legend items to 
      add and remove items from the chart.
    `,
  VARIATION_BY_CAUSE: 
    `
      The Variations By Cause Widget aggregates all the variation line items (both Approved and Unapproved) 
      in Cost & Claims across your entire project and groups them by the cause. NOTE: You can 
      click on the legend items to add and remove items from the chart.
    `,
  FFC_EXPENDITURE_CHANGE:
    `
      The FFC/Expenditure Widget displays the percentage change in your variations and expenditures over the last 
      1 month, 3 month, 6 month or 1 year period.
    `,
  SAFETY_INCIDENT:
    `
      The Safety Incident Widget displays the LTIs/MTIs/FAIs from the Safety module for this particular project over all the
      time periods defined.
    `,
  SAFETY_EVENT:
    `
      The Safety Event Widget displays the Near Miss Events (NME), Complaints Received, Reprimands Issued and Notifiable Events from the
      Safety module.
    `,
  MILESTONE_TIMELINE:
    `
      The Milestone Timeline widget pulls data from the Milestone module and displays your Milestones in a vertical timeline format sorted by
      Schedule Date.
    `
}
