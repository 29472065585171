import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Collapse,
  CardTitle,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import _ from 'lodash';
import { InputWrapper, DisplayType } from '../Shared';

import { Chat, DocumentList, DocumentUpload } from '../../../..';
import { provisionalSumVariationDocumentCategoryOptions } from '../../../../../helpers';

import { GlobalReferenceContext } from '../../../../../contexts';
import { useAddBudgetDocument } from '../../../../../hooks';
import Lets from './Lets';
import { COMMITMENT_STATUS } from '../../../../../common/dropdowns';

const BudgetProvisionalItemSidePanel = ({ item }) => {
  const [isDocumentSectionOpen, setDocumentSection] = useState(true);
  const [isCommentSectionOpen, setCommentSection] = useState(true);
  const [addBudgetDocument, { document, loading }] = useAddBudgetDocument();
  const [isAddDocumentModelOpen, setDocumentModel] = useState(false);

  const toggleDocumentSection = () =>
    setDocumentSection(!isDocumentSectionOpen);
  const toggleCommentSection = () => setCommentSection(!isCommentSectionOpen);
  const toggleAddDocumentModel = () =>
    setDocumentModel(!isAddDocumentModelOpen);

  const onDocumentUpload = ({ document, title, category, notes }) => {
    addBudgetDocument(item._id, document, title, category, notes);
  };

  useEffect(() => {
    // when document upload has finished
    if (!loading && document) {
      item.documents.push(document);
      toggleAddDocumentModel();
    }
  }, [document, loading]);

  return (
    <Row>
      <Col md="12">
        {/**
         * Document Drop Zone
         */}
        <CardTitle onClick={toggleDocumentSection}>
          <i
            className={
              isDocumentSectionOpen
                ? 'fas fa-chevron-circle-down'
                : 'fas fa-chevron-circle-right'
            }
          />{' '}
          Documents
        </CardTitle>
        <Collapse isOpen={isDocumentSectionOpen}>
          {item.documents.length > 0 && (
            <DocumentList documents={item.documents} />
          )}
          <Button color="projx" onClick={toggleAddDocumentModel}>
            <i className="fas fa-upload" /> Upload
          </Button>
          <DocumentUpload
            loading={loading}
            onDocumentUpload={onDocumentUpload}
            isOpen={isAddDocumentModelOpen}
            toggle={toggleAddDocumentModel}
            categoryOptions={provisionalSumVariationDocumentCategoryOptions}
          />
        </Collapse>

        {/**
         * Comments zone
         */}
        <CardTitle onClick={toggleCommentSection}>
          <i
            className={
              isCommentSectionOpen
                ? 'fas fa-chevron-circle-down mt-2'
                : 'fas fa-chevron-circle-right mt-2'
            }
          />{' '}
          Comments
        </CardTitle>
        <Collapse isOpen={isCommentSectionOpen} className="border">
          <Chat id={item._id} />
        </Collapse>
      </Col>
    </Row>
  );
};

const BudgetProvisionalItemPanel = ({
  approve = () => {},
  project,
  item,
  onInputChange = () => {},
}) => {
  const { normalize } = useContext(GlobalReferenceContext);
  const [activeTab, setActiveTab] = useState('general');

  const prepareCostCodesForSelect = () => {
    return project.costCodes
      .filter(({ type }) => type === 'contingency')
      .map((i) => ({
        value: i.costCode.code,
        label: `${i.costCode.code}: ${i.costCode.name}`,
      }));
  };

  const [isGeneralSectionOpen, setGeneralSection] = useState(true);
  const toggleGeneralSection = () => setGeneralSection(!isGeneralSectionOpen);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dropdownSelected = (key, value, label) => {
    onInputChange({
      cellKey: key,
      value: {
        key: label,
        value,
      },
    });
  };

  return (
    <Row>
      <Col md="12">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'general' })}
              onClick={() => {
                toggleTab('general');
              }}
            >
              General
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'endorsement' })}
              onClick={() => {
                toggleTab('endorsement');
              }}
            >
              Endorsement
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'allocation' })}
              onClick={() => {
                toggleTab('allocation');
              }}
            >
              Allocation
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="general">
            <FormGroup className="mt-3">
              <Row>
                <Col sm="12">
                  <Label className="font-12">Description</Label>
                  <InputWrapper
                    onInputChange={onInputChange}
                    width="150px"
                    cellKey="description"
                    value={item.description}
                    type={DisplayType.TextArea}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="font-12">Reason for Cost</Label>
                  <Select
                    closeMenuOnSelect
                    defaultValue={{ label: item.costCause.key }}
                    options={normalize('vo_cause')}
                    onChange={(e) =>
                      dropdownSelected('costCause', e.value, e.label)
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="font-12">Commitment Status</Label>
                  <Select
                    closeMenuOnSelect
                    defaultValue={_.find(
                      COMMITMENT_STATUS,
                      (i) => i.value === item.commitmentStatus,
                    )}
                    options={COMMITMENT_STATUS}
                    onChange={(e) =>
                      onInputChange({
                        cellKey: 'commitmentStatus',
                        value: e.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="font-12">Notice XRef</Label>
                  <InputWrapper
                    onInputChange={onInputChange}
                    width="150px"
                    cellKey="noticeReferences"
                    value={item.noticeReferences}
                    type={DisplayType.TextArea}
                  />
                </Col>
              </Row>
            </FormGroup>
          </TabPane>
          <TabPane tabId="endorsement">
            <FormGroup className="mt-3">
              <Row>
                <Col sm="12">
                  <Label className="font-12">PM/SI Review</Label>
                  <Select
                    closeMenuOnSelect
                    defaultValue={{ label: item.sipmReview.key }}
                    options={normalize('sipm_review')}
                    onChange={(e) =>
                      dropdownSelected('sipmReview', e.value, e.label)
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="font-12">Recommendation</Label>
                  <InputWrapper
                    onInputChange={onInputChange}
                    width="150px"
                    cellKey="action"
                    value={item.action}
                    type={DisplayType.TextArea}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="font-12">Further Comments</Label>
                  <InputWrapper
                    onInputChange={onInputChange}
                    width="150px"
                    cellKey="comments"
                    value={item.comments}
                    type={DisplayType.TextArea}
                  />
                </Col>
              </Row>
            </FormGroup>
          </TabPane>
          <TabPane tabId="allocation">
            <FormGroup className="mt-3">
              <Row>
                <Col sm="12">
                  <Label className="font-12">Contingency Drawdown</Label>
                  <Select
                    closeMenuOnSelect
                    defaultValue={
                      item.contingencyCostCode
                        ? {
                            label: `${item.contingencyCostCode}: ${
                              project.costCodes.find(
                                ({ costCode }) =>
                                  costCode.code === item.contingencyCostCode,
                              ).costCode.name
                            }`,
                          }
                        : undefined
                    }
                    options={prepareCostCodesForSelect()}
                    onChange={(e) =>
                      onInputChange({
                        cellKey: 'contingencyCostCode',
                        value: e.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="font-12">Accounts Payable</Label>
                  <Select
                    closeMenuOnSelect
                    defaultValue={{ label: item.accountsPayable.key }}
                    options={normalize('accounts_payable')}
                    onChange={(e) =>
                      dropdownSelected('accountsPayable', e.value, e.label)
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="font-12">Funding Source</Label>
                  <Select
                    closeMenuOnSelect
                    defaultValue={{ label: item.fundingSource.key }}
                    options={normalize('funding_source_category')}
                    onChange={(e) =>
                      dropdownSelected('fundingSource', e.value, e.label)
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="font-12">Phase</Label>
                  <InputWrapper
                    onInputChange={onInputChange}
                    width="150px"
                    cellKey="activity"
                    value={item.activity}
                    optionsKey="activities"
                    type={DisplayType.Select}
                  />
                </Col>
              </Row>
            </FormGroup>
          </TabPane>
        </TabContent>

        <CardTitle onClick={toggleGeneralSection}>
          <i
            className={
              isGeneralSectionOpen
                ? 'fas fa-chevron-circle-down'
                : 'fas fa-chevron-circle-right'
            }
          />{' '}
          Let Entries
        </CardTitle>
        <Collapse isOpen={isGeneralSectionOpen} className="mb-2">
          {item.committed.let && item.committed.let.length > 0 ? (
            <Lets
              approve={approve}
              lets={item.committed.let}
              itemId={item._id}
            />
          ) : (
            <div />
          )}
        </Collapse>
      </Col>
    </Row>
  );
};

export { BudgetProvisionalItemPanel, BudgetProvisionalItemSidePanel };
