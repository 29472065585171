import styled, { css } from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  margin-bottom: 16px;
`;

export const FilterItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export const FilterLabel = styled.label`
  color: #1B1B1B;
  font-size: 16px;
  margin: 0 16px 0 0;
`;

export const FilterInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  ${(props) => props.minWidth && css`
    min-width: ${props.minWidth};
  `}
`;

export const FilterFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
