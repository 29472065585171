import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { useAlert } from 'react-alert';
import { Card, Button, CardHeader, Col, Row, CardBody } from 'reactstrap';
import Select from 'react-select';
import BudgetManagementContent from './BudgetManagementContent';

import { updateArrayByKey, copyArrayReference } from '../../../common';
import { useCostCodeSetup } from '../../../hooks';
import { Transactions } from '../..';

const BudgetManagement = ({
  project,
  onLoaded = () => {},
  onCostCodeSelected = () => {},
}) => {
  const alert = useAlert();
  const [costCodes, setCostCodes] = useState([]);
  const [item, setItem] = useState(undefined);

  const refresh = () => getCostCodeSetupByProject(project._id);
  const onUpdate = (item) => {
    const updated = updateArrayByKey(item, costCodes);
    setCostCodes(updated);
    onLoaded(updated);

    setItem(item);
  };

  const {
    getCostCodeSetupByProject,
    deleteCostCodeSetupProject,
    retrieveCostCodeSetup,
    Actions,
  } = useCostCodeSetup({
    onCompleted: ({ action, data }) => {
      switch (action) {
        case Actions.GetCostCodeSetupProject:
          setCostCodes(
            _.orderBy(data, [(cc) => cc.costCode.name.toLowerCase()], ['asc']),
          );
          onLoaded(data);
          break;
        case Actions.DeleteCostCodeSetupProject:
          // eslint-disable-next-line no-case-declarations
          const clone = copyArrayReference(costCodes);
          _.remove(clone, (i) => i._id === data);
          setCostCodes(clone);
          setItem(null);
          onLoaded(clone);
          break;
        case Actions.RetrieveCostCodeSetup:
          onCostCodeSelected(data);
          onUpdate(data);
          break;
        default:
          break;
      }
    },
  });

  const transformItems = () => {
    return costCodes.map((i) => ({
      value: i,
      label: `[${i.costCode.code}] ${i.costCode.name}`,
    }));
  };

  const onDelete = async (item) => {
    try {
      await deleteCostCodeSetupProject(project._id, item._id);
    } catch (e) {
      alert.error(e.message);
    }
  };

  const createCode = () => {
    const clone = copyArrayReference(costCodes);

    const findEmpty = _.find(clone, (i) => i._id === '');

    if (findEmpty) {
      setItem(findEmpty);
      return;
    }

    const newItem = {
      _id: '',
      name: '',
      description: '',
      costCode: { code: 'Unassigned', name: 'Unsaved' },
      budgetCategory: { code: '', name: '' },
      accountsPayableCategory: { code: '', name: '' },
      fundingSourceCategory: { code: '', name: '' },
      type: 'cost',
      locked: false,
      amount: 0,
      organisation: '',
      hasTransactionsAssociated: false,
      hasVendorsAssociated: false,
    };

    clone.push(newItem);
    setCostCodes(clone);
    setItem(newItem);
  };

  const retrieveCostCode = async (costCodeId) => {
    try {
      await retrieveCostCodeSetup(project._id, costCodeId);
      await getCostCodeSetupByProject(project._id);
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
    }
  };

  const onTransferAction = () => {
    retrieveCostCode(item._id);
  };

  const costCodeSelected = async (e) => {
    retrieveCostCode(e.value._id);
  };

  useEffect(() => getCostCodeSetupByProject(project._id), []);

  return (
    <Card className="contact-app">
      <CardHeader>
        <Row>
          <Col xs="12" md="9">
            <Select
              closeMenuOnSelect
              options={transformItems()}
              value={
                item
                  ? {
                      value: item,
                      label: `(${item.costCode.code}) ${item.costCode.name}`,
                    }
                  : null
              }
              onChange={costCodeSelected}
              placeholder="Select Cost Code..."
            />
          </Col>
          <Col xs="12" md="3">
            <Button
              color="projx"
              className="float-right mt-1"
              onClick={createCode}
            >
              <i className="fas fa-plus" /> Create Code
            </Button>
          </Col>
        </Row>
      </CardHeader>
      {item ? (
        <BudgetManagementContent
          item={item}
          onUpdate={onUpdate}
          project={project}
          refresh={refresh}
          onDelete={onDelete}
          costCodes={costCodes}
        />
      ) : (
        <div className="d-flex mt-3 p-4 flex-column align-items-center justify-content-center">
          <i className="ti-agenda display-5" />
          <h4 className="mt-2">Select a cost code item.</h4>
        </div>
      )}
      {item ? (
        <CardBody className="border-top mt-3">
          <h4 className="text-muted mb-3">Budget Transfer History</h4>
          <Transactions
            project={project}
            costCode={item}
            onTransferAction={onTransferAction}
          />
        </CardBody>
      ) : (
        ''
      )}
    </Card>
  );
};

export default BudgetManagement;
