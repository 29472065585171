import React, { useState, useEffect, useContext } from 'react';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Modal,
  Label,
} from 'reactstrap';
import _ from 'lodash';

import Select from 'react-select';
import Chart from 'react-c3-component';
import 'c3/c3.css';

import { useAlert } from 'react-alert';
import { currencify, extractGraphqlError } from '../../../common';
import VendorInfo from '../../vendor/VendorInfo';
import { useCreateFocotr, useCostCodeSetup } from '../../../hooks';
import { BudgetConfigContext } from '../../../contexts';
import { projxColorPalette } from '../../../constants';

const highlightStyle = {
  control: (base, state) => ({
    ...base,
    border: '1px solid red',
    // You can also use state.isFocused to conditionally style based on the focus state
  }),
};

const Financials = ({
  project,
  onChange = () => {},
  onFocotr = () => {},
  totalOfTotals,
  cc,
}) => {
  const [modal, setModal] = useState(false);
  const [vendor] = useState(undefined);
  const [costCode, setCostCode] = useState(undefined);
  const [costCodes, setCostCodes] = useState([]);
  const [tOt, setToT] = useState(undefined);
  const [overbudget, setOverbudget] = useState(undefined);

  const alert = useAlert();

  const { config } = useContext(BudgetConfigContext);

  const [createFocotr] = useCreateFocotr({
    onCompleted: (data) => {
      toggle();
      onFocotr(data);
    },
  });

  const { getCostCodeSetupByProject, Actions } = useCostCodeSetup({
    onCompleted: ({ action, data }) => {
      switch (action) {
        case Actions.GetCostCodeSetupProject:
          setCostCodes(_.filter(data, (f) => f.type === 'cost'));
          break;
        default:
          break;
      }
    },
  });

  const uncomitted = () => {
    return costCode.currentAmount - tOt.fcc;
  };

  const leftover = (totalOfTotalsBudget) => {
    return {
      forecast: {
        amount: costCode.currentAmount - totalOfTotalsBudget.forecasts,
        over: totalOfTotalsBudget.forecasts > costCode.currentAmount,
      },
      committed: {
        amount:
          costCode.currentAmount -
          (totalOfTotalsBudget.contracted + totalOfTotalsBudget.adjustments),
        over:
          totalOfTotalsBudget.contracted + totalOfTotalsBudget.adjustments >
          costCode.currentAmount,
      },
      ffc: {
        amount: costCode.currentAmount - totalOfTotalsBudget.fcc,
        over: costCode.currentAmount,
      },
    };
  };

  const approved = () => {
    let totalContracted = tOt.contracted + tOt.adjustments;
    return (totalContracted / tOt.fcc) * 100;
  };

  const unapproved = () => {
    return (tOt.forecasts / tOt.fcc) * 100;
  };

  const stats = (statName) => {
    if (!tOt.costStats[statName]) return 0;

    let total = 0;

    for (const [, value] of Object.entries(tOt.costStats)) {
      total += value;
    }

    let percentage = (tOt.costStats[statName] / total) * 100;

    return percentage;
  };

  const toggle = () => setModal(!modal);

  const selectedVendor = async (vendor, accountType) => {
    try {
      await createFocotr({
        costCode: costCode.costCode,
        project: project._id,
        vendor: vendor._id,
        accountType,
      });
    } catch (e) {
      alert.error(extractGraphqlError(e));
    }
  };

  const transformItems = () => {
    return costCodes
      ? costCodes.map((i) => ({
          value: i,
          label: `(${i.costCode.code}) ${i.costCode.name} [${
            i.currentAmount > 0 ? currencify(i.currentAmount, true) : '0'
          }]`,
        }))
      : [];
  };

  useEffect(() => {
    if (costCode) {
      onChange(costCode);
      setToT(undefined);
      setOverbudget(undefined);
    }
  }, [vendor, costCode]);

  useEffect(() => {
    if (cc && costCodes.length > 0) {
      const foundCostCode = _.find(
        costCodes,
        (code) => code.costCode.code === cc,
      );
      if (foundCostCode) setCostCode(foundCostCode);
    }
  }, [cc, costCodes]);

  useEffect(() => getCostCodeSetupByProject(project._id), []);

  useEffect(() => {
    if (totalOfTotals) {
      setToT(totalOfTotals);
      setOverbudget(leftover(totalOfTotals));
    }
  }, [totalOfTotals]);

  return (
    <>
      <Row className="mb-4">
        <Col md="5">
          {costCode === undefined && (
            <Label className="text-danger">
              <i className="fas fa-arrow-circle-down mr-2" />
              Select one of the existing cost codes.
            </Label>
          )}
          <Select
            placeholder="Select cost code ..."
            styles={costCode === undefined ? highlightStyle : undefined}
            closeMenuOnSelect
            defaultValue={undefined}
            options={transformItems()}
            onChange={(e) => setCostCode(e.value)}
          />
        </Col>
      </Row>
      {tOt ? (
        <Card className="order-widget">
          <CardBody>
            <Row>
              <Col sm="12" md="7">
                <Row className="mt-3">
                  <Col xs="3" className="border-right">
                    <i className="fa fa-circle text-cyan" />
                    <h4 className="mb-0 font-medium">
                      {tOt.forecasts && tOt.forecasts > 0
                        ? currencify(
                            tOt.forecasts,
                            true,
                            config.decimal ? 2 : 0,
                          )
                        : '-'}
                    </h4>
                    <span>Unapproved</span>
                  </Col>
                  <Col xs="3" className="border-right">
                    <i className="fa fa-circle text-orange" />
                    <h4 className="mb-0 font-medium">
                      {tOt.contracted && tOt.contracted > 0
                        ? currencify(
                            tOt.contracted + tOt.adjustments,
                            true,
                            config.decimal ? 2 : 0,
                          )
                        : '-'}
                    </h4>
                    <span>Approved</span>
                  </Col>
                  <Col xs="3" className="border-right">
                    <i className="fa fa-circle text-success" />
                    <h4 className="mb-0 font-medium">
                      {tOt.fcc && tOt.fcc > 0
                        ? currencify(tOt.fcc, true, config.decimal ? 2 : 0)
                        : '-'}
                    </h4>
                    <span>FFC</span>
                  </Col>
                  <Col xs="3">
                    <i className="fa fa-circle text-secondary" />
                    <h4
                      className={
                        uncomitted() < 0
                          ? 'mb-0 font-medium in-minus'
                          : 'mb-0 font-medium'
                      }
                    >
                      {uncomitted() === ''
                        ? '-'
                        : currencify(
                            uncomitted(),
                            true,
                            config.decimal ? 2 : 0,
                          )}
                    </h4>
                    <span>Forecast Position</span>
                  </Col>
                </Row>
              </Col>
              <Col sm="12" md="5">
                <Row>
                  {tOt.stats.count > 0 ? (
                    <>
                      <Col sm="4">
                        <h5 className="ml-4">FFC by Approval</h5>
                        <div id="visitor" className="mt-3">
                          <Chart
                            style={{ height: '150px', width: '100%' }}
                            config={{
                              data: {
                                columns: [
                                  ['Approved', approved()],
                                  ['Unapproved', unapproved()],
                                ],
                                type: 'donut',
                                tooltip: {
                                  show: true,
                                },
                              },
                              donut: {
                                label: {
                                  show: false,
                                },
                                width: 20,
                              },
                              legend: {
                                hide: true,
                              },
                              color: {
                                pattern: projxColorPalette,
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm="4">
                        <h5 className="ml-5">FFC by Cost Type</h5>
                        <div id="visitor" className="mt-3">
                          <Chart
                            style={{ height: '150px', width: '100%' }}
                            config={{
                              data: {
                                columns: [
                                  [
                                    'Contracts',
                                    (tOt.stats.ffcPercentages.contracts /
                                      tOt.fcc) *
                                      100,
                                  ],
                                  [
                                    'Provisional Sums',
                                    (tOt.stats.ffcPercentages.provisionals /
                                      tOt.fcc) *
                                      100,
                                  ],
                                  [
                                    'Variations',
                                    (tOt.stats.ffcPercentages.variations /
                                      tOt.fcc) *
                                      100,
                                  ],
                                ],
                                type: 'donut',
                                tooltip: {
                                  show: true,
                                },
                              },
                              donut: {
                                label: {
                                  show: false,
                                },
                                width: 20,
                              },
                              legend: {
                                hide: true,
                              },
                              color: {
                                pattern: projxColorPalette,
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm="4">
                        <h5 className="ml-5">FFC by Cost Status</h5>
                        <div id="visitor" className="mt-3">
                          <Chart
                            style={{ height: '150px', width: '100%' }}
                            config={{
                              data: {
                                columns: [
                                  ['Contracted', stats('contracted')],
                                  ['Anticipated', stats('anticipated')],
                                  ['Submitted', stats('submitted')],
                                  ['Assessed', stats('assessed')],
                                  // ['Let', stats('let')],
                                  // ['Variations', stats('variation')],
                                ],
                                type: 'donut',
                                tooltip: {
                                  show: true,
                                },
                              },
                              donut: {
                                label: {
                                  show: false,
                                },
                                width: 20,
                              },
                              legend: {
                                hide: true,
                              },
                              color: {
                                pattern: projxColorPalette,
                              },
                            }}
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <div className="float-left">
              {!costCode ? (
                <div />
              ) : (
                <>
                  <h3 className="mb-0">
                    {costCode.currentAmount > 0
                      ? currencify(costCode.currentAmount, true)
                      : '$0'}
                  </h3>
                  <h6 className="font-light text-muted">
                    {costCode.costCode.name} Budget
                  </h6>
                </>
              )}
            </div>

            <Button
              disabled={!costCode}
              onClick={toggle}
              className="float-right mt-2"
              color="projx"
            >
              <i className="fas fa-plus" /> Add Vendor
            </Button>
          </CardFooter>
          {!overbudget || !overbudget.committed.over ? (
            ''
          ) : (
            <CardFooter className="border-top">
              <div className="float-left">
                <i className="fas fa-exclamation-triangle text-warning" />{' '}
                Approved amount exceeds budget by{' '}
                <Label className="text-danger">
                  {currencify(Math.abs(overbudget.committed.amount), true, 0)}
                </Label>
              </div>
            </CardFooter>
          )}
        </Card>
      ) : (
        ''
      )}
      <Modal isOpen={modal} toggle={toggle} backdrop>
        <VendorInfo onVendorSelected={selectedVendor} />
      </Modal>
    </>
  );
};

export default Financials;
