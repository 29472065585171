import gql from 'graphql-tag';

/**
 * Queries
 */
const PROJECT_BY_SLUG_QUERY = gql`
  fragment CostCode on CostCodeSetup {
    _id
    name
    description
    costCode {
      code
      name
    }
    budgetCategory {
      code
      name
    }
    accountsPayableCategory {
      code
      name
    }
    fundingSourceCategory {
      code
      name
    }
    type
    organisation {
      _id
    }
    currentAmount
    locked
  }

  query ($slug: String!) {
    getProjectBySlug(slug: $slug) {
      _id
      name
      slug
      description
      logo
      thumbnail
      address
      sectors
      constructionType
      code
      organisation {
        _id
      }
      owner {
        _id
        name
        handle
        phone
        title
        company
        image
      }
      client {
        name
        contract
        abn
      }
      collaborators {
        user {
          _id
          name
          handle
          email
          phone
          title
          image
          company
        }
      }
      costCodes {
        ...CostCode
      }
      locked
      amount
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
    }
  }
`;

const PROJECT_BY_ID_QUERY = gql`
  fragment CostCode on CostCodeSetup {
    _id
    name
    description
    costCode {
      code
      name
    }
    budgetCategory {
      code
      name
    }
    accountsPayableCategory {
      code
      name
    }
    fundingSourceCategory {
      code
      name
    }
    type
    organisation {
      _id
    }
    amount
    currentAmount
    locked
  }

  query ($_id: ID!) {
    getProject(_id: $_id) {
      _id
      name
      slug
      description
      logo
      thumbnail
      address
      sectors
      constructionType
      code
      organisation {
        _id
        name
        logo
      }
      owner {
        _id
        name
        handle
        phone
        title
        company
        image
      }
      client {
        name
        contract
        abn
      }
      costCodes {
        amount
      }
      collaborators {
        user {
          _id
          name
          handle
          email
          phone
          title
          image
          company
        }
        role {
          _id
          name
        }
      }
      costCodes {
        ...CostCode
      }
      locked
      amount
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
    }
  }
`;

const PROJECTS_BY_ORG_QUERY = gql`
  query ($organisation: ID!) {
    getProjectsByOrg(organisation: $organisation) {
      _id
      name
      slug
      description
      logo
      thumbnail
      address
      sectors
      constructionType
      code
      organisation {
        _id
        name
        logo
      }
      owner {
        _id
        name
        handle
        phone
        title
        company
        image
      }
      client {
        name
        contract
        abn
      }
      costCodes {
        amount
      }
      collaborators {
        user {
          _id
          name
          handle
          email
          phone
          title
          image
          company
        }
        role {
          _id
          name
        }
      }
      locked
      amount
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
    }
  }
`;

/**
 * Mutations
 */

const CREATE_PROJECT_MUTATION = gql`
  fragment CostCode on CostCodeSetup {
    _id
    name
    description
    costCode {
      code
      name
    }
    budgetCategory {
      code
      name
    }
    accountsPayableCategory {
      code
      name
    }
    fundingSourceCategory {
      code
      name
    }
    type
    organisation {
      _id
    }
    locked
  }

  mutation ($project: CreateProjectInput!) {
    createProject(project: $project) {
      _id
      name
      slug
      description
      address
      sectors
      constructionType
      code
      owner {
        _id
        name
        handle
        phone
        title
        company
        image
      }
      organisation {
        _id
        name
        logo
      }
      client {
        name
        contract
        abn
      }
      collaborators {
        user {
          _id
          name
          handle
          email
          phone
          title
          image
          company
        }
      }
      costCodes {
        ...CostCode
      }
      amount
      documents {
        _id
        category
        title
        notes
        location
        uploadedBy
        createdAt
      }
    }
  }
`;

const ADD_PROJECT_COLLABORATOR_MUTATION = gql`
  mutation ($_id: ID!, $collaborator: CollaboratorInput!) {
    addProjectCollaborator(_id: $_id, collaborator: $collaborator)
  }
`;

const UPDATE_PROJECT_COLLABORATOR_MUTATION = gql`
  mutation ($_id: ID!, $collaborator: CollaboratorInput!) {
    updateProjectCollaborator(_id: $_id, collaborator: $collaborator)
  }
`;

const DELETE_PROJECT_COLLABORATOR_MUTATION = gql`
  mutation ($_id: ID!, $userId: ID!) {
    deleteProjectCollaborator(_id: $_id, userId: $userId)
  }
`;

const SAVE_PROJECT_FIELD_MUTATION = gql`
  mutation ($projectId: ID!, $key: String!, $value: String!) {
    saveProjectField(projectId: $projectId, key: $key, value: $value) {
      id
      key
      value
      saved
      message
    }
  }
`;

const ADD_PROJECT_DOCUMENT_MUTATION = gql`
  mutation ($projectId: ID!, $documentInput: DocumentInput!) {
    addProjectDocument(projectId: $projectId, documentInput: $documentInput) {
      _id
      title
      category
      notes
      uploadedBy
      location
      createdAt
    }
  }
`;

export {
  PROJECT_BY_ID_QUERY,
  PROJECT_BY_SLUG_QUERY,
  PROJECTS_BY_ORG_QUERY,
  CREATE_PROJECT_MUTATION,
  ADD_PROJECT_COLLABORATOR_MUTATION,
  UPDATE_PROJECT_COLLABORATOR_MUTATION,
  DELETE_PROJECT_COLLABORATOR_MUTATION,
  SAVE_PROJECT_FIELD_MUTATION,
  ADD_PROJECT_DOCUMENT_MUTATION,
};
