import React, { useState, useEffect } from 'react';
import SearchInput, { createFilter } from 'react-search-input';

import _ from 'lodash';
import { useGlobalSearch } from '../../hooks';

const GlobalSearch = ({ data, keys = [], onResults = () => {} }) => {
  const [term, setTerm] = useState(undefined);
  const [dataToFilter, setData] = useState([]);

  const searchUpdate = (searchTerm) => {
    setTerm(searchTerm);
  };

  useEffect(() => {
    if (term === undefined || term === '') return onResults(undefined);

    const filteredResults = _.filter(dataToFilter, createFilter(term, keys));
    onResults(filteredResults);
  }, [term]);

  useEffect(() => {
    if (!data) return;
    setData(data);
  }, [data]);

  return <SearchInput className="search-input" onChange={searchUpdate} />;
};

const GlobalSearchV2 = ({ onResults = () => {}, delay = 500 }) => {
  const { globalSearch } = useGlobalSearch({
    onCompleted: (data) => {
      onResults(data);
    },
  });

  const search = (searchTerm) => {
    try {
      if (!searchTerm || searchTerm === '') return onResults([]);
      globalSearch(searchTerm);
    } catch {}
  };

  return (
    <SearchInput
      placeholder="Global Search..."
      className="search-input"
      onChange={search}
      throttle={delay}
    />
  );
};

export default GlobalSearch;

export { GlobalSearchV2 };
