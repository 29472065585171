import { useMutation } from '@apollo/client';
import _ from 'lodash';

import { UPLOAD_IMAGE_MUTATION } from '../../graphql/queries';

import { cleanGqlTypename } from '../../common';

const ImageType = {
  Organisation: 'organisation',
  Profile: 'profile',
  Project: 'project',
};

const useUpload = (type) => {
  const [_uploadImage, { loading, data, error }] = useMutation(
    UPLOAD_IMAGE_MUTATION,
  );

  const uploadImage = (image, identifier) => {
    return _uploadImage({
      variables: {
        image,
        type,
        identifier,
      },
    });
  };

  return [
    uploadImage,
    {
      data:
        !loading && data
          ? cleanGqlTypename(_.get(data, 'uploadImage'))
          : undefined,
      loading,
      error,
    },
  ];
};

export default useUpload;

export { useUpload, ImageType };
