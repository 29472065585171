import { useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import { GLOABL_SEARCH_QUERY } from '../../graphql/queries';

import { cleanGqlTypename } from '../../common';
import { OrganisationContext } from '../../contexts';

const itemMapper = (item) => cleanGqlTypename(item);

const mapper = (items) => {
  return _.map(items, (item) => itemMapper(item));
};

const useGlobalSearch = ({ onCompleted = () => {} }) => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const [_globalSearch, { loading }] = useLazyQuery(GLOABL_SEARCH_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => onCompleted(mapper(_.get(data, 'globalSearch'))),
  });

  const globalSearch = (term) => {
    return _globalSearch({
      variables: {
        term,
        organisation: currentOrganisation ? currentOrganisation._id : null,
      },
    });
  };

  return {
    globalSearch,
    loading,
  };
};

export default useGlobalSearch;
