import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const AccountSummary = (props) => {
  const { user, loading } = props;

  if (loading) {
    return (
      <Card>
        <CardBody>
          <div className="text-center mt-4">
            <Skeleton circle height={150} width={150} />
          </div>
          <CardBody className="border-top">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </CardBody>
        </CardBody>
      </Card>
    );
  }
  return (
    <Card>
      <CardBody>
        <div className="text-center mt-4">
          <img src={user.image} className="rounded-circle" width="150" alt="" />
          <CardTitle className="mt-2">{user.name}</CardTitle>
          <CardSubtitle>{user.company}</CardSubtitle>
          <CardSubtitle>{user.title}</CardSubtitle>
        </div>
      </CardBody>
      <CardBody className="border-top">
        <div>
          <small className="text-muted">Email address </small>
          <h6>{user.email}</h6>
          <small className="text-muted pt-4 db">Phone</small>
          <h6>{user.phone ? user.phone : ' -'}</h6>
        </div>
      </CardBody>
    </Card>
  );
};

export default AccountSummary;
