import { useContext, useState } from 'react';
import { UseBudgetCostsQuery } from './index';

import { copyArrayReference } from '../common';
import { ProjectCtx } from '../contexts';

const useBudgetCost = (props = {}) => {
  const { notifyOnChange = () => {} } = props;

  const [loaded, setIsLoaded] = useState(false);
  const { budgetCost, setBudgetCost } = useContext(ProjectCtx);

  const _getBudgetCost = (key) => {
    if (key === 'new' && budgetCost && budgetCost.key === 'new')
      return budgetCost;

    const { budgetCosts, loaded } = UseBudgetCostsQuery(key);

    setBudgetCost(budgetCosts);
    setIsLoaded(loaded);
    return budgetCosts;
  };

  const createBudgetCost = () => {
    const newEntry = {
      key: 'new',
      totalBudget: 0,
      enabled: true,
      items: [],
    };
    setBudgetCost(newEntry);
    setIsLoaded(true);
    return newEntry;
  };

  const getBudgetCost = (key) => {
    if (!key || (key === 'new' && (!budgetCost || budgetCost.key !== 'new'))) {
      return createBudgetCost();
    }
    return _getBudgetCost(key);
  };

  const onBudgetCostChange = (update) => {
    let tempBudgetCostItems = copyArrayReference(budgetCost);
    tempBudgetCostItems = {
      ...tempBudgetCostItems,
      ...update,
    };

    setBudgetCost(tempBudgetCostItems);

    if (notifyOnChange) notifyOnChange(update);
  };

  const maybeCleanup = () => {
    if (budgetCost && budgetCost.key !== 'new') {
      cleanup();
    }
  };
  let cleanup = () => {
    setBudgetCost(undefined);
    setIsLoaded(false);
  };

  const getBudgetCostReferences = (budgetReferenceResolver) => {
    return budgetCost.items.map((item) => {
      return { value: item.key, label: budgetReferenceResolver(item.costCode) };
    });
  };

  const transfer = (fromKey, toKey, amount) => {
    return new Promise((resolve, reject) => {
      return resolve({});
    });
  };

  return {
    loaded,
    budgetCost,
    onBudgetCostChange,
    createBudgetCost,
    getBudgetCost,
    cleanup,
    maybeCleanup,
    getBudgetCostReferences,
    transfer,
  };
};

export default useBudgetCost;
