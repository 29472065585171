import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import CurrencyInput from 'react-currency-input';
import {
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Select from 'react-select';
import { useAlert } from 'react-alert';

import { currencify } from '../../../common';

import { useCostCodeSetup, useTransaction } from '../../../hooks';

const CostTransfer = ({
  item,
  project,
  transactionCompleted = () => {},
  toggle = () => {},
  open,
}) => {
  const alert = useAlert();

  const [transferAmount, setTransferAmount] = useState(undefined);
  const [destination, setDestination] = useState();
  const [description, setDescription] = useState();
  const [transferableItems, setTransferableItems] = useState([]);
  const [overBudget, setOverBudget] = useState({ amount: 0, over: false });
  const [availableCost, setAvailableCost] = useState(item.currentAmount);
  const [transaction, setTransferSelected] = useState(undefined);
  const [block, setBlock] = useState(false);
  const [approved] = useState(false);

  const { getCostCodeSetupByProject, Actions } = useCostCodeSetup({
    onCompleted: ({ action, data }) => {
      switch (action) {
        case Actions.GetCostCodeSetupProject:
          filterTransferableItems(data);
          break;
        default:
          break;
      }
    },
  });

  const { createTransaction, TransactionOperationType } = useTransaction({
    onCompleted: ({ action, data }) => {
      switch (action) {
        case TransactionOperationType.CreateTransaction:
          transactionCompleted(data);
          setBlock(false);
          toggle();
          break;
        default:
          break;
      }
    },
  });

  const filterTransferableItems = (transferableItemsData) => {
    _.remove(transferableItemsData, (i) => i._id === item._id);
    setTransferableItems(transferableItemsData);
  };

  const transformItems = () => {
    return transferableItems.map((i) => ({
      value: i._id,
      label: `${i.costCode.name} [${currencify(i.currentAmount, true)}]`,
    }));
  };

  const onChange = (e, maskedValue, floatValue) => {
    const amountLeft = item.currentAmount - floatValue;

    setAvailableCost(amountLeft < 0 ? 0 : amountLeft);

    if (amountLeft < 0)
      setOverBudget({ over: true, amount: Math.abs(amountLeft) });
    else setOverBudget({ over: false });

    setTransferAmount(floatValue);
  };

  useEffect(() => {
    getCostCodeSetupByProject(project._id);
  }, []);

  useEffect(() => {
    if (
      transferAmount <= 0 ||
      !destination ||
      destination === undefined ||
      destination === ''
    )
      return;

    setTransferSelected({
      source: item._id,
      destination,
      amount: transferAmount,
      notes: description,
      project: project._id,
      approved,
    });
  }, [transferAmount, destination, description]);

  const performTransfer = async () => {
    if (!transaction) return;
    try {
      setBlock(true);
      await createTransaction(transaction);
    } catch (e) {
      setBlock(false);
      alert.error(e.message);
    }
  };

  return (
    <Modal isOpen={open} toggle={toggle} backdrop>
      <ModalHeader toggle={toggle}>Cost Transfer</ModalHeader>
      <ModalBody>
        {transferableItems.length <= 0 ? (
          <div className="d-flex mt-3 p-4 flex-column align-items-center justify-content-center">
            <i className="ti-agenda display-5" />
            <h4 className="mt-2">No cost codes exist</h4>
          </div>
        ) : (
          <div className="step step1 mt-2">
            <div className="row justify-content-md-center">
              <div className="col">
                <div className="">
                  <h4>
                    You have{' '}
                    <code>
                      {availableCost > 0 ? currencify(availableCost, true) : ''}
                    </code>{' '}
                    {availableCost > 0 ? 'of' : 'no'} available budget to
                    transfer from <code>{}</code> to a selected cost code.
                  </h4>
                  <Row>
                    <Col md={6}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <CurrencyInput
                          placeholder="$ amount"
                          type="tel"
                          precision={0}
                          value={transferAmount}
                          onChangeEvent={onChange}
                          className="form-control currency-lg"
                        />
                      </InputGroup>
                      <small className="badge badge-default badge-projx text-white float-left mb-4">
                        Transfer Amount
                      </small>
                      {overBudget.over ? (
                        <small className="badge badge-default badge-warning text-white float-left mb-4">
                          ${overBudget.amount} over budget!
                        </small>
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col md={12}>
                      <Select
                        closeMenuOnSelect
                        options={transformItems()}
                        onChange={(e) => {
                          setDestination(e.value);
                        }}
                      />
                      <small className="badge badge-default badge-projx text-white float-left mb-4">
                        Transfer To:
                      </small>
                    </Col>
                    {/* <Col md={12} className="d-flex">
                                                <Switch onText="Y" offText="N" defaultValue={approved}
                                                    onChange={(e) => setApproved(e.state.value)} />
                                            </Col> */}
                    {/* <Col md={12}>
                                                <small className="badge badge-default badge-success text-white float-left mb-4">Approve Transfer</small>
                                            </Col> */}
                    <Col md={12}>
                      <Input
                        placeholder="budget transfer notes ..."
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        className="form-control"
                        type="textarea"
                        rows="3"
                      />
                      <small className="badge badge-default badge-projx text-white float-left mb-4">
                        Notes
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button
                        className="float-right mr-2 mt-2"
                        color="projx"
                        onClick={performTransfer}
                        disabled={
                          !!(!transaction || transferAmount <= 0 || block)
                        }
                      >
                        Transfer
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CostTransfer;
