import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 24px 32px;
  height: 192px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    border: 1px solid #d9d9d9;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderText = styled.p`
  height: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #212121;
`;

export const BodyContainer = styled.div`
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
`;

export const BodyPrimaryText = styled.p`
  height: 32px;
  color: #212121;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  margin: 0;
`;

export const BodySecondaryText = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #212121;
  margin: 8px 0 0 8px;
`;

export const BarPercentageContainer = styled.div`
  width: 100%;
  height: 16px;
  display: block;
  overflow: clip;
  border-right: 1px solid #000000;
  margin-bottom: 16px;
`;

export const BarPercentage = styled.div`
  width: ${(props) => props.widthPercentage}%;
  height: 16px;
  border-radius: 4px 0 0 4px;
  background-color: ${(props) => props.color};
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FooterGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const FooterPrimaryText = styled.p`
  height: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  margin: 0;
`;

export const FooterSecondaryText = styled.p`
  height: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616161;
  margin-left: 8px;
`;
