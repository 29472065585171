import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { parse } from 'query-string';
import jwtDecode, { InvalidTokenError } from 'jwt-decode';
import AuthContext from '../../contexts/auth/auth.context';
import rules from '../../helpers/form-validator/libs/rules';

// New invitation component stuff
import logoDarkSVG from '../../assets/images/logo_dark.svg';
import { LogoIMG } from '../../components';
import {
  LoginPageContainer,
  LoginFormContainer,
  LogoContainer,
  LoginFormBody,
  LoginFormHeading,
  LoginFormInput,
  LoginFormBoldText,
  LoginFormErrorContainer,
  LoginFormErrorText,
  LoginFormText,
  LoginFormButton,
} from './styles/LoginStyles';

const Invitation = ({ location }) => {
  const history = useHistory();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasInvitationToken, setHasInvitationToken] = useState(false);

  const { login, register } = useContext(AuthContext);

  useEffect(() => {
    const params = parse(location.search);
    if (params.token) {
      let decoded;
      try {
        decoded = jwtDecode(params.token);
      } catch (err) {
        if (err instanceof InvalidTokenError) {
          history.push('/authentication/register');
          return;
        }
        throw err;
      }
      setEmail(decoded.email);
      setHasInvitationToken(true);
    }
  }, [location]);

  // Construct errors for password field
  let passwordErrors = [];
  if (password.length !== 0) {
    if (password.length < 8) {
      passwordErrors.push('* Password must be at least 8 characters');
    }
    if (!rules.password[1].test.test(password)) {
      passwordErrors.push('* Password must contain a lowercase character');
    }
    if (!rules.password[2].test.test(password)) {
      passwordErrors.push('* Password must contain an uppercase character');
    }
    if (!rules.password[3].test.test(password)) {
      passwordErrors.push('* Password must contain a number');
    }
    if (!rules.password[4].test.test(password)) {
      passwordErrors.push('* Password must contain a special character');
    }
  }

  // Construct errors for confirm new password field
  let confirmPasswordErrors = [];
  if (
    password.length !== 0 &&
    password !== confirmPassword &&
    confirmPassword.length !== 0
  ) {
    confirmPasswordErrors.push('* Passwords do not match');
  }

  const showFormValidationErrors = (field) => {
    switch (field) {
      case 'password':
        const mappedNewPasswordErrors = _.map(passwordErrors, (value) => (
          <LoginFormErrorText>{value}</LoginFormErrorText>
        ));

        return (
          <LoginFormErrorContainer>
            {mappedNewPasswordErrors}
          </LoginFormErrorContainer>
        );

        break;
      case 'confirmPassword':
        const mappedConfirmNewPasswordErrors = _.map(
          confirmPasswordErrors,
          (value) => <LoginFormErrorText>{value}</LoginFormErrorText>,
        );

        return (
          <LoginFormErrorContainer>
            {mappedConfirmNewPasswordErrors}
          </LoginFormErrorContainer>
        );

        break;
    }
  };

  const doRegister = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    register(email, password, name, hasInvitationToken)
      .then(() => {
        return login(email, password, name);
      })
      .then((user) => {
        history.push('/');
      })
      .catch((error) => {
        setIsProcessing(false);
        console.error(error);
        alert('Something went wrong!');
      });
  };

  const isContinueButtonDisabled =
    name === '' ||
    password === '' ||
    confirmPassword === '' ||
    passwordErrors.length > 0 ||
    confirmPasswordErrors.length > 0 ||
    isProcessing

  return (
    <LoginPageContainer>
      <LoginFormContainer center>
        <Link to="/">
          <LogoContainer>
            <LogoIMG src={logoDarkSVG} alt="projx-logo" />
          </LogoContainer>
        </Link>
        <LoginFormBody onSubmit={doRegister}>
          <LoginFormHeading>Complete Registration</LoginFormHeading>
          <LoginFormInput
            placeholder="Email"
            type="text"
            id="email"
            name="email"
            value={email}
            disabled
          />
          {/* {showFormValidationErrors('email')} */}
          <LoginFormInput
            placeholder="Name"
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <LoginFormInput
            placeholder="Password"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {showFormValidationErrors('password')}
          <LoginFormInput
            placeholder="Confirm Password"
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {showFormValidationErrors('confirmPassword')}
          <LoginFormText center>
            By registering, you agree to our{' '}
            <LoginFormBoldText>Terms</LoginFormBoldText>,
            <LoginFormBoldText>Data Policy</LoginFormBoldText> and{' '}
            <LoginFormBoldText>Cookies Policy</LoginFormBoldText>
          </LoginFormText>
          <LoginFormButton type="submit" disabled={isContinueButtonDisabled}>
            {isProcessing ? 'Loading...' : 'Continue'}
          </LoginFormButton>
        </LoginFormBody>
      </LoginFormContainer>
    </LoginPageContainer>
  );
};

export default Invitation;
