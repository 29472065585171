import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const InputHeader = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

// This container just sends all items to the right in our 'Add Safety Period' modal :)
export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const MonthYearPicker = styled(DatePicker)`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  height: 36px;
  padding-left: 8px;
  margin: 8px 0;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
`;
