import React from 'react';
import { ErrorContainer, ErrorIcon, ErrorText } from './styles/ErrorWidget';

const ErrorWidget = ({}) => {
  return (
    <ErrorContainer>
      <ErrorIcon />
      <ErrorText>
        Something went wrong... Please refresh and try again.
      </ErrorText>
    </ErrorContainer>
  );
}

export default ErrorWidget;