import React, { useState, useEffect, useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  Row,
  Col,
  BreadcrumbItem,
  Breadcrumb,
} from 'reactstrap';
// Local Imports
import ProjectContext from '../../contexts/projects/projects.context';
import { withProject } from '../../common';
import { Widget, Spinner} from '../../components';
import { widgetTitles, widgetWidths, widgetHeights } from '../../constants';

// GraphQL Queries
const GET_WIDGETS_PROGRAM_DASHBOARD = gql`
  query getWidgets($projectId: ID!, $dashboard: DashboardType!) {
    getWidgets(projectId: $projectId, dashboard: $dashboard) {
      _id
      type
      data
      description
      dashboard
    }
  }
`;

const ProgramDashboard = ({ project }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const { setProject } = useContext(ProjectContext);

  useEffect(() => {
    setProject(project);
  }, []);

  const { loading: loadingWidgets, error: errorWidgets, data: dataWidgets } = useQuery(GET_WIDGETS_PROGRAM_DASHBOARD, {
    variables: {
      projectId: project._id,
      dashboard: "PROGRAM",
    },
    onCompleted: (data) => {
      setWidgets(data.getWidgets);
    }
  });

  if (loadingWidgets) return <Spinner />;

  return (
    <div>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href="">
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href="">
          Program Dashboard
        </BreadcrumbItem>
      </Breadcrumb>
      <Row>
        {_.map(widgets, (w) => (
          <Col key={`${w.type}`} xl={widgetWidths[w.type]}>
            <Widget
              title={`${widgetTitles[w.type]}`}
              type={`${w.type}`}
              inEditMode={isEditable}
              projectId={project._id}
              height={widgetHeights[w.type]}
              dashboard="PROGRAM"
              setWidgets={setWidgets}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Widget.AddWidgetButton
          inEditMode={isEditable}
          widgets={widgets}
          setWidgets={setWidgets}
          projectId={project._id}
          dashboard="PROGRAM"
        />
      </Row>
      <Row>
        <Widget.EditWidgetButton
          inEditMode={isEditable}
          onClick={() => setIsEditable(() => !isEditable)}
        />
      </Row>
    </div>
  );
};

export default withProject(ProgramDashboard);
