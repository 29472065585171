import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DocumentList from './DocumentList';
import DocumentUpload from './DocumentUpload';

const DocumentListModal = ({
  documents,
  onDocumentUpload,
  isOpen,
  toggle,
  header,
  categoryOptions,
  documentUploadComplete,
  documentUploadLoading,
  disableUpload = false,
}) => {
  const [isAddDocumentModelOpen, setDocumentModel] = useState(false);

  const toggleAddDocumentModel = () =>
    setDocumentModel(!isAddDocumentModelOpen);

  const _buttonOnClick = () => {
    toggleAddDocumentModel();
  };

  // Close model when upload is finishd
  useEffect(() => {
    if (documentUploadComplete && isAddDocumentModelOpen)
      toggleAddDocumentModel();
  }, [documentUploadComplete]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>{header}</ModalHeader>
        <ModalBody>
          <DocumentList documents={documents} />
        </ModalBody>
        {!disableUpload && ( // disable button to upload based on prop
          <ModalFooter>
            <div style={{ display: 'flex' }}>
              <Button
                style={{ marginLeft: 'auto' }}
                color="projx"
                onClick={_buttonOnClick}
              >
                <i className="fas fa-upload" />
                {' New Document'}
              </Button>
            </div>
          </ModalFooter>
        )}
      </Modal>
      <DocumentUpload
        onDocumentUpload={onDocumentUpload}
        isOpen={isAddDocumentModelOpen}
        toggle={toggleAddDocumentModel}
        categoryOptions={categoryOptions}
        loading={documentUploadLoading}
      />
    </>
  );
};

export default DocumentListModal;
