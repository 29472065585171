import React from 'react';

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@david.kucsai/react-pdf-table';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

import _, { get } from 'lodash';
import { currencify } from '../../../../common';

const styles = StyleSheet.create({
  cell: {
    fontSize: 5,
    fontFamily: 'Lato',
    paddingLeft: 2,
  },
  cellTotals: {
    fontSize: 5,
    fontFamily: 'Lato Bold',
    paddingLeft: 2,
  },
  cellSmall: {
    fontSize: 4,
    fontFamily: 'Lato',
    paddingLeft: 2,
  },
  cellTotalsSmall: {
    fontSize: 4,
    fontFamily: 'Lato Bold',
    paddingLeft: 2,
  },
  headerCell: {
    fontSize: 5,
    fontFamily: 'Lato Bold',
    //paddingLeft: 2,
    backgroundColor: '#edfdff',
    padding: 3,
  },
  headerCellSmall: {
    fontSize: 4,
    fontFamily: 'Lato Bold',
    padding: 3,
    //paddingLeft: 2,
    backgroundColor: '#edfdff',
  },
  headerTotal: {
    padding: 0,
  },
  tableTotals: {
    marginTop: 2,
  },
  table: {
    marginTop: 10,
  },
  negative: {
    color: 'red',
    textAlign: 'right',
  },
  positive: {
    textAlign: 'right',
  },
});

const TableTotals = ({ table }) => {
  return (
    <View style={styles.tableTotals}>
      <Table data={table.totals.data}>
        <TableHeader>
          {_.map(table.fields, (field) => (
            <TableCell key={field.key} style={styles.headerTotal} />
          ))}
        </TableHeader>
        <TableBody>
          {_.map(table.totals.columns, (column) => (
            <DataTableCell
              key={column.accessor}
              style={
                table.fields.length > 13
                  ? styles.cellTotalsSmall
                  : styles.cellTotals
              }
              getContent={(r) => {
                let val = get(r, column.accessor);
                if (_.isNumber(val))
                  return val < 0 ? (
                    <Text style={styles.negative}>
                      ({currencify(val, false, 0)})
                    </Text>
                  ) : (
                    <Text style={styles.positive}>
                      {currencify(val, false, 0)}
                    </Text>
                  );
                return val;
              }}
            />
          ))}
        </TableBody>
      </Table>
    </View>
  );
};

export default (props) => {
  const { table } = props;

  return (
    <View style={styles.table}>
      <Table data={table.data}>
        <TableHeader>
          {_.map(table.fields, (field) => (
            <TableCell
              key={field.key}
              style={
                table.fields.length > 13
                  ? styles.headerCellSmall
                  : styles.headerCell
              }
            >
              {field.label}
            </TableCell>
          ))}
        </TableHeader>
        <TableBody>
          {_.map(table.fields, (field) => (
            <DataTableCell
              key={field.key}
              style={table.fields.length > 13 ? styles.cellSmall : styles.cell}
              getContent={(r) => {
                let val = get(r, field.key);
                if (_.isNumber(val))
                  return val < 0 ? (
                    <Text style={styles.negative}>
                      ({currencify(val, false, 0)})
                    </Text>
                  ) : (
                    <Text style={styles.positive}>
                      {currencify(val, false, 0)}
                    </Text>
                  );
                return val;
              }}
            />
          ))}
        </TableBody>
      </Table>
      <TableTotals table={table} />
    </View>
  );
};
