import gql from 'graphql-tag';

/**
 * Queries
 */
const ACTIVITY_STREAM_QUERY = gql`
  query ($projectId: ID!, $cursor: Int!) {
    getActivities(projectId: $projectId, first: 5, after: $cursor) {
      edges {
        node {
          _id
          kind {
            type
          }
          actor {
            _id
            displayName
            avatarUrl
          }
          content
          created
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export { ACTIVITY_STREAM_QUERY };
