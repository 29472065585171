import _ from 'lodash';
import cloneDeep from 'clone-deep';
import omitDeep from 'omit-deep';

const updateArrayByKey = (
  item,
  originalArray,
  identifier = '_id',
  upsert = false,
) => {
  const tempItem = Object.assign([], originalArray);
  const index = _.findIndex(tempItem, { [identifier]: item[identifier] });

  if (index === -1 && upsert) return insertIntoArray(item, originalArray);

  tempItem.splice(index, 1, item);
  return tempItem;
};

let insertIntoArray = (item, originalArray) => {
  const tempItem = Object.assign([], originalArray);
  tempItem.push(item);
  return tempItem;
};

const removeFromArray = (item, originalArray, identifier = '_id') => {
  const tempItem = Object.assign([], originalArray);
  const index = _.findIndex(tempItem, { [identifier]: item[identifier] });
  if (index > -1) tempItem.splice(index, 1);
  return tempItem;
};

const copyArrayReference = (reference) => {
  if (Array.isArray(reference)) return Object.assign([], reference);
  return { ...reference };
};

const translateGqlData = (data, property) => _.get(data, property);

const cleanGqlTypename = (obj) => deepClean(obj, '__typename');

let deepClean = (obj, prop) => omitDeep(cloneDeep(obj), prop);

export {
  updateArrayByKey,
  insertIntoArray,
  copyArrayReference,
  translateGqlData,
  cleanGqlTypename,
  deepClean,
  removeFromArray,
};
