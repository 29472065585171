import styled, { css } from 'styled-components';

export const SidebarItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Inter', sans-serif;
  margin-bottom: 5px;
  color: #F9F9F9;

  ${props => props.break && css`
    margin-top: 25px;
    margin-bottom: 15px;
  `}
`;

export const SidebarText = styled.p`
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  text-align: justify;
  margin-left: 10px;
  letter-spacing: 0px;
`;

export const SidebarCreateProjectButton = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #F9F9F9;
  background-color: #E7224A;
  border: 0;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  padding: 8px 16px;

  &:hover {
    background-color: #F2607E;
  }

  ${(props) => props.toggle === false && css`
    padding: 8px 14px;
    font-size: 20px;
  `}
`;
