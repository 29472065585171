import React, { useState } from 'react';
import DefaultHeaderContent from './content/default';
import SettingsHeaderContent from './content/settings';

const headerContent = {
  default: DefaultHeaderContent,
  settings: SettingsHeaderContent,
};

const Header = ({ content = 'default' }) => {
  const [settings] = useState({
    activeDir: 'ltr',
    activeThemeLayout: 'vertical',
    activeTheme: 'dark',
    activeSidebarType: 'full',
    activeLogoBg: 'skin5',
    activeNavbarBg: 'skin6',
    activeSidebarBg: 'skin6',
    activeSidebarPos: 'fixed',
    activeHeaderPos: 'fixed',
    activeLayout: 'full',
  });

  return (
    <header className="topbar navbarbg" data-navbarbg={settings.activeNavbarBg}>
      {headerContent[content]({ settings })}
    </header>
  );
};

export default Header;
