import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardFooter,
} from 'reactstrap';

export default function ({ title, subtitle, children, controls }) {
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <CardSubtitle>{subtitle}</CardSubtitle>
        </CardBody>
        <CardBody>{children}</CardBody>
        <CardFooter>{controls}</CardFooter>
      </Card>
    </>
  );
}
