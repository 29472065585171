import React from 'react';
//
import { IconButtonStyles, IconButtonText } from './styles/IconButton';

/**
 * This component is the primary IconButton component
 * NOTE: Pass a 25px by 25px React icon component as children
 * @param {React.JSX} children The children of our react component - this should be our icon component
 * @param {Function} onClick The onClick event handler for our button 
 * @param {boolean} disabled Whether our IconButton is disabled or not
 * @param {string} text The text that should be displayed after our icon in our component
 * @returns 
 */
const IconButton = ({ children, onClick, text, disabled }) => {
  return (
    <IconButtonStyles onClick={onClick} disabled={disabled}>
      {children}
      { text && (  
        <IconButtonText>
          {text}
        </IconButtonText>
      )}
    </IconButtonStyles>
  );
}

export default IconButton;
