import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';

import AccountSummary from '../../components/account/AccountSummary';
import AccountEditor from '../../components/account/AccountEditor';
import { UserContext } from '../../contexts';

const Account = () => {
  const { user, loading } = useContext(UserContext);

  return (
    <div>
      <Row className="mb-4">
        <Col xs="10">
          <h4 className="mb-3">
            <i className="fas fa-user mr-2" /> Profile
          </h4>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="4">
          <AccountSummary user={user} loading={loading} />
        </Col>
        <Col xs="12" md="12" lg="8">
          <AccountEditor user={user} loading={loading} />
        </Col>
      </Row>
    </div>
  );
};

export default Account;
