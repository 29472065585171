import React, { useState, useEffect, useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Row, Col, BreadcrumbItem, Breadcrumb } from 'reactstrap';
import html2pdf from 'html2pdf.js'
// Local Imports
import ProjectContext from '../../contexts/projects/projects.context';
import { withProject } from '../../common';
import { Widget, Spinner} from '../../components';
import { widgetTitles, widgetWidths, widgetHeights } from '../../constants';

// GraphQL Queries
const GET_WIDGETS_FINANCIAL_DASHBOARD = gql`
  query getWidgets($projectId: ID!, $dashboard: DashboardType!) {
    getWidgets(projectId: $projectId, dashboard: $dashboard) {
      _id
      type
      data
      description
      dashboard
    }
  }
`;

const FinancialDashboard = ({ project }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const { setProject } = useContext(ProjectContext);

  useEffect(() => {
    setProject(project);
  }, []);

  const { loading: loadingWidgets, error: errorWidgets, data: dataWidgets } = useQuery(GET_WIDGETS_FINANCIAL_DASHBOARD, {
    variables: {
      projectId: project._id,
      dashboard: "FINANCIAL",
    },
    onCompleted: (data) => {
      setWidgets(data.getWidgets);
    }
  });

  // Example taken from https://github.com/eKoopmans/html2pdf.js
  const createPDF = async () => {   
    const element = document.querySelector("#financial-dashboard-pdf");
    const opt = {
      margin: 1,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        windowWidth: 400,
      },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'landscape',
      }
    };
    
    // New Promise-based usage:
    await html2pdf().set(opt).from(element).save();
  };

  if (loadingWidgets) return <Spinner />;

  return (
    <div>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href="">
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href="">
          Financial Dashboard
        </BreadcrumbItem>
      </Breadcrumb>
      
      <Widget.StatusCards projectId={project._id} />
      <Widget.ProjectOverview project={project} />
      <div id="financial-dashboard-pdf">
        <Row>
          {_.map(widgets, (w) => (
            <Col key={`${w.type}`} xl={widgetWidths[w.type]}>
              <Widget
                title={`${widgetTitles[w.type]}`}
                type={`${w.type}`}
                inEditMode={isEditable}
                projectId={project._id}
                height={widgetHeights[w.type]}
                dashboard="FINANCIAL"
                setWidgets={setWidgets}
              />
            </Col>
          ))}
        </Row>
      </div>
      <Row>
        <Widget.AddWidgetButton
          inEditMode={isEditable}
          widgets={widgets}
          setWidgets={setWidgets}
          projectId={project._id}
          dashboard="FINANCIAL"
        />
      </Row>
      <Row>
        <Widget.EditWidgetButton
          inEditMode={isEditable}
          onClick={() => setIsEditable(() => !isEditable)}
        />
      </Row>
      {/* <button onClick={createPDF} type="button">Download</button> */}
    </div>
  );
};

export default withProject(FinancialDashboard);
