import { useMutation } from '@apollo/client';

import {
  INVITE_MEMBERS_TO_ORG_MUTATION,
  INVITE_COLLABORATORS_TO_PROJECT_MUTATION,
  ACCEPT_INVITATION,
} from '../../graphql/queries';

const useInviteMembersToOrg = () => {
  const [_inviteMembersToOrg, { data }] = useMutation(
    INVITE_MEMBERS_TO_ORG_MUTATION,
  );

  const inviteMembersToOrg = (organisationId, invitations) => {
    return _inviteMembersToOrg({
      variables: {
        organisationId,
        invitations,
      },
    });
  };

  return [inviteMembersToOrg, { data }];
};

const useInviteCollaboratorsToProject = () => {
  const [_inviteCollaboratorsToProject, { data }] = useMutation(
    INVITE_COLLABORATORS_TO_PROJECT_MUTATION,
  );

  const inviteCollaboratorsToProject = (
    organisationId,
    projectId,
    invitations,
  ) => {
    return _inviteCollaboratorsToProject({
      variables: {
        organisationId,
        projectId,
        invitations,
      },
    });
  };

  return [inviteCollaboratorsToProject, { data }];
};

const useAcceptInvitation = () => {
  const [_acceptInvitation, { data }] = useMutation(ACCEPT_INVITATION);

  const acceptInvitation = (invitationToken) => {
    return _acceptInvitation({
      variables: {
        invitationToken,
      },
    });
  };

  return [acceptInvitation, { data }];
};

export {
  useInviteMembersToOrg,
  useInviteCollaboratorsToProject,
  useAcceptInvitation,
};
