import { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../contexts/auth/auth.context';

const Logout = () => {
  const { logout } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    logout();
    history.push('/');
  });

  return null;
};

export default Logout;
