import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { gql, useQuery, useMutation } from '@apollo/client';
import 'react-datepicker/dist/react-datepicker.css';
import Switch from '@mui/material/Switch';
//
import { Button, Spinner, TagsInput } from '../../../components';
import {
  InputContainer,
  InputHeader,
  FooterContainer,
  DatePickerStyled,
  TextAreaStyled,
  Divider,
} from './styles/Modal';

// GraphQL Queries
const GET_PROGRESS_DIARY_DOCUMENT = gql`
  query getProgressDiaryDocument($projectId: ID!, $documentId: ID!) {
    getProgressDiaryDocument(projectId: $projectId, documentId: $documentId) {
      _id
      event
      date
      tags
      reporting
    }
  }
`;

// GraphQL Mutations
const UPDATE_PROGRESS_DIARY_DOCUMENT = gql`
  mutation updateProgressDiaryDocument($projectId: ID!, $documentId: ID!, $input: ProgressDiaryDocumentInput!) {
    updateProgressDiaryDocument(projectId: $projectId, documentId: $documentId, input: $input) {
      code
      success
      message
      documents {
        _id
        event
        date
        tags
        reporting
      }
    }
  }
`;

const DELETE_PROGRESS_DIARY_DOCUMENT = gql`
  mutation deleteProgressDiaryDocument($projectId: ID!, $documentId: ID!) {
    deleteProgressDiaryDocument(projectId: $projectId, documentId: $documentId) {
      code
      success
      message
      documents {
        _id
        event
        date
        tags
        reporting
      }
    }
  }
`;

/**
 * This component is the modal for when we want to edit a line item
 * @returns Our Modal for editing a line item
 */
const EditLineItemModal = ({
  showEditProgressDiaryEventModal,
  toggleEditLineItemModal,
  documentId,
  projectId,
  setTableData,
}) => {
  // The states for our progress diary line item input
  const [eventDate, setEventDate] = useState(new Date());
  const [eventText, setEventText] = useState('');
  const [eventTags, setEventTags] = useState([]);
  const [reportingSwitch, setReportingSwitch] = useState(false);
  // For showing/hiding our modal for confirming deletion of an event
  const [showDeleteProgressDiaryEventModal, setShowDeleteProgressDiaryEventModal] =useState(false);

  // GraphQL Queries
  const {
    loading: loadingGetProgressDiaryDocument,
    error: errorGetProgressDiaryDocument,
    data: dataGetProgressDiaryDocument,
  } = useQuery(GET_PROGRESS_DIARY_DOCUMENT, {
    variables: {
      projectId,
      documentId,
    },
    onCompleted: (data) => {
      setEventDate(new Date(data.getProgressDiaryDocument.date));
      setEventText(data.getProgressDiaryDocument.event);
      setEventTags(data.getProgressDiaryDocument.tags);
      setReportingSwitch(data.getProgressDiaryDocument.reporting);
    },
  });

  // GraphQL Mutations
  const [updateProgressDiaryDocument, {
    loading: loadingUpdateProgressDiaryDoc,
    error: errorUpdateProgressDiaryDoc,
    data: dataUpdateProgressDiaryDoc
  }] = useMutation(UPDATE_PROGRESS_DIARY_DOCUMENT, {
    onCompleted: (data) => {
      setTableData(data.updateProgressDiaryDocument.documents);
    }
  });
  
  const [deleteProgressDiaryDocument, {
    loading: loadingDeleteProgressDiaryDoc,
    error: errorDeleteProgressDiaryDoc,
    data: dataDeleteProgressDiaryDoc
  }] = useMutation(DELETE_PROGRESS_DIARY_DOCUMENT, {
    onCompleted: (data) => {
      setTableData(data.deleteProgressDiaryDocument.documents);
    }
  });

  const toggleConfirmDeleteModal = (e) => {
    setShowDeleteProgressDiaryEventModal(() => !showDeleteProgressDiaryEventModal);
  }

  if (loadingGetProgressDiaryDocument) {
    return (
      <Modal isOpen={showEditProgressDiaryEventModal} toggle={toggleEditLineItemModal} backdrop>
        <ModalHeader toggle={toggleEditLineItemModal}>
          <InputHeader>Edit Progress Diary Item</InputHeader>
        </ModalHeader>
        <ModalBody>
          <Spinner />
        </ModalBody>
      </Modal>
    );
  }

  const handleUpdateButtonClick = async (e) => {
    await updateProgressDiaryDocument({
      variables: {
        projectId,
        documentId,
        input: {
          event: eventText,
          date: eventDate.toISOString(),
          tags: eventTags,
          reporting: reportingSwitch,
        }
      }
    });
    toggleEditLineItemModal({});
  }

  const handleConfirmDeleteButtonClick = async (e) => {
    setShowDeleteProgressDiaryEventModal(false);
    await deleteProgressDiaryDocument({
      variables: {
        projectId,
        documentId,
      }
    });
    toggleEditLineItemModal({});
  }

  const updateButtonDisabled = eventText === '' || eventDate === null || eventTags.length === 0;

  return (
    <>
      {/* This is our primary modal for editing a progress diary line item */}
      <Modal isOpen={showEditProgressDiaryEventModal} toggle={toggleEditLineItemModal} backdrop>
        <ModalHeader toggle={toggleEditLineItemModal}>
          <InputHeader>Edit Progress Diary Item</InputHeader>
        </ModalHeader>
        <ModalBody>
          <InputContainer>
            <InputHeader>Select a date (DD-MM-YYYY):</InputHeader>
            <DatePickerStyled
              dateFormat="dd/MM/yyyy"
              selected={eventDate}
              onChange={(date) => setEventDate(date)}
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>Event Details:</InputHeader>
            <TextAreaStyled
              value={eventText}
              onChange={(e) => setEventText(e.target.value)}
              placeholder="Describe your event"
            />
          </InputContainer>
          <InputContainer>
            <InputHeader>Tags (max. 5):</InputHeader>
            <TagsInput tags={eventTags} setTags={setEventTags} max={5} />
          </InputContainer>
          <InputContainer horizontal>
            <InputHeader>Reporting:</InputHeader>
            <Switch
              checked={reportingSwitch}
              onChange={(e) => setReportingSwitch(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </InputContainer>
          <FooterContainer>
            <Button onClick={toggleConfirmDeleteModal} outline>Delete</Button>
            <Divider />
            <Button
            onClick={handleUpdateButtonClick}
            disabled={updateButtonDisabled}
            >
              Update
            </Button>
          </FooterContainer>
        </ModalBody>
      </Modal>
      {/*
        This modal which pops up on top of the primary modal for editing a line item and is for for
        confirming our deletion of the progress diary line item
      */}
      <Modal isOpen={showDeleteProgressDiaryEventModal} toggle={toggleConfirmDeleteModal} backdrop>
        <ModalHeader toggle={toggleConfirmDeleteModal}>
          <InputHeader>Are you sure you want to delete this item?</InputHeader>
        </ModalHeader>
        <ModalBody>
          You will be unable to recover the data once it is deleted, are you sure you want to continue ?
          <FooterContainer>
            <Button onClick={toggleConfirmDeleteModal} outline>Cancel</Button>
            <Divider />
            <Button
              onClick={handleConfirmDeleteButtonClick}
            >
              Confirm
            </Button>
          </FooterContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditLineItemModal;
