import React from 'react';
import {
  DropdownContainer,
  DropdownItem,
  DropdownItemText,
} from './styles/Dropdown';

/**
 * The primary container for our custom dropdown component
 * @param {JSX} children Children of Dropdown
 * @param {number} width The width of the dropdown in pixels
 * @param {boolean} show Whether to show the dropdown or not, typically controlled by a useState hook
 * @param {number} top Number of pixels from the top, as dropdown position is absolute (relative to parent)
 * @param {number} left Number of pixels from the left, as dropdown position is absolute (relative to parent)
 * @returns The container for our dropdown
 */
const Dropdown = ({ children, width, show, top, right, bottom, left, onMouseOver, onMouseLeave }) => {
  return (
    <DropdownContainer
      show={show}
      width={width}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </DropdownContainer>
  );
};

/**
 * The item of each dropdown
 * @param {JSX} children Children of Dropdown.Item, in `Dropdown.ItemText` components
 * @returns Each item for our Dropdown menu
 */
Dropdown.Item = ({ children, onClick, to }) => {
  return <DropdownItem onClick={onClick} to={to}>{children}</DropdownItem>;
};

/**
 * The text inside each `Dropdown.Item` components
 * @param {JSX} children Children of Dropdown.ItemText
 * @returns Text for each of our Dropdown item
 */
Dropdown.ItemText = ({ children }) => {
  return <DropdownItemText>{children}</DropdownItemText>;
};

export default Dropdown;
