import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import _ from 'lodash';
import ReactTable from 'react-table-v6';
import treeTableHOC from 'react-table-v6/lib/hoc/treeTable';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { useReport } from '../../hooks';
import { currencify, withProject } from '../../common';

import { ColumnType } from './data';

import ReportFilter from './ReportFilter';

const TreeTable = treeTableHOC(ReactTable);

const TheadComponent = (props) => null;

const ReportContent = ({ project, reportConfig }) => {
  const [result, setResult] = useState(undefined);
  const [columns, setColumns] = useState(undefined);
  const [tableConfig, setTableConfig] = useState(undefined);

  const [runReport, { loading }] = useReport({
    onCompleted: (data) => {
      setResult(data);
    },
  });

  const onQueryConstructed = async ({ query, columns }) => {
    setColumns(columns);
    setTableConfig(undefined);

    try {
      await runReport({
        projectId: project._id,
        type: reportConfig.type,
        query,
      });
    } catch (e) {}
  };

  const buildTableColumns = ({ query, columns, data, totals }) => {
    let totalsColumns = [];

    const _currencifyCell = ({ value }) => {
      if (value && value > 0)
        return (
          <div style={{ textAlign: 'right' }}>
            {currencify(value, false, query.showDecimals ? 2 : 0)}
          </div>
        );
      if (value && value < 0)
        return (
          <div style={{ textAlign: 'right' }} className="text-danger">
            ({currencify(value, false, query.showDecimals ? 2 : 0)})
          </div>
        );
      return <div style={{ textAlign: 'right' }}>{value}</div>;
    };

    const _badgefy = ({ value }) => {
      switch (value) {
        case 'pending':
          return (
            <div style={{ textAlign: 'center' }}>
              <span className="badge badge-secondary">{value}</span>
            </div>
          );
        case 'approved':
          return (
            <div style={{ textAlign: 'center' }}>
              <span className="badge badge-success">{value}</span>
            </div>
          );
        case 'withdrawn':
          return (
            <div style={{ textAlign: 'center' }}>
              <span className="badge badge-info">{value}</span>
            </div>
          );
        case 'rejected':
          return (
            <div style={{ textAlign: 'center' }}>
              <span className="badge badge-warning">{value}</span>
            </div>
          );
        default:
          return <div style={{ textAlign: 'center' }}>{value}</div>;
      }
    };

    const _renderCell = ({ type, value }) => {
      if (value === undefined || value === null)
        return <div style={{ textAlign: 'center' }} />;

      switch (type) {
        case ColumnType.Text:
          return <div style={{ textAlign: 'left' }}>{value}</div>;
        case ColumnType.Date:
          return (
            <div style={{ textAlign: 'center' }}>
              {moment(value).format('Do MMM YYYY')}
            </div>
          );
        case ColumnType.Numerical:
          return _currencifyCell({ value });
        case ColumnType.Badge:
          return _badgefy({ value });
        default:
          return value;
      }
    };

    const removed = _.remove(Object.assign([], columns), (column) => {
      if (_.some(query.columns, (c) => c.field === column.value)) return true;
      return false;
    });

    let prepared = _.map(removed, (column) => {
      return {
        Header: column.label,
        accessor: column.value,
        Cell: ({ value }) => _renderCell({ type: column.type, value }),
        headerClassName: 'report-header',
      };
    });

    if (query.layout.type === 'groupBy') {
      // totalsColumns = _.map(
      //     _.remove(
      //         Object.assign([], prepared), p => p.accessor !== query.layout.accessor),
      //     p => ({ accessor: p.accessor, Cell: ({ value }) => _renderCell({ type: ColumnType.Numerical, value }) }));

      totalsColumns = _.map(prepared, (p) => ({
        accessor: p.accessor,
        Cell: ({ value }) => _renderCell({ type: ColumnType.Numerical, value }),
      }));

      prepared = [{ accessor: query.layout.accessor }].concat(prepared);
    } else {
      totalsColumns = _.map(prepared, (p) => ({
        accessor: p.accessor,
        Cell: ({ value }) => _renderCell({ type: ColumnType.Numerical, value }),
      }));
    }

    setTableConfig({
      prepared,
      query,
      data,
      expanded:
        query.layout.type === 'groupBy'
          ? _.map(_.groupBy(data, query.layout.accessor), (x) => true)
          : null,
      pivot: query.layout.type === 'groupBy' ? [query.layout.accessor] : [],
      draggableColumns: _.map(prepared, (p) => p.accessor),
      totals: {
        data: query.showBalances && totals ? [totals] : [],
        columns: totalsColumns,
      },
    });
  };

  useEffect(() => {
    if (!loading && result) {
      const { query, data, totals } = result;
      buildTableColumns({ query, data, columns, totals });
    }
  }, [loading, result]);

  useEffect(() => {
    setResult(undefined);
    setColumns(undefined);
    setTableConfig(undefined);
  }, [reportConfig]);

  return (
    <>
      <div>
        <CardHeader>
          <h4>{reportConfig.name}</h4>
        </CardHeader>
        <ReportFilter
          projectId={project._id}
          type={reportConfig.type}
          onQuery={onQueryConstructed}
          artefact={{
            data:
              tableConfig && tableConfig.data.length > 0
                ? tableConfig.data
                : null,
            totals:
              tableConfig && tableConfig.totals
                ? tableConfig.totals
                : undefined,
            fileName: project.name,
            document: {
              template: reportConfig.template,
              fileName: `${project.name}.pdf`,
            },
          }}
        />
      </div>
      {loading ? (
        <Skeleton />
      ) : tableConfig ? (
        <Card className="mt-3">
          <CardBody>
            <img
              src={project.logo}
              alt="project"
              className="rounded-circle d-block float-right"
              width="60"
              height="60"
            />
            <h2 className="float-left">{reportConfig.name}</h2>
          </CardBody>
          <CardBody>
            <h4 className="font-light">
              For the reporting period{' '}
              <b className="font-bold">
                {moment(tableConfig.query.dateRange.from).format('Do MMM YYYY')}
              </b>{' '}
              to{' '}
              <b className="font-bold">
                {moment(tableConfig.query.dateRange.to).format('Do MMM YYYY')}
              </b>
            </h4>
          </CardBody>
          <CardBody className="border-top">
            <TreeTable
              data={tableConfig.data}
              showPagination={false}
              pageSize={tableConfig.data.length}
              expanded={tableConfig.expanded}
              className="-highlight"
              pivotBy={tableConfig.pivot}
              columns={tableConfig.prepared}
            />
            {tableConfig.query.showBalances &&
            tableConfig.data.length > 0 &&
            tableConfig.totals.data.length === 1 ? (
              <div className="mt-2">
                <TreeTable
                  TheadComponent={TheadComponent}
                  data={tableConfig.totals.data}
                  showPagination={false}
                  pageSize={tableConfig.totals.data.length}
                  className="-highlight"
                  columns={tableConfig.totals.columns}
                />
              </div>
            ) : (
              ''
            )}
          </CardBody>
        </Card>
      ) : (
        ''
      )}
    </>
  );
};

export default withProject(ReportContent);
