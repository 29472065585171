import styled from 'styled-components';

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Inter', sans-serif;
  margin: 8px 0 16px 0;
  color: #1B1B1B;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeadingText = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-left: 8px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 8px;
`;

export const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DashboardButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-left: 16px;
  border: none;
  text-decoration: none;
  border-radius: 8px;
  background-color: #F9F9F9;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #D9D9D9;
  }
`;