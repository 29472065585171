import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Table, Button } from 'reactstrap';

import { useCreateProject } from '../../../hooks';

import { ProjectsContext } from '../../../contexts';
import { currencify } from '../../../common';
import AuthContext from '../../../contexts/auth/auth.context';

const ConfirmStep = ({ getStore }) => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useContext(AuthContext);

  const { refetchProjects } = useContext(ProjectsContext);

  const [createProject] = useCreateProject();

  const storeCopy = getStore();
  storeCopy.prepare();

  const [store] = useState(storeCopy);

  useEffect(() => {
    if (isLoading) {
      const storeCopy = { ...store };
      createProject(storeCopy)
        .then((project) => {
          refetchProjects();
          setTimeout(() => {
            history.push(`/projects/${project._id}`);
          }, 500);
        })
        .catch((err) => {
          createProject(storeCopy)
            .then((project) => {
              refetchProjects();
              setTimeout(() => {
                history.push(`/projects/${project._id}`);
              }, 500);
            })
            .catch((err) => {});
        });
    }
  }, [isLoading]);

  const renderSectors = () => {
    return _.map(store.project.sectors, (sector) => sector.value).join(',');
  };

  const handleSave = (e) => {
    setLoading(true);
  };

  return (
    <div className="step step4 mt-5">
      <div className="row justify-content-md-center">
        <div className="col-lg-8">
          <form id="Form" className="form-horizontal">
            <div className="form-group">
              <label className="col-md-12 control-label">
                <div>
                  <h2>
                    You're almost done! Let's review and <code>Confirm</code>
                  </h2>

                  <div className="">
                    <div className="d-flex align-items-center p-4 border-bottom">
                      <div className="mr-3">
                        <img src={store.logo} alt="user" width="50" />
                      </div>
                      <div>
                        <h5 className="message-title mb-0">
                          {store.project.name}
                        </h5>
                        <p className="mb-0">{store.project.address}</p>
                        <p className="mb-0">
                          {store.project.code} {store.project.costCode}
                        </p>
                      </div>
                    </div>
                    <div className="details-table px-4">
                      <Table responsive borderless size="sm" className="mt-4">
                        <tbody>
                          {store.project.sectors.length > 0 ? (
                            <tr className="d-flex">
                              <td className="col-3 font-bold">Sectors</td>
                              <td className="col-9">{renderSectors()}</td>
                            </tr>
                          ) : (
                            ''
                          )}

                          <tr className="d-flex">
                            <td className="col-3 font-bold">Principal</td>
                            <td className="col-9">{store.client.name}</td>
                          </tr>
                          {store.client.contract ? (
                            <tr className="d-flex">
                              <td className="col-3 font-bold">Contract</td>
                              <td className="col-9">{store.client.contract}</td>
                            </tr>
                          ) : (
                            <tr />
                          )}
                          {store.client.abn ? (
                            <tr className="d-flex">
                              <td className="col-3 font-bold">ABN/ACN</td>
                              <td className="col-9">{store.client.abn}</td>
                            </tr>
                          ) : (
                            <tr />
                          )}
                          {store.totalBudget && store.totalBudget > 0 ? (
                            <tr className="d-flex">
                              <td className="col-3 font-bold">
                                Project Estimate
                              </td>
                              <td className="col-9">
                                {currencify(store.totalBudget, true)}
                              </td>
                            </tr>
                          ) : (
                            <tr />
                          )}
                          {store.invitees.length > 0 ? (
                            <tr className="d-flex">
                              <td className="col-3 font-bold">Collaborators</td>
                              <td className="col-9">
                                <Table
                                  responsive
                                  borderless
                                  size="sm"
                                  className="table contact-table no-wrap table-hover v-middle"
                                >
                                  <tbody>
                                    {store.invitees.map((invitee) => {
                                      return (
                                        <tr
                                          className="bg-white"
                                          key={invitee.member.user._id}
                                        >
                                          <td style={{ width: `${25}px` }}>
                                            <img
                                              src={invitee.member.user.image}
                                              className="rounded-circle"
                                              width="25"
                                              alt={invitee.member.user.image}
                                            />
                                          </td>
                                          <td>
                                            <p className="mb-0 text-truncate font-12">
                                              {invitee.member.user.name} (
                                              {invitee.member.user.email})
                                            </p>
                                            <p className="mb-0 text-muted font-10">
                                              {invitee.member.user.title}{' '}
                                              <b className="text-danger">
                                                {invitee.member.isAdmin
                                                  ? '(Viewer)'
                                                  : invitee.member.user._id ===
                                                    id
                                                  ? '(Owner)'
                                                  : ''}
                                              </b>
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          ) : (
                            ''
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>

                  <Button
                    className="btn btn-lg btn-warning text-white float-right"
                    onClick={handleSave}
                  >
                    {isLoading ? 'Saving ...' : 'Create'}
                  </Button>
                </div>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStep;
