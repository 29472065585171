import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useInviteCollaboratorsToProject } from '../../../../hooks';

const UserAdd = ({ organisation, project, collaborators, members, roles }) => {
  const [modal, setModal] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [invitationRole, setInvitationRole] = useState();
  const [inviteCollaboratorsToProject] = useInviteCollaboratorsToProject();

  useEffect(() => {
    if (roles.length)
      setInvitationRole(roles.find((role) => role.name === 'viewer')._id);
  }, [roles]);

  const toggle = () => {
    setModal(!modal);
  };

  const sendInvites = () => {
    const collaboratorInvites = invitations.map(({ value }) => ({
      email: value,
      roleId: invitationRole,
    }));

    inviteCollaboratorsToProject(
      organisation._id,
      project._id,
      collaboratorInvites,
    ).catch((err) => {});
    setModal(!modal);
    setInvitations([]);
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      {props.data.chipLabel || props.data.label}
    </components.MultiValue>
  );

  return (
    <div className="dl">
      <Button color="projx" onClick={toggle} className="btn float-right">
        <i className="fas fa-plus" /> Invite Collaborators
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Invite Collaborators</ModalHeader>
        <ModalBody>
          <div className="mb-2 d-md-flex align-items-center">
            To:
            <div className="ml-auto">
              <Select
                styles={{
                  container: (base) => ({
                    ...base,
                    width: 150,
                  }),
                }}
                closeMenuOnSelect
                isClearable={false}
                on
                onChange={({ value }) => setInvitationRole(value)}
                defaultValue={{
                  value: roles.find((orgRole) => orgRole.name === 'viewer'),
                  label: 'viewer',
                }}
                options={roles.map((orgRole) => ({
                  value: orgRole._id,
                  label: orgRole.name,
                }))}
              />
            </div>
          </div>
          <CreatableSelect
            isMulti
            components={{ MultiValue }}
            styles={{
              noOptionsMessage: (provided) => ({
                ...provided,
                textAlign: 'left',
              }),
            }}
            onChange={(event) => {
              setInvitations(event);
            }}
            options={members
              .filter(
                (member) =>
                  !collaborators.some(
                    (collaborator) => collaborator.user._id === member.user._id,
                  ),
              )
              .map(({ user }) => ({
                label: `${user.name} - ${user.email}`,
                chipLabel: user.name,
                value: user.email,
              }))}
            placeholder="Email address or name"
            isValidNewOption={(inputValue, selectValue, selectOptions) => {
              const normalisedInputValue = inputValue.trim().toLowerCase();

              return (
                !selectOptions.some((selectOption) =>
                  selectOption.label.includes(normalisedInputValue),
                ) &&
                !collaborators.some(
                  (collaborator) =>
                    collaborator.user.email === normalisedInputValue,
                ) &&
                /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(normalisedInputValue)
              );
            }}
            formatCreateLabel={(inputValue) => `Invite ${inputValue}`}
            noOptionsMessage={() =>
              "Looks like this person isn't in the org. Add their email address to invite them."
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button color="projx" onClick={sendInvites}>
            Invite
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserAdd;
