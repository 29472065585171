import React from 'react';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { withProject } from '../../../common';
import ProfileEdit from '../../../components/project/settings/profile/ProfileEdit';

const Profile = ({ project }) => {
  return (
    <>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/portfolio">
          Portfolio
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href={'/projects/' + project._id}>
          {project.name}
        </BreadcrumbItem>
        <BreadcrumbItem tag="a">Profile</BreadcrumbItem>
      </Breadcrumb>
      <Row>
        <Col xs="12">
          <ProfileEdit project={project} />
        </Col>
      </Row>
    </>
  );
};

export default withProject(Profile);
