import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import { useAlert } from 'react-alert';
import {
  SwitchWrapper,
  DisplayWrapper,
  DisplayType,
  BudgetContractItemPanel,
  BudetContractItemSidePanel,
  disableForecast,
  BudgetMenus,
  BudgetType,
} from '..';
import {
  copyArrayReference,
  currencify,
  evaluateLineStatus,
  extractGraphqlError,
} from '../../../../../common';

import { Panel } from '../../../..';

import {
  useSaveBudgetLine,
  usePanel,
  useDeleteBudget,
} from '../../../../../hooks';


import { UserContext, BudgetConfigContext } from '../../../../../contexts';
import AdditionalDetailsForm from '../AdditionalDetailsForm';

const BudgetContractItem = ({
  view,
  details,
  item,
  onCalculationCompleted = () => {},
  onDelete = () => {},
}) => {
  const alert = useAlert();

  const { setContent } = usePanel();

  const { user } = useContext(UserContext);

  const [entry, setEntry] = useState();
  const [currentStage, setStage] = useState();

  const [rowOpen, setRowOpen] = useState(false);

  const { config } = useContext(BudgetConfigContext);

  const [lineStatus, setLineStatus] = useState('loaded');

  const [saveBudgetLine, { loading }] = useSaveBudgetLine({
    onCompleted: (data) => {
      setLineStatus('saved');
      const calculatedItem = calculate(data);
      setEntry(calculatedItem);
      onCalculationCompleted(calculatedItem);
    },
  });

  const [deleteBudget] = useDeleteBudget({
    onCompleted: (data) => {
      if (data) onDelete(entry._id);
    },
  });

  const toggleRow = (e) => setRowOpen(!rowOpen);

  const onClick = async (type, e) => {
    switch (type) {
      case 'chat':
        updatePanel(entry);
        break;
      case 'upload':
        updatePanel(entry);
        break;
      case 'expand':
        toggleRow();
        break;
      case 'delete':
        try {
          await deleteBudget(entry._id);
        } catch (e) {
          alert.error(extractGraphqlError(e));
        }
        break;
      default:
        break;
    }
  };

  const updatePanel = (updatedEntry) => {
    setContent(
      <Panel title="Budget Contracts" subtitle={updatedEntry.phase}>
        <BudetContractItemSidePanel item={updatedEntry} />
      </Panel>,
    );
  };

  const calculate = (e) => {
    if (_.isNumber(e.committed.contracted)) e.fcc = e.committed.contracted;
    else if (_.isNumber(e.forecast.recommended)) e.fcc = e.forecast.recommended;
    else if (_.isNumber(e.forecast.submitted)) e.fcc = e.forecast.submitted;
    else if (_.isNumber(e.forecast.anticipated)) e.fcc = e.forecast.anticipated;
    else e.fcc = 0;

    e.expenditure.allowed = e.committed.contracted;
    e.expenditure.thisClaim =
      (e.expenditure.current ? e.expenditure.current.amount : 0) -
      (e.expenditure.previous.length > 0
        ? _.sumBy(e.expenditure.previous, (ev) => ev.amount)
        : 0);
    e.expenditure.remaining =
      e.expenditure.allowed -
      (e.expenditure.current ? e.expenditure.current.amount : 0);

    /**
     * Zero out all disabled fields
     */

    setStage(stage(e));

    return e;
  };

  const onInputChangeEvent = (e) => {
    let copyEntry = copyArrayReference(entry);
    _.set(copyEntry, e.cellKey, e.value);

    if (e.type === DisplayType.Number || e.type === DisplayType.Currency) {
      copyEntry = calculate(copyEntry);
      onCalculationCompleted(copyEntry);
    }

    copyEntry.history = e.history ? e.history : copyEntry.history;

    setEntry(copyEntry);
    setLineStatus(e.error ? 'error' : e.autoSave ? 'saved' : 'dirty');
  };

  const saveContract = async (item) => {
    try {
      await saveBudgetLine(item);
      setRowOpen(false);
    } catch (e) {
      setLineStatus('error');
    }
  };

  const save = () => saveContract(entry);

  const validateExpenditureAssessed = (val) => {
    if (val > entry.expenditure.allowed)
      return {
        valid: false,
        message: `Claim amount exceeds the allowed to pay amount of ${currencify(
          entry.expenditure.allowed,
          true,
        )}`,
      };

    return {
      valid: true,
    };
  };

  useEffect(() => {
    if (item.isNew) saveContract(item);
    else {
      const calculatedItem = calculate(item);
      setEntry(calculatedItem);
      onCalculationCompleted(calculatedItem);
      setLineStatus('loaded');
    }
  }, [item]);

  let stage = (e) => {
    if (_.isNumber(e.committed.contracted))
      return { name: 'contracted', color: 'success' };
    if (_.isNumber(e.forecast.recommended))
      return { name: 'assessed', color: 'primary' };
    if (_.isNumber(e.forecast.submitted))
      return { name: 'submitted', color: 'warning' };
    if (_.isNumber(e.forecast.anticipated))
      return { name: 'anticipated', color: 'danger' };
    return null;
  };

  return entry ? (
    <>
      <tr disabled={loading} className="popup-menu-trigger">
        <th className={evaluateLineStatus(lineStatus)} />
        <td className="buttons-menu">
          <div className="popup-menu mt-1 ml-2">
            <BudgetMenus
              onClick={onClick}
              item={entry}
              type={BudgetType.Contract}
            />
          </div>
        </td>
        <td className="created-by">
          <div className="mt-1">
            <img
              src={entry.modifiedBy ? entry.modifiedBy.image : user.image}
              alt="user"
              className="rounded-circle"
              width="20"
            />
          </div>
        </td>
        <td className="reference">
          <SwitchWrapper
            disabled={details.locked}
            onInputChange={onInputChangeEvent}
            width="150px"
            cellKey="ref"
            value={entry.ref}
            type={DisplayType.Text}
            budget={entry}
            autoSave
            maxLength={10}
            placeholder="[REF]"
          />
        </td>
        <td className="text">
          <SwitchWrapper
            disabled={details.locked}
            onInputChange={onInputChangeEvent}
            width="150px"
            cellKey="phase"
            value={entry.phase}
            type={DisplayType.Text}
            budget={entry}
            autoSave
            maxLength={100}
            placeholder="[ACTIVITY]"
          />
        </td>
        <td className="stage align-middle">
          {!currentStage ? (
            <div />
          ) : (
            <span className={`ml-3 badge badge-${currentStage.color}`}>
              {currentStage.name}
            </span>
          )}
        </td>
        {view === 'simple' && (
          <td className="currency ffc-display">
            <DisplayWrapper
              onInputChange={onInputChangeEvent}
              cellKey="fcc"
              value={entry.fcc}
              type={DisplayType.Currency}
              precision={config.decimal ? 2 : 0}
            />
          </td>
        )}
        {view === 'detailed' && (
          <>
            <td
              className={
                details.locked
                  ? 'currency forecast-light greyout'
                  : 'currency forecast-light'
              }
            >
              <SwitchWrapper
                disabled={
                  details.locked || disableForecast(entry, 'anticipated')
                }
                onInputChange={onInputChangeEvent}
                cellKey="forecast.anticipated"
                value={entry.forecast.anticipated}
                type={DisplayType.Currency}
                budget={entry}
                autoSave
                precision={config.decimal ? 2 : 0}
                allowNegative
              />
            </td>
            <td
              className={
                details.locked
                  ? 'currency forecast-light greyout'
                  : 'currency forecast-light'
              }
            >
              <SwitchWrapper
                disabled={details.locked || disableForecast(entry, 'submitted')}
                onInputChange={onInputChangeEvent}
                cellKey="forecast.submitted"
                value={entry.forecast.submitted}
                type={DisplayType.Currency}
                budget={entry}
                autoSave
                precision={config.decimal ? 2 : 0}
                allowNegative
              />
            </td>
            <td
              className={
                details.locked
                  ? 'currency forecast-light greyout'
                  : 'currency forecast-light'
              }
            >
              <SwitchWrapper
                disabled={
                  details.locked || disableForecast(entry, 'recommended')
                }
                onInputChange={onInputChangeEvent}
                cellKey="forecast.recommended"
                value={entry.forecast.recommended}
                type={DisplayType.Currency}
                budget={entry}
                autoSave
                precision={config.decimal ? 2 : 0}
                allowNegative
              />
            </td>
            <td className="currency">
              <SwitchWrapper
                disabled={details.locked}
                onInputChange={onInputChangeEvent}
                cellKey="committed.contracted"
                value={entry.committed.contracted}
                type={DisplayType.Currency}
                budget={entry}
                autoSave
                precision={config.decimal ? 2 : 0}
                allowNegative
              />
            </td>
            <td
              className={details.locked ? 'currency locked' : 'currency'}
              style={{ cursor: 'not-allowed' }}
            >
              <DisplayWrapper
                cellKey="committed.empty1"
                value=""
                type={DisplayType.Empty}
              />
            </td>
            <td
              className={details.locked ? 'currency locked' : 'currency'}
              style={{ cursor: 'not-allowed' }}
            >
              <DisplayWrapper
                cellKey="committed.empty2"
                value=""
                type={DisplayType.Empty}
              />
            </td>
            <td className="currency ffc-display">
              <DisplayWrapper
                onInputChange={onInputChangeEvent}
                cellKey="fcc"
                value={entry.fcc}
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
            <td className="currency expenditure">
              <DisplayWrapper
                cellKey="expenditure.current.amount"
                value={
                  entry.expenditure.current
                    ? entry.expenditure.current.amount
                    : 0
                }
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
          </>
        )}
        {view === 'claims' && (
          <>
            <td className="currency expenditure" >
              <DisplayWrapper
                cellKey="expenditure.allowed"
                value={entry.expenditure.allowed}
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
            <td className="currency expenditure">
              <DisplayWrapper
                cellKey="expenditure.previous"
                value={
                  entry.expenditure.previous.length > 0
                    ? _.sumBy(entry.expenditure.previous, (e) => e.amount)
                    : 0
                }
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
            <td className="currency" >
              {details.accountType === 'bills' ? (
                <SwitchWrapper
                  disabled={
                    entry.expenditure.allowed == null ||
                    entry.expenditure.allowed === undefined ||
                    entry.expenditure.allowed === 0
                  }
                  onInputChange={onInputChangeEvent}
                  cellKey="expenditure.current.amount"
                  value={
                    entry.expenditure.current
                      ? entry.expenditure.current.amount
                      : 0
                  }
                  type={DisplayType.Currency}
                  budget={entry}
                  allowNegative
                  autoSave
                  precision={config.decimal ? 2 : 0}
                  validation={validateExpenditureAssessed}
                />
              ) : (
                <SwitchWrapper
                  disabled={
                    !details.locked ||
                    entry.expenditure.allowed == null ||
                    entry.expenditure.allowed === undefined ||
                    entry.expenditure.allowed === 0
                  }
                  onInputChange={onInputChangeEvent}
                  cellKey="expenditure.current.amount"
                  value={
                    entry.expenditure.current
                      ? entry.expenditure.current.amount
                      : ''
                  }
                  type={DisplayType.Currency}
                  budget={entry}
                  autoSave
                  allowNegative
                  precision={config.decimal ? 2 : 0}
                  validation={validateExpenditureAssessed}
                />
              )}
            </td>
            <td className="currency expenditure">
              <DisplayWrapper
                cellKey="expenditure.thisClaim"
                value={entry.expenditure.thisClaim}
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
            <td className="currency expenditure-header">
              <DisplayWrapper
                cellKey="expenditure.remaining"
                value={entry.expenditure.remaining}
                type={DisplayType.Currency}
                precision={config.decimal ? 2 : 0}
              />
            </td>
          </>
        )}
      </tr>
      <AdditionalDetailsForm onSave={save} toggle={toggleRow} open={rowOpen} item={entry} loading={loading} details={details}>
        <BudgetContractItemPanel
          onInputChange={onInputChangeEvent}
          item={entry}
        />
      </AdditionalDetailsForm>
    </>
  ) : (
    <tr>
      <th colSpan="12" />
    </tr>
  );
};

export default BudgetContractItem;
