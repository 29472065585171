import React, { useEffect, useState } from 'react';
import { Input, Card, CardBody } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { useGetMessages, useSendMessage } from '../../hooks';
import { insertIntoArray } from '../../common';

const Chat = ({ id }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const { getMessages } = useGetMessages({
    budgetId: id,
    onCompleted: (data) => {
      setMessages(data);
    },
  });

  const [sendMessage] = useSendMessage({
    onCompleted: (data) => {
      setMessages(insertIntoArray(data, messages));
      setMessage('');
    },
  });

  const handleChatMsgChange = (e) => {
    setMessage(e.target.value);
  };

  const onChatMsgSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await sendMessage(id, message);
    } catch (e) {}
  };

  const updateScrollbar = () => {
    const ctn = document.getElementById('chat-list');
    setTimeout(() => {
      ctn.scrollTo(0, ctn.scrollHeight);
    }, 300);
  };

  useEffect(() => {
    getMessages();
  }, []);

  useEffect(() => {
    updateScrollbar();
  }, [messages]);

  return (
    <>
      <ul id="chat-list" className="chat-list p-4" style={{ height: '350px' }}>
        <PerfectScrollbar>
          {messages.map((message) =>
            !message.me ? (
              <li className="chat-item d-flex" key={message._id}>
                <div className="chat-img">
                  <img src={message.sender.image} alt={message.sender.name} />
                </div>
                <div className="pl-3">
                  <div className="chat-content">
                    <div className="box bg-light-info">
                      <div className="mb-1">
                        <b>{message.sender.name}</b>
                      </div>
                      <div className="font-12">{message.message}</div>
                      <div className="float-right font-10">
                        {moment(message.timestamp).fromNow(true)} ago
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ) : (
              <li className="chat-item odd">
                <div className="chat-content">
                  <div className="box bg-light-info">
                    <div className="font-12">{message.message}</div>
                    <div className="float-right font-10">
                      {moment(message.timestamp).fromNow(true)} ago
                    </div>
                  </div>
                </div>
              </li>
            ),
          )}
        </PerfectScrollbar>
      </ul>

      <Card>
        <CardBody className="border-top">
          <div className="d-flex">
            <Input
              type="text"
              className="form-control mr-2"
              placeholder="Type your message"
              onChange={handleChatMsgChange}
              value={message}
              required
            />
            <button
              type="submit"
              className="btn btn-outline-secondary"
              disabled={!message}
              onClick={onChatMsgSubmit}
            >
              <i className="fas fa-paper-plane" />
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Chat;
