import React from 'react';
import { components } from 'react-select';

const buildingOptions = [
  {
    value: 'Arts, Tourism, Culture & Entertainment',
    label: 'Arts, Tourism, Culture & Entertainment',
  },
  {
    value: 'Accommodation (Long-term & Transient)',
    label: 'Accommodation (Long-term & Transient)',
  },
  { value: 'Aviation', label: 'Aviation' },
  { value: 'Carpark', label: 'Carpark' },
  { value: 'Commercial / Offices', label: 'Commercial / Offices' },
  { value: 'Defence', label: 'Defence' },
  {
    value: 'Residential (Appartments & Domestic)',
    label: 'Residential (Appartments & Domestic)',
  },
  {
    value: 'Education (Schools & Universities)',
    label: 'Education (Schools & Universities)',
  },
  { value: 'Health & Aged Care', label: 'Health & Aged Care' },
  { value: 'Heritage & Regeneration', label: 'Heritage & Regeneration' },

  { value: 'Hotels & Accommodation', label: 'Hotels & Accommodation' },
  {
    value: 'Public (Government & Coucil)',
    label: 'Public (Government & Coucil)',
  },
  { value: 'Justice', label: 'Justice' },
  {
    value: 'Retail, Shopping Centres & Restaurants',
    label: 'Retail, Shopping Centres & Restaurants',
  },
  {
    value: 'Research - Pharmaceutical, Laboratory',
    label: 'Research - Pharmaceutical, Laboratory',
  },
  { value: 'Social Housing', label: 'Social Housing' },
  { value: 'Sports, Civic & Community', label: 'Sports, Civic & Community' },
  {
    value: 'Industrial - Warehouses, Factories & Storage',
    label: 'Industrial - Warehouses, Factories & Storage',
  },
  { value: 'Greenfield (New Assets)', label: 'Greenfield (New Assets)' },
  {
    value: 'Brownfield (Existing Assets)',
    label: 'Brownfield (Existing Assets)',
  },
  { value: 'Other Building', label: '' },
];

const infrastructureOptions = [
  { value: 'Energy & Environmental', label: 'Energy & Environmental' },
  {
    value: 'Parks, Tourism & Recreation',
    label: 'Parks, Tourism & Recreation',
  },
  {
    value: 'Utilities - Power, Gas, Water, Sewer, Comms',
    label: 'Utilities - Power, Gas, Water, Sewer, Comms',
  },
  { value: 'Mining & Resources', label: 'Mining & Resources' },
  { value: 'Maritime', label: 'Maritime' },
  { value: 'Transport - Roads & Rail', label: 'Transport - Roads & Rail' },
  { value: 'Other Infrastructure', label: 'Other Infrastructure' },
];

const groupedOptions = [
  {
    label: 'BUILDING',
    options: buildingOptions,
  },
  {
    label: 'INFRASTRUCTURE',
    options: infrastructureOptions,
  },
];

const constructionTypes = [
  {
    value: 'greenfield',
    label: 'Greenfield (New Asset)',
  },
  {
    value: 'brownfield',
    label: 'Brownfield (Existing Asset)',
  },
];

const groupStyles = {
  borderRadius: '5px',
  background: '#f2fcff',
};

const IndicatorsContainer = (props) => {
  return (
    <div>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

export {
  buildingOptions,
  infrastructureOptions,
  groupedOptions,
  groupStyles,
  constructionTypes,
  IndicatorsContainer,
};
