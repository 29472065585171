// Commented out all functions related to leaving an org

import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
} from 'reactstrap';
// import _ from 'lodash';
// import { useAlert } from 'react-alert';
// import { useHistory } from 'react-router-dom';
import { useSaveOrg } from '../../../hooks'; // useLeaveOrg
import { OrganisationContext } from '../../../contexts';

const OrgProfile = () => {
  //   const alert = useAlert();
  //   const history = useHistory();
  const { currentOrganisation, setCurrentOrganisation } =
    useContext(OrganisationContext);

  const [saveOrg, { loading, organisation }] = useSaveOrg();
  //   const [
  //     leaveOrg,
  //     { loading: leaveLoading, data, error: leaveError },
  //   ] = useLeaveOrg();

  const [name, setName] = useState('');

  const save = () => {
    saveOrg({
      key: 'name',
      value: name,
    });
  };

  //   const leave = async () => {
  //     try {
  //       await leaveOrg();
  //       history.push('/');
  //     } catch (e) {
  //       if (e.graphQLErrors && e.graphQLErrors.length > 0)
  //         alert.error(e.graphQLErrors[0].message);
  //     }
  //   };

  useEffect(() => {
    setName(currentOrganisation.name);
  }, []);

  useEffect(() => {
    if (!loading && organisation) {
      setCurrentOrganisation(organisation);
    }
  }, [loading]);

  return (
    <Card>
      <CardHeader>
        <h3>Org Profile</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="9">
            <Label className="font-14">Org Name</Label>
            <Input
              bsSize="lg"
              style={{ width: '300px' }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Button
              disabled={loading}
              className="mt-3"
              color="projx"
              onClick={save}
            >
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </Col>
        </Row>
      </CardBody>
      {/* <CardBody className="border-top pt-4">
        <h3>Leave or delete {currentOrganisation.name} organisation</h3>
        <Label className="font-14">
          By leaving the organisation, you will lose access to all projects.
        </Label>
        <Label className="font-14">
          Deleting the organisation will irreversibly remove all projects, users
          and reference data.
        </Label>
        <div className="mt-4">
          <Button
            size="lg"
            className="float-left"
            outline
            color="info"
            onClick={leave}
          >
            Leave Organisation
          </Button>
          <Button
            size="lg"
            className="float-left ml-2"
            outline
            color="danger"
            disabled
          >
            Delete Organisation
          </Button>
        </div>
      </CardBody> */}
    </Card>
  );
};

export default OrgProfile;
