import React from 'react';
import { Router, Route } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes, faMinus } from '@fortawesome/free-solid-svg-icons';

import AuthProvider from './contexts/auth/auth.provider';
import InvitationProvider from './contexts/invitation/invitation.provider';

import ApolloWrapper from './graphql/apollo/apolloWrapper';
import { AuthenticatedRoutes, UnauthenticatedRoutes } from './routes';

import browserHistory from './browserHistory';

// Global Styles
import GlobalStyles from './GlobalStyles';

library.add(faCheck, faTimes, faMinus);

const BelvedereApp = () => (
  <>
    <GlobalStyles />
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
    <AuthProvider>
      <Router basename="/" history={browserHistory}>
        <InvitationProvider>
          <ApolloWrapper>
            <Route component={AuthenticatedRoutes} />
            <Route component={UnauthenticatedRoutes} />
          </ApolloWrapper>
        </InvitationProvider>
      </Router>
    </AuthProvider>
  </>
);

export default BelvedereApp;
