import React from 'react';
import moment from 'moment';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { ReportType, ColumnType } from './data';
import { currencify } from '../../common';

// These colors are 'red' and 'black' from the brand guidelines UI/UX PDF document
const negativeColor = '#E7224A';
const nonNegativeColor = '#1B1B1B';

const calculateCurrencyString = (num) => {
  return num < 0 ? ('(' + currencify(num, false, 0) + ')') : currencify(num, false, 0);
}

// These default 'queries' are used in the 'reportQuery' rootfield query for GraphQL and they are used
// ONLY for generating PDFs. This default query only pulls in the start and end date between which we want
// data for as well as the cost codes for which we want data for. For Exporting to PDF's we use all the columns
// for the particular report as this makes formatting the PDF easier, which would otherwise be a headache with
// dynamic columns, for which we would need to create 2^n stylesheets styles for all permutations of n number of
// columns.
const defaultReportQuery = ({
  type,
  dateFrom,
  dateTo,
  costCodeValues,
  vendor,
}) => {
  switch (type) {
    case ReportType.Invoicing:
      return {
        dateRange: {
          field: 'invoices.date',
          from: dateFrom,
          to: dateTo,
        },
        comparison: {
          withPeriods: false,
          period: 1,
          values: [],
        },
        queryBy: {
          accessor: 'costCode.code',
          // All the cost codes go here
          values: costCodeValues,
        },
        queryByVendor: vendor,
        filterBy: [],
        layout: {
          type: 'view',
          accessor: '',
        },
        columns: [
          {
            field: 'invoices.date',
            type: ColumnType.Date,
          },
          {
            field: 'invoices.invoiceId',
            type: ColumnType.Text,
          },
          {
            field: 'vendor.name',
            type: ColumnType.Text,
          },
          {
            field: 'costCode.code',
            type: ColumnType.Text,
          },
          {
            field: 'costCode.name',
            type: ColumnType.Text,
          },
          {
            field: 'invoices.claimedAmount',
            type: ColumnType.Numerical,
          },
          {
            field: 'invoices.comments',
            type: ColumnType.Text,
          },
        ],
        showDecimals: true,
        showBalances: true,
      };
      break;

    case ReportType.FfcBreakdown:
      return {
        dateRange: {
          field: 'timestamp',
          from: dateFrom,
          to: dateTo,
        },
        comparison: {
          withPeriods: false,
          period: 1,
          values: [],
        },
        queryBy: {
          accessor: 'costCode',
          values: costCodeValues,
        },
        queryByVendor: vendor,
        filterBy: [],
        layout: {
          type: 'view',
          accessor: '',
        },
        columns: [
          {
            field: 'ref',
            type: ColumnType.Text,
          },
          {
            field: 'history.modifiedBy.name',
            type: ColumnType.Text,
          },
          {
            field: 'history.modifiedOn',
            type: ColumnType.Date,
          },
          {
            field: 'costCode',
            type: ColumnType.Text,
          },
          {
            field: 'type',
            type: ColumnType.Text,
          },
          {
            field: 'vendor.name',
            type: ColumnType.Text,
          },
          {
            field: 'phase',
            type: ColumnType.Text,
          },
          {
            field: 'history.stage',
            type: ColumnType.Text,
          },
          {
            field: 'history.ffc',
            type: ColumnType.Numerical,
          },
        ],
        showDecimals: true,
        showBalances: true,
      };
      break;

    case ReportType.BudgetTransfers:
      return {
        dateRange: {
          field: 'modifiedDate',
          from: dateFrom,
          to: dateTo,
        },
        comparison: {
          withPeriods: false,
          period: 1,
          values: [],
        },
        queryBy: {
          accessor: 'source.costCode.code',
          values: costCodeValues,
        },
        queryByVendor: vendor,
        filterBy: [],
        layout: {
          type: 'view',
          accessor: '',
        },
        columns: [
          {
            field: 'createdBy.name',
            type: ColumnType.Text,
          },
          {
            field: 'modifiedBy.name',
            type: ColumnType.Text,
          },
          {
            field: 'source.costCode.code',
            type: ColumnType.Text,
          },
          {
            field: 'source.costCode.name',
            type: ColumnType.Text,
          },
          {
            field: 'destination.costCode.code',
            type: ColumnType.Text,
          },
          {
            field: 'destination.costCode.name',
            type: ColumnType.Text,
          },
          {
            field: 'amount',
            type: ColumnType.Numerical,
          },
          {
            field: 'modifiedDate',
            type: ColumnType.Date,
          },
          {
            field: 'timestamp',
            type: ColumnType.Date,
          },
          {
            field: 'state',
            type: ColumnType.Badge,
          },
        ],
        showDecimals: true,
        showBalances: true,
      };
      break;

    case ReportType.CostSummary:
      return {
        dateRange: {
          field: '',
          from: dateFrom,
          to: dateTo,
        },
        comparison: {
          withPeriods: false,
          period: 1,
          values: [],
        },
        queryBy: {
          accessor: '',
          values: [],
        },
        queryByVendor: '',
        filterBy: [],
        layout: {
          type: 'view',
          accessor: '',
        },
        columns: [],
        showDecimals: true,
        showBalances: true,
      };
      break;

    default:
      return {
        dateRange: {
          field: '',
          from: dateFrom,
          to: dateTo,
        },
        comparison: {
          withPeriods: false,
          period: 1,
          values: [],
        },
        queryBy: {
          accessor: '',
          values: costCodeValues,
        },
        queryByVendor: vendor,
        filterBy: [],
        layout: {
          type: 'view',
          accessor: '',
        },
        columns: [],
        showDecimals: true,
        showBalances: true,
      };
  }
};

const defaultReportStyleSheets = (type) => {
  switch (type) {
    case ReportType.Invoicing:
      const invoicingStyles = StyleSheet.create({
        // Page styles
        page: {
          fontSize: 9,
          flexDirection: 'column',
          padding: 40,
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 8,
          bottom: 40,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
        },
        // Header Styles
        headerContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        headingBlock: {
          flexDirection: 'column',
        },
        mainHeading: {
          fontSize: 24,
          marginBottom: 16,
        },
        headerText: {
          fontSize: 16,
          marginBottom: 4,
        },
        // Text styles
        bold: {
          fontWeight: 'bold',
        },
        number: {
          textAlign: 'center',
        },
        // Table styles
        tableContainer: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginTop: 30,
        },
        tableRow: {
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 3,
          borderBottom: '1px solid #D9D9D9',
        },
        tableColumnHeading: {},
        // Row widths
        column1: {
          width: '16.67%',
        },
        column2: {
          width: '16.67%',
        },
        column3: {
          width: '20.67%',
        },
        column4: {
          width: '8.67%',
        },
        column5: {
          width: '20.67%',
        },
        column6: {
          width: '16.67%',
        },
      });

      return invoicingStyles;
      break;
    case ReportType.FfcBreakdown:
      const ffcBreakdownStyles = StyleSheet.create({
        // Page styles
        page: {
          fontSize: 9,
          flexDirection: 'column',
          padding: 40,
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 8,
          bottom: 40,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
        },
        // Header Styles
        headerContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        headingBlock: {
          flexDirection: 'column',
        },
        mainHeading: {
          fontSize: 24,
          marginBottom: 16,
        },
        headerText: {
          fontSize: 16,
          marginBottom: 4,
        },
        // Text styles
        bold: {
          fontWeight: 'bold',
        },
        number: {
          textAlign: 'center',
        },
        // Table styles
        tableContainer: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginTop: 30,
        },
        tableRow: {
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 3,
          borderBottom: '1px solid #D9D9D9',
        },
        tableColumnHeading: {},
        // Row widths
        column1: {
          width: '16.67%',
        },
        column2: {
          width: '16.67%',
        },
        column3: {
          width: '16.67%',
        },
        column4: {
          width: '16.67%',
        },
        column5: {
          width: '16.67%',
        },
        column6: {
          width: '16.67%',
        },
      });

      return ffcBreakdownStyles;
      break;
    case ReportType.BudgetTransfers:
      const budgetTransferStyles = StyleSheet.create({
        // Page styles
        page: {
          fontSize: 9,
          flexDirection: 'column',
          padding: 40,
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 8,
          bottom: 40,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
        },
        // Header Styles
        headerContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        headingBlock: {
          flexDirection: 'column',
        },
        mainHeading: {
          fontSize: 24,
          marginBottom: 16,
        },
        headerText: {
          fontSize: 16,
          marginBottom: 4,
        },
        // Text styles
        bold: {
          fontWeight: 'bold',
        },
        number: {
          textAlign: 'center',
        },
        // Table styles
        tableContainer: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginTop: 30,
        },
        tableRow: {
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 3,
          borderBottom: '1px solid #D9D9D9',
        },
        tableColumnHeading: {},
        // Row widths
        column1: {
          width: '22%',
        },
        column2: {
          width: '22%',
        },
        column3: {
          width: '16%',
        },
        column4: {
          width: '20%',
        },
        column5: {
          width: '20%',
        },
      });

      return budgetTransferStyles;
      break;
    case ReportType.CostSummary:
      const costSummaryStyles = StyleSheet.create({
        // Page styles
        page: {
          fontSize: 9,
          flexDirection: 'column',
          padding: 40,
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 8,
          bottom: 40,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
        },
        // Header Styles
        headerContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        headingBlock: {
          flexDirection: 'column',
        },
        mainHeading: {
          fontSize: 24,
          marginBottom: 16,
        },
        headerText: {
          fontSize: 16,
          marginBottom: 4,
        },
        // Text styles
        bold: {
          fontWeight: 'bold',
        },
        number: {
          textAlign: 'center',
        },
        // Table styles
        tableContainer: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginTop: 30,
        },
        tableRow: {
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 3,
          borderBottom: '1px solid #D9D9D9',
        },
        tableColumnHeading: {},
        // Row widths
        column1: {
          width: '20%',
        },
        column2: {
          width: '10%',
        },
        column3: {
          width: '10%',
        },
        column4: {
          width: '10%',
        },
        column5: {
          width: '10%',
        },
        column6: {
          width: '10%',
        },
        column7: {
          width: '10%',
        },
        column8: {
          width: '10%',
        },
        column9: {
          width: '10%',
        },
      });

      return costSummaryStyles;
      break;
    default:
      return StyleSheet.create({});
  }
};

const defaultPdfDocument = (type, styles, defaultQueryData) => {
  switch (type) {
    case ReportType.Invoicing:
      return (
        <Document>
          <Page orientation="landscape" style={styles.page}>
            <View style={styles.headerContainer}>
              <View style={styles.headingBlock}>
                <Text style={styles.mainHeading}>Vendor Invoices</Text>
                <Text style={styles.headerText}>Projx</Text>
                {defaultQueryData && defaultQueryData.data && (
                  <Text style={styles.headerText}>
                    From{' '}
                    <Text style={styles.bold}>
                      {moment(defaultQueryData.query.dateRange.from).format(
                        'Do MMM YYYY',
                      )}
                    </Text>{' '}
                    to{' '}
                    <Text style={styles.bold}>
                      {moment(defaultQueryData.query.dateRange.to).format(
                        'Do MMM YYYY',
                      )}
                    </Text>
                  </Text>
                )}
              </View>
              {/* <Image style={styles.logo} source={"TODO"} /> */}
            </View>
            <View style={styles.tableContainer}>
              <View style={styles.tableRow}>
                <Text style={[styles.column1, styles.tableColumnHeading]}>
                  Invoice Date
                </Text>
                <Text style={[styles.column2, styles.tableColumnHeading]}>
                  Invoice #
                </Text>
                <Text style={[styles.column3, styles.tableColumnHeading]}>
                  Vendor
                </Text>
                <Text style={[styles.column4, styles.tableColumnHeading]}>
                  Cost Code
                </Text>
                <Text style={[styles.column5, styles.tableColumnHeading]}>
                  Cost Code Name
                </Text>
                <Text style={[styles.column6, styles.tableColumnHeading]}>
                  Claimed Amount
                </Text>
              </View>
              {defaultQueryData &&
                defaultQueryData.data &&
                defaultQueryData.data.map((row, i) => (
                  <View key={i} style={styles.tableRow} wrap={false}>
                    <Text style={styles.column1}>
                      {moment(row.invoices.date).format('Do MMM YYYY')}
                    </Text>
                    <Text style={styles.column2}>{row.invoices.invoiceId}</Text>
                    <Text style={styles.column3}>{row.vendor.name}</Text>
                    <Text style={styles.column4}>{row.costCode.code}</Text>
                    <Text style={styles.column5}>{row.costCode.name}</Text>
                    <Text
                      style={[
                        styles.column6,
                        styles.number,
                        {
                          color:
                            row.invoices.claimedAmount < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.invoices.claimedAmount)}
                    </Text>
                  </View>
                ))}
              {defaultQueryData && defaultQueryData.totals && (
                <View style={styles.tableRow} wrap={false}>
                  <Text style={styles.column1}> </Text>
                  <Text style={styles.column2}> </Text>
                  <Text style={styles.column3}> </Text>
                  <Text style={styles.column4}> </Text>
                  <Text style={styles.column5}> </Text>
                  <Text
                    style={[
                      styles.column6,
                      styles.number,
                      {
                        color:
                        defaultQueryData.totals.invoices.claimedAmount < 0
                        ? `${negativeColor}` : `${nonNegativeColor}`,
                      },
                    ]}
                  >
                    {calculateCurrencyString(defaultQueryData.totals.invoices.claimedAmount)}
                  </Text>
                </View>
              )}
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      );
      break;
    case ReportType.FfcBreakdown:
      return (
        <Document>
          <Page orientation="landscape" style={styles.page}>
            <View style={styles.headerContainer}>
              <View style={styles.headingBlock}>
                <Text style={styles.mainHeading}>FFC Breakdown</Text>
                <Text style={styles.headerText}>Projx</Text>
                {defaultQueryData && defaultQueryData.data && (
                  <Text style={styles.headerText}>
                    From{' '}
                    <Text style={styles.bold}>
                      {moment(defaultQueryData.query.dateRange.from).format(
                        'Do MMM YYYY',
                      )}
                    </Text>{' '}
                    to{' '}
                    <Text style={styles.bold}>
                      {moment(defaultQueryData.query.dateRange.to).format(
                        'Do MMM YYYY',
                      )}
                    </Text>
                  </Text>
                )}
              </View>
            </View>
            <View style={styles.tableContainer}>
              <View style={styles.tableRow}>
                <Text style={[styles.column1, styles.tableColumnHeading]}>
                  Budget Code
                </Text>
                <Text style={[styles.column2, styles.tableColumnHeading]}>
                  Phase
                </Text>
                <Text style={[styles.column3, styles.tableColumnHeading]}>
                  Cost Type
                </Text>
                <Text style={[styles.column4, styles.tableColumnHeading]}>
                  Vendor
                </Text>
                <Text style={[styles.column5, styles.tableColumnHeading]}>
                  Stage
                </Text>
                <Text style={[styles.column6, styles.tableColumnHeading]}>
                  Amount/FFC
                </Text>
              </View>
              {defaultQueryData &&
                defaultQueryData.data &&
                defaultQueryData.data.map((row, i) => (
                  <View key={i} style={styles.tableRow} wrap={false}>
                    <Text style={styles.column1}>{row.costCode}</Text>
                    <Text style={styles.column2}>{row.phase}</Text>
                    <Text style={styles.column3}>{row.type}</Text>
                    <Text style={styles.column4}>{row.vendor.name}</Text>
                    <Text style={styles.column5}>{row.history.stage}</Text>
                    <Text
                      style={[
                        styles.column6,
                        styles.number,
                        {
                          color:
                            (row.history.ffc ?? row.history.amount) < 0
                            ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.history.ffc ?? row.history.amount)}
                    </Text>
                  </View>
                ))}
              {defaultQueryData && defaultQueryData.totals && (
                <View style={styles.tableRow} wrap={false}>
                  <Text style={styles.column1}> </Text>
                  <Text style={styles.column2}> </Text>
                  <Text style={styles.column3}> </Text>
                  <Text style={styles.column4}> </Text>
                  <Text style={styles.column5}> </Text>
                  <Text
                    style={[
                      styles.column6,
                      styles.number,
                      {
                        color:
                          defaultQueryData.totals.history.ffc < 0
                          ? `${negativeColor}` : `${nonNegativeColor}`,
                      },
                    ]}
                  >
                    {calculateCurrencyString(defaultQueryData.totals.history.ffc)}
                  </Text>
                </View>
              )}
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      );
      break;
    case ReportType.BudgetTransfers:
      return (
        <Document>
          <Page orientation="landscape" style={styles.page}>
            <View style={styles.headerContainer}>
              <View style={styles.headingBlock}>
                <Text style={styles.mainHeading}>Budget Transfers</Text>
                <Text style={styles.headerText}>Projx</Text>
                {defaultQueryData && defaultQueryData.data && (
                  <Text style={styles.headerText}>
                    From{' '}
                    <Text style={styles.bold}>
                      {moment(defaultQueryData.query.dateRange.from).format(
                        'Do MMM YYYY',
                      )}
                    </Text>{' '}
                    to{' '}
                    <Text style={styles.bold}>
                      {moment(defaultQueryData.query.dateRange.to).format(
                        'Do MMM YYYY',
                      )}
                    </Text>
                  </Text>
                )}
              </View>
              {/* <Image style={styles.logo} source={"TODO"} /> */}
            </View>
            <View style={styles.tableContainer}>
              <View style={styles.tableRow}>
                <Text style={[styles.column1, styles.tableColumnHeading]}>
                  Source Budget
                </Text>
                <Text style={[styles.column2, styles.tableColumnHeading]}>
                  Destination Budget
                </Text>
                <Text style={[styles.column3, styles.tableColumnHeading]}>
                  Amount
                </Text>
                <Text style={[styles.column4, styles.tableColumnHeading]}>
                  Created Date
                </Text>
                <Text style={[styles.column5, styles.tableColumnHeading]}>
                  State
                </Text>
              </View>
              {defaultQueryData &&
                defaultQueryData.data &&
                defaultQueryData.data.map((row, i) => (
                  <View key={i} style={styles.tableRow} wrap={false}>
                    <Text style={styles.column1}>
                      {`(${row.source.costCode.code}) ${row.source.costCode.name}`}
                    </Text>
                    <Text style={styles.column2}>
                      {`(${row.destination.costCode.code}) ${row.destination.costCode.name}`}
                    </Text>
                    <Text
                      style={[
                        styles.column3,
                        styles.number,
                        {
                          color: row.amount < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.amount)}
                    </Text>
                    <Text style={styles.column4}>
                      {moment(row.timestamp).format('Do MMM YYYY')}
                    </Text>
                    <Text style={styles.column5}>{row.state}</Text>
                  </View>
                ))}
              {defaultQueryData && defaultQueryData.totals && (
                <View style={styles.tableRow} wrap={false}>
                  <Text style={styles.column1}> </Text>
                  <Text style={styles.column2}> </Text>
                  <Text
                    style={[
                      styles.column3,
                      styles.number,
                      {
                        color:
                          defaultQueryData.totals.amount < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                      },
                    ]}
                  >
                    {calculateCurrencyString(defaultQueryData.totals.amount)}
                  </Text>
                  <Text style={styles.column4}> </Text>
                  <Text style={styles.column5}> </Text>
                </View>
              )}
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      );
      break;
    case ReportType.CostSummary:
      return (
        <Document>
          <Page orientation="landscape" style={styles.page}>
            <View style={styles.headerContainer}>
              <View style={styles.headingBlock}>
                <Text style={styles.mainHeading}>Cost Summary</Text>
                <Text style={styles.headerText}>Projx</Text>
                {defaultQueryData && defaultQueryData.data && (
                  <Text style={styles.headerText}>
                    From{' '}
                    <Text style={styles.bold}>
                      {moment(defaultQueryData.query.dateRange.from).format(
                        'Do MMM YYYY',
                      )}
                    </Text>{' '}
                    to{' '}
                    <Text style={styles.bold}>
                      {moment(defaultQueryData.query.dateRange.to).format(
                        'Do MMM YYYY',
                      )}
                    </Text>
                  </Text>
                )}
              </View>
              {/* <Image style={styles.logo} source={"TODO"} /> */}
            </View>
            <View style={styles.tableContainer}>
              <View style={styles.tableRow}>
                <Text style={[styles.column1, styles.tableColumnHeading]}>
                  Cost Code
                </Text>
                <Text style={[styles.column2, styles.tableColumnHeading]}>
                  Current Budget
                </Text>
                <Text style={[styles.column3, styles.tableColumnHeading]}>
                  Total Unapproved
                </Text>
                <Text style={[styles.column4, styles.tableColumnHeading]}>
                  Total Approved
                </Text>
                <Text style={[styles.column5, styles.tableColumnHeading]}>
                  Approved Position
                </Text>
                <Text style={[styles.column6, styles.tableColumnHeading]}>
                  FFC Amount
                </Text>
                <Text style={[styles.column7, styles.tableColumnHeading]}>
                  FFC Position
                </Text>
                <Text style={[styles.column8, styles.tableColumnHeading]}>
                  Cumulative Expenditure
                </Text>
                <Text style={[styles.column9, styles.tableColumnHeading]}>
                  FCC
                </Text>
              </View>
              {defaultQueryData &&
                defaultQueryData.data &&
                defaultQueryData.data.map((row, i) => (
                  <View key={i} style={styles.tableRow} wrap={false}>
                    <Text style={styles.column1}>{`(${row.group.costCode.code}) ${row.group.costCode.name}`}</Text>
                    <Text
                      style={[
                        styles.column2,
                        styles.number,
                        {
                          color: row.summary.currentBudget < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.summary.currentBudget)}
                    </Text>
                    <Text
                      style={[
                        styles.column3,
                        styles.number,
                        {
                          color: row.summary.forecasts.total < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.summary.forecasts.total)}
                    </Text>
                    <Text
                      style={[
                        styles.column4,
                        styles.number,
                        {
                          color: row.summary.committments.total < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.summary.committments.total)}
                    </Text>
                    <Text
                      style={[
                        styles.column5,
                        styles.number,
                        {
                          color: row.summary.position.approvedPosition < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.summary.position.approvedPosition)}
                    </Text>
                    <Text
                      style={[
                        styles.column6,
                        styles.number,
                        {
                          color: row.summary.ffc < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.summary.ffc)}
                    </Text>
                    <Text
                      style={[
                        styles.column7,
                        styles.number,
                        {
                          color: row.summary.position.fccPosition < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.summary.position.fccPosition)}
                    </Text>
                    <Text
                      style={[
                        styles.column8,
                        styles.number,
                        {
                          color: row.summary.expenditure.cumulative < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.summary.expenditure.cumulative)}
                    </Text>
                    <Text
                      style={[
                        styles.column9,
                        styles.number,
                        {
                          color: row.summary.expenditure.forecastToComplete < 0 ? `${negativeColor}` : `${nonNegativeColor}`,
                        },
                      ]}
                    >
                      {calculateCurrencyString(row.summary.expenditure.forecastToComplete)}
                    </Text>
                  </View>
                ))}
              {defaultQueryData && defaultQueryData.totals && (
                <View style={styles.tableRow} wrap={false}>
                  <Text style={styles.column1}> </Text>
                  <Text style={styles.column2}> </Text>
                  <Text style={styles.column3}> </Text>
                  <Text style={styles.column4}> </Text>
                  <Text style={styles.column5}> </Text>
                  <Text style={styles.column6}> </Text>
                  <Text style={styles.column7}> </Text>
                  <Text style={styles.column8}> </Text>
                  <Text style={styles.column9}> </Text>
                </View>
              )}
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      );
    default:
      return (
        <Document>
        </Document>
      );
  }
};

export { defaultReportQuery, defaultReportStyleSheets, defaultPdfDocument };
