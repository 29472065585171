import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import EditPersonalInfo from './EditPersonalInfo';
import EditPassword from './EditPassword';
import Conditional from '../shared/Conditional';

const AccountEditor = (props) => {
  const { user, loading } = props;

  const [activeTab, setActiveTab] = useState('1');

  return (
    <Card>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              setActiveTab('1');
            }}
          >
            Personal Info
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              setActiveTab('2');
            }}
          >
            Password
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => {
              setActiveTab('3');
            }}
          >
            Profile Picture
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Conditional if={loading}>
            <Row>
              <Col sm="12">
                <Skeleton height={350} />
                {/* <Skeleton height={100} />
                <Skeleton height={100} /> */}
              </Col>
            </Row>
          </Conditional>
          <Conditional if={!loading}>
            <Row>
              <Col sm="12">
                <EditPersonalInfo user={user} />
              </Col>
            </Row>
          </Conditional>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <EditPassword />
            </Col>
          </Row>
        </TabPane>
        {/* <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <EditProfilePicture />
            </Col>
          </Row>
        </TabPane> */}
      </TabContent>
    </Card>
  );
};

export default AccountEditor;
