import React, { useState } from 'react';
import PanelContext from './panelContext';

const PanelProvider = ({ children }) => {
  const [content, setContent] = useState(undefined);
  const [menu, setMenu] = useState(undefined);
  const [resize, setResize] = useState(undefined);

  return (
    <PanelContext.Provider
      value={{
        menu: menu,
        content: content,
        setContent: setContent,
        setMenu: setMenu,
        resize: resize,
        setResize: setResize,
      }}
    >
      {children}
    </PanelContext.Provider>
  );
};

export default PanelProvider;
