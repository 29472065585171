import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #1B1B1B;
  background-color: #F9F9F9;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  padding: 8px 0;
  font-family: 'Inter', sans-serif;
  position: absolute;
  ${(props) => css`
    top: ${props.top}px;
    right: ${props.right}px;
    bottom: ${props.bottom}px;
    left: ${props.left}px;
    width: ${props.width ? props.width : 256}px;
  `}

  ${(props) => props.show === false && css`
    display: none;
  `}
`;

export const DropdownItem = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #D9D9D9;
  }
`;

export const DropdownItemText = styled.p`
  font-weight: 400;  
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
  text-decoration: none;
  color: #1B1B1B;
`;