import React from 'react';
import Portfolio from '../views/dashboard/portfolio';
import FinancialDashboard from '../views/dashboard/financialDashboard';
import ProgramDashboard from '../views/dashboard/programDashboard';
import Project from '../views/project/project';
import Commitments from '../views/commitments/Commitments';
import GlobalReference from '../views/settings/data/globalReferences';
import ProjectSteps from '../views/project/newProject';
import ProjectDocuments from '../views/project/ProjectDocuments';
import ProjectUserSettings from '../views/project/settings/users';
import ProjectProfile from '../views/project/settings/Profile';
import Account from '../views/account/account';
import Reports from '../views/claims/Reports';
import BudgetConfiguration from '../views/settings/budget/budgetConfiguration';
import FundingView from '../views/funding';
import Budgets from '../views/budgets/Budgets';
import DirectoryView from '../views/directory/DirectoryView';
import RisksView from '../views/risks/Risks';
import ProjectDashboard from '../views/dashboard/financialDashboard';
import CashflowView from '../views/cashflow/CashflowView';
import SafetyView from '../views/safety';
import MilestoneView from '../views/milestones';
import ProgressDiaryView from '../views/progressDiary';
import LookaheadView from '../views/lookahead';
import StatusView from '../views/status';

// Icons
import {
  HorizontalDotsIcon,
  ReferenceDataIcon,
  BudgetCodeIcon,
  DirectoryIcon,
} from '../layouts/layout-components/sidebar/styles/SidebarIcons';

const ThemeRoutes = [
  {
    navlabel: false,
    path: '/portfolio',
    hide: true,
    component: Portfolio,
  },
  {
    navlabel: false,
    path: '/account',
    hide: true,
    component: Account,
  }, 
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/financial-dashboard',
    component: FinancialDashboard,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/program-dashboard',
    component: ProgramDashboard,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/summary',
    component: Project,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/commitments',
    component: Commitments,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/funding',
    component: FundingView,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/budgets',
    component: Budgets,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/risks',
    component: RisksView,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/milestones',
    component: MilestoneView,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/progress-diary',
    component: ProgressDiaryView,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/lookahead',
    component: LookaheadView,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/status',
    component: StatusView,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/safety',
    component: SafetyView,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/reports',
    component: Reports,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/cashflows',
    component: CashflowView,
  },
  {
    collapse: true,
    withParams: true,
    path: '/projects/:handle/settings',
    name: 'Settings',
    state: 'projectSettings',
    child: [
      {
        path: '/projects/:handle/settings/users',
        name: 'Users',
        component: ProjectUserSettings,
      },
      {
        path: '/projects/:handle/settings/profile',
        name: 'Profile',
        component: ProjectProfile,
      },
    ],
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle/documents',
    component: ProjectDocuments,
  },
  {
    navlabel: false,
    withParams: true,
    path: '/projects/:handle',
    component: ProjectDashboard,
  },
  {
    navlabel: true,
    name: 'ORGANISATION SETTINGS',
    // icon: 'mdi mdi-dots-horizontal',
    icon: <HorizontalDotsIcon />,
  },
  /**
   * Settings paths
   */
  {
    path: '/settings/data/references',
    // icon: 'fas fa-database',
    icon: <ReferenceDataIcon />,
    name: 'Reference Data',
    component: GlobalReference,
  },
  {
    path: '/settings/budget/configuration',
    // icon: 'fas fa-dollar-sign',
    icon: <BudgetCodeIcon />,
    name: 'Budget Codes',
    component: BudgetConfiguration,
  },
  {
    path: '/directory',
    // icon: 'mdi mdi-contacts',
    icon: <DirectoryIcon />,
    name: 'Directory',
    component: DirectoryView,
  },
  /** Other Steps */

  {
    navlabel: false,
    withParams: true,
    path: '/project/new',
    component: ProjectSteps,
  },

  { path: '/', pathTo: '/portfolio', name: 'Portfolio', redirect: true },
];
export default ThemeRoutes;
