import styled from 'styled-components';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

export const HeaderFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FundingAccountIcon = styled(MdOutlineAccountBalanceWallet)`
  width: 25px;
  height: 25px;
  color: #1B1B1B;
`;

export const Heading = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-left: 8px;
`;
