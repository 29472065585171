import gql from 'graphql-tag';

const UPDATE_ACCOUNT_MUTATION = gql`
  mutation (
    $_id: ID!
    $name: String
    $title: String
    $company: String
    $phone: String
  ) {
    updateAccount(
      _id: $_id
      user: { name: $name, title: $title, company: $company, phone: $phone }
    ) {
      name
      title
      company
      phone
    }
  }
`;

const GET_USER = gql`
  query ($_id: ID!) {
    getUser(_id: $_id) {
      email
      name
      phone
      handle
      title
      company
    }
  }
`;

const GET_USERS = gql`
  query {
    getUsers {
      _id
      name
      email
    }
  }
`;

const GET_USER_PROFILE = gql`
  fragment UserDetails on User {
    _id
    name
    email
    handle
    phone
    title
    image
    company
  }

  query {
    profileView {
      _id
      name
      email
      handle
      phone
      title
      image
      company
      settings {
        notification {
          mention {
            text
            email
            push
          }
          budgetApproval {
            text
            email
            push
          }
        }
      }
      organisations {
        _id
        name
        logo
        owner {
          ...UserDetails
        }
        members {
          isAdmin
          user {
            _id
          }
        }
        projects {
          _id
        }
        config {
          projectLimit
          trialPeriodEnding
          paid
        }
      }
    }
  }
`;

export { GET_USERS, UPDATE_ACCOUNT_MUTATION, GET_USER, GET_USER_PROFILE };
