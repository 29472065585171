import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  Input,
  CardFooter,
  CardLink,
} from 'reactstrap';
import Switch from 'react-bootstrap-switch';
import _ from 'lodash';
import { useSaveProjectField } from '../../../../hooks';
import { Contact } from '../../../budget/entry/types';
import UploadPhoto from '../../../global/UploadPhoto';

const ProfileEdit = ({ project }) => {
  const [saveProjectField, { fieldData, loading }] = useSaveProjectField();

  const [projectCopy, setProject] = useState();

  const onBlur = ({ field, value }) => {
    if (_.get(project[field]) === value) return;
    saveProjectField(projectCopy._id, field, value);
  };

  useEffect(() => {
    setProject(project);
  }, []);

  useEffect(() => {
    if (!loading && fieldData) {
      project[fieldData.key] = fieldData.value;
    }
  }, [loading, fieldData]);

  return !projectCopy ? (
    <Card>
      <CardHeader>
        <Skeleton />
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="9">
            <Skeleton />
          </Col>
          <Col sm="3">
            <Label className="font-14">Project Logo</Label>
            <Skeleton circle height={100} width={100} />
          </Col>
        </Row>
      </CardBody>
      <CardBody className="border-top pt-4">
        <Skeleton />
      </CardBody>
    </Card>
  ) : (
    <Card>
      <CardHeader>
        <Row>
          <Col md="9">
            <h5 className="mt-2">Profile Details</h5>
          </Col>
          <Col md="3" style={{ textAlign: 'right' }}>
            <Switch
              onColor="success"
              offColor="warning"
              onText="Unarchive"
              offText="Archive"
              defaultValue={projectCopy.locked}
              onChange={(e) => {
                setProject((prev) => ({ ...prev, locked: e.state.value }));
                onBlur({ field: 'locked', value: String(e.state.value) });
              }}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="9" className="pr-5">
            <Label className="font-14">Code</Label>
            <Input
              bsSize="lg"
              style={{ width: '200px' }}
              value={projectCopy.code}
              onBlurCapture={(e) =>
                onBlur({ field: 'code', value: e.target.value })
              }
              onChange={(e) =>
                setProject((prev) => ({ ...prev, code: e.target.value }))
              }
            />
            <Label className="font-14 mt-4">Project Name</Label>
            <Input
              bsSize="lg"
              style={{ width: '300px' }}
              value={projectCopy.name}
              onBlurCapture={(e) =>
                onBlur({ field: 'name', value: e.target.value })
              }
              onChange={(e) =>
                setProject((prev) => ({ ...prev, name: e.target.value }))
              }
            />
            <Label className="font-14 mt-4">Address</Label>
            <Input
              bsSize="lg"
              style={{ width: '500px' }}
              value={projectCopy.address}
              onBlurCapture={(e) =>
                onBlur({ field: 'address', value: e.target.value })
              }
              onChange={(e) =>
                setProject((prev) => ({ ...prev, address: e.target.value }))
              }
            />
            <Label className="font-14 mt-4">Description</Label>
            <Input
              type="textarea"
              bsSize="lg"
              // style={{ width: '500px' }}
              value={projectCopy.description}
              onBlurCapture={(e) =>
                onBlur({ field: 'description', value: e.target.value })
              }
              onChange={(e) =>
                setProject((prev) => ({ ...prev, description: e.target.value }))
              }
            />
            {/* <Label className="font-14 mt-4">Sectors</Label>
                                <Select
                                
                                    onBlur={(tags) => onBlur({ field: "sectors", value: tags })}
                                    defaultValue={projectCopy.sectors}
                                    options={groupedOptions}
                                    components={{ IndicatorsContainer }}
                                    onChange={(tags) => setProject(prev => ({ ...prev, sectors: tags }))}
                                    isMulti
                                    style={{ width: "200px" }}
                                    closeMenuOnSelect={true}
                                /> */}
          </Col>
          <Col sm="3">
            <Label className="font-14">Project Logo</Label>
            <img
              src={projectCopy.logo}
              alt="user"
              className="rounded-circle d-block"
              width="150"
            />
            <UploadPhoto
              onUploadCompleted={(data) =>
                setProject((prev) => ({ ...prev, logo: data.url }))
              }
              imageType="project"
              identifier={projectCopy._id}
            />
            <div />
            <Label className="font-14">Project Thumbnail</Label>
            <img
              src={projectCopy.thumbnail}
              alt="user"
              className="d-block"
              width="150"
            />
            <UploadPhoto
              onUploadCompleted={(data) =>
                setProject((prev) => ({ ...prev, thumbnail: data.url }))
              }
              imageType="thumbnail"
              identifier={projectCopy._id}
            />
          </Col>
        </Row>
      </CardBody>
      <CardHeader>
        <h5>Client Details</h5>
      </CardHeader>
      <CardBody className="border-top">
        <Col sm="9">
          <Label className="font-14">Client Name</Label>
          <Input
            bsSize="lg"
            style={{ width: '300px' }}
            value={projectCopy.client.name}
            onBlurCapture={(e) =>
              onBlur({ field: 'client.name', value: e.target.value })
            }
            onChange={(e) =>
              setProject((prev) => ({
                ...prev,
                client: { name: e.target.value },
              }))
            }
          />
          <Label className="font-14 mt-4">ABN</Label>
          <Input
            bsSize="lg"
            style={{ width: '200px' }}
            value={projectCopy.client.abn}
            onBlurCapture={(e) =>
              onBlur({ field: 'client.abn', value: e.target.value })
            }
            onChange={(e) =>
              setProject((prev) => ({
                ...prev,
                client: { abn: e.target.value },
              }))
            }
          />
        </Col>
      </CardBody>
      <CardBody className="border-top pt-3">
        <div className="mt-4">
          {/* <Button size="lg" className="float-left" outline color="success" disabled={loading}>
                                Save
                    </Button> */}
          {/* <Button size="lg" className="float-left ml-2" outline color="warning">
                                Archive
                    </Button> */}
          <CardLink
            className="float-right ml-4"
            href={`/projects/${project._id}/budgets`}
          >
            Budgets ({projectCopy.costCodes.length})
          </CardLink>
          <CardLink
            className="float-right"
            href={`/projects/${project._id}/settings/users`}
          >
            Collaborators ({projectCopy.collaborators.length})
          </CardLink>
        </div>
      </CardBody>
      <CardFooter>
        {projectCopy.locked ? (
          <Label className="text-warning font-16 mt-2">
            This project is archived.
          </Label>
        ) : (
          ''
        )}
        <div className="budget-contact-card float-right d-flex">
          <Label className="mr-2 mt-2">Owner:</Label>
          <Contact user={project.owner} />
        </div>
      </CardFooter>
    </Card>
  );
};

export default ProfileEdit;
