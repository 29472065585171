import { useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import { useContext } from 'react';
import {
  CREATE_VENDOR_MUTATION,
  VENDOR_BY_ORG_QUERY,
  UPDATE_VENDOR_MUTATION,
} from '../../graphql/queries';
import { OrganisationContext } from '../../contexts';
import { cleanGqlTypename } from '../../common';

const prepare = (data, orgId) => {
  const clone = _.clone(data);
  if (_.matchesProperty('_id', clone)) delete clone._id;
  clone.organisation = orgId;
  return clone;
};

const itemMapper = (item) => cleanGqlTypename(item);

const mapper = (items) => {
  return _.map(items, (item) => itemMapper(item));
};

const useCreateVendor = () => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const [_createVendor, { data, loading, error }] = useMutation(
    CREATE_VENDOR_MUTATION,
  );

  const createVendor = (vendor) => {
    return _createVendor({
      variables: {
        item: prepare(vendor, currentOrganisation._id),
      },
    });
  };

  return [
    createVendor,
    { vendor: cleanGqlTypename(_.get(data, 'createVendor')), loading, error },
  ];
};

const useUpdateVendor = () => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const [_updaeVendor, { data, loading, error }] = useMutation(
    UPDATE_VENDOR_MUTATION,
  );

  const updaeVendor = (vendorId, vendor) => {
    return _updaeVendor({
      variables: {
        _id: vendorId,
        item: prepare(vendor, currentOrganisation._id),
      },
    });
  };

  return [
    updaeVendor,
    { vendor: cleanGqlTypename(_.get(data, 'updateVendor')), loading, error },
  ];
};

const useQueryVendors = ({ onCompleted = () => {} }) => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const [_getVendors, { loading, data, error }] = useLazyQuery(
    VENDOR_BY_ORG_QUERY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) =>
        onCompleted(mapper(_.get(data, 'getVendorsByOrg'))),
    },
  );

  const getVendors = (includeArchived = true) => {
    return _getVendors({
      variables: {
        orgId: currentOrganisation?._id,
        includeArchived,
      },
    });
  };

  return [getVendors, { loading, error, data }];
};

export { useCreateVendor, useQueryVendors, useUpdateVendor };
