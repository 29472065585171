import React, { useState, useEffect } from 'react';
import {
  Progress,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader,
  Button,
  Table,
  Card,
  CardBody,
  CardHeader,
  Label,
  CardFooter,
} from 'reactstrap';

import { ToolTipController, Select } from 'react-tooltip-controller';

import SelectOverride from 'react-select';
import NumberFormat from 'react-number-format';

import _, { toNumber, toLower } from 'lodash';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { useSaveField } from '../../../../hooks';

import {
  currencify,
  extractGraphqlError,
  evaluateStage,
} from '../../../../common';

import { IndicatorsContainer, resolveOptions } from './Phases';

const DisplayType = {
  Text: 'text',
  Circle: 'circle',
  Currency: 'currency',
  Empty: 'empty',
  TextArea: 'textarea',
  DateTime: 'date',
  Number: 'number',
  Select: 'select',
  Autocomplete: 'autocomplete',
};

const BudgetType = {
  Contract: 'contract',
  Provisional: 'provisional',
  Variation: 'variation',
  Bills: 'bills',
};

const Contact = ({ user, timestamp }) => {
  return (
    <div className="d-flex no-block align-items-center">
      <div className="mr-2">
        <img
          src={user.image}
          alt="user"
          className="rounded-circle"
          width="25"
        />
      </div>
      {!timestamp ? (
        <div className="">
          <h5 className="mb-0 font-12 font-medium">{user.name}</h5>
        </div>
      ) : (
        <div className="">
          <h5 className="mb-0 font-12 font-medium">{user.name}</h5>
          <span>{moment(timestamp).fromNow(true)}</span>
        </div>
      )}
    </div>
  );
};

// const onlyNumbers = (val) => val.replace(/\D+./g, '');

const onlyNumbers = (val) => val.replace(/[^-?\d*.]/g, '');

// This function is our input field 'wrapper', basically serves as an interface from which we construct
// all of the inputs used in our budgets (such as text, currency, etc.):
// NOTE: InputWrapper is never used directly, it is used through 'SwitchWrapper', see SwitchWrapper for further info
const InputWrapper = ({
  cellKey,
  value,
  type = DisplayType.Currency,
  onInputChange = () => {},
  disabled = false,
  allowEmpty = true,
  precision = 0,
  allowNegative = false,
  autoSave = false,
  budget,
  validation,
  maxLength = undefined,
  placeholder,
  optionsKey = undefined,
  width = undefined,
}) => {
  const [saveField, { loading }] = useSaveField({
    onCompleted: (data) => {
      if (type === DisplayType.Currency || type === DisplayType.Number) {
        if (data.value !== null) {
          try {
            data.value = toNumber(data.value);
          } catch {
            data.value = null;
          }
        }
      }

      setTempValue(data.value);
      onInputChangeEvent(data.value, !data.saved, true, data);
    },
  });
  const [tempValue, setTempValue] = useState(undefined);
  const alert = useAlert();

  const saveOnBlur = async (v) => {
    let val = v;
    if (type === DisplayType.Currency) {
      if (!val || val === '') val = null;
      else val = toNumber(onlyNumbers(val));
    }

    // If val is equal to the previous value we do nothing:
    if (val === tempValue) return;

    if (validation) {
      const validationResult = validation(val);
      if (!validationResult.valid) {
        onInputChangeEvent(tempValue, false);
        return alert.error(validationResult.message);
      }
    }

    if (!autoSave || !budget) return onInputChangeEvent(val, false);

    try {
      await saveField({
        budgetId: budget._id,
        parentId: budget.parentId,
        key: cellKey,
        value: val == null ? val : String(val),
      });
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
      alert.error(extractGraphqlError(e));
      setTempValue(value);
      onInputChangeEvent(value, true, true);
    }
  };

  const convertType = (type, value) => {
    switch (type) {
      case DisplayType.Number:
        if (value) return toNumber(onlyNumbers(value));
        return undefined;
      // case DisplayType.Currency:
      //   if (value) return toNumber(onlyNumbers(value));
      //   return undefined;
      default:
        return value;
    }
  };

  const onInputChangeEvent = (
    value,
    error = false,
    autoSave = false,
    data = { history: [], budget: undefined },
  ) => {
    if (validation) {
      const validationResult = validation(value);
      if (!validationResult.valid) {
        onInputChange({
          cellKey,
          value: convertType(type, tempValue),
          type,
          error,
          autoSave,
          history: data.history,
          budget: data.budget,
        });
        return alert.error(validationResult.message);
      }
    }

    onInputChange({
      cellKey,
      value: convertType(type, value),
      type,
      error,
      autoSave,
      history: data.history,
      budget: data.budget,
    });
  };

  useEffect(() => {
    setTempValue(value);
    // Cleanup function to prevent memory leak.
    return () => {
      setTempValue(null);
    };
  }, []);

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) return saveOnBlur(e.target.value);
  };

  if (
    (type === DisplayType.Text ||
      type === DisplayType.TextArea ||
      type === DisplayType.DateTime ||
      type === DisplayType.Number) &&
    !autoSave
  )
    return (
      <Input
        type={type}
        disabled={disabled}
        onChange={(e) => onInputChangeEvent(e.target.value)}
        key={cellKey}
        value={value}
        width={width}
        className="form-control currency-sm"
        rows="2"
        maxLength={maxLength}
        placeholder={placeholder}
        style={{ width: '100%', height: '30px', fontSize: '12px' }}
      />
    );

  if (
    (type === DisplayType.Text ||
      type === DisplayType.TextArea ||
      type === DisplayType.DateTime ||
      type === DisplayType.Number) &&
    autoSave
  )
    return (
      <Input
        onBlurCapture={(e) => saveOnBlur(e.target.value)}
        onChange={(e) => onInputChangeEvent(e.target.value)}
        onKeyDown={handleOnKeyDown}
        type={type}
        disabled={disabled}
        key={cellKey}
        value={value}
        className="form-control currency-sm"
        rows="2"
        maxLength={maxLength}
        placeholder={placeholder}
        style={{ width: '100%', height: '30px', fontSize: '12px' }}
      />
    );

  if (type === DisplayType.Currency && autoSave)
    return (
      <HistoryHover budget={budget} cellKey={cellKey}>
        <NumberFormat
          onBlur={(e) => saveOnBlur(e.target.value)}
          onKeyDown={handleOnKeyDown}
          disabled={disabled || loading}
          key={cellKey}
          type="text"
          allowEmptyFormatting={allowEmpty}
          allowNegative={allowNegative}
          thousandsGroupStyle="thousand"
          value={value}
          decimalSeparator="."
          decimalScale={precision}
          displayType="input"
          thousandSeparator={true}
          style={{ width: '100%', height: '30px', fontSize: '12px' }}
        />
        {/* <CurrencyInput
          onBlur={(e) => saveOnBlur(e.target.value)}
          onKeyDown={handleOnKeyDown}
          disabled={disabled || loading}
          key={cellKey}
          value={value}
          type="tel"
          prefix=""
          allowEmpty={allowEmpty}
          allowNegative={allowNegative}
          precision={precision}
          className="form-control "
        /> */}
        {/* <CurrencyInputV2
          onBlur={(e) => saveOnBlur(e.target.value)}
          onKeyDown={handleOnKeyDown}
          onValueChange={(value) => onInputChangeEvent(value)}
          disabled={disabled || loading}
          key={cellKey}
          value={value}
          type="tel"
          prefix=""
          allowNegativeValue={allowNegative}
          decimalsLimit={precision}
          className="form-control currency-sm">
        </CurrencyInputV2> */}
      </HistoryHover>
    );

  if (type === DisplayType.Currency && !autoSave)
    return (
      <HistoryHover budget={budget} cellKey={cellKey}>
        <NumberFormat
          disabled={disabled || loading}
          onValueChange={(values) => {
            onInputChangeEvent(values.floatValue);
          }}
          key={cellKey}
          value={value}
          allowEmptyFormatting={allowEmpty}
          allowNegative={allowNegative}
          thousandsGroupStyle="thousand"
          decimalSeparator="."
          decimalScale={precision}
          isNumericString={true}
          displayType="input"
          type="text"
          thousandSeparator={true}
          style={{
            width: '100%',
            height: '35px',
            fontSize: '12px',
            border: '1px solid #cccccc',
            borderRadius: '5px',
            paddingLeft: '5px',
          }}
        />
        {/* <CurrencyInput
          disabled={disabled || loading}
          onChangeEvent={(e, maskedValue, floatValue) =>
            onInputChangeEvent(floatValue)
          }
          key={cellKey}
          value={value}
          type="tel"
          prefix=""
          allowEmpty={allowEmpty}
          allowNegative={allowNegative}
          precision={precision}
          className="form-control "
        /> */}
        {/* <CurrencyInputV2
          onValueChange={(value, name) => onInputChangeEvent(value)}
          disabled={disabled || loading}
          key={cellKey}
          value={value}
          type="tel"
          prefix=""
          allowNegativeValue={allowNegative}
          decimalsLimit={precision}
          className="form-control currency-sm">
        </CurrencyInputV2> */}
      </HistoryHover>
    );

  if (type === DisplayType.Select) {
    return (
      <SelectOverride
        key={cellKey}
        default={{ label: value }}
        isDisabled={disabled}
        value={{ label: value }}
        options={resolveOptions(optionsKey)}
        components={{ IndicatorsContainer }}
        onChange={(tags) => saveOnBlur(tags.value)}
        closeMenuOnSelect={true}
      />
    );
  }
};

const DisplayWrapper = ({
  cellKey,
  value = 0,
  type = DisplayType.Circle,
  precision = 0,
  disabled = false,
}) => {
  // Here we store our styles based on 'isEditable' props as well as generic :
  const styles = {
    height: '30px',
    cursor: `${disabled ? 'not-allowed' : 'auto'}`,
  };

  if (type === DisplayType.Empty)
    return (
      <div className="display-wrapper budget-currency pt-1" key={cellKey} />
    );
  if (type === DisplayType.Currency) {
    if (value < 0)
      return (
        <div
          className={
            !disabled
              ? 'display-wrapper budget-currency pt-1 in-minus'
              : 'display-wrapper budget-currency pt-1 in-minus gray-me-out'
          }
          key={cellKey}
          style={styles}
        >
          {`(${currencify(value, false, precision)})`}
        </div>
      );
    if (value === 0 || !value)
      return (
        <div
          className={
            !disabled
              ? 'display-wrapper budget-currency pt-1'
              : 'display-wrapper budget-currency pt-1 gray-me-out'
          }
          style={styles}
          key={cellKey}
        >
          {value === 0 ? 0 : '-'}
        </div>
      );

    return (
      <div
        className={
          !disabled
            ? 'display-wrapper budget-currency pt-1'
            : 'display-wrapper budget-currency pt-1 gray-me-out'
        }
        key={cellKey}
        style={styles}
      >
        {currencify(value, false, precision)}
      </div>
    );
  }

  if (type === DisplayType.Text)
    return (
      <div
        className="display-wrapper budget-text pt-1"
        key={cellKey}
        style={{
          ...styles,
          overflow: 'hidden',
          // whiteSpace: 'nowrap',
          // textOverflow: "'...'",
        }}
      >
        {value}
      </div>
    );
  if (type === DisplayType.Circle)
    return (
      <div className="wrapper">
        <Progress
          className={value <= 30 ? 'mt-2 black-text' : 'mt-2'}
          color={
            value <= 30
              ? 'danger'
              : value > 30 && value <= 70
              ? 'info'
              : 'success'
          }
          value={value}
        >
          {value}%
        </Progress>
      </div>
    );
};

// This is our 'SwitchWrapper', this component 'switches' between InputWrapper and DisplayWrapper
// based on whether the user has clicked on the field or not:
const SwitchWrapper = (props) => {
  const [switchState, setSwitchState] = useState('display');
  const [focus, setFocus] = useState(false);

  // Our event handler functions, if props indicates wrapper is disabled, then we do nothing:
  const handleFocus = () => {
    if (!props.disabled) {
      setFocus(true);
    }
  };

  const handleBlur = () => {
    if (!props.disabled) {
      setSwitchState('display');
      setFocus(false);
    }
  };

  const handleMouseOver = () => {
    // If input field not disabled and not focused:
    if (!props.disabled && !focus) {
      setSwitchState('edit');
    }
  };

  const handleMouseOut = () => {
    // If input field not disabled and not focused:
    if (!props.disabled && !focus) {
      setSwitchState('display');
    }
  };

  const handleKeyDown = (e) => {
    // If user presses tab/enter when input cell is focused and not disabled we get out of focus by switching state:
    if ((e.keyCode === 13 || e.keyCode === 9) && !props.disabled && focus) {
      setFocus(false);
      setSwitchState('display');
    }
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {switchState === 'display' && <DisplayWrapper {...props} />}
      {switchState === 'edit' && <InputWrapper {...props} />}
    </div>
  );
};

const HistoryContent = ({ history }) => {
  // const MAPPING = {
  //   "forecast.anticipated": "Anticipated History",
  //   "forecast.submitted": "Quoted History",
  //   "forecast.recommended": "Assessed History",
  //   "committed.contracted": "Contracted History",
  //   "committed.approved": "Approved History",
  //   "expenditure.current.amount": "Total Assessed History"
  // }

  return (
    <Card style={{ width: '500px' }}>
      <CardHeader>{history.header}</CardHeader>
      <CardBody>
        <Table size="sm">
          <tbody>
            {history.items
              .slice(Math.max(history.items.length - 5, 0))
              .reverse()
              .map((item) => (
                <tr key={item._id}>
                  <td>
                    <Label
                      className={
                        item.fieldValue < 0
                          ? 'in-minus font-10 font-medium'
                          : 'font-10 font-medium'
                      }
                    >
                      {item.fieldValue < 0
                        ? `(${currencify(item.fieldValue, true, 2)})`
                        : currencify(item.fieldValue, true, 2)}
                    </Label>
                    <div className="budget-contact-card float-right ml-2">
                      <div className="d-flex no-block align-items-center">
                        <div className="mt-1">
                          <h5 className="mb-0 font-10 font-medium">
                            {item.modifiedBy.name}
                          </h5>
                        </div>
                        <div className="ml-2">
                          <img
                            src={item.modifiedBy.image}
                            alt="user"
                            className="rounded-circle"
                            width="15"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="budget-contact-card float-right">
                      <div className="d-flex no-block align-items-center">
                        <div className="">
                          <span className="font-10">
                            {moment(item.modifiedOn).fromNow(true)} ago by
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </CardBody>
      <CardFooter />
    </Card>
  );
};

const HistoryHover = ({ budget, cellKey, children }) => {
  const FIELDS = {
    'forecast.anticipated': 'Anticipated History',
    'forecast.submitted': 'Quoted History',
    'forecast.recommended': 'Assessed History',
    'committed.contracted': 'Contracted History',
    'committed.approved': 'Approved History',
    'expenditure.current.amount': 'Total Assessed History',
  };

  const handleStateChange = () => {};

  const extractHistory = (cell) => {
    return {
      header: FIELDS[cell] ?? 'History',
      items: _.filter(budget.history, (item) => item.field === cell),
    };
  };

  if (budget && extractHistory(cellKey).items.length > 0) {
    return (
      <ToolTipController
        id="history"
        detect="hover"
        returnState={handleStateChange}
        offsetX="centre"
        offsetY={20}
        animation="fadeIn"
        duration="200ms"
        timing="ease"
        properties={['opacity', 'transform']}
      >
        <Select>{children}</Select>

        <HistoryContent history={extractHistory(cellKey)} />
      </ToolTipController>
    );
  }
  return children;
};

const BudgetHeader = ({ type, view }) => {
  return (
    <>
      <tr>
        <th colSpan="6" />
        {view === 'simple' && <th className="committed" />}
        {view === 'detailed' && (
          <>
            <th colSpan="3" className="forecast-header">
              Unapproved Costs ($)
            </th>
            <th colSpan="3" className="committed-header">
              Approved Costs ($)
            </th>
            <th colSpan="1" className="ffc-header" />
            <th colSpan="1" className="expenditure-header-header">
              Expenditure
            </th>
          </>
        )}
        {view === 'claims' && (
          <th colSpan="5" className="expenditure-header-header">
            Expenditure
          </th>
        )}
      </tr>
      <tr>
        <th />
        <th />
        <th />
        <th className="budget-left-align">REF</th>
        <th className="budget-left-align">Activity</th>
        <th className="budget-left-align">Cost Position</th>
        {view === 'simple' && <th className="ffc budget-right-align">FFC</th>}
        {view === 'detailed' && (
          <>
            <th className="forecast budget-right-align">Anticipated</th>
            <th className="forecast budget-right-align">Submitted</th>
            <th className="forecast budget-right-align">Assessed</th>
            <th className="committed budget-right-align">
              {type === BudgetType.Provisional || type === BudgetType.Contract
                ? 'Contracted'
                : ''}
            </th>
            <th className="committed budget-right-align">
              {type === BudgetType.Provisional ? 'Approved Let' : ''}
            </th>
            <th className="committed budget-right-align">
              {type === BudgetType.Provisional
                ? 'Adjustment'
                : type === BudgetType.Variation
                ? 'Approved'
                : ''}
            </th>
            <th className="ffc budget-right-align">FFC</th>
            <th className="expenditure-header budget-right-align">
              Previously Assessed
            </th>
          </>
        )}
        {view === 'claims' && (
          <>
            <th className="expenditure-header budget-right-align">
              Allowed To Claim
            </th>
            <th className="expenditure-header budget-right-align">
              Previously Assessed
            </th>
            <th className="expenditure-header budget-right-align">
              Total Assessed
            </th>
            <th className="expenditure-header budget-right-align">
              This Claim
            </th>
            <th className="expenditure-header budget-right-align">
              Remaining to Claim
            </th>
          </>
        )}
      </tr>
    </>
  );
};

const BudgetFooter = ({
  type,
  totals,
  view,
  precision = 0,
  details,
  createNewLineItem = () => {},
  value,
}) => {
  const newButtonStyle = {
    border: 0,
    padding: '5px',
    cursor: 'pointer',
    height: '20px',
    backgroundColor: '#ffffff',
    color: '#c7c6c4',
    outline: 'none',
  };

  return (
    <>
      <tr>
        <th />
        {details.locked && type !== 'variation' ? (
          <th />
        ) : (
          <td style={{ borderWidth: '1px' }}>
            <button
              onClick={() =>
                createNewLineItem({
                  type: 'new-budget', // always type 'new-budget' as we create a new budget here
                  value: `${value}`,
                })
              }
              style={newButtonStyle}
            >
              <i className="fas fa-plus mr-1" />
              <span>New</span>
            </button>
          </td>
        )}
        <th />
        <th />
        <th />
        <td>Subtotal:</td>
        {view === 'simple' && (
          <td>{currencify(totals.fcc, true, precision)}</td>
        )}
        {view === 'detailed' && (
          <>
            <td className={totals.forecast.anticipated < 0 ? 'in-minus' : ''}>
              {totals.forecast.anticipated < 0
                ? `(${currencify(
                    totals.forecast.anticipated,
                    true,
                    precision,
                  )})`
                : currencify(totals.forecast.anticipated, true, precision)}
            </td>
            <td className={totals.forecast.submitted < 0 ? 'in-minus' : ''}>
              {totals.forecast.submitted < 0
                ? `(${currencify(totals.forecast.submitted, true, precision)})`
                : currencify(totals.forecast.submitted, true, precision)}
            </td>
            <td className={totals.forecast.recommended < 0 ? 'in-minus' : ''}>
              {totals.forecast.recommended < 0
                ? `(${currencify(
                    totals.forecast.recommended,
                    true,
                    precision,
                  )})`
                : currencify(totals.forecast.recommended, true, precision)}
            </td>
            <td className={totals.committed.contracted < 0 ? 'in-minus' : ''}>
              {type === BudgetType.Provisional || type === BudgetType.Contract
                ? totals.committed.contracted < 0
                  ? `(${currencify(
                      totals.committed.contracted,
                      true,
                      precision,
                    )})`
                  : currencify(totals.committed.contracted, true, precision)
                : ''}
            </td>
            <td className={totals.committed.let < 0 ? 'in-minus' : ''}>
              {type === BudgetType.Provisional
                ? totals.committed.let < 0
                  ? `(${currencify(totals.committed.let, true, precision)})`
                  : currencify(totals.committed.let, true, precision)
                : ''}
            </td>
            <td
              className={
                (totals.committed.adjustment && totals.committed.adjustment) <
                  0 ||
                (totals.committed.approved && totals.committed.approved) < 0
                  ? 'in-minus'
                  : ''
              }
            >
              {type === BudgetType.Provisional
                ? totals.committed.adjustment && totals.committed.adjustment < 0
                  ? `(${currencify(
                      totals.committed.adjustment,
                      true,
                      precision,
                    )})`
                  : currencify(totals.committed.adjustment, true, precision)
                : type === BudgetType.Variation
                ? (totals.committed.approved && totals.committed.approved) < 0
                  ? `(${currencify(
                      totals.committed.approved,
                      true,
                      precision,
                    )})`
                  : currencify(totals.committed.approved, true, precision)
                : ''}
            </td>
            <td className={totals.fcc < 0 ? 'in-minus' : ''}>
              {totals.fcc < 0
                ? `(${currencify(totals.fcc, true, precision)})`
                : currencify(totals.fcc, true, precision)}
            </td>
            <td>{currencify(totals.expenditure.current, true, precision)}</td>
          </>
        )}
        {view === 'claims' && (
          <>
            <td>{currencify(totals.expenditure.allowed, true, precision)}</td>
            <td>{currencify(totals.expenditure.previous, true, precision)}</td>
            <td>{currencify(totals.expenditure.current, true, precision)}</td>
            <td className={totals.expenditure.thisClaim < 0 ? 'in-minus' : ''}>
              {totals.expenditure.thisClaim < 0
                ? `(${currencify(
                    totals.expenditure.thisClaim,
                    true,
                    precision,
                  )})`
                : currencify(totals.expenditure.thisClaim, true, precision)}
            </td>
            <td>{currencify(totals.expenditure.remaining, true, precision)}</td>
          </>
        )}
      </tr>
    </>
  );
};

const TotalFooter = ({ totals, precision = 0 }) => {
  return (
    <Table size="sm" hover className="budget-entry">
      <tfoot>
        <tr>
          <td colSpan="6" style={{ width: '45%' }} />
          <td className="font-14">
            {currencify(totals.expenditure.allowed, true, precision)}
          </td>
          <td>{currencify(totals.expenditure.previous, true, precision)}</td>
          <td>{currencify(totals.expenditure.current, true, precision)}</td>
          <td className={totals.expenditure.thisClaim < 0 ? 'in-minus' : ''}>
            {totals.expenditure.thisClaim < 0
              ? `(${currencify(totals.expenditure.thisClaim, true, precision)})`
              : currencify(totals.expenditure.thisClaim, true, precision)}
          </td>
          <td>{currencify(totals.expenditure.remaining, true, precision)}</td>
        </tr>
      </tfoot>
    </Table>
  );
};

const BudgetMenus = ({
  onInputChangeEvent = () => {},
  onClick = () => {},
  item,
  type,
  focotr,
  splitOpen = false,
}) => {
  const [show, setShow] = useState(false);
  const [saveField, { loading }] = useSaveField({
    onCompleted: () => {},
  });

  const [showMenu, setShowMenu] = useState({
    costAdjustmentApprovalForm: false,
  });

  const toggle = () => setShow(!show);

  const internalOnInputChangeEvent = async ({
    cellKey,
    value,
    type,
    autoSave,
  }) => {
    try {
      await saveField({
        budgetId: item._id,
        parentId: item.parentId,
        key: cellKey,
        value: String(value),
      });

      onInputChangeEvent({
        cellKey,
        value,
        type,
        error: false,
        autoSave,
      });
    } catch (e) {
      onInputChangeEvent({
        cellKey,
        value: !value,
        type,
        error: true,
        autoSave,
      });
    }
  };

  const onDelete = () => {
    onClick('delete');
    toggle();
  };

  useEffect(() => {
    setShowMenu((prev) => ({
      ...prev,
      costAdjustmentApprovalForm: !!(
        _.isNumber(item.committed.approved) ||
        _.isNumber(item.forecast.recommended)
      ),
    }));
  }, [item]);

  return (
    <>
      <UncontrolledDropdown style={{ zIndex: 10000, position: 'relative' }}>
        <DropdownToggle className="btn-sm ml-2" color="projx">
          <i className="fas fa-ellipsis-h" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => onClick('expand')}>
            <i className="fas fa-expand" /> Details
          </DropdownItem>
          <DropdownItem onClick={() => onClick('upload')}>
            <i className="fas fa-upload" /> Upload
          </DropdownItem>
          <DropdownItem onClick={() => onClick('chat')}>
            <i className="fab fa-rocketchat" /> Chat
          </DropdownItem>
          {BudgetType.Provisional === type && focotr.locked ? (
            <>
              <DropdownItem divider />
              <DropdownItem
                disabled={loading}
                onClick={() =>
                  internalOnInputChangeEvent({
                    cellKey: 'committed.open',
                    value: !item.committed.open,
                    autoSave: true,
                    type: 'pslocked',
                  })
                }
              >
                <i
                  className={
                    item.committed.open ? 'fas fa-lock-open' : 'fas fa-lock'
                  }
                />
                {item.committed.open
                  ? ' Close Provisional Sum'
                  : ' Open Provisional Sum'}
              </DropdownItem>
            </>
          ) : (
            <div />
          )}
          {BudgetType.Variation === type ? (
            item.parentId == undefined ? (
              <>
                <DropdownItem divider />
                <DropdownItem
                  disabled={item.parentId ?? false}
                  onClick={() => onClick('split')}
                >
                  <i className="fas fa-copy" /> Split Variation
                </DropdownItem>
                <DropdownItem
                  disabled={item.parentId ?? false}
                  onClick={() => onClick('split-show')}
                >
                  <i className="fas fa-list-ul" /> {splitOpen ? 'Close Splits': 'Show Splits'}
                 </DropdownItem>
              </>
            ) : (
              ''
            )
          ) : (
            <div />
          )}
          {/* For now only variations show the approval form */}
          {BudgetType.Variation === type &&
            showMenu.costAdjustmentApprovalForm && (
              <>
                <DropdownItem divider />
                <DropdownItem onClick={() => onClick('approval-form')}>
                  <i className="fab fa-wpforms" /> Approval Form
                </DropdownItem>
              </>
            )}
          <DropdownItem divider />
          <DropdownItem onClick={toggle} className="text-danger">
            <i className="fas fa-trash" /> Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <Modal isOpen={show} toggle={toggle}>
        <ModalHeader>Delete &quot;{item.phase}&quot;</ModalHeader>
        <ModalBody>
          <span className="text-danger">
            <b>WARNING: </b>
          </span>
          You are about to delete this{' '}
          <span className="text-danger">
            <b>{item.type}</b>
          </span>{' '}
          record. This is a irreversable operation and all data associated to
          this record will be permanently lost.
          <div className="mt-3">
            <span className="text-danger">
              <b>Are you sure you want to delete this record?</b>
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="projx" outline onClick={toggle}>
            Cancel
          </Button>
          <Button color="projx" onClick={onDelete}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const disableForecast = (entry, field) => {
  const { submitted, recommended } = entry.forecast;
  const { approved, contracted } = entry.committed;

  if (_.isNumber(approved) || _.isNumber(contracted)) return true;

  let doDisable = true;

  switch (toLower(field)) {
    case 'recommended':
      doDisable = false;
      break;
    case 'submitted':
      if (_.isNumber(recommended)) doDisable = true;
      else doDisable = false;
      break;
    case 'anticipated':
      if (_.isNumber(recommended) || _.isNumber(submitted)) doDisable = true;
      else doDisable = false;
      break;
    default:
      break;
  }

  return doDisable;
};

const Stage = ({ cost }) => {
  const [stage, setStage] = useState({
    name: 'unknown',
    color: 'secondary',
  });

  useEffect(() => {
    if (!cost) return;
    setStage(evaluateStage(cost));
  }, [cost.stage]);

  return (
    <div className="d-flex flex-row">
      {cost.parentId ? <i className="mdi mdi-subdirectory-arrow-right" /> : ''}
      <div>
        <span className={`ml-3 badge badge-${stage.color}`}>{stage.name}</span>
      </div>
    </div>
  );
};

export {
  BudgetType,
  DisplayType,
  SwitchWrapper,
  DisplayWrapper,
  BudgetHeader,
  BudgetFooter,
  InputWrapper,
  disableForecast,
  Contact,
  BudgetMenus,
  TotalFooter,
  Stage,
};
