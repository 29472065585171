import styled, { css } from 'styled-components';
import { IoSettingsOutline } from 'react-icons/io5';
import { IoNotificationsOutline } from 'react-icons/io5';
import { BiArrowToLeft } from 'react-icons/bi';
import { BiArrowToRight } from 'react-icons/bi';
import { GrSearch } from 'react-icons/gr';
import { AiOutlineUser } from 'react-icons/ai';

const HeaderIconStyles = css`
  width: 25px;
  height: 25px;
  color: #1B1B1B;
`;

export const SettingIcon = styled(IoSettingsOutline)`
  ${HeaderIconStyles}
`;

export const NotificationIcon = styled(IoNotificationsOutline)`
  ${HeaderIconStyles}
`;

export const LeftArrow = styled(BiArrowToLeft)`
  ${HeaderIconStyles}
`;  

export const RightArrow = styled(BiArrowToRight)`
  ${HeaderIconStyles}
`;

export const SearchIcon = styled(GrSearch)`
  width: 20px;
  height: 20px;
`;

export const ProfileIcon = styled(AiOutlineUser)`
  ${HeaderIconStyles}
`;
