import styled from 'styled-components';

export const DeleteSafetyPeriodModalHeader = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
`;

export const DeleteSafetyPeriodModalText = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
`;

export const DeleteSafetyPeriodModalButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: #E7224A;
  cursor: pointer;
  color: #F9F9F9;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

// Just align everything to the right
export const DeleteSafetyPeriodModalFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;