// Select Options for our 'react-select' inputs in the milestone module
const phaseSelectOptions = [
  { value: 'PRE_CONSTRUCTION', label: 'Pre-Construction' },
  { value: 'CONSTRUCTION', label: 'Construction' },
  { value: 'POST_CONSTRUCTION', label: 'Post-Construction' },
];
  
const statusSelectOptions = [
  { value: 'ON_TRACK', label: 'On Track' },
  { value: 'DELAYED', label: 'Delayed' },
  { value: 'HALTED', label: 'Halted' },
];

// Select Options for our 'react-select' FILTER inputs in the milestone module
const filterPhaseSelectOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'PRE_CONSTRUCTION', label: 'Pre-Construction' },
  { value: 'CONSTRUCTION', label: 'Construction' },
  { value: 'POST_CONSTRUCTION', label: 'Post-Construction' },
];

const filterStatusSelectOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'ON_TRACK', label: 'On Track' },
  { value: 'DELAYED', label: 'Delayed' },
  { value: 'HALTED', label: 'Halted' },
];

/**
 * This object is used for colour mapping our StatusBadge component in our 'Status' 
 * column for the table
 */
const statusColorOptionMapping = {
  ON_TRACK: {
    text: 'On Track',
    backgroundColor: '#7BB662',
  },
  DELAYED: {
    text: 'Delayed',
    backgroundColor: '#FFC51C',
  },
  HALTED: {
    text: 'Halted',
    backgroundColor: '#E03C32' ,
  }
}

/**
 * This object is used for colour mapping our PhaseBadge component in our 'Phase' 
 * column for the table
 */
const phaseColorOptionMapping = {
  PRE_CONSTRUCTION: {
    text: 'Pre-Construction',
    backgroundColor: '#007FFF',
  },
  CONSTRUCTION: {
    text: 'Construction',
    backgroundColor: '#EB4C42',
  },
  POST_CONSTRUCTION: {
    text: 'Post-Construction',
    backgroundColor: '#9966CC',
  }
}

export {
  phaseSelectOptions,
  statusSelectOptions,
  filterPhaseSelectOptions,
  filterStatusSelectOptions,
  statusColorOptionMapping,
  phaseColorOptionMapping,
}
