import React, { } from "react"
import { Badge, Table } from "reactstrap"
import { currencify } from "../../common"
import { DisplayWrapper, DisplayType } from "../budget/entry/types"


const MatrixValue = ({ cashflow, byType, amount }) => {

    return (
        <span >
            {cashflow.original.calculations.byCostType[byType][amount] == 0
                ? <DisplayWrapper value={"-"} type={DisplayType.Text} />
                : <DisplayWrapper precision={0} value={cashflow.original.calculations.byCostType[byType][amount]} type={DisplayType.Currency} />}
        </span>
    )
}

const ProjectionBy = ({ by }) => {
    const evaluateBy = () => {
        switch (by) {
            case "budget": return "projx";
            case "forecast": return "info";
            case "approved": return "success";
            case "committed": return "success";
            default: return "default"
        }
    }
    return (
        <td className="currency cashflow-matrix-projectionby">
            <Badge className="mt-2" color={evaluateBy()}>{by}</Badge>
        </td>
    )
}


const FormatCurrency = ({ val }) => {
    return (
        <td className={`currency ${val !== 0 ? "cashflow-indicator-notok" : "cashflow-indicator-ok"}`}>
            {val !== 0 ? (
                <DisplayWrapper
                    value={val}
                    type={DisplayType.Currency}
                    precision={0} />
            ) : (
                <div className="pt-1">
                    <i className={`fas fa-check-circle`}></i>
                </div>

            )}
        </td>
    );
};

const CashflowMatrix = ({ cashflow }) => {

    return (
        <Table size="sm" hover className="budget-entry cashflow-matrix-table">
            <thead>
                <tr>
                    <th colspan="6" className="cashflow-matrix-header period-header-radius-left period-header-radius-right font-14">Projection Offset Table</th>
                </tr>
                <tr>
                    <th className="cashflow-matrix-cell">Projection By</th>
                    <th className="cashflow-matrix-cell">Current Total</th>
                    <th className="cashflow-matrix-cell">Cost Revisions</th>
                    <th className="cashflow-matrix-cell">Expended to Date</th>
                    <th className="cashflow-matrix-cell">Cost to Complete</th>
                    <th className="cashflow-matrix-cell">Expended Difference</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <ProjectionBy by="budget" />
                    <td>
                        <MatrixValue cashflow={cashflow} byType={"byBudget"} amount="total" />
                    </td>
                    <td><MatrixValue cashflow={cashflow} byType={"byBudget"} amount="revision" /></td>
                    <td rowspan="4" className="cashflow-matrix-cols"><FormatCurrency val={cashflow.original.calculations.byCostType.spentToDate} /></td>
                    <td><MatrixValue cashflow={cashflow} byType={"byBudget"} amount="toComplete" /></td>
                    <td rowspan="4" className="cashflow-matrix-cols"><FormatCurrency val={cashflow.original.calculations.byCostType.difference} /></td>
                </tr>
                <tr>
                    <ProjectionBy by="approved" />
                    <td><MatrixValue cashflow={cashflow} byType={"byApproved"} amount="total" /></td>
                    <td><MatrixValue cashflow={cashflow} byType={"byApproved"} amount="revision" /></td>
                    <td><MatrixValue cashflow={cashflow} byType={"byApproved"} amount="toComplete" /></td>
                </tr>
                <tr>
                    <ProjectionBy by="committed" />
                    <td><MatrixValue cashflow={cashflow} byType={"byCommitted"} amount="total" /></td>
                    <td><MatrixValue cashflow={cashflow} byType={"byCommitted"} amount="revision" /></td>
                    <td><MatrixValue cashflow={cashflow} byType={"byCommitted"} amount="toComplete" /></td>
                </tr>
                <tr>
                    <ProjectionBy by="forecast" />
                    <td><MatrixValue cashflow={cashflow} byType={"byForecast"} amount="total" /></td>
                    <td><MatrixValue cashflow={cashflow} byType={"byForecast"} amount="revision" /></td>
                    <td><MatrixValue cashflow={cashflow} byType={"byForecast"} amount="toComplete" /></td>
                </tr>
            </tbody>
        </Table>
    )
}

export default CashflowMatrix;
export {
    CashflowMatrix
}