import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  width: 100%;
  box-shadow: 0 5px 10px rgba(154,160,185,.025), 0 15px 40px rgba(166,173,201,.2);
  border-radius: 8px;
  color: #1B1B1B;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: #F9F9F9;
  padding: 8px 16px;
  margin-bottom: 4px;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: 12px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export const Heading = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin: 0 16px 0 0;
`

export const TimeStamp = styled.p`
  font-weight: 300;
`;

export const Text = styled.p`
  margin: 8px 0 24px 0;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export const Button = styled.button`
  font-weight: 300;
  color: #757575;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: underline;
  }

  ${(props) => props.color && css`
    color: ${props.color};
  `}
`;

export const Dot = styled.div`
  color: #757575;
  width: 2px;
  height: 2px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: black;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 24px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  padding: 8px;
  margin-bottom: 16px;

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px #4299E1;
  }
`;