import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { ACTIVITY_STREAM_QUERY } from '../../graphql/queries';

import { cleanGqlTypename } from '../../common';

const extractCursorData = (data) => {
  const { pageInfo, edges } = data;
  const activities = _.map(edges, (edge) => edge.node);
  return {
    pageInfo,
    activities,
  };
};

const useStream = (projectId) => {
  let { data, loading, error, fetchMore } = useQuery(ACTIVITY_STREAM_QUERY, {
    variables: {
      projectId,
      cursor: 1,
    },
  });

  const getActivities = (cursor) => {
    return fetchMore({
      variables: {
        projectId,
        cursor,
      },
    });
  };

  return [
    getActivities,
    {
      data: extractCursorData(cleanGqlTypename(_.get(data, 'getActivities'))),
      loading,
      error,
    },
  ];
};

export default useStream;

export { useStream };
