import _ from 'lodash';

const verifyWithinBudget = (
  inputValue,
  previousValue,
  totalBudget,
  budgetCostItems,
) => {
  return new Promise((resolve, reject) => {
    const sumOfBudgets =
      _.sumBy(budgetCostItems, (cost) => cost.amount) - previousValue;

    if (inputValue > totalBudget - sumOfBudgets)
      return reject({
        amount: inputValue - (totalBudget - sumOfBudgets),
        sum: sumOfBudgets,
        input: inputValue,
        over: true,
      });

    return resolve({
      over: false,
      amount: 0,
      input: inputValue,
    });
  });
};

const disableOnSplit = (entry) => {
  if(evaluateStage(entry).name === "split") return true;
  return false;
}

let evaluateStage = (item) => {
  let _stage = { name: item.stage, color: '' };

  switch (item.stage) {
    case "created": _stage.color = "secondary"; break;
    case "anticipated": _stage.color = "danger"; break;
    case "assessed": _stage.color = "primary"; break;
    case "submitted": _stage.color = "primary"; break;
    case "recommended": _stage.color = "info"; break;
    case "contracted": _stage.color = "success"; break;
    case "approved": _stage.color = "success"; break;
    case "split": _stage.color = "warning"; break;
    default: _stage.color = "secondary"; break;
  }

  return _stage
};

const calculateLet = (committed, stage = 'approved') => {
  const committedLet =
    committed.let && committed.let.length > 0
      ? _.filter(committed.let, (l) => l.state === stage)
      : [];
  return {
    stage: committedLet,
    amount:
      committedLet && committedLet.length > 0
        ? _.sumBy(committedLet, (item) => item.value)
        : 0,
  };
};

const minorAdjustments = (committed) => {
  const { amount: letAmount } = calculateLet(committed);

  if (committed.open && committed.contracted > letAmount) return 0;

  return letAmount - committed.contracted;
};

const evaluateLineStatus = (status) => {
  if (status === 'loaded') return 'budget-approval-indicator budget-saved';
  if (status === 'error') return 'budget-approval-indicator budget-rejected';
  if (status === 'dirty') return 'budget-approval-indicator budget-dirty';
  return 'budget-approval-indicator budget-saved';
};

const whatStage = (budget) => {
  switch (budget.type) {
    case 'contract':
      break;
    case 'provisional':
      let adjustment = minorAdjustments(budget.committed);
      if (_.isNumber(budget.committed.contracted))
        return {
          stage: 'contracted',
          amount: budget.committed.contracted + adjustment,
        };
      break;
    case 'variation':
      if (_.isNumber(budget.committed.approved))
        return { stage: 'contracted', amount: budget.committed.approved };
      break;
    case 'bills':
      break;
    default:
      break;
  }

  if (_.isNumber(budget.committed.contracted))
    return { stage: 'contracted', amount: budget.committed.contracted };
  if (_.isNumber(budget.forecast.recommended))
    return { stage: 'assessed', amount: budget.forecast.recommended };
  if (_.isNumber(budget.forecast.submitted))
    return { stage: 'submitted', amount: budget.forecast.submitted };
  if (_.isNumber(budget.forecast.anticipated))
    return { stage: 'anticipated', amount: budget.forecast.anticipated };
  return { stage: 'unknown' };
};

const howManyApproved = (budgets) => {
  if (!budgets) return 0;
  const approved = _.sumBy(budgets, (budget) => {
    if (budget.type === 'contract' || budget.type === 'provisional') {
      if (_.isNumber(budget.committed.contracted)) return 1;
      return 0;
    }
    if (_.isNumber(budget.committed.approved)) return 1;
    return 0;
  });

  return approved;
};

const sumCostsByStage = (stage, budgets, fn) => {
  return _.sumBy(
    _.filter(budgets, (filterItem) => stage === fn(filterItem).stage),
    (item) => {
      return whatStage(item).amount;
    },
  );
};

const costStats = (budgets) => {
  if (!budgets) return 0;

  const totals = {
    contracted: sumCostsByStage('contracted', budgets, whatStage),
    anticipated: sumCostsByStage('anticipated', budgets, whatStage),
    submitted: sumCostsByStage('submitted', budgets, whatStage),
    assessed: sumCostsByStage('assessed', budgets, whatStage),
    unknown: 0,
  };

  return totals;
};

const calculateCostItem = (costItem) => {
  
}

export { verifyWithinBudget, evaluateLineStatus, howManyApproved, costStats, evaluateStage, disableOnSplit };
