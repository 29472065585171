import styled, { css } from 'styled-components';

export const WidgetCardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  height: 400px;
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  color: #1B1B1B;
  background-color: #F9F9F9;
  box-shadow: 0 5px 10px rgba(154,160,185,.025), 0 15px 40px rgba(166,173,201,.2);
  font-family: 'Inter', sans-serif;
  // NOTE: We add this for our html2pdf library, See https://github.com/eKoopmans/html2pdf.js 
  break-after: always;

  // Size: 
  ${(props) =>
    props.height === 'M' &&
    css`
     height: 450px;
    `
  }

  ${(props) =>
    props.height === 'L' &&
    css`
     height: 600px;
    `
  }

`;

export const WidgetTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const WidgetOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 0 0;
`;

export const WidgetHeader = styled.h6`
  font-size: 21px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  padding: 20px 0 5px 30px;
  align-self: flex-start;
  font-weight: 400;
`;

export const WidgetBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
`;

export const DeleteWidgetButton = styled.span`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #D9D9D9;
  border-radius: 50%;
  outline: none;
  font-size: 20px;
  transition: transform 0.1s;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;
