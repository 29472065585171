import { Font } from '@react-pdf/renderer';

const openSans = require('../../assets/fonts/Open_Sans/OpenSans-Regular.ttf');
const lato = require('../../assets/fonts/Lato/Lato-Regular.ttf');
const latoBold = require('../../assets/fonts/Lato/Lato-Bold.ttf');
const latoItalic = require('../../assets/fonts/Lato/Lato-Italic.ttf');

const registerFonts = () => {
  Font.register({
    family: 'Open Sans',
    src: openSans,
  });
  Font.register({
    family: 'Lato',
    src: lato,
  });
  Font.register({
    family: 'Lato Italic',
    src: latoItalic,
  });
  Font.register({
    family: 'Lato Bold',
    src: latoBold,
  });

  return true;
};

export { registerFonts };
