import React, { useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import OrganisationContext from './organisation.context';
import UserContext from '../user/user.context';
import AuthContext from '../auth/auth.context';
import _ from 'lodash';

const OrganisationProvider = ({ children }) => {
  const { id } = useContext(AuthContext);
  const { user } = useContext(UserContext);

  const localStorageKey = `${id}.currentOrganisationId`;

  const [localStorageOrganisationId, setLocalStorageOrganisationId] =
    useLocalStorage(localStorageKey, null);

  // use local store value if not will be null
  const [currentOrganisationState, setCurrentOrganisationState] =
    useState(null);

  // if user has never selected an org before, default to the first organisation they are apart of
  useEffect(() => {
    if (user !== null) {
      if (!localStorageOrganisationId) {
        setCurrentOrganisation(user.organisations[0]);
      } else {
        // Blasphemy....
        let org = _.find(
          user.organisations,
          (o) => o._id === localStorageOrganisationId,
        );
        if (org && org.name === null) return;
        setCurrentOrganisationState(org);
      }
    }
  }, [user]);

  const setCurrentOrganisation = (organisation) => {
    setCurrentOrganisationState(organisation);
    setLocalStorageOrganisationId(organisation._id);
  };

  return (
    <OrganisationContext.Provider
      value={{
        currentOrganisation: currentOrganisationState,
        setCurrentOrganisation,
      }}
    >
      {children}
    </OrganisationContext.Provider>
  );
};

export default OrganisationProvider;
