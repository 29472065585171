import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col,
  Label,
} from 'reactstrap';
import { Pie } from 'react-chartjs-2';
import { currencify, randomColour } from '../../../common';

import { useSaveProjectField } from '../../../hooks';

import CostCodeItem from './CostCodeItem';
import { DisplayType, InputWrapper } from '../entry/types';

const CostCodeOverview = ({ costCodes, project }) => {
  const [saveProjectField, { loading }] = useSaveProjectField();

  const [estimate, setEstimate] = useState(project.amount);

  const [total, setTotal] = useState(0);
  const [group, setGroup] = useState(undefined);
  const [chart, setChart] = useState(undefined);

  const calcGroup = () => _.groupBy(costCodes, 'budgetCategory.name');
  const calcTotal = () => _.sumBy(costCodes, (item) => item.currentAmount);
  const calcChart = () => {
    const randomBgColour = _.map(costCodes, () => randomColour());
    return {
      labels: _.map(group, (_, key) => key),
      datasets: [
        {
          data: _.map(group, (value) => _.sumBy(value, (v) => v.currentAmount)),
          backgroundColor: randomBgColour,
          hoverBackgroundColor: randomBgColour,
        },
      ],
    };
  };

  const updateAmount = (e) => {
    setEstimate(e.value);
  };

  const updateProject = () => {
    saveProjectField(project._id, 'amount', String(estimate));
  };

  useEffect(() => {
    setTotal(calcTotal());
    setGroup(calcGroup());
  }, [costCodes]);

  useEffect(() => {
    if (group) setChart(calcChart());
  }, [group]);

  if (!costCodes) return <Skeleton />;

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6">
            <h4>Budget Breakdown</h4>
          </Col>
          <Col sm="3" className="pt-1">
            <span className="font-12">Project Estimate:</span>
          </Col>
          <Col sm="3">
            <div className="d-flex">
              <InputWrapper
                disabled={loading}
                cellKey="amount"
                value={estimate}
                type={DisplayType.Currency}
                onInputChange={updateAmount}
              />
              <Button className="btn-sm" onClick={updateProject} color="projx">
                <i className="fas fa-save" />
              </Button>
            </div>
          </Col>
        </Row>
      </CardHeader>
      {estimate && estimate > 0 && estimate < total ? (
        <CardFooter className="border-top">
          <i className="fas fa-exclamation-triangle text-warning" /> You are{' '}
          <Label className="text-danger">
            {currencify(Math.abs(estimate - total), true, 2)}
          </Label>{' '}
          over your Project Estimate of{' '}
          <Label className="text-danger">{currencify(estimate, true, 2)}</Label>
        </CardFooter>
      ) : (
        ''
      )}
      <CardBody>
        <div
          className="chart-wrapper"
          style={{ width: '100%', margin: '0 auto', height: 200 }}
        >
          <Pie
            data={chart}
            options={{
              maintainAspectRatio: false,
              legend: { display: true, labels: { fontFamily: 'Arial' } },
            }}
          />
        </div>
      </CardBody>
      <CardBody className="border-top">
        <table width="100%">
          <tbody>
            {_.map(
              Object.keys(group)
                .sort()
                // eslint-disable-next-line no-sequences
                .reduce((a, c) => ((a[c] = group[c]), a), {}),
              (value, key) => (
                <CostCodeItem
                  key={key}
                  name={key}
                  group={value}
                  project={project}
                />
              ),
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter>
        <h4 className="ml-3 float-left">Total:</h4>
        <div className="float-right">
          <h4>{currencify(total, true, 2)}</h4>
        </div>
      </CardFooter>
    </Card>
  );
};

export default CostCodeOverview;
