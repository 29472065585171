import React, { useState, useEffect } from 'react';
import {
  CardBody,
  CardFooter,
  Button,
  CardHeader,
  Row,
  Col,
  Label,
} from 'reactstrap';
import Select from 'react-select';

import Skeleton from 'react-loading-skeleton';
import { useQueryVendors } from '../../hooks';

const accountTypes = [
  {
    label: 'Contract',
    value: 'contract',
  },
  {
    label: 'Outgoings',
    value: 'bills',
  },
];

const VendorInfo = ({ onVendorSelected = () => {} }) => {
  const [getVendors, { loading }] = useQueryVendors({
    onCompleted: (data) => {
      setVendorz(data);
    },
  });

  const [vendorz, setVendorz] = useState(undefined);
  const [vendorsNormalized, setVendors] = useState([]);
  const [currenVendor, setCurrentVendor] = useState(undefined);
  const [accountType, setAccountType] = useState('contract');

  const vendorSelected = (selectedVendor) => setCurrentVendor(selectedVendor);
  const accountTypeSelected = (selectedAccountType) =>
    setAccountType(selectedAccountType);

  const create = () => onVendorSelected(currenVendor, accountType);

  useEffect(() => {
    if (vendorz)
      setVendors(
        vendorz
          ? vendorz.map((vendor) => {
              return {
                label: `${vendor.name} (ABN: ${vendor.abn})`,
                value: vendor,
              };
            })
          : [],
      );
  }, [vendorz]);

  useEffect(() => {
    getVendors(false);
  }, []);

  return (
    <>
      <CardHeader>Add vendor against a cost code ...</CardHeader>
      <CardBody>
        {loading ? (
          <Skeleton />
        ) : (
          <div className="mt-4">
            <Select
              maxMenuHeight={190}
              closeMenuOnSelect
              defaultValue={undefined}
              options={vendorsNormalized}
              onChange={(e) => vendorSelected(e.value)}
            />
          </div>
        )}
      </CardBody>
      {currenVendor ? (
        <>
          <CardBody className="border-top">
            <div>
              <small className="text-muted pt-4 db">Address</small>
              <h6>{currenVendor.address.suburb}</h6>
              <small className="text-muted pt-4 db">
                Australian Business Number (ABN)
              </small>
              <h6>{currenVendor.abn}</h6>
            </div>
          </CardBody>
          <CardBody className="border-top">
            <Row>
              <Col md="12">
                <Label className="font-12">Account Type</Label>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Select
                  closeMenuOnSelect
                  defaultValue={{ value: 'contract', label: 'Contract' }}
                  options={accountTypes}
                  onChange={(e) => accountTypeSelected(e.value)}
                />
              </Col>
            </Row>
          </CardBody>
        </>
      ) : (
        ''
      )}
      <CardFooter>
        <Button
          color="projx"
          disabled={!currenVendor}
          className="float-right"
          onClick={create}
        >
          Create
        </Button>
      </CardFooter>
    </>
  );
};

export default VendorInfo;
