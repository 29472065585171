import React from 'react';

import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    textTransform: 'uppercase',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    paddingLeft: '50%',
  },
  name: {
    fontSize: 18,
    fontFamily: 'Lato Bold',
  },
  subtitle: {
    fontSize: 10,
    justifySelf: 'flex-end',
    fontFamily: 'Lato',
  },
  link: {
    fontFamily: 'Lato',
    fontSize: 10,
    color: 'black',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  logo: {
    width: 45,
    height: 45,
  },
  formDetailsLeft: {
    flexDirection: 'column',
    width: '40%',
    padding: 2,
    marginTop: 5,
  },
  formDetailsRight: {
    padding: 2,
    marginTop: 5,
  },
  detailsContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  detailsLabel: {
    fontSize: 9,
    fontFamily: 'Lato Bold',
  },
  detailsText: {
    fontSize: 9,
    fontFamily: 'Lato',
  },
  marginize: {
    marginTop: 3,
  },
});

const Header = ({ name, details }) => {
  return (
    <View style={{ margin: 2 }}>
      <View style={styles.container}>
        <View style={styles.detailColumn}>
          <Text style={styles.name}>{name}</Text>
        </View>
        <View style={styles.linkColumn}>
          <Image
            style={styles.logo}
            src={`${
              details.organisationLogo
            }?noCache=${Math.random().toString()}`}
            source={{
              header: {
                'Access-Control-Allow-Origin': '*',
              },
            }}
          />
        </View>
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.formDetailsLeft}>
          <Text style={styles.detailsLabel}>Project Code:</Text>
          <Text style={styles.detailsLabel}>Project Name:</Text>
          <Text style={styles.detailsLabel}>Project Address:</Text>
          <Text style={styles.detailsLabel}>Client:</Text>
          <Text style={[styles.detailsLabel, styles.marginize]}>
            Budget Code:
          </Text>
          <Text style={styles.detailsLabel}>Vendor:</Text>
          <Text style={styles.detailsLabel}>Issue Date:</Text>
        </View>
        <View style={styles.formDetailsRight}>
          <Text style={styles.detailsText}>
            {details.projectCode ? details.projectCode : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.projectName ? details.projectName : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.projectAddress ? details.projectAddress : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.clientName ? details.clientName : '-'}
          </Text>
          <Text style={[styles.detailsText, styles.marginize]}>
            {details.costCode ? details.costCode : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.vendorName ? details.vendorName : '-'}
          </Text>
          <Text style={styles.detailsText}>
            {details.issueDate ? details.issueDate : '-'}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Header;
