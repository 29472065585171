import gql from 'graphql-tag';

/**
 * Queries
 */
const GET_FUNDING_BY_PROJECT_QUERY = gql`
  query ($projectId: ID!) {
    getFundingByProject(projectId: $projectId) {
      _id
      amount
      source {
        key
        value
      }
      description
      date
      project {
        _id
        name
        slug
        logo
        locked
      }
      createdBy {
        _id
        name
        handle
        title
        company
        image
      }
      timestamp
    }
  }
`;

/**
 * Mutations
 */
const CREATE_FUNDING_MUTATION = gql`
  mutation ($input: FundingInput!) {
    createFunding(input: $input) {
      _id
      amount
      source {
        key
        value
      }
      description
      date
      project {
        _id
        name
        slug
        logo
        locked
      }
      createdBy {
        _id
        name
        handle
        title
        company
        image
      }
      timestamp
    }
  }
`;

export { GET_FUNDING_BY_PROJECT_QUERY, CREATE_FUNDING_MUTATION };
