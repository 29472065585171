import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
//
import { Button, Filter, TagsInput } from '../../../components';
import { ProgressDiaryContext } from '../../../contexts';
import {
  AccordionContainer,
  FilterDatePicker,
  DateSeparationText,
  Separator,
} from './styles/FilterAccordion';

// The options for our reporting select dropdown
const reportingSelectOptions = [
  { value: 'BOTH', label: 'Both' },
  { value: 'YES', label: 'Yes' },
  { value: 'NO', label: 'No' },
];

const FilterAccordion = ({ projectId }) => {
  const { 
    filterAccordionOpen, 
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    filterTags,
    setFilterTags,
    reportingSelectValue,
    setReportingSelectValue,
    getTableDataWithFilters,
  } = useContext(ProgressDiaryContext);

  // Handler for our to date input
  const handleToDateSelect = (date) => {
    if (moment(date).isBefore(moment(fromDate))) {
      setFromDate(date);
      setToDate(null);
    } else {
      setToDate(date);
    }
  };

  // Handler for our from date input
  const handleFromDateSelect = (date) => {
    if (moment(date).isAfter(moment(toDate))) {
      setToDate(date);
      setFromDate(null);
    } else {
      setFromDate(date);
    }
  };

  // Handler for resetting all filters to their defaults, so by default ALL results get rendered
  const handleResetFilters = () => {
    setFromDate(new Date(0));
    setToDate(null);
    setFilterTags([]);
    setReportingSelectValue('BOTH');
  };

  const handleApplyFilters = async () => {
    const filter = {
      startDate: fromDate ? fromDate.toISOString() : null,
      endDate: toDate ? toDate.toISOString() : null,
      tags: filterTags,
      reporting: reportingSelectValue,
    };

    await getTableDataWithFilters({
      variables: {
        projectId,
        filter, 
      }
    });
  };

  const applyButtonDisabled = (fromDate === null && toDate === null);

  return (
    <AccordionContainer open={filterAccordionOpen}>
      <Filter>
        <Filter.Item>
          <Filter.Label width="200px">{'Date Range:'}</Filter.Label>
          <Filter.Input width="400px">
            <FilterDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              selected={fromDate}
              onChange={handleFromDateSelect}
            />
            <DateSeparationText>{'to'}</DateSeparationText>
            <FilterDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              selected={toDate}
              onChange={handleToDateSelect}
            />
          </Filter.Input>
        </Filter.Item>
        <Filter.Item>
          <Filter.Label>{'Tags:'}</Filter.Label>
          <Filter.Input minWidth="400px">
            <TagsInput tags={filterTags} setTags={setFilterTags} max={5} />
          </Filter.Input>
        </Filter.Item>
        <Filter.Item>
          <Filter.Label>{'Reporting:'}</Filter.Label>
          <Filter.Input>
            <Select
              options={reportingSelectOptions}
              value={_.find(reportingSelectOptions, (item, index) => {
                return item.value === reportingSelectValue;
              })}
              defaultValue={{ value: 'BOTH', label: 'Both' }}
              onChange={(e) => setReportingSelectValue(e.value)}
            />
          </Filter.Input>
        </Filter.Item>
        <Filter.Footer>
          <Button outline onClick={handleResetFilters}>
            {`Reset`}
          </Button>
          <Separator />
          <Button onClick={handleApplyFilters} disabled={applyButtonDisabled}>{`Apply`}</Button>
        </Filter.Footer>
      </Filter>
    </AccordionContainer>
  );
};

export default FilterAccordion;
