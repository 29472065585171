import styled from 'styled-components';
import { MdOutlineDateRange } from 'react-icons/md';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

export const SafetyPage = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif; 
`;

export const SafetyHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SafetyHeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SafetyHeaderBackIcon = styled(MdOutlineArrowBackIosNew)`
  width: 25px;
  height: 25px;
  color: #1B1B1B;
`;

export const SafetyPeriodsIcon = styled(MdOutlineDateRange)`
  width: 25px;
  height: 25px;
  color: #1B1B1B;
`;

export const SafetyHeaderBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const SafetyHeaderBackButtonText = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-left: 8px;
  cursor: pointer;
`;

export const SafetyHeading = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-left: 8px;
`;

export const SafetyPeriodNoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
`;

export const SafetyPeriodNoItemText = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
`;