import React from 'react';
import { FundingCardsContainerStyles } from './styles/FundingCardsContainer';

const FundingCardsContainer = ({ children }) => {
  return (
    <FundingCardsContainerStyles>
      {children}
    </FundingCardsContainerStyles>
  );
}

export default FundingCardsContainer;
