import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useContext } from 'react';
import {
  CREATE_PROJECT_MUTATION,
  ADD_PROJECT_COLLABORATOR_MUTATION,
  UPDATE_PROJECT_COLLABORATOR_MUTATION,
  DELETE_PROJECT_COLLABORATOR_MUTATION,
  SAVE_PROJECT_FIELD_MUTATION,
  ADD_PROJECT_DOCUMENT_MUTATION,
} from '../../graphql/queries';
import { OrganisationContext } from '../../contexts';
import { cleanGqlTypename } from '../../common';
import { toBase64 } from '../../common/utils';

const projectMapper = (project) => {
  return {
    name: project.project.name,
    slug: project.slug,
    description: project.description,
    address: project.project.address,
    code: project.project.code,
    sectors: _.map(project.project.sectors, (sector) => sector.value),
    client: project.client,
    organisation: project.organisationId,
    collaborators: _.map(project.invitees, ({ user, role }) => ({
      user,
      role,
    })),
    amount: project.totalBudget,
  };
};

export const useSaveProjectField = () => {
  const [_saveProjectField, { data, loading, error }] = useMutation(
    SAVE_PROJECT_FIELD_MUTATION,
  );

  const saveProjectField = (projectId, key, value) => {
    return _saveProjectField({
      variables: {
        projectId,
        key,
        value,
      },
    });
  };

  let fieldData =
    !loading && data
      ? cleanGqlTypename(_.get(data, 'saveProjectField'))
      : undefined;

  return [saveProjectField, { fieldData, loading, error }];
};

export const useCreateProject = () => {
  const { currentOrganisation } = useContext(OrganisationContext);

  const [createProjectInternal, { data }] = useMutation(
    CREATE_PROJECT_MUTATION,
  );

  const createProject = async (project) => {
    const organisationId = currentOrganisation
      ? currentOrganisation._id
      : undefined;

    if (!organisationId) throw Error(`No organisation selected`);

    project.organisationId = organisationId;

    let response;

    try {
      response = await createProjectInternal({
        variables: {
          project: projectMapper(project),
        },
      });
    } catch (e) {
      throw Error(e);
    }

    return response.data.createProject;
  };

  return [createProject, { data }];
};

export const useAddProjectCollaborator = () => {
  const [_addProjectCollaborator, { data, loading, error }] = useMutation(
    ADD_PROJECT_COLLABORATOR_MUTATION,
  );

  const addProjectCollaborator = (projectId, collaborator) => {
    return _addProjectCollaborator({
      variables: { _id: projectId, collaborator },
    });
  };

  return [addProjectCollaborator, { data, loading, error }];
};

export const useUpdateProjectCollaborator = () => {
  const [_updateProjectCollaborator, { data, loading, error }] = useMutation(
    UPDATE_PROJECT_COLLABORATOR_MUTATION,
  );

  const updateProjectCollaborator = (projectId, collaborator) => {
    return _updateProjectCollaborator({
      variables: { _id: projectId, collaborator },
    });
  };

  return [updateProjectCollaborator, { data, loading, error }];
};

export const useDeleteProjectCollaborator = () => {
  const [_deleteProjectCollaborator, { data, loading, error }] = useMutation(
    DELETE_PROJECT_COLLABORATOR_MUTATION,
  );

  const deleteProjectCollaborator = (projectId, userId) => {
    return _deleteProjectCollaborator({
      variables: { _id: projectId, userId },
    });
  };

  return [deleteProjectCollaborator, { data, loading, error }];
};

export const useAddProjectDocument = ({ onCompleted = () => {} }) => {
  const [_addProjectDocument, { data, loading, error }] = useMutation(
    ADD_PROJECT_DOCUMENT_MUTATION,
    {
      onCompleted: (data) => onCompleted(_.get(data, 'addProjectDocument')),
    },
  );

  const addProjectDocument = (projectId, documentInput) => {
    return toBase64(documentInput.document).then((documentBase64) => {
      return _addProjectDocument({
        variables: {
          projectId,
          documentInput: _.assign({}, documentInput, {
            document: documentBase64,
          }),
        },
      });
    });
  };

  const document =
    !loading && data ? _.get(data, 'addProjectDocument') : undefined;

  return [addProjectDocument, { document, loading, error }];
};
