import { useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import {
  GET_BUDGET_MESSAGES_QUERY,
  SEND_BUDGET_MESSAGES_MUTATION,
} from '../../graphql/queries';

import { cleanGqlTypename } from '../../common';

const itemMapper = (item) => cleanGqlTypename(item);
const mapper = (items) => _.map(items, (item) => itemMapper(item));

const useGetMessages = ({ budgetId, onCompleted = () => {} }) => {
  let [getMessages, { loading, error, refetch }] = useLazyQuery(
    GET_BUDGET_MESSAGES_QUERY,
    {
      variables: {
        budgetId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => onCompleted(mapper(_.get(data, 'getMessages'))),
    },
  );

  return { loading, error, getMessages, refetch };
};

const useSendMessage = ({ onCompleted = () => {} }) => {
  const [_sendMessage, { data, loading, error }] = useMutation(
    SEND_BUDGET_MESSAGES_MUTATION,
    {
      onCompleted: (data) =>
        onCompleted(itemMapper(_.get(data, 'sendMessage'))),
    },
  );

  const sendMessage = (budgetId, message) => {
    return _sendMessage({
      variables: {
        budgetId,
        message,
      },
    });
  };

  return [sendMessage, { data, loading, error }];
};

export { useGetMessages, useSendMessage };
