import React from 'react';
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';
import Select from 'react-select';
import { removeFileExtension } from '../../helpers';
import FileUploadDropzone from '../fileUpload/FileUploadDropzone';
import { toast } from 'react-toastify';
import _ from 'lodash';

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
const formatBytes = (bytes, decimals = 1) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const MAX_DOCUMENT_BYTES = 4720000; // Approximately 4.5 megabytes
const PRETTY_FORMATED_BYTES = formatBytes(MAX_DOCUMENT_BYTES);

const DocumentForm = ({
  documentFormState,
  setDocumentFormState,
  categoryOptions,
}) => {
  const _onFilesAccepted = (files) => {
    // Currently only support one file
    const file = files[0];

    // default title to name of file
    setDocumentFormState((prevState) => ({
      ...prevState,
      document: file,
      title: removeFileExtension(file.name),
    }));
  };

  const _onDropRejected = (droppedFiles) => {
    // Currently only support one file
    const droppedFile = droppedFiles[0];

    const errors = _.get(droppedFile, 'errors', []);

    errors.forEach(({ message, code }) => {
      if (code === 'file-too-large')
        toast.error(`Maximum document size exceeded: ${PRETTY_FORMATED_BYTES}`);
      else toast.error(message);
    });
  };

  return (
    <div>
      <FormGroup>
        <Row>
          <Label sm="3">Document *</Label>
          <Col sm="9">
            <FileUploadDropzone
              onFilesAccepted={_onFilesAccepted}
              onDropRejected={_onDropRejected}
              maxSizeBytes={MAX_DOCUMENT_BYTES}
            >
              {documentFormState.document ? (
                documentFormState.document.name
              ) : (
                <div>
                  <p>Drag document here, or click to select document</p>
                  <em>(Maximum size of document: {PRETTY_FORMATED_BYTES})</em>
                </div>
              )}
            </FileUploadDropzone>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Label sm="3">Title *</Label>
          <Col sm="9">
            <Input
              value={documentFormState.title}
              onChange={(e) => {
                const { value } = e.target;
                setDocumentFormState((prevState) => ({
                  ...prevState,
                  title: value,
                }));
              }}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Label sm="3">Category *</Label>
          <Col sm="9">
            <Select
              closeMenuOnSelect
              options={categoryOptions}
              onChange={(e) => {
                const { value } = e;
                setDocumentFormState((prevState) => ({
                  ...prevState,
                  category: value,
                }));
              }}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Label sm="3">Notes</Label>
          <Col sm="9">
            <Input
              type="textarea"
              value={documentFormState.notes}
              onChange={(e) => {
                const { value } = e.target;
                setDocumentFormState((prevState) => ({
                  ...prevState,
                  notes: value,
                }));
              }}
            />
          </Col>
        </Row>
      </FormGroup>
    </div>
  );
};

export default DocumentForm;
