import React from 'react';
import { SpinnerContainer, ClipLoaderStyles } from './styles/LoadingWidgetSpinner';

const LoadingWidgetSpinner = (props) => {
  return (
    <SpinnerContainer>
      <ClipLoaderStyles {...props}/>
    </SpinnerContainer>
  )
}

export default LoadingWidgetSpinner;