import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';
import { useAlert } from 'react-alert';
import GlobalReferenceContext from './globalReference.context';

// import { budgets } from "./globalReference.data"

import { useQueryReference, useCostCodeSetup } from '../../hooks';
import { updateArrayByKey, copyArrayReference } from '../../common';
import OrganisationContext from '../organisation/organisation.context';

const GlobalReferenceProvider = ({ children }) => {
  const alert = useAlert();

  const { currentOrganisation } = useContext(OrganisationContext);

  const [refs, setReference] = useState(undefined);
  const [budgets, setBudget] = useState([]);
  const [budgetItemCosts, setBudgetCosts] = useState([]);

  const {
    Actions,
    getCostCodeSetupByOrg,
    createCostCodeSetup,
    updateCostCodeSetup,
    deleteCostCodeSetup,
  } = useCostCodeSetup({
    onCompleted: ({ action, data }) => {
      switch (action) {
        case Actions.GetCostCodeSetupOrg:
          setBudget(data);
          break;
        case Actions.CreateCostCodeSetup:
          setBudget(updateArrayByKey(data, budgets));
          break;
        case Actions.UpdateCostCodeSetup:
          setBudget(updateArrayByKey(data, budgets));
          break;
        case Actions.DeleteCostCodeSetupOrg:
          _removeItem(data);
          break;
        default:
          break;
      }
    },
  });

  const { getReferences } = useQueryReference({
    onCompleted: (data) => setReference(data),
  });

  /**
   * Bootstrap all the references
   */
  useEffect(() => {
    if (_.has(currentOrganisation, '_id')) {
      getReferences(currentOrganisation._id);
      getCostCodeSetupByOrg(currentOrganisation._id);
    }
  }, [currentOrganisation]);

  let _removeItem = (id) => {
    const clone = copyArrayReference(budgets);
    _.remove(clone, (item) => item._id === id);
    setBudget(clone);
  };

  const _updateReferenceItem = (item) =>
    setReference(updateArrayByKey(item, refs, '_id', true));

  const _normalize = (key) => {
    const reference = _getReference(key);
    if (!reference) return [];
    return reference.items.map((item) => ({
      value: item.key,
      label: item.value,
    }));
  };

  const _transform = () => {
    if (!budgets) return [];
    return budgets.map((item) => ({
      value: item,
      label: `(${item.costCode.code}) ${item.costCode.name}`,
    }));
  };

  let _getReference = (referenceName) => {
    return _.find(refs, (ref) => ref.name === referenceName);
  };

  const _copyBudgetCosts = () => {
    return Object.assign([], budgetItemCosts);
  };

  const _updateLocalBudget = (item) => {
    setBudget(updateArrayByKey(item, budgets));
  };

  const _updateBudget = async (budgetItem) => {
    try {
      await updateCostCodeSetup(budgetItem._id, budgetItem);
    } catch (e) {
      alert.error(e.message);
    }
  };

  const _deleteBudgetLine = async (item) => {
    if (item.isNew) return _removeItem(item._id);

    try {
      await deleteCostCodeSetup(item._id);
    } catch (e) {
      alert.error(e.message);
    }
  };

  const _saveBudgetItem = async (budgetItem) => {
    try {
      await createCostCodeSetup(budgetItem);
    } catch (e) {
      alert.error(e.message);
      throw e;
    }
  };

  const _addBudgetItem = (budgetItem, fn = () => {}) => {
    const tempBudgets = Object.assign([], budgets);

    if (_.some(tempBudgets, (item) => item._id === '')) return;

    if (
      !_.some(
        tempBudgets,
        (budget) =>
          budget.costCode.code.toLowerCase() ===
          budgetItem.costCode.code.toLowerCase(),
      )
    ) {
      tempBudgets.push(budgetItem);
      setBudget(tempBudgets);
      fn(null, tempBudgets);
    } else {
      fn(new Error(`${budgetItem.costCode.code} code already exists.`));
    }
  };
  const _saveBudgetCost = (budgetItemData, cb) => {
    let tempBudgetItemCosts = _copyBudgetCosts();

    if (
      _.some(tempBudgetItemCosts, (item) => item.key === budgetItemData.key)
    ) {
      tempBudgetItemCosts = _.map(tempBudgetItemCosts, (budgetItem) =>
        budgetItem.key === budgetItemData.key ? budgetItemData : budgetItem,
      );
    } else {
      tempBudgetItemCosts.push(budgetItemData);
    }

    setBudgetCosts(tempBudgetItemCosts);

    if (cb) cb();
  };

  const _resetBudget = () => {
    setBudgetCosts([]);
  };

  const _getBudgetReferences = () => {
    return budgets.map((item) => {
      return { value: item.costCode.code, label: item.costCode.name };
    });
  };

  const _removeBudgetCost = (key) => {
    const tempBudgetItemCosts = _copyBudgetCosts();
    _.remove(tempBudgetItemCosts, (item) => item.key === key);
    setBudgetCosts(tempBudgetItemCosts);
  };

  const _getBudgetSettingFromBudgetCost = (costCode) => {
    return _.find(budgets, (budget) => budget.costCode === costCode);
  };

  const _getBudgetCostsLength = () => {
    return budgetItemCosts.length;
  };

  const _getBudgetCosts = () => {
    return budgetItemCosts;
  };

  return (
    <GlobalReferenceContext.Provider
      value={{
        budgets,
        references: refs,
        updateReferenceItem: _updateReferenceItem,
        refreshReferences: getReferences,
        budgetCosts: budgetItemCosts,
        transformBudgets: _transform,
        getReference: _getReference,
        normalize: _normalize,
        deleteBudgetLine: _deleteBudgetLine,
        updateBudget: _updateBudget,
        addBudgetItem: _addBudgetItem,
        saveBudgetItem: _saveBudgetItem,
        updateLocalBudget: _updateLocalBudget,
        saveBudgetCost: _saveBudgetCost,
        removeBudgetCost: _removeBudgetCost,
        getBudgetReferences: _getBudgetReferences,
        resetBudget: _resetBudget,
        getBudgetSettingFromBudgetCost: _getBudgetSettingFromBudgetCost,
        getBudgetCostsLength: _getBudgetCostsLength,
        getBudgetCosts: _getBudgetCosts,
      }}
    >
      {children}
    </GlobalReferenceContext.Provider>
  );
};

export default GlobalReferenceProvider;
