import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { ROLES_BY_ORG_QUERY } from '../../graphql/queries';
import { cleanGqlTypename } from '../../common';

const useRolesByOrg = (organisationId) => {
  const { loading, error, data } = useQuery(ROLES_BY_ORG_QUERY, {
    variables: { organisationId },
  });

  const roles = !loading && data ? cleanGqlTypename(data.getRolesByOrg) : [];

  const getRoleId = (roleName) =>
    _.get(
      _.find(roles, (role) => role.name === roleName),
      '_id',
    );

  return {
    getRoleId,
    loading,
    error,
    roles,
  };
};

export { useRolesByOrg };
